import React from 'react'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material'
import { IEquipmentProfile } from '../../../types/equipment/equipmentProfile'
import DeviceBoxInsides, { DeviceBoxOutside } from './DeviceBoxInsides'
import IconButton from '../../buttons/IconButton'
import Button from '../../buttons/Button'
import Icon from '../../Icon'
import { IDevices, IAssetDevice } from '../../../types/account/devices'
import AddPhoneComponent from './AddPhoneComponent'
import AddDeviceComponent from './AddDeviceComponent'

interface IProps {
  open: boolean
  onClose: () => void
  isMobile: boolean
  equipmentProfile: IEquipmentProfile
}

interface IState {
  anchorEl: HTMLButtonElement | null
  allDevices: { deviceModel: string; devices: IDevices[] }[]
  devicesLoading: boolean
  deviceSearch: string
  selectedDevices: IAssetDevice[]
  addingPhone: boolean
}

class DeviceDialog extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: null,
    allDevices: [],
    devicesLoading: true,
    deviceSearch: '',
    selectedDevices: [],
    addingPhone: false,
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  removeDevice(esn: string) {
    let selectedDevices = this.state.selectedDevices
    const selectedDevicesIndx = selectedDevices.findIndex(
      (sd) => sd.esn === esn
    )
    selectedDevices.splice(selectedDevicesIndx, 1)
    this.setState({ selectedDevices: selectedDevices })
  }

  addDevice(newDevice: IDevices) {
    let selectedDevices = this.state.selectedDevices
    selectedDevices.push({
      esn: newDevice.esn,
      deviceType: newDevice.id,
      description: newDevice.description,
    })
    this.setState({ selectedDevices: selectedDevices })
  }

  componentDidMount() {
    this.setState({ selectedDevices: this.props.equipmentProfile.devices })
  }

  render() {
    const { equipmentProfile } = this.props
    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={() => this.props.onClose()}
          fullScreen={this.props.isMobile}
          fullWidth
        >
          {this.state.addingPhone ? (
            <AddPhoneComponent
              onClose={() => this.setState({ addingPhone: false })}
            />
          ) : (
            <>
              <DialogTitle>
                Edit Devices for {equipmentProfile.name}
              </DialogTitle>

              <DialogContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {this.state.selectedDevices.map((device, key) => (
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    key={'deviceDialog' + key}
                  >
                    <div style={{ width: '50%' }}>
                      <DeviceBoxOutside>
                        <DeviceBoxInsides device={device} />
                      </DeviceBoxOutside>
                    </div>
                    <IconButton
                      icon="delete"
                      variant="row"
                      tooltip="Remove Device"
                      onClick={() => this.removeDevice(device.esn)}
                    />
                  </div>
                ))}
                <div style={{ paddingTop: 20 }}>
                  <Button
                    variant="outlined"
                    startIcon={<Icon icon="add" color="primary" />}
                    color="primary"
                    tooltip="Add Device"
                    onClick={this.handleClick}
                  >
                    DEVICE
                  </Button>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.props.onClose()}>Cancel</Button>
                <Button color="primary" onClick={() => this.props.onClose()}>
                  Save
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
        <AddDeviceComponent
          anchorEl={this.state.anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          addDevice={(newDevice) => {
            this.addDevice(newDevice)
            this.setState({ anchorEl: null })
          }}
          addPhone={() => this.setState({ addingPhone: true, anchorEl: null })}
        />
      </>
    )
  }
}

export default DeviceDialog
