import React from 'react'
import '../styles/components/UserMenu.scss'
import IconButtonPopover from './buttons/IconButtonPopover'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import Icon from './Icon'
import { signOut } from './signOut'

interface IState {
  anchorEl: any
}

interface IProps {
  title: string
  accountName: string
  user: {
    name: string
    email: string
  }
}

class UserMenu extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: null,
  }

  render() {
    return (
      <IconButtonPopover icon="session" title={this.props.title}>
        <div>
          <ListSubheader>{this.props.accountName}</ListSubheader>
          <div className="userInfoStyle">
            {this.props.user.name}
            <br />
            {this.props.user.email}
          </div>

          <Divider />

          <List className="sessionMenu">
            <ListItem
              button
              component="a"
              href="/profile"
              style={{ color: 'rgba(0, 0, 0, 0.87)' }} // needed to avoid the color change that was created globally for this
            >
              <ListItemIcon>
                <Icon icon="userProfile" />
              </ListItemIcon>
              <ListItemText primary="User Profile" />
            </ListItem>
            <ListItem
              button
              component="a"
              onClick={() => signOut()}
              style={{ color: 'rgba(0, 0, 0, 0.87)' }} // needed to avoid the color change that was created globally for this
            >
              <ListItemIcon>
                <Icon icon="signOut" />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </List>
        </div>
      </IconButtonPopover>
    )
  }
}

export default UserMenu
