import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Checkbox, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { colors, darkMode } from '../../ux/roviTheme'
import { grey } from '@mui/material/colors'

const check = (color?: string) =>
  createTheme({
    palette: {
      secondary: {
        main: color ? color : colors.primaryOrange,
      },
      action: {
        active: color ? color : darkMode ? '#fff' : grey[800],
      },
    },
  })

interface IProps {
  label: string
  onChange: (bool: boolean) => void
  checked: boolean
  color?: string
}

class FilterCheckBox extends React.Component<IProps> {
  render() {
    return (
      <FormControlLabel
        control={
          <ThemeProvider theme={() => check(this.props.color)}>
            <Checkbox
              checked={this.props.checked}
              onChange={(event) =>
                this.props.onChange(event.target.checked as boolean)
              }
            />
          </ThemeProvider>
        }
        label={<Typography variant="caption">{this.props.label}</Typography>}
      />
    )
  }
}

export default FilterCheckBox
