import React from 'react'
import Icon from '../Icon'
import Fab from '@mui/material/Fab'
import { IStandardIcons } from '../ux/standardIcons'
import { colors } from '../ux/roviTheme'

interface IProps {
  icon: IStandardIcons
  color?: 'secondary' | 'primary' | 'default' | 'inherit'
  whiteBlackVersion?: boolean
  size?: 'small' | 'medium'
  noDefaultPostion?: boolean
  onClick?: () => void
}

const FabButton = (props: IProps) => {
  return (
    <Fab
      color={
        props.color
          ? props.color
          : props.whiteBlackVersion
          ? 'inherit'
          : 'primary'
      }
      style={{
        right: props.noDefaultPostion ? undefined : 20,
        bottom: props.noDefaultPostion ? undefined : 20,
        position: props.noDefaultPostion ? undefined : 'fixed',
        backgroundColor: props.whiteBlackVersion ? colors.bg : undefined,
      }}
      size={props.size}
      onClick={props.onClick}
    >
      <Icon
        icon={props.icon}
        style={{
          color: props.whiteBlackVersion ? colors.defaultText : '#fff',
        }}
      />
    </Fab>
  )
}

export default FabButton
