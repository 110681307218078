import React from 'react'
import { Auth } from 'aws-amplify'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material/styles/createTheme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import MUIFormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '../components/buttons/IconButton'
import { RegexPasswordCheck } from '../utility/regex'
import '../styles/pages/Registration.scss'
import TextField from '../components/textBoxs/TextField'
import ReactHelmet from '../components/ReactHelmet'
import ExternalFootbar from '../components/footbars/ExternalFootbar'
import AssetManagement from '../components/registration/AssetManagement'
import Button from '../components/buttons/Button'
import { darkMode } from '../components/ux/roviTheme'
import WidthViewport from '../components/ux/WidthViewport'

const DarkTheme = (theme: Theme) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#0078FF',
      },
      background: {
        paper: darkMode ? theme.palette.grey[700] : theme.palette.grey[100],
      },
    },
  })

const industries = [
  'Construction',
  'Energy',
  'Government',
  'Law Enforcement',
  'Rental',
  'Other',
]

interface IProps {}

class Registration extends React.Component<IProps> {
  state = {
    companyName: '',
    industrySelected: '',
    industrySelectedError: false,
    fname: '',
    lname: '',
    email: '',
    emailError: '',
    promo: '',
    emailOffers: false,
    acceptedTerms: false,
    awsError: '',
    password: '',
    passwordError: '',
    passwordConfirm: '',
    password2Error: '',
    passwordVisible: false,
    password2Visible: false,
  }

  changePassword1(val: string) {
    this.setState({
      password: val,
      passwordError:
        val.length > 7
          ? RegexPasswordCheck.test(val)
            ? ''
            : 'Password Requires atleast 1 special character'
          : 'Password is too short',
    })
  }

  changePassword2(val: string) {
    this.setState({
      passwordConfirm: val,
      password2Error:
        this.state.password === val ? '' : "Password doesn't match",
    })
  }

  handleAWSErrors(err: { message: string; code: string }) {
    console.log(err)
    switch (err.code) {
      case 'UsernameExistsException':
        this.setState({ emailError: err.message })
        break
      case 'InvalidPasswordException':
        if (
          err.message ===
          'Password did not conform with policy: Password must have symbol characters'
        ) {
          this.setState({
            passwordError: 'Password must have symbol characters',
          })
        }
        break
      default:
        this.setState({ awsError: err.message })
        break
    }
  }

  handleSignUp = () => {
    console.log(this.state)
    Auth.signUp({
      username: this.state.email,
      password: this.state.password,
      attributes: {
        email: this.state.email,
        'custom:company': this.state.companyName,
        'custom:industry': this.state.industrySelected,
        'custom:promo': this.state.promo,
      },
    })
      .then((data: any) => console.log(data))
      .then(() => window.location.replace('/verify'))
      .catch((err: any) => this.handleAWSErrors(err))
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => {
          const styles: Record<string, React.CSSProperties> = {
            textFieldLeft: {
              marginRight: isMobile ? undefined : 20,
            },
            singleTextFields: {
              justifyContent: isMobile ? 'center' : 'flex-start',
            },
            buttonContainer: {
              justifyContent: isMobile ? 'center' : 'flex-end',
            },
          }

          const { textFieldLeft, singleTextFields, buttonContainer } = styles

          return (
            <ThemeProvider theme={DarkTheme}>
              <div className="registrationContainer">
                <div className="registrationLogoContainer">
                  <h1 className="logoh1">
                    <a title="Rovi Tracker" href="https://www.rovitracker.com">
                      <img
                        src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-320x204.png"
                        className="logo"
                        alt="Rovi tracker"
                      />
                    </a>
                  </h1>
                </div>
                {this.state.awsError}
                <Paper className="registrationInner">
                  <ReactHelmet title="Registration" />
                  <div className="registrationTitle">
                    <div className="registrationTitleWords">Registration</div>
                  </div>
                  <div className="registrationTexts">
                    <div className="textFieldRow">
                      <div style={textFieldLeft}>
                        <TextField
                          required
                          name="company"
                          id="registrationCompanyNameInputField"
                          label="Company Name"
                          autoComplete="company"
                          className="textField textFieldLeft"
                          type="text"
                          noMargin
                          value={this.state.companyName}
                          onChange={(event) =>
                            this.setState({ companyName: event.target.value })
                          }
                        />
                      </div>
                      <FormControl
                        id="industryForm"
                        className="textField"
                        required
                        variant="standard"
                        sx={{ marginTop: 0, marginBottom: '15px' }}
                      >
                        <InputLabel style={{ paddingBottom: 14 }}>
                          Industry
                        </InputLabel>
                        <Select
                          error={this.state.industrySelectedError}
                          value={this.state.industrySelected}
                          onChange={(event) =>
                            this.setState({
                              industrySelected: event.target.value,
                            })
                          }
                        >
                          {industries.map((industry, key) => (
                            <MenuItem key={key} value={industry}>
                              {industry}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.state.industrySelectedError && (
                          <MUIFormHelperText error>Required</MUIFormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className="textFieldRow">
                      <div style={textFieldLeft}>
                        <TextField
                          id="registrationFirstNameInputField"
                          required
                          name="fname"
                          label="First Name"
                          autoComplete="given-name"
                          className="textField textFieldLeft"
                          type="text"
                          noMargin
                          value={this.state.fname}
                          onChange={(event) =>
                            this.setState({ fname: event.target.value })
                          }
                        />
                      </div>
                      <TextField
                        required
                        id="registrationLastNameInputField"
                        name="lname"
                        label="Last Name"
                        autoComplete="family-name"
                        className="textField"
                        type="text"
                        noMargin
                        value={this.state.lname}
                        onChange={(event) =>
                          this.setState({ lname: event.target.value })
                        }
                      />
                    </div>
                    <div className="textFieldRow" style={singleTextFields}>
                      <TextField
                        required
                        id="registrationEmailInputField"
                        name="email"
                        label="Email"
                        autoComplete="email"
                        className="textField"
                        type="email"
                        noMargin
                        value={this.state.email}
                        error={!!this.state.emailError}
                        helperText={this.state.emailError}
                        onChange={(event) =>
                          this.setState({
                            email: event.target.value,
                            emailError: '',
                          })
                        }
                      />
                    </div>
                    <div className="textFieldRow">
                      <div style={textFieldLeft}>
                        <TextField
                          required
                          id="registrationPasswordInputField"
                          name="password"
                          label="Password"
                          autoComplete="password"
                          className="textField"
                          noMargin
                          error={!!this.state.passwordError}
                          helperText={this.state.passwordError}
                          type={
                            this.state.passwordVisible ? 'text' : 'password'
                          }
                          value={this.state.password}
                          onChange={(event) =>
                            this.changePassword1(event.target.value)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                id="registrationPasswordToggleVisibility"
                                variant="appbar"
                                icon={
                                  this.state.passwordVisible
                                    ? 'visibility'
                                    : 'visibilityOff'
                                }
                                onClick={() =>
                                  this.setState({
                                    passwordVisible:
                                      !this.state.passwordVisible,
                                  })
                                }
                              />
                            </InputAdornment>
                          }
                        />
                      </div>
                      <TextField
                        required
                        id="registrationConfirmPasswordInputField"
                        name="password2"
                        label="Confirm Password"
                        autoComplete="password"
                        className="textField"
                        noMargin
                        error={!!this.state.password2Error}
                        helperText={this.state.password2Error}
                        type={this.state.password2Visible ? 'text' : 'password'}
                        value={this.state.passwordConfirm}
                        onChange={(event) =>
                          this.changePassword2(event.target.value)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              id="registrationConfirmPasswordToggleVisibility"
                              variant="appbar"
                              icon={
                                this.state.password2Visible
                                  ? 'visibility'
                                  : 'visibilityOff'
                              }
                              onClick={() =>
                                this.setState({
                                  password2Visible:
                                    !this.state.password2Visible,
                                })
                              }
                            />
                          </InputAdornment>
                        }
                      />
                    </div>
                    <div className="textFieldRow" style={singleTextFields}>
                      <TextField
                        id="registrationPromoCodeInputField"
                        name="promo"
                        label="Promo Code"
                        className="textField"
                        type="text"
                        noMargin
                        value={this.state.promo}
                        onChange={(event) =>
                          this.setState({ promo: event.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="registrationCheckBoxes">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="registrationEmailOffersCheckBox"
                          name="checkedB"
                          color="primary"
                          checked={this.state.emailOffers}
                          onChange={(event) =>
                            this.setState({ emailOffers: event.target.checked })
                          }
                        />
                      }
                      label={
                        <div className="checkP">
                          Email me Rovitracker.com newsletters, offers and
                          product updates.
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="registrationTermsCheckBox"
                          name="checkedB"
                          color="primary"
                          checked={this.state.acceptedTerms}
                          onChange={(event) =>
                            this.setState({
                              acceptedTerms: event.target.checked,
                            })
                          }
                        />
                      }
                      label={
                        <div className="checkTerms checkP">
                          <div className="checkTermsP">
                            By checking this box you agree to our
                          </div>
                          <a href="https://rovitracker.com/terms-of-service/">
                            terms of use.
                          </a>
                        </div>
                      }
                    />
                  </div>
                  <div className="registerButtons" style={buttonContainer}>
                    <a
                      className="registrationLoginReturnButton"
                      id="registrationLoginReturnButton"
                      href="/"
                    >
                      Login?
                    </a>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleSignUp()}
                      disabled={
                        !(
                          !this.state.passwordError &&
                          !this.state.password2Error &&
                          this.state.password.length > 7 &&
                          this.state.passwordConfirm.length > 7 &&
                          this.state.passwordConfirm === this.state.password &&
                          this.state.acceptedTerms &&
                          !!this.state.companyName &&
                          !this.state.industrySelectedError &&
                          !!this.state.industrySelected &&
                          !!this.state.fname &&
                          !!this.state.lname &&
                          !!this.state.email &&
                          !this.state.emailError
                        )
                      }
                    >
                      Register
                    </Button>
                  </div>
                </Paper>
                <AssetManagement />
                <ExternalFootbar />
              </div>
            </ThemeProvider>
          )
        }}
      </WidthViewport>
    )
  }
}

export default Registration
