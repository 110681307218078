import React from 'react'
import '../../styles/ux/ServiceButton.scss'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Badge from '@mui/material/Badge'
import Icon from '../Icon'
import { ERoutes } from '../routes/CheckRoutes'
import { colors } from './roviTheme'
import { backend_api, headersAPI } from '../../constants/api'
import ServicePopover, { colorLookup, getType } from './ServicePopover'
import classNames from 'classnames'

export function sort<T>(
  arr: Array<T>,
  compareFn?: (a: T, b: T) => number
): Array<T> {
  return [...arr].sort(compareFn)
}

const styles = createStyles({
  icon: {
    height: 16,
    width: 16,
    margin: '2px 0',
    color: colors.iconColor,
  },
  badge: {
    top: 2,
    height: 14,
    width: 14,
    minWidth: 14,
    fontSize: 10,
    color: 'white',
    background: colorLookup['none'],
  },
  badge_red: {
    background: colorLookup['red'],
  },
  badge_orange: {
    background: colorLookup['orange'],
  },
  badge_blue: {
    background: colorLookup['blue'],
  },
  badge_none: {
    background: colorLookup['none'],
  },
  root: {},
})

interface IState {
  tasks: Array<any>
  anchorEl: React.ReactNode
}

interface IProps extends WithStyles<typeof styles> {
  assetId: string
  tasks?: Array<any>
}

class ServiceButton extends React.Component<IProps, IState> {
  state = {
    anchorEl: null,
    tasks: [],
  }

  findHighestRatio(
    tasksCheck: Array<{
      type: any
      task: any
      time: any
      scheduleRatio: string
    }>
  ) {
    let highestRatio = 0
    tasksCheck.forEach((task) => {
      const ratio = parseFloat(task.scheduleRatio)
      highestRatio = ratio > highestRatio ? ratio : highestRatio
    })
    return highestRatio
  }

  handlePopoverOpen = (ev: { currentTarget: any }) => {
    this.setState({ anchorEl: ev.currentTarget })
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  grabServices() {
    fetch(backend_api + `/assets/tasks?assetId=${this.props.assetId}`, {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          tasks: response.tasks,
        })
      })
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    if (!!this.props.tasks) {
      this.setState({
        tasks: this.props.tasks,
      })
    } else {
      this.grabServices()
    }
  }

  render() {
    const badgeType = getType(this.findHighestRatio(this.state.tasks))
    return (
      <>
        {this.state.tasks.length > 0 ? (
          <a href={ERoutes.service} onClick={(ev) => ev.stopPropagation()}>
            <Badge
              badgeContent={this.state.tasks.length}
              onMouseEnter={this.handlePopoverOpen}
              onMouseLeave={this.handlePopoverClose}
              classes={{
                root: this.props.classes.root,
                badge: classNames(this.props.classes.badge, {
                  [this.props.classes.badge_blue]: badgeType === 'blue',
                  [this.props.classes.badge_none]: badgeType === 'none',
                  [this.props.classes.badge_orange]: badgeType === 'orange',
                  [this.props.classes.badge_red]: badgeType === 'red',
                }),
              }}
            >
              <Icon
                icon="service"
                className={this.props.classes.icon}
                fontSize="small"
              />
            </Badge>
          </a>
        ) : null}
        <ServicePopover
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          tasks={this.state.tasks}
        />
      </>
    )
  }
}

export default withStyles(styles)(ServiceButton)
