import React from 'react'
import '../../styles/ux/ServiceButton.scss'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import Button from '../buttons/Button'
import Popover from '@mui/material/Popover'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Icon from '../Icon'

const styles = createStyles({
  formControl: {
    margin: '10px 20px',
    minWidth: 250,
  },
})

interface IProps extends WithStyles<typeof styles> {
  values: any[]
}

interface IState {
  anchorEl: HTMLButtonElement | null
  selectedThing: string
}

class BranchThingsPopover extends React.Component<IProps, IState> {
  state = {
    anchorEl: null,
    selectedThing: this.props.values[0],
  }

  render() {
    return (
      <>
        <Button
          variant="outlined"
          onClick={(event) => this.setState({ anchorEl: event.currentTarget })}
        >
          {this.state.selectedThing}
          <Icon icon="unfold" />
        </Button>
        <Popover
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ overflowY: 'auto' }}
        >
          {this.props.values.map((value) => (
            <ListItem
              button
              key={value}
              onClick={() => this.setState({ selectedThing: value })}
            >
              <ListItemText primary={value} />
            </ListItem>
          ))}
        </Popover>
      </>
    )
  }
}

export default withStyles(styles)(BranchThingsPopover)
