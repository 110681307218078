import React, { Suspense } from 'react'
import Toolbar from '../components/toolbar/Toolbar'
import { getHash } from '../constants/hashGrabber'
import LoadingOverlay from '../components/LoadingOverlay'
import NoAccessPage from './NoAccessPage'
import SideAppBar from '../components/sideAppBar/SideAppBar'
import HashStateManager from '../rx-js/HashStateManager'
import {
  AccountHashFromString,
  AccountHashToString,
  IAccountHash,
  IAccountTabs,
  EAccountTabs,
} from '../constants/hashControllers'
import WidthViewport from '../components/ux/WidthViewport'
import AccountPage from './account/accountPage'
import DevicesPage from './account/devicesPage'
import IntegrationsPage from './account/integrationsPage'
import FieldLabelsPage from './account/fieldLabelsPage'
//TODO: JA: When React implements lazy loading to server rendering we should reimplement this then

const accountTabs = [
  {
    value: EAccountTabs.account,
    label: 'Account',
    icon: 'domain',
  },
  {
    value: EAccountTabs.devices,
    label: 'Devices',
    icon: 'devices',
  },
  {
    value: EAccountTabs.integrations,
    label: 'Integrations',
    icon: 'deviceHub',
  },
  {
    value: EAccountTabs.fieldLabels,
    label: 'Labels',
    icon: 'lowPriority',
  },
]

interface IState {
  selectedTab: string | EAccountTabs
}

interface IProps {}

class Account extends React.Component<IProps, IState> {
  state: IState = {
    selectedTab: EAccountTabs.account,
  }

  hash: HashStateManager<IAccountHash> = new HashStateManager(
    AccountHashFromString,
    AccountHashToString
  )

  setTab = (tab: IAccountTabs) => {
    this.setState({ selectedTab: tab })
    this.hash.set({
      ...this.hash.value,
      tab,
    })
  }

  lazyLoadAccount(selectedTab: string, isMobile: boolean) {
    switch (selectedTab) {
      case EAccountTabs.account:
        return <AccountPage isMobile={isMobile} /> // async issue?
      case EAccountTabs.devices:
        return <DevicesPage isMobile={isMobile} />
      case EAccountTabs.integrations:
        return <IntegrationsPage />
      case EAccountTabs.fieldLabels:
        return <FieldLabelsPage />
      default:
        return <NoAccessPage Error="404 Not Found" />
    }
  }

  componentDidMount() {
    const hashgrabbed = getHash()
      ? (getHash() as { tab?: IAccountTabs })
      : { tab: EAccountTabs.account as IAccountTabs }
    this.setTab(hashgrabbed.tab ? hashgrabbed.tab : EAccountTabs.account)
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <div>
            {isMobile ? null : (
              <Toolbar
                title="Account: Rovitracker Inc."
                noXtraBar
                noBackButton
                noBlueBar
              />
            )}
            <SideAppBar
              selectedTab={this.state.selectedTab}
              tabs={accountTabs}
              onChangeTab={(val: string) => this.setTab(val as IAccountTabs)}
              toolBar={{ single: true }}
            >
              <Suspense
                fallback={<LoadingOverlay loading delayedTime={5000} />} // async issue
              >
                {this.lazyLoadAccount(this.state.selectedTab, isMobile)}
              </Suspense>
            </SideAppBar>
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default Account
