import React from 'react'
import WidthViewport from '../components/ux/WidthViewport'
import ReportBlock from '../components/reportComponents/ReportBlock'
import { Switch, Route } from 'react-router-dom'
import {
  reports,
  IReportCategorys,
  IReportLink,
} from '../components/reportComponents/Reports'

/**
 * This is a bad way to implement account usage, just fast.
 * @param id pass the account id.
 * @list Allowed accounts are:
 * @var 'Brahma Group Inc.' as 278
 * @var 'Redgwick Construction Company' as 500
 * @var 'Liberty Industrial Group' as 354
 * @var 'Rovitracker Inc.' as 160 our testing purposes
 */
export function fuelReportAccess(id: string) {
  return (
    (id === '278' || id === '500' || id === '354' || id === '160') &&
    (true as boolean)
  )
}

/**
 * This is a bad way to implement account usage, just fast.
 * @param id pass the account id.
 * @list Allowed accounts are:
 * @var 'Liberty Industrial Group' as 354
 * @var 'Rovitracker Inc.' as 160 our testing purposes
 */
export function FlaggedSpeedingAccess(id: string) {
  return (id === '354' || id === '160') && (true as boolean)
}

interface IProps {}

class Report extends React.Component<IProps> {
  /**
   * takes reports category array and just takes all the reports and stuff them into
   * one array not broken up like the original array. Makes for everything being rendered
   * @param reportsOrg reports category array - IReportCategorys[]
   * @returns IReportLink[]
   */
  mappingReportLinks(reportsOrg: IReportCategorys[]) {
    let newReportLinks: IReportLink[] = []
    reportsOrg.forEach((report) =>
      report.reports.forEach((rep) => newReportLinks.push(rep))
    )
    return newReportLinks
  }

  render() {
    return (
      <WidthViewport>
        {({ width, height, isMobile }) => (
          <Switch>
            <Route exact path="/reports">
              <ReportBlock isMobile={isMobile} />
            </Route>

            {this.mappingReportLinks(reports).map((link, key) => (
              <Route exact path={link.href} key={key}>
                {link.reportPage
                  ? link.reportPage(width, height - 160, isMobile)
                  : link.href}
              </Route>
            ))}
          </Switch>
        )}
      </WidthViewport>
    )
  }
}

export default Report
