import React from 'react'
import '../../styles/pages/equipment/EquipmentMonitor.scss'
import { CompBar } from '../../components/toolbar/Toolbar'
import ToolbarDivider from '../../components/ux/ToolbarDivider'
import IconButton from '../../components/buttons/IconButton'
import { colors } from '../../components/ux/roviTheme'
import { ERoutes } from '../../components/routes/CheckRoutes'
import { EEquipmentTabs } from '../../constants/equipmentHashControllers'

interface IProps {
  selected: EEquipmentTabs.timeline | EEquipmentTabs.events
  onChangeHash: (val: string) => void
}

interface IState {}

class EquipmentMonitor extends React.Component<IProps, IState> {
  state: IState = {}
  render() {
    return (
      <div className="monitorContainer">
        <CompBar scrollBackgroundColor>
          <div className="monitorTimeline">
            <div>Date</div>
            <div className="monitorToolbarRight">
              <IconButton
                variant="toolbar"
                icon="timeline"
                tooltip="Timeline"
                onClick={() => this.props.onChangeHash(EEquipmentTabs.timeline)}
                style={{
                  color:
                    this.props.selected === EEquipmentTabs.timeline
                      ? colors.primaryBlue
                      : undefined,
                }}
              />
              <IconButton
                variant="toolbar"
                icon="events"
                tooltip="Events List"
                onClick={() => this.props.onChangeHash(EEquipmentTabs.events)}
                style={{
                  color:
                    this.props.selected === EEquipmentTabs.events
                      ? colors.primaryBlue
                      : undefined,
                }}
              />
              <ToolbarDivider />
              <IconButton
                variant="toolbar"
                icon="maptrac"
                tooltip="Maptrac"
                href={ERoutes.maptrac}
              />
            </div>
          </div>
        </CompBar>
        <div className="monitorBody">
          {this.props.selected === EEquipmentTabs.timeline ? (
            <div>timeline</div>
          ) : (
            <div>events</div>
          )}
        </div>
      </div>
    )
  }
}

export default EquipmentMonitor
