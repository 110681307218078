import React from 'react'
import { colors } from '../ux/roviTheme'
import Paper from '@mui/material/Paper'
import Button from '../buttons/Button'
import Icon from '../Icon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'

const playIcon = {
  0: 'pause',
  1: 'playArrow',
  2: 'fastForward',
  3: 'doubleFastForward',
}

const styles: Record<string, React.CSSProperties> = {
  mobileContainer: {
    position: 'absolute',
    top: 7,
    left: 7,
    zIndex: 9,
    transition: '0.33s',
    backgroundColor: colors.wBodyBackgroundColor,
  },
  desktopContainer: {
    position: 'absolute',
    bottom: 24,
    right: 48,
    zIndex: 9,
    transition: '0.33s',
    backgroundColor: colors.wBodyBackgroundColor,
  },
  Button: {
    color: colors.defaultText,
  },
}

interface IProps {
  isMobile: boolean
  playSpeed: number
  switchLiveHistory: () => void
}

interface IState {}

class PlayBackControl extends React.Component<IProps, IState> {
  state = {
    liveAnchorEl: null,
    speedAnchorEl: null,
    position: 0,
  }
  handleSpeedClick = (speed: number) => {
    this.setState({ speedAnchorEl: null })
    // console.log(speed)
  }
  render() {
    return (
      <div
        style={
          this.props.isMobile ? styles.mobileContainer : styles.desktopContainer
        }
      >
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.wBodyBackgroundColor,
          }}
        >
          <div
            style={{
              display: this.props.isMobile ? 'none' : 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px 10px 0px 0px',
            }}
          >
            <Button
              onClick={(ev) =>
                this.setState({ speedAnchorEl: ev.currentTarget })
              }
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Icon
                  //@ts-ignore
                  icon={playIcon[this.props.playSpeed]}
                  style={{ color: colors.iconColor }}
                />
                <Icon
                  icon="arrowDropDown"
                  style={{ color: colors.iconColor }}
                />
              </div>
            </Button>
            {/* <PlaybackSlider
              startOfDayInstant={startOfDayInstant}
              currInstant={timestamp}
              setTimestamp={setTimestamp}
              handleSkipToUpdate={this.handleSkipToUpdate}
              onChangePosition={(_, newPos) =>
                this.setState({ position: newPos })
              }
              position={position}
              timezone={timezone}
            /> */}
          </div>
          {!this.props.isMobile && (
            <Button
              onClick={(ev) =>
                this.setState({ liveAnchorEl: ev.currentTarget })
              }
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Icon icon="history" style={{ color: colors.iconColor }} />
                <Icon
                  icon="arrowDropDown"
                  style={{ color: colors.iconColor }}
                />
              </div>
            </Button>
          )}
          <Menu
            anchorEl={this.state.liveAnchorEl}
            open={!!this.state.liveAnchorEl}
            onClose={() => this.setState({ liveAnchorEl: null })}
          >
            <MenuItem
              style={styles.Button}
              onClick={() => this.props.switchLiveHistory()}
            >
              <ListItemIcon>
                <Icon icon="liveUpdates" style={{ color: colors.iconColor }} />
              </ListItemIcon>
              <Typography>Live</Typography>
            </MenuItem>
            <MenuItem style={styles.Button} disabled>
              <ListItemIcon>
                <Icon icon="history" style={{ color: colors.iconColor }} />
              </ListItemIcon>
              <Typography>History</Typography>
            </MenuItem>
          </Menu>
          <Menu
            anchorEl={this.state.speedAnchorEl}
            open={!!this.state.speedAnchorEl}
            onClose={() => this.setState({ speedAnchorEl: null })}
          >
            <MenuItem
              style={styles.Button}
              onClick={() => this.handleSpeedClick(0)}
              disabled={this.props.playSpeed === 0}
            >
              <ListItemIcon>
                <Icon icon="pause" style={{ color: colors.iconColor }} />
              </ListItemIcon>
              <Typography>Pause</Typography>
            </MenuItem>
            <MenuItem
              style={styles.Button}
              onClick={() => this.handleSpeedClick(1)}
              disabled={this.props.playSpeed === 1}
            >
              <ListItemIcon>
                <Icon icon="playArrow" style={{ color: colors.iconColor }} />
              </ListItemIcon>
              <Typography>1x Speed</Typography>
            </MenuItem>
            <MenuItem
              style={styles.Button}
              onClick={() => this.handleSpeedClick(2)}
              disabled={this.props.playSpeed === 2}
            >
              <ListItemIcon>
                <Icon icon="fastForward" style={{ color: colors.iconColor }} />
              </ListItemIcon>
              <Typography>10x Speed</Typography>
            </MenuItem>
            <MenuItem
              style={styles.Button}
              onClick={() => this.handleSpeedClick(3)}
              disabled={this.props.playSpeed === 3}
            >
              <ListItemIcon>
                <Icon
                  icon="doubleFastForward"
                  style={{ color: colors.iconColor }}
                />
              </ListItemIcon>
              <Typography>100x Speed</Typography>
            </MenuItem>
          </Menu>
          {/* <DatePickerFlat
            date={atZone(timestamp, timezone).date}
            onChange={this.handleDateChange}
            shouldDisableDate={(localDate) => {
              const todayDate = LocalDate.today(timezone)
              return localDate.isAfter(todayDate)
            }}
          /> */}
        </Paper>
      </div>
    )
  }
}

export default PlayBackControl
