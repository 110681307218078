import React from 'react'
import IntegrationDialog from './IntegrationDialog'

interface IPointOfRentalIntegrationDialogProps {
  open: boolean
  logo?: string
  onAuthorize: () => void
  onClose: () => void
}

const PointOfRentalIntegrationDialog = (
  props: IPointOfRentalIntegrationDialogProps
) => {
  return (
    <IntegrationDialog
      open={props.open}
      logo={props.logo}
      title="Authorize Integration"
      installNotes="By authorising this integration, you will be allowing access to your Rovitracker data from a 3rd party."
      closeButtonLabel="Cancel"
      saveButtonLabel="Authorize"
      onClose={props.onClose}
      onSave={props.onAuthorize}
    />
  )
}

export default PointOfRentalIntegrationDialog
