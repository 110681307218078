import React from 'react'
import '../styles/pages/NoAccessPage.scss'
import ReactHelmet from '../components/ReactHelmet'

interface IProps {
  Error: string
}

class NoAccessPage extends React.Component<IProps> {
  state = {
    open: true,
  }

  render() {
    const props = this.props
    return (
      <div className="noAccessPage">
        <ReactHelmet title={props.Error} />
        <div className="noAccessPageCenter">
          <img
            src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-440x280.png"
            alt="Rovitracker Logo 440x280"
          />
          <div className="noAccessPageText">
            <div className="noAccessPageTitle">
              {props.Error ? props.Error : 'Access Forbidden'}
            </div>
            {!props.Error && (
              <>
                <div className="noAccessPageP">
                  Sorry [USER_NAME], your account does not have access to the
                  [FEATURE] feature.
                </div>
                <div className="noAccessPageP">
                  Talk to your administrator if you need access.
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default NoAccessPage
