enum TemperatureType {}

type Temperature = number & TemperatureType

namespace Temperature {
  export const ofCelsius = (v: number): Temperature => v as Temperature
  export const ofFahrenheit = (v: number) =>
    Temperature.ofCelsius((v - 32) / 1.8)

  export const toCelsius = (v: Temperature) => v as number
  export const toFahrenheit = (v: Temperature) =>
    Temperature.toCelsius(v) * 1.8 + 32

  export enum Unit {
    Celsius = '°C',
    Fahrenheit = '°F'
  }

  const conversionLookupTo: { [key in Unit]: (val: Temperature) => number } = {
    [Unit.Celsius]: toCelsius,
    [Unit.Fahrenheit]: toFahrenheit
  }

  const conversionLookupFrom: {
    [key in Unit]: (val: number) => Temperature
  } = {
    [Unit.Celsius]: ofCelsius,
    [Unit.Fahrenheit]: ofFahrenheit
  }

  export const format = (val: Temperature, unit: Unit = Unit.Celsius) =>
    `${Math.round(conversionLookupTo[unit](val))}${unit}`

  export const convertToUnit = (val: Temperature, unit: Unit) =>
    conversionLookupTo[unit](val)
  export const convertFromUnit = (val: number, unit: Unit) =>
    conversionLookupFrom[unit](val)
}

export default Temperature
