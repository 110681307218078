import React from 'react'
import ReactHelmet from '../components/ReactHelmet'
import LiveMaptracContainer from '../components/maptrac/maptracLive/LiveMaptracContainer'
import HistoryMaptracContainer from '../components/maptrac/maptracHistory/HistoryMaptracContainer'
import PlayBackControl from '../components/maptrac/PlayBackControl'
import ModeButton from '../components/maptrac/ModeButton'
import WidthViewport from '../components/ux/WidthViewport'

interface IProps {}

interface IState {
  isMaptracLive: boolean
}

class MaptracPage extends React.Component<IProps, IState> {
  state: IState = {
    isMaptracLive: true,
  }

  switchLiveHistory(switchLiveHistory: boolean) {
    this.setState({ isMaptracLive: switchLiveHistory })
  }

  componentDidMount() {}

  render() {
    return (
      <WidthViewport>
        {({ height, width, isMobile }) => (
          <div>
            <ReactHelmet title="Maptrac" />
            <div>
              {this.state.isMaptracLive ? (
                /**
                 * Everything in this section is Maptrac Live Components
                 */
                <>
                  <ModeButton
                    timezone=""
                    onChangeDate={(val) => console.log(val)}
                    isMobile={isMobile}
                    switchLiveHistory={() => this.switchLiveHistory(false)}
                  />
                  <LiveMaptracContainer
                    isMobile={isMobile}
                    height={height}
                    width={width}
                  />
                </>
              ) : (
                /**
                 * Everything in this section is Maptrac History Components
                 */
                <>
                  <PlayBackControl
                    isMobile={isMobile}
                    playSpeed={0}
                    switchLiveHistory={() => this.switchLiveHistory(true)}
                  />
                  <HistoryMaptracContainer
                    isMobile={isMobile}
                    height={height}
                    width={width}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default MaptracPage
