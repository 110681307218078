import React from 'react'
import './FilterMaptrac.scss'
import {
  Paper,
  Button,
  Divider,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import Icon from '../../Icon'
import FilterChipMaptrac from './FilterChipMaptrac'
import FilterCheckBox from './FilterCheckBox'
import { ICategory } from '../../../types/category'
import { IAssetList } from '../../../types/maptrac/Assets'
import EllipsisText from '../../ux/EllipsisText'
import { colors } from '../../ux/roviTheme'

interface IMaintenanceInner {
  value: number
  filter: boolean
}

export interface IMaintenance {
  red: IMaintenanceInner
  orange: IMaintenanceInner
  blue: IMaintenanceInner
}

/**
 * switches the code name red orange blue, to high medium low for display
 * @param value
 */
const maintenaceLevel = (value: keyof IMaintenance): string => {
  switch (value) {
    case 'red':
      return 'High'
    case 'orange':
      return 'Medium'
    case 'blue':
      return 'Low'
    default:
      return ''
  }
}

/**
 * This preps the selected maintenance to be stored in the hash of the URL
 */
export const maintenanceHashEncrypter = (maintenance: IMaintenance) => {
  let newArr = [] as string[]
  Object.keys(maintenance).forEach((name?: keyof IMaintenance | string) => {
    console.log({
      name: name,
      filter: maintenance[name as keyof IMaintenance].filter,
    })
    if (!maintenance[name as keyof IMaintenance].filter) {
      newArr.push(name as keyof IMaintenance)
    }
  })
  return newArr.toString()
}

/**
 * This takes the stored hash and converts it to a useable value
 */
export const maintenanceHashDecrypter = (
  maintenace: IMaintenance,
  hashString: string
) => {
  let splitString = hashString.split('%2C')
  return {
    red: {
      value: maintenace.red.value,
      filter: splitString.includes('red') ? false : true,
    },
    orange: {
      value: maintenace.orange.value,
      filter: splitString.includes('orange') ? false : true,
    },
    blue: {
      value: maintenace.blue.value,
      filter: splitString.includes('blue') ? false : true,
    },
  } as IMaintenance
}

/**
 * pass it the string from the hash that uses , as that code is also %2C
 * @param val "example%2Cexample"
 * @returns ["example", "example"]
 */
export const splitToArr = (val: string) => {
  return val.split('%2C')
}

interface IProps {
  isMobile: boolean
  BLETagsShown: boolean
  BLETagsToggle: (bool: boolean) => void
  maintenance: IMaintenance
  changeMaintenance: (newMaintenance: IMaintenance) => void
  clearHash: () => void
  categoriesFiltered: string[]
  updateCategoriesFiltered: (categoriesFiltered: string[]) => void
  categoriesList: ICategory[]
  assets: IAssetList[]
}

interface IState {
  filterBarOpen: boolean
  // This is here to prevent the animation from playing when it first enters the page
  filterBarLoaded?: boolean
  maintenanceOpen: boolean
  categoriesOpen: boolean
  categoriesAllFilter: boolean
}

export default class FilterMaptrac extends React.Component<IProps> {
  state: IState = {
    filterBarOpen: false,
    filterBarLoaded: undefined,
    maintenanceOpen: false,
    categoriesOpen: false,
    categoriesAllFilter: true,
  }

  clearFilter() {
    this.props.BLETagsToggle(false)
    this.props.changeMaintenance({
      red: {
        value: this.props.maintenance.red.value,
        filter: true,
      },
      orange: {
        value: this.props.maintenance.orange.value,
        filter: true,
      },
      blue: {
        value: this.props.maintenance.blue.value,
        filter: true,
      },
    })
    this.updateAllCategories(true)
    this.props.clearHash()
  }

  filterClosed() {
    this.setState({ categoriesOpen: false, maintenanceOpen: false })
  }

  filterOpened() {
    this.setState({
      filterBarOpen: !this.state.filterBarOpen,
      filterBarLoaded: true,
    })
    if (this.state.filterBarOpen) {
      this.filterClosed()
    }
  }

  categoriesClicked() {
    this.setState({
      categoriesOpen: !this.state.categoriesOpen,
      maintenanceOpen: false,
    })
  }

  maintenanceClicked() {
    this.setState({
      maintenanceOpen: !this.state.maintenanceOpen,
      categoriesOpen: false,
    })
  }

  maintenanceShowing() {
    let priority = [] as string[]
    const keys = Object.keys(this.props.maintenance)
    const Arr = Object.values(this.props.maintenance) as IMaintenanceInner[]
    Arr.forEach((task: IMaintenanceInner, index) =>
      task.filter
        ? priority.push(maintenaceLevel(keys[index] as keyof IMaintenance))
        : undefined
    )
    return priority
  }

  changeBLETags(showing: boolean) {
    this.props.BLETagsToggle(showing)
  }

  updateAllCategories(bool: boolean) {
    const newArr: string[] = []
    this.props.categoriesList.forEach((c) => newArr.push(c.id))
    this.props.updateCategoriesFiltered(bool ? newArr : [])
    this.setState({ categoriesAllFilter: bool })
  }

  updateCategory(bool: boolean, id: string) {
    let newArr = this.props.categoriesFiltered
    bool
      ? newArr.splice(this.props.categoriesFiltered.indexOf(id), 1)
      : newArr.push(id)
    this.props.updateCategoriesFiltered(newArr)
    this.props.categoriesList.length === newArr.length
      ? this.setState({ categoriesAllFilter: true })
      : this.setState({ categoriesAllFilter: false })
  }

  sortCategoryNames(category: ICategory[], filteredList: string[]) {
    let newList: string[] = []
    filteredList.forEach((f) => {
      let n = category.find((c) => c.id === f)
      if (n) {
        newList.push(n.name)
      }
    })
    return newList
  }

  render() {
    const maintenaceChipOpen =
      !this.props.maintenance.red.filter ||
      !this.props.maintenance.orange.filter ||
      !this.props.maintenance.blue.filter
    const categoriesChipClosed =
      this.props.categoriesList.length === this.props.categoriesFiltered.length
    const bleTagsChipOpen = this.props.BLETagsShown
    /** returns true if any chips are loaded in */
    const anyChipOpen =
      maintenaceChipOpen || !categoriesChipClosed || bleTagsChipOpen
    /** This component will hold open the filter bar, when using it if a chip is in it */
    const filterBarOpen = anyChipOpen || this.state.filterBarOpen
    return (
      <>
        <Paper
          style={{
            position: 'absolute',
            right: this.props.isMobile ? 40 : 174,
            top: this.props.isMobile ? 37 : 0,
            height: this.props.isMobile ? 35 : 28,
            margin: 7,
            backgroundColor: colors.bg,
            width: filterBarOpen ? '100%' : 32,
            // Changing the max width will determine the length of the filter box
            maxWidth: this.props.isMobile ? '70vw' : 350,
            display: 'flex',
            flexDirection: 'row-reverse',
            overflow: 'hidden',
          }}
          // This className is mostly for the animation Styles
          className={
            filterBarOpen
              ? 'expand'
              : this.state.filterBarLoaded
              ? 'reduce'
              : undefined
          }
        >
          <Button
            variant="contained"
            style={{
              height: this.props.isMobile ? 35 : 28,
              maxWidth: 25,
              minWidth: 0,
              backgroundColor: colors.bg,
              marginLeft: 5,
            }}
            onClick={() => this.filterOpened()}
          >
            <Icon icon={this.state.filterBarOpen ? 'close' : 'filter'} />
          </Button>
          <div
            style={{
              display: 'flex',
              overflowX: 'auto',
              overflowY: 'hidden',
              flexWrap: 'nowrap',
              height: 40,
            }}
          >
            {bleTagsChipOpen ? (
              <FilterChipMaptrac
                chipName="Tags Show"
                isMobile={this.props.isMobile}
                onClick={() => this.changeBLETags(!this.props.BLETagsShown)}
              />
            ) : null}
            {maintenaceChipOpen ? (
              <FilterChipMaptrac
                chipName={`Task Level: ${this.maintenanceShowing()}`}
                isMobile={this.props.isMobile}
                onClick={() => {
                  this.props.changeMaintenance({
                    red: {
                      value: this.props.maintenance.red.value,
                      filter: true,
                    },
                    orange: {
                      value: this.props.maintenance.orange.value,
                      filter: true,
                    },
                    blue: {
                      value: this.props.maintenance.blue.value,
                      filter: true,
                    },
                  })
                }}
              />
            ) : null}
            {categoriesChipClosed ? null : (
              <FilterChipMaptrac
                chipName={
                  <EllipsisText
                    text={`Categories: ${this.sortCategoryNames(
                      this.props.categoriesList,
                      this.props.categoriesFiltered
                    ).toString()}`}
                    width={180}
                  />
                }
                isMobile={this.props.isMobile}
                onClick={() => this.updateAllCategories(true)}
              />
            )}
          </div>
        </Paper>

        {this.state.filterBarOpen ? (
          <Paper
            style={{
              width: 250,
              height: '100%',
              maxHeight: 350,
              position: 'absolute',
              right: this.props.isMobile ? 47 : 181,
              top: this.props.isMobile ? 84 : 41,
              overflowX: 'hidden',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              padding: 10,
            }}
            className="containerExpand"
          >
            <Button
              style={{ height: 48, borderRadius: 0 }}
              onClick={() => this.clearFilter()}
            >
              Clear filter
            </Button>
            <Divider />
            <Accordion>
              <AccordionSummary
                expandIcon={<Icon icon="expandMore" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>BLE Tags</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FilterCheckBox
                  label="Show BLE Tags"
                  checked={this.props.BLETagsShown}
                  onChange={(bool) => this.props.BLETagsToggle(bool)}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<Icon icon="expandMore" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="subtitle1">Maintenance Level</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {/* The color is red */}
                <FilterCheckBox
                  label={`95% - 100% (${this.props.maintenance.red.value})`}
                  color="#FF0000"
                  checked={this.props.maintenance.red.filter}
                  onChange={(bool) =>
                    this.props.changeMaintenance({
                      ...this.props.maintenance,
                      red: {
                        value: this.props.maintenance.red.value,
                        filter: bool,
                      },
                    })
                  }
                />
                {/* The color is orange */}
                <FilterCheckBox
                  label={`85% - 94% (${this.props.maintenance.orange.value})`}
                  color="#FFA500"
                  checked={this.props.maintenance.orange.filter}
                  onChange={(bool) =>
                    this.props.changeMaintenance({
                      ...this.props.maintenance,
                      orange: {
                        value: this.props.maintenance.orange.value,
                        filter: bool,
                      },
                    })
                  }
                />
                {/* The color is blue */}
                <FilterCheckBox
                  label={`0% - 84% (${this.props.maintenance.blue.value})`}
                  color="#0000ff"
                  checked={this.props.maintenance.blue.filter}
                  onChange={(bool) =>
                    this.props.changeMaintenance({
                      ...this.props.maintenance,
                      blue: {
                        value: this.props.maintenance.blue.value,
                        filter: bool,
                      },
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<Icon icon="expandMore" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Categories</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <FilterCheckBox
                    label="Select All"
                    checked={this.state.categoriesAllFilter}
                    onChange={(bool) => this.updateAllCategories(bool)}
                  />
                  {this.props.categoriesList.map((category) => (
                    <FilterCheckBox
                      key={category.id}
                      label={
                        category.name +
                        ` (${
                          this.props.assets.find(
                            (a) => a.category.id === category.id
                          )?.equipment.length
                        })`
                      }
                      color={category.color}
                      checked={
                        !!this.props.categoriesFiltered.find(
                          (c) => c === category.id
                        )
                      }
                      onChange={(bool) =>
                        this.updateCategory(!bool, category.id)
                      }
                    />
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </Paper>
        ) : null}
      </>
    )
  }
}
