import React from 'react'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import { ERoutes, PageTitle } from '../components/routes/CheckRoutes'
import { colors } from '../components/ux/roviTheme'
import AddonCard from './account/AddonCard'
import Button from '../components/buttons/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import { backend_api, headersAPI } from '../constants/api'
import LoadingOverlay from '../components/LoadingOverlay'
import WidthViewport from '../components/ux/WidthViewport'

const styles: Record<string, React.CSSProperties> = {
  cardContainerDesktop: {
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    padding: 20,
    flexWrap: 'wrap',
    height: 'calc(100vh - 64px)',
    paddingTop: 96,
  },
  cardContainerMobile: {
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    padding: 20,
    flexWrap: 'wrap',
    height: '100%',
    paddingTop: 68,
  },
  subscriptionCardContainer: {
    position: 'relative',
    background: colors.mbg,
    height: 450,
    width: 280,
    minWidth: 250,
    margin: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

const planButtonStyle = (plan: any, currentPlan: any, plans: any[]) => {
  const indexPlan = plans.findIndex((obj) => obj.planId === plan.planId)
  const indexCurrentPlan = plans.findIndex(
    (obj) => obj.planId === currentPlan.plan.planId
  )
  if (indexPlan === indexCurrentPlan) {
    return {
      text: 'Current Plan',
      color: colors.accentDarkGrey,
      disabled: true,
    }
  } else if (indexPlan > indexCurrentPlan) {
    return {
      text: 'Upgrade to this plan',
      color: colors.primaryBlue,
      disabled: false,
    }
  } else {
    return {
      text: 'Downgrade to this plan',
      color: colors.primaryBlue,
      disabled: false,
    }
  }
}

interface ISubscriptionCardProps {
  plan: {
    planId: number
    planName: string
    planLabel: string
    planDescription: string
    planPrice: number
    planShown: boolean
    planPopular: boolean
  }
  currentPlan: any
  plans: Array<{
    planId: number
    planName: string
    planLabel: string
    planDescription: string
    planPrice: number
    planShown: boolean
    planPopular: boolean
  }>
}
const SubscriptionCard: React.FunctionComponent<ISubscriptionCardProps> = ({
  plan,
  currentPlan,
  plans,
}) => {
  const buttonStyle = planButtonStyle(plan, currentPlan, plans)
  return (
    <div style={styles.subscriptionCardContainer}>
      {plan.planId === 7 && (
        <img
          src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/marketPlace/mostpopular.svg"
          style={{ position: 'absolute', top: -5, left: -5 }}
          alt="mostpopular"
        />
      )}
      <div>
        <div
          style={{
            fontSize: 20,
            margin: 30,
          }}
        >
          {plan.planName} Plan
        </div>
        <div
          style={{
            textAlign: 'center',
            color: colors.primaryBlue,
            fontSize: 26,
            fontWeight: 'bold',
          }}
        >
          ${plan.planPrice}
          <span style={{ fontWeight: 'normal' }}>/month</span>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <div
          style={{
            textAlign: 'center',
            color: colors.accentDarkGrey,
            lineHeight: 2.5,
            margin: 20,
          }}
        >
          {plan.planDescription}
        </div>
      </div>
      <Button
        style={{
          height: 50,
          width: '100%',
          backgroundColor: buttonStyle.color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
        }}
        disabled={buttonStyle.disabled}
      >
        {buttonStyle.text}
      </Button>
    </div>
  )
}

interface IProps {}

interface IState {
  accountPlan: any | null
  plans: Array<{
    planId: number
    planName: string
    planLabel: string
    planDescription: string
    planPrice: number
    planShown: boolean
    planPopular: boolean
  }>
  addons: Array<{
    addonName: string
    addonDescription: string
    addonPrice: number
    addonLabel: string
  }>
  openAddonDialog: boolean
  selectedAddonLabel: string
  openSnackbar: boolean
  loadingCurrentPlan: boolean
  loadingPlans: boolean
  loadingAddons: boolean
}

class MarketPlace extends React.Component<IProps, IState> {
  state: IState = {
    accountPlan: null,
    plans: [],
    addons: [],
    openAddonDialog: false,
    selectedAddonLabel: 'selectedAddonLabel',
    openSnackbar: false,
    loadingCurrentPlan: true,
    loadingPlans: true,
    loadingAddons: true,
  }

  handleRequestAddon = (addonLabel: any) => {
    this.setState({ openAddonDialog: false, openSnackbar: true })
  }

  handleClickedAddon = (addonLabel: any) => {
    this.setState({
      selectedAddonLabel: addonLabel,
      openAddonDialog: true,
    })
  }

  CheckAccountPlans() {
    fetch(backend_api + '/marketPlace/currentPlan', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          accountPlan: response,
          loadingCurrentPlan: false,
        })
      })
      .catch((err) => console.error(err))
  }

  grabAddons() {
    fetch(backend_api + '/marketPlace/addon', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          addons: response,
          loadingAddons: false,
        })
      })
      .catch((err) => console.error(err))
  }

  grabPlans() {
    fetch(backend_api + '/marketPlace/plan', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          plans: response,
          loadingPlans: false,
        })
      })
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    this.grabPlans()
    this.grabAddons()
    this.CheckAccountPlans()
  }

  render() {
    const state = this.state
    const MarketLoading =
      state.loadingAddons || state.loadingCurrentPlan || state.loadingPlans
    const accountAddOns = state.accountPlan ? state.accountPlan.addons : []
    const splitAddOns = state.addons.reduce((total: any, _, i: any) => {
      return i % 2 === 0 ? [...total, state.addons.slice(i, i + 2)] : total
    }, [])
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <div>
            <ReactHelmet title={PageTitle(ERoutes.marketPlace)} />
            <Toolbar title={PageTitle(ERoutes.marketPlace)} noXtraBar></Toolbar>
            {MarketLoading ? (
              <LoadingOverlay loading />
            ) : (
              <div
                style={
                  isMobile
                    ? styles.cardContainerMobile
                    : styles.cardContainerDesktop
                }
              >
                {state.plans.map(
                  (plan, i) =>
                    (plan.planId === 11 || plan.planId === 7) &&
                    state.accountPlan && (
                      <SubscriptionCard
                        key={i}
                        plan={plan}
                        currentPlan={state.accountPlan}
                        plans={state.plans}
                      />
                    )
                )}
                {splitAddOns.map((arrayOfTwo: any, key: any) => (
                  <div
                    key={key}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: 360,
                      minWidth: 280,
                      height: 450,
                      margin: 8,
                      justifyContent: 'space-between',
                    }}
                  >
                    {arrayOfTwo.map((addOn: any, i: any) => (
                      <AddonCard
                        key={i}
                        addOn={addOn}
                        added={
                          !!(
                            accountAddOns &&
                            accountAddOns.includes(addOn.addonName)
                          )
                        }
                        onClickAddon={this.handleClickedAddon}
                      />
                    ))}
                  </div>
                ))}
              </div>
            )}

            <Dialog
              open={state.openAddonDialog}
              onClose={() => this.setState({ openAddonDialog: false })}
            >
              <DialogTitle>{state.selectedAddonLabel} Add-on</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Request more information and a Rovitracker representative will
                  contact you shortly.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ openAddonDialog: false })}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  onClick={() =>
                    this.handleRequestAddon(state.selectedAddonLabel)
                  }
                >
                  Request More Info
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              open={state.openSnackbar}
              message="Request Sent"
              autoHideDuration={4000}
              onClose={() => this.setState({ openSnackbar: false })}
            />
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default MarketPlace
