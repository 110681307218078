import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '../../buttons/IconButton'
import { IPart } from '../../../types/service/parts'
import PartDialog from './PartDialog'
import DeletePartDialog from './DeletePartDialog'
import { backend_api, headersAPI } from '../../../constants/api'
import { LoadingIcon } from '../../LoadingOverlay'

interface IState {
  partsList: IPart[]
  editDialogOpen: boolean
  deleteDialogOpen: boolean
  selectedPart?: IPart
  partsLoading: boolean
}

interface IProps {
  isMobile?: boolean
  addDialogOpen: boolean
  onClose: () => void
}

class PartsPage extends React.Component<IProps, IState> {
  state: IState = {
    partsList: [],
    editDialogOpen: false,
    deleteDialogOpen: false,
    selectedPart: undefined,
    partsLoading: true,
  }

  grabParts() {
    fetch(backend_api + '/service/parts/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ partsList: response, partsLoading: false })
      })
      .catch((err) => console.log(err))
  }

  checkPrice(price?: number) {
    let endPrice = '0.00'
    return '$' + (price ? (price > 0 ? price : endPrice) : endPrice)
  }

  componentDidMount() {
    this.grabParts()
  }

  render() {
    return (
      <>
        <Paper
          style={{
            marginTop: 16,
            width: '100%',
            overflowX: 'auto',
            marginBottom: this.props.isMobile ? 120 : undefined,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Retail</TableCell>
                <TableCell>Rental Rate</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.partsList.map((part, key) => (
                <TableRow key={key} hover>
                  <TableCell>{part.number}</TableCell>
                  <TableCell>{part.vendor}</TableCell>
                  <TableCell>{part.manufacturer}</TableCell>
                  <TableCell>{part.description}</TableCell>
                  <TableCell>{part.category.name}</TableCell>
                  <TableCell align="right">
                    {this.checkPrice(part.cost)}
                  </TableCell>
                  <TableCell align="right">
                    {this.checkPrice(part.retail)}
                  </TableCell>
                  <TableCell align="right">
                    {this.checkPrice(part.rentalRate)}
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ width: 'max-content' }}>
                      <IconButton
                        icon="edit"
                        variant="row"
                        onClick={() =>
                          this.setState({
                            editDialogOpen: true,
                            selectedPart: part,
                          })
                        }
                      />
                      <IconButton
                        icon="delete"
                        variant="row"
                        onClick={() =>
                          this.setState({
                            deleteDialogOpen: true,
                            selectedPart: part,
                          })
                        }
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {this.state.partsList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: this.props.isMobile
                          ? 'flex-start'
                          : 'center',
                        width: '100%',
                      }}
                    >
                      {this.state.partsLoading ? (
                        <LoadingIcon loading />
                      ) : (
                        'No Parts are Created'
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Paper>
        <PartDialog
          isMobile={this.props.isMobile}
          type="add"
          open={this.props.addDialogOpen}
          onClose={() => this.props.onClose()}
        />
        <PartDialog
          part={this.state.selectedPart}
          isMobile={this.props.isMobile}
          type="edit"
          open={this.state.editDialogOpen}
          onClose={() => this.setState({ editDialogOpen: false })}
        />
        <DeletePartDialog
          part={this.state.selectedPart}
          open={this.state.deleteDialogOpen}
          onClose={() => this.setState({ deleteDialogOpen: false })}
          onDelete={(partId) => console.log(partId)}
        />
      </>
    )
  }
}

export default PartsPage
