import React from 'react'
import { AssetMarkerEquipOverlay } from '../AssetMarker'
import Geofence from '../Geofence'
import SiteMarker from '../SiteMarker'
import { ISite } from '../../../types/maptrac/Sites'
import { ERadiusType, TRadiusType } from '../GeofenceDrawingManager'
import FullTags from './maptracLiveTags/FullTags'
import { ILatLng } from '../../../types/maptrac/LatLng'
import { IGeofenceMarker } from '../../../types/maptrac/Geofences'
import { IAsset, IAssetClicked } from '../../../types/maptrac/Assets'
import { ITag } from '../../../types/maptrac/Tags'

interface IProps {
  isMobile: boolean
  assetClicked: (val: IAssetClicked) => void
  geofenceClicked: (
    geofenceId: string,
    latlng: google.maps.LatLng[] | ILatLng[],
    geofenceType: TRadiusType,
    radius: number
  ) => void
  BLETagsShown: boolean
  assetMarkers: IAsset[]
  geofenceMarkers: IGeofenceMarker[]
  siteMarkers: ISite[]
  siteClicked: (siteId: string, gps: ILatLng) => void
  tagMarkers: Array<ITag[]>
}

interface IState {
  assetMarkers: IAsset[]
}

class LiveMaptracMarkers extends React.Component<IProps, IState> {
  state: IState = {
    assetMarkers: [],
  }

  render() {
    return (
      <>
        {this.props.assetMarkers &&
          this.props.assetMarkers.map((asset: IAsset, key) => (
            <AssetMarkerEquipOverlay
              key={key}
              useMetric={false}
              onClick={() =>
                this.props.assetClicked({ assetId: asset.id, gps: asset.gps })
              }
              category={
                asset.category
                  ? {
                      color: asset.category.color,
                      icon: asset.category.icon,
                    }
                  : undefined
              }
              name={asset.name}
              speed={asset.speed}
              ign={asset.ignition}
              heading={asset.heading}
              lat={asset.gps.lat}
              lng={asset.gps.lng}
              isTransparent={asset.markerIsTransparent}
              isHighlighted={asset.markerIsHighlighted}
              tasks={{
                equipId: '123',
                color: '#0078FF',
                onMouseEnter: (
                  ev: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => console.log(ev),
                onMouseLeave: (
                  ev: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => console.log(ev),
              }}
            />
          ))}
        {this.props.geofenceMarkers &&
          this.props.geofenceMarkers.map((geofence: IGeofenceMarker, key) => (
            <Geofence
              key={key}
              points={geofence.points}
              radius={geofence.radius}
              onEditPoints={(p) => console.log(p)}
              type={geofence.type}
              onClick={(paths) => {
                // console.log(geofence.points)
                this.props.geofenceClicked(
                  geofence.geofenceId,
                  paths,
                  geofence.type ? geofence.type : ERadiusType.Polygon,
                  geofence.radius ? geofence.radius : 0
                )
              }}
            />
          ))}

        {this.props.siteMarkers &&
          this.props.siteMarkers.map((site: ISite, key) => (
            <SiteMarker
              key={key}
              latLng={site.siteGps}
              name={site.siteName}
              onClick={() => this.props.siteClicked(site.siteId, site.siteGps)}
            />
          ))}

        {this.props.BLETagsShown ? (
          this.props.tagMarkers.length > 0 ? (
            <FullTags
              totalTags={this.props.tagMarkers}
              onClick={this.props.assetClicked}
            />
          ) : null
        ) : null}
      </>
    )
  }
}

export default LiveMaptracMarkers
