import React from 'react'
import Popover from '@mui/material/Popover'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Flag from './Flag'

const styles = createStyles({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 6,
    maxWidth: 200,
  },
  flag: {
    margin: 1,
  },
})

interface IProps extends WithStyles<typeof styles> {
  anchorEl?: HTMLElement | null
  onClose: () => void
  flags: Array<{ flagId: string; flagName: string }>
}

const FlagPopover: React.FunctionComponent<IProps> = (props) => (
  <Popover
    className={props.classes.popover}
    classes={{
      paper: props.classes.paper,
    }}
    open={Boolean(props.anchorEl)}
    anchorEl={props.anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    onClose={props.onClose}
    disableRestoreFocus
  >
    {props.flags.map((flag) => (
      <Flag
        key={flag.flagId}
        flagId={flag.flagId}
        name={flag.flagName}
        className={props.classes.flag}
      />
    ))}
  </Popover>
)

export default withStyles(styles)(FlagPopover)
