import React from 'react'
import { CirclePicker } from 'react-color'
import stdColors from '../../constants/categoryColors'
import Popover from '@mui/material/Popover'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'

/*
 Color picker that wraps CirclePicker from react-color.
 Shows a colored button that you click on to get the CirclePicker from 'react-color'.

 This component always handles colors as if they are valid CSS color attributes.
 Unfortunately the rest of the app handles them as values like 'ff00aa' with no "#" prefix.

 I refuse to allow such madness inside this pristine component, which always assumes the
 colors are valid CSS values like "#FF0000" or "red".

 Adding and removing the prefix to hex values must be handled by client code's supplying
 appropriate props for "color" and "onColorUpdate", e.g.:

 <ChildproofColorPicker color={'#' + this.state.someStateVariable}
  onColorUpdate = {
    (color)=> {
      this.setState({someStateVariable: (color.slice(1)});
    }
  } />
  See /app/assets/pages/categories.jsx.
 */

export const DEFAULT_COLOR = stdColors[0]

const styles = (theme: Theme) =>
  createStyles({
    swatch: {
      height: 36,
      width: 36,
      borderRadius: '50%',
    },
    swatchContainer: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '50%',
      cursor: 'pointer',
      height: 46,
      width: 46,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 5,
    },
    paper: {
      padding: 15,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  onChange: (color: string) => void
  color: string
  id?: string
  isMobile?: boolean
}

class ColorPicker extends React.Component<IProps> {
  state = {
    anchorEl: null,
  }

  handleClick = (ev: any) => {
    this.setState({ anchorEl: ev.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleChange = (color: any) => {
    this.props.onChange(color.hex)
    this.handleClose()
  }

  render() {
    return (
      <>
        <div
          id={this.props.id}
          className={this.props.classes.swatchContainer}
          onClick={this.handleClick}
        >
          <div
            className={this.props.classes.swatch}
            style={{ background: this.props.color }}
          />
        </div>
        <Popover
          classes={{
            paper: this.props.classes.paper,
          }}
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handleClose}
          disableRestoreFocus
        >
          <CirclePicker
            width={this.props.isMobile ? '252px' : '300px'}
            color={this.props.color}
            colors={stdColors}
            onChange={this.handleChange}
          />
        </Popover>
      </>
    )
  }
}

export default withStyles(styles)(ColorPicker)
