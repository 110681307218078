import React from 'react'
import '../../styles/pages/Inventory.scss'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Fab from '@mui/material/Fab'
import { BottomNav } from '../menus/NavBar'
import { BottomTab } from '../menus/Tab'
import { ITabs } from './sideBarTypes'
import Icon from '../Icon'

const DarkTheme = createTheme({
  palette: {
    action: {
      active: '#fff',
    },
  },
})

interface IState {}

interface IProps {
  tabs: Array<ITabs>
  selectedTab: string
  onChangeTab: (val: string) => void
  addFab?: boolean
  FabDisabled?: boolean
  onClick?: () => void
}

class MobileInventoryMenu extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ overflowX: 'hidden', maxWidth: '100vw' }}>
          {this.props.children}
        </div>
        <BottomNav>
          {this.props.tabs.map((tab) => (
            <BottomTab
              key={tab.value}
              label={tab.label}
              icon={tab.icon as any}
              selected={this.props.selectedTab === tab.value}
              onClick={() => this.props.onChangeTab(tab.value)}
            />
          ))}
        </BottomNav>
        {this.props.addFab && !this.props.FabDisabled && (
          <Fab color="primary" className="fab" onClick={this.props.onClick}>
            <ThemeProvider theme={DarkTheme}>
              <Icon icon="add" />
            </ThemeProvider>
          </Fab>
        )}
      </div>
    )
  }
}

export default MobileInventoryMenu
