import React from 'react'
import '../../styles/components/reportsComponents/reportBlock.scss'
import ReportCard from './ReportCard'
import ReportCategoryTitle from './ReportCategoryTitle'
import { reports, IReportLink, IReportCategorys } from './Reports'
import Paper from '@mui/material/Paper'
import { colors } from '../ux/roviTheme'
import SearchBar from '../textBoxs/SearchBar'
import Typography from '@mui/material/Typography'
import ReactHelmet from '../../components/ReactHelmet'
import { ERoutes, PageTitle } from '../../components/routes/CheckRoutes'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const searchBarTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

interface IProps {
  isMobile: boolean
}

interface IState {
  searchBar: string
}

class Report extends React.Component<IProps, IState> {
  state: IState = {
    searchBar: '',
  }

  filterReports(reports: IReportLink[], searchBar: string) {
    let newReport = reports
    newReport = reports.filter((report) =>
      report.title.toLowerCase().includes(searchBar.toLowerCase())
    )
    return newReport
  }

  filterCategoryReports(reports: IReportCategorys[], searchBar: string) {
    let newReport = reports
    newReport = reports.filter((report) =>
      report.reports.find((rep) =>
        rep.title.toLowerCase().includes(searchBar.toLowerCase())
      )
    )
    return newReport
  }

  render() {
    return (
      <div
        style={{
          marginTop: this.props.isMobile ? 48 : undefined,
        }}
      >
        <ReactHelmet title={PageTitle(ERoutes.fleetReport)} />
        <Paper
          className="reportToolBar"
          style={{
            backgroundColor: this.props.isMobile ? colors.mbg : '#0054b2',
            top: this.props.isMobile ? 48 : 64,
            color: this.props.isMobile ? colors.defaultText : '#fff',
          }}
          square
        >
          <Typography
            variant="h5"
            style={{
              marginLeft: this.props.isMobile ? undefined : 15,
              textAlign: 'center',
              color: colors.white,
            }}
          >
            Fleet Reports
          </Typography>
          {this.props.isMobile ? (
            <SearchBar
              value={this.state.searchBar}
              onChange={(newString) => this.setState({ searchBar: newString })}
              className="searchBox"
            />
          ) : (
            <ThemeProvider theme={searchBarTheme}>
              <SearchBar
                value={this.state.searchBar}
                onChange={(newString) =>
                  this.setState({ searchBar: newString })
                }
                className="searchBox"
              />
            </ThemeProvider>
          )}
        </Paper>
        {this.filterCategoryReports(reports, this.state.searchBar).map(
          (category, key) => (
            <Paper
              key={`${key}Category`}
              elevation={1}
              style={{
                margin: 15,
                padding: 15,
                backgroundColor: colors.bg,
              }}
              square
            >
              <ReportCategoryTitle
                title={category.title}
                icon={category.icon}
              />
              <div
                className="reportBlock"
                style={{
                  gridTemplateColumns: this.props.isMobile
                    ? '1fr'
                    : '1fr 1fr 1fr',
                }}
              >
                {this.filterReports(category.reports, this.state.searchBar).map(
                  (report) => (
                    <ReportCard
                      key={`${report.href} ${report.title}`}
                      style={report.style}
                      href={report.href}
                      title={report.title}
                      description={report.description}
                    />
                  )
                )}
              </div>
            </Paper>
          )
        )}
      </div>
    )
  }
}

export default Report
