import { Button, TextField, Typography } from '@mui/material'
import React from 'react'
import { IOnboardingStepProps } from '../Onboarding'

interface IOnboardingSecondStepProps {
  onChangeBranchName: (val: string) => void
  branchName?: string
}

const OnboardingSecondStep = ({
  back,
  next,
  branchName,
  onChangeBranchName,
}: IOnboardingStepProps & IOnboardingSecondStepProps) => {
  const buttonClassName = 'onboarding-action-button'
  return (
    <div className="onboarding-step">
      <Typography variant="h4" fontWeight={700} textAlign="center" marginY={2}>
        Add a branch to your account.
      </Typography>
      <Typography textAlign="center" marginBottom={3}>
        A branch is typically defined as a geographical location or department.
      </Typography>
      <TextField
        className="branch-name-input"
        onChange={(e) => onChangeBranchName(e.target.value)}
        variant="standard"
        label="Branch Name"
        value={branchName}
      />
      <div className="action-button-group">
        <Button onClick={back} className={buttonClassName}>
          Back
        </Button>
        <Button
          onClick={next}
          disabled={!branchName}
          className={buttonClassName}
          variant="contained"
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default OnboardingSecondStep
