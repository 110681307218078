import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import { IUser } from '../../../types/account/user'

const styles = createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
  },
})

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  allUsers: IUser[]
  flagName: string
  flagId?: string
}

interface IState {
  selectedUsers: string[]
}

class SubscribeUsersDialog extends React.Component<IProps, IState> {
  state: IState = {
    selectedUsers: [],
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    console.log(this.state.selectedUsers)
    this.props.onClose()
  }

  render() {
    const classes = this.props.classes
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose}>
        <DialogTitle>Subscribe Users to {this.props.flagName}</DialogTitle>
        <DialogContent>
          <List className={classes.root}>
            {this.props.allUsers.map((user: IUser, key) => (
              <ListItem
                key={user.id + key}
                dense
                button
                onClick={() => {
                  let newArr = this.state.selectedUsers
                  let index = this.state.selectedUsers.findIndex(
                    (x) => x === user.id
                  )
                  if (!this.state.selectedUsers.includes(user.id)) {
                    newArr.push(user.id)
                  } else if (this.state.selectedUsers.includes(user.id)) {
                    newArr.splice(index, 1)
                  }

                  this.setState({ selectedUsers: newArr })
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={this.state.selectedUsers.includes(user.id)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  id={user.id}
                  primary={`${user.firstName} ${user.lastName}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(SubscribeUsersDialog)
