import React from 'react'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
// import Icon from '../../../Icon'
import IconButton from '../../../buttons/IconButton'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'
import InfoButton from '../../../buttons/InfoButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { RoviLog, ObjErr } from '../../../../utility/roviLog'
import api from '../../../../constants/api'
import { manufacture, modelID, EModel } from '../../../../types/account/devices'

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

const styles = createStyles({
  innerDiv: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

interface IState {
  ignTip: string
  dialogOpen: boolean
  dialogCancelOpen: boolean
  pending: boolean
}

interface IProps extends WithStyles<typeof styles> {
  id: string
  deviceType: EModel
  ignitionDisabled?: boolean | null
  IgnitionSectionOpen?: () => void // Old component thats a function open its dialog, passed it through to help with Jan 25th 2021 issue
  children?: any
}

/**
 * IgnitionSection Component allows the user to change the IGN State value, also displays the current Ignition Relay status.
 * @TODO add a pending ability.
 * @param id pass the Equipment ID
 * @param deviceType pass the Equipment Model
 * @param ignitionDisabled pass the current Ignition State.
 * @see https://rovitracker.atlassian.net/l/c/rPEfkZbS
 */
class IgnitionState extends React.Component<IProps, IState> {
  state: IState = {
    ignTip: '',
    dialogOpen: false,
    dialogCancelOpen: false,
    pending: false,
  }

  switch(val: boolean) {
    this.setState({ dialogOpen: false })
    const raw = JSON.stringify({
      deviceID: this.props.id,
      deviceModel: modelID[this.props.deviceType],
      command: {
        code: '200000',
        value: val ? 'true' : 'false',
      },
    })
    const requestOptions = {
      headers: myHeaders,
      method: 'POST',
      body: raw,
    }
    this.longPoll(
      `${api}/devices/commands/${manufacture[this.props.deviceType]}`,
      requestOptions
    )
  }

  /**
   * this is the Long Polling function.
   * this function will constantly ping the fetch request and check to see what the value is.
   * it will stop pinging when the return value is not "pending" or "inProgress"
   * @param selectedLink this value is the selected link, as we use a status link and a update link,
   * this will fetch what ever link is sent through it.
   * @param selectedRequest the request Options to be added in the fetch request.
   * @param dontFail this prevents it from failing, just use for the DELETE command
   * @returns updates the state on the device current status.
   */
  async longPoll(
    selectedLink: string,
    selectedRequest: any,
    dontFail?: boolean
  ) {
    // const date = new Date()
    const requestOptions = {
        method: 'GET',
      },
      statusLink = `${api}/devices/commands/${this.props.id}`
    await fetch(selectedLink, selectedRequest)
      .then((response) => response.json())
      .then((result) => {
        switch (result['status'] ? result['status'] : result['commands']) {
          case 'pending':
            this.setState({
              ignTip: result['status'],
              pending: true,
            })
            setTimeout(() => this.longPoll(statusLink, requestOptions), 1000)
            break
          case 'inProgress':
            this.setState({
              ignTip: result['status'],
              pending: true,
            })
            setTimeout(() => this.longPoll(statusLink, requestOptions), 1000)
            break
          case 'OK':
          case 'none':
          case 'notFound':
          case 'no-update-needed':
          case 'finished':
            this.setState({
              ignTip: result['status'],
              pending: false,
            })
            break
          case 'noUpdate':
          case 'success':
          case 'no-updates':
            this.setState({
              ignTip: result['status'],
              pending: false,
            })
            break
          default:
            this.setState({
              ignTip: result['status'],
              pending: false,
            })
            if (!dontFail) {
              RoviLog.error(
                ObjErr(
                  `Error IgnState device ${
                    this.props.id
                  } didnt recieve correct readings ${
                    selectedRequest['method']
                  }, ${JSON.stringify(selectedRequest['body'])}: `,
                  result
                )
              )
            }
            break
        }
      })
      .catch((error) =>
        dontFail
          ? undefined
          : RoviLog.error(
              ObjErr(
                `IgnState device ${this.props.id} Failed to Long Poll ${
                  selectedRequest['method']
                }, ${JSON.stringify(selectedRequest['body'])}: `,
                error
              )
            )
      )
  }

  componentDidMount() {
    this.longPoll(`${api}/devices/commands/${this.props.id}`, { method: 'GET' })
  }

  render() {
    const props = this.props
    const classes = this.props.classes
    return (
      <div style={{ width: '100%' }}>
        {props.ignitionDisabled != null ? (
          <>
            <div className={classes.labelContainer}>
              <>
                {/* opens up the command Dialog */}
                {/* {!access.viewOnly ? ( */}
                <>
                  <IconButton
                    icon="ignition"
                    style={{
                      fill: props.ignitionDisabled ? red[500] : green[500],
                    }}
                    variant="row"
                    onClick={() => this.setState({ dialogOpen: true })}
                    tooltip={
                      props.ignitionDisabled
                        ? 'Ignition Disabled'
                        : 'Ignition Enabled'
                    }
                  />
                </>
                {/* ) : ( */}
                {/* <Icon
                  icon="ignition"
                  style={{
                    fill: props.ignitionDisabled ? red[500] : green[500],
                  }}
                /> */}
                {/* )} */}
                {/* Passing the insides of the device box */}
                {this.props.children}
                {/* Cancel the Current Pending Command */}
                {this.state.pending && (
                  <IconButton
                    variant="row"
                    icon="cancel"
                    tooltip="Cancel the current pending request"
                    onClick={() => this.setState({ dialogCancelOpen: true })}
                  />
                )}
              </>
            </div>
            <Dialog
              open={this.state.dialogOpen}
              onClose={() => this.setState({ dialogOpen: false })}
            >
              <DialogTitle>
                {props.ignitionDisabled ? 'Enable' : 'Disable'} Igniton State
                <InfoButton>Whether or not the engine will start</InfoButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to{' '}
                  {props.ignitionDisabled ? 'Enable' : 'Disable'} the Ignition?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({ dialogOpen: false })}>
                  Cancel
                </Button>
                <Tooltip
                  title={
                    this.state.pending
                      ? 'Current request is pending'
                      : `${
                          props.ignitionDisabled ? 'Enable' : 'Disable'
                        } the ignition`
                  }
                >
                  <span>
                    <Button
                      disabled={this.state.pending}
                      onClick={() => {
                        // this.handleSetIgnitionDisabled( // This part is old call, we connected it to help with Jan 25th 2021 issue
                        //   props.ignitionDisabled ? true : false
                        // )
                        this.switch(props.ignitionDisabled ? false : true)
                      }}
                      color="primary"
                      autoFocus
                    >
                      {props.ignitionDisabled ? 'Enable' : 'Disable'}
                    </Button>
                  </span>
                </Tooltip>
              </DialogActions>
            </Dialog>
            {/* Cancels the Current Command Dialog */}
            <Dialog
              open={this.state.dialogCancelOpen}
              onClose={() => this.setState({ dialogOpen: false })}
            >
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to cancel the current pending command?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ dialogCancelOpen: false })}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ dialogCancelOpen: false })
                    this.longPoll(
                      `${api}/devices/commands/${props.id}`,
                      {
                        method: 'DELETE',
                      },
                      true
                    )
                  }}
                  color="primary"
                  autoFocus
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <div className={classes.labelContainer}>{this.props.children}</div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(IgnitionState)
