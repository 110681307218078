import qs from 'qs'
import moment from 'moment'

/**
 * Equipment Page Hash Information
 */

export enum EEquipmentTabs {
  profile = 'profile',
  maintenance = 'maintenance',
  timeline = 'timeline',
  events = 'events',
  reports = 'reports',
}

export type TEquipmentTabs =
  | EEquipmentTabs.profile
  | EEquipmentTabs.maintenance
  | EEquipmentTabs.timeline
  | EEquipmentTabs.events
  | EEquipmentTabs.reports

export enum EAssetReports {
  fluidUsageLine = 'fluidLevelLine',
  fluidUsageBar = 'fluidLevelBar',
  dailyUsage = 'daily_usage',
  equipmentUsage = 'equipment_metrics',
  dailyTimeline = 'daily_timeline',
  nightlyTimeline = 'nightly_timeline',
  yearMonth = 'year_month',
  dailyStops = 'daily_stops',
  dailyIdleTime = 'daily_idle_time',
}

export type TAssetReports =
  | EAssetReports.fluidUsageLine
  | EAssetReports.fluidUsageBar
  | EAssetReports.dailyUsage
  | EAssetReports.equipmentUsage
  | EAssetReports.dailyTimeline
  | EAssetReports.nightlyTimeline
  | EAssetReports.yearMonth
  | EAssetReports.dailyStops
  | EAssetReports.dailyIdleTime

export interface IEquipmentHash {
  tab: TEquipmentTabs
  report?: TAssetReports
  /**
   * with this we are able support all date modes, but it should be a string formatted like YYYY-MM-DD
   */
  dateStart?: moment.Moment | Date | string
  dateEnd?: moment.Moment | Date | string
}

export const EquipmentHashFromString = (params: string): IEquipmentHash => {
  return qs.parse(params) as unknown as IEquipmentHash
}

export const EquipmentHashToString = (params: IEquipmentHash) => {
  return qs.stringify(params)
}
