import React from 'react'
import '../../styles/components/inventory/AssetsTab.scss'
import AddEquipmentDialog from './inventoryDialogs/AddEquipmentDialog'
import WidthViewport from '../ux/WidthViewport'
import ThingRow from './ThingRow'
import ThingToolbar from './inventoryFilters/ThingToolbar'
import { backend_api, headersAPI } from '../../constants/api'
import { IAssetSnapshotRow } from './AssetsTabTypes'
import { ERoutes } from '../../components/routes/CheckRoutes'
import { Virtuoso } from 'react-virtuoso'
import StartByText from '../ux/StartByText'
import {
  EMonitoring,
  TMonitorTypes,
  ERentStatus,
  TRentStatusTypes,
  ERepairStatus,
  TRepairStatus,
} from '../../types/inventory'
import { IInventoryHash } from '../../constants/inventoryHashController'
import HashStateManager from '../../rx-js/HashStateManager'
import allFilters from './inventoryFilters/FilterAssets'
import {
  sortInventoryCategories,
  sortInventoryUsers,
  sortInventoryFlags,
  sortInventoryAgreements,
} from './inventorySorting/inventorySorting'
import { ICategory } from '../../types/category'
import { IUser } from '../../types/account/user'
import { IFlag } from '../../types/flag'
import { IAgreement } from '../../types/agreements'
import TransferEquipmentDialog from './inventoryDialogs/TransferEquipmentDialog'

const defaultVal = 'all'

interface IState {
  select: string[]
  searchValue: string
  thingRowAmount: number
  thingval: IAssetSnapshotRow[]
  loading: boolean
  allMetrics: any[]
  /** Filters */
  monitor: TMonitorTypes
  rentStatus: TRentStatusTypes
  repairStatus: TRepairStatus
  categoryList: ICategory[]
  categoryId: string
  userList: IUser[]
  userId: string
  flagList: IFlag[]
  flagId: string
  agreementList: IAgreement[]
  clientId: string
  siteId: string
  transferDialogOpen: boolean
}

interface IProps {
  hash: HashStateManager<IInventoryHash>
  addThingDialog: boolean
  closeThingDialog: () => void
}

export default class AssetsTab extends React.Component<IProps, IState> {
  state: IState = {
    select: [],
    searchValue: '',
    thingRowAmount: 112,
    thingval: [],
    loading: true,
    allMetrics: [],
    /** Filters */
    monitor: EMonitoring.all,
    rentStatus: ERentStatus.all,
    repairStatus: ERepairStatus.all,
    categoryList: [],
    categoryId: defaultVal,
    userList: [],
    userId: defaultVal,
    flagList: [],
    flagId: defaultVal,
    agreementList: [],
    clientId: defaultVal,
    siteId: defaultVal,
    transferDialogOpen: false,
  }

  /**
   * When Select all button is clicked, it will trigger
   * @param bool
   */
  switchAllAssets(bool: boolean, allAssets: IAssetSnapshotRow[]) {
    let newArr: string[] = []
    if (bool) {
      allAssets.forEach((asset) => {
        newArr.push(asset.id)
      })
    }
    this.setState({ select: newArr })
  }

  changeChecked(val: string, bool: boolean, original: string[]) {
    let newArr = original
    if (bool) {
      newArr.push(val)
    } else {
      newArr.splice(original.indexOf(val), 1)
    }
    this.setState({ select: newArr })
  }

  grabAssets() {
    fetch(backend_api + '/assets/all', { method: 'GET', headers: headersAPI })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          thingval: response,
          loading: false,
          categoryList: sortInventoryCategories(response),
          userList: sortInventoryUsers(response),
          flagList: sortInventoryFlags(response),
          agreementList: sortInventoryAgreements(response),
        })
        this.findDivSize(response)
      })
      .catch((err) => console.log(err))
  }

  findDivSize(thingval: IAssetSnapshotRow[]) {
    let largestMetricSet: any[] = []
    thingval.forEach((thing) =>
      thing.metrics
        ? thing.metrics.forEach((metric) => {
            if (!largestMetricSet.find((l) => metric.field === l.field)) {
              largestMetricSet.push(metric)
            }
          })
        : undefined
    )
    this.setState({ allMetrics: largestMetricSet })
  }

  /**
   * Quick filter
   */

  setCurrentFilters() {
    const hashgrabbed: IInventoryHash = this.props.hash.value
    console.log(hashgrabbed)
    if (hashgrabbed.filtersThing) {
      let monitoring = hashgrabbed.filtersThing.monitoring
      let rentStatus = hashgrabbed.filtersThing.rentStatus
      let repairStatus = hashgrabbed.filtersThing.repairStatus
      let categoryId = hashgrabbed.filtersThing.categoryId
      let userId = hashgrabbed.filtersThing.userId
      let flagId = hashgrabbed.filtersThing.flagId
      let clientId = hashgrabbed.filtersThing.clientId
      let siteId = hashgrabbed.filtersThing.siteId
      this.setState({
        monitor: monitoring ? monitoring : EMonitoring.all,
        rentStatus: rentStatus ? rentStatus : ERentStatus.all,
        repairStatus: repairStatus ? repairStatus : ERepairStatus.all,
        categoryId: categoryId ? categoryId : defaultVal,
        userId: userId ? userId : defaultVal,
        flagId: flagId ? flagId : defaultVal,
        clientId: clientId ? clientId : defaultVal,
        siteId: siteId ? siteId : defaultVal,
      })
    }
  }

  componentDidMount() {
    this.grabAssets()
    this.setCurrentFilters()
  }

  /**
   * This helps the rendering process of more than a certain amount of assets loaded.
   */
  reactVirtual(thingval: IAssetSnapshotRow[], width: number, height: number) {
    return (
      <Virtuoso
        totalCount={thingval ? thingval.length : 0}
        style={{ height: height, width: width }}
        overscan={thingval.length > 100 ? 0 : 4150} // this number was selected because it essentially prerenders a total of 100 assets since all of them have a height of 50px
        isScrolling={(bool) =>
          thingval.length > 100 ? this.setState({ loading: bool }) : undefined
        }
        itemContent={(index) => {
          const val = thingval[index]
          return val ? (
            <ThingRow
              key={index}
              id={val.id}
              name={val.name}
              checked={this.state.select.includes(val.id)}
              onChecked={(event) =>
                this.changeChecked(
                  val.id,
                  event.target.checked,
                  this.state.select
                )
              }
              vin={val.vin}
              serialNumber={val.serial}
              device={val.device}
              category={val.category}
              imageUrl={val.imageUrl}
              user={val.user}
              inForService={val.inForService}
              hasCamera={val.hasCamera}
              hasDevice={val.hasDevice}
              tasks={val.tasks}
              flags={val.flags}
              metrics={val.metrics}
              fakeMetrics={this.state.allMetrics}
              agreement={val.agreement}
              clientFiltered={this.state.clientId !== defaultVal}
            />
          ) : (
            <div>Something Went Wrong</div>
          )
        }}
      />
    )
  }

  filters() {
    return allFilters(
      this.state.thingval,
      this.state.searchValue,
      this.state.monitor,
      this.state.rentStatus,
      this.state.repairStatus,
      this.state.categoryId,
      this.state.userId,
      this.state.flagId,
      this.state.clientId,
      this.state.siteId
    )
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile, width, height }) => (
          <>
            <ThingToolbar
              thingRows={this.filters()}
              openTransferDialog={() => {
                console.log(this.state.select)
                this.setState({ transferDialogOpen: true })
              }}
              selectAll={
                this.state.thingval.length > 0
                  ? this.state.select.length === this.filters().length
                  : false
              }
              switchAllAssets={(val) =>
                this.switchAllAssets(val, this.filters())
              }
              selectArr={this.state.select}
              searchValue={this.state.searchValue}
              changeSearchVal={(val) => this.setState({ searchValue: val })}
              loading={this.state.loading}
              isMobile={isMobile}
              /** Filter */
              categoryList={this.state.categoryList}
              monitor={this.state.monitor}
              rentStatus={this.state.rentStatus}
              repairStatus={this.state.repairStatus}
              changeMonitor={(val: TMonitorTypes) => {
                this.props.hash.set({
                  ...this.props.hash.value,
                  filtersThing: {
                    ...this.props.hash.value.filtersThing,
                    monitoring: val,
                  },
                })
                this.setState({ monitor: val })
              }}
              changeRent={(val: TRentStatusTypes) => {
                this.props.hash.set({
                  ...this.props.hash.value,
                  filtersThing: {
                    ...this.props.hash.value.filtersThing,
                    rentStatus: val,
                  },
                })
                this.setState({ rentStatus: val })
              }}
              changeRepair={(val: TRepairStatus) => {
                this.props.hash.set({
                  ...this.props.hash.value,
                  filtersThing: {
                    ...this.props.hash.value.filtersThing,
                    repairStatus: val,
                  },
                })
                this.setState({ repairStatus: val })
              }}
              categoryId={this.state.categoryId}
              changeCategory={(val: string) => {
                this.props.hash.set({
                  ...this.props.hash.value,
                  filtersThing: {
                    ...this.props.hash.value.filtersThing,
                    categoryId: val,
                  },
                })
                this.setState({ categoryId: val })
              }}
              userList={this.state.userList}
              userId={this.state.userId}
              changeUser={(val: string) => {
                this.props.hash.set({
                  ...this.props.hash.value,
                  filtersThing: {
                    ...this.props.hash.value.filtersThing,
                    userId: val,
                  },
                })
                this.setState({ userId: val })
              }}
              agreementList={this.state.agreementList}
              clientId={this.state.clientId}
              changeClient={(val: string) => {
                this.props.hash.set({
                  ...this.props.hash.value,
                  filtersThing: {
                    ...this.props.hash.value.filtersThing,
                    clientId: val,
                  },
                })
                this.setState({ clientId: val })
              }}
              siteId={this.state.siteId}
              changeSite={(val: string) => {
                this.props.hash.set({
                  ...this.props.hash.value,
                  filtersThing: {
                    ...this.props.hash.value.filtersThing,
                    siteId: val,
                  },
                })
                this.setState({ siteId: val })
              }}
              flagList={this.state.flagList}
              flagId={this.state.flagId}
              changeFlag={(val: string) => {
                this.props.hash.set({
                  ...this.props.hash.value,
                  filtersThing: {
                    ...this.props.hash.value.filtersThing,
                    flagId: val,
                  },
                })
                this.setState({ flagId: val })
              }}
              clearFilters={() =>
                this.props.hash.set({
                  ...this.props.hash.value,
                  filtersThing: undefined,
                })
              }
            />
            <div
              style={{
                marginTop: isMobile ? 48 : 50,
                marginBottom: isMobile ? 56 : 0,
              }}
            >
              {this.state.thingval.length > 0 ? (
                this.reactVirtual(
                  this.filters(),
                  isMobile ? width : width - 80,
                  isMobile ? height : height - 114
                )
              ) : (
                <StartByText
                  id="categoriesStartByText"
                  containerStyle={{ marginTop: 180 }}
                  clickableText="creating a category"
                  href={ERoutes.categories}
                />
              )}
            </div>
            <AddEquipmentDialog
              dialogOpen={this.props.addThingDialog}
              closeDialog={this.props.closeThingDialog}
              isMobile={isMobile}
            />
            <TransferEquipmentDialog
              open={this.state.transferDialogOpen}
              onClose={() => this.setState({ transferDialogOpen: false })}
              selectedAssets={this.state.select}
              allAssets={this.state.thingval}
              isMobile={isMobile}
            />
          </>
        )}
      </WidthViewport>
    )
  }
}
