import React from 'react'
import Fab from '@mui/material/Fab'
import Icon from '../Icon'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'

const styles = createStyles({
  root: {
    width: '100%',
    flexShrink: 0,
    position: 'relative',
  },
  scrollContainer: {
    overflowX: 'hidden',
    padding: '10px 30px',
    display: 'flex',
    boxSizing: 'border-box',
  },
  leftButton: {
    position: 'absolute',
    left: 4,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  rightButton: {
    position: 'absolute',
    right: 4,
    top: '50%',
    transform: 'translateY(-50%)',
  },
})

/**
 * Cathy or Alvaron left this note, not sure if we will eventually implement it all
 * TODO: should scroll to and center on next element in array of children instead
 */
interface IProps extends WithStyles<typeof styles> {
  scrollBy: number
  children?: React.ReactNode // Everything was breaking without this so added it when upgrading to Material-ui@v5.0.0-alpha.25 Feb 15th
}

class Carousel extends React.Component<IProps> {
  // @ts-ignore
  scrollContainer: HTMLDivElement

  state = {
    isScrollable: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleSetRef = (ref: any) => {
    if (ref) {
      this.scrollContainer = ref
      this.handleResize()
    }
  }

  handleResize = () => {
    const isScrollable =
      this.scrollContainer.scrollWidth > this.scrollContainer.clientWidth
    this.setState({ isScrollable })
  }

  handleClickScrollRight = () => {
    this.scrollContainer.scrollBy({
      top: 0,
      left: this.props.scrollBy,
      behavior: 'smooth',
    })
  }

  handleClickScrollLeft = () => {
    this.scrollContainer.scrollBy({
      top: 0,
      left: -this.props.scrollBy,
      behavior: 'smooth',
    })
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <div
          ref={this.handleSetRef}
          className={this.props.classes.scrollContainer}
        >
          {this.props.children}
          <div style={{ width: 30, height: 30, flexShrink: 0 }} />
        </div>

        {this.state.isScrollable && (
          <>
            <Fab
              size="small"
              color="primary"
              onClick={this.handleClickScrollLeft}
              className={this.props.classes.leftButton}
            >
              <Icon icon="arrowLeft" color="inherit" />
            </Fab>

            <Fab
              size="small"
              color="primary"
              onClick={this.handleClickScrollRight}
              className={this.props.classes.rightButton}
            >
              <Icon icon="arrowRight" color="inherit" />
            </Fab>
          </>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(Carousel)
