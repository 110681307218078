import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '../buttons/IconButton'
import { ICategory } from '../../types/category'
import DeleteAttributeDialog from './dialogs/DeleteAttributeDialog'
import AddEditAttributeDialog from './dialogs/AddEditAttributeDialog'

interface IAttribute {
  id: string
  name: string
}

interface IAttributeCategory {
  category: ICategory
  attributes: IAttribute[]
}

interface IAttributeAll extends IAttribute {}

interface IProps {
  attribute: IAttribute
  categories: ICategory[] // all categories
  categoryId: string // the specific category assigned to this row
}

interface IState {
  deleteAttributeDialog: boolean
  addAttributeDialogOpen: boolean
}

class AttributesRow extends React.Component<IProps, IState> {
  state: IState = {
    deleteAttributeDialog: false,
    addAttributeDialogOpen: false,
  }

  render() {
    return (
      <TableRow>
        <TableCell style={{ paddingLeft: 40 }}>
          {this.props.attribute.name}
        </TableCell>
        <TableCell align="right">
          <IconButton
            icon="edit"
            variant="toolbar"
            tooltip="Edit"
            onClick={() => this.setState({ addAttributeDialogOpen: true })}
          />
          <IconButton
            icon="delete"
            variant="toolbar"
            tooltip="Delete"
            onClick={() => this.setState({ deleteAttributeDialog: true })}
          />
        </TableCell>
        <DeleteAttributeDialog
          dialogOpen={this.state.deleteAttributeDialog}
          onClose={() => this.setState({ deleteAttributeDialog: false })}
          attributeName={this.props.attribute.name}
          attributeId={this.props.attribute.id}
        />
        {this.state.addAttributeDialogOpen ? (
          <AddEditAttributeDialog
            dialogOpen={this.state.addAttributeDialogOpen}
            onClose={() => this.setState({ addAttributeDialogOpen: false })}
            type="edit"
            categories={this.props.categories}
            attributeName={this.props.attribute.name}
            attributeId={this.props.attribute.id}
            attributeCategoryId={this.props.categoryId}
          />
        ) : null}
      </TableRow>
    )
  }
}

export default AttributesRow
