import React from 'react'
import CategoryAvatar from '../../../ux/CategoryAvatar'
import AssetListItem from './AssetListItem'
import MessageOverlay from '../../../ux/MessageOverlay'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import { colors } from '../../../ux/roviTheme'
import { LoadingIcon } from '../../../LoadingOverlay'
import {
  IAssetList,
  IAsset,
  IAssetClicked,
} from '../../../../types/maptrac/Assets'
import { Virtuoso } from 'react-virtuoso'

interface IProps {
  data: IAssetList[]
  onClick: (val: IAssetClicked) => void
  onHoverIn: (id: any) => void
  onHoverOut: () => void
  loading: boolean
  searchString: string
  isMobile: boolean
  width: number
  height: number
}

interface IState {}

class LiveMaptracListAsset extends React.Component<IProps, IState> {
  render() {
    const props = this.props
    return (
      <>
        <MessageOverlay belowTheSurface isVisible={props.data.length === 0}>
          {props.loading ? <LoadingIcon loading /> : 'No Assets'}
        </MessageOverlay>
        <Virtuoso
          totalCount={props.data.length}
          style={{
            height: props.isMobile
              ? this.props.height - 320
              : this.props.height - 163,
          }}
          /** this value is smaller than the inventory page due to maptrac also being rendered on screen at the same time, this one is rendering a maximum of 50 at a time */
          overscan={3800}
          itemContent={(index) =>
            props.data[index].equipment.length > 0 ? (
              <List
                key={index}
                subheader={
                  <ListSubheader
                    style={{
                      background: colors.bg,
                      zIndex: 2,
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <CategoryAvatar
                        size={24}
                        color={props.data[index].category.color}
                        icon={props.data[index].category.icon}
                      />
                      <div
                        title={props.data[index].category.name}
                        style={{ marginLeft: 10 }}
                      >
                        {`${props.data[index].category.name} (${props.data[index].equipment.length})`}
                      </div>
                    </div>
                  </ListSubheader>
                }
              >
                {props.data[index].equipment.map((asset: IAsset) => (
                  <AssetListItem
                    key={asset.id}
                    id={asset.id}
                    name={asset.name}
                    make={asset.make}
                    model={asset.model}
                    imageUrl={asset.imageUrl}
                    icon={props.data[index].category.icon}
                    // onClick={() => props.onClick(asset.id)}
                    onClick={() =>
                      props.onClick({ assetId: asset.id, gps: asset.gps })
                    }
                    tasks={asset.tasks}
                    user={asset.user}
                    hasCamera={asset.hasCamera}
                    battery={asset.battery}
                    ignition={asset.ignition}
                    onHoverIn={() => props.onHoverIn(asset.id)}
                    onHoverOut={props.onHoverOut}
                    flags={asset.flags}
                  />
                ))}
              </List>
            ) : (
              // visibility needed here because virtuoso doesnt like having no value, @todo need better fix
              <div style={{ visibility: 'hidden' }}>no data warning</div>
            )
          }
        />
      </>
    )
  }
}

export default LiveMaptracListAsset
