import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import MessageBanner from '../../ux/MessageBanner'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import {
  ISensor,
  ESensorTypes,
} from '../../../types/equipment/equipmentProfile'
import { Divider } from '@mui/material'
import NumberField from '../../textBoxs/NumberField'
import { colors } from '../../ux/roviTheme'
import EllipsisText from '../../ux/EllipsisText'

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
  })

/**
 * This Container Seperates all the different Calibrations, but allowing same stlying.
 * @param props pass the title, and the children to this.
 */
const CalibratorContainer: React.FC<{ title: string }> = (props) => (
  <>
    <div style={{ padding: 16 }}>
      <div style={{ fontSize: 16, marginBottom: 10 }}>
        <EllipsisText width={250}>{props.title}</EllipsisText>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {props.children}
      </div>
    </div>
    <Divider />
  </>
)

/**
 * Function to let the user know that you can only calibrate once values are recieved.
 * @returns Device can only be calibrated once values have been received
 */
const NoValueMessage = () => (
  <div style={{ margin: 8, color: colors.textDarkGrey }}>
    Device can only be calibrated once values have been received
  </div>
)

interface IVoltageProps {
  sensor: ISensor
  onChange: (newOffset: string) => void
  newOffset: number
  classes?: string
}

/**
 * Voltage Calibrator for the device.
 * This component renders if the Device supports Voltage input.
 * @param props takes the parameters of IVoltageProps.
 */
const VoltageSensorCalibrator = (props: IVoltageProps) => {
  const [value, setValue] = useState<number | null>(props.sensor.offset) // Set the first value as the default value of the input element.
  const uncalibratedValue =
    props.sensor.currentValue != null
      ? props.sensor.currentValue - props.sensor.offset
      : 0
  const changeValue = (newVal: number | null) => {
    setValue(newVal)
    props.onChange(newVal ? newVal.toString() : '')
  }
  return (
    <CalibratorContainer title={props.sensor.label}>
      {props.sensor.currentValue != null ? (
        <>
          {/* 
              The Text Box Rendered.
            */}
          <NumberField
            label="Offset"
            className={props.classes}
            endAdornment="V"
            value={value}
            onChange={changeValue}
            step={0.1}
            min={0}
            helperText={
              <div style={{ margin: 8, color: colors.textDarkGrey }}>
                Calibrated Voltage: {uncalibratedValue} V + {props.newOffset} V
                = <b>{uncalibratedValue + props.newOffset} V</b>
              </div>
            }
          />
        </>
      ) : (
        <NoValueMessage />
      )}
    </CalibratorContainer>
  )
}

interface IOdometerProps {
  sensor: ISensor
  onChange: (val: string) => void
  useMetric: boolean
  classes?: string
}

/**
 * Odometer Calibrator for the device.
 * @param props takes the parameters of IOdometerProps
 */
const OdometerSensorCalibrator = (props: IOdometerProps) => {
  const currentValue = props.sensor.currentValue
    ? props.sensor.currentValue
    : null
  const [value, setValue] = useState<number | null>(currentValue) // Set the first value as the default value of the input element.
  const changeValue = (newVal: number | null) => {
    setValue(newVal)
    props.onChange(newVal ? newVal.toString() : '')
  }
  return (
    <CalibratorContainer title={props.sensor.label}>
      {props.sensor.currentValue != null ? (
        <>
          {/* 
            The Text Box Rendered to only show the previous value before modified.
          */}
          <NumberField
            label="Current Estimate"
            className={props.classes}
            endAdornment={props.useMetric ? 'km' : 'm'}
            defaultValue={props.sensor.currentValue} // Set the first value as the default value of the input element.
            disabled
          />
          {/* 
            The Text Box Rendered.
          */}
          <NumberField
            label="Actual"
            className={props.classes}
            endAdornment={props.useMetric ? 'km' : 'm'}
            value={value}
            onChange={changeValue}
            step={0.1}
            min={0}
          />
        </>
      ) : (
        <NoValueMessage />
      )}
    </CalibratorContainer>
  )
}

interface IRuntimeProps {
  sensor: ISensor
  onChange: (val: string) => void
  classes?: string
}

/**
 * RuntimeSensor Calibrator for the device.
 * @param props takes the parameters of IRuntimeProps
 */
const RuntimeSensorCalibrator = (props: IRuntimeProps) => {
  const currentValue = props.sensor.currentValue
    ? props.sensor.currentValue
    : null
  const [value, setValue] = useState<number | null>(currentValue) // Set the first value as the default value of the input element.
  const changeValue = (newVal: number | null) => {
    setValue(newVal)
    props.onChange(newVal ? newVal.toString() : '')
  }
  return (
    <CalibratorContainer title={props.sensor.label}>
      {props.sensor.currentValue != null ? (
        <>
          {/* 
            The Text Box Rendered to only show the previous value before modified.
          */}
          <NumberField
            endAdornment="hour"
            label="Current Estimate"
            className={props.classes}
            defaultValue={props.sensor.currentValue} // Set the first value as the default value of the input element.
            disabled
          />
          {/* 
            The Text Box Rendered.
          */}
          <NumberField
            label="Actual"
            className={props.classes}
            endAdornment="hour"
            value={value}
            onChange={changeValue}
            step={0.1}
            min={0}
          />
        </>
      ) : (
        <NoValueMessage />
      )}
    </CalibratorContainer>
  )
}

interface ITextFieldInfo {
  val: number
}

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  assetName: string
  assetId: string
  sensors: ISensor[]
  useMetric: boolean
}

interface IState {
  textFieldValues: any
}

class DeviceCalibration extends React.Component<IProps, IState> {
  state: IState = {
    textFieldValues: {},
  }

  updateTextFields = (
    currentValues: any,
    name: string,
    val: string | number
  ) => {
    let textFieldValues = currentValues
    textFieldValues = { ...textFieldValues, [name]: val }
    return textFieldValues
  }

  setTextFieldState = (name: string, val: string | number) =>
    this.setState({
      textFieldValues: this.updateTextFields(
        this.state.textFieldValues,
        name,
        val
      ),
    })

  handleClose = () => this.props.onClose()

  onSubmit = () => {
    console.log(this.state.textFieldValues)
    this.props.onClose()
  }

  setTextFields = (sensors: ISensor[]) => {
    let allSensors: any = {}
    console.log()
    sensors.forEach((sensor) => {
      allSensors = this.updateTextFields(
        allSensors,
        sensor.id,
        sensor.currentValue ? sensor.currentValue : 0
      )
    })
    return allSensors
  }

  componentDidMount() {
    this.setState({ textFieldValues: this.setTextFields(this.props.sensors) })
  }

  render() {
    const classes = this.props.classes
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose}>
        <DialogTitle>Calibrate Device</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <MessageBanner>
            Calibration changes only affect data going forward. It might take a{' '}
            <b>few minutes</b> for changes to be reflected on your data.
          </MessageBanner>
          {this.props.sensors.map((sensor) => {
            const sensorProps = {
              key: sensor.name,
              sensor,
            }
            return (
              <>
                {sensor.type === ESensorTypes.voltage ? (
                  <VoltageSensorCalibrator
                    {...sensorProps}
                    newOffset={sensor.offset}
                    onChange={(val) => this.setTextFieldState(sensor.id, val)}
                    classes={classes.TextField}
                  />
                ) : sensor.type === ESensorTypes.runtime ? (
                  <RuntimeSensorCalibrator
                    {...sensorProps}
                    onChange={(val) => this.setTextFieldState(sensor.id, val)}
                    classes={classes.TextField}
                  />
                ) : (
                  <OdometerSensorCalibrator
                    {...sensorProps}
                    useMetric={this.props.useMetric}
                    onChange={(val) => this.setTextFieldState(sensor.id, val)}
                    classes={classes.TextField}
                  />
                )}
                <Divider sx={{ width: '100%' }} />
              </>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(DeviceCalibration)
