import React from 'react'
import '../../styles/ux/ServiceButton.scss'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '../buttons/Button'
import IconButton from '../buttons/IconButton'

const styles = createStyles({
  formControl: {
    margin: '10px 20px',
    minWidth: 250,
  },
})

export interface ISelectValues {
  value: string
  label: string
}

interface IProps extends WithStyles<typeof styles> {
  onChange: (value: string) => void
  id?: string
  values: ISelectValues[]
  disabled?: boolean
  className?: string
  required?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  children?: any
  isIconButton?: boolean
  tooltip?: string
}

interface IState {
  anchorEl: null | HTMLElement | Element
}

class SelectBlock extends React.Component<IProps, IState> {
  state = {
    anchorEl: null,
  }

  handleClose = () => this.setState({ anchorEl: null })

  render() {
    return (
      <div>
        {this.props.isIconButton ? (
          <IconButton
            icon="add"
            onClick={(event) =>
              this.setState({ anchorEl: event.currentTarget })
            }
            variant="row"
            tooltip={this.props.tooltip}
          ></IconButton>
        ) : (
          <Button
            variant={this.props.variant ? this.props.variant : 'contained'}
            onClick={(event) =>
              this.setState({ anchorEl: event.currentTarget })
            }
            tooltip={this.props.tooltip}
          >
            {this.props.children}
          </Button>
        )}
        <Menu
          anchorEl={this.state.anchorEl}
          open={!!this.state.anchorEl}
          onClose={this.handleClose}
        >
          {this.props.values.map((value, key) => (
            <MenuItem
              key={'selectBlock' + key}
              onClick={() => {
                this.props.onChange(value.value)
                this.handleClose()
              }}
            >
              {value.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

export default withStyles(styles)(SelectBlock)
