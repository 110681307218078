import React from 'react'
import MUIButton from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

interface IState {}

interface IProps {
  id?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  color?: 'primary' | 'secondary' | 'inherit'
  variant?: 'contained' | 'outlined' | 'text'
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  component?: any
  href?: string
  style?: React.CSSProperties
  className?: string
  disabled?: boolean
  tooltip?: string
}

/**
 * The Switch from Materialv4 to Materialv5 required us to switch the Button to our own controlled Button
 * - Leave color undefined for default color
 */
class Button extends React.Component<IProps, IState> {
  state: IState = {}

  component() {
    return (
      <MUIButton
        id={this.props.id}
        color={this.props.color}
        onClick={this.props.onClick}
        variant={this.props.variant}
        startIcon={this.props.startIcon}
        endIcon={this.props.endIcon}
        size={this.props.size}
        disabled={this.props.disabled}
        component={this.props.component}
        href={this.props.href}
        style={this.props.style}
        className={this.props.className}
        sx={
          this.props.variant === 'contained' || !!this.props.color
            ? undefined
            : {
                color: 'action.active',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                '&:hover': {
                  textDecoration: 'none',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  /** Used to override the a tag highlight */
                  color: 'rgba(0, 0, 0, 0.87)',
                },
              }
        }
        {...this.props}
      >
        {this.props.children}
      </MUIButton>
    )
  }

  render() {
    return !!this.props.tooltip ? (
      <Tooltip title={this.props.tooltip}>
        <span>{this.component()}</span>
      </Tooltip>
    ) : (
      this.component()
    )
  }
}

export default Button
