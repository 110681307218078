import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import ListItemIcon from '@mui/material/ListItemIcon'
import EllipsisText from '../../ux/EllipsisText'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import { WithStyles } from '@mui/styles'
import { createStyles, withStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import IconButton from '../../buttons/IconButton'
import { IAssignAsset } from '../../../types/service/tasks'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxHeight: 420,
      minWidth: 200,
    },
    header: {
      background: theme.palette.background.paper,
    },
    back: {
      marginRight: 10,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  equips: IAssignAsset[]
  filterEquips: string[]
  onChange: (equipIds: string[]) => void
  header: boolean
  return?: () => void
  label?: string
}

const AssetsList = (props: IProps) => {
  return (
    <List>
      <ListSubheader className={props.classes.header}>
        {props.return ? (
          <IconButton icon="back" variant="toolbar" onClick={props.return} />
        ) : null}
        {props.label}
      </ListSubheader>
      {props.equips.map((equip) => {
        const selected = props.filterEquips.includes(equip.id)
        return (
          <ListItem
            key={equip.id}
            button
            onClick={() =>
              selected
                ? props.onChange(
                    props.filterEquips.filter((id) => id !== equip.id)
                  )
                : props.onChange([...props.filterEquips, equip.id])
            }
            dense
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={selected}
                disableRipple
                color="primary"
              />
            </ListItemIcon>
            <ListItemText primary={<EllipsisText text={equip.name} />} />
          </ListItem>
        )
      })}
    </List>
  )
}

export default withStyles(styles)(AssetsList)
