/** Checks what the radius size is, than we set the zoom lvl */
export const findZoomLvl = (radius: number) => {
  return radius < 40000
    ? 10
    : radius < 250000
    ? 8
    : radius < 500000
    ? 7
    : radius < 1000000
    ? 6
    : 4
}
