import React from 'react'
import '../../../styles/components/accountComponents/AccountBranch.scss'
import IconButton from '../../buttons/IconButton'
import { DefaultAgreementFees } from '../../../types/account/account'
import { IAssetBase } from '../../../types/assets'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import DeleteDefaultFeeDialog from '../dialogs/DeleteDefaultFeeDialog'

const styles = createStyles({
  paper: {
    margin: 20,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  table: {},
  hideLastBorder: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
})

interface IProps extends WithStyles<typeof styles> {
  fee: DefaultAgreementFees
  isMobile?: boolean
}

interface IState {
  deleteDefaultFeeDialog: boolean
}

class BranchFeeRow extends React.Component<IProps, IState> {
  state: IState = {
    deleteDefaultFeeDialog: false,
  }

  splitByNames(assets: IAssetBase[]) {
    let newNames: string[] = []
    assets.forEach((asset) => newNames.push(asset.name))
    return newNames
  }

  render() {
    const classes = this.props.classes
    const fee = this.props.fee
    return (
      <>
        <TableRow className={classes.hideLastBorder}>
          <TableCell component="th" scope="row">
            {fee.description}
          </TableCell>
          <TableCell>${fee.cost}</TableCell>
          <TableCell align="right">
            <IconButton
              icon="delete"
              variant="row"
              tooltip="Delete"
              onClick={() => this.setState({ deleteDefaultFeeDialog: true })}
            />
          </TableCell>
        </TableRow>
        <DeleteDefaultFeeDialog
          dialogOpen={this.state.deleteDefaultFeeDialog}
          onClose={() => this.setState({ deleteDefaultFeeDialog: false })}
          feeDescription={fee.description}
          feeId={fee.id}
        />
      </>
    )
  }
}

export default withStyles(styles)(BranchFeeRow)
