import React from 'react'
import '../../styles/pages/account/accountPage.scss'
import ReactHelmet from '../../components/ReactHelmet'
import ContactCard from '../../components/accountComponents/ContactCard'
import RentalTermsCard from '../../components/accountComponents/RentalTermsCard'
import SubscriptionPlanCard from '../../components/accountComponents/SubscriptionPlanCard'
import AccountBranches from '../../components/accountComponents/AccountBranches'
import { backend_api, headersAPI } from '../../constants/api'
import { IAccount } from '../../types/account/account'
import LoadingOverlay from '../../components/LoadingOverlay'

interface IProps {
  isMobile: boolean
}

interface IState {
  AccountData: IAccount
  loadingData: boolean
}

class AccountPage extends React.Component<IProps, IState> {
  state: IState = {
    AccountData: {},
    loadingData: true,
  }

  grabAccountData() {
    fetch(backend_api + '/account', { method: 'GET', headers: headersAPI })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          AccountData: response,
          loadingData: false,
        })
      })
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    this.grabAccountData()
  }

  componentWillUnmount() {
    /** This is here to essentially unmount the state, fixing the async issue */
    this.setState = () => {
      return
    }
  }

  render() {
    return (
      <div
        className="accountPage"
        style={{ padding: this.props.isMobile ? '48px 0' : undefined }}
      >
        <ReactHelmet title="Account" />
        <LoadingOverlay loading={this.state.loadingData} />
        <div className="accountPageContainer">
          <div className="accountPageCards">
            <ContactCard
              account={this.state.AccountData.CompanyInfo}
              loadingData={this.state.loadingData}
            />
            <RentalTermsCard
              rentalTerms={this.state.AccountData.RentalTerms}
              loadingData={this.state.loadingData}
            />
            <SubscriptionPlanCard />
          </div>
          <AccountBranches
            branches={this.state.AccountData.Branches}
            loadingData={this.state.loadingData}
            isMobile={this.props.isMobile}
          />
        </div>
      </div>
    )
  }
}

export default AccountPage
