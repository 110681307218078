import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const FuelIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M23.2,3.2l0.4-0.3l-1-1.1l-0.3,0.3c-0.5,0.5-1,0.9-1.3,1.2s-0.5,0.7-0.8,1.1c-0.2,0.4-0.4,0.9-0.5,1.4c-0.3,1.2-0.4,2.7-0.4,4.6c0,1.6,0.2,2.8,0.5,3.7l0.1,0.4c0.7,1.7,1.1,3.3,1.1,4.6c0,2.4-1,3.6-2.9,3.6c-1.7,0-2.7-1.2-2.8-3.5c0-0.7,0.1-1.9,0.5-3.6c0.3-1.5,0.5-2.7,0.7-3.5c0.2-1.4,0-2.4-0.5-2.9c-0.5-0.5-1.4-0.9-2.6-0.9H13V1.1c0-0.3-0.1-0.5-0.3-0.8S12.2,0,12,0H1.5C1.2,0,1,0.1,0.7,0.3S0.3,0.8,0.3,1.1v22.3H13V9.6h0.3c0.8,0,1.3,0.1,1.5,0.3s0.3,0.9,0.2,1.7c-0.1,0.8-0.3,2-0.7,3.4c-0.4,1.9-0.7,3.3-0.5,4c0.1,1.5,0.4,2.7,1.2,3.6c0.8,0.9,1.7,1.3,3.1,1.3c1.4,0,2.5-0.4,3.3-1.3c0.8-0.9,1.2-2.2,1.2-3.8c0-1.5-0.3-3.3-1.1-5.1l-0.1-0.4c-0.2-0.5-0.3-1.5-0.4-2.8c0.7,0,1.1,0,1.3,0c0.5,0,0.9-0.3,1.1-1c0-0.2,0-0.3,0-0.4c0-0.1,0-0.2,0-0.5c0-0.3,0-0.5,0-0.8V7.5c0-1.1-0.1-1.9-0.2-2.3c-0.3-0.4-0.5-0.9-1-1.1c0.1-0.1,0.2-0.2,0.5-0.4C23,3.4,23.1,3.3,23.2,3.2z M11.6,8.6H1.9V2.1h9.6v6.5H11.6z M20.7,8.9c0-1.1,0.2-2.1,0.4-2.9c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.3,0.1-0.3c0.2,0.2,0.4,1,0.4,2.2v0.2c0,0.1,0,0.3,0,0.5s0,0.4,0,0.5h-1V8.9z" />,
  'FuelIcon'
)

export default FuelIcon
