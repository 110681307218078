import { ICategory } from '../category'
import { IFlag } from '../flag'
import { IUser } from '../account/user'
import { IImage } from '../../components/ux/ImageUploader'
import { ILatLng } from '../maptrac/LatLng'
import { IAssetDevice } from '../account/devices'

export interface IEquipmentProfile {
  id: string
  uuid: string
  name: string
  devices: IAssetDevice[]
  yearBuilt: string
  purchaseDate: string
  purchasePrice: string
  gps: ILatLng
  boundary?: IBoundary
  make: string
  model: string
  vin?: string
  serial?: string
  metrics: Array<IMetricClass>
  customAttributes: ICustomAttributes[]
  imageUrl?: IImage[]
  user?: IUser
  soldTo?: { clientId: string; soldDate: string }
  agreement?: {
    agreementId: string
    client: {
      name: string
      id: string
    }
    site: {
      id: string
      name: string
    }
  }
  inForService: boolean
  hasCamera: boolean
  hasDevice: boolean
  category: ICategory
  note?: string
  tasks: IEquipTask[]
  flags: IFlag[]
  rentalRates: IRentalRates
  operator: IOperator | null
  sensors: ISensor[]
}

export interface IBoundary {
  id: string
  radius: number
  inside: boolean
}

export enum ESensorTypes {
  odometer = 'odometer',
  voltage = 'voltage',
  runtime = 'runtime',
}

export interface ISensor {
  currentValue?: number
  label: string
  name: string
  offset: number
  type: ESensorTypes
  id: string
}

/** This is taken over from the legacy code i feel it should switched to just user instead of this Operator */
export interface IOperator {
  id: string
  firstName: string
  lastName: string
  viewUrl: string
  role: string
}

export interface IMetricClass {
  field: string
  value: number | string
}

export interface IEquipTask {
  type: string
  task: string
  time: string
  scheduleRatio: string
}

/** This metric is used for the attributes that are coordinated to the asset */
export interface ICustomAttributes {
  id: string
  name: string
  value: string | null
}

/** This interface is for the asset profile */
export interface IRentalRates {
  hour?: number
  day?: number
  week?: number
  month?: number
  flat?: number
  showDay: boolean
  showFlat: boolean
  showHour: boolean
  showMonth: boolean
  showWeek: boolean
}

/** Used when updating the values of the rental page */
export interface IRentalRatesBody {
  hour: number | null
  day: number | null
  week: number | null
  month: number | null
  flat: number | null
  showHour: boolean
  showDay: boolean
  showWeek: boolean
  showMonth: boolean
  showFlat: boolean
}

export type TRateType = 'hour' | 'day' | 'week' | 'month' | 'flat'
export type TRateShow =
  | 'showHour'
  | 'showDay'
  | 'showWeek'
  | 'showMonth'
  | 'showFlat'

export const rateList: TRateType[] = ['hour', 'day', 'week', 'month', 'flat']

export const showKey: Record<TRateType, TRateShow> = {
  hour: 'showHour',
  day: 'showDay',
  week: 'showWeek',
  month: 'showMonth',
  flat: 'showFlat',
}

export const label: Record<TRateType, string> = {
  hour: 'Hour',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  flat: 'Flat',
}

export const labelDetailed: Record<TRateType, string> = {
  hour: 'Hour',
  day: 'Day',
  week: 'Week (M-F)',
  month: 'Month (4-Weeks)',
  flat: 'Flat',
}

export const labelPer: Record<TRateType, string> = {
  hour: 'Per Hour',
  day: 'Per Day',
  week: 'Per Week',
  month: 'Per Month',
  flat: 'Flat Rate',
}
