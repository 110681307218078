import React from 'react'
import { Popover, List, ListItem } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  paper: {
    maxHeight: 200,
  },
})

interface Props {
  anchorEl?: Element | ((element: Element) => Element) | null | undefined
  onClose: () => void
  tags: {
    id: string
    name: string
  }[]
  onClick: (id: string) => void
}

const TagsPopover = (props: Props) => {
  const classes = useStyles()

  return (
    <Popover
      classes={{
        paper: classes.paper,
      }}
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={props.onClose}
    >
      <List dense>
        {props.tags.map((t) => (
          <ListItem key={t.id} button onClick={() => props.onClick(t.id)}>
            {t.name}
          </ListItem>
        ))}
      </List>
    </Popover>
  )
}

export default TagsPopover
