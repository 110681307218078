import React from 'react'
import Button from '../buttons/Button'
import Tooltip from '@mui/material/Tooltip'
import Icon from '../Icon'
import { LoadingIcon } from '../LoadingOverlay'

export interface IImage {
  id: string
  viewUrl?: string
  url: string
  width: number
  height: number
}

interface IProps {
  images: Array<IImage>
  mainImageId?: string | null
  settingMainImgId?: string
  deletingImgId?: string
  handleSetMainImageClick?: (id: string) => void
  handleDeleteImageClick: (id: string) => void
  imageUploading: boolean
  handleChangeImages: (file: object) => void
  imageLimit: number
}

export default class ImageUploader extends React.Component<IProps> {
  state = {
    hoveredId: null,
  }

  render() {
    const {
      images,
      mainImageId,
      settingMainImgId,
      imageUploading,
      deletingImgId,
      imageLimit,
      handleSetMainImageClick,
      handleDeleteImageClick,
      handleChangeImages,
    } = this.props

    return (
      <div
        style={{
          margin: '20px 0',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          maxWidth: 282,
        }}
      >
        {images.map((image) => {
          const isHovered = this.state.hoveredId === image.id
          const isLoading =
            settingMainImgId === image.id || deletingImgId === image.id
          return (
            <div
              key={image.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 86,
                width: 86,
                margin: 2,
                padding: 2,
                border: '1px solid #ddd',
                borderRadius: 3,
              }}
              onMouseEnter={() => this.setState({ hoveredId: image.id })}
              onMouseLeave={() => this.setState({ hoveredId: null })}
            >
              {isLoading ? (
                <div
                  style={{
                    height: 64,
                    width: 64,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LoadingIcon loading />
                </div>
              ) : (
                <>
                  <div style={{ height: 64, width: 64, overflow: 'hidden' }}>
                    <a
                      href={image.viewUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={image.url}
                        width={image.width / 2}
                        height={image.height / 2}
                        alt="asset Images"
                      />
                    </a>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      visibility: isHovered ? 'visible' : 'hidden',
                      width: '100%',
                    }}
                  >
                    {handleSetMainImageClick &&
                      (!mainImageId || mainImageId !== image.id) && (
                        <Button
                          size="small"
                          onClick={() => handleSetMainImageClick(image.id)}
                          style={{ padding: 0, whiteSpace: 'nowrap' }}
                        >
                          Set Main
                        </Button>
                      )}
                    <Icon
                      fontSize="small"
                      icon="delete"
                      onClick={() => handleDeleteImageClick(image.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </>
              )}
            </div>
          )
        })}

        {imageUploading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 86,
              width: 86,
              margin: 2,
              padding: 2,
              border: '1px solid #ddd',
              borderRadius: 3,
            }}
          >
            <LoadingIcon loading />
          </div>
        ) : (
          <Tooltip
            title={
              images.length >= imageLimit ? 'Limit of five images reached.' : ''
            }
          >
            <span>
              <Button
                size="small"
                component="label"
                disabled={images.length >= imageLimit}
              >
                <Icon icon="add" variant="button" />
                Image
                <input
                  id="imageFile"
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={({ target }) => {
                    target.files && handleChangeImages(target.files[0])
                  }}
                  disabled={images.length >= imageLimit}
                />
              </Button>
            </span>
          </Tooltip>
        )}
      </div>
    )
  }
}
