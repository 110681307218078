import React from 'react'
import '../../styles/components/accountComponents/RentalTermsCard.scss'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '../buttons/IconButton'
import MessageOverlay from '../ux/MessageOverlay'
import EditRentalsDialog from './dialogs/EditRentalsDialog'

interface IProps {
  rentalTerms?: string
  loadingData: boolean
}

interface IState {
  dialogOpen: boolean
}

class RentalTermsCard extends React.Component<IProps, IState> {
  state: IState = {
    dialogOpen: false,
  }

  render() {
    return (
      <>
        <Card className="rentalTermsCard">
          <CardHeader
            action={
              <IconButton
                icon="edit"
                variant="row"
                tooltip="Edit"
                disabled={this.props.loadingData}
                onClick={() => this.setState({ dialogOpen: true })}
              />
            }
            title="Rental Terms"
          />
          <CardContent>
            {this.props.rentalTerms ? (
              <div className="rentalTermsCardContent">
                {this.props.rentalTerms}
              </div>
            ) : (
              <MessageOverlay stayInBox>None Specified</MessageOverlay>
            )}
          </CardContent>
        </Card>
        {this.props.loadingData ? null : (
          <EditRentalsDialog
            dialogOpen={this.state.dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
            rentalInformation={this.props.rentalTerms}
          />
        )}
      </>
    )
  }
}

export default RentalTermsCard
