import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import { IDeviceType } from '../../../types/account/devices'

interface IProps {
  dialogOpen: boolean
  onClose: () => void
  deviceInfo: IDeviceType
}

interface IState {}

class LearnMoreDialog extends React.Component<IProps, IState> {
  state: IState = {}

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose}>
        <DialogTitle>{this.props.deviceInfo.label}</DialogTitle>
        <DialogContent>
          Price: {this.props.deviceInfo.price} <br /> <br />
          {this.props.deviceInfo.description}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Close</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
          >
            Purchase
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default LearnMoreDialog
