import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Avatar from '@mui/material/Avatar'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import { IUser } from '../../../types/account/user'
import ButtonSideIcon from '../../buttons/ButtonSideIcon'
import { backend_api, headersAPI } from '../../../constants/api'

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxHeight: 400,
    },
    avatar: {
      background: theme.palette.primary.main,
      color: 'white',
    },
  })

interface IProps extends WithStyles<typeof styles> {
  includeUnassignOption: boolean
  selectedAssetIds: string[]
}

interface IState {
  users: IUser[]
  dialogOpen: boolean
}

class AssignUserDialog extends React.Component<IProps, IState> {
  state: IState = {
    dialogOpen: false,
    users: [],
  }

  handleClose = () => {
    this.setState({ dialogOpen: false })
  }

  handleOpen = () => {
    this.setState({ dialogOpen: true })
  }

  handleSubmit = () => {
    this.handleClose()
  }

  loadUsers() {
    fetch(backend_api + '/users/all', { method: 'GET', headers: headersAPI })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          users: response,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.loadUsers()
  }

  render() {
    return (
      <>
        <ButtonSideIcon
          startIcon="user"
          label="Assign User"
          onClick={this.handleOpen}
        />
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          PaperProps={{
            className: this.props.classes.paper,
          }}
        >
          <DialogTitle>Assign User</DialogTitle>
          <div>
            <List>
              {this.props.includeUnassignOption && (
                <ListItem button onClick={() => this.handleSubmit()}>
                  <ListItemText primary="- No User -" />
                </ListItem>
              )}
              {this.state.users.map((user) => (
                <ListItem
                  key={user.id}
                  button
                  onClick={() => this.handleSubmit()}
                >
                  <ListItemAvatar>
                    <Avatar className={this.props.classes.avatar}>
                      {user.firstName.charAt(0) + user.lastName.charAt(0)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.firstName + ' ' + user.lastName}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Dialog>
      </>
    )
  }
}

export default withStyles(styles)(AssignUserDialog)
