import React from 'react'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { Button, Theme } from '@mui/material'
import classNames from 'classnames'
import { Day } from 'src/constants/flagRules'

const days: Day[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

interface IDaysOfWeekFilterProps extends WithStyles<typeof styles> {
  days: Day[]
  onChange: (days: Day[]) => void
}

const DaysOfWeekFilter = (props: IDaysOfWeekFilterProps) => (
  <div className={props.classes.container}>
    {days.map((day) => {
      const isSelected = props.days.includes(day)
      return (
        <Button
          key={day}
          className={classNames(props.classes.button, {
            [props.classes.selectedButton]: isSelected,
          })}
          onClick={() => {
            if (isSelected) {
              props.onChange(props.days.filter((d) => d !== day))
            } else {
              props.onChange([...props.days, day])
            }
          }}
        >
          {day}
        </Button>
      )
    })}
  </div>
)

const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingTop: 4,
      paddingLeft: 4,
      paddingRight: 2,
      display: 'flex',
      flexWrap: 'wrap',
    },
    button: {
      marginRight: 2,
      marginBottom: 4,
      opacity: 0.54,
      background: theme.palette.grey[300],
      '&:hover': {
        background: theme.palette.grey[500],
      },
    },
    selectedButton: {
      opacity: 1,
      background: theme.palette.grey[400],
    },
  })
export default withStyles(styles)(DaysOfWeekFilter)
