import React from 'react'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Skeleton from '@mui/material/Skeleton'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    maxWidth: 260,
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

interface IProps {
  imageUrl?: string
  archived?: boolean
  name?: string
  link?: string
}

/**
 * Display an basic info for an equipment
 * You can pass null to display a skeleton (in case equipment hasn't loaded yet)
 * But only do this for an equipment that will eventually show
 */
const EquipChip = (props: IProps) => {
  const classes = useStyles()
  const name = props.name
    ? props.name
    : '' + props.archived
    ? ' (Archived)'
    : ''
  return (
    <div className={classes.container}>
      {props.name ? (
        <Avatar src={props.imageUrl} className={classes.avatar} />
      ) : (
        <Skeleton variant="circular" width={40} height={40} />
      )}
      <div className={classes.label}>
        {props.name ? (
          props.link ? (
            <Link to={props.link}>{name}</Link>
          ) : (
            <>{name}</>
          )
        ) : (
          <>{<Skeleton variant="rectangular" width={100} height={16} />}</>
        )}
      </div>
    </div>
  )
}

export default EquipChip
