import React from 'react'
import '../../styles/components/inventory/FieldRow.scss'
import { backend_api, headersAPI } from '../../constants/api'
import { View } from './FieldView'

const metricPath = '/assets/metrics'

// const importantMetrics = [
//   Field.LastUpdate,
//   Field.SignalStrength,
//   Field.Ignition,
//   Field.GPS,
//   Field.Fuel,
// ]

interface IState {
  importantfieldsLoaded?: Array<{
    field: string
    value: string | number | boolean
  }>
}

interface IProps {
  device: string
  fieldsLoaded?: Array<{
    field: string
    value: string | number | boolean
  }>
}

class FieldRow extends React.Component<IProps, IState> {
  state: IState = {
    importantfieldsLoaded: undefined,
  }

  grabImportantFields() {
    fetch(backend_api + metricPath + '?deviceId=' + this.props.device, {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          importantfieldsLoaded: response.metrics,
        })
      })
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    this.props.fieldsLoaded
      ? this.setState({ importantfieldsLoaded: this.props.fieldsLoaded })
      : this.grabImportantFields()
  }

  render() {
    return (
      <div className="fieldsContainer">
        {this.state.importantfieldsLoaded
          ? this.state.importantfieldsLoaded.map((val, key) => (
              <div key={key}>
                <View
                  field={val.field}
                  value={val.value}
                  equipId={this.props.device}
                />
              </div>
            ))
          : null}
      </div>
    )
  }
}

export default FieldRow
