import React from 'react'
import Typography from '@mui/material/Typography'
import Toolbar from '../../components/toolbar/Toolbar'
import ReactHelmet from '../../components/ReactHelmet'
import { ERoutes, PageTitle } from '../../components/routes/CheckRoutes'
import { QueryRenderer } from '@cubejs-client/react'
import LoadingOverlay from '../../components/LoadingOverlay'
import cubejsApi from '../../components/reportComponents/cubejsConfig'
import RangeDatePicker from '../../components/datePickers/RangeDatePicker'
import printJS from 'print-js'
import IconButton from '../../components/buttons/IconButton'
import moment from 'moment'
import { COLORS_SERIES } from '../../components/ux/roviTheme'
import { assetProfile, assetReport } from '../../constants/urlBuilder'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

// http://localhost:4000/#/build?query={%22measures%22:[%22TankMetrics.tanklevelPercentage%22],%22timeDimensions%22:[{%22dimension%22:%22TankMetrics.receivedTime%22,%22granularity%22:%22day%22,%22dateRange%22:%22This%20week%22}],%22order%22:{%22TankMetrics.tanklevelPercentage%22:%22desc%22},%22dimensions%22:[%22TankMetrics.assetId%22],%22filters%22:[],%22limit%22:1000,%22segments%22:[]}

interface IData {
  'TankMetrics.deviceEsn': string
  'TankMetrics.tanklevelPercentage': number
  'TankMetrics.timestamp': string
  'TankMetrics.timestamp.day': string
  'TankMetrics.assetDisplayName': string
  'TankMetrics.assetCategory': string
  'Assets.legacyId': string
}

interface IRow {
  backgroundColor: string
  borderColor: string
  data: number[]
  fill: boolean
  label: string
}

interface IProps {
  width: number
  height: number
  isMobile?: boolean
}

interface IState {
  currentDate: Date | null
  selectedDate: Array<Date | null>
  isBarChart: boolean
}

export default class FluidUsageOverTimeTable extends React.Component<
  IProps,
  IState
> {
  state: IState = {
    currentDate: new Date(),
    selectedDate: [moment().subtract(14, 'd').toDate(), new Date()],
    isBarChart: true,
  }

  /**
   * this is to allow for felxibility for the future, but essentially its to map through the dates,
   * insuring that the dates dont get messed up when we map into the past.
   */
  maxTotal =
    this.state.selectedDate[0] && this.state.selectedDate[1]
      ? parseInt(
          moment
            .duration(
              moment(this.state.selectedDate[1]).diff(
                moment(this.state.selectedDate[0]).startOf('day')
              )
            )
            .asDays()
            .toString()
        )
      : 0

  renderChart = ({ resultSet, error }: { resultSet: any; error: any }) => {
    if (error) {
      return <div>{error.toString()}</div>
    }

    if (!resultSet) {
      return <LoadingOverlay loading />
    }

    const data = {
      labels: resultSet.categories().map((c: { category: any }) => c.category),
      datasets: resultSet
        .series()
        .map((s: { title: any; series: any[] }, index: number) => ({
          label: s.title,
          data: s.series.map((r: { value: any }) => r.value),
          borderColor: COLORS_SERIES[index],
          backgroundColor: COLORS_SERIES[index],
          fill: false,
        })),
    }
    return (
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Category</TableCell>
            <TableCell className="tableCell">Equipment</TableCell>
            <TableCell className="tableCell">Monitoring ID</TableCell>
            {[
              ...Array(
                this.state.selectedDate[0] && this.state.selectedDate[1]
                  ? parseInt(
                      moment
                        .duration(
                          moment(this.state.selectedDate[1]).diff(
                            moment(this.state.selectedDate[0]).startOf('day')
                          )
                        )
                        .asDays()
                        .toString()
                    )
                  : 7
              ),
            ].map((_, tc: any) => (
              <TableCell key={tc + tc} className="tableCell">
                {/* {console.log(tc)} */}
                {moment(
                  this.state.selectedDate[1]
                    ? this.state.selectedDate[1]
                    : this.state.selectedDate[0]
                )
                  .subtract(tc, 'd')
                  .format('MMM DD, YYYY')}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.datasets.map((row: IRow, key: React.Key | null | undefined) => {
            const esn = row.label.split(',')[0]
            const assetInfo = resultSet.backwardCompatibleData[0].find(
              (d: IData) => d['TankMetrics.deviceEsn'] === esn
            )
            return (
              <TableRow hover={true} key={key}>
                <TableCell>{assetInfo['TankMetrics.assetCategory']}</TableCell>
                <TableCell>
                  <a href={assetProfile(assetInfo['Assets.legacyId'])}>
                    {assetInfo['TankMetrics.assetDisplayName']}
                  </a>
                </TableCell>
                <TableCell>{esn}</TableCell>
                {[
                  ...Array(
                    this.state.selectedDate[0] && this.state.selectedDate[1]
                      ? parseInt(
                          moment
                            .duration(
                              moment(this.state.selectedDate[1]).diff(
                                moment(this.state.selectedDate[0]).startOf(
                                  'day'
                                )
                              )
                            )
                            .asDays()
                            .toString()
                        )
                      : 7
                  ),
                ].map((_, key2: any) => {
                  // let val = 5

                  // console.log(esn)
                  const val = resultSet.backwardCompatibleData[0].find(
                    (d: IData) =>
                      d['TankMetrics.deviceEsn'] === esn &&
                      d['TankMetrics.timestamp'].split('T')[0] ===
                        moment(this.state.selectedDate[1])
                          .subtract(key2, 'd')
                          .format('YYYY-MM-DD')
                  )

                  const measureVal: number | null = val
                    ? val['TankMetrics.tanklevelPercentage']
                    : null

                  // its passed to frontend as seconds, need to reduce to hours
                  return (
                    <TableCell key={key + key2}>
                      {measureVal ? (
                        <a
                          href={assetReport(
                            assetInfo['Assets.legacyId'],
                            'Fluid Level Line'
                          )}
                        >
                          {measureVal < 0 ? '0%' : measureVal.toFixed(2) + '%'}
                        </a>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  render() {
    return (
      <div>
        <ReactHelmet title={PageTitle(ERoutes.FluidUsageOverTimeTable)} />
        <Toolbar
          title={PageTitle(ERoutes.FluidUsageOverTimeTable)}
          scrollBackgroundColor
          noPositionFixed
          isMobile={this.props.isMobile}
          addPaddingXtraBar="4px 10px"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: this.props.isMobile ? 'column' : undefined,
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h5">Tank Level Per Asset</Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {this.props.isMobile ? null : (
                <div style={{ paddingRight: 15 }}>
                  Showing the previous{' '}
                  {this.state.selectedDate[0] && this.state.selectedDate[1]
                    ? parseInt(
                        moment
                          .duration(
                            moment(this.state.selectedDate[1]).diff(
                              moment(this.state.selectedDate[0]).startOf('day')
                            )
                          )
                          .asDays()
                          .toString()
                      )
                    : 7}{' '}
                  days since
                </div>
              )}
              <RangeDatePicker
                currentDate={this.state.selectedDate}
                onAccept={(date: Array<Date | null>) => {
                  this.setState({ selectedDate: date })
                }}
                disableFuture
                showToolbar
              />
              <IconButton
                icon="print"
                variant="appbar"
                tooltip="Print/PDF Report"
                onClick={() => {
                  printJS({
                    printable: 'reportFluidUsageOverTime',
                    type: 'html',
                    targetStyles: ['*'],
                    honorColor: true,
                    documentTitle: 'Fluid Usage Over Time Report',
                  })
                }}
              />
            </div>
          </div>
        </Toolbar>

        <QueryRenderer
          query={{
            measures: ['TankMetrics.tanklevelPercentage'],
            timeDimensions: [
              {
                dimension: 'TankMetrics.timestamp',
                granularity: 'day',
              },
            ],
            order: {
              'TankMetrics.tanklevelPercentage': 'desc',
            },
            dimensions: [
              'TankMetrics.deviceEsn',
              'TankMetrics.assetDisplayName',
              'TankMetrics.assetCategory',
              'Assets.legacyId',
            ],
            filters: [
              {
                member: 'TankMetrics.tenantId',
                operator: 'equals',
                values: ['a18fa347-8d56-4e26-8d26-287c4be86770'],
              },
            ],
            limit: 1000,
            segments: [],
          }}
          cubejsApi={cubejsApi('a18fa347-8d56-4e26-8d26-287c4be86770')}
          resetResultSetOnChange={false}
          render={(props) => (
            <>
              <TableContainer
                component={Paper}
                square
                style={{
                  maxHeight: this.props.height,
                }}
              >
                {this.renderChart({
                  resultSet: props.resultSet,
                  error: props.error,
                })}
              </TableContainer>
              <div style={{ display: 'none' }}>
                <div
                  style={{
                    overflow: 'visible',
                  }}
                  id="reportFluidUsageOverTime"
                >
                  {this.renderChart({
                    resultSet: props.resultSet,
                    error: props.error,
                  })}
                </div>
              </div>
            </>
          )}
        />
      </div>
    )
  }
}

/** This is the component that needs to be exported as it is used on the array that renders all the reports */
export const FluidUsageOverTimeTableComp = (
  width: number,
  height: number,
  isMobile?: boolean
) => (
  <FluidUsageOverTimeTable width={width} height={height} isMobile={isMobile} />
)
