import React from 'react'
import moment from 'moment'
import AdapterMoment from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { DateRange } from '@mui/lab'
import Button from '../buttons/Button'

interface IProps {
  onApply?: (date1: Date | null, date2: Date | null) => void
  onAccept?: (date1: Date | null, date2: Date | null) => void
  currentDate?: Array<Date | null>
  onClose?: () => void
  disableFuture?: boolean
  showToolbar?: boolean
  showTodayButton?: boolean
  paddingSaveBar?: boolean
  isMobile?: boolean
}

function LargeStaticDateRangePicker(props: IProps) {
  const [date, setDate] = React.useState<DateRange<Date>>([
    props.currentDate ? props.currentDate[0] : null,
    props.currentDate ? props.currentDate[1] : null,
  ])
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StaticDateRangePicker
          displayStaticWrapperAs={props.isMobile ? 'mobile' : 'desktop'}
          value={date}
          onChange={(newValue) => setDate(newValue)}
          onAccept={() => props.onAccept && props.onAccept(date[0], date[1])}
          disableFuture={props.disableFuture}
          allowSameDateSelection
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} variant="standard" />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} variant="standard" />
            </React.Fragment>
          )}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: props.paddingSaveBar ? 10 : undefined,
          }}
        >
          {props.isMobile ? undefined : (
            <div style={{ width: '100%' }}>
              Range:{' '}
              {date[0] ? moment(date[0]).format('MMM DD, YYYY') : 'Start'} -{' '}
              {date[1] ? moment(date[1]).format('MMM DD, YYYY') : 'End'}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              onClick={() => {
                setDate([null, null])
                props.onClose && props.onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={!(date[0] && date[1])}
              onClick={() => props.onApply && props.onApply(date[0], date[1])}
            >
              Apply
            </Button>
          </div>
        </div>
      </LocalizationProvider>
    </>
  )
}

export default LargeStaticDateRangePicker
