import React from 'react'

const ToolbarDivider = () => (
  <div
    style={{
      display: 'inline-block',
      width: 1,
      background: '#dddddd',
      height: 32,
      margin: '0 4px',
    }}
  />
)

export default ToolbarDivider
