import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '../buttons/IconButton'
import Typography from '@mui/material/Typography'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import InfoPopup from './InfoPopup'
import purple from '@mui/material/colors/purple'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '../Icon'
import { ERoutes } from '../routes/CheckRoutes'
import { IFlagRule } from '../../constants/flagRules'

const styles = (theme: Theme) =>
  createStyles({
    card: {
      margin: 5,
      maxWidth: 600,
      minWidth: 300,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    infoIcon: {
      marginRight: 4,
    },
    assetCount: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    centerCount: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headerContent: {
      overflow: 'hidden',
    },
    headerTitle: {
      overflow: 'hidden',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
    },
    purpleRect: {
      background: purple[300],
      position: 'absolute',
      height: '100%',
      width: 6,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  flag: IFlagRule
  useMetric: boolean
  assets: Array<{}>
  canManage: boolean
  isFocused: boolean
  onEditFlag?: (flag: IFlagRule) => void
  onDeleteFlag?: (flag: IFlagRule) => void
}

class FlagCard extends React.Component<IProps> {
  ref = React.createRef<HTMLDivElement>()

  state = {
    anchorEl: null,
  }

  componentDidMount() {
    if (this.props.isFocused) {
      this.scrollIntoView()
    }
  }

  scrollIntoView = () => {
    if (this.ref.current) {
      this.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    }
  }

  handleClickMenu = (ev: any) => {
    this.setState({
      anchorEl: ev.currentTarget,
    })
  }

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
    })
  }

  render() {
    return (
      <>
        <Card className={this.props.classes.card} ref={this.ref}>
          <div className={this.props.classes.purpleRect} />
          <CardHeader
            classes={{
              content: this.props.classes.headerContent,
              title: this.props.classes.headerTitle,
            }}
            title={
              <>
                <InfoPopup className={this.props.classes.infoIcon} />
                <div
                  className={this.props.classes.title}
                  title={this.props.flag.name}
                >
                  {this.props.flag.name}
                </div>
              </>
            }
            action={
              <IconButton
                icon="overflowMenu"
                variant="row"
                onClick={this.handleClickMenu}
              />
            }
          />

          <CardContent className={this.props.classes.cardContent}>
            <div className={this.props.classes.centerCount}>
              <Typography variant="h4" color="textSecondary">
                -
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {this.props.flag.name === '3' ? 'Areas' : 'Things'}
              </Typography>
            </div>
          </CardContent>
        </Card>

        <Menu
          anchorEl={this.state.anchorEl}
          open={!!this.state.anchorEl}
          onClose={this.handleCloseMenu}
          disableAutoFocusItem
        >
          <MenuItem dense component="a" href={ERoutes.subscriptions}>
            <ListItemIcon>
              <Icon icon="subscription" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Subscribe" />
          </MenuItem>

          {this.props.onEditFlag && (
            <MenuItem
              onClick={() => {
                this.props.onEditFlag!(this.props.flag)
                this.handleCloseMenu()
              }}
              dense
            >
              <ListItemIcon>
                <Icon icon="edit" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>
          )}

          {this.props.onDeleteFlag && (
            <MenuItem
              onClick={() => {
                this.props.onDeleteFlag!(this.props.flag)
                this.handleCloseMenu()
              }}
              dense
            >
              <ListItemIcon>
                <Icon icon="delete" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
          )}
        </Menu>
      </>
    )
  }
}

export default withStyles(styles)(FlagCard)

interface IPhantomFlagCard extends WithStyles<typeof styles> {}

/**
 * Invisible box for keeping flex FlagCards the same size.
 */
export const PhantomFlagCard = withStyles(styles)((props: IPhantomFlagCard) => (
  <div className={props.classes.card} />
))
