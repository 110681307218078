import React from 'react'
import '../../styles/components/BranchMenu.scss'
import ButtonPopover from '../buttons/ButtonPopover'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import BranchList from './BranchList'
import { ERoutes } from '../routes/CheckRoutes'

interface IState {
  anchorEl: any
}

interface IProps {
  title: string
}

class BranchMenu extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: null,
  }

  render() {
    return (
      <ButtonPopover
        title={this.props.title}
        icon="account"
        variant="text"
        buttonMinWidth={180}
      >
        <div className="branchMenu">
          <ListSubheader color="inherit">{this.props.title}</ListSubheader>

          <Divider />

          <BranchList />

          <Divider />

          <List className="branchList">
            <ListSubheader>Manage</ListSubheader>
            <ListItem
              button
              component="a"
              href={ERoutes.account}
              id="roviBranchMenuManageAccount"
            >
              <ListItemText primary="Account" />
            </ListItem>
            <ListItem
              button
              component="a"
              href={ERoutes.archive}
              id="roviBranchMenuManageArchive"
            >
              <ListItemText primary="Archive" />
            </ListItem>
            <ListItem
              button
              component="a"
              href={ERoutes.attribute}
              id="roviBranchMenuManageAttributes"
            >
              <ListItemText primary="Attributes" />
            </ListItem>
            <ListItem
              button
              component="a"
              href={ERoutes.categories}
              id="roviBranchMenuManageCategories"
            >
              <ListItemText primary="Categories" />
            </ListItem>
            <ListItem
              button
              component="a"
              href={ERoutes.flag}
              id="roviBranchMenuManageFlags"
            >
              <ListItemText primary="Flags" />
            </ListItem>
            <ListItem
              button
              component="a"
              href={ERoutes.subscriptions}
              id="roviBranchMenuManageSubscriptions"
            >
              <ListItemText primary="Subscriptions" />
            </ListItem>
            <ListItem
              button
              component="a"
              href={ERoutes.tutorials}
              id="roviBranchMenuManageTutorials"
            >
              <ListItemText primary="Tutorials" />
            </ListItem>
          </List>
        </div>
      </ButtonPopover>
    )
  }
}

export default BranchMenu
