import React from 'react'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Icon from '../../../Icon'
import { colors } from '../../../ux/roviTheme'

interface IProps {
  siteName: string
  siteAssetCount: number
  onClick: () => void
  onHoverIn?: () => void
  onHoverOut?: () => void
}

const countSites = (siteAssetCount: number) => {
  switch (siteAssetCount) {
    case 0:
      return 'No assets'
    case 1:
      return `${siteAssetCount} asset`
    default:
      return `${siteAssetCount} assets`
  }
}

const SiteListItem: React.FunctionComponent<IProps> = (props) => (
  <>
    <ListItem
      button
      onClick={props.onClick}
      onMouseEnter={props.onHoverIn}
      onMouseLeave={props.onHoverOut}
    >
      <ListItemIcon>
        <Icon style={{ color: colors.defaultText }} icon="site" />
      </ListItemIcon>
      <ListItemText
        primary={props.siteName}
        secondary={countSites(props.siteAssetCount)}
      />
    </ListItem>
    <Divider variant="inset" style={{ marginRight: 10 }} />
  </>
)

export default SiteListItem
