import React from 'react'

const style: React.CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

interface IEllipsisTextProps {
  text?: string
  width?: number | string
  style?: React.CSSProperties
  className?: string
}

const EllipsisText: React.FunctionComponent<IEllipsisTextProps> = (props) => (
  <div
    title={props.text}
    className={props.className}
    style={{ ...style, width: props.width, ...props.style }}
  >
    {props.children ? props.children : props.text}
  </div>
)

export default EllipsisText
