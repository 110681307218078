import React from 'react'
import '../../styles/components/equipmentComponents/equipmentProfileToolbar.scss'
import WidthViewport from '../ux/WidthViewport'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '../buttons/Button'
import { WeatherTooltip } from './weatherDisplay'
import { colors } from '../ux/roviTheme'
import IconButton from '../buttons/IconButton'
import { ERoutes } from '../routes/CheckRoutes'
import { IEquipmentProfile } from '../../types/equipment/equipmentProfile'
import AvailabilityControl from './AvailabilityControl'
import EditDuplicateAssetDialog from './assetDialog/EditDuplicateAssetDialog'
import ArchiveAssetDialog from './assetDialog/ArchiveAssetDialog'
import MarkAsSold from './assetDialog/MarkAsSold'
import BoundarySegment from './BoundarySegment'
import DeviceCalibration from './assetDialog/DeviceCalibration'
import CreateServiceTicket from './assetDialog/CreateServiceTicket'

enum buttonDialogVal {
  edit = 'edit',
  duplicate = 'duplicate',
  archive = 'archive',
  markSold = 'markSold',
  calibration = 'calibration',
  serviceCheckIn = 'serviceCheckIn',
}

interface IProps {
  equipmentProfile?: IEquipmentProfile
  useMetric: boolean
  isMobile: boolean
}

interface IState {
  anyDialogOpened?: string
}

class EquipmentProfileToolbar extends React.Component<IProps, IState> {
  state: IState = {}

  buttons = (assetId: string) => [
    {
      name: 'Edit',
      dialogName: buttonDialogVal.edit,
    },
    { name: 'Archive', dialogName: buttonDialogVal.archive },
    /** Needs to link to the new Service Dialog */
    { name: 'Service Ticket', href: ERoutes.ticket + '/' + assetId },
    { name: 'Service Check-In', dialogName: buttonDialogVal.serviceCheckIn },
    { name: 'Duplicate', dialogName: buttonDialogVal.duplicate },
    { name: 'Mark as Sold', dialogName: buttonDialogVal.markSold },
    { name: 'History', href: ERoutes.history },
    {
      name: 'Service Log',
      href: ERoutes.ticketLog + '/' + assetId,
    },
    { name: 'Device Calibration', dialogName: buttonDialogVal.calibration },
  ]

  closeDialog = () => {
    this.setState({ anyDialogOpened: undefined })
  }

  render() {
    const equipmentProfile = this.props.equipmentProfile
    const make = equipmentProfile?.make
    const model = equipmentProfile?.model
    const agreement = equipmentProfile?.agreement
    const soldTo = equipmentProfile?.soldTo

    return (
      <div
        className="equipToolbarOuterBox"
        style={{ backgroundColor: colors.dbg, zIndex: 1 }}
      >
        <WidthViewport>
          {({ isMobile }) =>
            isMobile ? null : (
              <div style={{ width: 960, padding: '0px 20px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: 5,
                    color: colors.defaultText,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h5">
                      {make} / {model}
                    </Typography>
                    <div>
                      {soldTo ? (
                        <Typography>-</Typography>
                      ) : agreement ? (
                        <AvailabilityControl
                          /**
                           * @TODO shouldn't be hard coded
                           */
                          agreementUrl={`/app/clients/${agreement.client.id}/agreements/${agreement.agreementId}`}
                          clientName={agreement.client.name}
                          siteName={agreement.site.name}
                          /**
                           * @TODO shouldn't be hard coded
                           */
                          siteUrl={`/app/clients/${agreement.client.id}/sites/${agreement.site.id}`}
                          clientUrl={`/app/clients/${agreement.client.id}`}
                          /**
                           * @TODO need to connect it to access !access.features.clients
                           */
                          clientDisabled={false}
                        />
                      ) : (
                        <Typography>Available</Typography>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <BoundarySegment
                        equipmentProfile={this.props.equipmentProfile}
                      />
                      {/**
                       * Desktop Weather component
                       */}
                      {this.props.equipmentProfile ? (
                        <WeatherTooltip
                          weather={{
                            summary: 'Partly Cloudy',
                            temperature: 71.6,
                            sunlightTime: 'Partly Cloudy',
                            cloudCover: 33,
                          }}
                          useMetric={this.props.useMetric}
                          equipmentProfile={this.props.equipmentProfile}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <Paper
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 42.56,
                    marginTop: 4,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div>
                      {this.buttons(
                        this.props.equipmentProfile
                          ? this.props.equipmentProfile.uuid
                          : ''
                      ).map((val, key) => (
                        <Button
                          size="small"
                          style={{ minWidth: 40 }}
                          key={val.name + key}
                          onClick={() =>
                            this.setState({ anyDialogOpened: val.dialogName })
                          }
                          href={val.href}
                          disabled={!this.props.equipmentProfile}
                        >
                          {val.name}
                        </Button>
                      ))}
                    </div>
                    <div>
                      <IconButton
                        icon="timeline"
                        tooltip="Timeline"
                        variant="toolbar"
                      />
                      <IconButton
                        icon="events"
                        tooltip="Events"
                        variant="toolbar"
                      />
                      <IconButton
                        icon="maptrac"
                        tooltip="Maptrac"
                        variant="toolbar"
                      />
                    </div>
                  </div>
                </Paper>
                {/* All the Dialogs to be placed under here and they wont render until data for the asset loads */}
                {this.props.equipmentProfile ? (
                  <>
                    {this.state.anyDialogOpened === buttonDialogVal.edit ? (
                      <EditDuplicateAssetDialog
                        dialogOpen={
                          this.state.anyDialogOpened === buttonDialogVal.edit
                        }
                        onClose={this.closeDialog}
                        isMobile={isMobile}
                        equipmentProfile={this.props.equipmentProfile}
                      />
                    ) : null}
                    {this.state.anyDialogOpened ===
                    buttonDialogVal.duplicate ? (
                      <EditDuplicateAssetDialog
                        dialogOpen={
                          this.state.anyDialogOpened ===
                          buttonDialogVal.duplicate
                        }
                        onClose={this.closeDialog}
                        duplicate
                        isMobile={isMobile}
                        equipmentProfile={this.props.equipmentProfile}
                      />
                    ) : null}
                    {this.state.anyDialogOpened === buttonDialogVal.archive ? (
                      <ArchiveAssetDialog
                        dialogOpen={
                          this.state.anyDialogOpened === buttonDialogVal.archive
                        }
                        onClose={this.closeDialog}
                        assetName={this.props.equipmentProfile.name}
                        assetId={this.props.equipmentProfile.id}
                      />
                    ) : null}
                    {this.state.anyDialogOpened === buttonDialogVal.markSold ? (
                      <MarkAsSold
                        dialogOpen={
                          this.state.anyDialogOpened ===
                          buttonDialogVal.markSold
                        }
                        onClose={this.closeDialog}
                        assetName={this.props.equipmentProfile.name}
                        assetId={this.props.equipmentProfile.id}
                      />
                    ) : null}
                    {this.state.anyDialogOpened ===
                    buttonDialogVal.calibration ? (
                      <DeviceCalibration
                        dialogOpen={
                          this.state.anyDialogOpened ===
                          buttonDialogVal.calibration
                        }
                        onClose={this.closeDialog}
                        assetName={this.props.equipmentProfile.name}
                        assetId={this.props.equipmentProfile.id}
                        sensors={this.props.equipmentProfile.sensors}
                        useMetric={this.props.useMetric}
                      />
                    ) : null}
                    {this.state.anyDialogOpened ===
                    buttonDialogVal.serviceCheckIn ? (
                      <CreateServiceTicket
                        dialogOpen={
                          this.state.anyDialogOpened ===
                          buttonDialogVal.serviceCheckIn
                        }
                        onClose={this.closeDialog}
                        assetName={this.props.equipmentProfile.name}
                        assetId={this.props.equipmentProfile.id}
                        isMobile={this.props.isMobile}
                        tasks={this.props.equipmentProfile.tasks}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            )
          }
        </WidthViewport>
      </div>
    )
  }
}

export default EquipmentProfileToolbar
