import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import ColorPicker from '../../ux/ColorPicker'
import IconPicker from '../../ux/IconPicker'
import InputLabel from '@mui/material/InputLabel'
import { TCategoryIcons } from '../../../types/category'

const styles = (theme: Theme) =>
  createStyles({
    TextFieldMobile: {
      width: '100%',
      maxWidth: 256,
      marginBottom: 0,
    },
    TextField: {
      width: 256,
      marginBottom: 0,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  type: 'edit' | 'add'
  isMobile?: boolean
  categoryName?: string
  categoryColor?: string
  categoryIcon?: TCategoryIcons
}

interface IState {
  name: string
  color: string
  icon: TCategoryIcons
}

class AddEditCategoryDialog extends React.Component<IProps, IState> {
  state: IState = {
    name: this.props.categoryName ? this.props.categoryName : '',
    color: this.props.categoryColor ? this.props.categoryColor : '#689f38',
    icon: this.props.categoryIcon
      ? this.props.categoryIcon
      : 'lightTowerTrailer',
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  isSaveButtonDisabled() {
    return !this.state.name && !this.state.icon && !this.state.color
  }

  render() {
    return (
      <Dialog
        open={this.props.dialogOpen}
        onClose={this.handleClose}
        fullWidth={this.props.isMobile}
      >
        <DialogTitle>
          {this.props.type === 'edit' ? 'Edit' : 'Add'} Category
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Name"
            required
            className={
              this.props.isMobile
                ? this.props.classes.TextFieldMobile
                : this.props.classes.TextField
            }
            value={this.state.name}
            onChange={(event) => this.setState({ name: event.target.value })}
            id="CategoryNameInputField"
          />
          <div style={{ marginTop: 20, display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <InputLabel shrink required>
                Color
              </InputLabel>
              <ColorPicker
                id="categoryColor"
                color={this.state.color}
                onChange={(color) => this.setState({ color: color })}
                isMobile={this.props.isMobile}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <InputLabel shrink required>
                Icon
              </InputLabel>
              <IconPicker
                id="iconPickerButton"
                icon={this.state.icon}
                onChange={(icon) => this.setState({ icon: icon })}
                isMobile={this.props.isMobile}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AddEditCategoryDialog)
