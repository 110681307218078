enum ElectricPotentialType {}

type ElectricPotential = number & ElectricPotentialType

namespace ElectricPotential {
  const MILLIVOLTS_PER_VOLT = 1000

  export const ofMillivolts = (v: number): ElectricPotential =>
    v as ElectricPotential
  export const ofVolts = (v: number) =>
    ElectricPotential.ofMillivolts(v * MILLIVOLTS_PER_VOLT)

  export const toMillivolts = (v: ElectricPotential) => v as number
  export const toVolts = (v: ElectricPotential) =>
    ElectricPotential.toMillivolts(v) / MILLIVOLTS_PER_VOLT

  export enum Unit {
    Millivolts = 'mV',
    Volts = 'V'
  }

  const conversionLookup: {
    [key in Unit]: (val: ElectricPotential) => number
  } = {
    [Unit.Millivolts]: toMillivolts,
    [Unit.Volts]: toVolts
  }

  export const format = (val: ElectricPotential, unit: Unit = Unit.Volts) =>
    `${(Math.round(conversionLookup[unit](val) * 10) / 10).toFixed(1)}${unit}`
}

export default ElectricPotential
