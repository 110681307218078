import React from 'react'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { Theme, Button } from '@mui/material'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    line: {
      flex: 1,
      height: 1,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    root: {
      color: theme.palette.grey[600],
      fontSize: '0.75rem',
      '&:hover': {
        color: theme.palette.grey[700],
      },
    },
  })

interface ILineButtonProps extends WithStyles<typeof styles> {
  onClick: () => void
}

const LineButton: React.FC<ILineButtonProps> = (props) => (
  <div className={props.classes.container}>
    <div className={props.classes.line} />
    <Button size="small" onClick={props.onClick} className={props.classes.root}>
      {props.children}
    </Button>
    <div className={props.classes.line} />
  </div>
)

export default withStyles(styles)(LineButton)
