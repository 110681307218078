import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'

const styles = (theme: Theme) =>
  createStyles({
    paper: {},
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    formControl: {
      margin: theme.spacing(1),
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  fname?: string
  lname?: string
  email?: string
}

interface IState {
  fname: string
  lname: string
  email: string
}

class EditUserDialog extends React.Component<IProps, IState> {
  state: IState = {
    fname: this.props.fname ? this.props.fname : '',
    lname: this.props.lname ? this.props.lname : '',
    email: this.props.email ? this.props.email : '',
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  isSaveButtonDisabled() {
    return !(!!this.state.fname && !!this.state.lname && !!this.state.email)
  }

  componentDidMount() {}

  render() {
    console.log({
      fname: !!this.state.fname,
      lname: !!this.state.lname,
      email: !!this.state.email,
    })
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <DialogRow
            FieldLeft={
              <TextField
                label="First Name"
                required
                className={this.props.classes.TextField}
                value={this.state.fname}
                onChange={(event) =>
                  this.setState({ fname: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                label="Last Name"
                required
                className={this.props.classes.TextField}
                value={this.state.lname}
                onChange={(event) =>
                  this.setState({ lname: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="Email"
                required
                className={this.props.classes.TextField}
                value={this.state.email}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditUserDialog)
