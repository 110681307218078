export enum Field {
  ACFrequency = 'ac-frequency',
  ACVoltage1 = 'ac-voltage-1',
  ACVoltage2 = 'ac-voltage-2',
  ACVoltage3 = 'ac-voltage-3',
  ACVoltage4 = 'ac-voltage-4',
  ACVoltage5 = 'ac-voltage-5',
  AddressRegion = 'address-region',
  Altitude = 'altitude',
  AssetsInRange = 'assetsInRange',
  AverageFuelEconomy = 'average-fuel-economy',
  AxleWeight1 = 'axle-weight-1',
  AxleWeight2 = 'axle-weight-2',
  Battery = 'battery',
  CabTemp = 'cab-temperature',
  CompressorPressure = 'compressor-pressure',
  CoolantLevel = 'coolant-level',
  CoolantPressure = 'coolant-pressure',
  CoolantTemperature = 'coolant-temperature',
  Current1 = 'current1',
  Current2 = 'current2',
  Current3 = 'current3',
  DeviceBattery = 'device-battery',
  DeviceBatteryLevel = 'device-battery-level',
  DeviceBatteryState = 'device-battery-state',
  DeviceBatteryTemperature = 'device-battery-temperature',
  EngineRPM = 'engine-rpm',
  EngineHour = 'engineHour',
  EstimatedLatLng = 'estimated-lat-lng',
  Fuel = 'fuel',
  Fuel2 = 'fuel2',
  FuelConsumption = 'fuel-consumption',
  FuelTemperature = 'fuel-temperature',
  GeoRegions = 'geo-regions',
  GPS = 'gps',
  GreaseLevel = 'grease-level',
  Heading = 'heading',
  Humidity = 'humidity',
  Ignition = 'ignition',
  IgnitionRelay = 'ignition-relay',
  InMotion = 'in-motion',
  Input1 = 'input1',
  Input2 = 'input2',
  Input3 = 'input3',
  Input4 = 'input4',
  InstantFuelEconomy = 'instant-fuel-economy',
  IpEndpoint = 'ip-endpoint',
  LastUpdate = 'lastUpdate',
  Light1 = 'light1',
  Light2 = 'light2',
  Light3 = 'light3',
  Light4 = 'light4',
  Lights1Runtime = 'lights1-runtime',
  Lights2Runtime = 'lights2-runtime',
  Lights3Runtime = 'lights3-runtime',
  Lights4Runtime = 'lights4-runtime',
  ObdSpeed = 'obd-speed',
  Odometer = 'odometer',
  OilLevel = 'oil-level',
  OilPressure = 'oil-pressure',
  OilTemperature = 'oil-temperature',
  PTO1 = 'pto1',
  PTO2 = 'pto2',
  PTO3 = 'pto3',
  PTO4 = 'pto4',
  SignalStrength = 'signalStrength',
  SolarBattery1 = 'solar-battery1',
  SolarBattery2 = 'solar-battery2',
  Speed = 'speed',
  TimeZone = 'time-zone',
  TotalFuelEconomy = 'total-fuel-economy',
  TripMaxAcceleration = 'max-acceleration',
  TripMaxSpeed = 'max-speed',
  TripMinAcceleration = 'min-acceleration',
  TripOdometer = 'trip-odometer',
  TripRuntime = 'trip-runtime',
}

export const title: Record<Field, string> = {
  [Field.ACFrequency]: 'AC Frequency',
  [Field.ACVoltage1]: 'L1',
  [Field.ACVoltage2]: 'T6',
  [Field.ACVoltage3]: 'R2',
  [Field.ACVoltage4]: 'Limit',
  [Field.ACVoltage5]: 'Solenoid Valve',
  [Field.AddressRegion]: 'Address Region',
  [Field.Altitude]: 'Altitude',
  [Field.AssetsInRange]: 'Tagged Assets',
  [Field.AverageFuelEconomy]: 'Average Fuel Economy',
  [Field.AxleWeight1]: 'Axle Weight 1',
  [Field.AxleWeight2]: 'Axle Weight 2',
  [Field.Battery]: 'Battery Voltage',
  [Field.CabTemp]: 'Cab Temperature',
  [Field.CompressorPressure]: 'PSI',
  [Field.CoolantLevel]: 'Coolant Level',
  [Field.CoolantPressure]: 'Coolant Pressure',
  [Field.CoolantTemperature]: 'Coolant Temperature',
  [Field.Current1]: 'Current 1',
  [Field.Current2]: 'Current 2',
  [Field.Current3]: 'Current 3',
  [Field.DeviceBattery]: 'Device Battery',
  [Field.DeviceBatteryLevel]: 'Device Battery Percentage',
  [Field.DeviceBatteryState]: 'Device Battery',
  [Field.DeviceBatteryTemperature]: 'Device Battery Temperature',
  [Field.EngineRPM]: 'Engine RPM',
  [Field.EngineHour]: 'Engine Hour Meter',
  [Field.EstimatedLatLng]: 'Estimated Lat/Lng',
  [Field.Fuel]: 'Fuel',
  [Field.Fuel2]: 'Fuel 2',
  [Field.FuelConsumption]: 'Fuel Consumption',
  [Field.FuelTemperature]: 'Fuel Temperature',
  [Field.GeoRegions]: 'Geo Regions',
  [Field.GPS]: 'GPS',
  [Field.GreaseLevel]: 'Grease Level',
  [Field.Heading]: 'Heading',
  [Field.Humidity]: 'Humidity',
  [Field.Ignition]: 'Ignition',
  [Field.IgnitionRelay]: 'IGN State',
  [Field.InMotion]: 'Motion',
  [Field.Input1]: 'Input1',
  [Field.Input2]: 'Input2',
  [Field.Input3]: 'Input3',
  [Field.Input4]: 'Input4',
  [Field.InstantFuelEconomy]: 'Instant Fuel Economy',
  [Field.IpEndpoint]: 'IP Endpoint',
  [Field.LastUpdate]: 'Last Time Device Updated',
  [Field.Light1]: 'Light 1',
  [Field.Light2]: 'Light 2',
  [Field.Light3]: 'Light 3',
  [Field.Light4]: 'Light 4',
  [Field.Lights1Runtime]: 'Light 1 Hour Meter',
  [Field.Lights2Runtime]: 'Light 2 Hour Meter',
  [Field.Lights3Runtime]: 'Light 3 Hour Meter',
  [Field.Lights4Runtime]: 'Light 4 Hour Meter',
  [Field.ObdSpeed]: 'OBD Speed',
  [Field.Odometer]: 'Odometer',
  [Field.OilLevel]: 'Oil Level',
  [Field.OilPressure]: 'Oil Pressure',
  [Field.OilTemperature]: 'Oil Temperature',
  [Field.PTO1]: 'PTO 1',
  [Field.PTO2]: 'PTO 2',
  [Field.PTO3]: 'PTO 3',
  [Field.PTO4]: 'PTO 4',
  [Field.SignalStrength]: 'Signal Strength',
  [Field.SolarBattery1]: 'Solar Battery 1',
  [Field.SolarBattery2]: 'Solar Battery 2',
  [Field.Speed]: 'Speed',
  [Field.TimeZone]: 'Time Zone',
  [Field.TotalFuelEconomy]: 'Total Fuel Economy',
  [Field.TripMaxAcceleration]: 'Max. Acceleration',
  [Field.TripMaxSpeed]: 'Max. Speed',
  [Field.TripMinAcceleration]: 'Min. Acceleration',
  [Field.TripOdometer]: 'Trip Odometer',
  [Field.TripRuntime]: 'Trip Hour Meter',
}
