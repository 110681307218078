import React from 'react'
import {
  ListItemIcon,
  ListItemText,
  ListSubheader,
  List,
  ListItem,
  Divider,
} from '@mui/material'
import Icon from '../../Icon'
import Popover from '@mui/material/Popover'
import { backend_api, headersAPI } from '../../../constants/api'
import { IDevices } from '../../../types/account/devices'
import { PaddedSearchBar } from '../../textBoxs/SearchBar'
import { Link } from 'react-router-dom'
import routes from '../../routes/routes'
import { colors } from '../../ux/roviTheme'

interface IProps {
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  isInventory?: boolean
  addDevice?: (newDevice: IDevices) => void
  addPhone?: () => void
}

interface IState {
  allDevices: { deviceModel: string; devices: IDevices[] }[]
  devicesLoading: boolean
  deviceSearch: string
}

class AddDeviceComponent extends React.Component<IProps, IState> {
  state: IState = {
    allDevices: [],
    devicesLoading: true,
    deviceSearch: '',
  }

  handleClose = () => {
    this.props.onClose()
  }

  grabAllDevicesOnAccount() {
    fetch(backend_api + '/devices/all/bytype/160', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) =>
        this.setState({
          allDevices: response,
          devicesLoading: false,
        })
      )
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabAllDevicesOnAccount()
  }

  render() {
    return (
      <Popover
        open={!!this.props.anchorEl}
        anchorEl={this.props.anchorEl}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PaddedSearchBar
          value={this.state.deviceSearch}
          onChange={(search) => this.setState({ deviceSearch: search })}
        />
        <List
          style={{ height: 300, width: 250, overflow: 'auto', paddingTop: 0 }}
        >
          {this.state.allDevices.map((devicesType) => (
            <>
              <ListSubheader style={{ backgroundColor: '#fff' }}>
                {devicesType.deviceModel}
              </ListSubheader>
              {devicesType.devices
                .filter((ad) =>
                  ad.esn
                    .toLowerCase()
                    .includes(this.state.deviceSearch.toLowerCase())
                )
                .map((d) => (
                  <ListItem
                    button
                    onClick={() =>
                      this.props.addDevice ? this.props.addDevice(d) : null
                    }
                    key={d.esn}
                  >
                    <ListItemText>{d.esn}</ListItemText>
                  </ListItem>
                ))}
            </>
          ))}
          <Divider />
          <Link
            to={routes.gotoDevicesPage}
            style={{ color: colors.defaultText }}
          >
            <ListItem button>
              <ListItemIcon>
                <Icon icon="add" />
              </ListItemIcon>
              <ListItemText>Purchase Device</ListItemText>
            </ListItem>
          </Link>
          {this.props.isInventory ? null : (
            <ListItem button onClick={this.props.addPhone}>
              <ListItemIcon>
                <Icon icon="add" />
              </ListItemIcon>
              <ListItemText>Mobile Phone App</ListItemText>
            </ListItem>
          )}
        </List>
      </Popover>
    )
  }
}

export default AddDeviceComponent
