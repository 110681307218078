export const isLive =
  window.location.hostname === 'app.rovitracker.cloud' ? true : false

/** Just the base headers for each REST fetch call */
export const headersAPI = new Headers({ 'Content-Type': 'application/json' })

export const runtime =
  'https://xbdg8hwdgl.execute-api.us-east-1.amazonaws.com/prod/get/runtimeReport'

export const runtimeSite =
  'https://roidbienw3.execute-api.us-east-1.amazonaws.com/dev/get/runtimeReportBySite'

export const speedReport =
  'https://xbdg8hwdgl.execute-api.us-east-1.amazonaws.com/prod/get/speedReport'

export const disconnectReport =
  'https://xbdg8hwdgl.execute-api.us-east-1.amazonaws.com/prod/get/disconnectReport'

/**
 * this is the postman mock server host name
 */
export const mockApiHost =
  'https://c36b87ec-8207-4016-bc57-51da60c30be6.mock.pstmn.io'

/**
 * this is the mockoon mock server host name
 * https://mockoon.com/
 * based off and enviroment value
 */
export const backend_api = process.env.REACT_APP_BACKEND_API

/**
 * The Main API call, it will swap depending if the server is live or not?
 */
export const api = isLive
  ? 'https://api.rovitracker.cloud'
  : 'https://api.dev.rovitracker.cloud'

export default backend_api
