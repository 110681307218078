import React from 'react'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import purple from '@mui/material/colors/purple'

const styles = (theme: Theme) =>
  createStyles({
    flag: {
      padding: '1px 4px',
      borderRadius: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 11,
      color: theme.palette.common.white,
      fontWeight: 480,
      background: purple[300],
    },
  })

interface IFlagBoxProps extends WithStyles<typeof styles> {
  name: string
  className?: string
  onMouseEnter?: (ev: any) => void
  onMouseLeave?: (ev: any) => void
}

export const FlagBox = withStyles(styles)((props: IFlagBoxProps) => (
  <div
    className={props.classes.flag}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
  >
    {props.name}
  </div>
))

interface IProps extends WithStyles<typeof styles> {
  flagId: string
  name: string
  className?: string
}

/**
 * Must be class to access innerRef - don't make functional component
 */
class Flag extends React.Component<IProps> {
  render() {
    return (
      <a className={this.props.className} href="/">
        <FlagBox name={this.props.name} />
      </a>
    )
  }
}

export default withStyles(styles)(Flag)
