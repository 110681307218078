import React, { Suspense } from 'react'
import '../styles/pages/Inventory.scss'
import NoAccessPage from './NoAccessPage'
import SideAppBar from '../components/sideAppBar/SideAppBar'
import LoadingOverlay from '../components/LoadingOverlay'
import HashStateManager from '../rx-js/HashStateManager'
import {
  ServiceHashFromString,
  ServiceHashToString,
  IServiceHash,
  IServiceTabs,
  EServiceTabs,
} from '../constants/serviceHashController'
import TicketsPage from './service/TicketsPage'
import TasksPage from './service/TasksPage'
import PartsPage from './service/PartsPage'
// TODO: JA: When React implements lazy loading to server rendering we should reimplement this then

const serviceTabs = [
  {
    value: EServiceTabs.tickets,
    label: 'Tickets',
    icon: 'service',
  },
  {
    value: EServiceTabs.tasks,
    label: 'Tasks',
    icon: 'serviceTask',
  },
  {
    value: EServiceTabs.parts,
    label: 'Parts',
    icon: 'parts',
  },
]

interface IState {
  selectedTab: string
  addDialogOpen: boolean
  addTaskDialogOpen: boolean
}

interface IProps {}

class Service extends React.Component<IProps, IState> {
  state: IState = {
    selectedTab: '',
    addDialogOpen: false,
    addTaskDialogOpen: false,
  }

  hash: HashStateManager<IServiceHash> = new HashStateManager(
    ServiceHashFromString,
    ServiceHashToString
  )

  setTab = (tab: IServiceTabs) => {
    this.setState({ selectedTab: tab })
    this.hash.set({
      ...this.hash.value,
      tab,
    })
  }

  lazyLoadService(selectedTab: string) {
    switch (selectedTab) {
      case EServiceTabs.tickets:
        return <TicketsPage hash={this.hash} />
      case EServiceTabs.tasks:
        return (
          <TasksPage
            addTaskDialogOpen={this.state.addTaskDialogOpen}
            onClose={() => this.setState({ addTaskDialogOpen: false })}
          />
        )
      case EServiceTabs.parts:
        return (
          <PartsPage
            addDialogOpen={this.state.addDialogOpen}
            onClose={() => this.setState({ addDialogOpen: false })}
          />
        )
      default:
        return <NoAccessPage Error="404 Not Found" />
    }
  }

  whichOnClickFunction(selectedTab: string) {
    switch (selectedTab) {
      case EServiceTabs.tasks:
        return this.setState({ addTaskDialogOpen: true })
      case EServiceTabs.parts:
        return this.setState({ addDialogOpen: true })
    }
  }

  componentDidMount() {
    const hashgrabbed: IServiceHash = this.hash.value
    this.setTab(hashgrabbed.tab ? hashgrabbed.tab : EServiceTabs.tickets)
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '100vw' }}>
        <SideAppBar
          selectedTab={this.state.selectedTab}
          tabs={serviceTabs}
          onChangeTab={(val: string) => this.setTab(val as IServiceTabs)}
          addFab
          FabDisabled={this.state.selectedTab === EServiceTabs.tickets}
          onClick={() => this.whichOnClickFunction(this.state.selectedTab)}
        >
          <Suspense fallback={<LoadingOverlay loading delayedTime={5000} />}>
            {this.lazyLoadService(this.state.selectedTab)}
          </Suspense>
        </SideAppBar>
      </div>
    )
  }
}

export default Service
