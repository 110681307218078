import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import Select, { ISelectValues } from '../../ux/Select'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import InfoHover from '../../buttons/InfoHover'
import IconButton from '../../buttons/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { RegexPasswordCheck } from '../../../utility/regex'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import {
  TFeatureAccess,
  allFeatures,
  label,
  allRoles,
  TRole,
} from '../../../types/account/user'

const styles = (theme: Theme) =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    SelectField: {
      width: 250,
    },
    formControl: {
      margin: theme.spacing(1),
    },
  })

function SortRoleSelectValues(allRolesTotal: TRole[]) {
  let selectRoles: ISelectValues[] = []
  allRolesTotal.forEach((role) => {
    selectRoles.push({
      value: role,
      label: label[role],
    })
  })
  return selectRoles
}

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
}

interface IState {
  role: string
  fname: string
  lname: string
  email: string
  password: string
  passwordError: string
  passwordConfirm: string
  password2Error: string
  passwordVisible: boolean
  password2Visible: boolean
  viewOnly: boolean
  featureAccess: TFeatureAccess[]
}

class AddUserDialog extends React.Component<IProps, IState> {
  state: IState = {
    role: 'worker',
    fname: '',
    lname: '',
    email: '',
    password: '',
    passwordError: '',
    passwordConfirm: '',
    password2Error: '',
    passwordVisible: false,
    password2Visible: false,
    viewOnly: false,
    featureAccess: [...allFeatures],
  }

  changePassword1(val: string) {
    this.setState({
      password: val,
      passwordError:
        val.length > 7
          ? RegexPasswordCheck.test(val)
            ? ''
            : 'Password Requires atleast 1 special character'
          : 'Password is too short',
      password2Error:
        this.state.password === val ? '' : "Password doesn't match",
    })
  }

  changePassword2(val: string) {
    this.setState({
      passwordConfirm: val,
      password2Error:
        this.state.password === val ? '' : "Password doesn't match",
    })
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  isSaveButtonDisabled() {
    return !(
      !!this.state.fname &&
      !!this.state.lname &&
      !!this.state.email &&
      !!this.state.role &&
      !!this.state.password &&
      !!this.state.passwordConfirm &&
      !this.state.passwordError &&
      !this.state.password2Error
    )
  }

  componentDidMount() {}

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>New User</DialogTitle>
        <DialogContent>
          <DialogRow
            FieldLeft={
              <Select
                label="Role"
                required
                selectedValue={this.state.role}
                values={SortRoleSelectValues(allRoles)}
                onChange={(name: string) =>
                  this.setState({
                    role: name,
                  })
                }
                className={this.props.classes.SelectField}
                formHelperText={
                  this.state.role === 'admin'
                    ? 'All rights'
                    : this.state.role === 'branch'
                    ? 'Access and manage their branch'
                    : 'Access their branch'
                }
              />
            }
            FieldRight={
              <InfoHover>
                <div>
                  <div>
                    <b>Administrator</b>
                    <small>
                      , can access and modify everything on the entire account.
                    </small>
                  </div>
                  <div>
                    <b>Branch Manager</b>
                    <small>
                      , can access and modify everything on their branch.
                    </small>
                  </div>
                  <div>
                    <b>Employee</b>
                    <small>
                      , can access their branch, cannot delete anything, are
                      limited to the Feature Access, and if View only is enabled
                      it will prevent the ability to create or edit.
                    </small>
                  </div>
                </div>
              </InfoHover>
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="First Name"
                required
                className={this.props.classes.TextField}
                value={this.state.fname}
                onChange={(event) =>
                  this.setState({ fname: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                label="Last Name"
                required
                className={this.props.classes.TextField}
                value={this.state.lname}
                onChange={(event) =>
                  this.setState({ lname: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="Email"
                required
                className={this.props.classes.TextField}
                value={this.state.email}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                required
                name="password"
                label="Password"
                autoComplete="password"
                className={this.props.classes.TextField}
                error={!!this.state.passwordError}
                helperText={this.state.passwordError}
                type={this.state.passwordVisible ? 'text' : 'password'}
                value={this.state.password}
                onChange={(event) => this.changePassword1(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      variant="appbar"
                      icon={
                        this.state.passwordVisible
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      onClick={() =>
                        this.setState({
                          passwordVisible: !this.state.passwordVisible,
                        })
                      }
                    />
                  </InputAdornment>
                }
              />
            }
            FieldRight={
              <TextField
                required
                name="password2"
                label="Confirm Password"
                autoComplete="password"
                className={this.props.classes.TextField}
                error={!!this.state.password2Error}
                helperText={this.state.password2Error}
                type={this.state.password2Visible ? 'text' : 'password'}
                value={this.state.passwordConfirm}
                onChange={(event) => this.changePassword2(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      variant="appbar"
                      icon={
                        this.state.password2Visible
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      onClick={() =>
                        this.setState({
                          password2Visible: !this.state.password2Visible,
                        })
                      }
                    />
                  </InputAdornment>
                }
              />
            }
          />
          {this.state.role === 'worker' && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControl className={this.props.classes.formControl}>
                <FormLabel>Feature Access</FormLabel>
                <FormGroup row>
                  {allFeatures.map((feature) => (
                    <FormControlLabel
                      key={feature}
                      control={
                        <Checkbox
                          checked={this.state.featureAccess.includes(feature)}
                          onChange={(event) => {
                            const featureIndex =
                              this.state.featureAccess.indexOf(feature)
                            let newArr = this.state.featureAccess

                            if (event.target.checked) {
                              this.state.featureAccess.push(feature)
                            } else {
                              this.state.featureAccess.splice(featureIndex, 1)
                            }
                            this.setState({ featureAccess: newArr })
                          }}
                          color="primary"
                        />
                      }
                      label={`${feature[0].toUpperCase()}${feature.substr(1)}`}
                    />
                  ))}
                </FormGroup>
              </FormControl>

              <FormControl className={this.props.classes.formControl}>
                <FormLabel>Prevent user from making changes</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.viewOnly}
                      onChange={(event) =>
                        this.setState({ viewOnly: event.target.checked })
                      }
                      color="primary"
                    />
                  }
                  label="View Only"
                />
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AddUserDialog)
