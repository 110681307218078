import qs from 'qs'

/**
 * Service Page Hash Information
 */

export enum EServiceTabs {
  tickets = 'tickets',
  tasks = 'tasks',
  parts = 'parts',
}

export type IServiceTabs =
  | EServiceTabs.tickets
  | EServiceTabs.tasks
  | EServiceTabs.parts

export interface IServiceHash {
  tab: IServiceTabs
  status?: 'open' | 'closed'
  assets?: string[]
  createdDate?: string
  resolvedDate?: string
}

export const ServiceHashFromString = (params: string): IServiceHash => {
  return qs.parse(params) as unknown as IServiceHash
}

export const ServiceHashToString = (params: IServiceHash) => {
  return qs.stringify(params)
}
