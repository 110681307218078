import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const SiteIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M12,2.7l10.8,18.8H1.1L12,2.7 M12,1.5C12,1.5,12,1.5,12,1.5c-0.3,0-0.6,0.2-0.7,0.4L0.1,21.3c-0.2,0.4-0.1,0.9,0.3,1.1c0.1,0.1,0.3,0.1,0.4,0.1h22.3c0.5,0,0.8-0.4,0.8-0.8c0-0.1,0-0.3-0.1-0.4L12.7,1.9C12.6,1.7,12.3,1.5,12,1.5L12,1.5z M20.2,20.1L17,15.9c-0.1-0.2-0.3-0.3-0.5-0.3h-1.7c-0.2,0-0.4,0.1-0.5,0.2l-0.7,0.9l-1.1-0.6c0.1-0.1,0.1-0.3,0.1-0.4l-0.2-4c0-0.1,0-0.1,0-0.2c0.1,0,0.2,0.1,0.3,0.1c0.6,0,1.2-0.5,1.2-1.1c0-0.6-0.5-1.2-1.1-1.2c-0.6,0-1.2,0.5-1.2,1.1c0,0,0,0,0,0.1c0,0.2,0.1,0.4,0.2,0.6h-0.1h-3c-0.1,0-0.1,0-0.2,0l0,0l-0.1,0.1c0,0,0,0-0.1,0.1L7,13.7c-0.1,0.1-0.1,0.3,0,0.4l0.4,0.3c0.1,0.1,0.2,0,0.3-0.1l0,0l0.2,0.1l0.6,0.3c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1l-2.3,4c-0.2,0.3-0.2,0.5,0,0.6l0.5,0.3c0.1,0.1,0.3,0,0.5-0.3l2.2-3.9l0.4,1.8v2.2c0,0.2,0.1,0.3,0.3,0.3h0.6c0.1,0,0.3-0.1,0.3-0.2c0,0,0,0,0,0v-2.3c0,0,0,0,0-0.1s0,0,0-0.1l-0.4-1.6c0,0,0-0.1,0.1-0.1l2.7,1.4L11,20.1c0,0.1,0,0.1,0.1,0.1h9C20.2,20.2,20.2,20.2,20.2,20.1z M11.6,15.8l-0.9-0.5l0.8-1.7L11.6,15.8z M11.9,11.4L11.9,11.4L11.9,11.4z M8,13.9l0.9-1.6h0.8l-0.9,2L8.2,14L8,13.9z" />,
  'Site'
)

export default SiteIcon
