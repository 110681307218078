import React from 'react'
import '../../styles/pages/Inventory.scss'
import { SideNav, SideNavSection } from '../menus/NavBar'
import { SideTab } from '../menus/Tab'
import SideTabSection from '../menus/SideTabSelection'
import Icon from '../Icon'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '../buttons/IconButton'
import { IStandardIcons } from '../ux/standardIcons'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ITabs } from './sideBarTypes'
import { ERoutes } from '../routes/CheckRoutes'

const DarkTheme = createTheme({
  palette: {
    action: {
      active: '#fff',
    },
  },
})

interface IState {}

interface IProps {
  tabs: Array<ITabs>
  selectedTab: string
  onChangeTab: (val: string) => void
  tooltip?: string
  bottomIconButton?: boolean
  addFab?: boolean
  FabDisabled?: boolean
  toolBar?: { single?: boolean }
  onClick?: () => void
}

class DesktopInventoryMenu extends React.Component<IProps, IState> {
  state: IState = {}

  changeTab(tab: ITabs) {
    this.props.onChangeTab(tab.value)
    if (tab.url) {
      window.location.replace(tab.url)
    }
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <SideNav offsetTop={64}>
          <div
            className="sideNavInner"
            style={
              this.props.toolBar
                ? {
                    marginTop: 48,
                  }
                : undefined
            }
          >
            <div>
              {this.props.addFab ? (
                <SideNavSection>
                  {this.props.FabDisabled ? (
                    <Fab
                      style={{ boxShadow: 'none' }}
                      color="secondary"
                      size="small"
                      disabled
                    >
                      <Icon icon="add" />
                    </Fab>
                  ) : (
                    <Tooltip
                      title={this.props.tooltip ? this.props.tooltip : ''}
                      placement="right"
                    >
                      <Fab
                        style={{ boxShadow: 'none' }}
                        id="DesktopInventoryMenuAddThingsButton"
                        color="secondary"
                        size="small"
                        onClick={this.props.onClick}
                      >
                        <Icon icon="add" style={{ color: '#fff' }} />
                      </Fab>
                    </Tooltip>
                  )}
                </SideNavSection>
              ) : (
                <div style={{ paddingTop: 16 }} />
              )}
              <SideTabSection
                idx={this.props.tabs.findIndex(
                  (v) => v.value === this.props.selectedTab
                )}
              >
                {this.props.tabs.map((tab) => (
                  <SideTab
                    key={tab.value}
                    label={tab.label}
                    icon={tab.icon as IStandardIcons}
                    onClick={() => this.changeTab(tab)}
                  />
                ))}
              </SideTabSection>
            </div>

            {this.props.bottomIconButton && (
              <div>
                <SideNavSection>
                  <ThemeProvider theme={DarkTheme}>
                    <IconButton
                      icon="flag"
                      variant="navbar"
                      tooltip="Flags"
                      tooltipPlacement="right"
                      href={ERoutes.flag}
                    />
                  </ThemeProvider>
                </SideNavSection>
              </div>
            )}
          </div>
        </SideNav>
        <div
          style={
            this.props.toolBar
              ? {
                  marginTop: 48,
                }
              : undefined
          }
          className="childrenContainer"
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default DesktopInventoryMenu
