import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import TextField from '../../textBoxs/TextField'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import NumberField from '../../textBoxs/NumberField'
import Popover from '@mui/material/Popover'
import MenuList from '@mui/material/MenuList'
import { IUser } from '../../../types/account/user'
import IconButton from '../../buttons/IconButton'
import { TTaskMetric, ETaskMetric, ITask } from '../../../types/service/tasks'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'

const styles = (theme: Theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(2),
      display: 'flex',
    },
    rowButtons: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      minHeight: 46,
      marginLeft: theme.spacing(1),
    },
    selectContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    select: {
      marginTop: 2,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      width: 147,
    },
    usersContainer: {
      padding: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    userChip: {
      margin: 2,
    },
    menu: {
      maxHeight: 300,
    },
    intervalInput: {
      width: 147,
    },
  })

export const endAdorn = (metric?: TTaskMetric) => {
  switch (metric) {
    case ETaskMetric.time:
      return 'months'
    case ETaskMetric.odometer:
      return 'mi / km'
    case ETaskMetric.engineHours:
    default:
      return 'hours'
  }
}

interface INewTask {
  taskName: string
  scheduleType: TTaskMetric
  value: any
}

interface IProps extends WithStyles<typeof styles> {
  task?: ITask
  onSubmit: (task: any) => any
  open: boolean
  onClose: () => void
  isMobile?: boolean
  type: 'add' | 'edit'
  users: IUser[]
  useMetric: boolean
}

interface IState {
  usersNotfiy: string[]
  usersPopover?: Element | ((element: Element) => Element) | null
  groupTaskName: string
  isRecurring: boolean
  tasksBeingCreated: INewTask[]
}

class GroupTaskDialogPart extends React.Component<IProps, IState> {
  state: IState = {
    usersNotfiy: [],
    groupTaskName: '',
    isRecurring: false,
    tasksBeingCreated: [
      {
        taskName: '',
        scheduleType: ETaskMetric.engineHours,
        value: '',
      },
    ],
  }

  UpdateUsersList(addUser: boolean, userId: string, usersList: string[]) {
    let newUserArr = usersList
    let userIndex = usersList.indexOf(userId)
    if (addUser) {
      newUserArr.push(userId)
    } else {
      newUserArr.splice(userIndex, 1)
    }
    this.setState({
      usersNotfiy: newUserArr,
    })
  }

  addNewTask() {
    const newT: INewTask = {
      taskName: 'new task',
      scheduleType: ETaskMetric.engineHours,
      value: '0',
    }
    let newTArr: INewTask[] = this.state.tasksBeingCreated
    newTArr.push(newT)
    this.setState({ tasksBeingCreated: newTArr })
  }

  removeNewTask(index: number) {
    let newTArr: INewTask[] = this.state.tasksBeingCreated
    newTArr.splice(index, 1)
    this.setState({ tasksBeingCreated: newTArr })
  }

  updateNewTask(
    index: number,
    taskName: string,
    scheduleType: TTaskMetric,
    value: any
  ) {
    const newT: INewTask = {
      taskName: taskName,
      scheduleType: scheduleType,
      value: value,
    }
    let newTArr: INewTask[] = this.state.tasksBeingCreated
    newTArr.splice(index, 1, newT)
    this.setState({ tasksBeingCreated: newTArr })
  }

  render() {
    const classes = this.props.classes
    const edited = this.props.type === 'edit'
    return (
      <div style={{ minHeight: '100%' }}>
        <DialogContent>
          {/* <ErrorText>{conflict}</ErrorText> */}

          <div className={classes.row}>
            <TextField
              defaultValue={this.props.task?.taskName}
              value={this.state.groupTaskName}
              required
              label="Grouped Task Name"
              autoFocus
              onChange={(event) =>
                this.setState({ groupTaskName: event.target.value })
              }
            />
            {/* <FormControlLabel
              control={
                <Switch
                  checked={this.state.isRecurring}
                  onChange={(event) =>
                    this.setState({ isRecurring: event.currentTarget.checked })
                  }
                  name="recurring"
                  color="primary"
                />
              }
              style={{ paddingLeft: this.props.isMobile ? undefined : 50 }}
              label="Recurring"
            /> */}
          </div>

          {this.state.tasksBeingCreated.map((newTask, idx) => (
            <div className={classes.row} key={idx}>
              <TextField
                value={newTask.taskName}
                required
                label="Task Name"
                onChange={(event) =>
                  this.updateNewTask(
                    idx,
                    event.target.value,
                    newTask.scheduleType,
                    newTask.value
                  )
                }
              />
              <div className={classes.selectContainer}>
                <FormControl variant="standard" sx={{ m: 1 }}>
                  <InputLabel shrink>Schedule</InputLabel>
                  <Select
                    defaultValue={
                      this.props.task
                        ? this.props.task.metric
                        : ETaskMetric.engineHours
                    }
                    value={newTask.scheduleType}
                    onChange={(event) =>
                      this.updateNewTask(
                        idx,
                        newTask.taskName,
                        event.target.value as TTaskMetric,
                        newTask.value
                      )
                    }
                    className={classes.select}
                    disabled={edited}
                  >
                    <MenuItem value={ETaskMetric.time}>Time</MenuItem>
                    <MenuItem value={ETaskMetric.engineHours}>
                      Engine Hours
                    </MenuItem>
                    <MenuItem value={ETaskMetric.odometer}>Odometer</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* This Number field cant handle dates of course, so when single date selection is created we will need to switch between the 2 */}
              <NumberField
                endAdornment={endAdorn(newTask.scheduleType)}
                label="Interval"
                className={classes.intervalInput}
                onChange={(number) =>
                  this.updateNewTask(
                    idx,
                    newTask.taskName,
                    newTask.scheduleType,
                    number
                  )
                }
              />
              <div className={classes.rowButtons}>
                <IconButton
                  variant="row"
                  icon="close"
                  tooltip="Remove Task"
                  onClick={() => this.removeNewTask(idx)}
                />
              </div>
            </div>
          ))}
          <div className={classes.row}>
            <Button color="primary">Add Existing Task</Button>
            <Button color="primary" onClick={() => this.addNewTask()}>
              Add New Task
            </Button>
          </div>
          <div className={classes.row}>
            <div className={classes.selectContainer}>
              <InputLabel shrink>Notify Users</InputLabel>
              <div className={classes.usersContainer}>
                {this.state.usersNotfiy.map((userId) => {
                  const user = this.props.users.find(
                    (u: IUser) => u.id === userId
                  )
                  return (
                    <Chip
                      key={userId}
                      size="small"
                      label={user ? `${user.firstName} ${user.lastName}` : ''}
                      className={classes.userChip}
                      onDelete={() =>
                        this.UpdateUsersList(
                          false,
                          userId,
                          this.state.usersNotfiy
                        )
                      }
                    />
                  )
                })}

                {this.props.users.length > 0 && (
                  <IconButton
                    icon="add"
                    variant="row"
                    onClick={(event) =>
                      this.setState({ usersPopover: event.currentTarget })
                    }
                  />
                )}
              </div>
            </div>

            <Popover
              open={!!this.state.usersPopover}
              anchorEl={this.state.usersPopover}
              onClose={() => this.setState({ usersPopover: null })}
              className={classes.menu}
            >
              <MenuList>
                {this.props.users.map((user) => (
                  <MenuItem
                    key={user.id}
                    dense
                    onClick={() =>
                      this.UpdateUsersList(
                        true,
                        user.id,
                        this.state.usersNotfiy
                      )
                    }
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </MenuItem>
                ))}
              </MenuList>
            </Popover>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button
            // onClick={triggerSubmit}
            color="primary"
            // disabled={submitDisabled} // Need to prevent submission if fields arnt filled out
          >
            Save
          </Button>
        </DialogActions>
      </div>
    )
  }
}

export default withStyles(styles)(GroupTaskDialogPart)
