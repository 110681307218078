import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const CoordEditIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M 10.359375 0.50585938 C 10.115654 0.49553183 9.8690556 0.49644549 9.6210938 0.5078125 C 9.3566011 0.51993738 9.0903835 0.54421948 8.8222656 0.58203125 C 4.972369 1.1320166 1.8378906 4.6009635 1.8378906 8.5058594 C 1.8378906 9.7708261 2.2781397 10.923156 2.828125 12.078125 C 3.8180986 14.113071 5.0293335 15.982233 6.4042969 17.742188 C 7.5592661 19.282146 8.822924 20.768324 10.087891 22.363281 C 10.668656 21.629683 11.191925 20.985724 11.738281 20.316406 L 11.212891 20.316406 L 11.212891 17.865234 L 11.212891 17.513672 L 16.041016 12.6875 L 16.785156 11.941406 L 17.421875 11.304688 C 17.50833 11.218231 17.607331 11.153545 17.710938 11.097656 C 18.244195 9.6809873 18.396221 8.2509001 18.005859 6.6894531 C 17.135766 3.2090773 14.015183 0.66077265 10.359375 0.50585938 z M 10.142578 3.3886719 C 13.167497 3.4436704 15.587891 5.8637524 15.587891 8.8886719 C 15.587891 11.913591 13.167497 14.333673 10.142578 14.388672 C 7.0626599 14.388672 4.5328921 11.85695 4.5878906 8.8320312 C 4.6428892 5.8071119 7.1176584 3.3336733 10.142578 3.3886719 z M 18.324219 11.78125 C 18.215352 11.78125 18.106689 11.822999 18.023438 11.90625 L 17.242188 12.6875 L 18.84375 14.287109 L 19.625 13.505859 C 19.791504 13.339356 19.791504 13.0708 19.625 12.904297 L 18.625 11.90625 C 18.541748 11.822999 18.433086 11.78125 18.324219 11.78125 z M 16.785156 13.142578 L 12.064453 17.865234 L 12.064453 19.466797 L 13.664062 19.466797 L 18.386719 14.744141 L 16.785156 13.142578 z " />,
  'CoordEdit'
)

export default CoordEditIcon
