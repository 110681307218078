import React from 'react'
import { OverlayView } from '@react-google-maps/api'

interface IProps {
  lat: number
  lng: number
  zIndex?: number
}

class MaptracOverlay extends React.Component<IProps> {
  containerElement: any
  constructor(containerElement: any) {
    super(containerElement)
    this.containerElement = containerElement
  }

  focusOnCurrentLocation = (position: { lat: number; lng: number }) => {
    const padding = 0.0025
    const mapBounds = {
      south: position.lat - padding,
      north: position.lat + padding,
      west: position.lng - padding,
      east: position.lng + padding,
    }
    return mapBounds
  }

  render() {
    return (
      <OverlayView
        position={{ lat: this.props.lat, lng: this.props.lng }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => {
          return { x: -width * 0.04, y: -height * 0.07 }
        }}
      >
        {this.props.children}
      </OverlayView>
    )
  }
}

export default MaptracOverlay
