import React from 'react'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material/styles/createTheme'
import { createTheme } from '@mui/material/styles'
import { darkMode } from '../ux/roviTheme'
import '../../styles/components/registration/assetManagement.scss'
const DarkTheme = (theme: Theme) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#0078FF',
      },
      background: {
        paper: darkMode ? theme.palette.grey[700] : theme.palette.grey[100],
      },
    },
  })
interface IState {}

interface IProps {}

class assetManagement extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
        <Paper className="assetManagementComponent">
          <div className="registrationQuestions">
            If you have any questions, please feel free to
            <a href="https://rovitracker.com/contact/"> contact us.</a>
          </div>

          <div className="registrationUpgradeContainer">
            <div className="registerAssetManagement">Asset Management FREE</div>
            <div className="registrationUpgradePath">
              Upgrade your plan anytime once you have registered.
            </div>
          </div>
        </Paper>
    )
  }
}

export default assetManagement
