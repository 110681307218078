import React from 'react'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import {
  getNewDefaultConditionRule,
  ICondition,
  IConditionRule,
  Metric,
} from 'src/constants/flagRules'
import { IGeofenceEntity } from 'src/types/maptrac/playback'
import { MenuItem, Select } from '@mui/material'
import ConditionSelector from './ConditionSelector'
import LineButton from 'src/components/buttons/LineButton'

interface IConditionViewProps extends WithStyles<typeof styles> {
  condition: ICondition
  onUpdateCondition: (condition: Partial<ICondition>) => void
  geofences: Record<string, IGeofenceEntity>
  branchGeofenceIds: string[]
  useMetric?: boolean
}

const ConditionView = (props: IConditionViewProps) => {
  const { conditions } = props.condition

  return (
    <div>
      {conditions.length > 1 && (
        <Select
          value={props.condition.type}
          onChange={(e) =>
            props.onUpdateCondition({ type: e.target.value as any })
          }
          className={props.classes.typeSelect}
          variant="standard"
        >
          <MenuItem value="all">All of these are true</MenuItem>
          <MenuItem value="any">Any of these are true</MenuItem>
        </Select>
      )}
      {conditions.map((condition, i) => (
        <ConditionSelector
          key={i}
          geofences={props.geofences}
          condition={condition}
          showLastDeviceUpdate={conditions.length === 1}
          useMetric={props.useMetric}
          branchGeofenceIds={props.branchGeofenceIds}
          onRemove={
            conditions.length > 1
              ? () =>
                  props.onUpdateCondition({
                    conditions: conditions.filter((c, idx) => i !== idx),
                  })
              : undefined
          }
          onChange={(val) =>
            props.onUpdateCondition({
              conditions: conditions.map(
                (c, idx) => (i === idx ? { ...c, ...val } : c) as IConditionRule
              ),
            })
          }
        />
      ))}
      {!conditions.some(
        (condition) => condition.metric === Metric.LastDeviceUpdate
      ) && (
        <div className={props.classes.addButtonRow}>
          <LineButton
            onClick={() =>
              props.onUpdateCondition({
                conditions: [...conditions, getNewDefaultConditionRule()],
              })
            }
          >
            Add Condition
          </LineButton>
        </div>
      )}
    </div>
  )
}

const styles = createStyles({
  flex: {
    flex: 1,
  },
  typeSelect: {
    marginBottom: 20,
  },
  addButtonRow: {
    marginTop: 20,
  },
})

export default withStyles(styles)(ConditionView)
