import React from 'react'
import Popover from '@mui/material/Popover'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { colors } from '../ux/roviTheme'
import moment from 'moment'

export type TaskType = 'red' | 'orange' | 'blue' | 'none'
export const allTasks: TaskType[] = ['blue', 'orange', 'red']
export const colorLookup: { [key in TaskType]: string } = {
  red: 'red',
  orange: 'orange',
  blue: colors.primaryBlue,
  none: colors.offGrey,
}
export const getType = (scheduleRatio?: number): TaskType =>
  scheduleRatio === undefined
    ? 'none'
    : scheduleRatio >= 0.95
    ? 'red'
    : scheduleRatio >= 0.85
    ? 'orange'
    : 'blue'

export const getColor = (scheduleRatio?: number): string =>
  colorLookup[getType(scheduleRatio)]

interface ITaskType {
  taskType: string
  value?: any
}

const TaskType = (props: ITaskType) => {
  switch (props.taskType) {
    case 'miles':
      return props.value
    case 'months':
      return moment(moment(props.value as string).format())
        .endOf('day')
        .fromNow()
    case 'hours':
      return moment(moment(props.value as string).format())
        .endOf('hour')
        .fromNow()
    default:
      return props.value
  }
}

interface ITaskWidgetProps {
  task: any
}

const TaskWidget = (props: ITaskWidgetProps) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <div
      style={{
        backgroundColor: getColor(props.task.scheduleRatio),
        height: '7px',
        width: '7px',
        borderRadius: '50%',
        marginRight: '3px',
      }}
    />
    <Typography
      variant="body2"
      color="textPrimary"
      component="div"
      style={{
        marginRight: 8,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 100,
      }}
    >
      {props.task.task}
    </Typography>
    <Typography
      variant="body2"
      color="textSecondary"
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 100,
      }}
    >
      Due <TaskType taskType={props.task.type} value={props.task.time} />
    </Typography>
  </div>
)

const styles = createStyles({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: 6,
    display: 'flex',
    flexDirection: 'column',
  },
})

interface IProps extends WithStyles<typeof styles> {
  anchorEl?: HTMLElement | null
  onClose: () => void
  tasks: Array<any>
}

const ServicePopover = (props: IProps) => (
  <Popover
    className={props.classes.popover}
    classes={{
      paper: props.classes.paper,
    }}
    open={!!props.anchorEl}
    anchorEl={props.anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    onClose={props.onClose}
    disableRestoreFocus
  >
    {props.tasks.map((task, i) => (
      <TaskWidget key={i} task={task} />
    ))}
  </Popover>
)

export default withStyles(styles)(ServicePopover)
