import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const EquipmentIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M0,15.6c0.1-0.4,0.3-0.4,0.6-0.3l5.2,1.6l4.5-13c0.1-0.3,0.4-0.4,0.7-0.4c0.3,0.1,0.4,0.3,0.4,0.6L6.8,17.7c-0.1,0.2-0.3,0.4-0.5,0.4H6.1l-5.8-1.8C0,16.1-0.1,15.9,0,15.6z M10.3,13.2c0-0.3,0.1-0.6,0.1-1.1c0.1-0.4,0.2-1.2,0.5-2.3c0.3-1.1,0.6-2.1,0.9-2.9c0.4-0.8,0.8-1.6,1.4-2.3c0.6-0.7,1.3-1.1,2-1.1h6.4v8.9h1.6c0.5,0,0.7,0.5,0.7,1.4c0,0.9-0.1,1.8-0.4,2.8L23.3,18h-1c0-0.7-0.3-1.3-0.9-1.8c-0.5-0.5-1.1-0.7-1.8-0.7c-0.7,0-1.4,0.2-1.9,0.7c-0.5,0.5-0.8,1.1-0.8,1.8h-3.3V18c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.5-1.2-0.8-2-0.8c-0.8,0-1.5,0.3-2,0.8c-0.6,0.5-0.9,1.2-0.9,1.9c0,0.2,0,0.4,0.1,0.5H6.8l1.7-5.2c0.5,0.3,0.9,0.4,1.1,0.4c0.3,0,0.4-0.1,0.5-0.3L10.3,13.2z M10.7,15.6c0.7,0,1.2,0.2,1.7,0.7c0.5,0.5,0.7,1.1,0.7,1.7c0,0.7-0.2,1.2-0.7,1.7c-0.5,0.5-1.1,0.7-1.7,0.7c-0.7,0-1.3-0.2-1.8-0.7c-0.5-0.5-0.7-1-0.7-1.7c0-0.7,0.2-1.2,0.7-1.7C9.4,15.9,10,15.6,10.7,15.6z M10.7,19.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1C9.9,19.3,10.2,19.4,10.7,19.4z M15.4,13.5l1.5-2.5h3.3v-6h-4.6c-1.1,0-2,1.1-2.5,3.3c-0.5,2.2-0.9,3.9-1.1,5.2H15.4z M19.5,15.9c0.7,0,1.2,0.2,1.7,0.7c0.5,0.5,0.7,1,0.7,1.6c0,0.6-0.2,1.2-0.7,1.6c-0.5,0.5-1,0.7-1.7,0.7c-0.7,0-1.2-0.2-1.7-0.7c-0.5-0.4-0.7-1-0.7-1.6c0-0.6,0.2-1.2,0.7-1.6C18.3,16.1,18.9,15.9,19.5,15.9z M18.5,19.2c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,0.9C18.1,18.6,18.3,18.9,18.5,19.2z" />,
  'Equipment'
)

export default EquipmentIcon
