import { headersAPI, api } from '../../constants/api'
import {
  defaultMessageParameters,
  checkExceptionError,
  createResponseMessage,
} from '../../types/apiCalls'

/**
 * Confluence Documentation
 * https://rovitracker.atlassian.net/l/c/TEErVa4L
 */

/**
 * API Call is to delete a Geofence by the GeofenceId
 * @param geofenceId Specific Geofence UUID
 * @warning ID must be a UUID other wise it will fail
 */
const postDeleteGeofence = (geofenceId: string) => {
  const APICallType = 'Geofence Deleted Post' // Let Sentry Know what this API Call is
  const DefaultMessages = createResponseMessage(
    'Geofence Successfully Deleted',
    'Geofence Failed to Delete, Please Try Again Later'
  )
  const body = JSON.stringify({
    geofence_id: geofenceId,
  })

  return fetch(api + '/geofence/delete', {
    method: 'POST',
    headers: headersAPI,
    body: body,
  })
    .then((response) => response.json())
    .then((response) =>
      checkExceptionError(response, APICallType, DefaultMessages)
    )
    .catch((err) =>
      defaultMessageParameters('error', err, APICallType, DefaultMessages)
    )
}

export default postDeleteGeofence
