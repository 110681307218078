import React from 'react'
import '../../../styles/components/inventory/AssetsTab.scss'
import { PageTitle } from '../../routes/CheckRoutes'
import MobileRoviAppbar from '../../appbar/MobileRoviAppbar'
import { EInventoryTabs } from '../../../constants/inventoryHashController'
import { colors } from '../../ux/roviTheme'
import Checkbox from '@mui/material/Checkbox'
import SearchBar from '../../textBoxs/SearchBar'
import IconButton, { IconButtonNonA } from '../../buttons/IconButton'
import ButtonDrawer from '../../buttons/ButtonDrawer'
import { LoadingIcon } from '../../LoadingOverlay'
import FilterDrawer from './FilterDrawer'
import ButtonSideIcon from '../../buttons/ButtonSideIcon'
import Divider from '@mui/material/Divider'
import {
  TMonitorTypes,
  TRentStatusTypes,
  TRepairStatus,
} from '../../../types/inventory'
import { ICategory } from '../../../types/category'
import { IUser } from '../../../types/account/user'
import { IFlag } from '../../../types/flag'
import { IAgreement } from '../../../types/agreements'
import printJS from 'print-js'
import { IAssetSnapshotRow } from '../AssetsTabTypes'
import {
  printJsSortInventory,
  printList,
  csvSortInventory,
} from '../inventorySorting/inventoryPrint'
import { CSVLink } from 'react-csv'
import PriceSheet from '../inventorySorting/PriceSheet'
import AssignUserDialog from '../inventoryDialogs/AssignUserDialog'
import routes from '../../routes/routes'

interface IState {}

interface IProps {
  thingRows: IAssetSnapshotRow[]
  selectAll: boolean
  switchAllAssets: (val: boolean) => void
  selectArr: string[]
  searchValue: string
  changeSearchVal: (val: string) => void
  loading?: boolean
  isMobile?: boolean
  /** Filters */
  categoryList: ICategory[]
  monitor: TMonitorTypes
  rentStatus: TRentStatusTypes
  repairStatus: TRepairStatus
  changeMonitor: (val: TMonitorTypes) => void
  changeRent: (val: TRentStatusTypes) => void
  changeRepair: (val: TRepairStatus) => void
  categoryId: string
  changeCategory: (val: string) => void
  userList: IUser[]
  userId: string
  changeUser: (val: string) => void
  agreementList: IAgreement[]
  clientId: string
  changeClient: (val: string) => void
  siteId: string
  changeSite: (val: string) => void
  flagList: IFlag[]
  flagId: string
  changeFlag: (val: string) => void
  clearFilters: () => void
  openTransferDialog: () => void
}

export default class ThingToolbar extends React.Component<IProps, IState> {
  state: IState = {}

  dividerCustom() {
    return <Divider orientation="vertical" flexItem variant="middle" />
  }

  loadAssetsURL(things: IAssetSnapshotRow[], selected: string[]) {
    let newAssets: string[] = []
    things.forEach((thing) => {
      if (selected.includes(thing.id)) {
        newAssets.push(thing.id)
      }
    })
    return newAssets
  }

  DefaultToolbar() {
    const defaultVal = 'all'
    const clientb = this.props.clientId !== defaultVal
    const siteb = this.props.siteId !== defaultVal
    const flagb = this.props.flagId !== defaultVal
    const userb = this.props.userId !== defaultVal
    const monitorb = this.props.monitor !== defaultVal
    const rentStatusb = this.props.rentStatus !== defaultVal
    const repairStatusb = this.props.repairStatus !== defaultVal
    const categoryb = this.props.categoryId !== defaultVal
    const dot =
      clientb ||
      siteb ||
      flagb ||
      monitorb ||
      rentStatusb ||
      repairStatusb ||
      categoryb ||
      userb
    return (
      <div className="inventoryAppbar" style={{ backgroundColor: colors.mbg }}>
        <div className="inventoryLeftSide">
          <Checkbox
            id="thingToolbarAssetsCheckBox"
            checked={this.props.selectAll}
            onChange={(event) =>
              this.props.switchAllAssets(event.target.checked)
            }
            color="primary"
          />
          <SearchBar
            id="thingToolbarSearchBar"
            value={this.props.searchValue}
            onChange={(val) => this.props.changeSearchVal(val)}
          />
          <ButtonDrawer
            title="Filters"
            icon="filter"
            anchor="right"
            width={290}
            dot={dot}
          >
            <FilterDrawer
              categoryList={this.props.categoryList}
              monitor={this.props.monitor}
              rentStatus={this.props.rentStatus}
              repairStatus={this.props.repairStatus}
              changeMonitor={(val: TMonitorTypes) =>
                this.props.changeMonitor(val)
              }
              changeRent={(val: TRentStatusTypes) => this.props.changeRent(val)}
              changeRepair={(val: TRepairStatus) =>
                this.props.changeRepair(val)
              }
              categoryId={this.props.categoryId}
              changeCategory={(val: string) => this.props.changeCategory(val)}
              userList={this.props.userList}
              userId={this.props.userId}
              changeUser={(val: string) => this.props.changeUser(val)}
              agreementList={this.props.agreementList}
              clientId={this.props.clientId}
              changeClient={(val: string) => this.props.changeClient(val)}
              siteId={this.props.siteId}
              changeSite={(val: string) => this.props.changeSite(val)}
              flagList={this.props.flagList}
              flagId={this.props.flagId}
              changeFlag={(val: string) => this.props.changeFlag(val)}
              clearFilters={this.props.clearFilters}
            />
          </ButtonDrawer>
          <div className="equipmentTotal">
            Total: {this.props.thingRows.length}
          </div>
          {this.props.loading ? (
            <div style={{ marginLeft: 10 }}>
              <LoadingIcon loading />
            </div>
          ) : null}
        </div>
        <div className="equipmentIconButtons">
          <PriceSheet />
          <IconButton
            icon="print"
            variant="toolbar"
            tooltip="Print/PDF"
            onClick={() =>
              printJS({
                printable: printJsSortInventory(this.props.thingRows),
                properties: printList,
                header: 'Rovitracker Inc.',
                gridHeaderStyle:
                  'font-weight: bold; font-size: 15px; border: 1px solid lightgray; margin-bottom: -1px;',
                gridStyle:
                  'font-size: 12px; border: 1px solid lightgray; margin-bottom: -1px;',
                type: 'json',
              })
            }
          />
          <CSVLink
            data={csvSortInventory(this.props.thingRows)}
            filename="rovitracker-inventory.csv"
          >
            <IconButtonNonA
              id="csvAssetsToolBarButton"
              icon="csv"
              variant="toolbar"
              tooltip="Export as CSV file"
            />
          </CSVLink>
        </div>
      </div>
    )
  }

  selectToolbar() {
    return (
      <div
        className="inventoryAppbar"
        style={{ backgroundColor: colors.highlightedBackground }}
      >
        <div className="inventoryLeftSide">
          <Checkbox
            checked={this.props.selectAll}
            onChange={(event) =>
              this.props.switchAllAssets(event.target.checked)
            }
            color="primary"
          />
          <ButtonSideIcon
            startIcon="syncAlt"
            label="Transfer"
            onClick={this.props.openTransferDialog}
          />
          {this.dividerCustom()}
          <AssignUserDialog
            includeUnassignOption
            selectedAssetIds={this.props.selectArr}
          />
          {this.dividerCustom()}
          <ButtonSideIcon
            startIcon="flag"
            label="Create Flag"
            // onClick={() => routes.createFlagWithAssets([])}
            href={routes.createFlagWithAssetsHref(
              this.loadAssetsURL(this.props.thingRows, this.props.selectArr)
            )}
          />
        </div>
        <div className="equipmentIconButtons">
          <div style={{ color: '#5D96F2', fontSize: 15, marginRight: 20 }}>
            {this.props.selectArr.length}{' '}
            {this.props.selectArr.length > 1 ? 'assets ' : 'asset '}selected
          </div>
        </div>
      </div>
    )
  }

  render() {
    const isMobile = this.props.isMobile
    return isMobile ? (
      <MobileRoviAppbar
        title={PageTitle(undefined, EInventoryTabs.thing)}
        length={this.props.thingRows.length}
      />
    ) : this.props.selectArr.length > 0 ? (
      this.selectToolbar()
    ) : (
      this.DefaultToolbar()
    )
  }
}
