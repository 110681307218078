import React from 'react'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import { backend_api, headersAPI } from '../constants/api'
import SoldEquipmentTable from '../components/archiveComponents/SoldEquipmentTable'
import ArchiveEquipmentTable from '../components/archiveComponents/ArchiveEquipmentTable'

interface IAssetArchivedSold {
  name: string
  id: string
}

interface IState {
  loadingArchive: boolean
  loadingSold: boolean
  ArchiveData: IAssetArchivedSold[]
  ArchiveDataReinstate: IAssetArchivedSold[]
}

interface IProps {}

class ArchivePage extends React.Component<IProps, IState> {
  state: IState = {
    loadingArchive: true,
    loadingSold: true,
    ArchiveData: [],
    ArchiveDataReinstate: [],
  }

  async grabArchivedData() {
    await fetch(backend_api + '/devices/archived', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          ArchiveData: response,
          loadingArchive: false,
        })
      })
      .catch((err) => console.log(err))
  }

  async grabSoldData() {
    await fetch(backend_api + '/devices/sold', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          ArchiveDataReinstate: response,
          loadingSold: false,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabArchivedData()
    this.grabSoldData()
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <ReactHelmet title="Archive" />
        <Toolbar title="Archive" noXtraBar />
        <div style={{ width: 840, marginTop: 50 }}>
          <ArchiveEquipmentTable />

          <SoldEquipmentTable />
        </div>
      </div>
    )
  }
}

export default ArchivePage
