import React from 'react'
import ClusterTag from './ClusterTag'
import TagMarker from './TagMarker'
import { ITag } from '../../../../types/maptrac/Tags'
import { IAssetClicked } from '../../../../types/maptrac/Assets'

interface IProps {
  totalTags: Array<ITag[]>
  onClick: (val: IAssetClicked) => void
}

/** Tags should be loaded into this component by their timestamp */
export default class FullTags extends React.Component<IProps> {
  render() {
    return (
      <>
        {this.props.totalTags.map((tags) => {
          if (tags.length >= 10) {
            const latlng = tags[0].gps
            return (
              <ClusterTag
                key={tags.reduce((acc, t) => acc + t.tagId, '')}
                lat={latlng.lat}
                lng={latlng.lng}
                length={tags.length}
                onClick={(id: string) =>
                  this.props.onClick({ assetId: id, gps: latlng })
                }
                isTransparent={false}
                isHighlighted={false}
                tags={tags.map((t) => ({ id: t.tagId, name: t.tagName }))}
              />
            )
          } else {
            return tags.map((tag) => (
              <TagMarker
                key={tag.tagId}
                lat={tag.gps.lat}
                lng={tag.gps.lng}
                // default values
                color={tag.category ? tag.category.color : '#fff'}
                icon={tag.category ? tag.category.icon : 'atv'}
                name={tag.tagName}
                offset={0}
                onClick={() =>
                  this.props.onClick({ assetId: tag.tagId, gps: tag.gps })
                }
                isHighlighted={false}
                isTransparent={false}
              />
            ))
          }
        })}
      </>
    )
  }
}
