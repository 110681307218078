import React from 'react'
import '../../styles/components/accountComponents/ContactCard.scss'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '../buttons/IconButton'
import { ERoutes } from '../routes/CheckRoutes'
import MessageOverlay from '../ux/MessageOverlay'
import EllipsisText from '../ux/EllipsisText'
import EditAccountDialog from '../accountComponents/dialogs/EditAccountDialog'
import { ICompanyInfo } from '../../types/account/account'

interface IState {
  editAccountDialog: boolean
}

interface IProps {
  account?: ICompanyInfo
  loadingData: boolean
}

class ContactCard extends React.Component<IProps, IState> {
  state: IState = {
    editAccountDialog: false,
  }

  render() {
    return (
      <>
        <Card className="contactCard">
          <CardHeader
            action={
              <>
                <IconButton
                  icon="history"
                  variant="row"
                  tooltip="History"
                  href={ERoutes.history}
                />
                <IconButton
                  icon="edit"
                  variant="row"
                  tooltip="Edit"
                  onClick={() => this.setState({ editAccountDialog: true })}
                  disabled={this.props.loadingData}
                />
              </>
            }
            title={
              <EllipsisText
                width={200}
                text={this.props.account ? this.props.account.CompanyName : ''}
              />
            }
          />

          <CardContent>
            <div className="contactCardContainer">
              <div className="contactCardImageContainer">
                <label className="contactCardImageLabel">
                  <img
                    alt="companyLogo"
                    src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-120x78.png"
                  />
                </label>
              </div>
              <div className="contactInfoTextContainer">
                {this.props.account ? (
                  <div style={{ marginBottom: 20 }}>
                    <p className="contactText">
                      {this.props.account.CompanyName}
                    </p>
                    <p className="contactText">
                      {this.props.account.CompanyAddress}
                    </p>
                    <p className="contactText">
                      {this.props.account.CompanyCity},{' '}
                      {this.props.account.CompanyState}{' '}
                      {this.props.account.CompanyZipCode}
                    </p>
                  </div>
                ) : (
                  <MessageOverlay
                    stayInBox
                    style={{ flex: 1, position: 'relative' }}
                  >
                    No Address
                  </MessageOverlay>
                )}

                {this.props.account ? (
                  <div>
                    {this.props.account.CompanyPhone && (
                      <p className="contactText">
                        Phone: {this.props.account.CompanyPhone}
                      </p>
                    )}
                    {this.props.account.CompanyFax && (
                      <p className="contactText">
                        Fax: {this.props.account.CompanyFax}
                      </p>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </CardContent>
        </Card>
        {this.props.loadingData ? null : (
          <EditAccountDialog
            dialogOpen={this.state.editAccountDialog}
            onClose={() => this.setState({ editAccountDialog: false })}
            companyInformation={this.props.account}
          />
        )}
      </>
    )
  }
}

export default ContactCard
