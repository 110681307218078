import React from 'react'
import FloatingButtonDrawer from '../../buttons/FloatingButtonDrawer'
import TabsContainer from '../../ux/TabsContainer'
import { PaddedSearchBar } from '../../textBoxs/SearchBar'
import LiveMaptracListAsset from './maptracLiveAsset/LiveMaptracListAsset'
import LiveMaptracListSite from './maptracLiveSites/LiveMaptracListSite'
// import { backend_api } from '../../../constants/api'
import AssetDetailsMaptrac from './maptracLiveAsset/AssetDetailsMaptrac'
import SiteDetailsMaptrac from './maptracLiveSites/SiteDetailsMaptrac'
import GeofenceDetailsMaptrac from './maptracLiveGeofences/GeofenceDetailsMaptrac'
import { IClientSites } from '../../../types/maptrac/Sites'
import { ILatLng } from '../../../types/maptrac/LatLng'
import {
  IGeofence,
  IGeofenceClicked,
  IGeofenceInfo,
} from '../../../types/maptrac/Geofences'
import LiveMaptracListGeofence from '../maptracLive/maptracLiveGeofences/LiveMaptracListGeofence'
import { IAssetList, IAssetClicked } from '../../../types/maptrac/Assets'

export type TFocusType =
  | 'asset'
  | 'site'
  | 'geofence'
  | 'not_found'
  | 'not_focused'

interface IProps {
  searchString: string
  updateSearch: (val: string) => void
  isMobile: boolean
  focused: boolean
  assetClicked: (val: IAssetClicked) => void
  siteClicked: (val: string, gps: ILatLng) => void
  geofenceClicked: (val: IGeofenceClicked) => void
  focusedType: TFocusType
  unFocus: () => void
  // Sites
  clientsVal: IClientSites[]
  sitesValTotal: number
  sitesLoading: boolean
  // Geofences
  geofencesVal: IGeofence[]
  geofencesLoading: boolean
  setEditedGeofence: (val: IGeofenceInfo) => void
  // Assets
  assetsVal: IAssetList[]
  assetsValTotal: number
  assetsLoading: boolean
  width: number
  height: number
  onReloadGeofences: () => void
}

interface IState {
  searchString: string
}

class LiveMaptracDrawerComponents extends React.Component<IProps, IState> {
  state: IState = {
    searchString: '',
  }

  assetCountVal(assetAmount: { equipment: string | any[] }[]) {
    let assetCountVal = 0
    assetAmount.forEach((val: { equipment: string | any[] }) => {
      assetCountVal = val.equipment.length + assetCountVal
    })
    return assetCountVal
  }

  sitesCountVal(siteAmount: IClientSites[]) {
    let siteCountVal = 0
    siteAmount.forEach((val) => {
      siteCountVal = val.clientSites.length + siteCountVal
    })
    return siteCountVal
  }

  focusedComponents(focusedType: TFocusType) {
    switch (focusedType) {
      case 'asset':
        return (
          <AssetDetailsMaptrac
            equip="56" // should be removed and hooked into the url hash
            isMobile={this.props.isMobile}
            onBack={this.props.unFocus}
            onClickEntity={(id: any) => console.log()}
            timezone=""
            onClickCamera={() => console.log()}
            onClickTrip={(date: any, endDate: any) => console.log()}
            width={0}
            gatewayHistoryObs=""
            tagsHistoryObs=""
            setDate={(data: any) => console.log()}
            setFocus={(entity: any) => console.log()}
          />
        )
      case 'site':
        return (
          <SiteDetailsMaptrac
            equip=""
            isMobile={this.props.isMobile}
            onBack={this.props.unFocus}
            assetClicked={(val) => this.props.assetClicked(val)}
          />
        )
      case 'geofence':
        return (
          <GeofenceDetailsMaptrac
            isMobile={this.props.isMobile}
            onBack={this.props.unFocus}
            assetClicked={(val) => this.props.assetClicked(val)}
            siteClicked={(id: string, gps: ILatLng) =>
              this.props.siteClicked(id, gps)
            }
            onReload={this.props.onReloadGeofences}
            unFocus={this.props.unFocus}
            setEditedGeofence={this.props.setEditedGeofence}
          />
        )
      case 'not_found':
        return <div>Not Found</div>
      default:
        return <div>not focused</div>
    }
  }

  componentDidMount() {
    // this.grabMaptracAssetsList()
  }

  render() {
    return (
      <FloatingButtonDrawer isMobile={this.props.isMobile} isLeft defaultOpen>
        {/* Search Bar Component */}
        {this.props.focused ? (
          this.focusedComponents(this.props.focusedType)
        ) : (
          <>
            <PaddedSearchBar
              value={this.state.searchString}
              onChange={(newString: string) => {
                this.props.updateSearch(newString)
                // May remove this state if it is having not improvement in performance
                this.setState({ searchString: newString })
              }}
            />
            {/* Tabs that split up the Assets, Sites, and geofences */}

            <TabsContainer
              scroll
              labels={[
                `Assets (${this.props.assetsValTotal})`,
                `Sites (${this.props.sitesValTotal})`,
                `geofences (${this.props.geofencesVal.length})`,
              ]}
            >
              <LiveMaptracListAsset
                searchString={this.props.searchString}
                data={this.props.assetsVal}
                onClick={(val: IAssetClicked) => this.props.assetClicked(val)}
                onHoverIn={() => console.log()}
                onHoverOut={() => console.log()}
                loading={this.props.assetsLoading}
                isMobile={this.props.isMobile}
                width={this.props.width}
                height={this.props.height}
              />
              <LiveMaptracListSite
                data={this.props.clientsVal}
                count={this.props.sitesValTotal}
                onClick={(val: string, gps: ILatLng) =>
                  this.props.siteClicked(val, gps)
                }
                onHoverIn={() => console.log()}
                onHoverOut={() => console.log()}
                loading={this.props.sitesLoading}
              />
              <LiveMaptracListGeofence
                geofences={this.props.geofencesVal}
                onClick={(geo) => this.props.geofenceClicked(geo)}
                onHoverIn={() => console.log()}
                onHoverOut={() => console.log()}
                loading={this.props.geofencesLoading}
              />
            </TabsContainer>
          </>
        )}
      </FloatingButtonDrawer>
    )
  }
}

export default LiveMaptracDrawerComponents
