import React from 'react'
import ReactHelmet from '../components/ReactHelmet'
import TextField from '../components/textBoxs/TextField'
import Button from '../components/buttons/Button'

import { Auth } from 'aws-amplify'

interface IProps {}

class Verify extends React.Component<IProps> {
  state = {
    VerificationCode: '',
    UserName: 'nathan+123123@rovitracker.com',
  }

  handleVerify = () => {
    console.log(this.state)
    Auth.confirmSignUp(this.state.UserName, this.state.VerificationCode)
      .then((data: any) => console.log(data))
      .then(() => window.location.replace('/onBoard'))
      .catch((err: any) => console.log(err))
  }

  render() {
    console.log(Auth.currentUserInfo)
    console.log(Auth.currentSession)
    console.log(Auth.currentAuthenticatedUser)
    return (
      <div className="verifyPage">
        <ReactHelmet title="Verify" />
        <TextField
          required
          label="UserName"
          type="text"
          value={this.state.UserName}
          onChange={(event) => this.setState({ UserName: event.target.value })}
        />
        <TextField
          required
          label="Verification Code"
          type="text"
          value={this.state.VerificationCode}
          onChange={(event) =>
            this.setState({ VerificationCode: event.target.value })
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.handleVerify()}
        >
          Verify
        </Button>
      </div>
    )
  }
}

export default Verify
