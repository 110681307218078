import React from 'react'
import '../../styles/components/accountComponents/AccountBranch.scss'
import Button from '../buttons/Button'
import Icon from '../Icon'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Paper from '@mui/material/Paper'
import AddUserDialog from './dialogs/AddUserDialog'
import { IBranch } from '../../types/account/account'
import { IAssetBase } from '../../types/assets'
import { CardItem } from '../ux/Attribute'
import Card from '@mui/material/Card'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import { findCountry } from '../../constants/countries'
import AddDefaultFeeDialog from './dialogs/AddDefaultFeeDialog'
import BranchUserRow from './branchComponents/BranchUserRow'
import BranchFeeRow from './branchComponents/BranchFeeRow'

const styles = createStyles({
  paper: {
    margin: 20,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  table: {},
  hideLastBorder: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
})

interface IProps extends WithStyles<typeof styles> {
  branch: IBranch
  isMobile?: boolean
}

interface IState {
  addUserDialogOpen: boolean
  addDefaultFeeOpen: boolean
}

class BranchInfoComponent extends React.Component<IProps, IState> {
  state: IState = {
    addUserDialogOpen: false,
    addDefaultFeeOpen: false,
  }

  splitByNames(assets: IAssetBase[]) {
    let newNames: string[] = []
    assets.forEach((asset) => newNames.push(asset.name))
    return newNames
  }

  render() {
    const classes = this.props.classes
    return (
      <>
        <div
          style={{
            paddingTop: this.props.isMobile ? undefined : 96,
            maxWidth: '100vw',
          }}
        >
          <Card className={classes.paper}>
            <CardContent>
              <Typography variant="h6">Branch Information</Typography>
            </CardContent>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <CardContent
                style={{
                  display: 'flex',
                  flexWrap: this.props.isMobile ? 'wrap' : undefined,
                }}
              >
                <CardItem
                  title="Address"
                  value={this.props.branch.BranchAddress}
                />

                <CardItem title="City" value={this.props.branch.BranchCity} />
                <CardItem
                  title="Zip Code / Postal Code"
                  value={this.props.branch.BranchZipCode}
                />
                <CardItem
                  title="Country"
                  value={findCountry(this.props.branch.BranchCountry).name}
                />
              </CardContent>
              <CardContent
                style={{
                  display: 'flex',
                  flexWrap: this.props.isMobile ? 'wrap' : undefined,
                }}
              >
                <CardItem
                  title="Time Zone"
                  value={this.props.branch.BranchTimeZone}
                />
                <CardItem
                  title="Default Tax Rentals"
                  value={this.props.branch.taxRental}
                />
                <CardItem
                  title="Default Tax Rate"
                  value={this.props.branch.defaultTax}
                />
                <CardItem
                  title="Hourly Label Rate"
                  value={this.props.branch.BranchLaborHourlyRate}
                />
              </CardContent>
              <CardContent>
                <CardItem
                  title="Use Metric System"
                  value={this.props.branch.metricSystem}
                />
              </CardContent>
            </div>
          </Card>
          <TableContainer
            component={Paper}
            className={classes.paper}
            style={{ width: 'calc(100% - 40px)' }}
          >
            <CardContent>
              <Typography variant="h5">Users</Typography>
            </CardContent>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Things</TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => this.setState({ addUserDialogOpen: true })}
                    >
                      <Icon icon="add" />
                      User
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              {this.props.branch.BranchUsers ? (
                <TableBody>
                  {this.props.branch.BranchUsers.map((user) => (
                    <BranchUserRow user={user} key={user.id} />
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>No Users</TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            className={classes.paper}
            style={{ width: 'calc(100% - 40px)' }}
          >
            <CardContent>
              <Typography variant="h5">Default Agreement Fees</Typography>
            </CardContent>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => this.setState({ addDefaultFeeOpen: true })}
                    >
                      <Icon icon="add" />
                      Fee
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              {this.props.branch.DefaultAgreementFees ? (
                <TableBody>
                  {this.props.branch.DefaultAgreementFees.map((fee) => (
                    <BranchFeeRow fee={fee} key={fee.id} />
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>No Users</TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
        <AddUserDialog
          dialogOpen={this.state.addUserDialogOpen}
          onClose={() => this.setState({ addUserDialogOpen: false })}
        />
        <AddDefaultFeeDialog
          dialogOpen={this.state.addDefaultFeeOpen}
          onClose={() => this.setState({ addDefaultFeeOpen: false })}
        />
      </>
    )
  }
}

export default withStyles(styles)(BranchInfoComponent)
