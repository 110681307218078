import React from 'react'
import Button from '../../components/buttons/Button'
import { colors } from '../../components/ux/roviTheme'

const buttonStyle: React.CSSProperties = {
  height: 40,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const enabledButtonStyle = {
  ...buttonStyle,
  backgroundColor: colors.accentDarkGrey,
  color: '#fff',
}

interface IProps {
  addOn: {
    addonPrice: number
    addonLabel: string
    addonDescription: string
    addonName: string
  }
  added: boolean
  onClickAddon: (label: string) => void
}
export default class AddonCard extends React.Component<IProps> {
  state = {
    hovered: false,
  }

  render() {
    const { hovered } = this.state
    return (
      <div
        style={{
          background: colors.accentLightGrey,
          flex: 1,
          maxHeight: 217,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onMouseEnter={() =>
          !this.props.added && this.setState({ hovered: true })
        }
        onMouseLeave={() =>
          !this.props.added && this.setState({ hovered: false })
        }
      >
        <div>
          <div style={{ fontSize: 20, margin: 20 }}>
            {this.props.addOn.addonLabel}
          </div>
          <div
            style={{
              textAlign: 'center',
              color: colors.primaryBlue,
              fontSize: 26,
              fontWeight: 'bold',
            }}
          >
            ${this.props.addOn.addonPrice}
            <span style={{ fontWeight: 'normal' }}>/year</span>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              textAlign: 'center',
              color: colors.accentDarkGrey,
              lineHeight: 2,
              margin: '10px 20px',
            }}
          >
            {this.props.addOn.addonDescription}
          </div>
        </div>
        {this.props.added ? (
          <Button disabled style={enabledButtonStyle}>
            Enabled
          </Button>
        ) : (
          <Button
            onClick={() => this.props.onClickAddon(this.props.addOn.addonLabel)}
            style={{
              ...buttonStyle,
              backgroundColor: hovered
                ? colors.accentOrange
                : colors.primaryBlue,
              color: '#fff',
            }}
          >
            {hovered ? 'More Info' : 'Add-on'}
          </Button>
        )}
      </div>
    )
  }
}
