import { RoviLog, ObjErr } from '../utility/roviLog'

export type TErrorTypes = 'success' | 'error'

export interface IResponseMessage {
  type: TErrorTypes
  snackBarMessage: string
  errorMessage: any
}

export interface IResponseMessages {
  successfulMessage: string
  failedMessage: string
}

/**
 * used to make an easy way to format the correct messages in the object
 * Successful Message first
 * Error Message Last
 */
export const createResponseMessage = (
  successfulMessage: string,
  failedMessage: string
) => ({ successfulMessage: successfulMessage, failedMessage: failedMessage })

/** Just removing duplication of tieing the Custom messages to the default message */
const tieMessagesTogether = (
  message: string,
  errorType: TErrorTypes,
  extra?: IResponseMessages
) =>
  extra
    ? ' ' +
      (errorType === 'error' ? extra.failedMessage : extra.successfulMessage)
    : message

/**
 * Was created in otder to return a standard object formula, to allow the frontend to react properly
 * @param errorType success | error
 * @param errorMessage This is the actual response from the server.
 * @param whatistheAPICall Used for Sentry Purposes and allows us to see what type of error this call is.
 * @param responseMessages - { successfulMessage: string, failedMessage: string} Custom Response Messages for Snack bars and user information.
 * @returns {type: TErrorTypes, snackBarMessage: string, errorMessage: any}
 */
export const defaultMessageParameters = (
  errorType: TErrorTypes,
  errorMessage: any,
  whatistheAPICall: string,
  responseMessages?: IResponseMessages
) => {
  switch (errorType) {
    case 'success':
      return {
        type: errorType,
        snackBarMessage: tieMessagesTogether(
          'Successful',
          errorType,
          responseMessages
        ),
        errorMessage: errorMessage,
      }
    case 'error':
      RoviLog.error(ObjErr(whatistheAPICall, errorMessage))
      return {
        type: errorType,
        snackBarMessage: tieMessagesTogether(
          'Failed',
          errorType,
          responseMessages
        ),
        errorMessage: errorMessage,
      }
  }
}

/**
 * Some API Calls return Exception as an error message but also return a successful Error code, so this is here to catch it
 * @returns an error if it finds an exceptions, otherwise a successful message
 */
export const checkExceptionError = (
  response: any,
  whatistheAPICall: string,
  responseMessages?: IResponseMessages
) => {
  switch (response.name) {
    case 'DeserializationException':
    case 'Exception':
      return defaultMessageParameters(
        'error',
        response,
        whatistheAPICall,
        responseMessages
      )
    default:
      return defaultMessageParameters(
        'success',
        response,
        whatistheAPICall,
        responseMessages
      )
  }
}
