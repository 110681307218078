import React from 'react'
import IconButton from './IconButton'
import Tooltip from '@mui/material/Tooltip'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0px 3px 8px -2px rgba(0,0,0,0.2)',
  },
}))(Tooltip)

interface IProps {
  text?: string
}

class InfoHover extends React.Component<IProps> {
  render() {
    return (
      <HtmlTooltip
        title={
          <div style={{ padding: 10, fontSize: 12 }}>
            {this.props.text} {this.props.children}
          </div>
        }
      >
        <span>
          <IconButton
            icon="info"
            variant="row"
            style={{ color: '#0078FF', cursor: 'help' }}
          />
        </span>
      </HtmlTooltip>
    )
  }
}

export default InfoHover
