import React from 'react'
import { Section } from '../equipmentComponents/EquipmentSection'
import { CardItem } from '../ux/Attribute'
import { ICustomAttributes } from '../../types/equipment/equipmentProfile'

interface IProps {
  customAttributes: ICustomAttributes[]
}

class AttributesSection extends React.Component<IProps> {
  render() {
    return (
      <div style={{ width: '100%', margin: '5px 0' }}>
        <Section
          header="Attributes"
          equipId="56"
          emptyMessage={
            this.props.customAttributes.length === 0
              ? 'no attributes'
              : undefined
          }
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto auto auto',
            }}
          >
            {this.props.customAttributes.map((customAttribute, key) => (
              <CardItem
                key={'attributes' + key}
                title={customAttribute.name}
                value={customAttribute.value ? customAttribute.value : '-'}
                ellipsis
              />
            ))}
          </div>
        </Section>
      </div>
    )
  }
}

export default AttributesSection
