import React from 'react'
import IconButton from './IconButton'
import { IStandardIcons } from '../ux/standardIcons'
import DrawerContainer, { Anchor } from '../ux/DrawerContainer'

interface IState {
  anchorEl: Anchor
  anchor: boolean
}

interface IProps {
  title?: string
  icon: IStandardIcons
  swipeable?: boolean
  anchor?: Anchor
}

/**
 * Same as a normal ButtonDrawer just this one is an Icon instead
 */
class IconButtonDrawer extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: this.props.anchor ? this.props.anchor : 'left',
    anchor: false,
  }

  render() {
    const props = this.props
    const state = this.state
    return (
      <div>
        <IconButton
          variant="appbar"
          icon={this.props.icon}
          onClick={() => this.setState({ anchor: true })}
          tooltip={props.title}
        />
        <DrawerContainer
          swipeable={this.props.swipeable}
          anchorEl={state.anchorEl}
          anchor={state.anchor}
          setAnchor={(bool: boolean) => this.setState({ anchor: bool })}
        >
          {props.children}
        </DrawerContainer>
      </div>
    )
  }
}

export default IconButtonDrawer
