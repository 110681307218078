import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const DoubleFastForward: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M 4 6 L 4 18 L 9.2011719 12 L 4 6 z M 9.5058594 6 L 9.5058594 18 L 14.707031 12 L 9.5058594 6 z M 15 6 L 15 18 L 20.201172 12 L 15 6 z" />,
  'DoubleFastForward'
)

export default DoubleFastForward
