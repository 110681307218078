import { countries, Country } from 'countries-list'
import { ISelectValues } from '../components/ux/Select'

/**
 * An example of what the countries value returns is this:
 * @example
 * US:{
 *  capital: "Washington D.C."
 *  continent: "NA"
 *  currency: "USD,USN,USS"
 *  emoji: "🇺🇸"
 *  emojiU: "U+1F1FA U+1F1F8"
 *  languages: ["en"]
 *  name: "United States"
 *  native: "United States"
 *  phone: "1"
 * }
 */

/**
 *
 */
export const countryKeys = Object.keys(countries)

export const countryEntries = Object.entries(countries).sort()

export const countryValues = Object.values(countries)

export const findCountry = (letters: string) => {
  //@ts-ignore
  return countries[letters] as Country
}
/**
 * This prepares the countries from the library in the format as:
 * @example [{ value: country.name, label: country.name }]
 */
export const countriesArray = countryEntries.map(
  ([short, country]: [string, Country]) => {
    return { value: short, label: country.name } as ISelectValues
  }
)
