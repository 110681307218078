import { Button, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { IOnboardingStepProps } from '../Onboarding'
import RemoveIcon from '@mui/icons-material/Delete'

type AddLogoCallback = (logo: File | undefined | null) => void

interface IOnboardingFirstStepProps {
  userName: string | null
  onLogoAdded: AddLogoCallback
  logo: File | undefined | null
}

const OnboardingFirstStep = ({
  logo: initialLogo,
  onLogoAdded,
  userName,
  next: onClickNext,
  skip: onClickSkip,
}: IOnboardingStepProps & IOnboardingFirstStepProps) => {
  const { logo, onClickAdd, onClickRemove } = useFirstStepHook(
    initialLogo,
    onLogoAdded
  )

  let welcomeString =
    'Welcome to Rovitracker! Customize this account by adding a logo.'
  if (userName) {
    welcomeString =
      welcomeString.substring(0, welcomeString.indexOf('!')) +
      `, ${userName}!` +
      welcomeString.substring(welcomeString.indexOf('!') + 1)
  }

  const buttonClassName = 'onboarding-action-button'

  return (
    <div className="onboarding-step">
      <Typography variant="h4" fontWeight={700} textAlign="center" marginY={2}>
        {welcomeString}
      </Typography>
      <div className={'logo-container ' + (!logo ? 'no-logo-selected' : '')}>
        {logo && (
          <>
            <img src={logo} alt="Your logo" />
            <Tooltip title="Remove">
              <IconButton
                onClick={onClickRemove}
                color="error"
                size="small"
                className="remove-logo-button"
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
      <div className="action-button-group">
        {!logo && (
          <Button className={buttonClassName} onClick={onClickSkip}>
            Skip
          </Button>
        )}
        <Button
          variant="contained"
          className={buttonClassName}
          onClick={!logo ? onClickAdd : onClickNext}
        >
          {!logo ? 'Add' : 'Next'}
        </Button>
      </div>
    </div>
  )
}

const useFirstStepHook = (
  initialLogo: File | undefined | null,
  onLogoAdded: AddLogoCallback
) => {
  const [logo, setLogo] = useState<string | null>(null)

  const onClickAdd = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = () => {
      const files = input.files
      if (files?.length) {
        onAddLogo(files[0])
      }
    }
    input.click()
  }

  const onAddLogo = (logoFile: File) => {
    viewLogo(logoFile)
    onLogoAdded(logoFile)
  }

  const onClickRemove = () => {
    setLogo(null)
    onLogoAdded(null)
  }

  const viewLogo = (file: File) => {
    const reader = new FileReader()
    reader.onload = () => {
      try {
        const dataURL = reader.result as string | null
        setLogo(dataURL)
      } catch {}
    }
    try {
      reader.readAsDataURL(file)
    } catch {}
  }

  if (initialLogo && !logo) {
    viewLogo(initialLogo)
  }

  return {
    logo,
    onClickAdd,
    onClickRemove,
  }
}

export default OnboardingFirstStep
