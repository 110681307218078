import React from 'react'
import { colors } from './roviTheme'

interface Props {
  isVisible?: boolean
  style?: React.CSSProperties
  textStyle?: React.CSSProperties
  /**
   * Added to fix not being able to click buttons on maptrac drawers.
   */
  belowTheSurface?: boolean
  /**
   * Default it forces the component out side the box
   */
  stayInBox?: boolean
}

const MessageOverlay: React.SFC<Props> = (props) => {
  if (props.isVisible) {
    return (
      <div
        style={{
          position: props.stayInBox ? 'relative' : 'absolute',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          zIndex: props.belowTheSurface ? -1 : 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...props.style,
        }}
      >
        <div
          style={{ color: colors.textGrey, fontSize: 18, ...props.textStyle }}
        >
          {props.children}
        </div>
      </div>
    )
  } else return null
}

MessageOverlay.defaultProps = {
  isVisible: true,
}

export default MessageOverlay
