import React from 'react'
import '../../styles/pages/OtherUser.scss'
import Typography from '@mui/material/Typography'
import { IUserInfo } from '../../types/account/user'
import Card from '@mui/material/Card'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TreeSelector, {
  ITreeLayout,
  ITreeAssetsCate,
  ITreeItem
} from '../ux/TreeSelector'
import { backend_api, headersAPI } from '../../constants/api'

interface IProps {
  userData: IUserInfo
  loading: boolean
}

interface IState {
  editFeaturesDialogOpen: boolean
  AccessAll: boolean
  selectableAssets: ITreeLayout[]
}

class AccessLevel extends React.Component<IProps> {
  state = {
    editFeaturesDialogOpen: false,
    AccessAll: true,
    selectableAssets: [],
  }

  loadAssetsbyCategory() {
    fetch(backend_api + '/assets/tree/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response: ITreeAssetsCate[]) => {
        let newResponse: ITreeLayout[] = []
        response.forEach((tree) => {
          let newAssets: ITreeItem[] = []
          tree.assets.forEach((assets) => {
            newAssets.push({
              label: assets.name,
              value: assets.id,
            })
          })
          newResponse.push({
            label: tree.category.name,
            value: tree.category.id,
            children: newAssets
          })
        })
        this.setState({
          selectableAssets: newResponse,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.loadAssetsbyCategory()
  }

  render() {
    return (
      <Card className="otherUserAttributes">
        <Typography variant="h6">Access Level</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.AccessAll}
              onChange={(event) =>
                this.setState({ AccessAll: event.target.checked })
              }
            />
          }
          label="Access to All Things"
        />
        {this.state.AccessAll ? null : (
          <div style={{ display: 'flex' }}>
            <Typography variant="h6">Access limited to:</Typography>
            <TreeSelector
              allLabel="Any Asset"
              treeNodes={this.state.selectableAssets}
            />
            {/* <div
              style={{
                border: '1px solid #ccc',
                // maxHeight: 350,
                padding: 3,
                overflow: 'auto',
                height: 350,
                minWidth: 300,
                maxWidth: 450,
                width: '100%',
              }}
            ></div> */}
          </div>
        )}
      </Card>
    )
  }
}

export default AccessLevel
