import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import DatePicker from '../../datePickers/DatePicker'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import NumberField from '../../textBoxs/NumberField'
import TextField from '../../textBoxs/TextField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { IPart } from '../../../types/service/parts'
import IconButton from '../../buttons/IconButton'
import Typography from '@mui/material/Typography'
import EllipsisText from '../../ux/EllipsisText'
import SelectBlock, { ISelectValues } from '../../ux/SelectBlock'
import { backend_api, headersAPI } from '../../../constants/api'

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    LargeTextArea: {
      width: 512,
      marginTop: 14,
      marginBottom: 0,
    },
    DialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    partsTableContainer: {
      width: '100%',
      maxWidth: 600,
      flex: 1,
      marginTop: 20,
      marginBottom: 25,
    },
    tableCell: {
      flex: 1,
      maxWidth: 200,
    },
    costs: {
      minWidth: 100,
    },
    title: {
      paddingLeft: 20,
      paddingTop: 10,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  open: boolean
  onClose: () => void
  onSave: () => void
  ticketId: string
  parts: IPart[]
}

interface IState {
  startDate: Date | null
  hourMeter: number | null
  issue: string
  parts: IPart[]
  allParts: IPart[]
}

class EditTicketDialog extends React.Component<IProps, IState> {
  state: IState = {
    startDate: new Date(),
    hourMeter: 0,
    issue: '',
    parts: this.props.parts,
    allParts: [],
  }

  grabAllParts = () => {
    fetch(backend_api + '/service/parts/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        this.setState({ allParts: response })
      })
      .catch((err) => console.log(err))
  }

  grabTotalPartCost(parts: IPart[]) {
    let totalPartsCost = 0
    parts.forEach((part) => (totalPartsCost = totalPartsCost + part.cost))
    return totalPartsCost
  }

  grabTotalPartRetail(parts: IPart[]) {
    let totalPartsRetail = 0
    parts.forEach((part) => (totalPartsRetail = totalPartsRetail + part.retail))
    return totalPartsRetail
  }

  addPart = (partId: string) => {
    let part = this.state.allParts.find((p) => p.id === partId)
    if (part) {
      let allParts = this.state.parts
      allParts.push(part)
      this.setState({ parts: allParts })
    }
  }

  removePart = (partId: string) => {
    let partIDX = this.state.allParts.findIndex((p) => p.id === partId)
    if (partIDX) {
      let allParts = this.state.parts
      allParts.splice(partIDX, 1)
      this.setState({ parts: allParts })
    }
  }

  componentDidMount() {
    this.grabAllParts()
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle>Edit Ticket</DialogTitle>
        <DialogContent className={this.props.classes.DialogContent}>
          <DatePicker
            selectedDate={this.state.startDate}
            onChange={(date: Date | null) => {
              console.log(date)
              this.setState({ startDate: date })
            }}
            showToolbar
            className={this.props.classes.TextField}
            label="Repair Start Date"
            required
          />
          <NumberField
            endAdornment="hours"
            value={this.state.hourMeter}
            onChange={(event) => this.setState({ hourMeter: event })}
            label="Current Hour Meter"
            className={this.props.classes.TextField}
            required
          />
          <TextField
            label="Issue"
            required
            multiline
            minRows={4}
            className={this.props.classes.LargeTextArea}
            value={this.state.issue}
            onChange={(event) => this.setState({ issue: event.target.value })}
          />
          <TableContainer
            component={Paper}
            className={this.props.classes.partsTableContainer}
          >
            <Typography variant="h6" className={this.props.classes.title}>
              Parts
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={this.props.classes.tableCell}>
                    Name
                  </TableCell>
                  <TableCell className={this.props.classes.costs} align="right">
                    Cost
                  </TableCell>
                  <TableCell className={this.props.classes.costs} align="right">
                    Retail
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {this.state.parts.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} style={{ flex: 1 }}>
                      - No Parts -
                    </TableCell>
                    <TableCell>
                      <SelectBlock
                        values={this.state.allParts.map(
                          (part) =>
                            ({
                              value: part.id,
                              label: `${part.number} (${part.manufacturer})`,
                            } as ISelectValues)
                        )}
                        onChange={(value) => this.addPart(value as string)}
                        isIconButton
                        tooltip="Add Part"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {this.state.parts.map((part, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <EllipsisText
                          text={`${part.number} (${part.manufacturer})`}
                          width={150}
                        />
                      </TableCell>
                      <TableCell align="right">$ {part.cost}</TableCell>
                      <TableCell align="right">$ {part.retail}</TableCell>
                      <TableCell>
                        <IconButton
                          icon="delete"
                          variant="row"
                          onClick={() => this.removePart(part.id)}
                          tooltip="Delete Part"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right">Totals:</TableCell>
                    <TableCell align="right">
                      $ {this.grabTotalPartCost(this.state.parts).toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      $ {this.grabTotalPartRetail(this.state.parts).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <SelectBlock
                        values={this.state.allParts.map(
                          (part) =>
                            ({
                              value: part.id,
                              label: `${part.number} (${part.manufacturer})`,
                            } as ISelectValues)
                        )}
                        onChange={(value) => this.addPart(value as string)}
                        isIconButton
                        tooltip="Add Part"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose()}>Cancel</Button>
          <Button onClick={() => this.props.onClose()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditTicketDialog)
