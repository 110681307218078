import React from 'react'
import GoogleRoviMap from '../GoogleRoviMap'

interface IProps {
  isMobile: boolean
  height: number
  width: number
}

interface IState {}
class HistoryMaptracContainer extends React.Component<IProps, IState> {
  render() {
    return (
      <GoogleRoviMap
        isMobile={this.props.isMobile}
        onChangeCenter={(val) => console.log(val)}
        height={this.props.height}
        width={this.props.width}
        onClick={() => console.log()}
      >
        {(map) => <></>}
      </GoogleRoviMap>
    )
  }
}

export default HistoryMaptracContainer
