import React from 'react'
import WidthViewport from '../../components/ux/WidthViewport'
import EquipmentInfoDesktop from './EquipmentInfoDesktop'
import EquipmentInfoMobile from './EquipmentInfoMobile'
import EquipmentProfileToolbar from '../../components/equipmentComponents/EquipmentProfileToolbar'
import { IEquipmentProfile } from '../../types/equipment/equipmentProfile'

interface IProps {
  equipmentProfile?: IEquipmentProfile
  isMobile: boolean
  useMetric: boolean
}

interface IState {}

class EquipmentProfile extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <EquipmentProfileToolbar
          equipmentProfile={this.props.equipmentProfile}
          useMetric={this.props.useMetric}
          isMobile={this.props.isMobile}
        />

        <WidthViewport>
          {({ isMobile }) =>
            isMobile ? (
              <EquipmentInfoMobile
                equipmentProfile={this.props.equipmentProfile}
                isMobile={this.props.isMobile}
                useMetric={this.props.useMetric}
              />
            ) : (
              <EquipmentInfoDesktop
                equipmentProfile={this.props.equipmentProfile}
                isMobile={this.props.isMobile}
                useMetric={this.props.useMetric}
              />
            )
          }
        </WidthViewport>
      </div>
    )
  }
}

export default EquipmentProfile
