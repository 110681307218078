import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { IFlagRule, getNewEmptyFlag, IRule } from 'src/constants/flagRules'
import MainView from './MainView'
import { IEquipEntity, IGeofenceEntity } from '../../../types/maptrac/playback'
import AssetView from './AssetView'
import ConditionView from './ConditionView'
import TimeView from './TimeView'

export type FlagDialogViewType = 'main' | 'assets' | 'condition' | 'time'

interface IFlagDialogProps {
  onSave: (flag: IFlagRule) => void
  onClose: () => void
  open: boolean
  useMetricMeasurement?: boolean
  categories: Record<string, any /* IEntityCategory */>
  geofences: Record<string, IGeofenceEntity>
  equipment: Record<string, IEquipEntity>
  flag?: IFlagRule
}

interface IFlagDialogState {
  currentView: FlagDialogViewType
  currentRuleIndex: number
  editedFlag: IFlagRule
}

export default class FlagDialog extends React.Component<
  IFlagDialogProps,
  IFlagDialogState
> {
  constructor(props: IFlagDialogProps) {
    super(props)
    this.state = {
      currentView: 'main',
      currentRuleIndex: 0,
      editedFlag: props.flag || getNewEmptyFlag(),
    }

    this.onSwitchView = this.onSwitchView.bind(this)
    this.onUpdateRule = this.onUpdateRule.bind(this)
    this.onUpdateFlag = this.onUpdateFlag.bind(this)
    this.removeRule = this.removeRule.bind(this)
    this.onCancelClicked = this.onCancelClicked.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<IFlagDialogProps>) {
    if (prevProps.flag !== this.props.flag) {
      this.setState({
        editedFlag: this.props.flag || getNewEmptyFlag(),
      })
    }
  }

  get onMainView() {
    return this.state.currentView === 'main'
  }

  get currentViewTitle() {
    switch (this.state.currentView) {
      case 'main':
        return 'Add Flag'
      case 'assets':
        return 'Applies To'
      case 'condition':
        return 'When'
      case 'time':
        return 'During'
      default:
        return ''
    }
  }

  onSwitchView(newView: FlagDialogViewType, ruleIndex: number) {
    this.setState({
      currentView: newView,
      currentRuleIndex: ruleIndex,
    })
  }

  onUpdateRule({ assets, condition, time }: Partial<IRule>) {
    const { currentRuleIndex, editedFlag } = this.state
    const { rules } = editedFlag

    assets = assets || this.currentRule.assets
    condition = condition || this.currentRule.condition
    time = time || this.currentRule.time

    rules.splice(currentRuleIndex, 1, {
      assets,
      condition,
      time,
    })
    this.onUpdateFlag({ rules })
  }

  onUpdateFlag(flag: Partial<IFlagRule>) {
    this.setState({
      editedFlag: { ...this.state.editedFlag, ...flag },
    })
  }

  removeRule(ruleIndex: number) {
    const { rules } = this.state.editedFlag
    rules.splice(ruleIndex, 1)
    this.onUpdateFlag({ rules })
  }

  onCancelClicked() {
    if (this.onMainView) {
      this.props.onClose()
    } else {
      this.onSwitchView('main', 0)
    }
  }

  get currentRule() {
    return this.state.editedFlag.rules[this.state.currentRuleIndex]
  }

  renderCurrentView() {
    switch (this.state.currentView) {
      case 'main':
        return (
          <MainView
            flag={this.state.editedFlag}
            onChangeView={this.onSwitchView}
            onChangeRule={this.onUpdateFlag}
            onRemoveRule={this.removeRule}
            categories={this.props.categories}
            geofences={this.props.geofences}
            equipment={this.props.equipment}
            useMetricMeasurement={this.props.useMetricMeasurement}
          />
        )
      case 'assets':
        return (
          <AssetView
            categoryTree={[
              {
                value: 'val1',
                label: 'label1',
                children: [
                  { value: 'childval1', label: 'childlabel1' },
                  { value: 'childval2', label: 'childlabel2' },
                ],
              },
              {
                value: 'val2',
                label: 'label2',
              },
            ]}
            values={this.currentRule.assets}
            onChangeAssets={(assets) => this.onUpdateRule({ assets })}
          />
        )
      case 'condition':
        return (
          <ConditionView
            condition={this.currentRule.condition}
            geofences={this.props.geofences}
            useMetric={this.props.useMetricMeasurement}
            branchGeofenceIds={[]}
            onUpdateCondition={(condition) =>
              this.onUpdateRule({
                condition: { ...this.currentRule.condition, ...condition },
              })
            }
          />
        )
      case 'time':
        return (
          <TimeView
            values={this.currentRule.time}
            onChange={(time) => this.onUpdateRule({ time })}
          />
        )
    }
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose} fullWidth>
        <DialogTitle>{this.currentViewTitle}</DialogTitle>
        <DialogContent>{this.renderCurrentView()}</DialogContent>
        <DialogActions>
          <Button onClick={this.onCancelClicked}>
            {this.onMainView ? 'Cancel' : 'Back'}
          </Button>
          {this.state.currentView === 'main' && (
            <Button onClick={() => this.props.onSave(this.state.editedFlag)}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}
