import React, { useState } from 'react'
import IconButton from '../buttons/IconButton'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import orange from '@mui/material/colors/orange'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '../Icon'
import SubscriptionSettingsDialog from './dialogs/SubscriptionSettingsDialog'
import { ITreeLayout } from '../ux/TreeSelector'
import UnSubscribeUserDialog from './dialogs/UnSubscribeUserDialog'

const styles = createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    background: orange[50],
    borderRadius: 500,
    margin: 2,
    border: `1px solid ${orange[400]}`,
  },
  name: {
    flex: 1,
    marginLeft: 8,
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: 'black', // temporary style to move darkmode further
  },
})

interface IProps extends WithStyles<typeof styles> {
  name: string
  id: string
  onClickSettings: () => void
  onClickDelete: () => void
  flagName: string
  flagId?: string
  treeAssetsCategory?: ITreeLayout[]
}

const UserChip = (props: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [settingsDialog, setSettingsDialog] = useState(false)
  const [unsubscribe, setUnsubscribe] = useState(false)

  const handleClick = (ev: any) => {
    setAnchorEl(ev.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className={props.classes.container}>
        <Typography variant="body2" className={props.classes.name}>
          {props.name}
        </Typography>

        <IconButton
          variant="row"
          icon="overflowMenu"
          onClick={(ev) => handleClick(ev as any)}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        disableAutoFocusItem
      >
        <MenuItem
          onClick={() => {
            props.onClickSettings()
            setSettingsDialog(true)
            handleClose()
          }}
          dense
        >
          <ListItemIcon>
            <Icon icon="settings" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onClickDelete()
            setUnsubscribe(true)
            handleClose()
          }}
          dense
        >
          <ListItemIcon>
            <Icon icon="delete" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Unsubscribe" />
        </MenuItem>
      </Menu>
      {settingsDialog ? (
        <SubscriptionSettingsDialog
          dialogOpen={settingsDialog}
          onClose={() => setSettingsDialog(false)}
          flagName={props.flagName}
          flagId={props.flagId}
          treeAssetsCategory={props.treeAssetsCategory}
        />
      ) : null}
      {unsubscribe ? (
        <UnSubscribeUserDialog
          dialogOpen={unsubscribe}
          onClose={() => setUnsubscribe(false)}
          flagName={props.flagName}
          flagId={props.flagId ? props.flagId : ''}
          UserName={props.name}
          UserId={props.id}
        />
      ) : null}
    </>
  )
}

export default withStyles(styles)(UserChip)
