import React from 'react'
import '../styles/pages/Inventory.scss'
import ReactHelmet from '../components/ReactHelmet'

interface IState {}

interface IProps {}

class Yard extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <ReactHelmet title="Yard" />
      </div>
    )
  }
}

export default Yard
