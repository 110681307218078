import React from 'react'
import '../styles/pages/UserProfile.scss'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import Button from '../components/buttons/Button'
import Icon from '../components/Icon'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { CardItem } from '../components/ux/Attribute'
import LoadingOverlay from '../components/LoadingOverlay'
import Panel from '../components/ux/Panel'
import NotificationsForm from '../components/userProfile/NotificationsForm'
import { darkMode } from '../components/ux/roviTheme'
import { Auth } from 'aws-amplify'
import ChangePasswordDialog from '../components/userProfile/dialogs/ChangePasswordDialog'
import EditUserDialog from '../components/userProfile/dialogs/EditUserDialog'

interface IProps {}

interface IState {
  loading: boolean
  passwordDialogOpen: boolean
  editUserDialogOpen: boolean
}

class UserProfile extends React.Component<IProps, IState> {
  state: IState = {
    loading: true,
    passwordDialogOpen: false,
    editUserDialogOpen: false,
  }

  /**
   * Changes the website from either light to darkmode.
   * @param event for when the switch component changes it will grab the HTMLInputElement that changed to be passed through.
   */
  handleDarkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('darkMode', event.target.checked ? 'dark' : 'light')
    window.location.reload()
  }

  componentDidMount() {
    this.setState({ loading: false })
    Auth.currentAuthenticatedUser().then((user) => console.log(user))
  }

  render() {
    return (
      <div className="userProfilePage">
        <ReactHelmet title="Profile" />
        <LoadingOverlay loading={this.state.loading} delayedTime={5000} />
        <Toolbar title="User Profile">
          <Button
            startIcon={<Icon icon="edit" />}
            onClick={() => this.setState({ editUserDialogOpen: true })}
          >
            Edit User
          </Button>
          <Button
            startIcon={<Icon icon="key" />}
            onClick={() => this.setState({ passwordDialogOpen: true })}
          >
            Change Password
          </Button>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                name="DarkMode"
                checked={darkMode}
                onChange={(event) => this.handleDarkModeChange(event)}
              />
            }
            label="DarkMode [BETA]"
          />
        </Toolbar>
        <div className="userProfilePageBody">
          <div className="userProfileAttributes">
            <CardItem title="Name" value="Nathaniel Young" />
            <CardItem title="Email" value="nathan@rovitracker.com" />
            <CardItem title="Branch" value="Salt Lake City" />
          </div>
          <Panel title="Notification Preferences">
            <NotificationsForm />
          </Panel>
        </div>
        {this.state.passwordDialogOpen ? (
          <ChangePasswordDialog
            dialogOpen={this.state.passwordDialogOpen}
            onClose={() => this.setState({ passwordDialogOpen: false })}
          />
        ) : null}
        <EditUserDialog
          dialogOpen={this.state.editUserDialogOpen}
          onClose={() => this.setState({ editUserDialogOpen: false })}
        />
      </div>
    )
  }
}

export default UserProfile
