import React from 'react'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import { ERoutes, PageTitle } from '../components/routes/CheckRoutes'

const history = [
  {
    user: 'Nathaniel Young',
    timeAgo: '4 days ago',
    what: 'created equipment',
    asset: 'Tekelek 1',
  },
  {
    user: 'Nathaniel Young',
    timeAgo: '4 days ago',
    what: 'created equipment',
    asset: 'Tekelek 1',
  },
]

interface IProps {}

class historyPage extends React.Component<IProps> {
  render() {
    console.log(history)
    return (
      <div>
        <ReactHelmet title={PageTitle(ERoutes.history)} />
        <Toolbar title={PageTitle(ERoutes.history)}></Toolbar>
      </div>
    )
  }
}

export default historyPage
