import React from 'react'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { CardItem } from '../ux/Attribute'
import { IEquipmentProfile } from '../../types/equipment/equipmentProfile'
import moment from 'moment'

const styles = createStyles({
  CardBorder: {
    width: '100%',
    marginTop: 5,
    marginBottom: 5,
  },
  cardContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    width: 175.67,
  },
})

interface IProps extends WithStyles<typeof styles> {
  equipmentProfile: IEquipmentProfile
  isMobile: boolean
  useMetric: boolean
  equipId: string
}

/**
 * @description
 * This Component is a Material-UI Card that holds the basic information of the asset, such as the Make/Model, and VIN, etc.
 * This component is already split between both mode Mobile and Desktop so its easy to use outside of this component.
 * @warning This Component is not Dynamic and only displays what's written inside the component already.
 */
class AssetInformationCard extends React.Component<IProps> {
  render() {
    const props = this.props,
      { equipmentProfile } = this.props
    return (
      <>
        {props.isMobile ? (
          // Mobile Version \/
          <Card
            className={props.classes.CardBorder}
            style={{ marginBottom: 0 }}
          >
            <CardContent>
              <Typography variant="h6">Asset Information</Typography>
            </CardContent>
            <CardContent>
              <div>
                <Typography>Available</Typography>
              </div>
            </CardContent>
            <CardContent style={{ display: 'flex' }}>
              <CardItem
                title="Make / Model"
                value={`${equipmentProfile.make} / ${equipmentProfile.model}`}
              />
            </CardContent>
            <CardContent style={{ display: 'flex' }}>
              <CardItem
                title="Category"
                value={equipmentProfile.category.name}
              />
              <CardItem
                title="Year Built"
                value={
                  equipmentProfile.yearBuilt ? equipmentProfile.yearBuilt : '-'
                }
              />
            </CardContent>
            <CardContent style={{ display: 'flex' }}>
              <CardItem
                title="Vin Number"
                value={equipmentProfile.vin ? equipmentProfile.vin : '-'}
              />
              <CardItem
                title="Serial Number"
                value={equipmentProfile.serial ? equipmentProfile.serial : '-'}
              />
            </CardContent>
            <CardContent style={{ display: 'flex' }}>
              <CardItem
                title="Purchase Price"
                value={
                  equipmentProfile.purchasePrice
                    ? '$' + equipmentProfile.purchasePrice
                    : '-'
                }
              />
              <CardItem
                title="Purchase Date"
                value={
                  equipmentProfile.purchaseDate
                    ? moment(equipmentProfile.purchaseDate).format(
                        'MMM DD, YYYY'
                      )
                    : '-'
                }
              />
            </CardContent>
            <CardContent style={{ display: 'flex', width: '100%' }}>
              <div className={props.classes.cardContentMobile}>
                <a href={`/app/equipment/${props.equipId}/reports`}>
                  {equipmentProfile.metrics.find(
                    (metric) => metric.field === 'engineHour'
                  )
                    ? equipmentProfile.metrics.find(
                        (metric) => metric.field === 'engineHour'
                      )?.value
                    : '-'}
                </a>
                <a
                  href={`/app/equipment/${props.equipId}/adjustEngineHours`}
                  style={{ fontSize: 13, marginLeft: 4 }}
                >
                  [Adjust]
                </a>
                <Typography
                  variant="caption"
                  component="p"
                  color="textSecondary"
                >
                  Engine Hour Meter
                </Typography>
              </div>
              <p>
                {equipmentProfile.metrics.find(
                  (metric) => metric.field === 'odometer'
                )
                  ? equipmentProfile.metrics.find(
                      (metric) => metric.field === 'odometer'
                    )?.value
                  : '-'}
              </p>
              <Typography variant="caption" component="p" color="textSecondary">
                Odometer
              </Typography>
            </CardContent>
            <CardContent style={{ display: 'flex' }}>
              <CardItem
                title="Notes"
                value={
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {equipmentProfile.note ? equipmentProfile.note : '-'}
                  </span>
                }
              />
            </CardContent>
          </Card>
        ) : (
          // Desktop Version \/

          <Card className={props.classes.CardBorder}>
            <CardContent>
              <Typography variant="h6">Asset Information</Typography>
            </CardContent>
            <CardContent style={{ display: 'flex' }}>
              <CardItem
                title="Category"
                value={equipmentProfile.category.name}
              />
              <CardItem
                title="Year Built"
                value={
                  equipmentProfile.yearBuilt ? equipmentProfile.yearBuilt : '-'
                }
              />
              <CardItem
                title="Vin Number"
                value={equipmentProfile.vin ? equipmentProfile.vin : '-'}
              />
            </CardContent>
            <CardContent style={{ display: 'flex' }}>
              <CardItem
                title="Purchase Price"
                value={
                  equipmentProfile.purchasePrice
                    ? '$' + equipmentProfile.purchasePrice
                    : '-'
                }
              />
              <CardItem
                title="Purchase Date"
                value={
                  equipmentProfile.purchaseDate
                    ? moment(equipmentProfile.purchaseDate).format(
                        'MMM DD, YYYY'
                      )
                    : '-'
                }
              />
              <CardItem
                title="Serial Number"
                value={equipmentProfile.serial ? equipmentProfile.serial : '-'}
              />
            </CardContent>

            <CardContent style={{ display: 'flex', width: '100%' }}>
              <div className={props.classes.cardContent}>
                <a href={`/app/equipment/${props.equipId}/reports`}>
                  {equipmentProfile.metrics.find(
                    (metric) => metric.field === 'engineHour'
                  )
                    ? equipmentProfile.metrics.find(
                        (metric) => metric.field === 'engineHour'
                      )?.value
                    : '-'}
                </a>
                <a
                  href={`/app/equipment/${props.equipId}/adjustEngineHours`}
                  style={{ fontSize: 13, marginLeft: 4 }}
                >
                  [Adjust]
                </a>
                <Typography
                  variant="caption"
                  component="p"
                  color="textSecondary"
                >
                  Engine Hour Meter
                </Typography>
              </div>

              <div className={props.classes.cardContent}>
                <p>
                  {equipmentProfile.metrics.find(
                    (metric) => metric.field === 'odometer'
                  )
                    ? equipmentProfile.metrics.find(
                        (metric) => metric.field === 'odometer'
                      )?.value
                    : '-'}
                </p>
                <Typography
                  variant="caption"
                  component="p"
                  color="textSecondary"
                >
                  Odometer
                </Typography>
              </div>
            </CardContent>
            <CardContent style={{ display: 'flex' }}>
              <CardItem
                title="Notes"
                value={
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {equipmentProfile.note ? equipmentProfile.note : '-'}
                  </span>
                }
              />
            </CardContent>
          </Card>
        )}
      </>
    )
  }
}

export default withStyles(styles)(AssetInformationCard)
