import { IAssetSnapshotRow } from '../AssetsTabTypes'
import {
  EMonitoring,
  TMonitorTypes,
  ERentStatus,
  TRentStatusTypes,
  ERepairStatus,
  TRepairStatus,
} from '../../../types/inventory'

export default function allFilters(
  thingval: IAssetSnapshotRow[],
  searchValue: string,
  monitoring: TMonitorTypes,
  rentStatus: TRentStatusTypes,
  repairStatus: TRepairStatus,
  categoryId: string,
  userId: string,
  flagId: string,
  clientId: string,
  siteId: string
) {
  return filterSite(
    filterClient(
      filterFlag(
        filterUser(
          filterCategory(
            filterRentStatus(
              filterRepairStatus(
                filterMonitor(filterAssets(thingval, searchValue), monitoring),
                repairStatus
              ),
              rentStatus
            ),
            categoryId
          ),
          userId
        ),
        flagId
      ),
      clientId
    ),
    siteId
  )
}

export function filterAssets(
  thingval: IAssetSnapshotRow[],
  searchValue: string
) {
  return thingval.filter((t) => t.name.includes(searchValue))
}

export function filterMonitor(
  thingval: IAssetSnapshotRow[],
  monitoring: TMonitorTypes
) {
  return thingval.filter((t) => {
    switch (monitoring) {
      case EMonitoring.monitored:
        return !!t.hasDevice
      case EMonitoring.nonMonitored:
        return !t.hasDevice
      default:
        return true
    }
  })
}

export function filterRentStatus(
  thingval: IAssetSnapshotRow[],
  rentStatus: TRentStatusTypes
) {
  return thingval.filter((t) => {
    switch (rentStatus) {
      case ERentStatus.onRent:
        return !!t.agreement
      case ERentStatus.offRent:
        return !!t.agreement
      default:
        return true
    }
  })
}

export function filterRepairStatus(
  thingval: IAssetSnapshotRow[],
  repairStatus: TRepairStatus
) {
  return thingval.filter((t) => {
    switch (repairStatus) {
      case ERepairStatus.onRepair:
        return !!t.inForService
      case ERepairStatus.offRepair:
        return !!t.inForService
      default:
        return true
    }
  })
}

export function filterCategory(
  thingval: IAssetSnapshotRow[],
  category: string
) {
  if (category !== 'all') {
    return thingval.filter((t) => category === t.category.id)
  } else {
    return thingval
  }
}

export function filterUser(thingval: IAssetSnapshotRow[], user: string) {
  if (user !== 'all') {
    return thingval.filter((t) => user === t.user?.id)
  } else {
    return thingval
  }
}

export function filterFlag(thingval: IAssetSnapshotRow[], flag: string) {
  if (flag !== 'all') {
    return thingval.filter((t) => {
      let foundFlag = false
      t.flags?.forEach((f) => {
        if (f.flagId === flag) {
          foundFlag = true
        }
      })
      return foundFlag
    })
  } else {
    return thingval
  }
}

export function filterClient(thingval: IAssetSnapshotRow[], clientId: string) {
  if (clientId !== 'all') {
    return thingval.filter((t) => {
      return t.agreement ? t.agreement.client.id === clientId : false
    })
  } else {
    return thingval
  }
}

export function filterSite(thingval: IAssetSnapshotRow[], siteId: string) {
  if (siteId !== 'all') {
    return thingval.filter((t) => {
      return t.agreement ? t.agreement.site.id === siteId : false
    })
  } else {
    return thingval
  }
}
