import { ERoutes } from '../components/routes/CheckRoutes'
// import {
//   MaptracHashFromString,
//   MaptracHashToString,
//   IMaptracHash,
//   IMaptracFocus,
// } from '../constants/maptracHashController'
// import HashStateManager from '../rx-js/HashStateManager'
// import { Auth } from 'aws-amplify'

// const hash: HashStateManager<IMaptracHash> = new HashStateManager(
//   MaptracHashFromString,
//   MaptracHashToString
// )

// const setMaptracTab = (focus: IMaptracFocus, id: string) => {
//   hash.set({
//     ...hash.value,
//     focus,
//     id,
//   })
// }

export const maptracFocusAsset = (assetId: string) => {
  return `${ERoutes.maptrac}#focus=asset&id=${assetId}`
}

export const maptracFocusSite = (siteId: string) => {
  return `${ERoutes.maptrac}#focus=site&id=${siteId}`
}

export const maptracFocusGeofence = (geofenceId: string) => {
  return `${ERoutes.maptrac}#focus=geofence&id=${geofenceId}`
}

export const branchPageURL = (branchId: string) => {
  return `${ERoutes.branch}/${branchId}`
}

/**
 * This function requires the current user, and the userId to make an educated url change
 * If you have no need for user url change, just pass empty string to currentUserId
 * @param userId uuid for user you are wanting to navigate towards
 * @param currentUserId uuid for the current logged in user
 */
export const userProfileURL = (userId: string, currentUserId?: string) => {
  // let currentUser = undefined
  // Auth.currentUserInfo().then((user) => (currentUser = user))
  // console.log(currentUser) // ex: "us-east-1:557c1877-9ea0-4954-aaa7-a6dd7c74abfe"

  if (currentUserId && currentUserId === userId) {
    return ERoutes.profile
  } else {
    return `${ERoutes.user}/${userId}`
  }
}

export const assetProfile = (assetId: string) => {
  return `${ERoutes.equipment}/56`
}

export const assetReport = (assetId: string, assetReport: string) => {
  return `${ERoutes.equipment}/56#tab=reports`
}
