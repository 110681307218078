import React from 'react'
import '../../styles/ux/LinearProgressBar.scss'
import LinearProgress from '@mui/material/LinearProgress'

/**
 * the types for the liquid types
 * dont forget to update comments
 */
type liquidType = 'fuel' | 'grease' | 'muiRed' | 'muiYellow'

/**
 * the size of the bar
 * dont forget to update comments
 */
type barSize = 'small' | 'medium' | 'large'

const themeSelector = (type?: liquidType) => {
  switch (type) {
    case 'grease':
      return 'muiTopGrease' // this is a class in the scss file - changes the color
    case 'fuel':
      return 'muiTopFuel' // this is a class in the scss file - changes the color
    default:
      return type
  }
}

const barSize = (size?: barSize) => {
  switch (size) {
    case 'small':
      return 4
    case 'medium':
      return 8
    case 'large':
      return 18
    default:
      // defaults to large
      return 18
  }
}

interface IProps {
  level: number
  title: string
  size?: barSize
  type?: liquidType
}

/**
 * Custom Linear ProgressBar from Material-UI https://material-ui.com/api/linear-progress/
 * @param level number
 * @param size 'small' | 'medium' | 'large'
 * @param type 'fuel' | 'grease'
 */
const LinearProgressBar: React.FunctionComponent<IProps> = (props) => (
  <LinearProgress
    title={props.title + ': ' + props.level + '%'}
    style={{
      height: barSize(props.size),
    }}
    classes={{
      // Overriding the material-ui values in order to build what we require
      barColorPrimary: themeSelector(props.type),
      colorPrimary: 'muiBottom',
    }}
    className="bar"
    variant="buffer"
    valueBuffer={100}
    value={props.level}
  />
)

export default LinearProgressBar
