import React from 'react'
import { createStyles, WithStyles, withStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import EllipsisText from '../../ux/EllipsisText'
import NumberField from '../../textBoxs/NumberField'
import { TTaskMetric, ETaskMetric } from '../../../types/service/tasks'

const styles = (theme: Theme) =>
  createStyles({
    equipName: {
      marginRight: 40,
    },
    lastPerformedContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    input: {
      flexShrink: 0,
      width: 140,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  interval: TTaskMetric
  onChange?: () => void
  name?: string
  useMetric?: boolean
  current?: any
}

interface IState {}

class LastPerformedField extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    const classes = this.props.classes
    switch (this.props.interval) {
      case ETaskMetric.time:
        return (
          <div className={classes.lastPerformedContainer}>
            <ListItemText
              primary={<EllipsisText text={this.props.name} />}
              className={classes.equipName}
            />
            Date
          </div>
        )
      case ETaskMetric.engineHours:
        return (
          <div className={classes.lastPerformedContainer}>
            <ListItemText
              primary={<EllipsisText text={this.props.name} />}
              secondary={
                this.props.current
                  ? `Current Engine Hours: ${this.props.current}`
                  : 'Current Engine Hours: --'
              }
              className={classes.equipName}
            />
            <NumberField
              value={0}
              onChange={() => console.log()}
              endAdornment="hours"
              className={classes.input}
            />
          </div>
        )
      case ETaskMetric.odometer:
        return (
          <div className={classes.lastPerformedContainer}>
            <ListItemText
              primary={<EllipsisText text={this.props.name} />}
              secondary={
                this.props.current
                  ? `Current Odometer: ${this.props.current} ${
                      this.props.useMetric ? 'km' : 'mi'
                    }`
                  : 'Current Odometer: --'
              }
              className={classes.equipName}
            />
            <NumberField
              value={0}
              onChange={() => console.log()}
              endAdornment={this.props.useMetric ? 'km' : 'mi'}
              className={classes.input}
            />
          </div>
        )
    }
  }
}

export default withStyles(styles)(LastPerformedField)
