import React from 'react'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material'
import TextField from '../../textBoxs/TextField'
import PhoneNumberInput from '../../textBoxs/PhoneNumberInput'
import Button from '../../buttons/Button'

const styles = () =>
  createStyles({
    TextField: {
      width: 256,
      marginTop: 14,
      marginBottom: 0,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  onClose: () => void
}

interface IState {
  radioType: string
  phoneNumber: string
  email: string
}

class AddPhoneComponent extends React.Component<IProps, IState> {
  state: IState = {
    radioType: 'phone',
    phoneNumber: '',
    email: '',
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <DialogTitle>Add Mobile Phone</DialogTitle>

        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <div>
              <FormLabel>Contact Type</FormLabel>
            </div>
            <RadioGroup
              value={this.state.radioType}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({
                  radioType: (event.target as HTMLInputElement).value,
                })
              }
              row
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                value="phone"
                label="Phone Number"
              />

              <FormControlLabel
                control={<Radio color="primary" />}
                value="email"
                label="Email"
              />
            </RadioGroup>
          </div>

          {this.state.radioType === 'phone' ? (
            <PhoneNumberInput
              onChange={(number) => this.setState({ phoneNumber: number })}
              value={this.state.phoneNumber}
              className={this.props.classes.TextField}
            />
          ) : (
            <TextField
              value={this.state.email}
              onChange={(ev) => this.setState({ email: ev.target.value })}
              className={this.props.classes.TextField}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose()}>Back</Button>
          <Button color="primary" onClick={() => this.props.onClose()}>
            Add
          </Button>
        </DialogActions>
      </>
    )
  }
}

export default withStyles(styles)(AddPhoneComponent)
