import moment from 'moment-timezone'

export const timezoneList = moment.tz.names()

export const currentUserTimezone = moment.tz.guess()

/**
 * This is here to reduce the amount of timezone's down for easier user interactions
 * If you want more timezones use the timezoneList for all 593 timezones in the world
 */
export const coreTimeZones = [
  'America/Anchorage',
  'Pacific/Honolulu',
  'America/Los_Angeles',
  'America/Phoenix',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Argentina/Buenos_Aires',
  'US/Arizona',
  'Europe/London',
  'Europe/Paris',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Africa/Johannesburg',
  'Asia/Baku',
  'Asia/Dubai',
  'Asia/Riyadh',
  'Asia/Qatar',
  'Asia/Karachi',
  'Asia/Calcutta',
  'Asia/Dhaka',
  'Asia/Bangkok',
  'Asia/Hong_Kong',
  'Asia/Tokyo',
  'Asia/Magadan',
  'Australia/Sydney',
  'Pacific/Guam',
  'Pacific/Auckland',
  'Pacific/Kwajalein',
  'Pacific/Midway',
]
