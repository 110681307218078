import React from 'react'
import './priceSheetPrint.css'
import { IconButtonNonA } from '../../buttons/IconButton'
import printJS from 'print-js'
import { backend_api, headersAPI } from '../../../constants/api'
import { IPricesheet } from '../../../types/pricesheet'

const colPixel = 12 * 6

interface IPrintable {
  priceSheet?: IPricesheet
}

class HtmlPrintablePriceSheet extends React.Component<IPrintable> {
  render() {
    return (
      <div id="htmlPrintablePriceSheet">
        <div>
          <div
            style={{
              textAlign: 'center',
              verticalAlign: 'middle',
              width: '100%',
            }}
          >
            <p style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              {this.props.priceSheet?.company}
              <br />
              {this.props.priceSheet?.address}
              <br />
            </p>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th className="pageTitle" colSpan={6}>
                Price Sheet
              </th>
            </tr>
            <tr>
              <th className="title">Equipment</th>
              <th className="title" colSpan={5}>
                Prices
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.priceSheet
              ? this.props.priceSheet.assets.map((category, key1) => (
                  <React.Fragment key={key1 + 'c'}>
                    <tr>
                      <td
                        className="category"
                        style={{ minWidth: colPixel }}
                        colSpan={6}
                      >
                        {category.category.name}
                      </td>
                    </tr>
                    {category.models.map((model, key2) => (
                      <React.Fragment key={key2 + 'm'}>
                        <tr>
                          <td
                            className="model"
                            style={{ minWidth: colPixel }}
                            colSpan={6}
                          >
                            {model.model}
                          </td>
                        </tr>
                        {model.equipment.map((equip, key3) => (
                          <tr
                            key={key3 + 'e'}
                            style={{ borderBlock: '2px solid' }}
                          >
                            <td className="equipment">{equip.name}</td>
                            <td
                              className="price"
                              style={{ minWidth: colPixel }}
                            >
                              {equip.rentalRates.hour
                                ? '$' + equip.rentalRates.hour + '/Hour'
                                : '-'}
                            </td>
                            <td
                              className="price"
                              style={{ minWidth: colPixel }}
                            >
                              {equip.rentalRates.day
                                ? '$' + equip.rentalRates.day + '/Day'
                                : '-'}
                            </td>
                            <td
                              className="price"
                              style={{ minWidth: colPixel }}
                            >
                              {equip.rentalRates.week
                                ? '$' + equip.rentalRates.week + '/Week'
                                : '-'}
                            </td>
                            <td
                              className="price"
                              style={{ minWidth: colPixel }}
                            >
                              {equip.rentalRates.month
                                ? '$' + equip.rentalRates.month + '/Month'
                                : '-'}
                            </td>
                            <td
                              className="price"
                              style={{ minWidth: colPixel }}
                            >
                              {equip.rentalRates.flat
                                ? '$' + equip.rentalRates.flat + '/Flat'
                                : '-'}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))
              : null}
          </tbody>
        </table>
        <div>
          <div style={{ pageBreakInside: 'avoid' }}>
            <div style={{ textAlign: 'center' }}>
              <h2>Terms Of Use</h2>
            </div>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {this.props.priceSheet?.rentalTerms}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

interface IState {
  priceSheet?: IPricesheet
}

interface IProps {}

export default class PriceSheet extends React.Component<IProps, IState> {
  state: IState = {
    priceSheet: undefined,
  }

  htmlPrintable() {
    return (
      <div style={{ display: 'none' }}>
        <HtmlPrintablePriceSheet />
      </div>
    )
  }

  onClick() {
    printJS({
      printable: 'htmlPrintablePriceSheet',
      type: 'html',
      targetStyles: ['*'],
      honorColor: true,
    })
  }

  grabPriceSheet() {
    fetch(backend_api + '/assets/all/priceSheet', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ priceSheet: response })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabPriceSheet()
  }

  render() {
    return (
      <>
        <IconButtonNonA
          icon="pricesheet"
          variant="toolbar"
          tooltip="Pricesheet"
          onClick={() => this.onClick()}
        />
        <div className="no-print">
          <HtmlPrintablePriceSheet priceSheet={this.state.priceSheet} />
        </div>
      </>
    )
  }
}
