import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import { IDeviceType } from '../../../types/account/devices'
import NumberField from '../../textBoxs/NumberField'
import TextField from '../../textBoxs/TextField'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'

const styles = () =>
  createStyles({
    TextField: {
      minWidth: 250,
      marginTop: 14,
      marginBottom: 0,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  deviceInfo: IDeviceType
}

interface IState {
  quantity: number | null
  quantityError: string
  notes: string
}

class PurchaseDeviceDialog extends React.Component<IProps, IState> {
  state: IState = {
    quantity: 1,
    quantityError: '',
    notes: '',
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose}>
        <DialogTitle>
          Purchase {this.props.deviceInfo.label} Devices
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          Use this form to request more {this.props.deviceInfo.label} devices.
          <DialogRow
            FieldLeft={
              <NumberField
                label="Quantity"
                required
                value={this.state.quantity}
                errorMessage={this.state.quantityError}
                className={this.props.classes.TextField}
                onChange={(event: number | null) =>
                  this.setState({
                    quantity: event,
                    quantityError: event
                      ? event < 1
                        ? 'Invalid amount'
                        : ''
                      : 'Invalid amount',
                  })
                }
              />
            }
          />
          <TextField
            multiline
            rows={3}
            fullWidth
            label="Notes"
            value={this.state.notes}
            className={this.props.classes.TextField}
            onChange={(event) => this.setState({ notes: event.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Close</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={!!this.state.quantityError}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(PurchaseDeviceDialog)
