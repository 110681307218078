import React from 'react'
import '../../../styles/pages/service/ticketsSearch/TicketSearchBar.scss'
import Paper from '@mui/material/Paper'
import SelectChip from '../../ux/SelectChip'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import Popover, { PopoverOrigin } from '@mui/material/Popover'
import StatusList from './StatusList'
import InputBase from '@mui/material/InputBase'
import SearchMenu from './SearchMenu'
import { IAssignAsset } from '../../../types/service/tasks'
import LargeStaticDateRangePicker from '../../datePickers/LargeStaticDateRangePicker'
import AssetsList from './AssetsList'
import moment from 'moment'

const styles = (theme: Theme) =>
  createStyles({
    chip: {
      margin: '0 8px',
    },
    chipPopover: {
      maxHeight: 300,
      width: 300,
    },
    chipDatePopover: {
      maxHeight: 600,
      maxWidth: 700,
    },
    inputRoot: {
      color: 'inherit',
      flex: 1,
    },
    inputInput: {
      padding: theme.spacing(1),
      transition: theme.transitions.create('width'),
      width: '100%',
    },
  })

const menuAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}
const menuTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

interface IProps extends WithStyles<typeof styles> {
  statusOpen: boolean
  changeStatus: (status: boolean) => void
  searchBarVal: string
  updateSearchBarVal: (newVal: string) => void
  selectedAsssets: string[]
  updateSelectedAssets: (newArr: string[]) => void
  assetsLoaded: IAssignAsset[]
  createdSelectedDates: Array<Date | null>
  resolvedSelectedDates: Array<Date | null>
  updateResolvedSelectedDates: (date: Array<Date | null>) => void
  updateCreatedSelectedDates: (date: Array<Date | null>) => void
  isMobile?: boolean
}

interface IState {
  statusMenuAnchorEl: any
  assetsMenuAnchorEl: any
  dateFilterMenuAnchorEl: any
}

class TicketSearchBar extends React.Component<IProps, IState> {
  state: IState = {
    statusMenuAnchorEl: null,
    assetsMenuAnchorEl: null,
    dateFilterMenuAnchorEl: null,
  }

  handleOpenAssetsMenu = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      assetsMenuAnchorEl: ev.currentTarget,
    })
  }

  handleOpenStatusMenu = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      statusMenuAnchorEl: ev.currentTarget,
    })
  }

  handleCloseChipMenu = () => {
    this.setState({
      statusMenuAnchorEl: null,
      dateFilterMenuAnchorEl: null,
      assetsMenuAnchorEl: null,
    })
  }

  render() {
    return (
      <Paper className="searchBarContainer">
        <div className="searchBarScroll">
          <>
            <SelectChip
              label="Status"
              value={this.props.statusOpen ? 'Open' : 'Closed'}
              className={this.props.classes.chip}
              onClickSelect={this.handleOpenStatusMenu}
            />
            <Popover
              open={!!this.state.statusMenuAnchorEl}
              anchorEl={this.state.statusMenuAnchorEl}
              onClose={this.handleCloseChipMenu}
              anchorOrigin={menuAnchorOrigin}
              transformOrigin={menuTransformOrigin}
              className={this.props.classes.chipPopover}
            >
              <StatusList
                header={false}
                statusOpen={this.props.statusOpen}
                onChange={this.props.changeStatus}
              />
            </Popover>
          </>

          {this.props.selectedAsssets.length > 0 ? (
            <>
              <SelectChip
                label={
                  this.props.selectedAsssets.length > 1
                    ? `Assets (${this.props.selectedAsssets.length})`
                    : 'Asset'
                }
                value={this.props.selectedAsssets.toString()}
                className={this.props.classes.chip}
                onClickSelect={this.handleOpenAssetsMenu}
                onDelete={() => this.props.updateSelectedAssets([])}
              />
              <Popover
                open={!!this.state.assetsMenuAnchorEl}
                anchorEl={this.state.assetsMenuAnchorEl}
                onClose={this.handleCloseChipMenu}
                anchorOrigin={menuAnchorOrigin}
                transformOrigin={menuTransformOrigin}
                className={this.props.classes.chipPopover}
              >
                <AssetsList
                  header
                  equips={this.props.assetsLoaded}
                  filterEquips={this.props.selectedAsssets}
                  onChange={(newVal: string[]) =>
                    this.props.updateSelectedAssets(newVal)
                  }
                  label="Assets"
                />
              </Popover>
            </>
          ) : null}
          {!this.props.createdSelectedDates.includes(null) ? (
            <>
              <SelectChip
                label={
                  'Created: ' +
                  moment(this.props.createdSelectedDates[0]).format(
                    'MMM DD, YYYY'
                  ) +
                  ' - ' +
                  moment(this.props.createdSelectedDates[1]).format(
                    'MMM DD, YYYY'
                  )
                }
                value={this.props.selectedAsssets.toString()}
                className={this.props.classes.chip}
                onClickSelect={this.handleOpenAssetsMenu}
                onDelete={() =>
                  this.props.updateCreatedSelectedDates([null, null])
                }
              />
              <Popover
                open={!!this.state.assetsMenuAnchorEl}
                anchorEl={this.state.assetsMenuAnchorEl}
                onClose={this.handleCloseChipMenu}
                anchorOrigin={menuAnchorOrigin}
                transformOrigin={menuTransformOrigin}
                className={this.props.classes.chipDatePopover}
              >
                <LargeStaticDateRangePicker
                  disableFuture
                  currentDate={this.props.createdSelectedDates}
                  onApply={(date1: Date | null, date2: Date | null) => {
                    this.props.updateCreatedSelectedDates([date1, date2])
                    this.handleCloseChipMenu()
                  }}
                  onClose={() => this.handleCloseChipMenu()}
                  paddingSaveBar
                  isMobile={this.props.isMobile}
                />
              </Popover>
            </>
          ) : null}
          {!this.props.resolvedSelectedDates.includes(null) ? (
            <>
              <SelectChip
                label={
                  'Resolved: ' +
                  moment(this.props.resolvedSelectedDates[0]).format(
                    'MMM DD, YYYY'
                  ) +
                  ' - ' +
                  moment(this.props.resolvedSelectedDates[1]).format(
                    'MMM DD, YYYY'
                  )
                }
                value={this.props.selectedAsssets.toString()}
                className={this.props.classes.chip}
                onClickSelect={this.handleOpenAssetsMenu}
                onDelete={() =>
                  this.props.updateResolvedSelectedDates([null, null])
                }
              />
              <Popover
                open={!!this.state.assetsMenuAnchorEl}
                anchorEl={this.state.assetsMenuAnchorEl}
                onClose={this.handleCloseChipMenu}
                anchorOrigin={menuAnchorOrigin}
                transformOrigin={menuTransformOrigin}
                className={this.props.classes.chipDatePopover}
              >
                <LargeStaticDateRangePicker
                  disableFuture
                  currentDate={this.props.resolvedSelectedDates}
                  onApply={(date1: Date | null, date2: Date | null) => {
                    this.props.updateResolvedSelectedDates([date1, date2])
                    this.handleCloseChipMenu()
                  }}
                  onClose={() => this.handleCloseChipMenu()}
                  paddingSaveBar
                  isMobile={this.props.isMobile}
                />
              </Popover>
            </>
          ) : null}
          <InputBase
            placeholder="search…"
            classes={{
              root: this.props.classes.inputRoot,
              input: this.props.classes.inputInput,
            }}
            value={this.props.searchBarVal}
            onChange={(ev) => this.props.updateSearchBarVal(ev.target.value)}
          />
        </div>
        <SearchMenu
          assetsLoaded={this.props.assetsLoaded}
          selectedAsssets={this.props.selectedAsssets}
          updateSelectedAssets={this.props.updateSelectedAssets}
          updateCreatedSelectedDates={(date) =>
            this.props.updateCreatedSelectedDates(date)
          }
          updateResolvedSelectedDates={(date) =>
            this.props.updateResolvedSelectedDates(date)
          }
          createdSelectedDates={this.props.createdSelectedDates}
          resolvedSelectedDates={this.props.resolvedSelectedDates}
          isMobile={this.props.isMobile}
        />
      </Paper>
    )
  }
}

export default withStyles(styles)(TicketSearchBar)
