import React from 'react'
import { Polygon, Polyline, Circle } from '@react-google-maps/api'
import { colors } from '../ux/roviTheme'
import { ERadiusType, TRadiusType } from './GeofenceDrawingManager'
import { ILatLng } from '../../types/maptrac/LatLng'

interface IGeofencePolygonProps {
  points: ILatLng[] | google.maps.LatLng[]
  radius?: number
  type?: TRadiusType
  onEditPoints: (points: ILatLng[]) => void
  onClick?: (paths: ILatLng[] | google.maps.LatLng[]) => void
  editable?: boolean
}

export default class EditablePolygon extends React.Component<IGeofencePolygonProps> {
  polygon!: any

  polygonOptions = {
    editable: this.props.editable ? true : false,
    strokeColor: colors.primaryBlue,
    strokeWeight: 4,
    fillOpacity: 0,
    zIndex: 2,
  }

  handleChangePath = () => {
    // const points = this.polygon
    //   .getPath()
    //   .getArray()
    //   .map((path: any) => ({ lat: path.lat(), lng: path.lng() }))
    // this.props.onEditPoints(points)
  }

  /**
   * This component was created to handle different versions of the geofence polygons and polylines
   */
  loadGeofence() {
    switch (this.props.type) {
      case ERadiusType.Rectangle:
      case ERadiusType.Polygon:
        return (
          <Polygon
            ref={(polyref) => {
              this.polygon = polyref
            }}
            onMouseUp={() => setTimeout(this.handleChangePath, 0)} // delays path update slightly so polygon is updated first
            paths={this.props.points}
            options={this.polygonOptions}
            onClick={() =>
              this.props.onClick
                ? this.props.onClick(this.props.points)
                : undefined
            }
          />
        )
      case ERadiusType.Circle:
        return (
          <Circle
            onMouseUp={() => setTimeout(this.handleChangePath, 0)} // delays path update slightly so polygon is updated first
            center={this.props.points[0]}
            radius={this.props.radius ? this.props.radius : 0}
            options={this.polygonOptions}
            onClick={() =>
              this.props.onClick
                ? this.props.onClick(this.props.points)
                : undefined
            }
          />
        )
      default:
        return (
          <Polyline
            path={this.props.points}
            options={this.polygonOptions}
            onClick={() =>
              this.props.onClick
                ? this.props.onClick(this.props.points)
                : undefined
            }
          />
        )
    }
  }

  render() {
    return this.loadGeofence()
  }
}
