import React from 'react'
import AdapterMoment from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker'
import TextField from '@mui/material/TextField'
import { DateRange } from '@mui/lab'

interface IProps {
  onApply?: (date1: Array<Date | null>) => void
  onAccept?: (date1: Array<Date | null>) => void
  currentDate?: Array<Date | null>
  onClose?: () => void
  disableFuture?: boolean
  showToolbar?: boolean
  showTodayButton?: boolean
  paddingSaveBar?: boolean
  isMobile?: boolean
  className?: string
  label?: string
}
function RangeDatePicker(props: IProps) {
  const [date, setDate] = React.useState<DateRange<Date>>([
    props.currentDate ? props.currentDate[0] : new Date(),
    props.currentDate ? props.currentDate[1] : new Date(),
  ])
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDateRangePicker
          value={date}
          onChange={(newValue) => setDate(newValue)}
          onAccept={() => props.onAccept && props.onAccept(date)}
          disableFuture={props.disableFuture}
          allowSameDateSelection
          renderInput={(startParams, endParams) => {
            /**
             * if there is no value for endparams it will show start params
             */
            return !!endParams.inputProps?.value ? (
              <TextField
                {...endParams}
                margin="normal"
                variant="standard"
                helperText=""
                inputProps={{ ...endParams.inputProps, readOnly: true }} // readonly prevents users from messing with the datepicker textbox, as it will break.
                className={props.className}
                label={props.label}
              />
            ) : (
              <TextField
                {...startParams}
                margin="normal"
                variant="standard"
                helperText=""
                inputProps={{ ...startParams.inputProps, readOnly: true }} // readonly prevents users from messing with the datepicker textbox, as it will break.
                className={props.className}
                label={props.label}
              />
            )
          }}
          ToolbarComponent={() => <div></div>}
        />
      </LocalizationProvider>
    </>
  )
}

export default RangeDatePicker
