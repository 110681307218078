import React from 'react'
import moment from 'moment'
import AdapterMoment from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { colors } from '../ux/roviTheme'

interface IProps {
  id?: string
  selectedDate: Date | any
  onChange: (date: Date | null) => void
  onAccept?: (
    date: Date | null,
    keyboardInputValue?: string | undefined
  ) => void
  disableFuture?: boolean
  showToolbar?: boolean
  showTodayButton?: boolean
  className?: string
  label?: string
  required?: boolean
}

function DatePicker(props: IProps) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
          value={props.selectedDate}
          onChange={(newValue) => props.onChange(newValue)}
          onAccept={props.onAccept}
          renderInput={(params) => {
            console.log(params)
            return (
              <TextField
                {...params}
                sx={{ m: 1 }}
                margin="normal"
                variant="standard"
                className={props.className}
                label={props.label}
                required={props.required}
              />
            )
          }}
          disableFuture={props.disableFuture}
          showToolbar={props.showToolbar ? props.showToolbar : false} // here to hide the top part of the dialog
          showTodayButton={props.showTodayButton ? props.showTodayButton : true}
          ToolbarComponent={() => (
            <div
              style={{
                display: 'flex',
                width: 320,
                height: 100,
                backgroundColor: colors.primaryBlue3,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                  padding: '0 25px',
                }}
              >
                <Typography variant="h4" style={{ color: '#fff' }}>
                  {props.selectedDate
                    ? moment(props.selectedDate).format('ddd, MMM D')
                    : moment().format('ddd, MMM D')}
                </Typography>
              </div>
            </div>
          )}
        />
      </LocalizationProvider>
    </>
  )
}

export default DatePicker
