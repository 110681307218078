import React from 'react'
import IconButton from '@mui/material/IconButton'
import { iconPaths } from '../../svg-icons/CategoryIcons'
import CategoryIcon from '../ux/CategoryIcon'
import Popover from '@mui/material/Popover'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Button from '../buttons/Button'
import { TCategoryIcons } from '../../types/category'
import { colors } from '../ux/roviTheme'

export const DEFAULT_ICON = 'lightTowerTrailer'

const styles = createStyles({
  paper: {
    padding: 6,
    width: 383,
  },
  paperMobile: {
    width: 278,
  },
  button: {
    margin: 5,
    width: 80,
  },
})

interface IProps extends WithStyles<typeof styles> {
  icon: TCategoryIcons
  onChange: (icon: TCategoryIcons) => void
  id?: string
  isMobile?: boolean
}

interface IState {
  anchorEl: HTMLElement | null
}

class IconPicker extends React.Component<IProps, IState> {
  state = {
    anchorEl: null,
  }

  handleClick = (ev: any) => {
    this.setState({ anchorEl: ev.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  onChange = (iconName: any) => {
    this.props.onChange(iconName)
    this.handleClose()
  }

  render() {
    return (
      <>
        <Button
          id={this.props.id}
          variant="outlined"
          size="medium"
          className={this.props.classes.button}
          onClick={this.handleClick}
        >
          <CategoryIcon icon={this.props.icon} />
        </Button>
        <Popover
          classes={{
            paper: this.props.isMobile
              ? this.props.classes.paperMobile
              : this.props.classes.paper,
          }}
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handleClose}
          disableRestoreFocus
        >
          {Object.keys(iconPaths).map((iconName: TCategoryIcons | string) => (
            <IconButton
              style={{
                color:
                  this.props.icon === iconName
                    ? colors.primaryBlue
                    : colors.iconColor,
              }}
              key={iconName}
              onClick={() => this.onChange(iconName)}
              id={`categoryIcon${iconName}`}
              title={
                this.props.icon === iconName ? 'Current: ' + iconName : iconName
              }
            >
              {/* @ts-ignore */}
              <CategoryIcon icon={iconName} />
            </IconButton>
          ))}
        </Popover>
      </>
    )
  }
}

export default withStyles(styles)(IconPicker)
