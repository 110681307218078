import React from 'react'
import '../../styles/components/AppBarLinks.scss'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { ILinks } from '../../types/appbar'
import { colors } from '../ux/roviTheme'

const locationTab = (link: string) => {
  switch (link.split('/')[1]) {
    case '':
      return 0
    case 'inventory':
    case 'equipment':
      return 1
    case 'maptrac':
      return 2
    case 'service':
      return 3
    case 'report':
      return 4
    default:
      return false
  }
}

interface IState {
  selected?: number | boolean
}

interface IProps {
  links: Array<ILinks>
}

class AppBarLinks extends React.Component<IProps, IState> {
  state: IState = {
    selected: locationTab(window.location.pathname),
  }

  changeCurrentTab() {
    this.setState({ selected: locationTab(window.location.pathname) })
  }

  componentDidMount() {
    window.addEventListener('hashchange', () => this.changeCurrentTab(), true)
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', () => this.changeCurrentTab())
  }

  render() {
    return (
      <Tabs
        value={this.state.selected}
        indicatorColor="secondary"
        className="linkContainer"
      >
        {this.props.links.map((link, key) => (
          <Tab
            key={key}
            id={`desktopRoviAppBar${link.name}`}
            label={link.name}
            className="appBarLink"
            style={
              this.state.selected === key
                ? { color: colors.defaultText }
                : undefined
            }
            href={link.link}
          />
        ))}
      </Tabs>
    )
  }
}

export default AppBarLinks
