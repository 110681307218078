import React from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Icon from '../Icon'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import EllipsisText from '../ux/EllipsisText'
import { colors } from '../ux/roviTheme'
import { ERoutes } from '../routes/CheckRoutes'
import { Auth } from 'aws-amplify'

interface IProps {}

class ManageList extends React.Component<IProps> {
  state = {
    manageList: [
      { name: 'Account', url: ERoutes.account },
      { name: 'Archive', url: ERoutes.archive },
      { name: 'Attributes', url: ERoutes.attribute },
      { name: 'Categories', url: ERoutes.categories },
      { name: 'Flags', url: ERoutes.flag },
      { name: 'Subscriptions', url: ERoutes.subscriptions },
      { name: 'Tutorials', url: ERoutes.tutorials },
    ],
  }

  signOut = () => {
    Auth.signOut()
  }

  render() {
    return (
      <>
        <ListSubheader style={{ backgroundColor: '#fff' }}>
          Manage
        </ListSubheader>
        <MenuList>
          {this.state.manageList.map((listItem, key) => (
            <MenuItem
              key={key}
              id={`Manage${listItem.name}`}
              component="a"
              href={listItem.url}
            >
              <ListItemText
                primary={<EllipsisText text={listItem.name} width={136} />}
              />
            </MenuItem>
          ))}
        </MenuList>
        <MenuItem
          component="a"
          href={ERoutes.profile}
          style={{ color: 'rgba(0, 0, 0, 0.87)' }} // needed to avoid the color change that was created globally for this
        >
          <ListItemIcon>
            <Icon icon="userProfile" style={{ color: colors.iconColor }} />
          </ListItemIcon>
          <ListItemText
            primary={<EllipsisText text="User Profile" width={136} />}
          />
        </MenuItem>
        <MenuItem
          component="a"
          onClick={() => this.signOut()}
          style={{ color: 'rgba(0, 0, 0, 0.87)' }} // needed to avoid the color change that was created globally for this
        >
          <ListItemIcon>
            <Icon icon="signOut" style={{ color: colors.iconColor }} />
          </ListItemIcon>
          <ListItemText
            primary={<EllipsisText text="Sign Out" width={136} />}
          />
        </MenuItem>
      </>
    )
  }
}

export default ManageList
