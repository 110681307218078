import React from 'react'
import Divider from '@mui/material/Divider'
import '../../styles/components/ExternalFootbar.scss'
interface IState {}

interface IProps {}

class ExternalFootbar extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <div className="registrationFooterContainter">
        <Divider />
        <div className="registrationFooter">
          <div className="registrationFooterInner">
            <div className="registrationCopyRight">
              © {new Date().getFullYear()} RoviTracker, LLC - All right
              reserved.
            </div>
            <div className="registrationFooterRight">
              <a
                className="registrationTermsofService"
                href="https://rovitracker.com/terms-of-service/"
              >
                Terms of Service
              </a>
              <a
                className="registrationPrivacyPolicy"
                href="https://rovitracker.com/privacy-policy/"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ExternalFootbar
