import React from 'react'
import { withStyles } from '@mui/styles'
import { WithStyles, createStyles } from '@mui/styles'
import {
  DialogTitle,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from '@mui/material'
import { Theme } from '@mui/material/styles/createTheme'
import { IUser } from '../../types/account/user'

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxHeight: 400,
    },
    avatar: {
      background: theme.palette.primary.main,
      color: 'white',
    },
  })

interface IProps extends WithStyles<typeof styles> {
  open: boolean
  onClose: () => void
  includeUnassignOption: boolean
  users: IUser[]
}

interface IState {}

class AssignUserDialog extends React.Component<IProps, IState> {
  state: IState = {}

  handleSubmit = (userId: string | null) => {
    this.props.onClose()
    console.log(userId)
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.onClose()}
        PaperProps={{
          className: this.props.classes.paper,
        }}
      >
        <DialogTitle>Assign User</DialogTitle>
        <div>
          <List>
            {this.props.includeUnassignOption && (
              <ListItem button onClick={() => this.handleSubmit(null)}>
                <ListItemText primary="- No User -" />
              </ListItem>
            )}
            {this.props.users.map((user) => (
              <ListItem
                key={user.id}
                button
                onClick={() => this.handleSubmit(user.id)}
              >
                <ListItemAvatar>
                  <Avatar className={this.props.classes.avatar}>
                    {user.firstName[0] + user.lastName[0]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={user.firstName + ' ' + user.lastName} />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AssignUserDialog)
