import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

const styles = () =>
  createStyles({
    scrollContainer: {
      flex: 1,
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      position: 'relative',
    },
    tabItemContainer: {
      fontSize: 11,
    },
    tab: {
      fontSize: 11,
      minWidth: 0, // this is required to override the material-ui min-width: 160
    },
  })

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  style?: React.CSSProperties
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  )
}

interface IProps extends WithStyles<typeof styles> {
  children: React.ReactNode[]
  labels: string[]
  scroll?: boolean
  height?: number
}

interface IState {
  index: number
}

class TabsContainer extends React.Component<IProps, IState> {
  state: IState = {
    index: 0,
  }

  handleTabChange = (i: any) => {
    this.setState({ index: i })
  }

  tabProps(index: any) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    }
  }

  render() {
    const { classes } = this.props
    return (
      <>
        <Tabs
          scrollButtons={false}
          onChange={(_, i) => this.handleTabChange(i)}
          value={this.state.index}
          className={classes.tabItemContainer}
          indicatorColor="primary"
          variant="fullWidth"
        >
          {this.props.labels.map((label, i) => (
            <Tab key={i} value={i} className={classes.tab} label={label} />
          ))}
        </Tabs>

        {this.props.children.map((view, i) => (
          <TabPanel
            key={i}
            value={this.state.index}
            index={i}
            style={{
              overflowY: this.props.scroll ? 'auto' : 'hidden',
              height: '100%',
            }}
          >
            {view}
          </TabPanel>
        ))}
      </>
    )
  }
}

export default withStyles(styles)(TabsContainer)
