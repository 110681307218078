import React from 'react'
import Paper from '@mui/material/Paper'
import Button from '../buttons/Button'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import classNames from 'classnames'
import Icon from '../Icon'
import { colors } from '../ux/roviTheme'
import MaptracOverlay from './MaptracOverlay'

// interface GeolocationPosition {
//   accuracy: number
//   altitude: number
//   altitudeAccuracy: number
//   heading: number
//   latitude: number
//   longitude: number
//   speed: number
// }

const styles = createStyles({
  container: {
    position: 'absolute',
    right: 7,
  },
  mobileContainer: {
    top: 44,
  },
  desktopContainer: {
    bottom: 83,
  },
  button: {
    padding: 4,
  },
  mobileButton: {
    width: 35,
    minWidth: 35,
    height: 35,
  },
  desktopButton: {
    width: 28,
    minWidth: 28,
    height: 28,
  },
})

interface IProps extends WithStyles<typeof styles> {
  isMobile: boolean
  centerUser: (location: { lat: number; lng: number }, zoom: number) => void
  isLiveMap?: boolean
}

interface IState {
  location?: any
  location2: any
}

class CurrentLocationButton extends React.Component<IProps, IState> {
  state: IState = {
    location: undefined,
    location2: undefined,
  }

  setCenter() {
    if (this.state.location) {
      this.props.centerUser(
        {
          lat: this.state.location.coords.latitude,
          lng: this.state.location.coords.longitude,
        },
        14
      )
    }
  }

  /**
   * Will constantly call the backend
   */
  continuousCheckOfPos() {
    window.navigator.geolocation.watchPosition(
      (coords) => this.setState({ location: coords }),
      (denied) => this.setState({ location2: denied })
    )
  }

  componentDidMount() {
    this.continuousCheckOfPos()
  }

  render() {
    // console.log(this.state.location)
    // console.log(this.state.location2)
    return !!this.state.location ? (
      <>
        <MaptracOverlay
          lat={this.state.location.coords.latitude}
          lng={this.state.location.coords.longitude}
        >
          <svg
            style={{
              fill: '#4285f4',
              stroke: 'white',
              strokeWidth: 1.5,
            }}
          >
            <circle cx={10} cy={10} r={6} />
          </svg>
        </MaptracOverlay>

        {this.props.isLiveMap ? (
          <Paper
            className={classNames(
              this.props.classes.container,
              this.props.isMobile
                ? this.props.classes.mobileContainer
                : this.props.classes.desktopContainer
            )}
          >
            <Button
              onClick={() => this.setCenter()}
              className={classNames(
                this.props.classes.button,
                this.props.isMobile
                  ? this.props.classes.mobileButton
                  : this.props.classes.desktopButton
              )}
              disabled={!this.state.location}
            >
              <Icon
                icon="location"
                fontSize="small"
                style={{ color: colors.iconColor }}
              />
            </Button>
          </Paper>
        ) : null}
      </>
    ) : null
  }
}

export default withStyles(styles)(CurrentLocationButton)
