import React from 'react'
import EntityDetailsPanel from '../../EntityDetailsPanel'
import { ICategory } from '../../../../types/category'
import { colors } from '../../../ux/roviTheme'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import { backend_api, headersAPI } from '../../../../constants/api'
import { LoadingIcon } from '../../../LoadingOverlay'
import AssetListItem from '../maptracLiveAsset/AssetListItem'

export interface IAsset {
  id: string
  name: string
  make: string
  model: string
  imageUrl: string
  tasks: Array<{
    type?: 'months' | 'days' | 'miles'
    task: string
    time: string
    scheduleRatio: string
  }>

  user?: {
    userId: string
    firstName: string
    lastName: string
    role: string
  }
  hasCamera: boolean
  battery: {
    field: string
    value: number
  }
  ignition: boolean
  siteId: string
  flags: Array<{
    flagId: string
    flagName: string
  }>
  category: ICategory
}

interface ISite {
  siteId: string
  siteName: string
  siteSubTitle: string
  siteAssets: IAsset[]
  siteGPS: boolean
}

const styles = createStyles({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    fontSize: 12,
    height: 15,
  },
  centerRow: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'white',
    width: 16,
    height: 16,
  },
  noItemsMessage: {
    fontSize: 16,
    padding: 20,
    textAlign: 'center',
    color: colors.textGrey,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  flagsIcon: {
    color: 'white',
  },
  spacing: {
    marginRight: 8,
  },
  noGpsMessage: {
    fontSize: 11,
    padding: 8,
    textAlign: 'center',
    color: colors.textGrey,
  },
})

interface IDetailsProps extends WithStyles<typeof styles> {
  category: ICategory
  make: string
  model: string
}

interface IProps extends WithStyles<typeof styles> {
  equip: any
  isMobile: boolean
  onBack: () => void
  assetClicked: (id: any) => void
}

interface IState {
  site?: ISite
}

class SiteDetailsMaptrac extends React.Component<IProps, IState> {
  state: IState = {
    site: undefined,
  }

  grabMaptracFocusSite = () => {
    fetch(backend_api + '/maptrac/sites/focus', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        this.setState({ site: response })
      })
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    this.grabMaptracFocusSite()
  }

  render() {
    return this.state.site ? (
      <EntityDetailsPanel
        onBack={this.props.onBack}
        isMobile={this.props.isMobile}
        width={350}
        title={this.state.site.siteName}
        subTitle={this.state.site.siteSubTitle}
        navItems={[
          { label: 'View', icon: 'site', onClick: () => console.log() },
          {
            label: 'Edit GPS',
            icon: 'editCoord',
            onClick: () => console.log(),
          },
        ]}
      >
        {this.state.site.siteGPS && (
          <div className={this.props.classes.noGpsMessage}>
            WARNING: This site has no GPS coordinates associated with it. It
            will not be displayed on the map.
          </div>
        )}
        {this.state.site.siteAssets.length <= 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: colors.textGrey,
              fontSize: 16,
              height: 100,
            }}
          >
            No Assets
          </div>
        )}
        {this.state.site.siteAssets.map((equip: IAsset, key) => (
          <AssetListItem
            key={key}
            id={equip.id}
            name={equip.name}
            make={equip.make}
            model={equip.model}
            imageUrl={equip.imageUrl}
            icon={equip.category.icon}
            onClick={() => this.props.assetClicked(equip.id)}
            tasks={equip.tasks}
            user={equip.user}
            hasCamera={equip.hasCamera}
            battery={equip.battery}
            ignition={equip.ignition}
            flags={equip.flags}
          />
        ))}
      </EntityDetailsPanel>
    ) : (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingIcon loading />
      </div>
    )
  }
}

export default withStyles(styles)(SiteDetailsMaptrac)
