import React from 'react'
import { WithStyles } from '@mui/styles'
import {
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
} from '@mui/material'
import { createStyles, withStyles } from '@mui/styles'
import EllipsisText from '../../ux/EllipsisText'
import { IPart } from '../../../types/service/parts'

const styles = () =>
  createStyles({
    partsTableContainer: { width: '100%', maxWidth: 600, flex: 1 },
    tableCell: { flex: 1, maxWidth: 200 },
    costs: { minWidth: 100 },
    title: { paddingLeft: 20, paddingTop: 10 },
  })

interface IProps extends WithStyles<typeof styles> {
  parts: IPart[]
}

interface IState {}

class PartsTable extends React.Component<IProps, IState> {
  state: IState = {}

  grabTotalPartCost(parts: IPart[]) {
    let totalPartsCost = 0
    parts.forEach((part) => (totalPartsCost = totalPartsCost + part.cost))
    return totalPartsCost
  }

  grabTotalPartRetail(parts: IPart[]) {
    let totalPartsRetail = 0
    parts.forEach((part) => (totalPartsRetail = totalPartsRetail + part.retail))
    return totalPartsRetail
  }

  render() {
    const classes = this.props.classes
    return (
      <TableContainer component={Paper} className={classes.partsTableContainer}>
        <Typography variant="h6" className={classes.title}>
          Parts
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Name</TableCell>
              <TableCell className={classes.costs} align="right">
                Cost
              </TableCell>
              <TableCell className={classes.costs} align="right">
                Retail
              </TableCell>
            </TableRow>
          </TableHead>
          {this.props.parts.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} style={{ flex: 1 }}>
                  - No Parts -
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {this.props.parts.map((part, i) => (
                <TableRow key={i}>
                  <TableCell className={classes.tableCell}>
                    <EllipsisText
                      text={`${part.number} (${part.manufacturer})`}
                      width={150}
                    />
                  </TableCell>
                  <TableCell align="right">$ {part.cost}</TableCell>
                  <TableCell align="right">$ {part.retail}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="right" className={classes.tableCell}>
                  Totals:
                </TableCell>
                <TableCell align="right">
                  $ {this.grabTotalPartCost(this.props.parts).toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  $ {this.grabTotalPartRetail(this.props.parts).toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    )
  }
}

export default withStyles(styles)(PartsTable)
