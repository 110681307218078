import React from 'react'
import '../../styles/pages/OtherUser.scss'
import { IUserInfo } from '../../types/account/user'
import { CardItem } from '../ux/Attribute'
import Card from '@mui/material/Card'
import { label } from '../../types/account/user'
import IconButton from '../buttons/IconButton'
import EditFeaturesDialog from './dialogs/EditFeaturesDialog'

interface IProps {
  userData: IUserInfo
  loading: boolean
}

interface IState {
  editFeaturesDialogOpen: boolean
}

class OtherUserInformation extends React.Component<IProps> {
  state = {
    editFeaturesDialogOpen: false,
  }

  render() {
    return (
      <div>
        <Card className="otherUserAttributes">
          <CardItem title="First Name" value={this.props.userData.firstName} />
          <CardItem title="Last Name" value={this.props.userData.lastName} />
          <CardItem title="Email" value={this.props.userData.email} />
          <CardItem title="Branch" value={this.props.userData.branchName} />
          <CardItem title="Role" value={label[this.props.userData.role]} />
          <CardItem
            title="Visible Features"
            value={
              <>
                {this.props.userData.visibleFeatures.toString()}
                <IconButton
                  icon="edit"
                  variant="row"
                  tooltip="Edit Visible Features"
                  onClick={() =>
                    this.setState({ editFeaturesDialogOpen: true })
                  }
                />
              </>
            }
          />
          <CardItem
            title="View Only"
            value={
              this.props.userData.viewOnly
                ? 'This user CAN make changes'
                : 'This user CANNOT make changes'
            }
          />
        </Card>
        <EditFeaturesDialog
          dialogOpen={this.state.editFeaturesDialogOpen}
          onClose={() => this.setState({ editFeaturesDialogOpen: false })}
        />
      </div>
    )
  }
}

export default OtherUserInformation
