import { IBounds } from '../../types/maptrac/maptracTypes'
import { googleMapsApiKey } from '../../google/googleData'

export const googleMapUrlBuilder = (): string => {
  const baseUrl = 'https://maps.googleapis.com/maps/api/js'
  // const baseLibs = ['geometry', 'drawing', 'places']
  // was geometry,places,events,drawing,visualization for maptrac
  return baseUrl + `?key=${googleMapsApiKey}` //&libraries=${baseLibs.toString()}
}

export const getMapUrl = (gpsCoordinate: any) => {
  const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap'
  const coords = `${gpsCoordinate.lat},${gpsCoordinate.lng}`
  const zoom = 'zoom=17'
  const size = 'size=360x340'
  const marker = 'markers=color:red|' + coords
  return `${baseUrl}?center=${coords}&${zoom}&${size}&${marker}&key=${googleMapsApiKey.key}`
}

const padding = 0.0025

interface ICoords {
  lat: number
  lng: number
}

export const getDataMapBounds = (data: Array<ICoords>): IBounds => {
  const coordsLists = data.reduce(
    // @ts-ignore
    (total, curr) => {
      return {
        lat: [...total.lat, curr.lat],
        lng: [...total.lng, curr.lng],
      }
    },
    { lat: [], lng: [] }
  )
  // @ts-ignore
  if (coordsLists.lat.length === 0) {
    return {
      south: 0 - padding,
      north: 0 + padding,
      west: 0 - padding,
      east: 0 + padding,
    }
  }

  const bounds = {
    // @ts-ignore
    south: Math.min(...coordsLists.lat) - padding, // @ts-ignore
    north: Math.max(...coordsLists.lat) + padding, // @ts-ignore
    west: Math.min(...coordsLists.lng) - padding, // @ts-ignore
    east: Math.max(...coordsLists.lng) + padding,
  }

  return bounds
}

const panelHeight = 72
const drawerWidth = 350

interface IDimensions {
  height: number
  width: number
}

export const getAdjustedBounds = (
  bounds: any,
  isMobile: boolean,
  dimensions: IDimensions
  // @ts-ignore
): google.maps.LatLngBounds => {
  if (isMobile) {
    const viewablePercentage =
      (dimensions.height - panelHeight) / dimensions.height
    const southBounds =
      bounds.north - (bounds.north - bounds.south) / viewablePercentage
    // @ts-ignore
    return new google.maps.LatLngBounds(
      { lat: southBounds, lng: bounds.west },
      { lat: bounds.north, lng: bounds.east }
    )
  } else {
    const viewablePercentage =
      (dimensions.width - drawerWidth) / dimensions.width
    const westBounds =
      bounds.east - (bounds.east - bounds.west) / viewablePercentage
    // @ts-ignore
    return new google.maps.LatLngBounds(
      { lat: bounds.south, lng: westBounds },
      { lat: bounds.north, lng: bounds.east }
    )
  }
}

const radiusMi = 3959 // radius of the earth in mi
const radiusKm = 6371 // radius of the earth in km
const deg2rad = (deg: number): number => deg * (Math.PI / 180)

// TODO: use geolib - similar to rovi-util getDistance
export const getDistance = (
  coords1: ICoords,
  coords2: ICoords,
  useMetric: boolean
): number => {
  const dLat = deg2rad(coords2.lat - coords1.lat) // deg2rad below
  const dLon = deg2rad(coords2.lng - coords1.lng)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(coords1.lat)) *
      Math.cos(deg2rad(coords2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const dist = useMetric ? radiusKm * c : radiusMi * c

  return Math.round(dist * 10) / 10
}

export const fromKmToMi = (distKm: number): number => distKm * 0.621371
