import React from 'react'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import WidthViewport from '../components/ux/WidthViewport'
import Button from '../components/buttons/Button'
import Icon from '../components/Icon'
import { ERoutes, PageTitle } from '../components/routes/CheckRoutes'
import { backend_api, headersAPI } from '../constants/api'
import { LoadingIcon } from '../components/LoadingOverlay'
import { ICategory } from '../types/category'
import CategoryRow from '../components/categoriesComponent/CategoryRow'
import AddEditCategoryDialog from '../components/categoriesComponent/dialogs/AddEditCategoryDialog'
import FabButton from '../components/buttons/FabButton'
import { RoviLog } from '../utility/roviLog'

interface ICategoryPage extends ICategory {
  things: number
}

interface IProps {}

interface IState {
  categorys: ICategoryPage[]
  categorysLoading: boolean
  addCategoryDialogOpen: boolean
}

class CategoriesPage extends React.Component<IProps, IState> {
  state: IState = {
    categorys: [],
    categorysLoading: true,
    addCategoryDialogOpen: false,
  }

  grabCategory() {
    fetch(backend_api + '/category', { method: 'GET', headers: headersAPI })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          categorys: response,
          categorysLoading: false,
        })
      })
      .catch((err) => RoviLog.info(err, 'error'))
  }

  componentDidMount() {
    this.grabCategory()
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ReactHelmet title={PageTitle(ERoutes.categories)} />
            <Toolbar
              title={PageTitle(ERoutes.categories)}
              scrollBackgroundColor
              noXtraBar={isMobile}
              defaultBackgroundColor
            >
              {isMobile ? null : (
                <Button
                  onClick={() => this.setState({ addCategoryDialogOpen: true })}
                >
                  <Icon icon="add" variant="button" />
                  Category
                </Button>
              )}
              <LoadingIcon loading={this.state.categorysLoading} />
            </Toolbar>
            <div
              style={{
                marginTop: isMobile ? 50 : 100,
                marginBottom: isMobile ? 80 : undefined,
              }}
            >
              {this.state.categorys.map((val: ICategoryPage, key) => (
                <CategoryRow category={val} isMobile={isMobile} key={key} />
              ))}
            </div>
            {isMobile ? (
              <FabButton
                icon="add"
                onClick={() => this.setState({ addCategoryDialogOpen: true })}
              />
            ) : null}
            {this.state.addCategoryDialogOpen ? (
              <AddEditCategoryDialog
                type="add"
                isMobile={isMobile}
                dialogOpen={this.state.addCategoryDialogOpen}
                onClose={() => this.setState({ addCategoryDialogOpen: false })}
              />
            ) : null}
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default CategoriesPage
