import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { backend_api, headersAPI } from '../../../constants/api'
import moment from 'moment'
import { ICategory } from '../../../types/category'
import Select, { ISelectValues } from '../../ux/Select'
import { countBackwardsInYears } from '../../../constants/yearBackwardsCount'
import TextField from '../../textBoxs/TextField'
import InfoHover from '../../buttons/InfoHover'
import DatePicker from '../../datePickers/DatePicker'
import Button from '../../buttons/Button'
import { IUser } from '../../../types/account/user'
import NumberField from '../../textBoxs/NumberField'
import {
  IEquipmentProfile,
  ICustomAttributes,
} from '../../../types/equipment/equipmentProfile'

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    LargeTextArea: {
      width: 512,
      marginTop: 14,
      marginBottom: 0,
    },
    AttributeTextField: {
      marginRight: 9,
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    DatePicker: {
      width: 250,
      marginBottom: 0,
    },
  })

function SortCategoriesintoSelectValue(cateogories: ICategory[]) {
  let selectCategories: ISelectValues[] = []
  cateogories.forEach((category) => {
    selectCategories.push({ value: category.id, label: category.name })
  })
  return selectCategories
}

function SortUsersintoSelectValue(users: IUser[]) {
  let selectUsers: ISelectValues[] = []
  users.forEach((user) => {
    selectUsers.push({
      value: user.id,
      label: user.firstName + ' ' + user.lastName,
    })
  })
  return selectUsers
}

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  isMobile: boolean
  equipmentProfile: IEquipmentProfile
  duplicate?: boolean
}

interface IState {
  // Category
  categories: ICategory[]
  selectCategoryValue: ISelectValues | string
  // User
  users: IUser[]
  selectUserValue: ISelectValues | string
  // Year
  selectYearValue: ISelectValues | string
  // Purchase Date
  selectedDate: Date | null
  // ID
  ID: string
  // Vin
  VIN: string
  // Make
  make: string
  // Model
  model: string
  // SN
  serialNumber: string
  // Purchase Price
  price: number | null
  // Notes about the Asset
  notes: string
  attributes: ICustomAttributes[]
}

class EditDuplicateAssetDialog extends React.Component<IProps, IState> {
  state: IState = {
    categories: [],
    users: [],
    selectCategoryValue: '',
    selectUserValue: '',
    selectYearValue: '',
    selectedDate: null,
    ID: '',
    VIN: '',
    make: '',
    model: '',
    serialNumber: '',
    price: null,
    notes: '',
    attributes: [],
  }

  handleClose = () => {
    this.props.onClose()
  }

  handleSubmit = () => {
    this.props.onClose()
  }

  loadUsers() {
    fetch(backend_api + '/users/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          users: response,
        })
      })
      .catch((err) => console.log(err))
  }

  loadCategories() {
    fetch(backend_api + '/categories/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          categories: response,
        })
      })
      .catch((err) => console.log(err))
  }

  isSaveButtonDisabled() {
    return !(
      !!this.state.selectCategoryValue &&
      !!this.state.ID &&
      !!this.state.make &&
      !!this.state.model
    )
  }

  onSubmit() {
    if (this.props.duplicate) {
      console.log({
        category: this.state.selectCategoryValue,
        ID: this.state.ID,
        VIN: this.state.VIN,
        make: this.state.make,
        model: this.state.model,
        year: this.state.selectYearValue,
        sn: this.state.serialNumber,
        price: this.state.price,
        purchaseDate: this.state.selectedDate,
        user: this.state.selectUserValue,
      })
    } else {
      console.log({
        category: this.state.selectCategoryValue,
        ID: this.state.ID,
        VIN: this.state.VIN,
        make: this.state.make,
        model: this.state.model,
        year: this.state.selectYearValue,
        sn: this.state.serialNumber,
        price: this.state.price,
        purchaseDate: this.state.selectedDate,
        user: this.state.selectUserValue,
        notes: this.state.notes,
        attributes: this.state.attributes,
      })
    }
  }

  componentDidMount() {
    this.loadCategories()
    this.loadUsers()
    const { equipmentProfile } = this.props
    this.setState({
      selectCategoryValue: {
        value: equipmentProfile.category.id,
        label: equipmentProfile.category.name,
      },
      selectUserValue: equipmentProfile.user
        ? {
            value: equipmentProfile.user.id,
            label:
              equipmentProfile.user.firstName +
              ' ' +
              equipmentProfile.user.lastName,
          }
        : '',
      selectYearValue: equipmentProfile.yearBuilt,
      selectedDate: moment(equipmentProfile.purchaseDate).toDate(),
      ID: this.props.duplicate
        ? 'Copy of ' + equipmentProfile.name
        : equipmentProfile.name,
      VIN: equipmentProfile.vin ? equipmentProfile.vin : '',
      make: equipmentProfile.make ? equipmentProfile.make : '',
      model: equipmentProfile.model ? equipmentProfile.model : '',
      serialNumber: equipmentProfile.serial ? equipmentProfile.serial : '',
      price: equipmentProfile.purchasePrice
        ? parseInt(equipmentProfile.purchasePrice)
        : null,
      notes: equipmentProfile.note ? equipmentProfile.note : '',
      attributes: equipmentProfile.customAttributes,
    })
  }

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>
          {this.props.duplicate ? 'Add' : 'Edit'} Equipment
        </DialogTitle>
        <DialogContent>
          <DialogRow
            FieldLeft={
              <Select
                label="Category"
                selectedValue={this.state.selectCategoryValue}
                values={SortCategoriesintoSelectValue(this.state.categories)}
                onChange={(value: ISelectValues) =>
                  this.setState({
                    selectCategoryValue: value,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="ID"
                required
                value={this.state.ID}
                onChange={(event) => this.setState({ ID: event.target.value })}
                className={this.props.classes.TextField}
              />
            }
            FieldRight={
              <TextField
                label="VIN"
                className={this.props.classes.TextField}
                value={this.state.VIN}
                onChange={(event) => this.setState({ VIN: event.target.value })}
              />
            }
            FieldRightAddOn={
              <InfoHover>
                <div style={{ margin: '8px 0' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    Why Is My Vin Invalid?{' '}
                  </span>
                  Vins should be 17 characters long, avoid the letters I, O, Q,
                  and all letters should be uppercase. The vin should also pass
                  the{' '}
                  <a
                    href="https://vin.dataonesoftware.com/vin_basics_blog/bid/112040/use-vin-validation-to-improve-inventory-quality"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    VIN Checksum Algorithm
                  </a>
                  . An invalid vin can be saved to an equipment.
                </div>
              </InfoHover>
            }
          />

          <DialogRow
            FieldLeft={
              <TextField
                label="Make"
                required
                className={this.props.classes.TextField}
                value={this.state.make}
                onChange={(event) =>
                  this.setState({ make: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                label="Model"
                required
                className={this.props.classes.TextField}
                value={this.state.model}
                onChange={(event) =>
                  this.setState({ model: event.target.value })
                }
              />
            }
          />

          <DialogRow
            FieldLeft={
              <Select
                label="Year Built"
                selectedValue={this.state.selectYearValue}
                values={countBackwardsInYears(moment().format('YYYY'), 100)}
                onChange={(value: ISelectValues) =>
                  this.setState({
                    selectYearValue: value,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
            FieldRight={
              <TextField
                label="Serial Number"
                className={this.props.classes.TextField}
                value={this.state.serialNumber}
                onChange={(event) =>
                  this.setState({ serialNumber: event.target.value })
                }
              />
            }
          />

          <DialogRow
            FieldLeft={
              <NumberField
                value={this.state.price}
                onChange={(event) => this.setState({ price: event })}
                startAdornment="$"
                label="Purchase Price"
                className={this.props.classes.TextField}
              />
            }
            FieldRight={
              <DatePicker
                selectedDate={this.state.selectedDate}
                onChange={(date: Date | null) => {
                  console.log(date)
                  this.setState({ selectedDate: date })
                }}
                showToolbar
                className={this.props.classes.DatePicker}
                label="Purchase Date"
              />
            }
          />

          <DialogRow
            FieldLeft={
              <Select
                label="User"
                selectedValue={this.state.selectUserValue}
                values={SortUsersintoSelectValue(this.state.users)}
                onChange={(value: ISelectValues) =>
                  this.setState({
                    selectUserValue: value,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
          />
          {/* These options should only show up when the dialog is used for editing */}
          {!this.props.duplicate ? (
            <>
              {this.state.attributes.map((attribute, attributeIndx) => (
                <TextField
                  key={'attribute' + attributeIndx}
                  label={attribute.name}
                  className={this.props.classes.AttributeTextField}
                  value={this.state.attributes[attributeIndx].value}
                  onChange={(event) => {
                    let stateAttribute = this.state.attributes
                    stateAttribute[attributeIndx].value = event.target.value
                    this.setState({ attributes: stateAttribute })
                  }}
                />
              ))}
              <TextField
                label="Notes"
                multiline
                minRows={4}
                className={this.props.classes.LargeTextArea}
                value={this.state.notes}
                onChange={(event) =>
                  this.setState({ notes: event.target.value })
                }
              />
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditDuplicateAssetDialog)
