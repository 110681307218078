import React from 'react'
import '../../styles/pages/account/branchPage.scss'
import ReactHelmet from '../../components/ReactHelmet'
import { backend_api, headersAPI } from '../../constants/api'
import { IBranch } from '../../types/account/account'
import LoadingOverlay from '../../components/LoadingOverlay'
import WidthViewport from '../../components/ux/WidthViewport'
import Toolbar from '../../components/toolbar/Toolbar'
import Icon from '../../components/Icon'
import Button from '../../components/buttons/Button'
import AddEditBranchDialog from '../../components/accountComponents/dialogs/AddEditBranchDialog'
import BranchInfoComponent from '../../components/accountComponents/BranchInfoComponent'

interface IProps {
  id: any
}

interface IState {
  branchData?: IBranch
  loadingData: boolean
  editBranchDialogOpen: boolean
}

class BranchPage extends React.Component<IProps, IState> {
  state: IState = {
    loadingData: true,
    editBranchDialogOpen: false,
  }

  grabBranchData() {
    fetch(backend_api + '/account/branch', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          branchData: response,
          loadingData: false,
        })
      })
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    this.grabBranchData()
  }

  render() {
    console.log(this.state.branchData)
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <div
            className="branchPage"
            style={{ padding: isMobile ? '48px 0' : undefined }}
          >
            <ReactHelmet title="Branch" />
            <LoadingOverlay loading={this.state.loadingData} />
            {isMobile ? null : (
              <Toolbar
                title={`Branch: ${
                  this.state.branchData ? this.state.branchData.BranchName : ''
                }`}
                scrollBackgroundColor
              >
                <Button
                  onClick={() => this.setState({ editBranchDialogOpen: true })}
                  disabled={this.state.loadingData}
                >
                  <Icon icon="edit" variant="button" />
                  Edit
                </Button>
              </Toolbar>
            )}
            {this.state.branchData ? (
              <BranchInfoComponent
                branch={this.state.branchData}
                isMobile={isMobile}
              />
            ) : null}
            {!!this.state.branchData && this.state.editBranchDialogOpen ? (
              <AddEditBranchDialog
                type="edit"
                dialogOpen={this.state.editBranchDialogOpen}
                onClose={() => this.setState({ editBranchDialogOpen: false })}
                name={this.state.branchData.BranchName}
                address={this.state.branchData.BranchAddress}
                city={this.state.branchData.BranchCity}
                stateProvince={this.state.branchData.BranchState}
                postalCode={this.state.branchData.BranchZipCode}
                country={this.state.branchData.BranchCountry}
                timezone={this.state.branchData.BranchTimeZone}
                taxRentals={this.state.branchData.taxRental}
                defaultTax={this.state.branchData.defaultTax}
                laborHourly={this.state.branchData.BranchLaborHourlyRate}
                metricSystem={this.state.branchData.metricSystem}
              />
            ) : null}
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default BranchPage
