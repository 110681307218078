import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import { Typography } from '@mui/material'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import CheckContainer from '../../ux/CheckContainer'
import TreeSelector, { ITreeLayout } from '../../ux/TreeSelector'

const styles = createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
  },
})

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  flagName: string
  flagId?: string
  treeAssetsCategory?: ITreeLayout[]
}

interface IState {
  flagged: boolean
  unflagged: boolean
}

class SubscriptionSettingsDialog extends React.Component<IProps, IState> {
  state: IState = {
    flagged: true,
    unflagged: false,
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  render() {
    const isPlaces = this.props.flagName === 'Places Alert'
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>{this.props.flagName} Subscription Settings</DialogTitle>
        <DialogContent>
          <div>
            <Typography variant="body1">Notify when:</Typography>
            <CheckContainer
              label="Flagged"
              checked={this.state.flagged}
              onChange={(bool) => this.setState({ flagged: bool })}
            />
            <CheckContainer
              label="UnFlagged"
              checked={this.state.unflagged}
              onChange={(bool) => this.setState({ unflagged: bool })}
            />
          </div>
          {!this.state.flagged && !this.state.unflagged ? (
            <Typography
              variant="body1"
              style={{ color: '#f44336' }}
              component="p"
            >
              Select atleast one when clause
            </Typography>
          ) : null}
          <div>
            <Typography variant="body1">For:</Typography>
            <TreeSelector
              allLabel={isPlaces ? 'Any Area' : 'Any Asset'}
              treeNodes={
                this.props.treeAssetsCategory
                  ? this.props.treeAssetsCategory
                  : []
              }
              isPlaces={isPlaces}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(SubscriptionSettingsDialog)
