import React from 'react'
import '../../styles/pages/reports/tableReports.scss'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Toolbar from '../../components/toolbar/Toolbar'
import ReactHelmet from '../../components/ReactHelmet'
import { ERoutes, PageTitle } from '../../components/routes/CheckRoutes'
import cubejsApi from '../../components/reportComponents/cubejsConfig'
import { QueryRenderer } from '@cubejs-client/react'
import RangeDatePicker from '../../components/datePickers/RangeDatePicker'
import moment from 'moment'
import printJS from 'print-js'
import IconButton from '../../components/buttons/IconButton'
import { assetProfile, assetReport } from '../../constants/urlBuilder'
import LoadingOverlay from '../../components/LoadingOverlay'

/**
 * This page is how the frontend manipulated all the data to display correctly, but this reports is very frontend heavy
 * http://localhost:4000/#/build?query={%22measures%22:[%22Runtimes.runTime%22],%22timeDimensions%22:[{%22dimension%22:%22Runtimes.startTime%22,%22granularity%22:%22day%22}],%22order%22:{%22Runtimes.runTime%22:%22desc%22},%22dimensions%22:[%22Runtimes.startTime%22,%22Runtimes.endTime%22,%22Runtimes.deviceEsn%22],%22filters%22:[]}
 */

interface IBackwardsData {
  'AssetInfo.assetCategory': string
  'AssetInfo.assetName': string
  'Runtimes.deviceEsn': string
  'Runtimes.endTime': string
  'Runtimes.runTime': string
  'Runtimes.startTime': string
  'Runtimes.startTime.day': string
  'Assets.legacyId': string
}

interface IDataRow {
  'AssetInfo.assetCategory': string
  'AssetInfo.assetName': string
  'Runtimes.deviceEsn': string
  'Assets.legacyId': string
}

interface IAllDateRow {
  'Runtimes.runTime': number
  'Runtimes.startTime.day': string
}

interface ILoadResponses {
  annotation: any
  data: IBackwardsData[]
  lastRefreshTime: any
  query: any
  refreshKeyValues: any[]
  slowQuery: any
  usedPreAggregations: any
}

interface IResultData {
  backwardCompatibleData: any
  loadResponse: any
  loadResponses: ILoadResponses[]
  options: any
  parseDateMeasures: any
  queryType: any
}

interface IProps {
  width: number
  height: number
  isMobile?: boolean
}

interface IState {
  selectedDate: Array<Date | null>
}

export default class EquipmentDailyRuntime extends React.Component<
  IProps,
  IState
> {
  state: IState = {
    selectedDate: [moment().subtract(7, 'd').toDate(), new Date()],
  }

  /**
   * lays out the table header for daily runtime report. built to be dynamic if we want to display more dates in the future.
   * @returns the head of the table and all the selected dates.
   */
  ReportTableHead(equipmentAmount: number) {
    return (
      <TableRow>
        <TableCell className="tableCell">Category</TableCell>
        <TableCell className="tableCell">
          Equipment ({equipmentAmount})
        </TableCell>
        <TableCell className="tableCell">Monitoring ID</TableCell>
        <TableCell className="tableCell">Total</TableCell>
        {[
          ...Array(
            this.state.selectedDate[0] && this.state.selectedDate[1]
              ? parseInt(
                  moment
                    .duration(
                      moment(this.state.selectedDate[1])
                        .endOf('day')
                        .diff(moment(this.state.selectedDate[0]).startOf('day'))
                    )
                    .asDays()
                    .toString()
                )
              : 7
          ),
        ].map((_, tc) => (
          <TableCell key={tc} className="tableCell">
            {moment(
              this.state.selectedDate[1]
                ? this.state.selectedDate[1]
                : this.state.selectedDate[0]
            )
              .subtract(tc, 'd')
              .format('MMM DD, YYYY')}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  dataRowTotal(resultsData: IBackwardsData[]) {
    let assetIds: IDataRow[] = []
    resultsData.forEach((data) => {
      const newDataRow = {
        'AssetInfo.assetCategory': data['AssetInfo.assetCategory'],
        'AssetInfo.assetName': data['AssetInfo.assetName'],
        'Runtimes.deviceEsn': data['Runtimes.deviceEsn'],
        'Assets.legacyId': data['Assets.legacyId'],
      }
      if (
        !assetIds.find(
          (assetOBJ) =>
            assetOBJ['Runtimes.deviceEsn'] === newDataRow['Runtimes.deviceEsn']
        )
      ) {
        assetIds.push(newDataRow)
      }
    })
    return assetIds
  }

  /**
   * Creates a Row in the body of the Table.
   * @param assetRow Pass the Equipment object to be layed out in this row.
   * @returns returns a completed row including all the dates.
   */
  ReportRuntimeTableRow(assetInfo: IDataRow, resultAllData: IBackwardsData[]) {
    let allDateRow: IAllDateRow[] = []
    resultAllData.forEach((dataResult: IBackwardsData) => {
      if (
        dataResult['Runtimes.deviceEsn'] === assetInfo['Runtimes.deviceEsn']
      ) {
        const value = allDateRow.find(
          (aDateR) =>
            aDateR['Runtimes.startTime.day'] ===
            dataResult['Runtimes.startTime.day']
        )
        // console.log(value)
        /** if date does exist trigger this */
        if (value) {
          const indexResult = allDateRow.indexOf(value)
          allDateRow[indexResult] = {
            'Runtimes.runTime':
              allDateRow[indexResult]['Runtimes.runTime'] +
              dataResult['Runtimes.runTime']
                ? parseFloat(dataResult['Runtimes.runTime'])
                : 0,
            'Runtimes.startTime.day':
              allDateRow[indexResult]['Runtimes.startTime.day'],
          }
        } else {
          allDateRow.push({
            'Runtimes.runTime': dataResult['Runtimes.runTime']
              ? parseFloat(dataResult['Runtimes.runTime'])
              : 0,
            'Runtimes.startTime.day': dataResult['Runtimes.startTime.day'],
          })
        }
      }
    })
    /** Grab total amount of runtime */
    let totalRuntime = 0
    allDateRow.forEach(
      (dr) => (totalRuntime = totalRuntime + dr['Runtimes.runTime'])
    )
    return (
      <TableRow hover={true}>
        <TableCell>{assetInfo['AssetInfo.assetCategory']}</TableCell>
        <TableCell>
          <a href={assetProfile(assetInfo['Assets.legacyId'])}>
            {assetInfo['AssetInfo.assetName']}
          </a>
        </TableCell>
        <TableCell>{assetInfo['Runtimes.deviceEsn']}</TableCell>
        <TableCell>{totalRuntime.toFixed(2)}</TableCell>
        {[
          ...Array(
            this.state.selectedDate[0] && this.state.selectedDate[1]
              ? parseInt(
                  moment
                    .duration(
                      moment(this.state.selectedDate[1]).diff(
                        moment(this.state.selectedDate[0]).startOf('day')
                      )
                    )
                    .asDays()
                    .toString()
                )
              : 7
          ),
        ].map((_, tc) => {
          const cellDate = moment(
            this.state.selectedDate[1]
              ? this.state.selectedDate[1]
              : this.state.selectedDate[0]
          )
            .subtract(tc, 'd')
            .format('YYYY-MM-DD')
          const cellValue = allDateRow.find(
            (adc) => adc['Runtimes.startTime.day'].split('T')[0] === cellDate
          )?.['Runtimes.runTime']
          return (
            <TableCell key={tc} className="tableCell">
              {cellValue ? (
                <a
                  href={assetReport(
                    assetInfo['Assets.legacyId'],
                    'daily_usage'
                  )}
                >
                  {cellValue.toFixed(2)}
                </a>
              ) : (
                '-'
              )}
            </TableCell>
          )
        })}
      </TableRow>
    )
  }

  renderChart = ({ resultSet, error }: { resultSet: any; error: any }) => {
    if (error) {
      return <div>{error.toString()}</div>
    }
    if (!resultSet) {
      return <LoadingOverlay loading />
    }

    const result: IResultData = resultSet
    const resultData: IBackwardsData[] = result.loadResponses[0].data
    const dataRow = this.dataRowTotal(resultData)
    return (
      <Table stickyHeader>
        <TableHead>{this.ReportTableHead(dataRow.length)}</TableHead>
        <TableBody>
          {dataRow.map((DR: IDataRow) =>
            this.ReportRuntimeTableRow(DR, resultData)
          )}
        </TableBody>
      </Table>
    )
  }

  render() {
    return (
      <div>
        <ReactHelmet title={PageTitle(ERoutes.EquipmentDailyRuntime)} />
        <Toolbar
          title={PageTitle(ERoutes.EquipmentDailyRuntime)}
          scrollBackgroundColor
          noPositionFixed
          addPaddingXtraBar="4px 10px"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            <Typography variant="h5">Engine Runtime Per Equipment</Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{ paddingRight: 15 }}>
                Previous{' '}
                {this.state.selectedDate[0] && this.state.selectedDate[1]
                  ? parseInt(
                      moment
                        .duration(
                          moment(this.state.selectedDate[1]).diff(
                            moment(this.state.selectedDate[0]).startOf('day')
                          )
                        )
                        .asDays()
                        .toString()
                    )
                  : 7}{' '}
                days from
              </div>
              <RangeDatePicker
                currentDate={this.state.selectedDate}
                onAccept={(date: Array<Date | null>) => {
                  this.setState({ selectedDate: date })
                }}
                disableFuture
                showToolbar
              />
              <IconButton
                icon="print"
                variant="appbar"
                onClick={() =>
                  printJS({
                    printable: 'reportRuntime',
                    type: 'html',
                    targetStyles: ['*'],
                    honorColor: true,
                    style: '@page { size: Letter landscape; }',
                  })
                }
              />
            </div>
          </div>
        </Toolbar>
        <QueryRenderer
          query={{
            measures: ['Runtimes.runTime'],
            timeDimensions: [
              {
                dimension: 'Runtimes.startTime',
                granularity: 'day',
                dateRange: [
                  moment(this.state.selectedDate[0]).format('YYYY-MM-DD'),
                  moment(this.state.selectedDate[1]).format('YYYY-MM-DD'),
                ],
              },
            ],
            order: {
              'Runtimes.startTime': 'desc',
            },
            dimensions: [
              'Runtimes.deviceEsn',
              'Runtimes.startTime',
              'Runtimes.endTime',
              'AssetInfo.assetName',
              'AssetInfo.assetCategory',
              'Assets.legacyId',
            ],
            filters: [],
          }}
          cubejsApi={cubejsApi('53abb3c6-eabc-4e81-9e37-f53855fae639')}
          resetResultSetOnChange={false}
          render={(props) => {
            return (
              <>
                <TableContainer
                  component={Paper}
                  square
                  style={{
                    maxHeight: this.props.height - 14,
                  }}
                >
                  {this.renderChart({
                    resultSet: props.resultSet,
                    error: props.error,
                  })}
                </TableContainer>
                <div style={{ display: 'none' }}>
                  <div
                    style={{
                      overflow: 'visible',
                    }}
                    id="reportRuntime"
                  >
                    {this.renderChart({
                      resultSet: props.resultSet,
                      error: props.error,
                    })}
                  </div>
                </div>
              </>
            )
          }}
        />
      </div>
    )
  }
}

/** This is the component that needs to be exported as it is used on the array that renders all the reports */
export const EquipmentDailyRuntimeComp = (
  width: number,
  height: number,
  isMobile?: boolean
) => <EquipmentDailyRuntime width={width} height={height} isMobile={isMobile} />
