import React from 'react'
import { colors } from './roviTheme'

const MessageBanner: React.FC<{}> = (props) => (
  <div
    style={{
      textAlign: 'center',
      background: colors.primaryBlue,
      padding: '12px 16px',
      color: 'white',
    }}
  >
    {props.children}
  </div>
)

export default MessageBanner
