import React from 'react'
import Typography from '@mui/material/Typography'
import Toolbar from '../../components/toolbar/Toolbar'
import ReactHelmet from '../../components/ReactHelmet'
import { ERoutes, PageTitle } from '../../components/routes/CheckRoutes'
import { QueryRenderer } from '@cubejs-client/react'
import { Spin } from 'antd'
import 'antd/dist/antd.min.css'
import { Line, Bar } from 'react-chartjs-2'
import cubejsApi from '../../components/reportComponents/cubejsConfig'
import RangeDatePicker from '../../components/datePickers/RangeDatePicker'
import printJS from 'print-js'
import IconButton from '../../components/buttons/IconButton'
import moment from 'moment'
import { COLORS_SERIES } from '../../components/ux/roviTheme'

// http://localhost:4000/#/build?query={%22measures%22:[%22TankMetrics.tanklevelPercentage%22],%22timeDimensions%22:[{%22dimension%22:%22TankMetrics.receivedTime%22,%22granularity%22:%22day%22,%22dateRange%22:%22This%20week%22}],%22order%22:{%22TankMetrics.tanklevelPercentage%22:%22desc%22},%22dimensions%22:[%22TankMetrics.assetId%22],%22filters%22:[],%22limit%22:1000,%22segments%22:[]}

const commonOptions = {
  maintainAspectRatio: false,
}

interface IProps {
  width: number
  height: number
  isMobile?: boolean
}

interface IState {
  currentDate: Date | null
  selectedDate: Array<Date | null>
  isBarChart: boolean
}

export default class FluidUsageOverTime extends React.Component<
  IProps,
  IState
> {
  state: IState = {
    currentDate: new Date(),
    selectedDate: [moment().subtract(7, 'd').toDate(), new Date()],
    isBarChart: true,
  }

  /**
   * this is to allow for felxibility for the future, but essentially its to map through the dates,
   * insuring that the dates dont get messed up when we map into the past.
   */
  maxTotal =
    this.state.selectedDate[0] && this.state.selectedDate[1]
      ? parseInt(
          moment
            .duration(
              moment(this.state.selectedDate[1]).diff(
                moment(this.state.selectedDate[0]).startOf('day')
              )
            )
            .asDays()
            .toString()
        )
      : 0

  renderChart = ({
    resultSet,
    error,
    height,
    width,
    pivotConfig,
  }: {
    resultSet: any
    error: any
    height: number
    width: number
    pivotConfig: any
  }) => {
    if (error) {
      return <div>{error.toString()}</div>
    }

    if (!resultSet) {
      return <Spin />
    }

    const data = {
      labels: resultSet.categories().map((c: { category: any }) => c.category),
      datasets: resultSet
        .series()
        .map((s: { title: any; series: any[] }, index: number) => ({
          label: s.title,
          data: s.series.map((r: { value: any }) => r.value),
          borderColor: COLORS_SERIES[index],
          backgroundColor: COLORS_SERIES[index],
          fill: false,
        })),
    }
    const options = {
      ...commonOptions,
      scales: {
        xAxes: [
          {
            stacked: true,
            type: 'time',
            time: {
              unit: 'day',
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
    }
    console.log(data.datasets)
    return this.state.isBarChart ? (
      <Bar
        data={data}
        options={options}
        height={height}
        width={width}
        legend={{ reverse: true, align: 'start' }}
      />
    ) : (
      <Line
        data={data}
        options={options}
        height={height}
        width={width}
        legend={{ reverse: true, align: 'start' }}
      />
    )
  }

  render() {
    return (
      <div>
        <ReactHelmet title={PageTitle(ERoutes.FluidUsageOverTime)} />
        <Toolbar
          title={PageTitle(ERoutes.FluidUsageOverTime)}
          scrollBackgroundColor
          defaultBackgroundColor
          noPositionFixed
          isMobile={this.props.isMobile}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: this.props.isMobile ? 'column' : undefined,
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h5">Fluid Level Over Time</Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {this.props.isMobile ? null : (
                <div style={{ paddingRight: 15 }}>
                  Showing the previous{' '}
                  {this.state.selectedDate[0] && this.state.selectedDate[1]
                    ? parseInt(
                        moment
                          .duration(
                            moment(this.state.selectedDate[1]).diff(
                              moment(this.state.selectedDate[0]).startOf('day')
                            )
                          )
                          .asDays()
                          .toString()
                      )
                    : 7}{' '}
                  days since
                </div>
              )}
              <RangeDatePicker
                currentDate={this.state.selectedDate}
                onAccept={(date: Array<Date | null>) => {
                  this.setState({ selectedDate: date })
                }}
                disableFuture
                showToolbar
              />
              <IconButton
                icon="print"
                variant="appbar"
                tooltip="Print/PDF Report"
                onClick={() => {
                  printJS({
                    printable: 'reportFluidUsageOverTime',
                    type: 'html',
                    targetStyles: ['*'],
                    honorColor: true,
                    documentTitle: 'Fluid Usage Over Time Report',
                  })
                }}
              />
              <IconButton
                icon={this.state.isBarChart ? 'multilineChart' : 'barChart'}
                variant="appbar"
                tooltip={`View as ${
                  this.state.isBarChart ? 'Line Chart' : 'Bar Chart'
                }`}
                onClick={() =>
                  this.setState({ isBarChart: !this.state.isBarChart })
                }
              />
            </div>
          </div>
        </Toolbar>

        <QueryRenderer
          query={{
            measures: ['TankMetrics.tanklevelPercentage'],
            timeDimensions: [
              {
                dimension: 'TankMetrics.timestamp',
                granularity: 'day',
                dateRange: [
                  moment(this.state.selectedDate[0]).format('YYYY-MM-DD'),
                  this.state.selectedDate[1]
                    ? moment(this.state.selectedDate[1]).format('YYYY-MM-DD')
                    : moment(this.state.selectedDate[0]).format('YYYY-MM-DD'),
                ],
              },
            ],
            order: [
              ['TankMetrics.tanklevelPercentage', 'desc'],
              ['TankMetrics.timestamp', 'asc'],
            ],
            filters: [],
            dimensions: ['TankMetrics.deviceEsn'],
          }}
          cubejsApi={cubejsApi('ca480653-6634-47c4-8ff0-09bb4173696c')}
          resetResultSetOnChange={false}
          render={(props) => (
            <div id="reportFluidUsageOverTime">
              {this.renderChart({
                ...props,
                height: this.props.height,
                width: this.props.width,
                pivotConfig: {
                  x: ['TankMetrics.deviceEsn', 'TankMetrics.timestamp.day'],
                  y: ['measures'],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })}
            </div>
          )}
        />
      </div>
    )
  }
}

/** This is the component that needs to be exported as it is used on the array that renders all the reports */
export const FluidUsageOvertimeComp = (
  width: number,
  height: number,
  isMobile?: boolean
) => <FluidUsageOverTime width={width} height={height} isMobile={isMobile} />
