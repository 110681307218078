import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '../../buttons/Button'
import { ITickets, ITicketTask, ITicket } from '../../../types/service/tickets'
import { LoadingIcon } from '../../LoadingOverlay'
import { Link } from 'react-router-dom'
import moment from 'moment'
import EquipChip from '../taskComponents/EquipChip'
import Chip from '@mui/material/Chip'
import { WithStyles } from '@mui/styles'
import { createStyles, withStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import blue from '@mui/material/colors/blue'
import orange from '@mui/material/colors/orange'
import red from '@mui/material/colors/red'
import ScheduleRatio from '../ScheduleRatio'
import Typography from '@mui/material/Typography'
import { globalThreshold } from '../../../pages/service/TasksPage'
import { assetType } from '../taskComponents/TaskAccordianRow'
import { ERoutes } from '../../routes/CheckRoutes'

const styles = (theme: Theme) =>
  createStyles({
    scheduled: {
      background: blue[500],
    },
    upcoming: {
      background: orange[500],
    },
    overdue: {
      background: red[500],
    },
    taskMessage: {
      marginLeft: 20,
      whiteSpace: 'nowrap',
    },
  })

interface IProps extends WithStyles<typeof styles> {
  isMobile?: boolean
  TicketsLoaded?: ITickets
  TicketsLoading: boolean
  searchBarVal: string
  statusOpen: boolean
  createdSelectedDates: Array<Date | null>
  resolvedSelectedDates: Array<Date | null>
}

interface IState {}

class TicketsTable extends React.Component<IProps, IState> {
  state: IState = {}

  filterCreatedDate(
    tickets: ITicket[],
    createdSelectedDates: Array<Date | null>
  ) {
    return createdSelectedDates[0] && createdSelectedDates[1]
      ? tickets.filter((ticket) => {
          return moment(ticket.checkInDate).isBetween(
            moment(createdSelectedDates[0]).startOf('day'),
            moment(createdSelectedDates[1]).endOf('day')
          )
        })
      : tickets
  }

  filterResolvedDate(
    tickets: ITicket[],
    status: boolean,
    resolvedSelectedDates: Array<Date | null>
  ) {
    return !status
      ? resolvedSelectedDates[0] && resolvedSelectedDates[1]
        ? tickets.filter((ticket) => {
            return moment(ticket.resolvedDate).isBetween(
              moment(resolvedSelectedDates[0]).startOf('day'),
              moment(resolvedSelectedDates[1]).endOf('day')
            )
          })
        : tickets
      : tickets
  }

  filterStatus(tickets: ITicket[], status: boolean) {
    return tickets.filter((ticket) => (ticket.resolvedDate ? !status : status))
  }

  filterTicketsSearchBar(tickets: ITicket[], searchBarVal: string) {
    return tickets.filter(
      (ticket) =>
        ticket.id.toLowerCase().includes(searchBarVal.toLowerCase()) ||
        ticket.issue.toLowerCase().includes(searchBarVal.toLowerCase()) ||
        ticket.asset.name.toLowerCase().includes(searchBarVal.toLowerCase())
    )
  }

  filterTickets(
    tickets: ITicket[],
    searchBarVal: string,
    status: boolean,
    createdSelectedDates: Array<Date | null>,
    resolvedSelectedDates: Array<Date | null>
  ) {
    return this.filterStatus(
      this.filterTicketsSearchBar(
        this.filterCreatedDate(
          this.filterResolvedDate(tickets, status, resolvedSelectedDates),
          createdSelectedDates
        ),
        searchBarVal
      ),
      status
    )
  }

  checkStatus(tasks?: ITicketTask[]) {
    if (tasks) {
      let arr = tasks.map((task) => task.scheduleRatio)
      let max = Math.max.apply(null, arr)
      return max >= 1 ? 'overdue' : 'upcoming'
    }
  }

  render() {
    const classes = this.props.classes
    return (
      <Paper
        style={{
          marginTop: 16,
          width: '100%',
          overflowX: 'auto',
          marginBottom: this.props.isMobile ? 56 : undefined,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Ticket #</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Asset</TableCell>
              <TableCell>Issue</TableCell>
              <TableCell />
              <TableCell />
              <TableCell>
                {this.props.statusOpen ? null : 'Resolved Date'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.TicketsLoaded ? (
              this.props.TicketsLoaded.suggestion.length > 0 ||
              this.props.TicketsLoaded.tickets.length > 0 ? (
                <>
                  {this.props.statusOpen
                    ? this.props.TicketsLoaded.suggestion.map(
                        (suggestion, key) => (
                          <TableRow key={key} hover>
                            <TableCell>
                              <Chip
                                size="small"
                                label={
                                  this.checkStatus(suggestion.asset.tasks) ===
                                  'overdue'
                                    ? 'Overdue'
                                    : 'Upcoming'
                                }
                                color="primary"
                                className={
                                  this.checkStatus(suggestion.asset.tasks) ===
                                  'overdue'
                                    ? classes.overdue
                                    : classes.upcoming
                                }
                              />
                            </TableCell>
                            <TableCell />
                            <TableCell>
                              <EquipChip
                                name={suggestion.asset.name}
                                imageUrl={suggestion.asset.imageUrl}
                                link="/"
                              />
                            </TableCell>
                            <TableCell colSpan={3}>
                              {suggestion.asset.tasks?.map((task) => (
                                <div
                                  key={task.taskId + suggestion.id}
                                  style={{ display: 'flex' }}
                                >
                                  <ScheduleRatio
                                    progress={task.scheduleRatio}
                                    threshold={globalThreshold}
                                    equipId={suggestion.asset.id}
                                  />
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    className={classes.taskMessage}
                                  >
                                    {assetType(
                                      task.scheduleRatio,
                                      task.metric,
                                      task.due,
                                      globalThreshold
                                    )}
                                  </Typography>
                                </div>
                              ))}
                            </TableCell>
                            <TableCell align="right">
                              <Button variant="outlined">Resolve</Button>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    : null}
                  {this.filterTickets(
                    this.props.TicketsLoaded.tickets,
                    this.props.searchBarVal,
                    this.props.statusOpen,
                    this.props.createdSelectedDates,
                    this.props.resolvedSelectedDates
                  ).map((ticket, key) => (
                    <TableRow key={key} hover>
                      <TableCell>
                        <Link to={`${ERoutes.ticket}/${ticket.id}`}>
                          #{ticket.id}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {moment(ticket.checkInDate).format('MMM DD, YYYY')}
                      </TableCell>
                      <TableCell>
                        <EquipChip
                          name={ticket.asset.name}
                          imageUrl={ticket.asset.imageUrl}
                          link="/"
                        />
                      </TableCell>
                      <TableCell colSpan={3}>{ticket.issue}</TableCell>
                      <TableCell align="right">
                        {ticket.resolvedDate ? (
                          moment(ticket.resolvedDate).format('MMM DD, YYYY')
                        ) : (
                          <Button variant="outlined">Resolve</Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={9}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      No Tickets
                    </div>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={9}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <LoadingIcon loading />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

export default withStyles(styles)(TicketsTable)
