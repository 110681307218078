import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { ValueBox, InputBox } from '../ux/Attribute'
import Icon from '../Icon'
import LinearProgressBar from '../metricUX/LinearProgressBar'
import WidthViewport from '../ux/WidthViewport'
import { IEquipmentProfile } from '../../types/equipment/equipmentProfile'
import SignalStrengthIcon from '../ux/SignalStrengthIcon'
import moment from 'moment'
interface IProps {
  equipmentProfile: IEquipmentProfile
  useMetric: boolean
  timezone: string
  isMobile?: boolean
}

class MetricsSection extends React.Component<IProps> {
  render() {
    const { equipmentProfile } = this.props
    const metrics = equipmentProfile.metrics
    /** All the metric Values for the frontend */
    const CabTemp = metrics.find((m) => m.field === 'cab-temperature')?.value
    const SignalStrength = metrics.find(
      (m) => m.field === 'signalStrength'
    )?.value
    const LastUpdate = metrics.find((m) => m.field === 'lastUpdate')?.value
    const current1 = metrics.find((m) => m.field === 'current1')?.value
    const current2 = metrics.find((m) => m.field === 'current2')?.value
    const current3 = metrics.find((m) => m.field === 'current3')?.value
    const ignition = metrics.find((m) => m.field === 'ignition')?.value
    const input1 = metrics.find((m) => m.field === 'input1')?.value
    const input2 = metrics.find((m) => m.field === 'input2')?.value
    const input3 = metrics.find((m) => m.field === 'input3')?.value
    const input4 = metrics.find((m) => m.field === 'input4')?.value
    const inputs = [
      { name: 'ignition', label: 'IGN', value: ignition },
      { name: 'input1', label: '1', value: input1 },
      { name: 'input2', label: '2', value: input2 },
      { name: 'input3', label: '3', value: input3 },
      { name: 'input4', label: '4', value: input4 },
    ]

    const fuel: number | undefined = metrics.find((m) => m.field === 'fuel')
      ?.value as number | undefined
    const greaseLevel: number | undefined = metrics.find(
      (m) => m.field === 'grease-level'
    )?.value as number | undefined
    const engineHour = metrics.find((m) => m.field === 'engineHour')?.value
    const odometer = metrics.find((m) => m.field === 'odometer')?.value
    const battery = metrics.find((m) => m.field === 'battery')?.value
    const assetsInRange: number | undefined = metrics.find(
      (m) => m.field === 'assetsInRange'
    )?.value as number | undefined
    const inMotion: boolean | undefined = metrics.find(
      (m) => m.field === 'in-motion'
    )?.value as boolean | undefined
    const deviceBatteryPercentage: number | undefined = metrics.find(
      (m) => m.field === 'device-battery-level'
    )?.value as number | undefined
    const speed = metrics.find((m) => m.field === 'speed')?.value
    const solarBattery1 = metrics.find(
      (m) => m.field === 'solar-battery1'
    )?.value
    const solarBattery2 = metrics.find(
      (m) => m.field === 'solar-battery2'
    )?.value

    /** This should be broken out into its own file, but works for now until we get actual data */
    const OtherMetrics = [
      {
        metricName: 'Engine Hour Meter',
        metricValue: engineHour,
        endAdornment: 'h',
      },
      {
        metricName: 'Odometer',
        metricValue: odometer,
        endAdornment: this.props.useMetric ? 'km' : 'mi',
      },
      {
        metricName: 'Battery Voltage',
        metricValue: battery,
        endAdornment: 'V',
      },
      {
        metricName: 'Solar Battery Voltage 1',
        metricValue: solarBattery1,
        endAdornment: 'V',
      },
      {
        metricName: 'Solar Battery Voltage 2',
        metricValue: solarBattery2,
        endAdornment: 'V',
      },
      {
        metricName: 'Current 1',
        metricValue: current1,
        endAdornment: 'A',
      },
      {
        metricName: 'Current 2',
        metricValue: current2,
        endAdornment: 'A',
      },
      {
        metricName: 'Current 3',
        metricValue: current3,
        endAdornment: 'A',
      },
      {
        metricName: 'Device Battery Percentage',
        metricValue: deviceBatteryPercentage,
        endAdornment: '%',
      },
      {
        metricName: 'Speed',
        metricValue: speed,
        endAdornment: this.props.useMetric ? 'kph' : 'mph',
      },
      { metricName: 'Assets in range', metricValue: assetsInRange },
      { metricName: 'Motion', metricValue: inMotion ? 'Yes' : 'No' },
    ]

    return (
      <div style={{ display: 'flex', width: '100%', margin: '5px 0' }}>
        {metrics.length > 0 ? (
          <WidthViewport>
            {({ isMobile }) => (
              <Card style={{ width: '100%' }}>
                <CardContent
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="subtitle1">Metrics</Typography>
                  <Typography
                    style={{ fontSize: 18 }}
                    title={`${CabTemp} ${
                      this.props.useMetric ? '˚C' : '˚F'
                    } Cab Temperature`}
                  >
                    {CabTemp
                      ? `${CabTemp} ${this.props.useMetric ? '˚C' : '˚F'}`
                      : null}
                  </Typography>
                </CardContent>

                <CardContent
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingRight: isMobile ? undefined : 20,
                  }}
                >
                  {LastUpdate ? (
                    <ValueBox
                      title={moment(LastUpdate)
                        .format('MMM DD, YYYY, hh:mm:ss A')
                        .toString()}
                      value={moment(LastUpdate)
                        .format('MMM DD, YYYY, hh:mm:ss A')
                        .toString()}
                      width={150}
                    />
                  ) : null}
                  {SignalStrength ? (
                    <div title="SignalStrength">
                      <SignalStrengthIcon strength={SignalStrength as number} />
                    </div>
                  ) : null}
                </CardContent>
                <CardContent
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexDirection: isMobile ? 'column' : 'row',
                  }}
                >
                  <div style={{ minWidth: 150, width: '100%' }}>
                    {/**
                     * Metric Power Inputs.
                     * this includes IGN, Light1, Light2, Light3, Light4.
                     */}
                    <Typography
                      variant="caption"
                      component="div"
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 5,
                          flexWrap: isMobile ? 'wrap' : undefined,
                        }}
                      >
                        {inputs.map((input) => (
                          <InputBox
                            key={input.name}
                            indicatorOn={!!input.value}
                            label={input.label}
                          />
                        ))}
                      </div>
                    </Typography>
                    {/**
                     * Metric Fuel and Grease Bar.
                     */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 3,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        {fuel ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: 3,
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                width: 40,
                                justifyContent: 'center',
                              }}
                            >
                              <Icon icon="fuel" />
                            </div>
                            <LinearProgressBar
                              level={fuel}
                              size="large"
                              title="Fuel Level: "
                              type="fuel"
                            />
                          </div>
                        ) : null}
                        {greaseLevel ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: 3,
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                width: 40,
                                justifyContent: 'center',
                              }}
                            >
                              <Icon icon="grease" />
                            </div>
                            <LinearProgressBar
                              level={greaseLevel}
                              size="large"
                              title="Grease Level: "
                              type="grease"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </CardContent>
                <CardContent
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto auto auto',
                  }}
                >
                  {OtherMetrics.map((met, key) =>
                    met.metricValue ? (
                      <ValueBox
                        key={'otherMetrics ' + key}
                        title={met.metricName}
                        value={
                          met.metricValue +
                          (!!met.endAdornment ? met.endAdornment : '')
                        }
                      />
                    ) : undefined
                  )}
                </CardContent>
              </Card>
            )}
          </WidthViewport>
        ) : null}
      </div>
    )
  }
}

export default MetricsSection
