import React from 'react'
import '../../styles/pages/account/integrationsPage.scss'
import ReactHelmet from '../../components/ReactHelmet'
import Typography from '@mui/material/Typography'
import IconButton from '../../components/buttons/IconButton'
import Icon from '../../components/Icon'
import { colors, darkMode } from '../../components/ux/roviTheme'
import GenieIntegrationDialog from './integrations/GenieIntegrationDialog'
import WexIntegrationDialog from './integrations/WexIntegrationDialog'
import OnStarIntegrationDialog from './integrations/OnStarIntegrationDialog'
import VisionLinkIntegrationDialog from './integrations/VisionLinkIntegrationDialog'
import PointOfRentalIntegrationDialog from './integrations/PointOfRentalIntegrationDialog'
import ShellIntegrationDialog from './integrations/ShellIntegrationDialog'

interface IServiceList {
  name: string
  type: string
  icon: string
  description: string
  enabled: boolean
}

const serviceList: IServiceList[] = [
  {
    name: 'Genie Lift Connect',
    type: 'genie',
    icon: 'https://s3.amazonaws.com/assets.rovitracker.cloud/images/integrationIcons/genie.png',
    description: 'Connect to your Genie Lift Connect account',
    enabled: false,
  },
  {
    name: 'OnStar',
    type: 'onStar',
    icon: 'https://s3.amazonaws.com/assets.rovitracker.cloud/images/integrationIcons/onStar.png',
    description: 'Connect to your OnStar account',
    enabled: false,
  },
  {
    name: 'Shell',
    type: 'comdata',
    icon: 'https://s3.amazonaws.com/assets.rovitracker.cloud/images/integrationIcons/shell.png',
    description: 'Connect to your Shell account',
    enabled: true,
  },
  {
    name: 'VisionLink',
    type: 'visionLink',
    icon: 'https://s3.amazonaws.com/assets.rovitracker.cloud/images/integrationIcons/visionlink.png',
    description: 'Connect to your VisionLink account',
    enabled: false,
  },
  {
    name: 'WEX',
    type: 'wex',
    icon: 'https://s3.amazonaws.com/assets.rovitracker.cloud/images/integrationIcons/wex.svg',
    description: 'Connect to your WEX account',
    enabled: false,
  },
  {
    name: 'Point Of Rental',
    type: 'pointofrental',
    icon: 'https://s3.amazonaws.com/assets.rovitracker.cloud/images/integrationIcons/por.png',
    description: 'Retrieve Point of Rental data from Rovitracker',
    enabled: true,
  },
  // {
  //   name: 'Wynne Systems',
  //   type: 'wynne',
  //   icon: 'https://s3.amazonaws.com/assets.rovitracker.cloud/images/integrationIcons/wynne.png',
  //   description: 'Retrieve Wynne Data from Rovitracker',
  //   enabled: false,
  // },
]

interface IIntegrationsPageState {
  selectedServiceType?: string
}

interface IIntegrationsPageProps {}

class IntegrationsPage extends React.Component<
  IIntegrationsPageProps,
  IIntegrationsPageState
> {
  constructor(props: IIntegrationsPageProps) {
    super(props)
    this.state = {
      selectedServiceType: undefined,
    }

    this.onCloseIntegration = this.onCloseIntegration.bind(this)
  }

  onConfigureIntegration(type: string) {
    this.setState({
      selectedServiceType: type,
    })
  }

  onCloseIntegration() {
    this.setState({
      selectedServiceType: undefined,
    })
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <ReactHelmet title="Integrations" />
        <div className="integrationsTab">
          <Typography variant="h5">Integrations</Typography>
          {serviceList.map((service) => (
            <div
              key={service.type}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '16px 0',
                flexWrap: 'wrap',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    height: 50,
                    width: 50,
                    marginRight: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: darkMode ? colors.borderColor : undefined,
                  }}
                >
                  <img
                    src={service.icon}
                    width={'100%'}
                    height="auto"
                    alt={''}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      marginBottom: 4,
                      display: 'flex',
                      alignItems: 'center',
                      width: 150,
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ fontWeight: 'bold' }}>{service.name}</div>
                    {service.enabled && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          icon="checkAll"
                          style={{
                            height: 20,
                            width: 20,
                            color: colors.onGreen,
                          }}
                        />
                        <div style={{ color: colors.textGrey }}>Enabled</div>
                      </div>
                    )}
                  </div>
                  <div style={{ color: colors.textGrey }}>
                    {service.description}
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', paddingLeft: 70 }}>
                {service.enabled && (
                  <IconButton
                    icon="settings"
                    variant="toolbar"
                    tooltip="Set items"
                  />
                )}
                {service.enabled && service.type !== 'comdata' && (
                  <IconButton
                    icon="delete"
                    variant="toolbar"
                    tooltip="Delete"
                  />
                )}
                <IconButton
                  icon="edit"
                  variant="toolbar"
                  disabled={
                    (service.type === 'comdata' ||
                      service.type === 'genie' ||
                      service.type === 'pointofrental') &&
                    service.enabled
                  }
                  tooltip={service.enabled ? 'Edit credentials' : 'Configure'}
                  onClick={() => this.onConfigureIntegration(service.type)}
                />
              </div>
            </div>
          ))}
        </div>
        <GenieIntegrationDialog
          onClose={this.onCloseIntegration}
          onSave={this.onCloseIntegration}
          logo={serviceList.find((s) => s.type === 'genie')?.icon}
          open={this.state.selectedServiceType === 'genie'}
        />
        <OnStarIntegrationDialog
          onClose={this.onCloseIntegration}
          logo={serviceList.find((s) => s.type === 'onStar')?.icon}
          open={this.state.selectedServiceType === 'onStar'}
        />
        <ShellIntegrationDialog
          onClose={this.onCloseIntegration}
          logo={serviceList.find((s) => s.type === 'comdata')?.icon}
          open={this.state.selectedServiceType === 'comdata'}
        />
        <VisionLinkIntegrationDialog
          onClose={this.onCloseIntegration}
          logo={serviceList.find((s) => s.type === 'visionLink')?.icon}
          open={this.state.selectedServiceType === 'visionLink'}
        />
        <WexIntegrationDialog
          onClose={this.onCloseIntegration}
          onSave={this.onCloseIntegration}
          logo={serviceList.find((s) => s.type === 'wex')?.icon}
          open={this.state.selectedServiceType === 'wex'}
        />
        <PointOfRentalIntegrationDialog
          onClose={this.onCloseIntegration}
          onAuthorize={this.onCloseIntegration}
          open={this.state.selectedServiceType === 'pointofrental'}
          logo={serviceList.find((s) => s.type === 'pointofrental')?.icon}
        />
      </div>
    )
  }
}

export default IntegrationsPage
