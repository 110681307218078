enum PercentageType {}

type Percentage = number & PercentageType

namespace Percentage {
  export const ofPercent = (v: number): Percentage => v as Percentage
  export const ofDecimal = (v: number) => Percentage.ofPercent(v * 100)

  export const toPercent = (v: Percentage) => v as number
  export const toDecimal = (v: Percentage) => Percentage.toPercent(v) / 100

  export enum Unit {
    Percent = '%',
    Decimal = ''
  }

  const conversionLookup: { [key in Unit]: (val: Percentage) => number } = {
    [Unit.Percent]: toPercent,
    [Unit.Decimal]: toDecimal
  }

  export const format = (val: Percentage, unit: Unit = Unit.Percent) =>
    unit === Unit.Percent
      ? `${conversionLookup[unit](val)}${unit}`
      : `${Math.round(conversionLookup[unit](val) * 100) / 100}${unit}`
}

export default Percentage
