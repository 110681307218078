import React from 'react'
import '../../styles/components/accountComponents/SubscriptionPlanCard.scss'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '../buttons/IconButton'
import Typography from '@mui/material/Typography'
import Icon from '../Icon'
import { ERoutes } from '../../components/routes/CheckRoutes'

interface IMarketPlaceItem {
  name: string
  price: string
  description: string
  isAddOn?: boolean
}

const MarketPlaceItem = (props: IMarketPlaceItem) => (
  <div
    style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      margin: '12px 0',
    }}
  >
    <div
      style={{
        fontSize: props.isAddOn ? 12 : 14,
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      {props.name}
    </div>
    <Typography variant="body1" color="primary">
      {props.price}
    </Typography>
    <Typography variant="body2" color="textSecondary" align="center">
      {props.description}
    </Typography>
  </div>
)

const addons = [1]

interface IState {}

interface IProps {}

class SubscriptionPlanCard extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <Card className="subscriptionPlanCard">
        <CardHeader
          action={
            <IconButton
              icon="edit"
              variant="row"
              tooltip="Edit"
              href={ERoutes.marketPlace}
            />
          }
          title="Market Place"
        />
        <CardContent>
          <div style={{ flex: 1, display: 'flex' }}>
            <MarketPlaceItem
              name={`Free Plan`}
              price={`$0/Month`}
              description="A free account for special clients and investors."
            />
            {addons && (
              <>
                <div
                  style={{
                    flex: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="add" />
                </div>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {addons.map((addon, i) => {
                    return (
                      <MarketPlaceItem
                        key={i}
                        name=""
                        price={`$500/Year`}
                        description="Construction Connect add-on"
                      />
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    )
  }
}

export default SubscriptionPlanCard
