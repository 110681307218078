enum LengthType {}
type Length = number & LengthType

namespace Length {
  const CM_PER_M = 100
  const M_PER_KM = 1000
  const CM_PER_IN = 2.54
  const IN_PER_FT = 12
  const FT_PER_MI = 5280

  export const ofZero = (): Length => 0 as Length
  export const ofCentimeters = (v: number): Length => v as Length
  export const ofMeters = (v: number) => Length.ofCentimeters(v * CM_PER_M)
  export const ofKilometers = (v: number) => Length.ofMeters(v * M_PER_KM)
  export const ofInches = (v: number) => Length.ofCentimeters(v * CM_PER_IN)
  export const ofFeet = (v: number) => Length.ofInches(v * IN_PER_FT)
  export const ofUsMiles = (v: number) => Length.ofFeet(v * FT_PER_MI)

  export const toCentimeters = (v: Length) => v as number
  export const toMeters = (v: Length) => Length.toCentimeters(v) / CM_PER_M
  export const toKilometers = (v: Length) => Length.toMeters(v) / M_PER_KM
  export const toInches = (v: Length) => Length.toCentimeters(v) / CM_PER_IN
  export const toFeet = (v: Length) => Length.toInches(v) / IN_PER_FT
  export const toUsMiles = (v: Length) => Length.toFeet(v) / FT_PER_MI

  export enum Unit {
    Centimeters = 'cm',
    Meters = 'm',
    KiloMeters = 'km',
    Inches = 'in',
    Feet = 'ft',
    UsMiles = 'mi',
  }

  const conversionLookupTo: { [key in Unit]: (val: Length) => number } = {
    [Unit.Centimeters]: toCentimeters,
    [Unit.Meters]: toMeters,
    [Unit.KiloMeters]: toKilometers,
    [Unit.Inches]: toInches,
    [Unit.Feet]: toFeet,
    [Unit.UsMiles]: toUsMiles,
  }

  const conversionLookupFrom: { [key in Unit]: (val: number) => Length } = {
    [Unit.Centimeters]: ofCentimeters,
    [Unit.Meters]: ofMeters,
    [Unit.KiloMeters]: ofKilometers,
    [Unit.Inches]: ofInches,
    [Unit.Feet]: ofFeet,
    [Unit.UsMiles]: ofUsMiles,
  }

  export const format = (val: Length, unit: Unit = Unit.Meters) =>
    `${Math.round(conversionLookupTo[unit](val))}${unit}`

  export const convertToUnit = (val: Length, unit: Unit) =>
    conversionLookupTo[unit](val)
  export const convertFromUnit = (val: number, unit: Unit) =>
    conversionLookupFrom[unit](val)
}

export default Length
