import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Slide,
} from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'

interface IIntegrationDialogProps extends WithStyles<typeof styles> {
  open: boolean
  title?: string
  logo?: string
  installNotes?: string
  closeButtonLabel?: string
  saveButtonLabel?: string
  onSave?: () => void
  onClose: () => void
}

interface IContactRoviDialogProps extends WithStyles<typeof styles> {
  open: boolean
  logo?: string
  title?: string
  onClose: () => void
}

const IntegrationDialog = (
  props: React.PropsWithChildren<IIntegrationDialogProps>
) => {
  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={SlideUpTransition}
      transitionDuration={200}
    >
      {(props.title || props.logo) && (
        <DialogTitle className={props.classes.title}>
          <span>{props.title}</span>
          {props.logo && (
            <img src={props.logo} className={props.classes.logo} alt="" />
          )}
        </DialogTitle>
      )}
      <DialogContent>
        {props.installNotes && (
          <div className={props.classes.installNotesSection}>
            <div className={props.classes.subtitle}>Install Notes</div>
            <div className={props.classes.explanationText}>
              {props.installNotes}
            </div>
          </div>
        )}
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          color={!!props.onSave ? 'inherit' : 'primary'}
        >
          {props.closeButtonLabel || 'Cancel'}
        </Button>
        {props.onSave && (
          <Button variant="contained" color="primary" onClick={props.onSave}>
            {props.saveButtonLabel || 'Save'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

const styles = (theme: any) =>
  createStyles({
    title: {
      ...theme.typography.h4,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logo: {
      maxHeight: 65,
      maxWidth: '35%',
    },
    installNotesSection: {
      marginBottom: 30,
    },
    subtitle: theme.typography.h5,
    explanationText: {
      ...theme.typography.body2,
      marginTop: 10,
      marginBottom: 30,
    },
    inputArea: {
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    inputField: {
      maxWidth: '60%',
      minWidth: '30%',
      marginBottom: 5,
    },
    contactBody: {
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    contactInfo: {
      ...theme.typography.body1,
      color: theme.palette.grey[600],
    },
  })

const ContactRoviDialog = withStyles(styles)(
  (props: IContactRoviDialogProps) => {
    return (
      <IntegrationDialog
        open={props.open}
        logo={props.logo}
        title={props.title}
        onClose={props.onClose}
        classes={props.classes}
        closeButtonLabel="Close"
      >
        <div className={props.classes.contactBody}>
          <div className={props.classes.contactInfo}>
            <div>
              Contact RoviTracker for more information about this integration.
            </div>
            <div>425-298-ROVI (425-298-7684)</div>
          </div>
        </div>
      </IntegrationDialog>
    )
  }
)

const SlideUpTransition = React.forwardRef(
  (props: any, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
)
SlideUpTransition.displayName = 'SlideUpTransition'

export { ContactRoviDialog }
export default withStyles(styles)(IntegrationDialog)
