import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import { createStyles, WithStyles, withStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import DialogContentText from '@mui/material/DialogContentText'
import EquipChip from './EquipChip'
import { IAssignAsset, TTaskMetric } from '../../../types/service/tasks'
import LastPerformedField from './LastPerformedField'

const styles = (theme: Theme) =>
  createStyles({
    equipName: {
      marginRight: 40,
    },
    lastPerformedContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    input: {
      flexShrink: 0,
      width: 140,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  onClose: () => void
  open: boolean
  isMobile?: boolean
  name: string
  equipment: IAssignAsset[]
  interval: TTaskMetric
}

interface IState {
  page: number
  equipIds: string[]
}

class AssignTaskToAssetDialog extends React.Component<IProps, IState> {
  state: IState = {
    page: 1,
    equipIds: [],
  }

  handleBack = () => {
    this.setState({
      page: 1,
    })
  }

  handleNext = () => {
    this.setState({
      page: 2,
    })
  }

  handleClose = () => {
    this.props.onClose()
    setTimeout(() => this.setState({ page: 1, equipIds: [] }), 500) // Needed to only switch after the animation of the dialog is finished.
  }

  handleCheckBox(checked: boolean, equip: string, equipIds: string[]) {
    let newEquipArr = equipIds
    let equipIndex = equipIds.indexOf(equip)
    if (checked) {
      newEquipArr.push(equip)
    } else {
      newEquipArr.splice(equipIndex, 1)
    }
    this.setState({
      equipIds: newEquipArr,
    })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        fullScreen={this.props.isMobile}
      >
        <DialogTitle>{this.props.name} Assignments</DialogTitle>
        {this.state.page === 1 && (
          <>
            <DialogContent>
              <List>
                {this.props.equipment.map((equip) => {
                  return (
                    <ListItem key={equip.id} dense button>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          disableRipple
                          color="primary"
                          onChange={(event) =>
                            this.handleCheckBox(
                              event.target.checked,
                              equip.id,
                              this.state.equipIds
                            )
                          }
                        />
                      </ListItemIcon>
                      <EquipChip
                        imageUrl={equip.imageUrl}
                        archived={equip.archived}
                        name={equip.name}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button
                color="primary"
                onClick={this.handleNext}
                disabled={this.state.equipIds.length === 0}
              >
                Next
              </Button>
            </DialogActions>
          </>
        )}

        {this.state.page === 2 && (
          <>
            <DialogContent>
              <DialogContentText>
                When were the following assets last serviced?
              </DialogContentText>
              {this.state.equipIds.map((equip) => (
                <LastPerformedField
                  key={equip}
                  name={
                    this.props.equipment.find((eq) => eq.id === equip)?.name
                  }
                  interval={this.props.interval}
                  current={
                    this.props.equipment.find((eq) => eq.id === equip)?.current
                  }
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.handleBack}>Back</Button>
              <Button color="primary">Submit</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    )
  }
}

export default withStyles(styles)(AssignTaskToAssetDialog)
