import React from 'react'
import '../../styles/ux/ServiceButton.scss'
import { WithStyles } from '@mui/styles'
import { createStyles, withStyles } from '@mui/styles'
import MUISelect from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MUIFormHelperText from '@mui/material/FormHelperText'

const styles = createStyles({
  formControl: {
    margin: '10px 20px',
    minWidth: 250,
  },
})

export interface ISelectValues {
  value: string
  label: string
}

interface IState {}

interface IProps extends WithStyles<typeof styles> {
  label: string
  selectedValue: any
  onChange: (value: any) => void
  id?: string
  values: ISelectValues[]
  disabled?: boolean
  className?: string
  required?: boolean
  formHelperText?: string
  variant?: 'standard' | 'filled' | 'outlined'
}

class Select extends React.Component<IProps, IState> {
  state = {}

  render() {
    const classes = this.props.classes
    return (
      <FormControl
        disabled={this.props.disabled}
        required={this.props.required}
        variant={this.props.variant ? this.props.variant : 'standard'}
        sx={{ m: 1 }}
      >
        <InputLabel>{this.props.label}</InputLabel>
        <MUISelect
          className={
            this.props.className ? this.props.className : classes.formControl
          }
          id={this.props.id}
          value={this.props.selectedValue}
          onChange={(event) => this.props.onChange(event.target.value)}
        >
          {this.props.values.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </MUISelect>
        {this.props.formHelperText ? (
          <MUIFormHelperText>{this.props.formHelperText}</MUIFormHelperText>
        ) : null}
      </FormControl>
    )
  }
}

export default withStyles(styles)(Select)
