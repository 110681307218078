import React from 'react'
import { Typography } from '@mui/material'
import CheckContainer from './CheckContainer'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ICategory } from '../../types/category'
import { getNewEmptyFlag } from '../../constants/flagRules'

interface ITreeAssets {
  id: string
  name: string
}

export interface ITreeAssetsCate {
  category: ICategory
  assets: ITreeAssets[]
}

export interface ITreePlaces {
  place: ITreeAssets
  area: ITreeAssets[]
}

const styles = (theme: Theme) =>
  createStyles({
    child: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(3),
    },
    parentSelected: {
      opacity: 0.54,
    },
  })

export interface ITreeItem {
  label: string
  value: string
}

export interface ITreeLayout extends ITreeItem {
  children?: ITreeItem[]
}

interface ITreeSelectorProps extends WithStyles<typeof styles> {
  treeNodes: ITreeLayout[]
  allLabel: string
  onChanged?: (values: string[]) => void
  values?: string[]
  isPlaces?: boolean
  useInternalState?: boolean
  parentsAreLinkedToChildren?: boolean
}

interface ITreeSelectorState {
  selectedValues: string[]
  useInternalState: boolean
  parentsAreLinkedToChildren: boolean
}

class TreeSelector extends React.Component<
  ITreeSelectorProps,
  ITreeSelectorState
> {
  constructor(props: ITreeSelectorProps) {
    super(props)
    this.state = {
      selectedValues: this.getAllValuesFromPropsTree(true),
      useInternalState: props.useInternalState || false,
      parentsAreLinkedToChildren: props.parentsAreLinkedToChildren || false,
    }

    this.nodeIsSelected = this.nodeIsSelected.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<ITreeSelectorProps>) {
    if (
      !this.state.useInternalState &&
      prevProps.values?.length !== this.props.values?.length
    ) {
      this.setState({ selectedValues: this.getAllValuesFromPropsTree(true) })
    }
  }

  changeParent(parent: ITreeLayout) {
    const { parentsAreLinkedToChildren } = this.state
    const isBeingAdded = !this.nodeIsSelected(parent)
    const updatedSelection = this.state.selectedValues

    if (isBeingAdded) {
      updatedSelection.push(parent.value)

      // Parent was not previously selected. Select it
      // and all of its children.
      if (parentsAreLinkedToChildren) {
        parent.children?.forEach((child) => {
          if (!this.nodeIsSelected(child)) {
            updatedSelection.push(child.value)
          }
        })
      }
    } else {
      updatedSelection.splice(updatedSelection.indexOf(parent.value), 1)

      // Parent had been selected. Remove it and
      // all of its children.
      if (parentsAreLinkedToChildren) {
        parent.children?.forEach((child) => {
          if (this.nodeIsSelected(child)) {
            updatedSelection.splice(updatedSelection.indexOf(child.value), 1)
          }
        })
      }
    }

    this.updateSelection(updatedSelection)
  }

  changeChild(child: ITreeItem, parentVal: string) {
    const { parentsAreLinkedToChildren } = this.state
    const isBeingAdded = !this.nodeIsSelected(child)
    const parentNode = this.getParentNode(parentVal)
    const numChildrenNodesSelected =
      parentNode?.children?.filter(this.nodeIsSelected).length || 0

    const updatedSelection = this.state.selectedValues

    if (isBeingAdded) {
      updatedSelection.push(child.value)

      // If all child values (minus the one being added) are selected
      // then we can select the parent
      if (
        parentsAreLinkedToChildren &&
        parentNode &&
        numChildrenNodesSelected === (parentNode.children?.length || 0) - 1
      ) {
        updatedSelection.push(parentVal)
      }
    } else {
      updatedSelection.splice(updatedSelection.indexOf(child.value), 1)

      // If no child values (minus the one being removed) are selected
      // then we can deselect the parent
      if (
        parentsAreLinkedToChildren &&
        parentNode &&
        numChildrenNodesSelected === 1
      ) {
        updatedSelection.splice(updatedSelection.indexOf(parentVal), 1)
      }
    }

    this.updateSelection(updatedSelection)
  }

  changeAll(val: boolean) {
    let newSelectedValues: string[] = []
    if (val) {
      newSelectedValues = this.getAllValuesFromPropsTree(false)
    }
    this.updateSelection(newSelectedValues)
  }

  updateSelection(newSelection: string[]) {
    if (this.state.useInternalState) {
      this.setState({ selectedValues: newSelection })
    } else if (this.props.onChanged) {
      this.props.onChanged(newSelection)
    }
  }

  getParentNode(parentVal: string) {
    return this.props.treeNodes.find((parent) => parent.value === parentVal)
  }

  nodeIsSelected(val: string | ITreeItem) {
    if (typeof val === 'string') {
      return this.state.selectedValues.includes(val)
    }
    return this.state.selectedValues.includes(val.value)
  }

  getAllValuesFromPropsTree(filterByPropAssets: boolean) {
    return this.props.treeNodes
      .flatMap((node) => {
        return [
          ...(node.children?.map((childNode) => childNode.value) || []),
          node.value,
        ]
      })
      .filter((node) =>
        filterByPropAssets ? this.props.values?.includes(node) : true
      )
  }

  render() {
    const classes = this.props.classes
    const totalAssetsCount = this.getAllValuesFromPropsTree(false).length
    const selectedAssetsCount = this.state.selectedValues.length
    const { parentsAreLinkedToChildren } = this.state

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #ccc',
            padding: 10,
            overflow: 'auto',
            height: '100%',
            minHeight: 350,
            maxHeight: 400,
            minWidth: 300,
            width: '100%',
          }}
        >
          <CheckContainer
            label={this.props.allLabel}
            indeterminate={
              selectedAssetsCount < totalAssetsCount &&
              selectedAssetsCount !== 0
            }
            checked={selectedAssetsCount === totalAssetsCount}
            onChange={(bool) => this.changeAll(bool)}
          />
          <div className={classes.child}>
            {this.props.treeNodes.map((parent) => {
              const parentHasNoChildren =
                !parent.children || parent.children.length === 0
              const parentHasSelectedChild = !!parent.children?.some(
                this.nodeIsSelected
              )
              const parentHasSelectedAllChildren = !!parent.children?.every(
                this.nodeIsSelected
              )

              return (
                <div key={parent.value + 'parent'}>
                  <CheckContainer
                    label={parent.label}
                    indeterminate={
                      parentsAreLinkedToChildren &&
                      parentHasSelectedChild &&
                      !parentHasSelectedAllChildren
                    }
                    checked={
                      this.nodeIsSelected(parent.value) &&
                      (!parentsAreLinkedToChildren ||
                        (parentsAreLinkedToChildren &&
                          parentHasSelectedAllChildren) ||
                        parentHasNoChildren)
                    }
                    onChange={() => this.changeParent(parent)}
                    className={
                      selectedAssetsCount === totalAssetsCount
                        ? this.props.classes.parentSelected
                        : undefined
                    }
                  />
                  <div className={classes.child}>
                    {parent.children?.map((child) => (
                      <CheckContainer
                        key={child.value + 'child'}
                        label={child.label}
                        checked={this.nodeIsSelected(child.value)}
                        onChange={() => this.changeChild(child, parent.value)}
                        className={
                          (parentsAreLinkedToChildren &&
                            this.nodeIsSelected(parent.value)) ||
                          selectedAssetsCount === totalAssetsCount
                            ? this.props.classes.parentSelected
                            : undefined
                        }
                      />
                    )) || []}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {selectedAssetsCount === 0 ? (
          <Typography
            variant="body1"
            style={{ color: '#f44336' }}
            component="p"
          >
            No {this.props.isPlaces ? 'places' : 'assets'} selected
          </Typography>
        ) : null}
      </>
    )
  }
}

export default withStyles(styles)(TreeSelector)
