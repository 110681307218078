/**
 * This function does not require any input as it will grab the current url it is under.
 * In order to use properly, it will only create an object of the values it can see, if nothing does exist
 * it will return undefined. This way was alot less verbose, and straight forward compared to using a HashStateManager.
 * @description The main use for this function is grab specific values that set in the url and parse them out.
 * @return Although its important to know that if a value has the same key as the another key it will replace
 * the previous key so you will always end up with a string or undefined in return.
 * @example const URL = "URL#tab=thing&foo=bar&tab=people"
 * getHash()
 * return { tab: "people", foo: "bar" }
 */
export function getHash(): IGetHash {
  if (window.location.hash) {
    const hash = window.location.hash.split('#')[1]
    let obj = {} as any
    // console.log(hash)
    hash.split('&').forEach((str) => {
      const arr = str.split('=')
      obj[arr[0]] = arr[1]
    })
    return obj
  } else return undefined
}

export function clearHash() {
  return (window.location.hash = '')
}

/**
 * Will return the type of the getHash, since this will always be different.
 */
export type IGetHash = typeof getHash | undefined
