import React from 'react'
import TextField from './TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '../buttons/IconButton'
import { makeStyles } from '@mui/styles'

interface IProps {
  id?: string
  onSearch?: () => void
  value?: string
  onChange: (val: string) => void
  className?: string
  variant?: 'filled' | 'outlined' | 'standard'
}

/**
 * This is the Base Search Bar component that has no styles applied to it
 * @param props
 */
const SearchBar: React.FunctionComponent<IProps> = (props) => (
  <TextField
    id={props.id}
    placeholder="Search"
    onChange={(ev) => props.onChange(ev.target.value)}
    value={props.value}
    endAdornment={
      <InputAdornment position="end">
        {props.value ? (
          <IconButton
            icon="close"
            variant="row"
            onClick={() => props.onChange('')}
          />
        ) : (
          <IconButton icon="search" variant="row" disabled />
        )}
      </InputAdornment>
    }
    className={props.className}
    variant={props.variant ? props.variant : 'standard'}
  />
)

export default SearchBar

const useStyles = makeStyles({
  search: {
    padding: 2,
    boxSizing: 'border-box',
  },
})

interface IPaddedSearchBar {
  value?: string
  onChange: (val: string) => void
}

/**
 * Adds Padding Around the Search Bar
 */
export const PaddedSearchBar: React.FunctionComponent<IPaddedSearchBar> = (
  props
) => {
  const classes = useStyles()

  return (
    <SearchBar
      value={props.value}
      onChange={props.onChange}
      className={classes.search}
    />
  )
}
