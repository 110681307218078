import React from 'react'
import { ContactRoviDialog } from './IntegrationDialog'

interface IShellIntegrationDialogProps {
  open: boolean
  logo?: string
  onClose: () => void
}

const ShellIntegrationDialog = ({
  open,
  logo,
  onClose,
}: IShellIntegrationDialogProps) => {
  return (
    <ContactRoviDialog
      open={open}
      logo={logo}
      onClose={onClose}
      title="Shell Integration"
    />
  )
}

export default ShellIntegrationDialog
