import React from 'react'
import CategoryAvatar from './CategoryAvatar'
import EntityChip from './EntityChip'
import { ERoutes } from '../routes/CheckRoutes'
import { TCategoryIcons } from '../../types/category'

interface IProps {
  name: string
  color: string
  icon: TCategoryIcons
  style?: React.CSSProperties
  disabled?: boolean
}

const CategoryChip = (props: IProps) => (
  <EntityChip
    name={props.name}
    badge={<CategoryAvatar color={props.color} icon={props.icon} size={20} />}
    style={props.style}
    href={ERoutes.categories}
  />
)

export default CategoryChip
