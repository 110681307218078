import React from 'react'
import WidthViewport from '../ux/WidthViewport'
import PeopleIcon from '../../svg-icons/PeopleIcon'
import Typography from '@mui/material/Typography'

export default class PeopleTab extends React.Component {
  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <>
            <div
              style={{
                position: 'absolute',
                top: 256,
                left: isMobile ? 0 : 80,
                right: 0,
                opacity: 0.7,
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant={isMobile ? 'h4' : 'h2'}>
                Lone Worker Space
              </Typography>
              <Typography variant={isMobile ? 'h4' : 'h2'}>
                Coming Soon
              </Typography>
            </div>
            <PeopleIcon
              style={{
                width: 256,
                height: 256,
                position: 'absolute',
                left: isMobile ? 0 : 80,
                right: 0,
                margin: '0 auto',
                color: '#ccc',
              }}
            />
          </>
        )}
      </WidthViewport>
    )
  }
}
