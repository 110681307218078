import qs from 'qs'

export enum EMaptracFocus {
  asset = 'asset',
  site = 'site',
  geofence = 'geofence',
}

export type IMaptracFocus =
  | EMaptracFocus.asset
  | EMaptracFocus.site
  | EMaptracFocus.geofence

export interface IMaptracHash {
  focus?: IMaptracFocus
  id?: string
  tasks?: string
  displayTags?: boolean | string
  categories?: string
}

export const MaptracHashFromString = (params: string): IMaptracHash => {
  return qs.parse(params) as unknown as IMaptracHash
}

export const MaptracHashToString = (params: IMaptracHash) => {
  return qs.stringify(params)
}

/**
 * Maptrac FIlter Hash Information
 */

export enum EMaptracFilterMaintenance {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export type IMaptracFilterMaintenance =
  | EMaptracFilterMaintenance.High
  | EMaptracFilterMaintenance.Medium
  | EMaptracFilterMaintenance.Low

/**
 * Different types of filters there can be
 */
export interface IMaptracFilterHash {
  displayTags?: boolean
  maintenance?: IMaptracFilterMaintenance
  category?: string
}

export const MaptracFilterHashFromString = (
  params: string
): IMaptracFilterHash => {
  return qs.parse(params) as unknown as IMaptracFilterHash
}

export const MaptracFilterHashToString = (params: IMaptracFilterHash) => {
  return qs.stringify(params)
}
