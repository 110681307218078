import { IAgreement } from '../agreements'

export enum EFeatureAccess {
  dashboard = 'dashboard',
  yard = 'yard',
  clients = 'clients',
  maptrac = 'maptrac',
  service = 'service',
  reports = 'reports',
}

export type TFeatureAccess = keyof typeof EFeatureAccess

export const allFeatures: TFeatureAccess[] = Object.values(EFeatureAccess)

export enum ERole {
  admin = 'admin',
  branch = 'branch',
  worker = 'worker',
  // operator = 'operator',
}

export type TRole = keyof typeof ERole

/**
 * An Array of all the roles possible
 * operator is left as
 */
export const allRoles: TRole[] = Object.values(ERole)

/**
 * this value is here to assign numbers to each roles
 */
export const orderRoles: Record<TRole, number> = {
  admin: 4,
  branch: 2,
  worker: 1,
  // operator: 0,
}

export const label: Record<TRole, string> = {
  admin: 'Administrator',
  branch: 'Branch Manager',
  worker: 'Employee',
  // operator: 'Operator',
}

export interface IUser {
  id: string
  firstName: string
  lastName: string
  role: TRole
}

export interface IFullUser extends IUser {
  branchId: string
  email: string
  // viewUrl: string // this was used on the legacy version to controll the direct where users click, via backend
  assignments?: string[] // From what i can see the assignments variable is what assets the user is assigned too
  viewOnly?: boolean
  features?: TFeatureAccess[]
}

export interface IUserInfo extends IUser {
  branchName: string
  email: string
  visibleFeatures: TFeatureAccess[]
  viewOnly: boolean
  assignedAssets: IUserAssignedAsset[]
}

export interface IUserAssignedAsset {
  id: string
  name: string
  Make: string
  Model: string
  agreement?: IAgreement
}
