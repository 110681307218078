import React from 'react'
import './FilterMaptrac.scss'
import { Chip } from '@mui/material'

interface IProps {
  chipName?: React.ReactNode
  onClick?: () => void
  isMobile: boolean
}

interface IState {}

export default class FilterChipMaptrac extends React.Component<IProps> {
  render() {
    return (
      <Chip
        label={this.props.chipName}
        onDelete={this.props.onClick}
        style={{
          height: this.props.isMobile ? 33 : 26,
          margin: this.props.isMobile ? '1px 15px 1px 15px' : 1,
        }}
      />
    )
  }
}
