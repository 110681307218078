import { IAssetList, IAsset } from '../../../types/maptrac/Assets'
import { ITag } from '../../../types/maptrac/Tags'
import { IMaintenance } from '../filters/FilterMaptrac'
import { getType } from '../../ux/ServicePopover'
import { ICategory } from '../../../types/category'

export function AssetsSorter(assetsList: IAssetList[]) {
  let assetVal: IAsset[] = []
  assetsList.forEach((assets) => {
    assets.equipment.forEach((asset) => {
      asset.category = assets.category
      assetVal.push(asset)
    })
  })
  return assetVal
}

export function TagsSorter(assetsList: IAssetList[]) {
  let tagVal: Array<ITag[]> = []
  assetsList.forEach((assets) => {
    assets.tags.forEach((taglist) => {
      taglist.forEach((tag) => {
        tag.category = assets.category
      })
      tagVal.push(taglist)
    })
  })
  return tagVal
}

export function MaintenanceSorter(
  assetsList: IAssetList[],
  maintenace: IMaintenance
) {
  let maintenanceTotal = maintenace
  assetsList.forEach((assetList) => {
    assetList.equipment.forEach((equipment) => {
      equipment.tasks.forEach((task) => {
        switch (getType(task.scheduleRatio)) {
          case 'red':
            maintenanceTotal.red.value++
            break
          case 'orange':
            maintenanceTotal.orange.value++
            break
          case 'blue':
            maintenanceTotal.blue.value++
            break
        }
      })
    })
  })
  return maintenanceTotal
}

export function AssetCategorySorter(assetsList: IAssetList[]) {
  let allCategories: ICategory[] = []
  assetsList.forEach((assetList) => allCategories.push(assetList.category))
  return allCategories
}
