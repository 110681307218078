import React from 'react'
import '../../styles/components/Appbar.scss'
import WidthViewport from '../ux/WidthViewport'
import DesktopRoviAppbar from './DesktopRoviAppbar'
import MobileRoviAppbar from './MobileRoviAppbar'
import { ILinks } from '../../types/appbar'
import {
  ERoutes,
  PageTitle,
  disabledMobileBarPages,
} from '../routes/CheckRoutes'
import HashStateManager from '../../rx-js/HashStateManager'
import { HashFromString, HashToString } from '../../constants/hashControllers'

interface IProps {
  userLoggedIn?: boolean
  title?: string
  appType?: boolean // this is to switch the app location link
}

class RoviAppbar extends React.Component<IProps> {
  state = {
    hash: '',
  }

  links: Array<ILinks> = [
    {
      name: 'DASHBOARD',
      link: ERoutes.dashboard,
      tab: 0,
    },
    // { name: 'YARD', link: ERoutes.yard, tab: 1 },
    // { name: 'CLIENT', link: ERoutes.client, tab: 2 },
    {
      name: 'ASSETS',
      link: ERoutes.inventory,
      tab: 1,
    },
    {
      name: 'MAPTRAC',
      link: ERoutes.maptrac,
      tab: 2,
    },
    {
      name: 'SERVICE',
      link: ERoutes.service,
      tab: 3,
    },
    {
      name: 'REPORTS',
      link: ERoutes.fleetReport,
      tab: 4,
    },
  ]

  componentDidMount() {
    window.addEventListener('popstate', () => {
      let hash = new HashStateManager(HashFromString, HashToString)
      this.setState({ hash: hash.value.tab })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', () => this.setState({ hash: '' }))
  }

  render() {
    // console.log(
    //   disabledMobileBarPages.includes(PageTitle(undefined, this.state.hash))
    // )
    // console.log(PageTitle(undefined, this.state.hash))
    return (
      <WidthViewport>
        {({ isMobile }) =>
          isMobile ? (
            disabledMobileBarPages.includes(
              PageTitle(undefined, this.state.hash)
            ) ? null : (
              <MobileRoviAppbar title={PageTitle(undefined, this.state.hash)} />
            )
          ) : (
            <DesktopRoviAppbar
              links={this.links}
              userLoggedIn={this.props.userLoggedIn}
              title={this.props.title}
            />
          )
        }
      </WidthViewport>
    )
  }
}

export default RoviAppbar
