import React from 'react'
import Image from './Image'
import CategoryIcon from './CategoryIcon'
import { colors } from './roviTheme'
import Icon from '../Icon'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import { TCategoryIcons } from '../../types/category'
import WidthViewport from './WidthViewport'

const stylesOld: Record<string, React.CSSProperties> = {
  icon: {
    // This changes the color of inside the icon it self - Default is white or #fff
    fill: 'white',
  },
}

const styles = createStyles({
  container: {
    backgroundColor: colors.imageBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
})

interface IProps extends WithStyles<typeof styles> {
  imageUrl?: string
  default: React.FunctionComponent<{ style: React.CSSProperties }>
  height: number
  width: number
  containerStyle?: React.CSSProperties
  isMobile?: boolean
}

const ImageWithDefault = withStyles(styles)((props: IProps) => {
  const iconSize = Math.min(props.height, props.width)
  return (
    <div
      className={props.classes.container}
      style={{
        ...props.containerStyle,
        height: props.height,
        width: props.isMobile ? undefined : props.width,
      }}
    >
      {props.imageUrl ? (
        <Image
          imageUrl={props.imageUrl}
          style={{ height: props.height, width: props.width }}
        />
      ) : (
        props.default({
          style: { height: iconSize, width: iconSize, ...stylesOld.icon },
        })
      )}
    </div>
  )
})

interface IEquipProps {
  imageUrl?: string
  categoryIcon?: TCategoryIcons
  height: number
  width: number
  containerStyle?: React.CSSProperties
}

export const ImageWithDefaultEquip: React.FunctionComponent<IEquipProps> = (
  props
) => (
  <WidthViewport>
    {({ isMobile }) => (
      <ImageWithDefault
        imageUrl={props.imageUrl}
        height={props.height}
        width={props.width}
        isMobile={isMobile}
        default={(categProps) =>
          props.categoryIcon ? (
            <CategoryIcon {...categProps} icon={props.categoryIcon} />
          ) : null
        }
        containerStyle={props.containerStyle}
      />
    )}
  </WidthViewport>
)

interface IPlaceProps {
  imageUrl?: string
  height: number
  width: number
  containerStyle?: React.CSSProperties
}

export const ImageWithDefaultPlace: React.FunctionComponent<IPlaceProps> = (
  props
) => (
  <ImageWithDefault
    imageUrl={props.imageUrl}
    height={props.height}
    width={props.width}
    default={(iconProps) => <Icon icon="place" {...iconProps} />}
    containerStyle={props.containerStyle}
  />
)
