import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Toolbar from '../../components/toolbar/Toolbar'
import ReactHelmet from '../../components/ReactHelmet'
import { ERoutes, PageTitle } from '../../components/routes/CheckRoutes'
import { Chart, Axis, Tooltip, Geom } from 'bizcharts'
import { QueryRenderer } from '@cubejs-client/react'
import { Spin } from 'antd'
import printJS from 'print-js'
import IconButton from '../../components/buttons/IconButton'
import moment from 'moment'
import cubejsApi from '../../components/reportComponents/cubejsConfig'

const stackedChartData = (resultSet: any) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }: { xValues: any; yValuesArray: any }) =>
      yValuesArray.map(([yValues, m]: [any, any]) => {
        return {
          x:
            xValues[0] +
            ', ' +
            moment(xValues[1]).format('LLL') +
            ' - ' +
            moment(xValues[2]).format('LLL'),
          color: resultSet.axisValuesString(yValues, ', '),
          measure: m && Number.parseFloat(m),
        }
      })
    )
    .reduce((a: any, b: any) => a.concat(b), [])
  return data
}

interface IProps {
  width: number
  height: number
  isMobile?: boolean
}

interface IState {}

export default class DeviceDisconnect extends React.Component<IProps, IState> {
  state: IState = {}

  renderChart = ({
    resultSet,
    error,
    pivotConfig,
  }: {
    resultSet: any
    error: any
    pivotConfig: any
  }) => {
    if (error) {
      return <div>{error.toString()}</div>
    }

    if (!resultSet) {
      return <Spin />
    }

    return (
      <Chart
        scale={{
          x: {
            tickCount: 8,
          },
        }}
        height={this.props.height}
        width={this.props.width}
        data={stackedChartData(resultSet)}
        forceFit
      >
        <Axis name="x" />
        <Axis name="measure" />
        <Tooltip
          crosshairs={{
            type: 'y',
          }}
        />
        <Geom type="line" position="x*measure" size={2} color="color" />
      </Chart>
    )
  }

  ChartRenderer = () => {
    return (
      <QueryRenderer
        query={{
          order: {},
          measures: ['Runtimes.runTime'],
          timeDimensions: [
            {
              dimension: 'Runtimes.startTime',
              dateRange: 'This week',
            },
          ],
          filters: [],
          dimensions: [
            'Runtimes.deviceEsn',
            'Runtimes.startTime',
            'Runtimes.endTime',
          ],
        }}
        cubejsApi={cubejsApi('473e171c-511d-40fb-a1bd-8a0251572cf6')}
        resetResultSetOnChange={false}
        render={(props) =>
          this.renderChart({
            ...props,
            pivotConfig: {
              x: [],
              y: [],
              fillMissingDates: true,
              joinDateRange: false,
            },
          })
        }
      />
    )
  }

  render() {
    return (
      <div>
        <ReactHelmet title={PageTitle(ERoutes.DeviceDisconnect)} />
        <Toolbar
          title={PageTitle(ERoutes.DeviceDisconnect)}
          scrollBackgroundColor
          defaultBackgroundColor
          noPositionFixed
        >
          <div>
            <Typography variant="h5">Events Disconnect</Typography>
          </div>
          <IconButton
            icon="print"
            variant="appbar"
            onClick={() =>
              printJS({
                printable: 'reportRuntimeLine',
                type: 'html',
                targetStyles: ['*'],
                honorColor: true,
                // style: '@page { size: Letter portrait; }',
              })
            }
          />
        </Toolbar>
        <Paper square>
          <div id="reportRuntimeLine">
            <div>Report</div>
            {this.ChartRenderer()}
          </div>
        </Paper>
      </div>
    )
  }
}

/** This is the component that needs to be exported as it is used on the array that renders all the reports */
export const DeviceDisconnectComp = (
  width: number,
  height: number,
  isMobile?: boolean
) => <DeviceDisconnect width={width} height={height} isMobile={isMobile} />
