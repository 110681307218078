import React from 'react'
import { OverlayView } from '@react-google-maps/api'
import MaintenanceIcon from '../../svg-icons/MaintenanceIcon'
import EllipsisText from '../ux/EllipsisText'
import { darken } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import { Option } from 'funfix-core'
import lightGreen from '@mui/material/colors/lightGreen'
import { ILatLng } from 'src/types/generated'
import '../../styles/components/maptrac/marker.scss'

export enum EMarkerShape {
  Arrow = 'M -13.9,9.95 C -12,-5 -12,-8 -0.02,-20 12,-8 12,-5 13.8,9.94 l -5.26,4.16 -8.56,-3.9 -8.31,3.9 z',
  Teardrop = 'M13,36C5.4,28.4,0,20.2,0,13S5.8,0,13,0s13,5.8,13,13S20.6,28.4,13,36z',
  Triangle = 'M12,1.49a.83.83,0,0,0-.67.41L.11,21.23a.83.83,0,0,0,.72,1.24l22.34,0a.83.83,0,0,0,.72-1.24L12.75,1.9A.83.83,0,0,0,12,1.49Z',
  Circle = 'M0,10a10,10 0 1,0 20,0a10,10 0 1,0 -20,0',
}
export enum EMarkerCenterPosition {
  Center,
  BottomCenter,
}

const badgeSize = 14

const styles = createStyles({
  titleBar: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 10,
    transform: 'translateY(-100%)',
  },
  titleBarItem: {
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 2,
    maxWidth: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: badgeSize,
    width: badgeSize,
    borderRadius: '50%',
    position: 'absolute',
    top: -2,
    right: 4,
    transform: 'translateX(100%)',
  },
})

const calcOffset = (
  width: number,
  height: number,
  centerPosition: EMarkerCenterPosition | undefined
): { x: number; y: number } => {
  if (centerPosition === EMarkerCenterPosition.Center) {
    return { x: -(width / 2), y: -(height / 2) }
  } else if (centerPosition === EMarkerCenterPosition.BottomCenter) {
    return { x: -(width / 2), y: -height }
  } else return { x: 0, y: 0 }
}

const Speed = (props: { speed: number; useMetric: boolean }) => {
  const speedNum = Math.round(props.speed)
  const unit = props.useMetric ? 'km/h' : 'mph'
  return (
    <>
      {speedNum} <span style={{ fontSize: 9, marginLeft: 2 }}>{unit}</span>
    </>
  )
}

interface ITitleBarProps extends WithStyles<typeof styles> {
  title: string
  speed?: number
  ign?: boolean
  background: string
  borderColor: string
  textColor: string
  tasks?: {
    equipId: string
    color: string
    onMouseEnter: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseLeave: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  }
}

const borderWidth = 1
// Shouldn't show speed if not moving
const TitleBar = withStyles(styles)((props: ITitleBarProps) => {
  const badge = Option.of(props.tasks)
    .map(({ color, onMouseEnter, onMouseLeave, equipId }) => (
      <a href="/" key={equipId}>
        <div
          className={props.classes.badge}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={{
            backgroundColor: color,
          }}
        >
          <MaintenanceIcon
            style={{
              height: badgeSize - 2,
              width: badgeSize - 2,
              color: 'white',
            }}
          />
        </div>
      </a>
    ))
    .orUndefined()
  return (
    <div className={props.classes.titleBar} style={{ color: props.textColor }}>
      {props.speed && props.speed !== 0 && (
        <div
          className={props.classes.titleBarItem}
          style={{
            background: props.background,
            border: `${borderWidth}px solid ${props.borderColor}`,
            transform: 'translateY(1px)',
            paddingRight: badge ? 6 : 2,
          }}
        >
          <Speed speed={props.speed} useMetric={false} />
          {badge}
        </div>
      )}
      <div
        className={props.classes.titleBarItem}
        style={{
          background: props.background,
          border: `${borderWidth}px solid ${props.borderColor}`,
          paddingRight: badge && props.speed === undefined ? 6 : 2,
        }}
      >
        <EllipsisText text={props.title} style={{ width: '100%' }} />
        {props.speed === undefined && badge}
      </div>
    </div>
  )
})

interface IMarkerShapeProps {
  shape: EMarkerShape
  scale: number
  markerColor: string
  iconPath?: string
  iconColor?: string
  borderColor: string
  heading?: number
}

const MarkerShape: React.SFC<IMarkerShapeProps> = (props) => {
  const { height, width, iconScale } = dimensionsLookup[props.shape]
  const iconTranslate = (width - width * iconScale) / 2
  switch (props.shape) {
    case EMarkerShape.Arrow: {
      return (
        <svg
          height="100%"
          width="100%"
          viewBox={`0 0 ${width} ${height}`}
          overflow="visible"
        >
          <path
            d={EMarkerShape.Arrow}
            vectorEffect="non-scaling-stroke"
            fill={props.markerColor}
            stroke={props.borderColor}
            strokeWidth={borderWidth}
            style={{
              transition: 'transform .8s',
              transform: `translate(50%,17px) rotate(${
                props.heading ? props.heading : 0
              }deg)`,
            }}
          />
          <circle
            cx="50%"
            cy="50%"
            r="37%"
            stroke={props.borderColor}
            strokeWidth={borderWidth}
            fill="white"
          />
          <path
            d={props.iconPath}
            fill={props.markerColor}
            transform={`translate(${iconTranslate + 1.5},${
              iconTranslate + 4
            }) scale(${iconScale})`}
          />
        </svg>
      )
    }
    case EMarkerShape.Circle: {
      return (
        <svg
          height="100%"
          width="100%"
          viewBox={`0 0 ${width} ${height}`}
          overflow="visible"
        >
          <path
            d={EMarkerShape.Circle}
            vectorEffect="non-scaling-stroke"
            fill={props.markerColor}
            stroke={props.borderColor}
            strokeWidth={borderWidth}
          />
          <circle
            cx="50%"
            cy="50%"
            r="37%"
            stroke={props.borderColor}
            strokeWidth={borderWidth}
            fill="white"
          />
          <path
            d={props.iconPath}
            fill={props.markerColor}
            transform={`translate(${iconTranslate - 1},${
              iconTranslate - 1
            }) scale(${iconScale})`}
          />
        </svg>
      )
    }
    case EMarkerShape.Teardrop: {
      return (
        <svg
          height="100%"
          width="100%"
          viewBox={`0 0 ${width} ${height}`}
          overflow="visible"
        >
          <path
            d={EMarkerShape.Teardrop}
            vectorEffect="non-scaling-stroke"
            fill={props.markerColor}
            stroke={props.borderColor}
            strokeWidth={borderWidth}
          />
          <circle
            fill="white"
            cx={width / 2}
            cy={width / 2}
            r={(width * 0.75) / 2}
            stroke={props.borderColor}
            strokeWidth={borderWidth}
          />
          <path
            d={props.iconPath}
            fill={props.markerColor}
            transform={`translate(${
              iconTranslate + 0.5
            },${iconTranslate}) scale(${iconScale})`}
          />
        </svg>
      )
    }
    case EMarkerShape.Triangle: {
      return (
        <svg
          height="100%"
          width="100%"
          viewBox={`0 0 ${width} ${height}`}
          overflow="visible"
        >
          <path
            d={EMarkerShape.Triangle}
            vectorEffect="non-scaling-stroke"
            fill={props.markerColor}
            stroke={props.borderColor}
            strokeWidth={borderWidth}
          />
          <path
            d={props.iconPath}
            fill={props.iconColor}
            transform={`translate(${iconTranslate},${iconTranslate}) scale(${iconScale})`}
          />
        </svg>
      )
    }
  }
}

const dimensionsLookup = {
  [EMarkerShape.Arrow]: {
    height: 34.1,
    width: 27.7,
    iconScale: 0.75,
  },
  [EMarkerShape.Circle]: {
    height: 20,
    width: 20,
    iconScale: 0.5,
  },
  [EMarkerShape.Teardrop]: {
    height: 36,
    width: 26,
    iconScale: 0.65,
  },
  [EMarkerShape.Triangle]: {
    height: 21,
    width: 24,
    iconScale: 1,
  },
}

interface IDefaultProps {
  markerColor: string
  shape: EMarkerShape
  textColor: string
  opacity: number
  scale: number
}

interface IMarkerViewOtherProps {
  onClick?: () => void
  title?: string
  speed?: number
  ign?: boolean
  heading?: number
  iconPath?: string
  iconColor?: string
  style?: React.CSSProperties
  tasks?: {
    equipId: string
    color: string
    onMouseEnter: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseLeave: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  }
}

interface IMarkerViewPropsPartial
  extends Partial<IDefaultProps>,
    IMarkerViewOtherProps {}
interface IMarkerViewProps extends IDefaultProps, IMarkerViewOtherProps {}

/**
 * This component is part of the LIVE Maptrac, and is the Marker Layout, doing stuff can mess with its position and GPS Location
 */
// @ts-ignore
export const MarkerView: React.FunctionComponent<IMarkerViewPropsPartial> = (
  props: IMarkerViewProps
) => {
  const borderColor = darken(props.markerColor, 0.4)
  const { height, width } = dimensionsLookup[props.shape]
  return (
    <div
      style={{
        height: height * props.scale,
        width: width * props.scale,
        overflow: 'visible',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        opacity: props.opacity,
        cursor: props.onClick && 'pointer',
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.title && (
        <TitleBar
          title={props.title}
          speed={props.speed}
          background={props.markerColor}
          textColor={props.textColor}
          borderColor={borderColor}
          tasks={props.tasks}
        />
      )}
      {/* This component will only show when the IGN is on, and is basically an animation that changes the opacity from 0% to 50% */}
      {props.ign
        ? props.ign && (
            <div
              className="ignBlinking"
              style={{
                width: 27,
                height: 27,
                backgroundColor: lightGreen['A400'],
                position: 'absolute',
                zIndex: -1,
                borderRadius: '50%',
              }}
            />
          )
        : null}
      <MarkerShape
        shape={props.shape}
        iconPath={props.iconPath}
        markerColor={props.markerColor}
        borderColor={borderColor}
        iconColor={props.iconColor}
        scale={props.scale}
        heading={props.heading}
      />
    </div>
  )
}

MarkerView.defaultProps = {
  markerColor: 'black',
  shape: EMarkerShape.Circle,
  textColor: 'white',
  opacity: 1,
  scale: 1,
}

const calcTransform = (centerPosition?: EMarkerCenterPosition): string => {
  switch (centerPosition) {
    case EMarkerCenterPosition.BottomCenter:
      return 'translate(-50%,-100%)'
    case EMarkerCenterPosition.Center:
      return 'translate(-50%,-50%)'
    default:
      return ''
  }
}

interface IOverlayMarker extends IMarkerViewPropsPartial {
  centerPosition?: EMarkerCenterPosition
  offset?: number
}

export const OverlayMarker: React.FunctionComponent<IOverlayMarker> = ({
  centerPosition,
  offset,
  ...props
}) => (
  <MarkerView
    {...props}
    style={{
      transform: calcTransform(centerPosition),
      marginTop: offset ? offset * 14 : undefined,
    }}
  />
)

export interface IMarker extends IMarkerViewPropsPartial {
  centerPosition?: EMarkerCenterPosition
  position: ILatLng | google.maps.LatLng | google.maps.LatLngLiteral
}

const Marker: React.FunctionComponent<IMarker> = ({
  position,
  centerPosition,
  ...props
}) => (
  <OverlayView
    position={position}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    getPixelPositionOffset={(w, h) => calcOffset(w, h, centerPosition)}
  >
    <MarkerView {...props} />
  </OverlayView>
)

export default Marker
