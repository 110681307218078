import React from 'react'
import '../../styles/components/IconButton.scss'
import MuiIconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { IStandardIcons } from '../ux/standardIcons'
import Icon from '../Icon'
import { IIconButtonVariants } from '../../types/buttonVariants'

interface IProps {
  icon: IStandardIcons
  variant: IIconButtonVariants
  id?: string
  href?: string
  target?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler
  tooltip?: string
  tooltipPlacement?: 'right'
  color?: 'inherit' | 'primary' | 'secondary'
  style?: React.CSSProperties
  key?: any
  title?: string
}

/**
 * Customized icon button for rovitracker
 * only supports rovitracker standard rovitracker icons
 * and has built-in tooltips
 */
const IconButton = (props: IProps) => {
  const comp = (
    <MuiIconButton
      key={props.key}
      id={props.id}
      href={props.href}
      component="a"
      color={props.color ? props.color : 'inherit'}
      disabled={props.disabled}
      target={props.target}
      onClick={props.onClick}
      style={{ opacity: props.disabled ? 0.26 : undefined }} // This is here to force the disable look, not sure why it wasnt working normally
      className={props.variant === 'row' ? 'row' : undefined}
      title={props.title}
    >
      <Icon
        icon={props.icon}
        style={props.style}
        fontSize={
          props.variant === 'toolbar' || props.variant === 'row'
            ? 'small'
            : undefined
        }
      />
    </MuiIconButton>
  )
  return !!props.tooltip ? (
    <Tooltip title={props.tooltip} placement={props.tooltipPlacement}>
      {props.disabled ? <span>{comp}</span> : comp}
    </Tooltip>
  ) : (
    comp
  )
}

export default IconButton

interface IPropsNonA {
  icon: IStandardIcons
  variant: IIconButtonVariants
  id?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler
  tooltip?: string
  tooltipPlacement?: 'right'
  color?: 'inherit' | 'primary' | 'secondary'
  style?: React.CSSProperties
  key?: any
}

export const IconButtonNonA = (props: IPropsNonA) => {
  const comp = (
    <MuiIconButton
      key={props.key}
      id={props.id}
      component="span"
      color={props.color ? props.color : 'inherit'}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{ opacity: props.disabled ? 0.26 : undefined }} // This is here to force the disable look, not sure why it wasnt working normally
      className={props.variant === 'row' ? 'row' : undefined}
    >
      <Icon
        icon={props.icon}
        style={props.style}
        fontSize={
          props.variant === 'toolbar' || props.variant === 'row'
            ? 'small'
            : undefined
        }
      />
    </MuiIconButton>
  )
  return !!props.tooltip ? (
    <Tooltip title={props.tooltip} placement={props.tooltipPlacement}>
      {props.disabled ? <span>{comp}</span> : comp}
    </Tooltip>
  ) : (
    comp
  )
}
