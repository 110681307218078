import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { colors } from '../ux/roviTheme'
import IconButton from '../buttons/IconButton'

interface IProps {
  header?: string
  style?: React.CSSProperties
  onEdit?: () => void
  emptyMessage?: string
  equipId: string
  styles?: string
  editButton?: boolean
}

export const Section: React.FunctionComponent<IProps> = (props) => (
  <Card className={props.styles}>
    <CardContent style={{ display: 'flex', alignItems: 'center' }}>
      {props.header && (
        <Typography variant="h6" style={{ flex: 1, color: colors.defaultText }}>
          {props.header}
        </Typography>
      )}
      <div>
        {props.editButton ? (
          <IconButton variant="toolbar" icon="edit" onClick={props.onEdit} />
        ) : null}
      </div>
    </CardContent>
    <CardContent style={props.style}>
      {props.children}
      {props.emptyMessage && (
        <Typography variant="body2" color="textSecondary">
          {props.emptyMessage}
        </Typography>
      )}
    </CardContent>
  </Card>
)
