import React from 'react'
import '../../styles/components/accountComponents/AccountBranch.scss'
import IconButton from '../buttons/IconButton'
import Icon from '../Icon'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import { getMapUrl } from '../maptrac/maptracHelpers'
import UserAvatar from '../ux/UserAvatar'
import AddEditBranchDialog from './dialogs/AddEditBranchDialog'
import DeleteBranchDialog from './dialogs/DeleteBranchDialog'
import AddUserDialog from './dialogs/AddUserDialog'
import { branchPageURL } from '../../constants/urlBuilder'
import { IBranch } from '../../types/account/account'
import { ERole, IUser } from '../../types/account/user'
import { userProfileURL } from '../../constants/urlBuilder'

const SpacedDiv: React.FC<{ style?: React.CSSProperties }> = (props) => (
  <div style={{ margin: 5, ...props.style }}>{props.children}</div>
)

const mapAvailable = true

const addressPresent = true

interface IProps {
  branch: IBranch
  isMobile: boolean
}

interface IState {
  editBranchDialogOpen: boolean
  deleteBranchDialogOpen: boolean
  addUserDialogOpen: boolean
  branchManagers: IUser[]
}

class AccountBranchCard extends React.Component<IProps, IState> {
  state: IState = {
    editBranchDialogOpen: false,
    deleteBranchDialogOpen: false,
    addUserDialogOpen: false,
    branchManagers: [],
  }

  sortBranchManagers(users?: IUser[]) {
    let managers: IUser[] = []
    if (!!users) {
      users.forEach((user) => {
        if (user.role !== ERole.worker) {
          managers.push(user)
        }
      })
    }
    return managers
  }

  componentDidMount() {
    this.setState({
      branchManagers: this.sortBranchManagers(this.props.branch.BranchUsers),
    })
  }

  render() {
    /** Combines all the situations to why this branch cannot be deleted */
    const DisableBranchDelete =
      this.props.branch.BranchAssets > 0 ||
      (this.props.branch.BranchUsers &&
        this.props.branch.BranchUsers.length === 0)
    return (
      <>
        <Divider />
        <div className="branchcontainer">
          {this.props.isMobile ? null : (
            <div className="branchMapOuterBox">
              {mapAvailable ? (
                <img
                  alt="Branch GPS Location Google Maps"
                  width={180}
                  height={170}
                  src={getMapUrl({
                    lat: this.props.branch.BranchGPS.BranchLatitude,
                    lng: this.props.branch.BranchGPS.BranchLongitude,
                  })}
                />
              ) : (
                <div className="mapNotAvailable" />
              )}
            </div>
          )}
          <div className="branchInfoContainer">
            <CardHeader
              action={
                <>
                  <IconButton
                    variant="toolbar"
                    icon="delete"
                    tooltip={
                      DisableBranchDelete
                        ? "Can't delete branch with users or equipment"
                        : 'Delete'
                    }
                    onClick={() =>
                      this.setState({ deleteBranchDialogOpen: true })
                    }
                    disabled={DisableBranchDelete}
                  />
                  <IconButton
                    variant="toolbar"
                    icon="addUser"
                    tooltip="Add User"
                    onClick={() => this.setState({ addUserDialogOpen: true })}
                  />
                  <IconButton
                    variant="toolbar"
                    icon="edit"
                    tooltip="Edit"
                    onClick={() =>
                      this.setState({ editBranchDialogOpen: true })
                    }
                  />
                </>
              }
              title={
                <a href={branchPageURL(this.props.branch.BranchId)}>
                  {this.props.branch.BranchName}
                </a>
              }
            />
            <CardContent>
              <div className="branchInfoBody">
                <div className="singleInfoContainer">
                  {addressPresent ? (
                    <div>
                      <SpacedDiv>{this.props.branch.BranchAddress}</SpacedDiv>
                      <SpacedDiv>
                        {this.props.branch.BranchCity},{' '}
                        {this.props.branch.BranchState}{' '}
                        {this.props.branch.BranchZipCode}
                      </SpacedDiv>
                    </div>
                  ) : (
                    <div style={{ height: 40 }} />
                  )}
                  <div
                    className="branchTimeZoneContainer"
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  >
                    <Icon
                      icon="timezone"
                      fontSize="inherit"
                      style={{
                        height: 12,
                        width: 12,
                        marginRight: 3,
                        marginBottom: 2,
                      }}
                    />
                    <SpacedDiv style={{ fontSize: 11 }}>
                      {this.props.branch.BranchTimeZone}
                    </SpacedDiv>
                  </div>
                </div>
                <div className="singleInfoContainer">
                  <div>
                    <SpacedDiv>Thing count:</SpacedDiv>
                    <SpacedDiv>
                      <Typography variant="h6" color="primary">
                        {this.props.branch.BranchAssets}
                      </Typography>
                    </SpacedDiv>
                  </div>
                </div>
                <div className="singleInfoContainer">
                  <div>
                    <SpacedDiv>Labor hourly rate:</SpacedDiv>
                    <SpacedDiv>
                      <Typography variant="h6" color="primary">
                        ${this.props.branch.BranchLaborHourlyRate}
                      </Typography>
                    </SpacedDiv>
                  </div>
                </div>
                <div style={{ flex: 1.5, minWidth: 120, marginRight: 5 }}>
                  {this.state.branchManagers
                    ? this.state.branchManagers.length > 0 && (
                        <div style={{ marginBottom: 20 }}>
                          <SpacedDiv>
                            {this.state.branchManagers.length > 1
                              ? 'Managers'
                              : 'Manager'}
                            :
                          </SpacedDiv>

                          {this.state.branchManagers.map((manager) => (
                            <div key={manager.id}>
                              <Typography
                                variant="body2"
                                style={{ paddingLeft: 15 }}
                              >
                                <a href={userProfileURL(manager.id)}>
                                  {manager.firstName} {manager.lastName}
                                </a>
                              </Typography>
                            </div>
                          ))}
                        </div>
                      )
                    : null}
                  <div>
                    {this.props.branch.BranchUsers
                      ? this.props.branch.BranchUsers.length > 0 && (
                          <SpacedDiv>Users:</SpacedDiv>
                        )
                      : null}
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {this.props.branch.BranchUsers?.map((user) =>
                        user.role === ERole.worker ? (
                          <div key={user.id} style={{ margin: 2 }}>
                            <UserAvatar
                              userId={user.id}
                              firstName={user.firstName}
                              lastName={user.lastName}
                            />
                          </div>
                        ) : undefined
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </div>
        </div>
        {this.state.editBranchDialogOpen ? (
          <AddEditBranchDialog
            type="edit"
            dialogOpen={this.state.editBranchDialogOpen}
            onClose={() => this.setState({ editBranchDialogOpen: false })}
            name={this.props.branch.BranchName}
            address={this.props.branch.BranchAddress}
            city={this.props.branch.BranchCity}
            stateProvince={this.props.branch.BranchState}
            postalCode={this.props.branch.BranchZipCode}
            country={this.props.branch.BranchCountry}
            timezone={this.props.branch.BranchTimeZone}
            taxRentals={this.props.branch.taxRental}
            defaultTax={this.props.branch.defaultTax}
            laborHourly={this.props.branch.BranchLaborHourlyRate}
            metricSystem={this.props.branch.metricSystem}
          />
        ) : null}
        {this.state.deleteBranchDialogOpen ? (
          <DeleteBranchDialog
            dialogOpen={this.state.deleteBranchDialogOpen}
            onClose={() => this.setState({ deleteBranchDialogOpen: false })}
            branchName={this.props.branch.BranchName}
            branchId={this.props.branch.BranchId}
          />
        ) : null}
        <AddUserDialog
          dialogOpen={this.state.addUserDialogOpen}
          onClose={() => this.setState({ addUserDialogOpen: false })}
        />
      </>
    )
  }
}

export default AccountBranchCard
