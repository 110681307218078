import React, { Component } from 'react'
import Typography from '@mui/material/Typography'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Button from '../../buttons/Button'
import Select from '../../ux/Select'
import {
  EMonitoring,
  ERentStatus,
  ERepairStatus,
  TMonitorTypes,
  TRentStatusTypes,
  TRepairStatus,
} from '../../../types/inventory'
import { ICategory } from '../../../types/category'
import { IUser } from '../../../types/account/user'
import { IFlag } from '../../../types/flag'
import { IAgreement } from '../../../types/agreements'
import {
  convertCategoriesSelect,
  convertUsersSelect,
  convertFlagsSelect,
  convertClientSelect,
  convertSiteSelect,
} from '../inventorySorting/inventorySorting'

const defaultLable = '- Any -'
const defaultVAl = 'all'

const styles = createStyles({
  label: {
    padding: 20,
  },
  contentsContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: 20,
    float: 'right',
  },
  fieldsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  skeleton: {
    margin: 0,
    marginTop: 16,
  },
})

interface IState {}

interface IProps extends WithStyles<typeof styles> {
  categoryList: ICategory[]
  monitor: TMonitorTypes
  rentStatus: TRentStatusTypes
  repairStatus: TRepairStatus
  changeMonitor: (val: TMonitorTypes) => void
  changeRent: (val: TRentStatusTypes) => void
  changeRepair: (val: TRepairStatus) => void
  categoryId: string
  changeCategory: (val: string) => void
  userList: IUser[]
  userId: string
  changeUser: (val: string) => void
  agreementList: IAgreement[]
  clientId: string
  changeClient: (val: string) => void
  siteId: string
  changeSite: (val: string) => void
  flagList: IFlag[]
  flagId: string
  changeFlag: (val: string) => void
  clearFilters: () => void
}

class FilterDrawer extends Component<IProps, IState> {
  clearAllFilters() {
    this.props.changeMonitor(EMonitoring.all)
    this.props.changeRent(ERentStatus.all)
    this.props.changeRepair(ERepairStatus.all)
    this.props.changeCategory(defaultVAl)
    this.props.changeUser(defaultVAl)
    this.props.changeFlag(defaultVAl)
    this.props.changeClient(defaultVAl)
    this.props.changeSite(defaultVAl)
    this.props.clearFilters() // this needs to be last as it clears the hash
  }

  render() {
    const classes = this.props.classes

    return (
      <div className={classes.contentsContainer}>
        <Typography variant="h6" className={classes.label}>
          Filters:
        </Typography>
        <div className={classes.fieldsContainer}></div>
        <Select
          id="assetsFilterDrawerMonitoringButton"
          label="Monitoring"
          selectedValue={this.props.monitor}
          values={[
            { value: EMonitoring.all, label: defaultLable },
            { value: EMonitoring.monitored, label: 'Only Monitored' },
            { value: EMonitoring.nonMonitored, label: 'Only Not-Monitored' },
          ]}
          onChange={(event) =>
            this.props.changeMonitor(event.target.value as TMonitorTypes)
          }
        />
        <Select
          id="assetsFilterDrawerAvailabilityButton"
          label="Availability"
          selectedValue={this.props.rentStatus}
          values={[
            { value: ERentStatus.all, label: defaultLable },
            { value: ERentStatus.onRent, label: 'On Rent' },
            { value: ERentStatus.offRent, label: 'Off Rent' },
          ]}
          onChange={(event) =>
            this.props.changeRent(event.target.value as TRentStatusTypes)
          }
        />
        <Select
          id="assetsFilterDrawerServiceButton"
          label="Service"
          selectedValue={this.props.repairStatus}
          values={[
            { value: ERepairStatus.all, label: defaultLable },
            { value: ERepairStatus.onRepair, label: 'Being Serviced' },
            { value: ERepairStatus.offRepair, label: 'Not Being Serviced' },
          ]}
          onChange={(event) =>
            this.props.changeRepair(event.target.value as TRepairStatus)
          }
        />
        <Select
          id="assetsFilterDrawerCategoryButton"
          label="Category"
          selectedValue={this.props.categoryId}
          values={convertCategoriesSelect(this.props.categoryList, {
            value: defaultVAl,
            label: defaultLable,
          })}
          onChange={(event) =>
            this.props.changeCategory(event.target.value as string)
          }
        />
        <Select
          id="assetsFilterDrawerUserButton"
          label="User"
          selectedValue={this.props.userId}
          values={convertUsersSelect(this.props.userList, {
            value: defaultVAl,
            label: defaultLable,
          })}
          onChange={(event) =>
            this.props.changeUser(event.target.value as string)
          }
        />
        <Select
          id="assetsFilterDrawerClientButton"
          label="Client"
          selectedValue={this.props.clientId}
          values={convertClientSelect(this.props.agreementList, {
            value: defaultVAl,
            label: defaultLable,
          })}
          onChange={(event) => {
            this.props.changeClient(event.target.value as string)
            this.props.changeSite(defaultVAl)
          }}
        />
        <Select
          id="assetsFilterDrawerSiteButton"
          label="Site"
          selectedValue={this.props.siteId}
          values={convertSiteSelect(
            this.props.agreementList,
            {
              value: defaultVAl,
              label: defaultLable,
            },
            this.props.clientId
          )}
          onChange={(event) =>
            this.props.changeSite(event.target.value as string)
          }
          disabled={this.props.clientId === defaultVAl}
        />
        <Select
          id="assetsFilterDrawerFlagButton"
          label="Flag"
          selectedValue={this.props.flagId}
          values={convertFlagsSelect(this.props.flagList, {
            value: defaultVAl,
            label: defaultLable,
          })}
          onChange={(event) =>
            this.props.changeFlag(event.target.value as string)
          }
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.clearAllFilters()}
          >
            Clear Filters
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(FilterDrawer)
