import React, { useCallback } from 'react'
import {
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber,
} from 'libphonenumber-js'
import MaskedInput from 'react-text-mask'
import Input from '@mui/material/Input'
import FormControl from '@mui/material/FormControl'

interface ITextMaskCustomProps {
  inputRef: (ref: JSX.Element | null) => void
}

const TextMaskCustom = React.forwardRef<HTMLElement>(function TextMaskCustom(
  props,
  ref
) {
  const setRef = useCallback(
    (maskedInputRef: { inputElement: HTMLElement } | null) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null

      if (typeof ref === 'function') {
        ref(value)
      } else if (ref) {
        ref.current = value
      }
    },
    [ref]
  )

  return (
    <MaskedInput
      {...props}
      ref={setRef}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
})

interface IProps {
  onChange: (phone: string) => void
  value: string
  className?: string
}

interface IState {
  textmask: string
}

class PhoneNumberInput extends React.Component<IProps, IState> {
  state: IState = {
    textmask: this.props.value
      ? formatIncompletePhoneNumber(this.props.value, 'US')
      : '(   )    -    ',
  }

  handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const textmask = ev.target.value
    this.setState({ textmask })

    const phone = parseIncompletePhoneNumber(textmask)
    this.props.onChange(phone)
  }

  render() {
    return (
      <FormControl variant="standard">
        <Input
          value={this.state.textmask}
          onChange={this.handleChange}
          inputComponent={TextMaskCustom as any}
          className={this.props.className}
        />
      </FormControl>
    )
  }
}

export default PhoneNumberInput
