import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      background: theme.palette.background.paper,
    },
  })
)

interface IProps {
  statusOpen: boolean
  onChange: (status: boolean) => void
  header: boolean
}

const StatusList = (props: IProps) => {
  const classes = useStyles({})
  return (
    <List
      subheader={
        <ListSubheader className={classes.header}>
          {props.header ? 'Status' : ''}
        </ListSubheader>
      }
    >
      <ListItem
        button
        selected={props.statusOpen}
        onClick={() => props.onChange(true)}
      >
        <ListItemText primary="Open" />
      </ListItem>
      <ListItem
        button
        selected={!props.statusOpen}
        onClick={() => props.onChange(false)}
      >
        <ListItemText primary="Closed" />
      </ListItem>
    </List>
  )
}

export default StatusList
