import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import NumberField from '../../textBoxs/NumberField'

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
}

interface IState {
  description: string
  amount: number | null
}

class AddDefaultFeeDialog extends React.Component<IProps, IState> {
  state: IState = {
    description: '',
    amount: 0,
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  isSaveButtonDisabled() {
    return !(!!this.state.description && !!this.state.amount)
  }

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose}>
        <DialogTitle>Create Default Agreement Fee</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Description"
            required
            className={this.props.classes.TextField}
            value={this.state.description}
            onChange={(event) =>
              this.setState({ description: event.target.value })
            }
          />
          <NumberField
            label="Amount"
            required
            className={this.props.classes.TextField}
            value={this.state.amount}
            onChange={(event) => this.setState({ amount: event })}
            startAdornment="$"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AddDefaultFeeDialog)
