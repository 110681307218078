import React from 'react'

interface IProps {
  height?: number
  width?: number
}

const Margin = (props: IProps) => <div style={{ ...props }} />

export default Margin
