import React from 'react'
import '../../styles/components/serviceComponents/scheduleRatio.scss'
import ArrowIcon from '@mui/icons-material/Details'
import red from '@mui/material/colors/red'
import Icon from '../Icon'
import Tooltip from '@mui/material/Tooltip'
import Link from '@mui/material/Link'

interface IProps {
  progress: number
  threshold: number
  isMobile?: boolean
  equipId: string
}

const ScheduleRatio = (props: IProps) => {
  const progressAdjusted =
    props.progress < 0
      ? 0
      : Math.min(
          props.progress * (props.isMobile ? 50 : 100),
          props.isMobile ? 60 : 120
        )

  return (
    <div style={{ margin: 10 }}>
      {props.progress < 0 ? (
        <Link href="/">
          <Tooltip title="Check Last Serviced Date">
            <div className="discrepancy">
              {props.isMobile ? 'Discrep...' : 'Discrepancy'}{' '}
              <Icon
                icon="warning"
                style={{ height: 16, width: 16, color: red[500] }}
              />
            </div>
          </Tooltip>
        </Link>
      ) : (
        <div className={props.isMobile ? 'containerMobile' : 'container'}>
          <div className="startBar" />
          <div className={props.isMobile ? 'endBarMobile' : 'endBar'} />
          <ArrowIcon
            fontSize="small"
            color="action"
            style={{
              left: progressAdjusted - 8,
              transition: '1s',
            }}
            className={
              'icon' +
              (props.progress < props.threshold
                ? ' scheduled'
                : props.progress >= props.threshold && props.progress < 1
                ? ' upcoming'
                : props.progress >= 1
                ? ' overdue'
                : '')
            }
          />
        </div>
      )}
    </div>
  )
}

export default ScheduleRatio
