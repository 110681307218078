import React from 'react'
import { Theme } from '@mui/material'
import { WithStyles, createStyles, withStyles } from '@mui/styles'

interface IRuleContainerProps extends WithStyles<typeof styles> {
  onClick: () => void
  label: string
  children: any[]
}

const RuleContainer = (props: IRuleContainerProps) => (
  <div className={props.classes.container} onClick={props.onClick}>
    <div className={props.classes.labelContainer}>{props.label}</div>
    <div className={props.classes.contentsContainer}>{props.children}</div>
  </div>
)

const styles = (theme: Theme) =>
  createStyles({
    container: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      background:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'white',
      flex: 1,
      '&:hover': {
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.grey[600]
            : theme.palette.grey[50],
      },
    },
    labelContainer: {
      padding: '4px 8px',
      background:
        theme.palette.mode === 'dark'
          ? theme.palette.grey[700]
          : theme.palette.grey[100],
    },
    contentsContainer: {
      flex: 1,
      overflowY: 'auto',
      padding: 12,
    },
  })

export default withStyles(styles)(RuleContainer)
