import React from 'react'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Badge from '@mui/material/Badge'
import Icon from '../Icon'
import FlagPopover from './FlagPopover'
import purple from '@mui/material/colors/purple'
import { colors } from './roviTheme'
import { ERoutes } from '../routes/CheckRoutes'
import { backend_api, headersAPI } from '../../constants/api'

const styles = createStyles({
  badge: {
    top: 2,
    height: 14,
    width: 14,
    minWidth: 14,
    fontSize: 10,
    background: purple[300],
    color: 'white',
  },
})

interface IProps extends WithStyles<typeof styles> {
  flags?: Array<{ flagId: string; flagName: string }>
  isMobile?: boolean
  assetId: string
  white?: boolean
}

interface IState {
  flags: Array<{ flagId: string; flagName: string }>
  anchorEl?: HTMLElement | null
}

class FlagButton extends React.Component<IProps> {
  state = {
    anchorEl: null,
    flags: [],
  }

  handlePopoverOpen = (ev: { currentTarget: any }) => {
    this.setState({ anchorEl: ev.currentTarget })
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  grabAssetFlags() {
    fetch(backend_api + `/flags/assetFlagged?assetId=${this.props.assetId}`, {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          flags: response.flags,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.props.flags
      ? this.setState({ flags: this.props.flags })
      : this.grabAssetFlags()
  }

  render() {
    return (
      <>
        {this.state.flags.length > 0 ? (
          <a href={ERoutes.flag} onClick={(ev) => ev.stopPropagation()}>
            <Badge
              badgeContent={this.state.flags.length}
              onMouseEnter={this.handlePopoverOpen}
              onMouseLeave={this.handlePopoverClose}
              onFocus={this.handlePopoverOpen}
              onBlur={this.handlePopoverClose}
              classes={{
                badge: this.props.classes.badge,
              }}
            >
              <Icon
                icon="flag"
                fontSize="small"
                style={{ fill: this.props.white ? '#fff' : colors.iconColor }}
              />
            </Badge>
          </a>
        ) : (
          <div style={{ width: 18.56 }} />
        )}
        <FlagPopover
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          flags={this.state.flags}
        />
      </>
    )
  }
}

export default withStyles(styles)(FlagButton)
