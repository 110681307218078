import React from 'react'
import '../../styles/components/userProfile/NotificationsForm.scss'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MUICheckbox from '@mui/material/Checkbox'
import TextField from '../textBoxs/TextField'
import Button from '../buttons/Button'

interface IProps {}

class NotificationsForm extends React.Component<IProps> {
  state = {
    Emailcheck: false,
    SMScheck: false,
    SMSNumber: '',
  }

  render() {
    return (
      <div className="NotificationsFormContainer">
        <FormControl className="NotificationsFormCheckBox">
          <FormLabel>Email</FormLabel>
          <FormControlLabel
            control={
              <MUICheckbox
                checked={this.state.Emailcheck}
                onChange={() =>
                  this.setState({ Emailcheck: !this.state.Emailcheck })
                }
                color="primary"
              />
            }
            label="Send notifications via email to: nathan@rovitracker.com"
          />
        </FormControl>
        <FormControl className="NotificationsFormCheckBox">
          <FormLabel>Text Messages (SMS)</FormLabel>
          <FormControlLabel
            control={
              <MUICheckbox
                checked={this.state.SMScheck}
                onChange={() =>
                  this.setState({ SMScheck: !this.state.SMScheck })
                }
                color="primary"
              />
            }
            label="Send notifications via text messages"
          />
        </FormControl>
        {this.state.SMScheck ? (
          <div className="NotifSMSNumber">
            to
            <TextField
              required
              name="tel"
              label="Phone Number"
              autoComplete="tel"
              type="tel"
              style={{ marginLeft: 5 }}
              value={this.state.SMSNumber}
              onChange={(event) =>
                this.setState({ SMSNumber: event.target.value })
              }
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => console.log()}
            >
              Save
            </Button>
          </div>
        ) : null}
      </div>
    )
  }
}

export default NotificationsForm
