// import qs from 'qs'
// import { ERoutes } from './CheckRoutes'
// import HashStateManager from '../../rx-js/HashStateManager'
// import {
//   IFlagHash,
//   FlagHashToString,
//   FlagHashFromString,
// } from '../../constants/flagHashController'

type UrlType = 'string' | 'number' | 'Array<string>' | 'object'
type RouteValue = [string, UrlType]

export default class Route {
  static arrayToString(url: string, array: any[]) {
    let arrStr = array.toString()

    return url + `#initialEquip=${arrStr}`
  }
}
