import Route from './Route'
import { ERoutes } from './CheckRoutes'

interface IAssetsParams {
  tab?: 'person' | 'place' | 'thing'
}

interface INewFlagParams {
  initialEquip?: string[]
}

/**
 * This is to try and make linking to accounts easier, and always the exact same.
 */
export default {
  /**
   * Links to the Flags Page.
   * this is only used with anything thats not a href
   * @returns '/app/flags#initialEquip=[Array]'
   */
  createFlagWithAssets: (array: string[]) => {
    return window.location.replace(Route.arrayToString(ERoutes.flag, array))
  },
  /**
   * Links to the Flags Page.
   * only use this with href calls
   * @returns '/app/flags#initialEquip=[Array]'
   */
  createFlagWithAssetsHref: (array: string[]) => {
    return Route.arrayToString(ERoutes.flag, array)
  },
  /** Navigate to Devices Page */
  gotoDevicesPage: () => {
    return ERoutes.account + '#tab=devices'
  },
}
