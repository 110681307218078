import React from 'react'
import Icon from '../Icon'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Popover from '@mui/material/Popover'

const styles = createStyles({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: 10,
    maxWidth: 300,
    minWidth: 100,
  },
})

interface IProps extends WithStyles<typeof styles> {
  className?: string
}

class InfoPopup extends React.Component<IProps> {
  state = {
    anchorEl: null,
  }

  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    return (
      <>
        <Icon
          icon="flag"
          className={this.props.className}
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
        />
        <Popover
          open={!!this.state.anchorEl}
          className={this.props.classes.popover}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: this.props.classes.paper,
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          {this.props.children}
        </Popover>
      </>
    )
  }
}

export default withStyles(styles)(InfoPopup)
