import { IImage } from './image'
import { IEquipmentAgreement } from './equipment'

export type FilterType_Thing =
  | 'monitoring'
  | 'rentStatus'
  | 'repairStatus'
  | 'categoryId'
  | 'clientId'
  | 'siteId'
  | 'userId'
  | 'flagId'

export enum EMonitoring {
  all = 'all',
  monitored = 'monitored',
  nonMonitored = 'not_monitored',
}
export type TMonitorTypes =
  | EMonitoring.all
  | EMonitoring.monitored
  | EMonitoring.nonMonitored

export enum ERentStatus {
  all = 'all',
  onRent = 'on_rent',
  offRent = 'off_rent',
}
export type TRentStatusTypes =
  | ERentStatus.all
  | ERentStatus.onRent
  | ERentStatus.offRent

export enum ERepairStatus {
  all = 'all',
  onRepair = 'on_repair',
  offRepair = 'off_repair',
}
export type TRepairStatus =
  | ERepairStatus.all
  | ERepairStatus.onRepair
  | ERepairStatus.offRepair

export interface IFilters_Thing {
  monitoring: TMonitorTypes
  rentStatus: TRentStatusTypes
  repairStatus: TRepairStatus
  categoryId: string
  clientId: string
  siteId: string
  userId: string
  flagId: string
  // q: string
}

export interface IFilters_Person {}

export interface IFilters_Place {}

export interface IEquip {
  agreement: IEquipmentAgreement | null
  assignedTo: string | null
  categoryId: string
  devices: string[]
  eventsUrl: string
  id: string
  image: IImage
  inService: boolean
  make: string
  maptracUrl: string
  model: string
  name: string
  reportsUrl: string
  serialNumber: string
  timelineUrl: string
  viewUrl: string
  vin: string | null
}
