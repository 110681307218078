import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import TextField from '../../textBoxs/TextField'
import { Typography } from '@mui/material'
import Button from '../../buttons/Button'
import IconButton from '../../buttons/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { RegexPasswordCheck } from '../../../utility/regex'
import { Auth } from 'aws-amplify'

const styles = (theme: Theme) =>
  createStyles({
    paper: {},
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    formControl: {
      margin: theme.spacing(1),
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
}

interface IState {
  error: string
  // current
  currentPassword: string
  currentPasswordError: string
  currentPasswordVisible: boolean
  // new
  password: string
  passwordError: string
  passwordVisible: boolean
  // confirm
  passwordConfirm: string
  password2Error: string
  password2Visible: boolean
}

class ChangePasswordDialog extends React.Component<IProps, IState> {
  state: IState = {
    error: '',
    currentPassword: '',
    currentPasswordError: '',
    currentPasswordVisible: false,
    password: '',
    passwordError: '',
    passwordVisible: false,
    passwordConfirm: '',
    password2Error: '',
    password2Visible: false,
  }

  changePassword1(val: string) {
    this.setState({
      password: val,
      passwordError:
        val.length > 7
          ? RegexPasswordCheck.test(val)
            ? ''
            : 'Password Requires atleast 1 special character'
          : 'Password is too short',
      password2Error:
        this.state.password === val ? '' : "Password doesn't match",
    })
  }

  changePassword2(val: string) {
    this.setState({
      passwordConfirm: val,
      password2Error:
        this.state.password === val ? '' : "Password doesn't match",
    })
  }

  handleClose = () => {
    this.props.onClose()
  }

  /**
   * Possible vairables
   * SUCCESS
   * {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Incorrect username or password."}
   */
  onSubmit = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          this.state.currentPassword,
          this.state.password
        )
      })
      .then(() => this.handleClose())
      .catch((err) => this.setState({ error: err.message }))
  }

  isSaveButtonDisabled() {
    return !(
      !!this.state.password &&
      !!this.state.passwordConfirm &&
      !this.state.passwordError &&
      !this.state.password2Error
    )
  }

  componentDidMount() {}

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Typography>{this.state.error}</Typography>
          <DialogRow
            FieldLeft={
              <TextField
                required
                name="password"
                label="Current Password"
                className={this.props.classes.TextField}
                error={!!this.state.currentPasswordError}
                helperText={this.state.currentPasswordError}
                type={this.state.currentPasswordVisible ? 'text' : 'password'}
                value={this.state.currentPassword}
                onChange={(event) =>
                  this.setState({ currentPassword: event.target.value })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      variant="appbar"
                      icon={
                        this.state.currentPasswordVisible
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      onClick={() =>
                        this.setState({
                          currentPasswordVisible:
                            !this.state.currentPasswordVisible,
                        })
                      }
                    />
                  </InputAdornment>
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                required
                name="password"
                label="New Password"
                autoComplete="password"
                className={this.props.classes.TextField}
                error={!!this.state.passwordError}
                helperText={this.state.passwordError}
                type={this.state.passwordVisible ? 'text' : 'password'}
                value={this.state.password}
                onChange={(event) => this.changePassword1(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      variant="appbar"
                      icon={
                        this.state.passwordVisible
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      onClick={() =>
                        this.setState({
                          passwordVisible: !this.state.passwordVisible,
                        })
                      }
                    />
                  </InputAdornment>
                }
              />
            }
            FieldRight={
              <TextField
                required
                name="password2"
                label="Confirm New Password"
                autoComplete="password"
                className={this.props.classes.TextField}
                error={!!this.state.password2Error}
                helperText={this.state.password2Error}
                type={this.state.password2Visible ? 'text' : 'password'}
                value={this.state.passwordConfirm}
                onChange={(event) => this.changePassword2(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      variant="appbar"
                      icon={
                        this.state.password2Visible
                          ? 'visibility'
                          : 'visibilityOff'
                      }
                      onClick={() =>
                        this.setState({
                          password2Visible: !this.state.password2Visible,
                        })
                      }
                    />
                  </InputAdornment>
                }
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => this.onSubmit()}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ChangePasswordDialog)
