/**
 * Will only check for special characters, but left the rest of the information about regex here
 * ^	The password string will start this way
 * (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
 * (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
 * (?=.*[0-9])	The string must contain at least 1 numeric character
 * (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
 * (?=.{8,})	The string must be eight characters or longer
 */
export const RegexPasswordCheck = new RegExp(
  // prettier-ignore
  '^(?=.*[!@#$%^&*])'
)

/**
 * This will search through a string making sure it follows standard email guidelines
 */
export const RegexEmailCheck = new RegExp(
  // prettier-ignore
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
)
