import React from 'react'
import UserAvatar from '../../../ux/UserAvatar'
import Divider from '@mui/material/Divider'
import { ListImageEquip } from '../../../ux/ListImage'
import IgnitionBox from '../../../ux/IgnitionBox'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import FlagButton from '../../../ux/FlagButton'
import ServiceButton from '../../../ux/ServiceButton'
import Avatar from '@mui/material/Avatar'
import Icon from '../../../Icon'
import { TCategoryIcons } from '../../../../types/category'
import EllipsisText from '../../../ux/EllipsisText'

const styles = createStyles({
  container: {
    position: 'relative',
  },
  rightSection: {
    position: 'absolute',
    right: 14,
    top: 10,
    display: 'flex',
    alignItems: 'center',
  },
  metricItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 4px',
  },
  spacing: {
    marginRight: 8,
  },
  cameraAvatar: {
    height: 18,
    width: 18,
  },
  cameraIcon: {
    height: 16,
    width: 16,
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  titleContainer: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
    paddingRight: 44,
    width: 220,
  },
})

interface IProps extends WithStyles<typeof styles> {
  id: any
  name: string
  make: string
  model: string
  icon: TCategoryIcons
  imageUrl?: string
  tasks: any[]
  user?: any
  hasCamera: boolean
  battery?: any
  ignition?: boolean
  flags: any[]
  onHoverIn?: () => void
  onHoverOut?: () => void
  onClick?: () => void
}

class AssetListItem extends React.Component<IProps> {
  render() {
    const props = this.props
    return (
      <div className={props.classes.container}>
        <ListItem
          button
          onClick={props.onClick}
          onMouseEnter={props.onHoverIn}
          onMouseLeave={props.onHoverOut}
        >
          <div style={{ position: 'relative' }}>
            <ListImageEquip
              imageUrl={props.imageUrl}
              categoryIcon={props.icon}
              containerStyle={{ alignSelf: 'center' }}
            />
            <div
              style={{
                position: 'absolute',
                top: -6,
                right: -8,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {props.hasCamera ? (
                <Avatar className={props.classes.cameraAvatar}>
                  <Icon icon="camera" className={props.classes.cameraIcon} />
                </Avatar>
              ) : null}
              {props.user && (
                <UserAvatar
                  // TODO: check access
                  userId={props.user.userId}
                  firstName={props.user.firstName}
                  lastName={props.user.lastName}
                  variant="small"
                />
              )}
            </div>
          </div>
          <ListItemText
            primary={
              <div className={props.classes.titleContainer}>
                <EllipsisText text={this.props.name} className="nametext" />
                <div
                  style={{
                    width: 45,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <ServiceButton assetId={this.props.id} tasks={props.tasks} />
                  <FlagButton assetId={this.props.id} flags={props.flags} />
                </div>
              </div>
            }
            secondary={
              <span
                title={`${props.make} / ${props.model}`}
              >{`${props.make} / ${props.model}`}</span>
            }
            style={{ marginLeft: 20 }}
          />
        </ListItem>
        <Divider style={{ marginRight: 10, marginLeft: 96 }} />

        {(props.battery !== undefined || props.ignition !== undefined) && (
          <div className={props.classes.rightSection}>
            {props.battery.value !== undefined && (
              <div className={props.classes.metricItem}>
                <div
                  style={{ fontSize: 10 }}
                  title={`${this.props.battery.value}V`}
                >
                  {this.props.battery.value}V
                  {/* {ElectricPotential.format(props.battery)} */}
                </div>
              </div>
            )}
            {props.ignition !== undefined && (
              <IgnitionBox label="IGN" isOn={props.ignition} />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(AssetListItem)
