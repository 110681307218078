import React from 'react'
import '../styles/pages/Dashboard.scss'
import ReactHelmet from '../components/ReactHelmet'
import WidthViewport from '../components/ux/WidthViewport'
import RecentActivity from '../components/recentActivityComponents/recentActivity'
import FloatingButtonDrawer from '../components/buttons/FloatingButtonDrawer'

const metaBaseHeaders = new Headers()
metaBaseHeaders.append('Accept', '*/*')
metaBaseHeaders.append('Content-Type', 'application/json;charset=utf-8')
metaBaseHeaders.append('Access-Control-Allow-Origin', '*')

const metabaseDashboardUrl =
  'https://metabase.rovitracker.com/embed/dashboard/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjd9LCJwYXJhbXMiOnsiYnJhbmNoX2lkIjoiNjcifX0.ellkJrV07LTTpt4n7XuV27-oFmTjfYVy0SZsBYaayLQ#bordered=false&titled=false'

interface IState {
  local?: string
}

interface IProps {}

class Dashboard extends React.Component<IProps, IState> {
  state: IState = {
    local: '',
  }

  SetDefaultRecentActivity(bool: boolean) {
    localStorage.setItem('recentActivityHeldOpen', bool ? 'true' : 'false')
  }

  CheckDefaultRecentActivity() {
    return localStorage.getItem('recentActivityHeldOpen') === 'false'
      ? false
      : true
  }

  grabContacts = () => {
    fetch(
      'https://api.hubapi.com/crm/v3/objects/contacts?hapikey=d6bb47ba-564c-4687-b21f-9def321a3233&limit=10',
      {
        method: 'GET',
        mode: 'no-cors',
        headers: metaBaseHeaders,
      }
    )
      // .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    this.grabContacts()
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <div className="dashBoardBody">
            <ReactHelmet title="Dashboard" />

            <iframe
              src={metabaseDashboardUrl}
              style={{
                flex: '1 1 0%',
                border: 0,
              }}
              title="Dashboard"
              id="metaBaseDashboard"
            />
            {/* <object
              id="rovitracker-report-frame"
              data="http://reporting.staging.rovitracker.cloud:8085/hi-ee/hi.html?dir=1463377807724/1472805277364/&file=dabe0f49-2da0-48db-9772-6a51d2a5e322.efw&mode=dashboard&j_organization=&j_username=hiadmin&j_password=hiadmin"
              aria-label="rovitracker report frame"
              style={{
                height: '100%',
                width: '100%',
                border: 0,
              }}
            /> */}
            {/* <iframe
              id="helicalinsight-report-frame"
              src="https://reporting.staging.rovitracker.cloud:8443/hi-ee/hi.html?dir=1463377807724/1472805277364/&file=dabe0f49-2da0-48db-9772-6a51d2a5e322.efw&mode=dashboard&j_organization=&j_username=hiadmin&j_password=hiadmin"
              title="rovitracker report frame"
              style={{ height: '100%', width: '100%', border: 0 }}
            ></iframe> */}
            {/* <embed
              id="helicalinsight-report-frame"
              src="https://reporting.staging.rovitracker.cloud:8443/hi-ee/hi.html?dir=1463377807724/1472805277364/&file=dabe0f49-2da0-48db-9772-6a51d2a5e322.efw&mode=dashboard&j_organization=&j_username=hiadmin&j_password=hiadmin"
              style={{ height: '100%', width: '100%', border: 0 }}
            ></embed> */}

            {isMobile ? undefined : (
              <FloatingButtonDrawer
                isMobile={isMobile}
                width={270}
                addPadding
                defaultOpen={this.CheckDefaultRecentActivity()}
                onClick={(bool) => this.SetDefaultRecentActivity(bool)}
                topMargin={64}
              >
                <RecentActivity />
              </FloatingButtonDrawer>
            )}
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default Dashboard
