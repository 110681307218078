import React from 'react'
import ReactHelmet from '../../components/ReactHelmet'
import { colors } from '../../components/ux/roviTheme'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { backend_api, headersAPI } from '../../constants/api'
import FieldLabelRow from '../../components/accountComponents/FieldLabelRow'

export interface IInput {
  name: string
  label: string
}

interface IProps {}

interface IState {
  inputs: IInput[]
}

class FieldLabelsPage extends React.Component<IProps, IState> {
  state: IState = {
    inputs: [
      {
        name: 'Input1',
        label: 'Input #1',
      },
      {
        name: 'input2',
        label: 'Input #2',
      },
      {
        name: 'input3',
        label: 'Input #3',
      },
      {
        name: 'input4',
        label: 'Input #4',
      },
    ],
  }

  grabInputs() {
    fetch(backend_api + '/inputs/123', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          inputs: response,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabInputs()
  }

  componentWillUnmount() {
    /** This is here to essentially unmount the state, fixing the async issue */
    this.setState = () => {
      return
    }
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <ReactHelmet title="FieldLabels" />
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            backgroundColor: colors.dbg,
            paddingBottom: 56,
            height: '100vh',
          }}
        >
          <div style={{ flex: 1, padding: 18 }}>
            <div style={{ fontSize: 20, margin: 0, paddingBottom: 12 }}>
              Device Field Labels
            </div>
            <div style={{ fontSize: 16, paddingBottom: 12 }}>
              Note: Accounts With Monitoring Only
            </div>
            <Table style={{ maxWidth: 400 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Field Name</TableCell>
                  <TableCell>Field Label</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.inputs.map((input: IInput, key) => (
                  <FieldLabelRow input={input} key={key} />
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    )
  }
}

export default FieldLabelsPage
