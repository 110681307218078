import React from 'react'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import Icon from '../Icon'

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    button: {
      marginLeft: 3,
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      whiteSpace: 'pre',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 210,
    },
  })
)

interface IProps {
  label: string
  value: string
  className?: string
  onDelete?: () => void
  onClickSelect: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const SelectChip = ({
  label,
  value,
  onClickSelect,
  onDelete,
  ...other
}: IProps) => {
  const classes = useStyles()
  return (
    <Chip
      {...other}
      label={
        <div className={classes.labelContainer}>
          <div className={classes.label}>
            <b>{label}:</b> {value}
          </div>
          <IconButton
            size="small"
            className={classes.button}
            onClick={onClickSelect}
          >
            <Icon icon="arrowDropDown" />
          </IconButton>
        </div>
      }
      onDelete={onDelete}
    />
  )
}

export default SelectChip
