import React, { useState } from 'react'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import { TextField, Theme } from '@mui/material'
import IntegrationDialog from './IntegrationDialog'

interface IWexIntegrationDialogProps extends WithStyles<typeof styles> {
  open: boolean
  username?: string
  password?: string
  ftpFilePath?: string
  logo?: string

  onSave: (username: string, password: string, ftpFilePath: string) => void
  onClose: () => void
}

const WexIntegrationDialog = (props: IWexIntegrationDialogProps) => {
  const [username, setUsername] = useState(props.username || '')
  const [password, setPassword] = useState(props.password || '')
  const [ftpFilePath, setFilePath] = useState(props.ftpFilePath || '')

  return (
    <IntegrationDialog
      open={props.open}
      logo={props.logo}
      title="Wex Integration"
      installNotes="Please provide your WEX transaction data FTP user, FTP password, and FTP file path."
      onClose={props.onClose}
      onSave={() => props.onSave(username, password, ftpFilePath)}
    >
      <div className={props.classes.inputArea}>
        <h5>Credentials</h5>
        <TextField
          variant="standard"
          label="Username"
          className={props.classes.inputField}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          variant="standard"
          label="Password"
          type="password"
          className={props.classes.inputField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          variant="standard"
          label="FTP File Path"
          className={props.classes.inputField}
          value={ftpFilePath}
          onChange={(e) => setFilePath(e.target.value)}
        />
      </div>
    </IntegrationDialog>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    inputArea: {
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    inputField: {
      maxWidth: '60%',
      minWidth: '30%',
      marginBottom: 5,
    },
  })

export default withStyles(styles)(WexIntegrationDialog)
