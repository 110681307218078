import { IAssetList, IAsset } from '../../../types/maptrac/Assets'
import { ITag } from '../../../types/maptrac/Tags'
import { getType } from '../../ux/ServicePopover'
import { IMaintenance } from '../filters/FilterMaptrac'

export function FilterAssetsByCategory(
  list: IAssetList[],
  searchString: string
) {
  return list.filter((ct) =>
    ct.equipment.find((a) =>
      a.name.toLowerCase().includes(searchString.toLowerCase())
    )
  )
}

export function FilterAssetsByName(list: IAssetList[], searchString: string) {
  return list.map((c) => {
    return {
      category: c.category,
      equipment: c.equipment.filter((a) =>
        a.name.toLowerCase().includes(searchString.toLowerCase())
      ),
      tags: c.tags,
    }
  })
}

export function FilterAssetsByMaintenance(
  list: IAssetList[],
  maintenance: IMaintenance
) {
  return list.map((c) => {
    return {
      category: c.category,
      equipment: filterAssetsMaintenanceMarker(
        c.equipment,
        maintenance.red.filter,
        maintenance.orange.filter,
        maintenance.blue.filter
      ),
      tags: c.tags,
    }
  })
}

/**
 * This filter Function handles the Asset Filtering on the Drawer
 */
export function filterAssetsDrawer(
  list: IAssetList[],
  searchString: string,
  maintenance: IMaintenance
) {
  return FilterAssetsByMaintenance(
    FilterAssetsByName(
      FilterAssetsByCategory(list, searchString),
      searchString
    ),
    maintenance
  )
}

/**
 * This filter Function handles the Asset Filtering on the Map also used as the length calc
 */
export function filterAssetsMarker(list: IAsset[], searchString: string) {
  return list.filter((a) =>
    a.name.toLowerCase().includes(searchString.toLowerCase())
  )
}

export function filterAssetsMaintenanceMarker(
  list: IAsset[],
  red: boolean,
  orange: boolean,
  blue: boolean
) {
  const All = red && orange && blue
  return list.filter((a) =>
    a.tasks.length > 0
      ? a.tasks.find((t) =>
          All
            ? true
            : getType(t.scheduleRatio) === 'red'
            ? red
              ? true
              : All
            : getType(t.scheduleRatio) === 'orange'
            ? orange
              ? true
              : All
            : getType(t.scheduleRatio) === 'blue'
            ? blue
              ? true
              : All
            : All
        )
      : All
  )
}

/**
 * Filter out Tags
 */
export function filterTagMarkers(list: Array<ITag[]>, searchString: string) {
  let fakeTags = [] as Array<ITag[]>
  list.forEach((tagList: ITag[]) =>
    fakeTags.push(
      tagList.filter((a) =>
        a.tagName.toLowerCase().includes(searchString.toLowerCase())
      )
    )
  )
  return fakeTags
}
