import React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogContentText,
  FormControlLabel,
} from '@mui/material'
import {
  TRateShow,
  TRateType,
  IRentalRatesBody,
  rateList,
  showKey,
  labelPer,
} from '../../types/equipment/equipmentProfile'
import CheckContainer from '../ux/CheckContainer'
import NumberField from '../textBoxs/NumberField'

interface IProps {
  open: boolean
  onClose: () => void
  isMobile: boolean
}

interface IState {
  rentalRatesBody: IRentalRatesBody
}

class RentalRatesDialog extends React.Component<IProps, IState> {
  state: IState = {
    rentalRatesBody: {
      hour: null,
      day: null,
      week: null,
      month: null,
      flat: null,
      showHour: false,
      showDay: false,
      showWeek: false,
      showMonth: false,
      showFlat: false,
    },
  }

  updateCheckBox(checkBox: TRateShow, newBoolean: boolean) {
    let newRentalRatesBody = this.state.rentalRatesBody
    newRentalRatesBody[checkBox] = newBoolean
    this.setState({ rentalRatesBody: newRentalRatesBody })
  }

  updateNumberField(field: TRateType, newNumber: number | null) {
    let newRentalRatesBody = this.state.rentalRatesBody
    newRentalRatesBody[field] = newNumber
    this.setState({ rentalRatesBody: newRentalRatesBody })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.onClose()}
        fullScreen={this.props.isMobile}
        fullWidth
      >
        <DialogTitle>Rental Rates</DialogTitle>

        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DialogContentText>
            Selected rental terms will display as an option on Rental Agreement
            forms.
          </DialogContentText>
          {rateList.map((rate) => {
            const showName = showKey[rate]
            return (
              <FormControlLabel
                key={rate}
                control={
                  <CheckContainer
                    checked={this.state.rentalRatesBody[showName]}
                    onChange={(checked: boolean) =>
                      this.updateCheckBox(showName, checked)
                    }
                    color="primary"
                  />
                }
                label={
                  <NumberField
                    value={this.state.rentalRatesBody[rate]}
                    onChange={(val) => this.updateNumberField(rate, val)}
                    startAdornment="$"
                    endAdornment={labelPer[rate].toLowerCase()}
                  />
                }
              />
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose()}>Cancel</Button>
          <Button color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default RentalRatesDialog
