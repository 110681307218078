import React from 'react'
import '../../styles/ux/ServiceButton.scss'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'

const styles = createStyles({
  dialogRowContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 30px',
  },
  dialogRowLeft: { width: 256, marginRight: 3 },
  dialogRowRight: { width: 256 },
  dialogRowRightAddOn: { display: 'flex', alignItems: 'center' },
})

interface IState {}

interface IProps extends WithStyles<typeof styles> {
  FieldLeft?: React.ReactElement
  FieldRight?: React.ReactElement
  FieldRightAddOn?: React.ReactElement | any
}

class DialogRow extends React.Component<IProps, IState> {
  state = {}

  render() {
    const classes = this.props.classes
    return (
      <div className={classes.dialogRowContainer}>
        <div className={classes.dialogRowLeft}>{this.props.FieldLeft}</div>
        <div className={classes.dialogRowRight}>{this.props.FieldRight}</div>
        <div className={classes.dialogRowRightAddOn}>
          {this.props.FieldRightAddOn}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(DialogRow)
