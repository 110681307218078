import React from 'react'
import { CompBar } from '../../components/toolbar/Toolbar'
import { ERoutes } from '../../components/routes/CheckRoutes'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '../../components/buttons/Button'
import Icon from '../../components/Icon'

const Tasks: Array<{ procedure: string }> = []
// [
//   { procedure: '1' },
//   { procedure: '2' },
//   { procedure: '3' },
//   { procedure: '4' },
// ]

interface IProps {}

interface IState {}

class EquipmentMaintenance extends React.Component<IProps, IState> {
  state: IState = {}
  render() {
    console.log(Tasks.length)
    return (
      <div className="">
        <CompBar>
          <Button startIcon={<Icon icon="add" />}>Assign Task</Button>
          <Button
            href={ERoutes.service}
            startIcon={<Icon icon="maintenance" />}
          >
            All Tasks
          </Button>
        </CompBar>
        <Paper className="">
          <Table style={{ minWidth: 900 }}>
            <TableHead>
              <TableRow>
                <TableCell>Procedure</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Schedule</TableCell>
                <TableCell>Last Serviced</TableCell>
                <TableCell>Next Service</TableCell>
                <TableCell>Schedule Progress</TableCell>
                <TableCell>Remove Assignment</TableCell>
              </TableRow>
            </TableHead>
            {Tasks.length === 0 ? (
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div>No tasks have been created.</div>
                    <a href={ERoutes.service}>Click here to add a task.</a>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {Tasks.map((task, key) => (
                  <TableRow key={key}>
                    <TableCell>{task.procedure}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Paper>
      </div>
    )
  }
}

export default EquipmentMaintenance
