import React from 'react'
import '../../styles/pages/equipment/EquipmentReports.scss'
import FloatingButtonDrawer from '../buttons/FloatingButtonDrawer'
import { PaddedSearchBar } from '../textBoxs/SearchBar'
import { Typography } from '@mui/material'
import ReportCard from '../reportComponents/ReportCard'
import { reports } from './EquipmentReportsInfo'
import Icon from '../Icon'
import Divider from '@mui/material/Divider'
import { IReportLink } from './EquipmentReportsInfo'

interface IProps {
  isMobile: boolean
  drawerOpen: boolean
  closeOpen: (bool: boolean) => void
  changeReport: (reportName: string, selectedReport: IReportLink) => void
}

interface IState {
  searchBar: string
}

class EquipmentReportFilterContainer extends React.Component<IProps, IState> {
  state: IState = {
    searchBar: '',
  }

  render() {
    return (
      <FloatingButtonDrawer
        isOpen={this.props.drawerOpen}
        closeOpen={this.props.closeOpen}
        isMobile={this.props.isMobile}
        topMargin={160}
        leftMargin={80}
        isLeft
        mobileBottom={56}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <PaddedSearchBar
            value={this.state.searchBar}
            onChange={(newString) => this.setState({ searchBar: newString })}
          />
          <div
            style={{
              overflowY: 'auto',
              height: this.props.isMobile
                ? 'calc(100% - 61px)'
                : 'calc(100vh - 225px)',
              width: '100%',
            }}
          >
            {reports.map((reportCategory) => (
              <div
                key={reportCategory.title}
                style={
                  this.props.isMobile
                    ? {
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }
                    : undefined
                }
              >
                <Divider
                  textAlign={this.props.isMobile ? 'center' : 'left'}
                  light
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {reportCategory.icon ? (
                      <div style={{ paddingRight: 10 }}>
                        <Icon icon={reportCategory.icon} fontSize="medium" />
                      </div>
                    ) : null}
                    <Typography variant="h6">{reportCategory.title}</Typography>
                  </div>
                </Divider>
                <div
                  style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                >
                  {reportCategory.reports
                    .filter((rep) =>
                      rep.title
                        .toLowerCase()
                        .includes(this.state.searchBar.toLowerCase())
                    )
                    .map((report, key) => (
                      <ReportCard
                        title={report.title}
                        key={report.title + key}
                        small
                        noHighlight
                        minWidth={100}
                        onClick={() => {
                          this.props.closeOpen(false)
                          this.props.changeReport(report.title, report)
                        }}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </FloatingButtonDrawer>
    )
  }
}

export default EquipmentReportFilterContainer
