import React from 'react'
import { DrawingManager } from '@react-google-maps/api'
import { ILatLng } from '../../types/maptrac/LatLng'

export enum ERadiusType {
  Polygon = 'polygon',
  Polyline = 'polyline',
  Rectangle = 'rectangle',
  Circle = 'circle',
}

export type TRadiusType =
  | ERadiusType.Polygon
  | ERadiusType.Polyline
  | ERadiusType.Rectangle
  | ERadiusType.Circle

interface IProps {
  strokeColor?: string
  onDrawEnd: (points: ILatLng[], type?: TRadiusType, radius?: number) => void
  editable: boolean
}

class GeofenceDrawingManager extends React.Component<IProps> {
  /** Saves a reference to the polygon and deletes it when unmounted */
  shape:
    | google.maps.Polygon
    | google.maps.Rectangle
    | google.maps.Circle
    | null = null
  /** Saves a reference to the manager to hide the manager once a polygon is drawn */
  dmRef: any = null

  render() {
    const drawingM = new google.maps.drawing.DrawingManager()

    return (
      <DrawingManager
        ref={(dm) => {
          this.dmRef = dm
        }}
        drawingMode={
          this.props.editable ? google.maps.drawing.OverlayType.POLYGON : null
        }
        options={{
          drawingControl: this.props.editable,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.POLYGON,
              google.maps.drawing.OverlayType.RECTANGLE,
              /** Until backend can handle Circles we should leave this section commented out */
              google.maps.drawing.OverlayType.CIRCLE,
            ],
          },
          polylineOptions: this.props.strokeColor
            ? {
                strokeColor: this.props.strokeColor,
              }
            : undefined,
          rectangleOptions: this.props.strokeColor
            ? {
                fillColor: '#00000000',
                strokeColor: this.props.strokeColor,
              }
            : undefined,
          polygonOptions: this.props.strokeColor
            ? {
                fillColor: '#00000000',
                strokeColor: this.props.strokeColor,
              }
            : undefined,
          circleOptions: this.props.strokeColor
            ? {
                fillColor: '#00000000',
                strokeColor: this.props.strokeColor,
              }
            : undefined,
        }}
        onPolygonComplete={(polygon) => {
          /** This will remove the shape when we are done using it */
          this.shape = polygon
          const points = polygon
            .getPath()
            .getArray()
            .map((point) => ({
              lat: parseFloat(point.lat().toFixed(6)),
              lng: parseFloat(point.lng().toFixed(6)),
            }))
          drawingM.setOptions({ drawingControl: false })
          drawingM.setDrawingMode(null)
          this.props.onDrawEnd(points, ERadiusType.Polygon)
        }}
        onRectangleComplete={(rectangle) => {
          /** This will remove the shape when we are done using it */
          this.shape = rectangle
          const bounds = rectangle.getBounds()
          const northeast = bounds.getNorthEast()
          const southwest = bounds.getSouthWest()
          const points = [
            {
              lat: parseFloat(northeast.lat().toFixed(6)),
              lng: parseFloat(northeast.lng().toFixed(6)),
            },
            {
              lat: parseFloat(southwest.lat().toFixed(6)),
              lng: parseFloat(northeast.lng().toFixed(6)),
            },
            {
              lat: parseFloat(southwest.lat().toFixed(6)),
              lng: parseFloat(southwest.lng().toFixed(6)),
            },
            {
              lat: parseFloat(northeast.lat().toFixed(6)),
              lng: parseFloat(southwest.lng().toFixed(6)),
            },
          ]
          drawingM.setOptions({ drawingControl: false })
          drawingM.setDrawingMode(null)
          this.props.onDrawEnd(points, ERadiusType.Rectangle)
        }}
        onCircleComplete={(circle) => {
          /** This will remove the shape when we are done using it */
          this.shape = circle
          /** Prevents the circle from being bigger than whats specified here */
          if (circle.getRadius() > 1500000) {
            circle.setRadius(1500000)
          }
          const bounds = circle.getBounds()
          const center = bounds.getCenter()
          /** This is the radius of the circle in Meters */
          const radius = circle.getRadius()
          const point = [
            {
              lat: parseFloat(center.lat().toFixed(6)),
              lng: parseFloat(center.lng().toFixed(6)),
            },
          ]
          drawingM.setOptions({ drawingControl: false })
          drawingM.setDrawingMode(null)

          // console.log(radius)
          this.props.onDrawEnd(point, ERadiusType.Circle, radius)
        }}
      />
    )
  }

  componentWillUnmount() {
    if (this.shape) {
      this.shape.setMap(null)
    }
    this.shape = null
  }
}

export default GeofenceDrawingManager
