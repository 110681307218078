import React from 'react'
import '../../styles/components/menus/Tab.scss'
import Typography from '@mui/material/Typography'
import Icon from '../Icon'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Theme } from '@mui/material/styles/createTheme'
import { standardIcons } from '../ux/standardIcons'
import { SideNavSection } from './NavBar'
import ButtonBase from '@mui/material/ButtonBase'

const DarkTheme = createTheme({
  palette: {
    action: {
      active: '#fff',
    },
  },
})

const bottomStyles = (theme: Theme) =>
  createStyles({
    root: {
      transition: theme.transitions.create(['background', 'padding-top'], {
        duration: theme.transitions.duration.short,
      }),
      padding: '6px 12px 8px',
      minWidth: 80,
      maxWidth: 168,
      color: theme.palette.grey[50],
      flex: '1',
      '&$selected': {
        paddingTop: 6,
        background: theme.palette.secondary.main,
      },
    },
    selected: {},
    /* Styles applied to the span element that wraps the icon and label. */
    wrapper: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      flexDirection: 'column',
    },
    /* Styles applied to the label's span element. */
    label: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(12),
      opacity: 1,
      transition: 'font-size 0.2s, opacity 0.2s',
      transitionDelay: '0.1s',
      '&$selected': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  })

interface IProps {
  label: string
  icon: keyof typeof standardIcons
  onClick: () => void
}

export const SideTab = (props: IProps) => (
  <SideNavSection className="SideNavSectionContainer" onClick={props.onClick}>
    <ThemeProvider theme={DarkTheme}>
      <Icon icon={props.icon} className="SideNavSectionIcon" />
    </ThemeProvider>
    <Typography
      variant="overline"
      color="inherit"
      className="SideNavSectionText"
    >
      {props.label}
    </Typography>
  </SideNavSection>
)

interface IBottomTabProps extends WithStyles<typeof bottomStyles> {
  label: string
  icon: keyof typeof standardIcons
  selected: boolean
  onClick: () => void
}

export const BottomTab = withStyles(bottomStyles)((props: IBottomTabProps) => {
  return (
    <ButtonBase
      className={props.classes.root}
      focusRipple
      onClick={props.onClick}
      style={props.selected ? { background: '#ff6f00' } : undefined}
    >
      <span className={props.classes.wrapper}>
        <ThemeProvider theme={DarkTheme}>
          <Icon icon={props.icon} color="inherit" />
          <span className={props.classes.label}>{props.label}</span>
        </ThemeProvider>
      </span>
    </ButtonBase>
  )
})
