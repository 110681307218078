import React from 'react'
import Marker, { EMarkerShape, EMarkerCenterPosition } from './Marker'
import { grey, yellow } from '@mui/material/colors'
import { ILatLng } from '../../types/maptrac/LatLng'

export const backgroundColor = grey[800]
export const iconColor = yellow[500]
const siteIconPath =
  'M20.17,20.07l-3.22-4.16a.55.55,0,0,0-.52-.26H14.77a.55.55,0,0,0-.46.24l-.73.93-1.07-.56a.59.59,0,0,0,.11-.42l-.23-4a.77.77,0,0,0,0-.22,1,1,0,0,0,.27.06,1.15,1.15,0,1,0-1.09-1.15,1.17,1.17,0,0,0,.16.57l-.11,0h-3a.38.38,0,0,0-.17,0l0,0-.08.07s0,0-.06.05L7,13.71a.39.39,0,0,0,0,.45l.38.34c.1.09.25,0,.33-.12h0l.17.1.62.33a.46.46,0,0,0,0,.17.92.92,0,0,0-.09.13l-2.3,4c-.15.26-.16.54,0,.63l.48.34c.13.09.35,0,.5-.3l2.21-3.88.43,1.79,0,2.17a.3.3,0,0,0,.28.29l.58,0a.25.25,0,0,0,.28-.25l0-2.32s0,0,0-.06,0,0,0-.08l-.38-1.59a.45.45,0,0,0,.06-.09l2.71,1.43L11,20.11c0,.1,0,.12.11.12h9C20.17,20.22,20.2,20.14,20.17,20.07ZM11.64,15.8l-.87-.46.75-1.68Zm.29-4.38v0l0,0ZM8,13.89l.86-1.62h.8l-.9,2L8.16,14Z'

interface IProps {
  onClick?: () => void
  latLng: ILatLng
  name: string
  isTransparent?: boolean
  isHighlighted?: boolean
}

export default class SiteMarker extends React.Component<IProps> {
  render() {
    const props = this.props
    return (
      <Marker
        title={props.name}
        position={props.latLng}
        centerPosition={EMarkerCenterPosition.Center}
        shape={EMarkerShape.Triangle}
        iconPath={siteIconPath}
        markerColor={backgroundColor}
        iconColor={iconColor}
        onClick={props.onClick}
        opacity={props.isTransparent ? 0.4 : 1}
        scale={props.isHighlighted ? 1.4 : 1}
      />
    )
  }
}
