import React from 'react'
import WidthViewport from '../../components/ux/WidthViewport'
import { WithStyles } from '@mui/styles'
import { createStyles, withStyles } from '@mui/styles'
import ReactHelmet from '../../components/ReactHelmet'
import Toolbar from '../../components/toolbar/Toolbar'
import Button from '../../components/buttons/Button'
import { backend_api, headersAPI } from '../../constants/api'
import { IAllTickets } from '../../types/service/tickets'
import moment from 'moment'
import EllipsisText from '../../components/ux/EllipsisText'
import LoadingOverlay from '../../components/LoadingOverlay'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { colors } from '../../components/ux/roviTheme'
import { ERoutes } from '../../components/routes/CheckRoutes'

const styles = () => createStyles({})

interface IProps extends WithStyles<typeof styles> {
  id: string
}

interface IState {
  tickets?: IAllTickets
}

class ServiceTicketLog extends React.Component<IProps, IState> {
  state: IState = {}

  grabServiceTicketLogInfo() {
    fetch(backend_api + '/service/tickets/all/' + this.props.id, {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        this.setState({ tickets: response })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabServiceTicketLogInfo()
  }

  render() {
    const { tickets } = this.state
    return (
      <WidthViewport>
        {({ isMobile }: { isMobile: boolean }) => (
          <div>
            <ReactHelmet title="Service Log" />
            <Toolbar
              title={`Asset: ${tickets ? tickets.assetId : ''} - Service Log`}
              noXtraBar
            />
            {tickets ? (
              <TableContainer
                id="runtimeReport"
                style={{
                  height:
                    tickets.tickets.length > 1
                      ? `calc(100vh - ${isMobile ? '48px' : '112px'})`
                      : undefined,
                  paddingTop: 48,
                  backgroundColor: colors.paper,
                }}
              >
                <Table stickyHeader style={{ backgroundColor: colors.paper }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 40 }}>#</TableCell>
                      <TableCell style={{ width: 100 }}>Created Date</TableCell>
                      <TableCell style={{ width: 50 }}>Status</TableCell>
                      <TableCell style={{ width: 100 }}>Issue</TableCell>
                      <TableCell style={{ width: 100 }}>Resolution</TableCell>
                      <TableCell style={{ width: 70 }}>Parts cost</TableCell>
                      <TableCell style={{ width: 70 }}>Retail Cost</TableCell>
                      <TableCell style={{ width: 100 }}>Total Labor</TableCell>
                      <TableCell style={{ width: 70 }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tickets.tickets.map((repair) => (
                      <TableRow key={repair.id} hover>
                        <TableCell style={{ width: 40 }}>
                          <a href={ERoutes.ticket + '/' + repair.uuid}>
                            {repair.id}
                          </a>
                        </TableCell>
                        <TableCell style={{ width: 100 }}>
                          {moment(repair.checkInDate).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell style={{ width: 50 }}>
                          {repair.isOpen ? 'open' : 'closed'}
                        </TableCell>
                        <TableCell style={{ width: 100 }}>
                          <EllipsisText width={100} text={repair.issue} />
                        </TableCell>
                        <TableCell style={{ width: 100 }}>
                          <EllipsisText width={100} text={repair.resolution} />
                        </TableCell>
                        <TableCell style={{ width: 70 }}>
                          ${repair.partsCost}
                        </TableCell>
                        <TableCell style={{ width: 70 }}>
                          ${repair.retailCost}
                        </TableCell>
                        <TableCell style={{ width: 100 }}>
                          ${repair.laborCostTotal}
                        </TableCell>
                        <TableCell align="right" style={{ width: 70 }}>
                          <Button
                            color="primary"
                            href={ERoutes.ticket + '/' + repair.uuid}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {tickets.tickets.length <= 0 ? (
                  <Typography
                    style={{
                      display: 'flex',
                      width: '100vw',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 54,
                    }}
                  >
                    No Services
                  </Typography>
                ) : null}
              </TableContainer>
            ) : (
              <LoadingOverlay loading />
            )}
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default withStyles(styles)(ServiceTicketLog)
