import React from 'react'
import ReactHelmet from '../../components/ReactHelmet'
import Typography from '@mui/material/Typography'
import WidthViewport from '../../components/ux/WidthViewport'
import PartsTable from '../../components/serviceComponents/partsComponents/PartsTable'

interface IState {}

interface IProps {
  addDialogOpen: boolean
  onClose: () => void
}

class PartsPage extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <div style={isMobile ? undefined : { margin: 24 }}>
            <ReactHelmet title="Parts" />
            <Typography
              variant="h6"
              style={isMobile ? { margin: 24, paddingTop: 48 } : undefined}
            >
              Service Parts
            </Typography>
            <PartsTable
              isMobile={isMobile}
              addDialogOpen={this.props.addDialogOpen}
              onClose={this.props.onClose}
            />
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default PartsPage
