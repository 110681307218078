import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  brown,
  blueGrey,
} from '@mui/material/colors'

const categoryColors = [
  '#689f38',
  '#7986cb',
  '#808080',
  '#800000',
  '#000080',
  '#800080',
  '#008080',
  '#808000',
  '#d0b000',
  red[500],
  pink[500],
  purple[500],
  deepPurple[500],
  indigo[500],
  blue[500],
  lightBlue['A200'],
  cyan[500],
  teal[500],
  green[500],
  lightGreen[500],
  lime[500],
  yellow[500],
  amber[500],
  orange[500],
  pink['A200'],
  brown[500],
  blueGrey[900],
  cyan['A200'],
]

export default categoryColors
