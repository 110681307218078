import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import MUICheckbox from '@mui/material/Checkbox'
import EllipsisText from './EllipsisText'

interface IProps {
  label?: string
  onChange?: (bool: boolean) => void
  checked?: boolean
  className?: string | undefined
  indeterminate?: boolean | undefined
  color?: 'primary' | 'secondary' | 'default'
  ellipsisWidth?: number | string
}

class CheckContainer extends React.Component<IProps> {
  render() {
    return (
      <FormControlLabel
        control={
          <MUICheckbox
            checked={this.props.checked}
            onChange={(event) =>
              this.props.onChange
                ? this.props.onChange(event.target.checked)
                : undefined
            }
            className={this.props.className}
            indeterminate={this.props.indeterminate}
            color={this.props.color}
          />
        }
        label={
          <EllipsisText
            text={this.props.label}
            width={this.props.ellipsisWidth}
          />
        }
      />
    )
  }
}

export default CheckContainer
