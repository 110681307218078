import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const MaptracIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M23.5,18.3L23.5,18.3l-6.3-1.8l-4.5,3.7l-5.7-0.8l-6.3,2.8l0,0C0,22.4,0,21.7,0,21.7L2.2,9.9 c0.1-0.3,0.3-0.5,0.3-0.5l1.8-0.9c0.1,0.3,0.2,0.5,0.3,0.7L3,10.1l-2,11l5.7-2.5l-0.3-6.5c0.1,0.1,0.2,0.3,0.3,0.4L7,18.6l5.3,0.8 l-1.2-4.9c0.1-0.1,0.2-0.2,0.2-0.3l1.3,5.1l4.2-3.4l-2.9-5.4c0.1-0.1,0.1-0.2,0.2-0.3l3,5.5l5.5,1.5l-6.4-9.3L15,7.7 c0-0.3,0.1-0.5,0.1-0.8l1,0.2c0,0,0.5,0.1,0.7,0.4l0,0l7.1,10.3C23.9,17.8,24.3,18.4,23.5,18.3z M11.4,13.1c1.1-1.4,2.1-2.8,2.8-4.4 c0.5-1,0.6-2,0.3-3.1c-0.6-2.5-3.1-4.2-5.7-3.8C6.4,2.2,4.5,4.4,4.5,6.8c0,0.8,0.3,1.5,0.6,2.2c0.6,1.3,1.4,2.4,2.2,3.5 c0.7,0.9,1.5,1.9,2.3,2.9C10.2,14.6,10.8,13.8,11.4,13.1z M9.6,9.5C8,9.5,6.7,8.2,6.8,6.7c0-1.6,1.3-2.8,2.8-2.8 c1.5,0,2.8,1.3,2.8,2.8C12.4,8.3,11.1,9.5,9.6,9.5z" />,
  'Maptrac'
)

export default MaptracIcon
