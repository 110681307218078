import React from 'react'
import '../../styles/pages/ResetPassword.scss'
import TextField from '../../components/textBoxs/TextField'
import Button from '../../components/buttons/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material/styles/createTheme'
import { ThemeProvider } from '@mui/material/styles'
import { ERoutes } from '../../components/routes/CheckRoutes'
import { signOut } from '../../components/signOut'
import { Auth } from 'aws-amplify'
import { RegexEmailCheck } from '../../utility/regex'
import SnackBar from '../../components/ux/SnackBar'

interface IState {
  emailError: string
  emailSent?: string
}

interface IProps {
  DarkTheme: (theme: Theme) => Theme
  submitForgotRequest: () => void
  email: string
  changeEmail: (email: string) => void
  isMobile: boolean
}

class ForgotPage extends React.Component<IProps, IState> {
  state: IState = {
    emailError: '',
    emailSent: undefined,
  }

  checkEmailValidation(email: string) {
    this.setState({
      emailError: RegexEmailCheck.test(email) ? '' : 'Invalid email',
    })
    this.props.changeEmail(email)
  }

  async submitEmail(email: string) {
    this.setState({
      emailError: '',
    })
    try {
      await Auth.forgotPassword(email)
      this.setState({
        emailSent: 'Email sent successfully',
      })
      this.props.submitForgotRequest() // must be last otherwise causes memoryleak error on localhost
    } catch (err) {
      this.setState({
        emailError: err.message,
      })
    }
  }

  render() {
    return (
      <div className="forgotContainer">
        <SnackBar
          variant="success"
          message={this.state.emailSent}
          onClose={() => this.setState({ emailSent: undefined })}
        />
        <ThemeProvider theme={this.props.DarkTheme}>
          <Paper className="forgotInner" square>
            <div className="forgotTitle">
              <Typography variant="h5" align="center">
                Forgot Your Password?
              </Typography>
              <Typography
                variant="body2"
                align="center"
                className="forgotInstructions"
              >
                Please enter the email that you used to register and we will
                help you reset your password.
              </Typography>
            </div>
            <TextField
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              error={!!this.state.emailError}
              helperText={this.state.emailError}
              width="100%"
              formClassName="forgotEmailField"
              type="email"
              onEnter={() => this.submitEmail(this.props.email)}
              onChange={(event) =>
                this.checkEmailValidation(event.target.value)
              }
            />
            <div className="submitEmailContainer">
              <a
                id="ForgotPasswordReturnToLoginButton"
                href={ERoutes.login}
                className="returnToLogin"
                onClick={() => signOut()}
              >
                Login?
              </a>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.submitEmail(this.props.email)}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </ThemeProvider>
      </div>
    )
  }
}
export default ForgotPage
