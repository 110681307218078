import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { SelectChangeEvent } from '@mui/material'
import { IGeofenceEntity } from 'src/types/maptrac/playback'
import {
  IConditionRule,
  StringToMetric,
  MetricToString,
  Metric,
  MetricOperations,
  Operation,
  MetricValueTypes,
  OperationStrings,
} from 'src/constants/flagRules'
import { RoviLog } from 'src/utility/roviLog'
import NumberField from 'src/components/textBoxs/NumberField'
import Percentage from 'src/types/jquants/Percentage'
import ElectricPotential from 'src/types/jquants/ElectricPotential'
import Velocity from 'src/types/jquants/Velocity'
import Temperature from 'src/types/jquants/Temperature'
import Length from 'src/types/jquants/Length'
import Pressure from 'src/types/jquants/Pressure'
import Duration from 'src/types/jquants/Duration'
import Margin from 'src/components/ux/Margin'
import IconButton from 'src/components/buttons/IconButton'

interface IConditionSelectorProps extends WithStyles<typeof styles> {
  condition: IConditionRule
  onChange: (condition: Partial<IConditionRule>) => void
  onRemove?: () => void
  branchGeofenceIds: string[]
  geofences: Record<string, IGeofenceEntity>
  showLastDeviceUpdate: boolean
  useMetric?: boolean
}

const ConditionSelector = (props: IConditionSelectorProps) => {
  const metricOperations = MetricOperations[props.condition.metric]

  const onChangeMetric = (e: SelectChangeEvent) => {
    let { operation, value } = props.condition
    const newMetric = StringToMetric[e.target.value]
    if (newMetric === props.condition.metric) return

    const operationsForMetric = MetricOperations[newMetric]

    if (!operationsForMetric.includes(operation)) {
      operation = operationsForMetric[0]
    }

    if (
      onlyOperationIsEquals(operationsForMetric) &&
      newMetric !== Metric.GPS
    ) {
      if (
        typeof MetricValueTypes[newMetric] === 'string' &&
        value !== 'on' &&
        value !== 'off'
      ) {
        value = 'on'
      } else if (
        typeof MetricValueTypes[newMetric] === 'boolean' &&
        value !== true &&
        value !== false
      ) {
        value = true
      }
    }

    if (newMetric === Metric.GPS) {
      value = 'asset.boundary'
    } else if (newMetric === Metric.LastDeviceUpdate) {
      value = Duration.ofHours(3)
    }

    props.onChange({ metric: newMetric, operation, value } as IConditionRule)
  }

  const onChangeOperation = (e: SelectChangeEvent) => {
    props.onChange({ operation: e.target.value as Operation })
  }

  const onChangeValue = (value: any) => {
    props.onChange({ value })
  }

  const onlyOperationIsEquals = (operations: Operation[]) =>
    operations.length === 1 && operations[0] === '='

  return (
    <div className={props.classes.container}>
      <div className={props.classes.inputRow}>
        <Select
          value={MetricToString[props.condition.metric]}
          className={props.classes.metricSelect}
          onChange={onChangeMetric}
          variant="standard"
        >
          {Object.keys(StringToMetric)
            .filter((metric) =>
              !props.showLastDeviceUpdate
                ? StringToMetric[metric] !== Metric.LastDeviceUpdate
                : true
            )
            .map((metric) => {
              return (
                <MenuItem key={metric} value={StringToMetric[metric]}>
                  {metric}
                </MenuItem>
              )
            })}
        </Select>

        <Margin width={10} />

        {props.condition.metric === Metric.LastDeviceUpdate && (
          <>
            <Select
              className={props.classes.operationSelect}
              value="more than"
              variant="standard"
              disabled
            >
              <MenuItem value="more than">more than</MenuItem>
            </Select>
            <Margin width={10} />
          </>
        )}

        {metricOperations &&
          metricOperations.length > 1 &&
          props.condition.metric !== Metric.LastDeviceUpdate && (
            <>
              <Select
                className={props.classes.operationSelect}
                value={props.condition.operation}
                variant="standard"
                onChange={onChangeOperation}
              >
                {metricOperations.map((op) => (
                  <MenuItem key={op} value={op}>
                    {OperationStrings[op]}
                  </MenuItem>
                ))}
              </Select>
              <Margin width={10} />
            </>
          )}

        {getValueComp(
          props.condition,
          onChangeValue,
          props.classes.valueNumberField,
          props.branchGeofenceIds,
          props.geofences,
          props.useMetric || false
        )}
      </div>

      {props.onRemove && (
        <>
          <Margin width={10} />
          <IconButton variant="row" onClick={props.onRemove} icon="delete" />
        </>
      )}
    </div>
  )
}

const styles = createStyles({
  metricSelect: {
    width: 210,
  },
  operationSelect: {
    width: 110,
  },
  valueNumberField: {
    width: 100,
    margin: 0,
    height: 58,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '95%',
  },
})

const getValueComp = (
  condition: IConditionRule,
  onChange: (val: any) => void,
  className: string,
  branchGeofenceIds: string[],
  geofenceLookup: Record<string, IGeofenceEntity>,
  useMetric: boolean
) => {
  switch (condition.metric) {
    case Metric.InMotion:
      return (
        <Select
          value={condition.value}
          onChange={(e) => {
            e.target.value === 'true' || e.target.value === 'false'
              ? onChange(e.target.value === 'true' ? true : false)
              : RoviLog.info(
                  `Flag InMotion value was updated to be a non boolean: ${e.target.value}`,
                  'fatal'
                )
          }}
          variant="standard"
        >
          <MenuItem value="true">Detected</MenuItem>
          <MenuItem value="false">Not Detected</MenuItem>
        </Select>
      )
    case Metric.Ignition:
    case Metric.Light1:
    case Metric.Light2:
    case Metric.Light3:
    case Metric.Light4:
    case Metric.PTO1:
    case Metric.PTO2:
      return (
        <Select
          value={condition.value}
          onChange={(e) => onChange(e.target.value)}
          variant="standard"
        >
          <MenuItem value="on">is ON</MenuItem>
          <MenuItem value="off">is OFF</MenuItem>
        </Select>
      )
    case Metric.Fuel:
    case Metric.DeviceBatteryLevel:
      return (
        <NumberField
          formClassName={className}
          endAdornment={Percentage.Unit.Percent}
          value={Percentage.toPercent(condition.value)}
          onChange={(val) => {
            if (!val) {
              val = 0
            } else {
              val = Math.max(Math.min(val, 100), 0)
            }
            onChange(Percentage.ofPercent(val))
          }}
        />
      )
    case Metric.Battery:
    case Metric.SolarBattery1:
    case Metric.SolarBattery2:
      return (
        <NumberField
          formClassName={className}
          endAdornment={ElectricPotential.Unit.Volts}
          value={ElectricPotential.toVolts(condition.value)}
          onChange={(val) => {
            if (!val || val < 0) {
              val = 0
            }
            onChange(ElectricPotential.ofVolts(val))
          }}
        />
      )
    case Metric.Speed:
      return (
        <NumberField
          formClassName={className}
          endAdornment={
            useMetric
              ? Velocity.Unit.KilometersPerHour
              : Velocity.Unit.MilesPerHour
          }
          value={Math.round(
            useMetric
              ? Velocity.toKilometersPerHour(condition.value)
              : Velocity.toMilesPerHour(condition.value)
          )}
          onChange={(val) => {
            if (!val || val < 0) {
              val = 0
            }
            onChange(
              useMetric
                ? Velocity.ofKilometersPerHour(val)
                : Velocity.ofMilesPerHour(val)
            )
          }}
        />
      )
    case Metric.CabTemp:
      return (
        <NumberField
          formClassName={className}
          endAdornment={
            useMetric ? Temperature.Unit.Celsius : Temperature.Unit.Fahrenheit
          }
          value={Math.round(
            useMetric
              ? Temperature.toCelsius(condition.value)
              : Temperature.toFahrenheit(condition.value)
          )}
          onChange={(val) => {
            val = val || 0
            onChange(
              useMetric
                ? Temperature.ofCelsius(val)
                : Temperature.ofFahrenheit(val)
            )
          }}
        />
      )
    case Metric.Altitude:
      return (
        <NumberField
          formClassName={className}
          endAdornment={useMetric ? Length.Unit.Meters : Length.Unit.Feet}
          value={Math.round(
            useMetric
              ? Length.toMeters(condition.value)
              : Length.toFeet(condition.value)
          )}
          onChange={(val) => {
            val = val || 0
            onChange(useMetric ? Length.ofMeters(val) : Length.ofFeet(val))
          }}
        />
      )
    case Metric.CompressorPressure:
      return (
        <NumberField
          formClassName={className}
          endAdornment={Pressure.Unit.PSI}
          value={Pressure.toPsi(condition.value)}
          onChange={(val) => {
            if (!val || val < 0) {
              val = 0
            }
            onChange(Pressure.ofPsi(val))
          }}
        />
      )
    case Metric.GPS:
      // includes current value in case geofence got deleted
      // could be asset.boundary - doesn't include if not geofence
      const currentGeofenceId = condition.value.split(':')[1]
      const ids = [currentGeofenceId, ...branchGeofenceIds]
      const geofences = ids
        .map((id) => {
          return geofenceLookup[id]
        })
        .filter((fence) => !!fence)

      return (
        <Select
          variant="standard"
          value={condition.value}
          onChange={(ev) => onChange(ev.target.value)}
        >
          <MenuItem value="asset.boundary">{"Asset's Geofence"}</MenuItem>
          {geofences.map((geofence) => (
            <MenuItem key={geofence.id} value={`geofence:${geofence.id}`}>
              {geofence.meta.name}
            </MenuItem>
          ))}
        </Select>
      )
    case Metric.LastDeviceUpdate:
      return (
        <Select
          variant="standard"
          value={condition.value}
          onChange={(ev) => onChange(ev.target.value)}
        >
          <MenuItem value={Duration.ofHours(3) as any as number}>
            3 hours ago
          </MenuItem>
          <MenuItem value={Duration.ofDays(1) as any as number}>
            1 day ago
          </MenuItem>
          <MenuItem value={Duration.ofDays(7) as any as number}>
            1 week ago
          </MenuItem>
          <MenuItem value={Duration.ofDays(14) as any as number}>
            2 weeks ago
          </MenuItem>
        </Select>
      )
  }
}

export default withStyles(styles)(ConditionSelector)
