import React from 'react'
import IconButton from '../buttons/IconButton'
import EllipsisText from '../ux/EllipsisText'

interface IAvailabilityControlProps {
  clientDisabled: boolean
  agreementUrl: string
  clientName: string
  siteName: string
  siteUrl: string
  clientUrl: string
}
const AvailabilityControl: React.FC<IAvailabilityControlProps> = (props) => (
  <div>
    {
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxHeight: 18,
          whiteSpace: 'nowrap',
        }}
      >
        <IconButton
          variant="row"
          title={
            props.clientDisabled
              ? 'Client feature disabled on this account'
              : undefined
          }
          tooltip={!props.clientDisabled ? 'Agreement' : undefined}
          href={props.agreementUrl}
          disabled={props.clientDisabled}
          icon="description"
          color="primary"
        />
        {props.clientDisabled ? (
          <>
            <EllipsisText
              text={props.clientName}
              style={{ maxWidth: '150px' }}
            />
            <div style={{ margin: '0 4px' }}>-</div>
            <EllipsisText text={props.siteName} style={{ maxWidth: '150px' }} />
          </>
        ) : (
          <>
            <a href={props.clientUrl}>
              <EllipsisText
                text={props.clientName}
                style={{ maxWidth: '150px' }}
              />
            </a>
            <div style={{ margin: '0 4px' }}>-</div>
            <a href={props.siteUrl}>
              <EllipsisText
                text={props.siteName}
                style={{ maxWidth: '150px' }}
              />
            </a>
          </>
        )}
      </div>
    }
  </div>
)

export default AvailabilityControl
