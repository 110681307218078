import React from 'react'
import '../../styles/pages/equipment/EquipmentReports.scss'
import { CompBar } from '../../components/toolbar/Toolbar'
import EquipmentReportFilterContainer from '../../components/equipmentReportComponents/EquipmentReportFilterContainer'
import Button from '../../components/buttons/Button'
import { IReportLink } from '../../components/equipmentReportComponents/EquipmentReportsInfo'
import IconButton from '../../components/buttons/IconButton'
import printJS from 'print-js'
import moment from 'moment'
import RangeDatePicker from '../../components/datePickers/RangeDatePicker'
import HashStateManager from '../../rx-js/HashStateManager'
import { IEquipmentHash } from '../../constants/equipmentHashControllers'
import { reports } from '../../components/equipmentReportComponents/EquipmentReportsInfo'

function CheckDefaultAssetReports() {
  return localStorage.getItem('assetReportsHeldOpen') === 'false' ? false : true
}

interface IProps {
  isMobile: boolean
  height: number
  width: number
  hash: HashStateManager<IEquipmentHash>
}

interface IState {
  drawerOpen: boolean
  selectedReportName: string
  selectedReport?: IReportLink
  selectedDate: Array<Date | null>
}

class EquipmentReports extends React.Component<IProps, IState> {
  state: IState = {
    drawerOpen: CheckDefaultAssetReports(),
    selectedReportName: 'Select Report',
    selectedDate: [
      this.props.hash.value.dateStart
        ? moment(this.props.hash.value.dateStart).toDate()
        : moment().subtract(14, 'd').toDate(),
      this.props.hash.value.dateEnd
        ? moment(this.props.hash.value.dateEnd).toDate()
        : moment().toDate(),
    ],
  }

  SetDefaultAssetReports(bool: boolean) {
    localStorage.setItem('assetReportsHeldOpen', bool ? 'true' : 'false')
  }

  componentDidMount() {
    const displayedCategoryReport = reports.find((categoryReport) =>
      categoryReport.reports.find(
        (report) => report.value === this.props.hash.value.report
      )
    )
    const displayedReport = displayedCategoryReport
      ? displayedCategoryReport.reports.find(
          (disReport) => disReport.value === this.props.hash.value.report
        )
      : undefined

    this.setState({
      selectedReport: displayedReport,
      selectedReportName: displayedReport
        ? displayedReport.title
        : 'Select Report',
    })
  }

  render() {
    return (
      <div className="equipmentReportOuterContainer">
        <CompBar scrollBackgroundColor>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Button
                onClick={() => {
                  this.SetDefaultAssetReports(!this.state.drawerOpen)
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }}
              >
                {this.state.selectedReportName}
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {this.props.isMobile ? null : (
                <div style={{ paddingRight: 15 }}>
                  Showing the previous{' '}
                  {this.state.selectedDate[0] && this.state.selectedDate[1]
                    ? parseInt(
                        moment
                          .duration(
                            moment(this.state.selectedDate[1])
                              .endOf('day')
                              .diff(
                                moment(this.state.selectedDate[0]).startOf(
                                  'day'
                                )
                              )
                          )
                          .asDays()
                          .toString()
                      )
                    : 7}{' '}
                  days since
                </div>
              )}
              <RangeDatePicker
                currentDate={this.state.selectedDate}
                onAccept={(date: Array<Date | null>) => {
                  this.setState({ selectedDate: date })
                  this.props.hash.set({
                    ...this.props.hash.value,
                    dateStart:
                      moment(date[0]).format('YYYY-MM-DD') ===
                      moment().subtract(14, 'd').format('YYYY-MM-DD')
                        ? undefined
                        : moment(date[0]).format('YYYY-MM-DD'),
                    dateEnd:
                      moment(date[1]).format('YYYY-MM-DD') ===
                      moment().format('YYYY-MM-DD')
                        ? undefined
                        : date[1] == null
                        ? moment(date[1]).format('YYYY-MM-DD')
                        : moment(date[0]).format('YYYY-MM-DD'),
                  })
                  console.log(date[1])
                }}
                disableFuture
                showToolbar
              />
              <IconButton
                icon="print"
                variant="navbar"
                tooltip="Print/PDF"
                onClick={() => {
                  printJS({
                    printable: 'assetReportPrint',
                    type: 'html',
                    targetStyles: ['*'],
                    honorColor: true,
                    style: '@page { size: Letter landscape; }',
                  })
                }}
              />
            </div>
          </div>
        </CompBar>
        <div
          id="assetReportPrint"
          style={{
            width: this.props.width - 80,
            height: this.props.height - 160,
          }}
        >
          {this.state.selectedReport
            ? this.state.selectedReport.reportPage
              ? this.state.selectedReport.reportPage(
                  this.props.width - 80,
                  this.props.height - 160,
                  this.props.isMobile,
                  '0860536046452159', // esn
                  'a18fa347-8d56-4e26-8d26-287c4be86770', // tenant
                  this.state.selectedDate
                )
              : null
            : null}
        </div>
        <EquipmentReportFilterContainer
          isMobile={this.props.isMobile}
          drawerOpen={this.state.drawerOpen}
          closeOpen={(bool: boolean) => {
            this.SetDefaultAssetReports(bool)
            this.setState({ drawerOpen: bool })
          }}
          changeReport={(reportName: string, selectedReport: IReportLink) => {
            this.setState({
              selectedReportName: reportName,
              selectedReport: selectedReport,
            })
            this.props.hash.set({
              ...this.props.hash.value,
              report: selectedReport.value,
              dateStart:
                moment(this.state.selectedDate[0]).format('YYYY-MM-DD') ===
                moment().subtract(14, 'd').format('YYYY-MM-DD')
                  ? undefined
                  : moment(this.state.selectedDate[0]).format('YYYY-MM-DD'),
              dateEnd:
                moment(this.state.selectedDate[1]).format('YYYY-MM-DD') ===
                moment().format('YYYY-MM-DD')
                  ? undefined
                  : moment(this.state.selectedDate[1]).format('YYYY-MM-DD'),
            })
          }}
        />
      </div>
    )
  }
}

export default EquipmentReports
