import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import { backend_api, headersAPI } from '../../../constants/api'
import Select, { ISelectValues } from '../../ux/Select'
import TextField from '../../textBoxs/TextField'
import InfoHover from '../../buttons/InfoHover'
import Button from '../../buttons/Button'
import { IBranch } from '../../../types/branch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { IAssetSnapshotRow } from '../AssetsTabTypes'

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    dialogContent: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    transferingAsset: {
      height: 200,
      maxHeight: 200,
      overflowY: 'auto',
      width: 250,
    },
  })

interface ISelectedBranches {
  name: string
  branch: string
  id: string
}

interface IProps extends WithStyles<typeof styles> {
  open: boolean
  onClose: () => void
  selectedAssets: string[]
  allAssets: IAssetSnapshotRow[]
  isMobile: boolean
}

interface IState {
  comment: string
  branchesLoaded: IBranch[]
  branchSelectedValue: ISelectValues | string
}

class TransferEquipmentDialog extends React.Component<IProps, IState> {
  state: IState = {
    comment: '',
    branchesLoaded: [],
    branchSelectedValue: '',
  }

  handleClose = () => {
    this.props.onClose()
  }

  handleSubmit = () => {
    this.props.onClose()
  }

  sortbranches(branches: IBranch[]) {
    let newBranchArr: ISelectValues[] = []
    branches.forEach((branch) =>
      newBranchArr.push({ value: branch.id, label: branch.name })
    )
    return newBranchArr
  }

  loadBranches() {
    fetch(backend_api + '/branch/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => this.setState({ branchesLoaded: response }))
      .catch(console.error)
  }

  loadTableOfAssets(things: IAssetSnapshotRow[], selected: string[]) {
    let newAssets: ISelectedBranches[] = []
    things.forEach((thing) => {
      if (selected.includes(thing.id)) {
        newAssets.push({
          name: thing.name,
          branch: 'Salt Lake City',
          id: thing.id,
        })
      }
    })
    return newAssets
  }

  onSubmit() {
    console.log({})
  }

  isSaveButtonDisabled() {
    return !(!!this.state.branchSelectedValue && !!this.state.comment)
  }

  componentDidMount() {
    this.loadBranches()
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.handleClose} fullWidth>
        <DialogTitle>
          <div className={this.props.classes.dialogTitle}>
            <div>Transfer</div>
            <InfoHover>
              You are transfering assets from one branch to another.
            </InfoHover>
          </div>
        </DialogTitle>
        <DialogContent className={this.props.classes.dialogContent}>
          <div className={this.props.classes.fieldContainer}>
            <Select
              label="Move to a new Branch"
              selectedValue={this.state.branchSelectedValue}
              values={this.sortbranches(this.state.branchesLoaded)}
              onChange={(event) =>
                this.setState({
                  branchSelectedValue: event.target.value as ISelectValues,
                })
              }
              required
              className={this.props.classes.TextField}
            />
            <TextField
              label="Comment on why"
              required
              className={this.props.classes.TextField}
              value={this.state.comment}
              onChange={(event) =>
                this.setState({ comment: event.target.value })
              }
              multiline
              rows={4}
            />
          </div>
          <div className={this.props.classes.transferingAsset}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Asset (
                    {
                      this.loadTableOfAssets(
                        this.props.allAssets,
                        this.props.selectedAssets
                      ).length
                    }
                    )
                  </TableCell>
                  <TableCell>Current Branch</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.loadTableOfAssets(
                  this.props.allAssets,
                  this.props.selectedAssets
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.branch}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Transfer
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(TransferEquipmentDialog)
