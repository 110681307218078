import React from 'react'
import CategoryIcon from './CategoryIcon'
import Avatar from '@mui/material/Avatar'
import { TCategoryIcons } from '../../types/category'

const defaultSize = 25

interface IProps {
  color: string
  icon: TCategoryIcons
  style?: React.CSSProperties
  size?: number
}

const CategoryAvatar = (props: IProps) => (
  <Avatar
    style={{
      background: props.color,
      height: props.size ? props.size : defaultSize,
      width: props.size ? props.size : defaultSize,
      ...props.style,
    }}
  >
    <CategoryIcon
      icon={props.icon}
      style={{
        color: 'white',
        height: (props.size ? props.size : defaultSize) * 0.6,
        width: (props.size ? props.size : defaultSize) * 0.6,
      }}
    />
  </Avatar>
)

export default CategoryAvatar
