import qs from 'qs'
import {
  IFilters_Thing,
  IFilters_Person,
  IFilters_Place,
} from '../types/inventory'

/**
 * Inventory Page Hash Information
 */

export enum EInventoryTabs {
  people = 'person',
  place = 'place',
  thing = 'thing',
}

export type IInventoryTabs =
  | EInventoryTabs.people
  | EInventoryTabs.place
  | EInventoryTabs.thing

export interface IInventoryHash {
  tab: IInventoryTabs
  filtersPerson?: Partial<IFilters_Person>
  filtersPlace?: Partial<IFilters_Place>
  filtersThing?: Partial<IFilters_Thing>
}

export const InventoryHashFromString = (params: string): IInventoryHash => {
  return qs.parse(params) as unknown as IInventoryHash
}

export const InventoryHashToString = (params: IInventoryHash) => {
  return qs.stringify(params)
}

export const InventoryHashValidate = (hashData: any): boolean => {
  if (hashData.tab) {
    switch (hashData.tab) {
      case EInventoryTabs.people:
      case EInventoryTabs.place:
        return true
      case EInventoryTabs.thing:
        if (hashData.filtersThing) {
          console.log('Filters')
        } else return true
        return false
      default:
        return false
    }
  } else return false
}
