import React from 'react'
import '../../styles/pages/service/TasksPage.scss'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import blue from '@mui/material/colors/blue'
import orange from '@mui/material/colors/orange'
import red from '@mui/material/colors/red'

interface IState {}

interface IProps {
  totalTaskTypes: {
    low: number
    med: number
    high: number
  }
}

class TasksOverviewBoxes extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <div className="tasksOverviewContainer">
        <Paper className="taskOverviewPapers">
          <Typography variant="h5" className="taskOverviewTitles">
            Scheduled Tasks
          </Typography>
          <div className="taskOverviewPreview">
            <Typography variant="h3" style={{ color: blue[500] }}>
              {this.props.totalTaskTypes.low}
            </Typography>
            <Typography variant="caption">
              Asset{this.props.totalTaskTypes.low === 1 ? null : 's'}
            </Typography>
          </div>
        </Paper>
        <Paper className="taskOverviewPapers">
          <Typography variant="h5" className="taskOverviewTitles">
            Upcoming Tasks
          </Typography>
          <div className="taskOverviewPreview">
            <Typography variant="h3" style={{ color: orange[500] }}>
              {this.props.totalTaskTypes.med}
            </Typography>
            <Typography variant="caption">
              Asset{this.props.totalTaskTypes.med === 1 ? null : 's'}
            </Typography>
          </div>
        </Paper>
        <Paper className="taskOverviewPapers">
          <Typography variant="h5" className="taskOverviewTitles">
            Overdue Tasks
          </Typography>
          <div className="taskOverviewPreview">
            <Typography variant="h3" style={{ color: red[500] }}>
              {this.props.totalTaskTypes.high}
            </Typography>
            <Typography variant="caption">
              Asset{this.props.totalTaskTypes.high === 1 ? null : 's'}
            </Typography>
          </div>
        </Paper>
      </div>
    )
  }
}

export default TasksOverviewBoxes
