import React from 'react'
import { onlyUpdateForKeys } from 'recompose'
import MaptracOverlay from '../../MaptracOverlay'
import { orange } from '@mui/material/colors'
import TagsPopover from './TagsPopover'

interface IProps {
  lat: number
  lng: number
  length: number
  onClick: (id: string) => void
  isTransparent: boolean
  isHighlighted: boolean
  tags: {
    id: string
    name: string
  }[]
}

const height = 42
const width = 42

const ClusterTag: React.FunctionComponent<IProps> = (props) => {
  const scale = props.isHighlighted ? 1 : 0.8
  const opacity = props.isTransparent ? 0.4 : 1

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickTag = (id: string) => {
    handleClose()
    props.onClick(id)
  }

  return (
    <>
      <MaptracOverlay
        lat={props.lat}
        lng={props.lng}
        zIndex={props.isHighlighted ? 8 : 7}
      >
        <svg
          onClick={handleClick}
          style={{
            transform: 'translateX(-50%)',
            height: height * scale,
            width: width * scale,
            opacity,
            cursor: 'pointer',
          }}
        >
          <circle
            cx="50%"
            cy="50%"
            r="50%"
            fill={orange[500]}
            opacity={opacity * 0.25}
          />
          <circle cx="50%" cy="50%" r="28%" fill={orange[500]} />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="white"
            fontSize={10}
          >
            {props.length}
          </text>
        </svg>
      </MaptracOverlay>
      <TagsPopover
        anchorEl={anchorEl}
        onClose={handleClose}
        tags={props.tags}
        onClick={handleClickTag}
      />
    </>
  )
}

export default onlyUpdateForKeys([
  'lat',
  'lng',
  'isHighlighted',
  'isTransparent',
])(ClusterTag)
