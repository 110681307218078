import React from 'react'
import { WithStyles } from '@mui/styles'
import {
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
} from '@mui/material'
import { createStyles, withStyles } from '@mui/styles'
import moment from 'moment'
import { INote } from '../../../types/service/tickets'

const styles = () =>
  createStyles({
    notesTableContainer: {
      width: '100%',
      maxWidth: 600,
      flex: 1,
    },
    tableCell: { flex: 1, maxWidth: 200 },
    costs: { minWidth: 100 },
    title: { paddingLeft: 20, paddingTop: 10 },
  })

interface IProps extends WithStyles<typeof styles> {
  notes: INote[]
}

interface IState {}

class PartsTable extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    const classes = this.props.classes
    return (
      <TableContainer component={Paper} className={classes.notesTableContainer}>
        <Typography variant="h6" className={classes.title}>
          Notes
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 130, flex: 5 }}>Content</TableCell>
              <TableCell style={{ minWidth: 100, flex: 1 }}>Date</TableCell>
              <TableCell style={{ minWidth: 100, flex: 1 }}>User</TableCell>
            </TableRow>
          </TableHead>
          {this.props.notes.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} style={{ flex: 1 }}>
                  - No Notes -
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {this.props.notes.map((note, i) => (
                <TableRow key={i}>
                  <TableCell style={{ minWidth: 130, flex: 5 }}>
                    {note.content}
                  </TableCell>
                  <TableCell style={{ minWidth: 100, flex: 1 }}>
                    {moment(note.date).format('ll')}
                  </TableCell>
                  <TableCell style={{ minWidth: 100, flex: 1 }}>
                    {note.user}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    )
  }
}

export default withStyles(styles)(PartsTable)
