import React from 'react'
import Typography from '@mui/material/Typography'
import Icon from '../Icon'
import { IStandardIcons } from '../ux/standardIcons'

interface IProps {
  title?: string
  icon?: IStandardIcons
}

class ReportCategoryTitle extends React.Component<IProps> {
  state = {
    hovered: false,
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          padding: this.props.icon ? undefined : '0 47.5px',
        }}
      >
        {this.props.icon ? (
          <div style={{ paddingRight: 15 }}>
            <Icon icon={this.props.icon} fontSize="large" />
          </div>
        ) : null}
        <Typography variant="h4" style={{ fontSize: 25 }}>
          {this.props.title}
        </Typography>
      </div>
    )
  }
}

export default ReportCategoryTitle
