import React from 'react'
import { colors } from '../ux/roviTheme'
import Icon from '../Icon'

interface IProps {
  strength: number
}

const SignalStrengthIcon: React.SFC<IProps> = (props) => {
  if (props.strength === 0) {
    return <Icon icon="noSignal" color="disabled" />
  } else {
    const filledStyle = (bar: number) =>
      props.strength > bar
        ? { fill: colors.green }
        : { fill: colors.iconColor, fillOpacity: 0.1 }
    return (
      <svg width={25} height={20} viewBox="0 0 25 20">
        <rect
          style={filledStyle(0)}
          rx={1}
          ry={1}
          x={0}
          y={17}
          width={4}
          height={3}
        />
        <rect
          style={filledStyle(1)}
          rx={1}
          ry={1}
          x={5}
          y={15}
          width={4}
          height={5}
        />
        <rect
          style={filledStyle(2)}
          rx={1}
          ry={1}
          x={10}
          y={10}
          width={4}
          height={10}
        />
        <rect
          style={filledStyle(3)}
          rx={1}
          ry={1}
          x={15}
          y={5}
          width={4}
          height={15}
        />
        <rect
          style={filledStyle(4)}
          rx={1}
          ry={1}
          x={20}
          y={0}
          width={4}
          height={20}
        />
      </svg>
    )
  }
}

export default SignalStrengthIcon
