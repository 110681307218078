import React, { Suspense } from 'react'
import Toolbar from '../components/toolbar/Toolbar'
import { getHash } from '../constants/hashGrabber'
import LoadingOverlay from '../components/LoadingOverlay'
import NoAccessPage from './NoAccessPage'
import SideAppBar from '../components/sideAppBar/SideAppBar'
import HashStateManager from '../rx-js/HashStateManager'
import {
  EquipmentHashFromString,
  EquipmentHashToString,
  IEquipmentHash,
  TEquipmentTabs,
  EEquipmentTabs,
} from '../constants/equipmentHashControllers'
import WidthViewport from '../components/ux/WidthViewport'
import EquipmentProfile from './equipment/EquipmentProfile'
import { IEquipmentProfile } from '../types/equipment/equipmentProfile'
import EquipmentMonitor from './equipment/EquipmentMonitor'
import EquipmentMaintenance from './equipment/EquipmentMaintenance'
import EquipmentReports from './equipment/EquipmentReports'

// TODO: JA: When React implements lazy loading to server rendering we should reimplement this then
// Fake DATA
// This should be replaced with a call that is loaded before user can view the data
const allowedEquipment = [
  '56',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
]

/**
 * Main Array of Tabs
 */
const equipmentTabs = [
  {
    value: EEquipmentTabs.profile,
    label: 'Profile',
    icon: 'profile',
  },
  {
    value: EEquipmentTabs.maintenance,
    label: 'Service', // Maintenance
    icon: 'maintenance',
  },
  {
    value: 'monitor',
    label: 'Timeline', // Monitor alain wanted this changed, so i left this note so we remember what it originally was used for
    icon: 'timeline', // monitor
  },
  {
    value: EEquipmentTabs.reports,
    label: 'Reports',
    icon: 'reports',
  },
]

interface IState {
  selectedTab: string | EEquipmentTabs
  id: any
  equipmentProfile?: IEquipmentProfile
}

interface IProps {
  id: any
}

class Equipment extends React.Component<IProps, IState> {
  state: IState = {
    selectedTab: EEquipmentTabs.profile,
    id: '',
  }

  hash: HashStateManager<IEquipmentHash> = new HashStateManager(
    EquipmentHashFromString,
    EquipmentHashToString
  )

  setTab = (tab: TEquipmentTabs, clearReports?: boolean) => {
    this.setState({ selectedTab: tab })
    this.hash.set({
      ...this.hash.value,
      tab,
    })
    if (clearReports) {
      this.hash.set({
        ...this.hash.value,
        report: undefined, // clears the current selected report
        dateStart: undefined, // clears the dates selected
        dateEnd: undefined, // clears the dates selected
      })
    }
  }

  /**
   * monitors all url tab selection and produces the correct on
   * @param tab pass the value that changing the tabs
   */
  equipLocationTab = (tab: EEquipmentTabs | string) => {
    switch (tab) {
      case EEquipmentTabs.profile:
        return EEquipmentTabs.profile
      case EEquipmentTabs.maintenance:
        return EEquipmentTabs.maintenance
      case EEquipmentTabs.events:
      case EEquipmentTabs.timeline:
        return 'monitor' // this is set to monitor as it includes both timeline and events
      case EEquipmentTabs.reports:
        return EEquipmentTabs.reports
      default:
        return 'error'
    }
  }

  /**
   * Maintains the current tabs, and preventing out of sync tabs when one is clicked, best used for the Monitor and Reports tab
   * @param tab pass the value that changing the tabs
   */
  equipLocationSet = (tab: string | EEquipmentTabs) => {
    switch (tab) {
      case 'monitor':
        return EEquipmentTabs.timeline
      case 'reports':
        return EEquipmentTabs.reports
      default:
        return tab as EEquipmentTabs
    }
  }

  /**
   * Loads the specific component based off the selected tab value
   * @param selectedTab pass the loaded tab
   */
  lazyLoadEquipment(
    selectedTab: string,
    isMobile: boolean,
    height: number,
    width: number
  ) {
    switch (selectedTab) {
      case EEquipmentTabs.profile:
        return (
          <EquipmentProfile
            equipmentProfile={this.state.equipmentProfile}
            isMobile={isMobile}
            /**
             * @todo Connect the useMetric to the branch use metric setting
             */
            useMetric
          />
        )
      case EEquipmentTabs.maintenance:
        return <EquipmentMaintenance />
      case EEquipmentTabs.timeline:
        return (
          <EquipmentMonitor
            selected={EEquipmentTabs.timeline}
            onChangeHash={(val: string) =>
              this.setTab(val as TEquipmentTabs, true)
            }
          />
        )
      case EEquipmentTabs.events:
        return (
          <EquipmentMonitor
            selected={EEquipmentTabs.events}
            onChangeHash={(val: string) =>
              this.setTab(val as TEquipmentTabs, true)
            }
          />
        )
      case EEquipmentTabs.reports:
        return (
          <EquipmentReports
            isMobile={isMobile}
            height={height}
            width={width}
            hash={this.hash}
          />
        )
      default:
        return <NoAccessPage Error="404 Not Found" />
    }
  }

  /**
   * Grabs all the equipment profile information to be rendered
   * @param assetId pass it the assetId for the correct information
   */
  grabEquipmentInfo(assetId: number) {
    fetch('http://localhost:3001/asset/info/' + assetId)
      .then((response) => response.json())
      .then((data) => this.setState({ equipmentProfile: data }))
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    const hashgrabbed = getHash()
      ? (getHash() as { tab?: TEquipmentTabs })
      : { tab: EEquipmentTabs.profile as TEquipmentTabs }
    this.setTab(hashgrabbed.tab ? hashgrabbed.tab : EEquipmentTabs.profile)
    this.grabEquipmentInfo(this.props.id)
  }

  render() {
    // console.log(allowedEquipment.includes(this.props.id))
    // console.log(this.props.id)
    return (
      <WidthViewport>
        {({ isMobile, height, width }) => (
          <div>
            {isMobile ? null : (
              <Toolbar
                title={`Asset: ${
                  this.state.equipmentProfile
                    ? this.state.equipmentProfile.name
                    : ''
                }`}
                noXtraBar
                noBlueBar
              />
            )}
            <SideAppBar
              selectedTab={this.equipLocationTab(this.state.selectedTab)}
              tabs={equipmentTabs}
              onChangeTab={(val: string) =>
                this.setTab(this.equipLocationSet(val), true)
              }
              toolBar={{ single: true }}
            >
              <Suspense
                fallback={<LoadingOverlay loading delayedTime={5000} />}
              >
                {allowedEquipment.includes(this.props.id) ? (
                  this.lazyLoadEquipment(
                    this.state.selectedTab,
                    isMobile,
                    height,
                    width
                  )
                ) : (
                  <NoAccessPage Error="401 Unauthorized" />
                )}
              </Suspense>
            </SideAppBar>
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default Equipment
