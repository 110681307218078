import React from 'react'
import { WithStyles } from '@mui/styles'
import { createTheme } from '@mui/material/styles'
import { createStyles, ThemeProvider, withStyles } from '@mui/styles'
import { red, green, orange } from '@mui/material/colors'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import IconButton from '../../../buttons/IconButton'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import { RoviLog, ObjErr } from '../../../../utility/roviLog'
import { colors } from '../../../ux/roviTheme'
import api from '../../../../constants/api'
import { manufacture, modelID, EModel } from '../../../../types/account/devices'

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

/**
 * this specific part of the api call is the direct call path before the actual commands.
 */
const dfuPath = '/devices/commands'

/**
 * contains the RL-300 device internal id
 */
const threeHundredDevice = 'rl-gl300ma'

/**
 * contains the RL-500 device internal id
 */
const fiveHundredDevice = 'rl-gl500ma'

const DarkTheme = createTheme({
  palette: {
    secondary: {
      main: green[500], // the primary rovi orange is being over written somewhere and the color is wanted to be green
    },
    action: {
      active: green[500], // the primary rovi orange is being over written somewhere and the color is wanted to be green
    },
  },
})

const styles = createStyles({
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogTextWarn: {
    maxWidth: 300,
    color: red[500],
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  inMotionCheck: {
    display: 'flex',
    alignItems: 'center',
  },
  dfuRadioPos: {
    paddingLeft: 25,
  },
  radioColor: {
    color: colors.iconColor,
  },
})

interface IState {
  motionOn: boolean
  nonMotionVal: string
  motionVal: string
  motionOnPend: boolean
  nonMotionValPend: string
  motionValPend: string
  value: string
  open: boolean
  dfuColor: string
  dfutip: string
  dfuDisplay: string
  cookieDate: number
  sosEnabled: boolean
  isPending: boolean
  openCancelDialog: boolean
  pendState: string
}

interface IProps extends WithStyles<typeof styles> {
  id: string
  deviceType?: EModel
  pending?: string
  children?: any
}

class DFU extends React.Component<IProps, IState> {
  state: IState = {
    motionOn: false,
    nonMotionVal: '43200',
    motionVal: '300',
    motionOnPend: false,
    nonMotionValPend: '43200',
    motionValPend: '300',
    value: '',
    open: false,
    dfuColor: colors.primaryBlue,
    dfutip: 'Waiting',
    dfuDisplay: '24hr',
    cookieDate: 0,
    sosEnabled: false,
    isPending: false,
    openCancelDialog: false,
    pendState: '',
  }

  /**
   * It checks if motion is on set motion, otherwise set non-motion.
   * This function does is replaces the cookie function that checked if motion was on or not.
   */
  setDFUDisplay() {
    return this.state.motionOn ? this.state.motionVal : this.state.nonMotionVal
  }

  /**
   * This will check the State of the device in dfu, it does not care about manufacture just currently mounted device to the component.
   * It will grab the this.props.id value as the device so no need to pass it through, the other things it does is set the state which
   * is the most confusing part for some, but in a sense theres alot of the douplication because thats required, but follow the values
   * being set to in order to understand.
   */
  async dfuStateChecker() {
    const requestOptions = {
        method: 'GET',
      },
      statusLinkCurrent = `${api}${dfuPath}/state/${this.props.id}`
    await fetch(statusLinkCurrent, requestOptions)
      .then((response) => response.json())
      .then((result) =>
        this.setState({
          // This will detect if the isMotion value is true than sets it to true
          motionOn: result.currentState.isMotion,
          nonMotionVal: result.currentState.nonMotionFrequency
            ? this.props.deviceType === threeHundredDevice
              ? result.currentState.nonMotionFrequency.toString() // just set the 300 value passed in
              : // if 500 multiply value
                (
                  parseInt(result.currentState.nonMotionFrequency) *
                  60 *
                  60
                ).toString()
            : '43200',
          motionVal: result.currentState.motionFrequency
            ? this.props.deviceType === threeHundredDevice
              ? result.currentState.motionFrequency.toString() // just set the 300 value passed in
              : // if 500 multiply value
                (parseInt(result.currentState.motionFrequency) * 60).toString()
            : '300',

          // Up is Current --- Down is Pending ----------------------------------------------------------------

          // This will detect if the isMotion value is true than sets it to true
          motionOnPend: result.pendingState.isMotion,
          nonMotionValPend: result.pendingState.nonMotionFrequency
            ? this.props.deviceType === threeHundredDevice
              ? result.pendingState.nonMotionFrequency.toString() // just set the 300 value passed in
              : // if 500 multiply value
                (
                  parseInt(result.pendingState.nonMotionFrequency) *
                  60 *
                  60
                ).toString()
            : '43200',
          motionValPend: result.pendingState.motionFrequency
            ? this.props.deviceType === threeHundredDevice
              ? result.pendingState.motionFrequency.toString() // just set the 300 value passed in
              : // if 500 multiply value
                (parseInt(result.pendingState.motionFrequency) * 60).toString()
            : '300',
        })
      )
      .catch((error) =>
        RoviLog.info(
          ObjErr(
            `DFU device ${this.props.id} Failed to Grab DFU State: `,
            error
          ),
          'error'
        )
      )
    /**
     * Has to set these values a second time after the state already updated due to requiring some data that is currently being set to.
     * and react has a hard time updating things in the same set state function.
     */
    this.setState({
      sosEnabled: this.state.nonMotionVal === '3600' ? true : false,
      dfuDisplay: this.setDFUDisplay(),
    })
  }

  /**
   * converts the seconds into a small easy to read setting.
   * @param seconds pass the seconds to this function as a string.
   * @returns the format we want displayed for the seconds value.
   */
  convertSec(seconds: string) {
    switch (seconds) {
      case '5':
        return '5sec' // Only available for the RL-301 Device
      case '30':
        return '30sec' // Only available for the RL-301 Device
      case '60':
        return '1min'
      case '300':
        return '5min'
      case '900':
        return '15min' // Only available for the RL-301 Device
      case '3600':
        return '1hr'
      case '21600':
        return '6hr'
      case '43200':
        return '12hr'
      case '86400':
        return '24hr'
      default:
        return ''
    }
  }

  /**
   * this is the Long Polling function.
   * this function will constantly ping the fetch request and check to see what the value is.
   * it will stop pinging when the return value is not "pending" or "inProgress"
   * @param selectedLink this value is the selected link, as we use a status link and a update link,
   * this will fetch what ever link is sent through it.
   * @param selectedRequest the request Options to be added in the fetch request.
   * @param dontFail this prevents it from failing, just use for the DELETE command
   * @returns updates the state on the device current status.
   */
  async longPoll(
    selectedLink: string,
    selectedRequest: any,
    dontFail?: boolean
  ) {
    const date = new Date()
    const requestOptions = {
        method: 'GET',
      },
      statusLink = `${api}${dfuPath}/${this.props.id}`
    this.setState({
      dfuDisplay: this.setDFUDisplay(),
    }) // sets the current status of the device
    await fetch(selectedLink, selectedRequest)
      .then((response) => response.json())
      .then((result) => {
        switch (result['status'] ? result['status'] : result['commands']) {
          case 'pending':
            this.setState({
              dfuColor: orange[500],
              dfutip: 'Pending',
              isPending: true,
              pendState: ` for ${
                this.convertSec(this.state.nonMotionValPend) +
                ' standby' +
                (this.state.motionOnPend
                  ? ' - ' +
                    this.convertSec(this.state.motionValPend) +
                    ' motion'
                  : '')
              }`,
            })
            setTimeout(() => this.longPoll(statusLink, requestOptions), 1000)
            break
          case 'inProgress':
            this.setState({
              dfuColor: orange[500],
              dfutip: 'In Progress',
              isPending: true,
              pendState: ` for ${
                this.convertSec(this.state.nonMotionValPend) +
                ' standby' +
                (this.state.motionOnPend
                  ? ' - ' +
                    this.convertSec(this.state.motionValPend) +
                    ' motion'
                  : '')
              }`,
            })
            setTimeout(() => this.longPoll(statusLink, requestOptions), 1000)
            break
          case 'OK':
          case 'none':
          case 'notFound':
          case 'no-update-needed':
          case 'finished':
            this.setState({
              dfuColor: colors.primaryBlue,
              dfutip: 'Finished',
              isPending: false,
            })
            setTimeout(() => this.dfuStateChecker(), 1000)
            break
          case 'noUpdate':
          case 'success':
          case 'no-updates':
            this.setState({
              dfuColor: colors.primaryBlue,
              dfutip:
                this.state.cookieDate + 24 * 60 * 60 * 1000 >= date.getTime()
                  ? 'Finished'
                  : 'No Update',
              isPending: false,
              pendState: '',
            })
            setTimeout(() => this.dfuStateChecker(), 1000)
            break
          default:
            this.setState({
              dfuColor: red['A700'],
              dfutip: 'Failed',
              isPending: false,
              pendState: '',
            })
            setTimeout(() => this.dfuStateChecker(), 1000)
            if (!dontFail) {
              RoviLog.info(
                ObjErr(
                  `Error DFU device ${
                    this.props.id
                  } didnt recieve correct readings ${
                    selectedRequest['method']
                  }, ${JSON.stringify(selectedRequest['body'])}: `,
                  result
                ),
                'error'
              )
            }
            break
        }
      })
      .catch((error) =>
        dontFail
          ? undefined
          : RoviLog.info(
              ObjErr(
                `DFU device ${this.props.id} Failed to Long Poll ${
                  selectedRequest['method']
                }, ${JSON.stringify(selectedRequest['body'])}: `,
                error
              ),
              'error'
            )
      )
  }

  /**
   * when this component mounts it immediately calls the device if it exists.
   */
  componentDidMount() {
    this.dfuStateChecker()
    this.longPoll(`${api}${dfuPath}/${this.props.id}`, {
      method: 'GET',
    })
  }

  /**
   * This function handles miss placed information insuring it doesnt end up with no information
   * @returns the command for the update request
   */
  commandSetting() {
    return !this.state.motionOn
      ? [
          // Standby Val, Motion Off, Motion Val
          {
            code: 100000,
            value: 'false',
          },
          {
            code: 100001,
            value: this.state.nonMotionVal,
          },
          {
            code: 100002,
            value: this.state.motionVal,
          },
        ]
      : this.state.motionOn && // Standby Val, Motion On, Motion Val
          this.state.motionVal && [
            {
              code: 100000,
              value: 'true',
            },
            {
              code: 100001,
              value: this.state.nonMotionVal,
            },
            {
              code: 100002,
              value: this.state.motionVal,
            },
          ]
  }

  handleClickCancel = () => {
    this.setState({ openCancelDialog: false })
    this.longPoll(
      `${api}${dfuPath}/${this.props.id}`,
      {
        method: 'DELETE',
      },
      true
    )
  }

  /**
   * when the value of the device is changed, this function should be called with the new value
   * and that should trigger the longPoll function to start checking every few seconds.
   */
  handleClickSave = () => {
    if (this.state.sosEnabled) {
      RoviLog.info(`User triggered SOS on device ${this.props.id}`, 'log')
    }
    let date = new Date()
    let dateSOS = new Date()
    dateSOS.setTime(dateSOS.getTime() + 24 * 60 * 60 * 1000) // Expires after 24 hours
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000) // Expires after 365 days
    const raw = JSON.stringify({
      deviceID: this.props.id,
      deviceModel: modelID[this.props.deviceType ? this.props.deviceType : ''],
      commands: this.commandSetting(),
      sos: this.state.sosEnabled,
    })
    this.longPoll(
      `${api}${dfuPath}/${
        manufacture[this.props.deviceType ? this.props.deviceType : '']
      }`,
      {
        method: 'POST',
        headers: myHeaders,
        body: raw,
      }
    )
    setTimeout(() => this.dfuStateChecker(), 500)
    this.setState({
      open: false,
      dfuDisplay: this.setDFUDisplay(),
    })
  }

  /**
   * This function only handles state change of the value.
   * sets the state with the current selected Standby value.
   */
  handleStandbyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value === 'sos') {
      this.setState({
        nonMotionVal: '3600',
        motionOn: true,
        sosEnabled: true,
        motionVal: this.props.deviceType === threeHundredDevice ? '5' : '60',
        dfuDisplay: this.setDFUDisplay(),
      })
    } else {
      this.setState({
        nonMotionVal: (event.target as HTMLInputElement).value,
        dfuDisplay: this.setDFUDisplay(),
        sosEnabled: false,
      })
    }
  }

  /**
   * This function only handles state change of the value.
   * sets the state with the current selected InMotion value.
   */
  handleInMotionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      motionVal: (event.target as HTMLInputElement).value,
      dfuDisplay: this.setDFUDisplay(),
    })
  }

  /**
   * This component is storing the current customizations, with out having to duplicate it.
   */
  RadioMUI = (<Radio classes={{ root: this.props.classes.radioColor }} />)

  render() {
    const classes = this.props.classes
    return (
      <div style={{ width: '100%' }}>
        <div className={classes.labelContainer}>
          {(this.props.deviceType === threeHundredDevice ||
            this.props.deviceType === fiveHundredDevice) && (
            <>
              {/* what the dfu is currently set too as an easy display */}
              {this.state.sosEnabled
                ? 'SOS'
                : this.convertSec(this.state.dfuDisplay)}
              {/* opens up the command Dialog */}
              <IconButton
                style={
                  this.state.dfuColor !== ''
                    ? { color: this.state.dfuColor }
                    : undefined
                }
                onClick={() => this.setState({ open: true })}
                variant="row"
                tooltip={this.state.dfutip + this.state.pendState}
                icon="radar"
              />
              {/* Passing the insides of the device box */}
              {this.props.children}
              {/* Cancel the Current Pending Command */}
              {this.state.isPending && (
                <IconButton
                  variant="row"
                  icon="cancel"
                  tooltip="Cancel the current pending request"
                  onClick={() => this.setState({ openCancelDialog: true })}
                />
              )}
            </>
          )}
        </div>

        <Dialog
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          {/* Title of the Dialog explaining to the user what this does */}
          <DialogTitle>Device {this.props.id} Data Frequency</DialogTitle>
          <DialogContentText className={classes.dialogText}>
            Change the frequency of a Device Update
          </DialogContentText>
          {parseInt(this.state.nonMotionVal) <= 21600 ? (
            <DialogContent
              className={classes.dialogText}
              style={{ flexDirection: 'column', alignItems: 'center' }}
            >
              <p className={classes.dialogTextWarn}>
                This setting may cause your device to run out of battery faster
                then normal
              </p>
            </DialogContent>
          ) : undefined}
          <DialogContent>
            <ThemeProvider theme={DarkTheme}>
              {/* This is the Radio Selector Group for when the device is not moving */}
              <RadioGroup
                name="dataStandByFrequency"
                onChange={this.handleStandbyChange}
                className={classes.dfuRadioPos}
                value={this.state.sosEnabled ? 'sos' : this.state.nonMotionVal}
              >
                {/* This Component is basically the 1Hour Value, but we set it up to Say SOS since we dont want people just setting 1Hour as it will kill the battery of the device*/}
                <FormControlLabel
                  value="sos"
                  control={
                    <Tooltip
                      title={`Enabling SOS, device reports every hour regardless of motion, when in motion it reports every ${
                        this.props.deviceType === threeHundredDevice
                          ? '5 seconds'
                          : 'minute'
                      }`}
                      // , This setting will revert after 24 hours`}
                    >
                      {this.RadioMUI}
                    </Tooltip>
                  }
                  label="SOS - Recovery mode"
                />
                <FormControlLabel
                  value="21600"
                  control={this.RadioMUI}
                  label="Every 6 Hour's"
                />
                <FormControlLabel
                  value="43200"
                  control={this.RadioMUI}
                  label="Every 12 Hour's"
                />
                <FormControlLabel
                  value="86400"
                  control={this.RadioMUI}
                  label="Every 24 Hour's"
                />
              </RadioGroup>
              {/* Check box to enable the Motion for the Device */}
              <div className={classes.inMotionCheck}>
                <FormControlLabel
                  disabled={this.state.sosEnabled}
                  control={
                    <Checkbox
                      checked={this.state.motionOn}
                      onChange={(event) =>
                        this.setState({ motionOn: event.target.checked })
                      }
                      classes={{ root: classes.radioColor }}
                    />
                  }
                  label="Enable InMotion Detection"
                />
              </div>
              {/* This is the Radio Selector Group for when device is moving, but if no radio value is selected, than no motion should be sent */}
              {this.state.motionOn && (
                <RadioGroup
                  name="dataInMotionFrequency"
                  onChange={this.handleInMotionChange}
                  className={classes.dfuRadioPos}
                  value={this.state.motionVal}
                >
                  {this.props.deviceType === threeHundredDevice ? (
                    <FormControlLabel
                      disabled={this.state.sosEnabled}
                      value="5"
                      control={this.RadioMUI}
                      label="Every 5 Second's while in motion"
                    />
                  ) : (
                    <FormControlLabel
                      disabled={this.state.sosEnabled}
                      value="60"
                      control={this.RadioMUI}
                      label="Every 1 Minute while in motion"
                    />
                  )}
                  {this.props.deviceType === threeHundredDevice && (
                    <FormControlLabel
                      disabled={this.state.sosEnabled}
                      value="30"
                      control={this.RadioMUI}
                      label="Every 30 Second's while in motion"
                    />
                  )}
                  <FormControlLabel
                    disabled={this.state.sosEnabled}
                    value="300"
                    control={this.RadioMUI}
                    label="Every 5 Minutes while in motion"
                  />
                  <FormControlLabel
                    disabled={this.state.sosEnabled}
                    value="900"
                    control={this.RadioMUI}
                    label="Every 15 Minutes while in motion"
                  />
                  <FormControlLabel
                    disabled={this.state.sosEnabled}
                    value="3600"
                    control={this.RadioMUI}
                    label="Every 1 Hour while in motion"
                  />
                </RadioGroup>
              )}
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ open: false })}>
              Cancel
            </Button>
            {/* Tool Tip around the save button in order to let the user know why they cant do somthing */}
            <Tooltip
              disableHoverListener={
                this.state.dfutip === 'Pending' ||
                this.state.dfutip === 'In Progress'
                  ? false
                  : this.state.nonMotionVal
                  ? true
                  : false
              }
              title={
                !this.state.nonMotionVal
                  ? 'Command Requires a nonMotion Setting'
                  : this.state.dfutip === 'Pending' ||
                    this.state.dfutip === 'In Progress'
                  ? this.state.dfutip + ' ' + this.state.pendState
                  : 'Send Command to the Device'
              }
            >
              <span>
                {/* sends the command to the server */}
                <Button
                  onClick={() => this.handleClickSave()}
                  disabled={
                    this.state.dfutip === 'Pending' ||
                    this.state.dfutip === 'In Progress'
                      ? true
                      : this.state.nonMotionVal
                      ? false
                      : true
                  }
                >
                  Save
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openCancelDialog}
          onClose={() => this.setState({ openCancelDialog: false })}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel the pending device update?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openCancelDialog: false })}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.handleClickCancel}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(DFU)
