import React from 'react'
import Button from './Button'
import Icon from '../Icon'
import { IStandardIcons } from '../ux/standardIcons'

interface IProps {
  startIcon?: IStandardIcons
  endIcon?: IStandardIcons
  label?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  href?: string
}

export default class ButtonSideIcon extends React.Component<IProps> {
  render() {
    return (
      <div>
        <Button
          startIcon={
            this.props.startIcon ? (
              <Icon icon={this.props.startIcon} />
            ) : undefined
          }
          endIcon={
            this.props.endIcon ? <Icon icon={this.props.endIcon} /> : undefined
          }
          onClick={this.props.onClick}
          href={this.props.href}
        >
          {this.props.label}
          {this.props.children}
        </Button>
      </div>
    )
  }
}
