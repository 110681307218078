import { IAssetSnapshotRow } from '../AssetsTabTypes'
import moment from 'moment'

interface IPrintJsNewList {
  ID: string
  Vin: string
  Serial: string
  'Year Built': string
  'Purchase Date': string
  Category: string
  Make: string
  Model: string
  Site: string
  Device: string
}

export const printList = [
  'ID',
  'Vin',
  'Serial',
  'Year Built',
  'Purchase Date',
  'Category',
  'Make',
  'Model',
  'Site',
  'Device',
]

function checkString(strCheck?: any) {
  return strCheck ? strCheck : ''
}

export function printJsSortInventory(thingsList: IAssetSnapshotRow[]) {
  let newList: IPrintJsNewList[] = []
  thingsList.forEach((thing: IAssetSnapshotRow) => {
    let newVal = {
      ID: checkString(thing.name),
      Vin: checkString(thing.vin),
      Serial: checkString(thing.serial),
      'Year Built': checkString(thing.yearBuilt),
      'Purchase Date': moment(checkString(thing.purchaseDate)).format('l'),
      Category: checkString(thing.category.name),
      Make: checkString(thing.Make),
      Model: checkString(thing.Model),
      Site: checkString(thing.agreement?.site.name),
      Device: checkString(thing.device),
    }
    newList.push(newVal)
  })
  return newList
}

interface ICSVNewList {
  ID: string
  Vin: string
  Serial: string
  'Year Built': string
  'Purchase Date': string
  Category: string
  Make: string
  Model: string
  Site: string
  Device: string
  'Purchase Price': string
  'Engine Hours': string
}

export function csvSortInventory(thingsList: IAssetSnapshotRow[]) {
  let newList: ICSVNewList[] = []
  thingsList.forEach((thing: IAssetSnapshotRow) => {
    let newVal = {
      ID: checkString(thing.name),
      Vin: checkString(thing.vin),
      Serial: checkString(thing.serial),
      'Year Built': checkString(thing.yearBuilt),
      'Purchase Date': moment(checkString(thing.purchaseDate)).format('l'),
      Category: checkString(thing.category.name),
      Make: checkString(thing.Make),
      Model: checkString(thing.Model),
      Site: checkString(thing.agreement?.site.name),
      Device: checkString(thing.device),
      'Purchase Price': '$' + checkString(thing.purchasePrice) + ' USD',
      'Engine Hours': checkString(thing.odometer),
    }
    newList.push(newVal)
  })
  return newList
}
