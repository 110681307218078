import qs from 'qs'

export interface IHash {
  tab: string
}

export const HashFromString = (params: string): IHash => {
  return qs.parse(params) as unknown as IHash
}

export const HashToString = (params: IHash) => {
  return qs.stringify(params)
}

/**
 * Account Page Hash information
 */

export enum EAccountTabs {
  account = 'account',
  devices = 'devices',
  integrations = 'integrations',
  fieldLabels = 'fieldLabels',
}

export type IAccountTabs =
  | EAccountTabs.account
  | EAccountTabs.devices
  | EAccountTabs.integrations
  | EAccountTabs.fieldLabels

export interface IAccountHash {
  tab: IAccountTabs
}

export const AccountHashFromString = (params: string): IAccountHash => {
  return qs.parse(params) as unknown as IAccountHash
}

export const AccountHashToString = (params: IAccountHash) => {
  return qs.stringify(params)
}

/**
 * Maptrac FIlter Hash Information
 */

export enum EMaptracFilterMaintenance {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export type IMaptracFilterMaintenance =
  | EMaptracFilterMaintenance.High
  | EMaptracFilterMaintenance.Medium
  | EMaptracFilterMaintenance.Low

/**
 * Different types of filters there can be
 */
export interface IMaptracFilterHash {
  ble?: boolean
  maintenance?: IMaptracFilterMaintenance
  category?: string
}

export const MaptracFilterHashFromString = (
  params: string
): IMaptracFilterHash => {
  return qs.parse(params) as unknown as IMaptracFilterHash
}

export const MaptracFilterHashToString = (params: IMaptracFilterHash) => {
  return qs.stringify(params)
}
