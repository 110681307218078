import React, { useRef } from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { googleMapsApiKey } from '../../google/googleData'
import mapStylesLight from './mapStylesLight.json'
import mapStylesDark from './mapStylesDark.json'
import { darkMode } from '../ux/roviTheme'

export interface ILatLng {
  lat: number
  lng: number
}

const panelHeight = 72
const drawerWidth = 350

interface IDimensions {
  height: number
  width: number
}

export const getAdjustedBounds = (
  bounds: IBounds,
  isMobile: boolean,
  dimensions: IDimensions
) => {
  if (isMobile) {
    const viewablePercentage =
      (dimensions.height - panelHeight) / dimensions.height
    const southBounds =
      bounds.north - (bounds.north - bounds.south) / viewablePercentage
    return {
      south: southBounds,
      north: bounds.north,
      west: bounds.west,
      east: bounds.east,
    }
    // new google.maps.LatLngBounds(
    //   { lat: southBounds, lng: bounds.west },
    //   { lat: bounds.north, lng: bounds.east }
    // )
  } else {
    const viewablePercentage =
      (dimensions.width - drawerWidth) / dimensions.width
    const westBounds =
      bounds.east - (bounds.east - bounds.west) / viewablePercentage
    return {
      south: bounds.south,
      north: bounds.north,
      west: westBounds,
      east: bounds.east,
    }
    // new google.maps.LatLngBounds(
    //   { lat: bounds.south, lng: westBounds },
    //   { lat: bounds.north, lng: bounds.east }
    // )
  }
}

export interface IBounds {
  south: number
  north: number
  west: number
  east: number
}

export const calcMapBounds = (gpsCoords: ILatLng[]) => {
  const padding = 0.0025
  // console.log(gpsCoords.length)
  if (gpsCoords.length > 0) {
    const lats = gpsCoords.map((coord) => coord.lat)
    const lngs = gpsCoords.map((coord) => coord.lng)
    // console.log('Maptrac Boundaries Set')
    // console.log({
    //   south: Math.min(...lats) - padding,
    //   north: Math.max(...lats) + padding,
    //   west: Math.min(...lngs) - padding,
    //   east: Math.max(...lngs) + padding,
    // })
    return {
      south: Math.min(...lats) - padding,
      north: Math.max(...lats) + padding,
      west: Math.min(...lngs) - padding,
      east: Math.max(...lngs) + padding,
    } as IBounds
  } else {
    // console.log('Maptrac Set to Default Zoom')
    return {
      south: -60,
      north: 60,
      west: -30,
      east: 110,
    } as IBounds
  }
}

interface IGoogleMapDetailsProps {
  isMobile: boolean
  height: number
  width: number
  onClick: () => void
  onMapLoad: (map: google.maps.Map<Element>) => void
}

export const GoogleMapDetails: React.FunctionComponent<IGoogleMapDetailsProps> =
  (props) => {
    const mapRef = useRef(null)
    return (
      <LoadScript
        googleMapsApiKey={googleMapsApiKey.key}
        libraries={['drawing']}
      >
        <GoogleMap
          ref={mapRef}
          onLoad={(map) => props.onMapLoad(map)}
          mapContainerStyle={
            props.isMobile ? containerStyleMobile : containerStyleDesktop
          }
          /**
           * If no devices load this should be the default Location
           * lat: 40.4312869, // Our coords we should remove this after completion
           * lng: -111.8918247,
           */
          center={{ lat: 0, lng: 0 }} // default center
          /**
           * If no devices load this should be the default Location - it displays most of the world in one view
           */
          zoom={2} // default zoom
          onClick={() => props.onClick}
          options={{
            disableDefaultUI: true, // this just enables or disables googleMaps Basic UI
            zoomControl: !props.isMobile, // removes the buttons to zoom in and out on mobile
            zoomControlOptions: { position: props.isMobile ? 8 : 9 },
            mapTypeControl: true,
            clickableIcons: false, // We dont want the users to accidently click a name and be redirected out side the website
            mapTypeControlOptions: { position: 3 }, // TOP_RIGHT
            //@ts-ignore
            styles: darkMode ? mapStylesDark : mapStylesLight, // Changes the entire Maptrac to Darkmode
            controlSize: 28, // shrinks the default buttons
            // restriction: {
            //   latLngBounds: getAdjustedBounds(
            //     { north: 180, south: -180, west: -180, east: 180 },
            //     props.isMobile,
            //     {
            //       height: props.height,
            //       width: props.width,
            //     }
            //   ),
            // },
          }}
        >
          {props.children}
        </GoogleMap>
      </LoadScript>
    )
  }

/**
 * GoogleMap Size and Box, since we will not be having it be larger than the users main screen it will be set to the screen width and height
 */
const containerStyleDesktop: React.CSSProperties = {
  borderWidth: 0,
  width: '100vw',
  height: 'calc(100vh - 64px)',
}
const containerStyleMobile: React.CSSProperties = {
  borderWidth: 0,
  width: '100vw',
  height: 'calc(100vh - 48px)',
  marginTop: '48px',
}

interface IProps {
  isMobile: boolean
  onChangeCenter: (center: ILatLng) => void
  height: number
  width: number
  gpsCoords?: ILatLng[]
  onClick: () => void
  children: (map: google.maps.Map<Element>) => React.ReactNode
}

interface IState {
  gpsCoords?: ILatLng[]
}

class GoogleRoviMap extends React.Component<IProps, IState> {
  state: IState = {
    gpsCoords: this.props.gpsCoords,
  }

  map!: google.maps.Map<Element>

  /**
   * This is now temporary i want to move this in to the part the loads all the
   * other calls, and grab all there latlng values and extend the boundary, once
   * all of those are gathered pan to bounds. Should work like a charm, best example
   * of this is on the geofences.
   */
  onMapLoad = (map: google.maps.Map<Element>) => {
    if (map && !this.map) {
      this.map = map
      /** sets up bounds observable */
      // console.log(this.props.gpsCoords)
      if (this.map && this.props.gpsCoords) {
        const bounds = calcMapBounds(this.props.gpsCoords)

        // map.fitBounds(bounds)
        map.panToBounds(bounds)
      }
    }
  }

  componentDidMount() {}

  /**
   * I added these few lines of code, because maptrac kept bugging out on me and
   * desyncing, my only thoughts were if it broke on me anywhere during the process
   * to just reload the page after 30secs if its still broken. so now it has to
   * have a delay other wise it would have an infinite loop of constant reloading.
   */
  checkGoogleMaps() {
    if (this.map) {
    } else {
      window.location.reload()
    }
  }

  componentDidUpdate() {
    setTimeout(() => this.checkGoogleMaps(), 30000)
  }

  render() {
    return (
      <GoogleMapDetails
        isMobile={this.props.isMobile}
        height={this.props.height}
        width={this.props.width}
        onClick={this.props.onClick}
        onMapLoad={this.onMapLoad}
      >
        {this.props.children(this.map)}
      </GoogleMapDetails>
    )
  }
}

export default GoogleRoviMap
