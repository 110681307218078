import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const PeopleIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <svg viewBox="0 0 76.69 48">
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g id="Layer_2" data-name="Layer 2">
      <g id="workers">
        <path d="M.13,48H34.54V41.73S18.8,27.46,0,41.6Z" />
        <path d="M9.33,25.53l15.77.12s-.36,7.41-7.65,7.77C16.38,33.42,10.57,33.08,9.33,25.53Z" />
        <path d="M7.41,24.22H26.77A2,2,0,0,0,25.22,22c-.12-2.39-.36-5.14-4.18-7.53l.12,5.14H18.88l-.11-6.81H15.3l.12,6.81H13.15V14.42A8.57,8.57,0,0,0,8.85,22C8.25,22.31,7.53,22.19,7.41,24.22Z" />
        <path d="M42.29,48h34.4V41.73S61,27.46,42.15,41.6Z" />
        <path d="M51.48,25.53l15.77.12s-.36,7.41-7.65,7.77C58.53,33.42,52.72,33.08,51.48,25.53Z" />
        <path d="M49.57,24.22H68.92A2,2,0,0,0,67.37,22c-.12-2.39-.36-5.14-4.18-7.53l.12,5.14H61l-.12-6.81H57.45l.12,6.81H55.3V14.42A8.57,8.57,0,0,0,51,22C50.4,22.31,49.69,22.19,49.57,24.22Z" />
        <path d="M14.89,48H61.74V39.47s-21.43-19.43-47-.19Z" />
        <path d="M27.41,17.41l21.48.16S48.4,27.66,38.47,28.15C37,28.15,29.1,27.69,27.41,17.41Z" />
        <path d="M24.8,15.62H51.16a2.75,2.75,0,0,0-2.11-3.09c-.16-3.26-.49-7-5.7-10.25l.17,7h-3.1L40.26,0H35.54l.17,9.27h-3.1v-7a11.65,11.65,0,0,0-5.85,10.25C25.94,13,25,12.85,24.8,15.62Z" />
      </g>
    </g>
  </svg>,
  'People'
)

export default PeopleIcon
