import { IGeofence } from '../../../types/maptrac/Geofences'

export function filterGeofenceDrawer(
  geofences: IGeofence[],
  searchString: string
) {
  // console.log(
  //   geofences.filter((g: IGeofence) =>
  //     g.geofenceName.toLowerCase().includes(searchString.toLowerCase())
  //   )
  // )
  return geofences.filter((g: IGeofence) =>
    g.geofenceName.toLowerCase().includes(searchString.toLowerCase())
  )
}

export function filterGeofenceMarkers(
  geofences: IGeofence[],
  searchString: string
) {
  return geofences.filter((g) =>
    g.geofenceName.toLowerCase().includes(searchString.toLowerCase())
  )
}

export function filterGeofenceMarkersUUID(
  geofences: IGeofence[],
  searchUUID: string
) {
  return geofences.filter(
    (g) => g.geofenceId.toLowerCase() !== searchUUID.toLowerCase()
  )
}
