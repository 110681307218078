import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const GeofenceIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M12,19.9c-1.2-1.4-2.3-2.8-3.3-4.2c-1.2-1.6-2.3-3.3-3.2-5.1C5,9.7,4.6,8.6,4.6,7.5c0-3.5,2.8-6.7,6.3-7.2c3.8-0.6,7.4,1.9,8.3,5.5c0.4,1.6,0.2,3-0.5,4.5c-1.1,2.3-2.5,4.4-4,6.3C13.8,17.7,12.9,18.8,12,19.9z M17,7.8c0-2.7-2.2-4.9-4.9-5c-2.7,0-5,2.2-5,4.9s2.2,5,5,5C14.8,12.7,17,10.5,17,7.8z M16.3,16.7l-1.1,1.4c3.1,0.4,4.9,1.4,4.9,2c0,0.8-3.1,2.2-8.1,2.2s-8.1-1.4-8.1-2.2c0-0.6,1.8-1.6,5-2l-1.2-1.4c-3,0.5-5.4,1.7-5.4,3.3c0,2.4,4.9,3.7,9.7,3.7s9.7-1.3,9.7-3.7C21.7,18.4,19.4,17.3,16.3,16.7z" />,
  'Geofence'
)

export default GeofenceIcon
