import React from 'react'
import { TextField, Theme } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { FlagDialogViewType } from './FlagDialog'
import RulesContainer from './RulesContainer'
import Margin from 'src/components/ux/Margin'
import {
  IFlagRule,
  flagConditionRuleFormatter,
  getNewEmptyFlag,
  isAnyTime,
} from 'src/constants/flagRules'
import IconButton from 'src/components/buttons/IconButton'
import { IEquipEntity, IGeofenceEntity } from '../../../types/maptrac/playback'
import CategoryChip from '../../ux/CategoryChip'
import LineButton from '../../buttons/LineButton'

interface IMainViewProps extends WithStyles<typeof styles> {
  flag: IFlagRule
  onChangeView: (newView: FlagDialogViewType, ruleIndex: number) => void
  onRemoveRule: (rule: number) => void
  onChangeRule: (fields: Partial<IFlagRule>) => void
  equipment: Record<string, IEquipEntity>
  categories: Record<string, any /*ICategory*/>
  geofences: Record<string, IGeofenceEntity>
  useMetricMeasurement?: boolean
}

const MainView = (props: IMainViewProps) => {
  const onChangeFlagName = (newValue: string) => {
    props.onChangeRule({ name: newValue })
  }

  return (
    <>
      <TextField
        id="name"
        autoFocus
        label="Flag Name"
        value={props.flag.name}
        onChange={(e) => onChangeFlagName(e.target.value)}
        className={props.classes.name}
        placeholder="i.e. Low Fuel"
        variant="standard"
      />
      {props.flag.rules.map((rule, ruleIndex) => (
        <div key={ruleIndex} className={props.classes.ruleRow}>
          <div className={props.classes.ruleBoxes}>
            <RulesContainer
              onClick={() => props.onChangeView('assets', ruleIndex)}
              label="Applies to:"
            >
              {rule.assets.map((entityId) => {
                const [type, id] = entityId.split(':')
                switch (type) {
                  case 'thing': {
                    const equip = props.equipment[id]
                    return (
                      <div
                        key={entityId}
                        className={props.classes.ruleTextSection}
                      >
                        {equip.meta.name}
                      </div>
                    )
                  }
                  case 'category': {
                    const category = props.categories[id] as any
                    return (
                      <CategoryChip
                        key={category.id}
                        name={
                          category.name + (category.deleted ? ' (Deleted)' : '')
                        }
                        color={category.meta.color}
                        icon={category.meta.icon}
                        style={{ margin: 2 }}
                        disabled
                      />
                    )
                  }
                  case 'all':
                    return (
                      <div key="all" className={props.classes.ruleTextSection}>
                        Any Asset
                      </div>
                    )
                }
              })}
            </RulesContainer>
            <Margin width={1} />
            <RulesContainer
              onClick={() => props.onChangeView('condition', ruleIndex)}
              label="When:"
            >
              {rule.condition.conditions.length > 1
                ? `${rule.condition.type.toUpperCase()} are true`
                : null}
              {rule.condition.conditions.map((condition, conditionIndex) => (
                <div
                  key={conditionIndex}
                  className={props.classes.ruleTextSection}
                >
                  {flagConditionRuleFormatter(
                    condition,
                    props.geofences,
                    props.useMetricMeasurement
                  )}
                </div>
              ))}
            </RulesContainer>
            <Margin width={1} />
            <RulesContainer
              label="During:"
              onClick={() => props.onChangeView('time', ruleIndex)}
            >
              {rule.time.map((time, timeIndex) =>
                isAnyTime(time) ? (
                  <div
                    key={timeIndex}
                    className={props.classes.ruleTextSection}
                  >
                    Any Time
                  </div>
                ) : (
                  <div
                    key={timeIndex}
                    className={props.classes.ruleTextSection}
                  >
                    {time.days.join(', ').toUpperCase()} - {time.hours[0]} to{' '}
                    {time.hours[1]}
                  </div>
                )
              )}
            </RulesContainer>
          </div>
          {props.flag.rules.length > 1 && (
            <div className={props.classes.button}>
              <IconButton
                variant="row"
                icon="delete"
                onClick={() => props.onRemoveRule(ruleIndex)}
              />
            </div>
          )}
        </div>
      ))}
      <LineButton
        onClick={() =>
          props.onChangeRule({
            rules: [...props.flag.rules, ...getNewEmptyFlag().rules],
          })
        }
      >
        Add Ruleset
      </LineButton>
    </>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    flex: {
      flex: 1,
    },
    name: {
      marginBottom: 10,
    },
    rulesContainer: {
      padding: 10,
      boxShadow: 'inset 2px 2px 5px 0px rgba(0,0,0,0.2)',
    },
    ruleRow: {
      marginBottom: 10,
      display: 'flex',
    },
    ruleBoxes: {
      display: 'flex',
      boxShadow: theme.shadows[2],
      maxHeight: 160,
      flex: 1,
    },
    ruleTextSection: {
      margin: 4,
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 10,
    },
  })

export default withStyles(styles)(MainView)
