import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const CoordIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M 12.259766 0.20507812 C 11.993882 0.1938114 11.723636 0.19463043 11.453125 0.20703125 C 11.16458 0.22025875 10.874531 0.24781225 10.582031 0.2890625 C 6.3820318 0.8890625 2.9628906 4.6716407 2.9628906 8.9316406 C 2.9628906 10.311641 3.4429688 11.570078 4.0429688 12.830078 C 5.1229688 15.050078 6.4433594 17.089766 7.9433594 19.009766 C 9.2033594 20.689766 10.582891 22.310781 11.962891 24.050781 C 13.102891 22.610781 14.181172 21.35125 15.201172 20.03125 C 17.061172 17.63125 18.8025 15.109609 20.0625 12.349609 C 20.8425 10.609609 21.081562 8.871172 20.601562 6.9511719 C 19.652344 3.1542969 16.248026 0.37407893 12.259766 0.20507812 z M 12.023438 3.3496094 C 15.323437 3.4096094 17.962891 6.0496093 17.962891 9.3496094 C 17.962891 12.649609 15.323438 15.289609 12.023438 15.349609 C 8.6634375 15.349609 5.9028906 12.589062 5.9628906 9.2890625 C 6.0228906 5.9890625 8.7234375 3.2896094 12.023438 3.3496094 z " />,
  'CoordIcon'
)

export default CoordIcon
