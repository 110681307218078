import React from 'react'
import Button from '../buttons/Button'
import Paper from '@mui/material/Paper'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Icon from '../Icon'
import ListItemIcon from '@mui/material/ListItemIcon'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import classNames from 'classnames'
import Typography from '@mui/material/Typography'
import { colors } from '../ux/roviTheme'

const styles = createStyles({
  container: {
    position: 'absolute',
    zIndex: 9,
  },
  mobilePosition: {
    top: 6,
    left: 6,
  },
  desktopPosition: {
    bottom: 21,
    right: 42,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.defaultText,
  },
})

interface IProps extends WithStyles<typeof styles> {
  timezone: string
  onChangeDate: (date: Date) => void
  isMobile: boolean
  switchLiveHistory: () => void
}

class ModeButton extends React.Component<IProps> {
  state = {
    anchorEl: undefined,
  }

  render() {
    const { classes, isMobile } = this.props

    return (
      <div
        className={classNames(classes.container, {
          [classes.mobilePosition]: isMobile,
          [classes.desktopPosition]: !isMobile,
        })}
      >
        <Paper className={classes.buttonContainer}>
          <Button
            onClick={(ev) => this.setState({ anchorEl: ev.currentTarget })}
          >
            <Icon icon="liveUpdates" className={classes.buttonContainer} />
            <Icon icon="arrowDown" className={classes.buttonContainer} />
          </Button>

          {/* {!this.props.isMobile && (
            <DatePickerFlat
              date={LocalDate.today(this.props.timezone)}
              onChange={(date) =>
                this.props.onChangeDate(date.toInstant(this.props.timezone))
              }
              shouldDisableDate={(localDate) => {
                const todayDate = LocalDate.today(this.props.timezone)
                return localDate.isAfter(todayDate)
              }}
            />
          )} */}
        </Paper>

        <Menu
          anchorEl={this.state.anchorEl}
          open={!!this.state.anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
        >
          <MenuItem disabled>
            <ListItemIcon>
              <Icon icon="liveUpdates" className={classes.buttonContainer} />
            </ListItemIcon>
            <Typography>Live</Typography>
          </MenuItem>
          <MenuItem onClick={() => this.props.switchLiveHistory()}>
            <ListItemIcon>
              <Icon icon="history" className={classes.buttonContainer} />
            </ListItemIcon>
            <Typography>History</Typography>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

export default withStyles(styles)(ModeButton)
