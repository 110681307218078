import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'

interface IProps {
  dialogOpen: boolean
  onClose: () => void
  assetId: string
  assetName: string
}

interface IState {}

class ReturnToInventoryDialog extends React.Component<IProps, IState> {
  state: IState = {}

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose}>
        <DialogTitle>Send {this.props.assetName} back to inventory</DialogTitle>
        <DialogContent>
          Are you sure you want to send asset back to inventory?
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ReturnToInventoryDialog
