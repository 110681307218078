import React from 'react'
import '../../../styles/pages/service/TasksPage.scss'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import HoverContainer from '../../ux/HoverContainer'
import IconButton from '../../buttons/IconButton'
import ScheduleRatio from './../ScheduleRatio'
import Typography from '@mui/material/Typography'
import EllipsisText from '../../ux/EllipsisText'
import Link from '@mui/material/Link'
import { IAssetTasks, TTaskMetric } from '../../../types/service/tasks'
import moment from 'moment'
import UnassignTaskDialog from '../taskComponents/UnassignTaskDialog'

const assetInterval = (interval: TTaskMetric, value: any) => {
  let intervalS = parseInt(value) === 1 ? undefined : 's'
  switch (interval) {
    case 'date':
      return moment(value).format('MMM Do YYYY')
    case 'engineHours':
      return value + ' hour' + intervalS
    case 'time':
      return value + ' day' + intervalS
    case 'odometer':
      return value + ' mile' + intervalS
  }
}

export const assetType = (
  schedule: number,
  interval: TTaskMetric,
  due: any,
  globalThreshold: number
) => {
  return schedule < globalThreshold
    ? 'Due in ' + assetInterval(interval, due)
    : schedule > globalThreshold && schedule < 1
    ? 'Upcoming in ' + assetInterval(interval, due)
    : 'OverDue by ' + assetInterval(interval, due)
}

interface IProps {
  name: string
  interval: TTaskMetric
  equips: IAssetTasks[]
  globalThreshold: number
  equip: IAssetTasks
  isMobile?: boolean
}

interface IState {
  unassignDialogOpen: boolean
}

class TasksAccordianRow extends React.Component<IProps, IState> {
  state: IState = {
    unassignDialogOpen: false,
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <HoverContainer>
          {({ hovered }: { hovered: boolean }) => (
            <>
              <ListItem dense>
                <ListItemText
                  primary={
                    <Link href="">
                      <EllipsisText text={this.props.equip.name} />
                    </Link>
                  }
                  className="listText"
                />
                {this.props.equip.scheduleRatio ? (
                  <>
                    <div className="dueContainer">
                      <Typography color="textSecondary" variant="body2">
                        {assetType(
                          this.props.equip.scheduleRatio,
                          this.props.interval,
                          this.props.equip.due,
                          this.props.globalThreshold
                        )}
                      </Typography>
                    </div>
                    <div
                      className={
                        this.props.isMobile
                          ? 'progressContainerMobile'
                          : 'progressContainer'
                      }
                    >
                      <ScheduleRatio
                        progress={this.props.equip.scheduleRatio}
                        threshold={this.props.globalThreshold}
                        equipId={this.props.equip.id}
                        isMobile={this.props.isMobile}
                      />
                    </div>
                  </>
                ) : null}
                <ListItemSecondaryAction>
                  {this.props.isMobile ? (
                    <IconButton
                      icon="delete"
                      variant="row"
                      tooltip="Unassign"
                      onClick={() =>
                        this.setState({ unassignDialogOpen: true })
                      }
                    />
                  ) : (
                    hovered && (
                      <IconButton
                        icon="delete"
                        variant="row"
                        tooltip="Unassign"
                        onClick={() =>
                          this.setState({ unassignDialogOpen: true })
                        }
                      />
                    )
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </>
          )}
        </HoverContainer>
        <UnassignTaskDialog
          open={this.state.unassignDialogOpen}
          onClose={() => this.setState({ unassignDialogOpen: false })}
          onUnassign={() => this.setState({ unassignDialogOpen: false })}
          taskName={this.props.name}
          equipName={this.props.equip.name}
        />
      </>
    )
  }
}

export default TasksAccordianRow
