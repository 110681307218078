import React from 'react'
import { Section } from '../equipmentComponents/EquipmentSection'
import { IOperator } from '../../types/equipment/equipmentProfile'
import EntityChip from '../ux/EntityChip'
import UserAvatar from '../ux/UserAvatar'
import { userProfileURL } from '../../constants/urlBuilder'
import AssignUserDialog from './AssignUserDialog'
import { IUser } from '../../types/account/user'
import { backend_api, headersAPI } from '../../constants/api'

interface IProps {
  equipId: string
  assignedTo: IOperator | null
  handleAddUser: () => void
}

interface IState {
  assignDialogOpen: boolean
  users: IUser[]
}

class UserSection extends React.Component<IProps, IState> {
  state: IState = {
    assignDialogOpen: false,
    users: [],
  }

  loadUsers() {
    fetch(backend_api + '/users/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          users: response,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.loadUsers()
  }

  render() {
    return (
      <div style={{ width: '100%', margin: '10px 0' }}>
        <Section
          header="User"
          equipId={this.props.equipId}
          emptyMessage={this.props.assignedTo ? undefined : 'no user'}
          editButton
          onEdit={() => this.setState({ assignDialogOpen: true })}
        >
          {this.props.assignedTo && (
            <EntityChip
              name={`${this.props.assignedTo.firstName} ${this.props.assignedTo.lastName}`}
              badge={
                <UserAvatar
                  userId={this.props.assignedTo.id}
                  firstName={this.props.assignedTo.firstName}
                  lastName={this.props.assignedTo.lastName}
                />
              }
              href={userProfileURL(this.props.assignedTo.id)}
            />
          )}
        </Section>
        <AssignUserDialog
          open={this.state.assignDialogOpen}
          onClose={() => this.setState({ assignDialogOpen: false })}
          includeUnassignOption
          users={this.state.users}
        />
      </div>
    )
  }
}

export default UserSection
