import React, { useState } from 'react'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { Button, Popover } from '@mui/material'
import { Day, ITimeRule } from 'src/constants/flagRules'
import Margin from 'src/components/ux/Margin'
import IconButton from 'src/components/buttons/IconButton'
import DaysOfWeekFilter from 'src/components/datePickers/DaysOfWeekFilter'
import HoursOfDayFilter from 'src/components/datePickers/HoursOfDayFilter'

interface ITimeViewProps extends WithStyles<typeof styles> {
  values: ITimeRule[]
  onChange: (values: ITimeRule[]) => void
}

const TimeView = (props: ITimeViewProps) => {
  const [dayAnchorEl, setDayAnchorEl] = useState(undefined as any)
  const [hourAnchorEl, setHourAnchorEl] = useState(undefined as any)

  return (
    <>
      {props.values.map((time, i) => (
        <div key={i} className={props.classes.timeRuleContainer}>
          <div className={props.classes.buttonsContainer}>
            <Button
              variant="outlined"
              className={props.classes.button}
              onClick={(ev) => setDayAnchorEl(ev.currentTarget)}
            >
              {time.days.length === 7
                ? 'Any Day'
                : time.days.join(', ').toUpperCase()}
            </Button>

            <Margin width={10} />

            <Button
              variant="outlined"
              className={props.classes.button}
              onClick={(ev) => setHourAnchorEl(ev.currentTarget)}
            >
              {time.hours[0] === '12AM' && time.hours[1] === '12AM'
                ? 'Any Hour'
                : `${time.hours[0]} to ${time.hours[1]}`}
            </Button>
          </div>

          {props.values.length > 1 && (
            <>
              <Margin width={10} />
              <IconButton
                variant="row"
                onClick={() =>
                  props.onChange(props.values.filter((_, idx) => idx !== i))
                }
                icon="delete"
              />
            </>
          )}

          <Popover
            open={!!dayAnchorEl}
            anchorEl={dayAnchorEl}
            onClose={() => setDayAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <DaysOfWeekFilter
              days={time.days}
              onChange={(days: Day[]) =>
                props.onChange(
                  props.values.map((time, idx) =>
                    idx === i ? { ...time, days } : time
                  )
                )
              }
            />
          </Popover>
          <Popover
            open={!!hourAnchorEl}
            anchorEl={hourAnchorEl}
            onClose={() => setHourAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <HoursOfDayFilter
              hours={time.hours}
              onChange={(hours: [string, string]) =>
                props.onChange(
                  props.values.map((time, idx) =>
                    idx === i ? { ...time, hours } : time
                  )
                )
              }
            />
          </Popover>
        </div>
      ))}
    </>
  )
}

const styles = createStyles({
  flex: {
    flex: 1,
  },
  timeRuleContainer: {
    display: 'flex',
    marginBottom: 10,
  },
  buttonsContainer: {
    flex: 1,
    display: 'flex',
  },
  button: {
    flex: 1,
  },
  addButtonRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
})

export default withStyles(styles)(TimeView)
