import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import Select, { ISelectValues } from '../../ux/Select'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import { countriesArray } from '../../../constants/countries'
import { ICompanyInfo } from '../../../types/account/account'

const styles = (theme: Theme) =>
  createStyles({
    paper: {},
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  companyInformation?: ICompanyInfo
}

interface IState {
  companyName: string
  address: string
  city: string
  stateProvince: string
  postalCode: string
  country: string
  countriesList: ISelectValues[]
  phone: string
  fax: string
}

class EditAccountDialog extends React.Component<IProps, IState> {
  state: IState = {
    companyName: this.props.companyInformation?.CompanyName
      ? this.props.companyInformation.CompanyName
      : '',
    address: this.props.companyInformation?.CompanyAddress
      ? this.props.companyInformation.CompanyAddress
      : '',
    city: this.props.companyInformation?.CompanyCity
      ? this.props.companyInformation.CompanyCity
      : '',
    stateProvince: this.props.companyInformation?.CompanyState
      ? this.props.companyInformation.CompanyState
      : '',
    postalCode: this.props.companyInformation?.CompanyZipCode
      ? this.props.companyInformation.CompanyZipCode
      : '',
    country: this.props.companyInformation?.CompanyCountry
      ? this.props.companyInformation.CompanyCountry
      : 'US',
    countriesList: countriesArray,
    phone: this.props.companyInformation?.CompanyPhone
      ? this.props.companyInformation.CompanyPhone
      : '',
    fax: this.props.companyInformation?.CompanyFax
      ? this.props.companyInformation.CompanyFax
      : '',
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  isSaveButtonDisabled() {
    return !(
      !!this.state.companyName &&
      !!this.state.address &&
      !!this.state.stateProvince &&
      !!this.state.postalCode &&
      !!this.state.country &&
      !!this.state.phone &&
      !!this.state.fax
    )
  }

  componentDidMount() {}

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>Edit Account</DialogTitle>
        <DialogContent>
          <DialogRow
            FieldLeft={
              <TextField
                label="Company Name"
                required
                className={this.props.classes.TextField}
                value={this.state.companyName}
                onChange={(event) =>
                  this.setState({ companyName: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="Address"
                required
                className={this.props.classes.TextField}
                value={this.state.address}
                onChange={(event) =>
                  this.setState({ address: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                label="City"
                required
                className={this.props.classes.TextField}
                value={this.state.city}
                onChange={(event) =>
                  this.setState({ city: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="State / Province"
                required
                className={this.props.classes.TextField}
                value={this.state.stateProvince}
                onChange={(event) =>
                  this.setState({ stateProvince: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                label="Postal Code"
                required
                className={this.props.classes.TextField}
                value={this.state.postalCode}
                onChange={(event) =>
                  this.setState({ postalCode: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <Select
                label="Country"
                required
                selectedValue={this.state.country}
                values={this.state.countriesList}
                onChange={(name: string) =>
                  this.setState({
                    country: name,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="Phone"
                required
                className={this.props.classes.TextField}
                value={this.state.phone}
                onChange={(event) =>
                  this.setState({ phone: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                label="Fax"
                required
                className={this.props.classes.TextField}
                value={this.state.fax}
                onChange={(event) => this.setState({ fax: event.target.value })}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditAccountDialog)
