import React from 'react'
import AssetInformationCard from '../../components/equipmentComponents/AssetInformation'
import MetricsSection from '../../components/equipmentComponents/MetricsSection'
import AttributesSection from '../../components/equipmentComponents/AttributesSection'
import DeviceSection from '../../components/equipmentComponents/DeviceSection'
import UserSection from '../../components/equipmentComponents/UserSection'
import RentalRatesSection from '../../components/equipmentComponents/RentalRatesSection'
import UpcomingMaintenance from '../../components/equipmentComponents/UpcomingMaintenance'
import FlagsSection from '../../components/equipmentComponents/FlagsSection'
import ProfileImageUploader from '../../components/equipmentComponents/ProfileImageUploader'
import { IEquipmentProfile } from '../../types/equipment/equipmentProfile'

interface IProps {
  equipmentProfile?: IEquipmentProfile
  isMobile: boolean
  useMetric: boolean
}

interface IState {}

class EquipmentInfoMobile extends React.Component<IProps, IState> {
  state: IState = {}
  render() {
    return (
      /** PaddingTop is the height of the bar */
      <div style={{ width: '100%', height: '100%', paddingTop: 48 }}>
        {this.props.equipmentProfile ? (
          <ProfileImageUploader
            images={
              this.props.equipmentProfile.imageUrl
                ? this.props.equipmentProfile.imageUrl
                : []
            }
            category={this.props.equipmentProfile.category}
          />
        ) : null}
        {this.props.equipmentProfile ? (
          <FlagsSection flags={this.props.equipmentProfile.flags} />
        ) : null}
        {this.props.equipmentProfile ? (
          <UpcomingMaintenance
            tasks={this.props.equipmentProfile.tasks}
            equipId={this.props.equipmentProfile.id}
          />
        ) : null}
        {this.props.equipmentProfile ? (
          <AssetInformationCard
            equipmentProfile={this.props.equipmentProfile}
            isMobile
            /** @todo connect the useMetric variable to this */
            useMetric
            equipId={this.props.equipmentProfile.id}
          />
        ) : null}
        {this.props.equipmentProfile ? (
          <DeviceSection
            equipmentProfile={this.props.equipmentProfile}
            isMobile={this.props.isMobile}
          />
        ) : null}
        {this.props.equipmentProfile ? (
          <UserSection
            assignedTo={this.props.equipmentProfile.operator}
            equipId={this.props.equipmentProfile.id}
            handleAddUser={() => console.log()}
          />
        ) : null}
        {this.props.equipmentProfile ? (
          <MetricsSection
            /** @todo connect the useMetric variable to this */
            useMetric
            timezone="denver"
            equipmentProfile={this.props.equipmentProfile}
          />
        ) : null}
        {this.props.equipmentProfile ? (
          <AttributesSection
            customAttributes={
              this.props.equipmentProfile.customAttributes
                ? this.props.equipmentProfile?.customAttributes
                : []
            }
          />
        ) : null}
        {this.props.equipmentProfile ? (
          <RentalRatesSection
            equipId={this.props.equipmentProfile.id.toString()}
            rentalRates={this.props.equipmentProfile.rentalRates}
            isMobile={this.props.isMobile}
          />
        ) : null}
      </div>
    )
  }
}

export default EquipmentInfoMobile
