import React from 'react'
import { colors } from '../ux/roviTheme'
import { Link } from 'react-router-dom'
import { ERoutes } from '../../components/routes/CheckRoutes'

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
    color: colors.textGrey,
  },
  clickableText: {
    color: colors.primaryBlue,
    cursor: 'pointer',
  },
}

type IProps = {
  containerStyle?: React.CSSProperties
  clickableTextStyle?: React.CSSProperties
  clickableText: string
  href?: ERoutes
  onClick?: () => void
  id?: string
}

const StartByText: React.FC<IProps> = (props) => (
  <div style={{ ...styles.container, ...props.containerStyle }}>
    <div>
      Start by{' '}
      {props.href ? (
        <Link
          id={props.id}
          style={{ ...styles.clickableText, ...props.clickableTextStyle }}
          onClick={props.onClick}
          to={props.href}
        >
          {props.clickableText}
        </Link>
      ) : (
        <span
          id={props.id}
          style={{ ...styles.clickableText, ...props.clickableTextStyle }}
          onClick={props.onClick}
        >
          {props.clickableText}
        </span>
      )}
    </div>
  </div>
)

export default StartByText
