import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { backend_api, headersAPI } from '../../../constants/api'
import moment from 'moment'
import { ICategory } from '../../../types/category'
import Select, { ISelectValues } from '../../ux/Select'
import { countBackwardsInYears } from '../../../constants/yearBackwardsCount'
import TextField from '../../textBoxs/TextField'
import InfoHover from '../../buttons/InfoHover'
import DatePicker from '../../datePickers/DatePicker'
import Button from '../../buttons/Button'
import { IUser } from '../../../types/account/user'
import NumberField from '../../textBoxs/NumberField'

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    DatePicker: {
      width: 250,
      marginBottom: 0,
    },
  })

function SortCategoriesintoSelectValue(cateogories: ICategory[]) {
  let selectCategories: ISelectValues[] = []
  cateogories.forEach((category) => {
    selectCategories.push({ value: category.id, label: category.name })
  })
  return selectCategories
}

function SortUsersintoSelectValue(users: IUser[]) {
  let selectUsers: ISelectValues[] = []
  users.forEach((user) => {
    selectUsers.push({
      value: user.id,
      label: user.firstName + ' ' + user.lastName,
    })
  })
  return selectUsers
}

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  closeDialog: () => void
  isMobile: boolean
}

interface IState {
  // Category
  categories: ICategory[]
  selectCategoryValue: ISelectValues | string
  // User
  users: IUser[]
  selectUserValue: ISelectValues | string
  // Year
  selectYearValue: ISelectValues | string
  // Purchase Date
  selectedDate: Date | null
  // ID
  ID: string
  // Vin
  VIN: string
  // Make
  make: string
  // Model
  model: string
  // SN
  serialNumber: string
  // Purchase Price
  price: number | null
}

class AddEquipmentDialog extends React.Component<IProps, IState> {
  state: IState = {
    categories: [],
    users: [],
    selectCategoryValue: '',
    selectUserValue: '',
    selectYearValue: '',
    selectedDate: null,
    ID: '',
    VIN: '',
    make: '',
    model: '',
    serialNumber: '',
    price: null,
  }

  handleClose = () => {
    this.props.closeDialog()
  }

  handleSubmit = () => {
    this.props.closeDialog()
  }

  loadUsers() {
    fetch(backend_api + '/users/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          users: response,
        })
      })
      .catch((err) => console.log(err))
  }

  loadCategories() {
    fetch(backend_api + '/categories/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          categories: response,
        })
      })
      .catch((err) => console.log(err))
  }

  isSaveButtonDisabled() {
    return !(
      !!this.state.selectCategoryValue &&
      !!this.state.ID &&
      !!this.state.make &&
      !!this.state.model
    )
  }

  onSubmit() {
    console.log({
      category: this.state.selectCategoryValue,
      ID: this.state.ID,
      VIN: this.state.VIN,
      make: this.state.make,
      model: this.state.model,
      year: this.state.selectYearValue,
      sn: this.state.serialNumber,
      price: this.state.price,
      purchaseDate: this.state.selectedDate,
      user: this.state.selectUserValue,
    })
  }

  componentDidMount() {
    this.loadCategories()
    this.loadUsers()
  }

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>Add Equipment</DialogTitle>
        <DialogContent>
          <DialogRow
            FieldLeft={
              <Select
                label="Category"
                selectedValue={this.state.selectCategoryValue}
                values={SortCategoriesintoSelectValue(this.state.categories)}
                onChange={(event) =>
                  this.setState({
                    selectCategoryValue: event.target.value as ISelectValues,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                id="addEquipmentDialogIdTextInput"
                label="ID"
                required
                value={this.state.ID}
                onChange={(event) => this.setState({ ID: event.target.value })}
                className={this.props.classes.TextField}
              />
            }
            FieldRight={
              <TextField
                id="addEquipmentDialogVinTextInput"
                label="VIN"
                className={this.props.classes.TextField}
                value={this.state.VIN}
                onChange={(event) => this.setState({ VIN: event.target.value })}
              />
            }
            FieldRightAddOn={
              <InfoHover>
                <div style={{ margin: '8px 0' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    Why Is My Vin Invalid?{' '}
                  </span>
                  Vins should be 17 characters long, avoid the letters I, O, Q,
                  and all letters should be uppercase. The vin should also pass
                  the{' '}
                  <a
                    href="https://vin.dataonesoftware.com/vin_basics_blog/bid/112040/use-vin-validation-to-improve-inventory-quality"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    VIN Checksum Algorithm
                  </a>
                  . An invalid vin can be saved to an equipment.
                </div>
              </InfoHover>
            }
          />

          <DialogRow
            FieldLeft={
              <TextField
                id="addEquipmentDialogMakeTextInput"
                label="Make"
                required
                className={this.props.classes.TextField}
                value={this.state.make}
                onChange={(event) =>
                  this.setState({ make: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                id="addEquipmentDialogModelTextInput"
                label="Model"
                required
                className={this.props.classes.TextField}
                value={this.state.model}
                onChange={(event) =>
                  this.setState({ model: event.target.value })
                }
              />
            }
          />

          <DialogRow
            FieldLeft={
              <Select
                id="addEquipmentDialogYearTextInput"
                label="Year Built"
                selectedValue={this.state.selectYearValue}
                values={countBackwardsInYears(moment().format('YYYY'), 100)}
                onChange={(event) =>
                  this.setState({
                    selectYearValue: event.target.value as ISelectValues,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
            FieldRight={
              <TextField
                id="addEquipmentDialogSerialTextInput"
                label="Serial Number"
                className={this.props.classes.TextField}
                value={this.state.serialNumber}
                onChange={(event) =>
                  this.setState({ serialNumber: event.target.value })
                }
              />
            }
          />

          <DialogRow
            FieldLeft={
              <NumberField
                id="addEquipmentDialogPurchasePriceInput"
                value={this.state.price}
                onChange={(event) => this.setState({ price: event })}
                startAdornment="$"
                label="Purchase Price"
                className={this.props.classes.TextField}
              />
            }
            FieldRight={
              <DatePicker
                id="addEquipmentDialogPurchaseDateInput"
                selectedDate={this.state.selectedDate}
                onChange={(date: Date | null) => {
                  console.log(date)
                  this.setState({ selectedDate: date })
                }}
                showToolbar
                className={this.props.classes.DatePicker}
                label="Purchase Date"
              />
            }
          />

          <DialogRow
            FieldLeft={
              <Select
                label="User"
                selectedValue={this.state.selectUserValue}
                values={SortUsersintoSelectValue(this.state.users)}
                onChange={(event) =>
                  this.setState({
                    selectUserValue: event.target.value as ISelectValues,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="addEquipmentDialogCancelAddEquipment"
            onClick={this.handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AddEquipmentDialog)
