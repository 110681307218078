import { headersAPI, api } from '../../constants/api'
import {
  IGeofence,
  IGeofenceRaw,
  IGeofenceRawCoords,
} from '../../types/maptrac/Geofences'
import { geofenceLATLNGSwitch } from './getAllGeofenceData'

const getGeofenceInfoByUUID = (geofenceUUID: string) => {
  return fetch(api + '/geofence/get/' + geofenceUUID, {
    method: 'GET',
    headers: headersAPI,
  })
    .then((response) => response.json())
    .then((response: { geofence: IGeofenceRaw[] }) => {
      const parseRawGeofence = response.geofence.map((geoRaw) => {
        const coordinfo: IGeofenceRawCoords = JSON.parse(geoRaw.coordinates)
        const latLngInfo = geofenceLATLNGSwitch(coordinfo.coordinates)
        return {
          geofenceId: geoRaw.id,
          geofenceName: geoRaw.name,
          geofenceAssetCount: 0,
          points: latLngInfo,
          type: coordinfo.type.toLowerCase(),
        } as IGeofence
      })[0]
      return parseRawGeofence as IGeofence
    })
    .catch((err) => console.error(err))
}

export default getGeofenceInfoByUUID
