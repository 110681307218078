import * as Sentry from '@sentry/browser'

/**
 * grabs the hostname and checks to see if its live, if it is to @returns false, other wise @returns true.
 */
const isLocal =
  window.location.hostname === 'app.rovitracker.com' ? false : true

/**
 * console logging to remove logging on client side.
 * and if it does log, to log to sentry.
 * this function was created to remove any logs that may interupt user browser information.
 */
export const RoviLog = {
  /**
   * replaces console.log
   */
  log: (val?: any, ...optionalParams: any[]) => {
    isLocal && console.log(val, ...optionalParams)
  },
  /**
   * replaces console.error while sending info to sentry if triggered.
   */
  error: (val?: any, ...optionalParams: any[]) => {
    isLocal && console.error(val, ...optionalParams)
    Sentry.captureException(val + optionalParams)
  },
  /**
   * replaces console.warn.
   */
  warn: (val?: any, ...optionalParams: any[]) => {
    isLocal && console.warn(val, ...optionalParams)
  },
  /**
   * just like a console.log but this one is a sentry message one using captureMessage.
   * @Levels
   * @param Fatal = "fatal",
   * @param Error = "error",
   * @param Warning = "warning",
   * @param Log = "log",
   * @param Info = "info",
   * @param Debug = "debug",
   * @param Critical = "critical"
   */
  info: (
    val: string,
    level?:
      | 'fatal'
      | 'error'
      | 'warning'
      | 'log'
      | 'info'
      | 'debug'
      | 'critical'
  ) => {
    // would have set the level to Sentry.Severity but it would'nt allow.
    isLocal && console.info(val)
    Sentry.captureMessage(val, level as any)
  },
}

/**
 * Simply combines the name of an error and the object error.
 * @param ErrName has to be a string as this just explains what it is doing.
 * @param ErrObj Any type of Error Value it should be able to take it.
 */
export const ObjErr = (ErrName: string, ErrObj: any) => {
  return ErrName + JSON.stringify(ErrObj)
}
