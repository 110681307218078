import React from 'react'
import EntityDetailsPanel from '../../EntityDetailsPanel'
import { ICategory } from '../../../../types/category'
import { colors } from '../../../ux/roviTheme'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { backend_api, headersAPI } from '../../../../constants/api'
import { LoadingIcon } from '../../../LoadingOverlay'
import AssetListItem from '../maptracLiveAsset/AssetListItem'
import { IAsset } from '../maptracLiveSites/SiteDetailsMaptrac'
import ListSubheader from '@mui/material/ListSubheader'
import SiteListItem from '../maptracLiveSites/ListItemSite'
import { ILatLng } from 'src/types/generated'
import postDeleteGeofence from '../../../../apiCalls/postCalls/postDeleteGeofence'
import getGeofenceInfoByUUID from '../../../../apiCalls/getCalls/getGeofenceInfoByUUID'
import SnackBar from '../../../ux/SnackBar'
import { TErrorTypes } from '../../../../types/apiCalls'
import { IGeofenceInfo, IGeofence } from '../../../../types/maptrac/Geofences'
import { getHash } from '../../../../constants/hashGrabber'
import { IMaptracHash } from '../../../../constants/maptracHashController'

const styles = createStyles({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    fontSize: 12,
    height: 15,
  },
  centerRow: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'white',
    width: 16,
    height: 16,
  },
  noItemsMessage: {
    fontSize: 16,
    padding: 20,
    textAlign: 'center',
    color: colors.textGrey,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  flagsIcon: {
    color: 'white',
  },
  spacing: {
    marginRight: 8,
  },
})

interface IDetailsProps extends WithStyles<typeof styles> {
  category: ICategory
  make: string
  model: string
}

interface IProps extends WithStyles<typeof styles> {
  isMobile: boolean
  onBack: () => void
  assetClicked: (id: any) => void
  siteClicked: (id: any, gps: ILatLng) => void
  onReload: () => void
  unFocus: () => void
  setEditedGeofence: (val: IGeofenceInfo) => void
}

interface IState {
  geofence?: IGeofenceInfo
  snackBarMessage: string
  snackBarError: TErrorTypes
}

class GeofenceDetailsMaptrac extends React.Component<IProps, IState> {
  state: IState = {
    geofence: undefined,
    snackBarMessage: '',
    snackBarError: 'error',
  }

  deleteGeofence = () => {
    if (this.state.geofence) {
      postDeleteGeofence(this.state.geofence.geofenceId).then((response) => {
        this.setState({
          snackBarError: response.type,
          snackBarMessage: response.snackBarMessage,
        })
        if (response.type === 'success') {
          this.props.unFocus()
          this.props.onReload()
        }
      })
    }
  }

  grabGeofenceDetail = async (geofenceUUID: string) => {
    const geofenceInfo = (await getGeofenceInfoByUUID(
      geofenceUUID
    )) as IGeofence

    setTimeout(() => this.setState({ geofence: geofenceInfo }), 1000)
  }

  /**
   * Geofence Mockoon API grabing basic information
   */
  grabMaptracFocusGeofence = () => {
    fetch(backend_api + '/maptrac/geofence/focus', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ geofence: response })
      })
      .catch((err) => console.error(err))
  }

  geofenceMounted(hashgrabbed: IMaptracHash | undefined) {
    if (!!hashgrabbed) {
      if (hashgrabbed.id) {
        this.grabGeofenceDetail(hashgrabbed.id)
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      const hashgrabbed = getHash() ? (getHash() as IMaptracHash) : undefined
      this.geofenceMounted(hashgrabbed)
    }, 500)
  }

  render() {
    return this.state.geofence ? (
      <>
        <EntityDetailsPanel
          onBack={this.props.onBack}
          isMobile={this.props.isMobile}
          width={350}
          title={this.state.geofence.geofenceName}
          navItems={[
            {
              label: 'Edit',
              icon: 'edit',
              onClick: () => {
                if (this.state.geofence) {
                  this.props.setEditedGeofence(this.state.geofence)
                }
              },
            },
            {
              label: 'Delete',
              icon: 'delete',
              onClick: () => this.deleteGeofence(),
            },
          ]}
        >
          {this.state.geofence.geofenceAssets ? (
            <>
              <ListSubheader inset={false}>
                Assets ({this.state.geofence.geofenceAssets.length})
              </ListSubheader>
              {this.state.geofence.geofenceAssets.map((equip: IAsset, key) => (
                <AssetListItem
                  key={key}
                  id={equip.id}
                  name={equip.name}
                  make={equip.make}
                  model={equip.model}
                  imageUrl={equip.imageUrl}
                  icon={equip.category.icon}
                  onClick={() => this.props.assetClicked(equip.id)}
                  tasks={equip.tasks}
                  user={equip.user}
                  hasCamera={equip.hasCamera}
                  battery={equip.battery}
                  ignition={equip.ignition}
                  flags={equip.flags}
                />
              ))}
            </>
          ) : (
            <ListSubheader inset={false}>Assets (0)</ListSubheader>
          )}
          {this.state.geofence.geofenceSites ? (
            <>
              <ListSubheader inset={false}>
                Sites ({this.state.geofence.geofenceSites.length})
              </ListSubheader>
              {this.state.geofence.geofenceSites.map((site, key2) => (
                <SiteListItem
                  key={key2}
                  siteName={site.siteName}
                  siteAssetCount={site.siteAssetCount}
                  onClick={() =>
                    this.props.siteClicked(site.siteId, site.siteGps)
                  }
                />
              ))}
            </>
          ) : (
            <ListSubheader inset={false}>Sites (0)</ListSubheader>
          )}
        </EntityDetailsPanel>
        <SnackBar
          message={this.state.snackBarMessage}
          onClose={() => this.setState({ snackBarMessage: '' })}
          variant={this.state.snackBarError}
        />
      </>
    ) : (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingIcon loading />
      </div>
    )
  }
}

export default withStyles(styles)(GeofenceDetailsMaptrac)
