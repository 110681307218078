import React from 'react'
import EntityDetailsPanel from '../../EntityDetailsPanel'
// import LocationDetailsPopover from '../mapComponents/LocationDetailsPopover'
import CategoryIcon from '../../../ux/CategoryIcon'
import { ICategory } from '../../../../types/category'
import TabsContainer from '../../../ux/TabsContainer'
import Divider from '@mui/material/Divider'
import { colors } from '../../../ux/roviTheme'
import HoverContainer from '../../../ux/HoverContainer'
import FlagButton from '../../../ux/FlagButton'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import classNames from 'classnames'
import UserAvatar from '../../../ux/UserAvatar'
import EllipsisText from '../../../ux/EllipsisText'
import { backend_api, headersAPI } from '../../../../constants/api'
import { LoadingIcon } from '../../../LoadingOverlay'
import LocationDetailsPopover from '../../LocationDetailsPopover'

interface IAsset {
  assetId: string
  assetName: string
  assetMake: string
  assetModel: string
  assetUser: {
    userId: string
    firstName: string
    lastName: string
    role: string
  }
  assetLat: number
  assetLng: number
  imageUrl?: string
  assetCategory: ICategory
  assetGatewayHistory?: any[]
  isGateway?: boolean
  assetTagsHistory?: any[]
  isTag?: boolean
  assetTrips: any[]
  assetMetrics: {
    field: string
    value: Date
  }[]
  assetFlags: {
    flagId: string
    flagName: string
  }[]
}

const styles = createStyles({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    fontSize: 12,
    height: 15,
  },
  centerRow: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'white',
    width: 16,
    height: 16,
  },
  noItemsMessage: {
    fontSize: 16,
    padding: 20,
    textAlign: 'center',
    color: colors.textGrey,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  flagsIcon: {
    color: 'white',
  },
  spacing: {
    marginRight: 8,
  },
})

interface IDetailsProps extends WithStyles<typeof styles> {
  category: ICategory
  make: string
  model: string
}

const Details = withStyles(styles)((props: IDetailsProps) => (
  <div className={props.classes.detailsContainer}>
    {props.category ? (
      <div className={props.classes.detailsSection}>
        <div className={props.classes.centerRow}>
          <CategoryIcon
            className={classNames(props.classes.icon, props.classes.spacing)}
            icon={props.category ? props.category.icon : 'atv'}
          />
          {props.category.name}
        </div>
        {`${props.make} / ${props.model}`}
      </div>
    ) : null}

    {/* {props.agreement
      .map((a) => (
        <div className={props.classes.detailsSection}>
          <div
            className={props.classes.centerRow}
            style={{ cursor: 'pointer' }}
            onClick={a.onClickSite}
          >
            <Icon
              className={classNames(props.classes.icon, props.classes.spacing)}
              icon="site"
            />
            {a.clientName} / {a.siteName}
          </div>
        </div>
      ))
      .orNull()} */}
  </div>
))

interface IProps extends WithStyles<typeof styles> {
  equip: any
  isMobile: boolean
  onBack: () => void
  onClickEntity: (id: any) => void
  timezone: string
  onClickCamera: () => void
  onClickTrip: (date: any, endDate: any) => void
  width: number
  gatewayHistoryObs: any
  tagsHistoryObs: any
  setDate: (date: any) => void
  setFocus: (entity: any) => void
}

interface IState {
  asset?: IAsset
}

class AssetDetailsMaptrac extends React.Component<IProps, IState> {
  state: IState = {
    asset: undefined,
  }

  grabMaptracFocusAsset = () => {
    fetch(backend_api + '/maptrac/assets/focus', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        this.setState({ asset: response })
      })
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    this.grabMaptracFocusAsset()
  }

  render() {
    return this.state.asset ? (
      <EntityDetailsPanel
        onBack={this.props.onBack}
        isMobile={this.props.isMobile}
        width={this.props.width}
        titleDense={this.state.asset.assetName}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocationDetailsPopover
              gps={{ lat: 39.478105, lng: -113.7953739 }}
              timezone="utc"
            />
            <div className={this.props.classes.spacing}>
              <EllipsisText text={this.state.asset.assetName} width={100} />
            </div>
            <FlagButton
              assetId={'56'}
              flags={this.state.asset.assetFlags}
              white
            />

            <div style={{ marginLeft: 8 }}>
              {this.state.asset.assetUser ? (
                <UserAvatar
                  userId={this.state.asset.assetUser.userId}
                  firstName={this.state.asset.assetUser.firstName}
                  lastName={this.state.asset.assetUser.lastName}
                  variant="small"
                />
              ) : null}
            </div>
          </div>
        }
        imageUrl={this.state.asset.imageUrl}
        category={this.state.asset.assetCategory.icon}
        details={
          // <div>details{console.log(this.props.equip)}</div>
          <Details
            category={this.state.asset.assetCategory}
            make={this.state.asset.assetMake}
            model={this.state.asset.assetModel}
          />
        }
        navItems={[
          {
            label: 'Profile',
            icon: 'profile',
            href: '/',
          },
          {
            label: 'Timeline',
            icon: 'timeline',
            href: '/',
          },
          {
            label: 'Events',
            icon: 'events',
            href: '/',
          },
        ]}
        isEquip
      >
        <>
          {/* {console.log(this.state.asset)} */}
          {/* {console.log(this.props.equip) /** '' */}
          {/* {console.log(this.props.equip.trips) /** undefined */}
          <TabsContainer
            labels={
              this.state.asset.isGateway
                ? ['Metrics', 'Trips', 'Tag History']
                : this.state.asset.isTag
                ? ['Metrics', 'Trips', 'History']
                : ['Metrics', 'Trips']
            }
          >
            {this.state.asset.assetMetrics ? (
              <div>
                {/* Double loaded to test some things */}
                {this.state.asset.assetMetrics.map((metric, key) => (
                  <div key={key}>
                    {metric.field} {metric.value}
                  </div>
                ))}
                {this.state.asset.assetMetrics.map((metric, key) => (
                  <div key={key}>
                    {metric.field} {metric.value}
                  </div>
                ))}
              </div>
            ) : (
              <div className={this.props.classes.noItemsMessage}>
                No Metrics
              </div>
            )}

            {this.state.asset.assetTrips.length === 0 ? (
              <div className={this.props.classes.noItemsMessage}>No Trips</div>
            ) : (
              this.state.asset.assetTrips.map((trips: any[], tripKey: any) => (
                <HoverContainer key={tripKey}>
                  {({ hovered }) => (
                    <div
                      style={{
                        cursor: 'pointer',
                        background: hovered ? colors.hoverGrey : undefined,
                      }}
                      onClick={() => console.log()}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '20px 10px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div>From </div>
                          <div></div>
                        </div>
                        <div>
                          <div>Duration: minutes</div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div>To</div>
                          <div></div>
                        </div>
                      </div>
                      <Divider />
                    </div>
                  )}
                </HoverContainer>
              ))
            )}

            {/* History Box - this area splits up depending on the device it self, so if its a gateway 
                it can contain the history of any tag its picked up and display it here. Now if its a
                Tag it will only show its own history if there is any. In the past alain has wanted
                tag history to only show up if it has been recent along side some time stamps. */}
            {this.state.asset.isGateway ? (
              this.state.asset.assetGatewayHistory &&
              this.state.asset.assetGatewayHistory.length !== 0 ? (
                this.state.asset.assetGatewayHistory.map(
                  (gatewayHist, gHKey) => <div key={gHKey}>history</div>
                )
              ) : (
                <div className={this.props.classes.noItemsMessage}>
                  No Tag History
                </div>
              )
            ) : this.state.asset.isTag ? (
              this.state.asset.assetTagsHistory &&
              this.state.asset.assetTagsHistory.length !== 0 ? (
                this.state.asset.assetTagsHistory.map((tagHist, tHKey) => (
                  <div key={tHKey}>history</div>
                ))
              ) : (
                <div className={this.props.classes.noItemsMessage}>
                  No History
                </div>
              )
            ) : null}
          </TabsContainer>
        </>
      </EntityDetailsPanel>
    ) : (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingIcon loading />
      </div>
    )
  }
}

export default withStyles(styles)(AssetDetailsMaptrac)
