import React from 'react'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import { ERoutes, PageTitle } from '../components/routes/CheckRoutes'
import Typography from '@mui/material/Typography'
import FabButton from '../components/buttons/FabButton'
import FlagCard, {
  PhantomFlagCard
} from '../components/flagComponents/FlagCard'
import FlagDialog from 'src/components/flagComponents/flagDialog/FlagDialog'
import { IFlagRule } from '../constants/flagRules'

interface IFlagPageProps {
}

interface IFlagPageState {
  flags: IFlagRule[]
  editingFlagIndex?: number
}

class FlagsPage extends React.Component<IFlagPageProps, IFlagPageState> {
  constructor(props: IFlagPageProps) {
    super(props)
    this.state = {
      editingFlagIndex: undefined,
      flags: [
        {
          name: '1',
          rules: []
        },
        {
          name: '2',
          rules: []
        },
        {
          name: '3',
          rules: []
        },
        {
          name: '4',
          rules: []
        },
        {
          name: '5',
          rules: []
        },
        {
          name: '6',
          rules: []
        },
        {
          name: '7',
          rules: []
        }]
    }

    this.onClickOpenDialog = this.onClickOpenDialog.bind(this)
  }

  get isCreatingNewFlag() {
    return this.state.editingFlagIndex === -1
  }

  onClickOpenDialog(flagToEditIndex: number) {
    this.setState({
      editingFlagIndex: flagToEditIndex
    })
  }

  onCloseDialog(wasSaved: boolean, updatedFlag?: IFlagRule) {
    const { flags } = this.state

    if (wasSaved && updatedFlag) {
      if (this.isCreatingNewFlag) {
        flags.push(updatedFlag)
      } else {
        flags.splice(this.state.editingFlagIndex!, 1, updatedFlag)
      }
    }

    this.setState({
      editingFlagIndex: undefined,
      flags
    })
  }

  onDeleteFlag(flagIndex: number) {
    const { flags } = this.state
    flags.splice(flagIndex, 1)
    this.setState({
      flags
    })
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <ReactHelmet title={PageTitle(ERoutes.flag)} />
        <Toolbar title={PageTitle(ERoutes.flag)} noXtraBar />
        <div style={{ marginTop: 50 }}>
          <Typography variant='h6' style={{ paddingTop: 10, paddingLeft: 20 }}>
            Standard
          </Typography>
          <div
            style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 20px' }}
          >
            <FlagCard
              flag={{ name: 'Outside Asset Boundary', rules: [] }}
              useMetric
              assets={[{}]}
              canManage
              isFocused
            />
            <FlagCard
              flag={{ name: 'Places Alert', rules: [] }}
              useMetric
              assets={[{}]}
              canManage
              isFocused
            />
            <PhantomFlagCard />
            <PhantomFlagCard />
            <PhantomFlagCard />
            <PhantomFlagCard />
            <PhantomFlagCard />
          </div>
          <Typography variant='h6' style={{ paddingTop: 10, paddingLeft: 20 }}>
            Custom
          </Typography>
          <div
            style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 20px' }}
          >
            {this.state.flags.map((flag, flagIndex) => (
              <FlagCard
                key={flag.name + flagIndex}
                flag={flag}
                onDeleteFlag={() => this.onDeleteFlag(flagIndex)}
                onEditFlag={() => this.onClickOpenDialog(flagIndex)}
                useMetric
                assets={[{}]}
                canManage
                isFocused
              />
            ))}
            <PhantomFlagCard />
            <PhantomFlagCard />
            <PhantomFlagCard />
            <PhantomFlagCard />
            <PhantomFlagCard />
          </div>
        </div>
        <FabButton icon='add' onClick={() => this.onClickOpenDialog(-1)} />

        <FlagDialog
          open={this.state.editingFlagIndex !== undefined}
          onClose={() => this.onCloseDialog(false)}
          onSave={(flag) => this.onCloseDialog(true, flag)}
          equipment={{}}
          geofences={{}}
          categories={{}}
          flag={!this.isCreatingNewFlag ? this.state.flags[this.state.editingFlagIndex!] : undefined}
          useMetricMeasurement={false}
        />
      </div>
    )
  }
}

export default FlagsPage
