import React from 'react'
import '../../../styles/components/accountComponents/AccountBranch.scss'
import IconButton from '../../buttons/IconButton'
import { IBranchUser } from '../../../types/account/account'
import { label } from '../../../types/account/user'
import { IAssetBase } from '../../../types/assets'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import BranchThingsPopover from '../../ux/BranchThingsPopover'
import DeleteUserDialog from '../dialogs/DeleteUserDialog'

const styles = createStyles({
  paper: {
    margin: 20,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  table: {},
  hideLastBorder: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
})

interface IProps extends WithStyles<typeof styles> {
  user: IBranchUser
  isMobile?: boolean
}

interface IState {
  deleteUserDialog: boolean
}

class BranchUserRow extends React.Component<IProps, IState> {
  state: IState = {
    deleteUserDialog: false,
  }

  splitByNames(assets: IAssetBase[]) {
    let newNames: string[] = []
    assets.forEach((asset) => newNames.push(asset.name))
    return newNames
  }

  render() {
    const classes = this.props.classes
    const user = this.props.user
    return (
      <>
        <TableRow className={classes.hideLastBorder}>
          <TableCell component="th" scope="row">
            {user.firstName} {user.lastName}
          </TableCell>
          <TableCell>{label[user.role]}</TableCell>
          <TableCell>
            {user.assignedAssets ? (
              user.assignedAssets.length > 0 ? (
                <BranchThingsPopover
                  values={this.splitByNames(user.assignedAssets)}
                />
              ) : null
            ) : null}
          </TableCell>
          <TableCell align="right">
            <IconButton
              icon="delete"
              variant="row"
              tooltip="Delete"
              onClick={() => this.setState({ deleteUserDialog: true })}
            />
          </TableCell>
        </TableRow>
        <DeleteUserDialog
          dialogOpen={this.state.deleteUserDialog}
          onClose={() => this.setState({ deleteUserDialog: false })}
          userName={user.firstName + ' ' + user.lastName}
          userId={user.id}
        />
      </>
    )
  }
}

export default withStyles(styles)(BranchUserRow)
