import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import TextField from '../../textBoxs/TextField'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import NumberField from '../../textBoxs/NumberField'
import Popover from '@mui/material/Popover'
import MenuList from '@mui/material/MenuList'
import { IUser } from '../../../types/account/user'
import IconButton from '../../buttons/IconButton'
import { TTaskMetric, ETaskMetric, ITask } from '../../../types/service/tasks'
import Chip from '@mui/material/Chip'
import GroupTaskDialogPart, { endAdorn } from './GroupTaskDialogPart'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'

const styles = (theme: Theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(2),
      display: 'flex',
    },
    selectContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    select: {
      marginTop: 2,
      marginRight: theme.spacing(1),
      width: 147,
    },
    usersContainer: {
      padding: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    userChip: {
      margin: 2,
    },
    menu: {
      maxHeight: 300,
    },
    intervalInput: {
      width: 147,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  task?: ITask
  onSubmit: (task: any) => any
  open: boolean
  onClose: () => void
  isMobile?: boolean
  type: 'add' | 'edit'
  users: IUser[]
  useMetric: boolean
  maintenanceType?: 'single' | 'group'
}

interface IState {
  scheduleType?: TTaskMetric
  taskName?: string
  usersNotfiy: string[]
  usersPopover?: Element | ((element: Element) => Element) | null
  maintenanceType: 'single' | 'group'
}

class TaskDialog extends React.Component<IProps, IState> {
  state: IState = {
    usersNotfiy: [],
    maintenanceType: this.props.maintenanceType
      ? this.props.maintenanceType
      : 'single',
  }

  UpdateUsersList(addUser: boolean, userId: string, usersList: string[]) {
    let newUserArr = usersList
    let userIndex = usersList.indexOf(userId)
    if (addUser) {
      newUserArr.push(userId)
    } else {
      newUserArr.splice(userIndex, 1)
    }
    this.setState({
      usersNotfiy: newUserArr,
    })
  }

  updateUsersAlreadyAssigned(task?: ITask) {
    if (task) {
      this.setState({ usersNotfiy: task.users.map((user) => user.id) })
    }
  }

  componentDidMount() {
    this.updateUsersAlreadyAssigned(this.props.task)
  }

  render() {
    const classes = this.props.classes
    const edited = this.props.type === 'edit'
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullScreen={this.props.isMobile}
        fullWidth
      >
        <DialogTitle>{edited ? 'Edit' : 'Add'} Maintenance Task</DialogTitle>
        <DialogContent style={{ minHeight: 70 }}>
          <Tabs
            value={this.state.maintenanceType}
            onChange={(_, tabValue) =>
              this.setState({ maintenanceType: tabValue })
            }
            variant="fullWidth"
          >
            <Tab
              label="Single Task"
              value="single"
              disabled={
                this.props.maintenanceType
                  ? !!(this.props.maintenanceType === 'group')
                  : false
              }
            />
            <Tab
              label="Grouped Task"
              value="group"
              disabled={
                this.props.maintenanceType
                  ? !!(this.props.maintenanceType === 'single')
                  : false
              }
            />
          </Tabs>
          <Divider />
        </DialogContent>
        {this.state.maintenanceType === 'group' ? (
          <GroupTaskDialogPart
            task={this.props.task}
            onSubmit={this.props.onSubmit}
            open={this.props.open}
            onClose={this.props.onClose}
            isMobile={this.props.isMobile}
            type={this.props.type}
            users={this.props.users}
            useMetric={this.props.useMetric}
          />
        ) : (
          <div style={{ minHeight: '100%' }}>
            <DialogContent>
              {/* <ErrorText>{conflict}</ErrorText> */}

              <div className={classes.row}>
                <TextField
                  defaultValue={this.props.task?.taskName}
                  value={this.state.taskName}
                  required
                  label="Task Name"
                  autoFocus
                  onChange={(event) =>
                    this.setState({ taskName: event.target.value })
                  }
                />
              </div>

              <div className={classes.row}>
                <div className={classes.selectContainer}>
                  <FormControl variant="standard" sx={{ m: 1 }}>
                    <InputLabel shrink>Schedule</InputLabel>
                    <Select
                      defaultValue={
                        this.props.task
                          ? this.props.task.metric
                          : ETaskMetric.engineHours
                      }
                      value={this.state.scheduleType}
                      onChange={(event) =>
                        this.setState({
                          scheduleType: event.target.value as TTaskMetric,
                        })
                      }
                      className={classes.select}
                      disabled={edited}
                    >
                      <MenuItem value={ETaskMetric.time}>Time</MenuItem>
                      <MenuItem value={ETaskMetric.engineHours}>
                        Engine Hours
                      </MenuItem>
                      <MenuItem value={ETaskMetric.odometer}>Odometer</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* This Number field cant handle dates of course, so when single date selection is created we will need to switch between the 2 */}
                <NumberField
                  endAdornment={endAdorn(this.state.scheduleType)}
                  label="Interval"
                  className={classes.intervalInput}
                />
              </div>

              <div className={classes.row}>
                <div className={classes.selectContainer}>
                  <InputLabel shrink>Notify Users</InputLabel>
                  <div className={classes.usersContainer}>
                    {this.state.usersNotfiy.map((userId) => {
                      const user = this.props.users.find(
                        (u: IUser) => u.id === userId
                      )
                      return (
                        <Chip
                          key={userId}
                          size="small"
                          label={
                            user ? `${user.firstName} ${user.lastName}` : ''
                          }
                          className={classes.userChip}
                          onDelete={() =>
                            this.UpdateUsersList(
                              false,
                              userId,
                              this.state.usersNotfiy
                            )
                          }
                        />
                      )
                    })}

                    {this.props.users.length > 0 && (
                      <IconButton
                        icon="add"
                        variant="row"
                        onClick={(event) =>
                          this.setState({ usersPopover: event.currentTarget })
                        }
                      />
                    )}
                  </div>
                </div>

                <Popover
                  open={!!this.state.usersPopover}
                  anchorEl={this.state.usersPopover}
                  onClose={() => this.setState({ usersPopover: null })}
                  className={classes.menu}
                >
                  <MenuList>
                    {this.props.users.map((user) => (
                      <MenuItem
                        key={user.id}
                        dense
                        onClick={() =>
                          this.UpdateUsersList(
                            true,
                            user.id,
                            this.state.usersNotfiy
                          )
                        }
                      >
                        {`${user.firstName} ${user.lastName}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Popover>
              </div>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.props.onClose}>Cancel</Button>
              <Button
                // onClick={triggerSubmit}
                color="primary"
                disabled={this.state.usersNotfiy.length <= 0} // Need to prevent submission if fields arnt filled out
              >
                Save
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    )
  }
}

export default withStyles(styles)(TaskDialog)
