import React from 'react'
import TextField from './TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { WithStyles } from '@mui/styles'
import { createStyles, withStyles } from '@mui/styles'

const styles = createStyles({
  adornment: {
    whiteSpace: 'nowrap',
  },
})

interface INumberFieldProps extends WithStyles<typeof styles> {
  onChange?: (newValue: number | null) => void
  id?: string
  defaultValue?: number | null
  value?: number | null
  label?: string
  style?: React.CSSProperties
  placeholder?: string
  endAdornment?: string
  startAdornment?: string
  errorMessage?: string
  onBlur?: () => void
  className?: string
  formClassName?: string
  InputLabelProps?: any
  name?: string
  required?: boolean
  helperText?: React.ReactNode
  min?: number
  disabled?: boolean
  step?: number | string
}

const NumberField = ({
  value,
  onChange,
  endAdornment,
  startAdornment,
  errorMessage,
  defaultValue,
  classes,
  min,
  ...props
}: INumberFieldProps) => (
  <TextField
    {...props}
    type="number"
    defaultValue={defaultValue}
    onChange={(ev) => {
      const value = ev.target.value
      const numValue = value === '' ? null : Number(value)
      if (onChange) {
        onChange(numValue)
      }
    }}
    required={props.required}
    value={value === null ? '' : value}
    endAdornment={
      endAdornment ? (
        <InputAdornment position="end" className={classes.adornment}>
          {endAdornment}
        </InputAdornment>
      ) : undefined
    }
    startAdornment={
      startAdornment ? (
        <InputAdornment position="start" className={classes.adornment}>
          {startAdornment}
        </InputAdornment>
      ) : undefined
    }
    error={!!errorMessage}
    helperText={errorMessage ? errorMessage : props.helperText}
    name={props.name}
    disabled={props.disabled}
    step={props.step}
    min={min}
  />
)

export default withStyles(styles)(NumberField)
