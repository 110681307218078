import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import { ICategory } from '../../../types/category'
import InfoHover from '../../buttons/InfoHover'
import MUISelect from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import CategoryAvatar from '../../ux/CategoryAvatar'

const styles = (theme: Theme) =>
  createStyles({
    TextFieldMobile: {
      width: '100%',
      maxWidth: 256,
      marginBottom: 10,
    },
    TextField: {
      width: 256,
      marginBottom: 10,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  type: 'edit' | 'add'
  isMobile?: boolean
  attributeName?: string
  attributeId?: string
  attributeCategoryId?: string
  categories: ICategory[]
}

interface IState {
  name: string
  nameError: string
  categorySelected?: string
}

class AddEditAttributeDialog extends React.Component<IProps, IState> {
  state: IState = {
    name: this.props.attributeName ? this.props.attributeName : '',
    nameError: '',
    categorySelected: this.props.attributeCategoryId
      ? this.props.attributeCategoryId
      : 'all',
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  render() {
    return (
      <Dialog
        open={this.props.dialogOpen}
        onClose={this.handleClose}
        fullWidth={this.props.isMobile}
      >
        <DialogTitle>
          {this.props.type === 'edit' ? 'Edit' : 'Add'} Attribute
          <InfoHover>An attribute is extra info about an asset</InfoHover>
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Name"
            required
            className={
              this.props.isMobile
                ? this.props.classes.TextFieldMobile
                : this.props.classes.TextField
            }
            value={this.state.name}
            onChange={(event) =>
              this.setState({
                name: event.target.value,
                nameError: event.target.value
                  ? ''
                  : 'Attribute requires a name',
              })
            }
            error={!!this.state.nameError}
            helperText={this.state.nameError}
            id="CategoryNameInputField"
          />
          <FormControl required variant="standard" sx={{ m: 1 }}>
            <InputLabel>Applicable To</InputLabel>
            <MUISelect
              value={this.state.categorySelected}
              onChange={(event) =>
                this.setState({
                  categorySelected: event.target.value as string,
                })
              }
            >
              <MenuItem value={'all'}>All Categories</MenuItem>
              {this.props.categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CategoryAvatar
                      color={category.color}
                      icon={category.icon}
                      size={20}
                    />
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginLeft: '8px',
                      }}
                    >
                      {category.name}
                    </div>
                  </div>
                </MenuItem>
              ))}
            </MUISelect>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={!this.state.name}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AddEditAttributeDialog)
