import React, { useRef, useEffect } from 'react'
import { Polygon } from '@react-google-maps/api'
import { ILatLng } from '../../types/maptrac/LatLng'
import { colors } from '../ux/roviTheme'

interface IProps {
  points: ILatLng[]
  onEditPoints: (points: ILatLng[]) => void
}

const polygonOptions = {
  editable: true,
  strokeColor: colors.primaryBlue,
  strokeWeight: 4,
  fillOpacity: 0.4,
  zIndex: 2,
}

const EditablePolygon: React.FunctionComponent<IProps> = (props) => {
  const polygonRef = useRef<Polygon>(null)
  useEffect(() => {
    setTimeout(() => {
      const newPoints = polygonRef.current
      /**
       * Console.log this value below in order to see what the geofences state is currently.
       */
      const currentPoints = newPoints
        ? newPoints.state.polygon
            ?.getPaths()
            .getArray()[0]
            .getArray()
            .map((path) => ({ lat: path.lat(), lng: path.lng() } as ILatLng))
        : null
      if (newPoints) {
        props.onEditPoints(currentPoints ? currentPoints : [])
      }
      /**
       * This delay is here, to slow down how fast its updating, now its to half a second
       * since its faster then most people will take, and it doesnt bog down the website.
       *
       * TODO: Find an easier way to fix this rather then a timeout, but currently we
       * needed this finished fast at the time.
       */
    }, 500)
  }, [props])
  return (
    <Polygon ref={polygonRef} paths={props.points} options={polygonOptions} />
  )
}

export default EditablePolygon
