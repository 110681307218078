import { BehaviorSubject } from 'rxjs'

export default class HashStateManager<T> {
  updates: BehaviorSubject<T>

  constructor(
    private read: (val: string) => T,
    private write: (val: T) => string
  ) {
    this.updates = new BehaviorSubject<T>(this.value)

    window.addEventListener('hashchange', () => {
      this.updates.next(this.value)
    })
  }

  set(params: T): void {
    window.location.hash = this.write(params)
  }

  get value(): T {
    return this.read(window.location.hash.substring(1))
  }
}
