import React from 'react'
import Button from './Button'
import Popover from '@mui/material/Popover'
import { IStandardIcons } from '../ux/standardIcons'
import Icon from '../Icon'
import { IButtonVariants } from '../../types/buttonVariants'

interface IState {
  anchorEl: any
  id: string
}

interface IProps {
  title: string
  icon: IStandardIcons
  variant?: IButtonVariants
  buttonMinWidth?: number
}

class ButtonPopover extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: null,
    id: 'navBarBranchNameButton',
  }

  render() {
    const props = this.props
    const state = this.state
    return (
      <div>
        <Button
          id={'navBarBranchNameButton'}
          variant={props.variant}
          onClick={(event: { currentTarget: any }) =>
            this.setState({ anchorEl: event.currentTarget })
          }
          style={{ minWidth: this.props.buttonMinWidth }}
        >
          <Icon icon={props.icon} variant="button" />
          <span>{props.title}</span>
        </Button>
        <Popover
          open={!!state.anchorEl}
          anchorEl={state.anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {props.children}
        </Popover>
      </div>
    )
  }
}

export default ButtonPopover
