import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const IgnitionIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M1,10v4h2v4h4v-4h4.4c0.8,2.3,3,4,5.6,4c3.3,0,6-2.7,6-6s-2.7-6-6-6c-2.6,0-4.8,1.7-5.6,4H1z M15,12c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S15,13.1,15,12z" />,
  'IgnitionIcon'
)

export default IgnitionIcon
