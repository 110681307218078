import React from 'react'
import '../../styles/pages/ResetPassword.scss'
import TextField from '../../components/textBoxs/TextField'
import Button from '../../components/buttons/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material/styles/createTheme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { darkMode } from '../../components/ux/roviTheme'
import { ERoutes } from '../../components/routes/CheckRoutes'
import { signOut } from '../../components/signOut'

const DarkTheme = (theme: Theme) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#0078FF',
      },
      background: {
        paper: darkMode ? theme.palette.grey[700] : theme.palette.grey[100],
      },
    },
  })

interface IProps {
  verifyCode: string
  submitVerificationCode: () => void
  setVerifyCode: (val: string) => void
  isMobile: boolean
}
class VerificationCodePage extends React.Component<IProps> {
  render() {
    return (
      <div className="resetPassContainer">
        <ThemeProvider theme={DarkTheme}>
          <Paper className="resetPassInner" square>
            <div className="resetPassTitle">
              <Typography variant="h5" align="center">
                Almost Done...
              </Typography>
              <Typography
                variant="body2"
                align="center"
                className="resetPassInstructions"
              >
                We are sending you a confirmation email. Please allow a few
                minutes to send and follow the instructions in the email to
                reset your password (be sure to check your spam and junk
                folder).
              </Typography>
            </div>
            <TextField
              id="verificationCode"
              name="verificationCode"
              label="Verification Code"
              width="100%"
              type="text"
              onEnter={() => this.props.submitVerificationCode()}
              onChange={(event) => this.props.setVerifyCode(event.target.value)}
            />
            <div className="submitEmailContainer">
              <a
                href={ERoutes.login}
                className="returnToLogin"
                onClick={() => signOut()}
              >
                Login?
              </a>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.props.submitVerificationCode()}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </ThemeProvider>
      </div>
    )
  }
}

export default VerificationCodePage
