import React from 'react'
import DesktopInventoryMenu from './DesktopInventoryMenu'
import MobileInventoryMenu from './MobileInventoryMenu'
import WidthViewport from '../ux/WidthViewport'

interface IState {}

interface IProps {
  tabs: Array<{ label: string; value: string; icon: string; url?: string }>
  selectedTab: string
  onChangeTab: (val: string) => void
  tooltip?: string
  addFab?: boolean
  FabDisabled?: boolean
  bottomIconButton?: boolean
  toolBar?: { single?: boolean }
  onClick?: () => void
}

class SideAppBar extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) =>
          isMobile ? (
            <MobileInventoryMenu
              tabs={this.props.tabs}
              selectedTab={this.props.selectedTab}
              onChangeTab={this.props.onChangeTab}
              addFab={this.props.addFab}
              FabDisabled={this.props.FabDisabled}
              onClick={this.props.onClick}
            >
              {this.props.children}
            </MobileInventoryMenu>
          ) : (
            <DesktopInventoryMenu
              tabs={this.props.tabs}
              selectedTab={this.props.selectedTab}
              onChangeTab={this.props.onChangeTab}
              addFab={this.props.addFab}
              bottomIconButton={this.props.bottomIconButton}
              tooltip={this.props.tooltip}
              toolBar={this.props.toolBar}
              FabDisabled={this.props.FabDisabled}
              onClick={this.props.onClick}
            >
              {this.props.children}
            </DesktopInventoryMenu>
          )
        }
      </WidthViewport>
    )
  }
}

export default SideAppBar
