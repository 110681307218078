import React from 'react'
import Popover from '@mui/material/Popover'
import { WithStyles } from '@mui/styles'
import { createStyles, withStyles } from '@mui/styles'
import ListSubheader from '@mui/material/ListSubheader'
import { Theme } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ButtonBase from '@mui/material/ButtonBase'
import Icon from '../../Icon'
import { alpha } from '@mui/material/styles'
import AssetsList from './AssetsList'
import { IAssignAsset } from '../../../types/service/tasks'
import LargeStaticDateRangePicker from '../../datePickers/LargeStaticDateRangePicker'
import IconButton from '../../buttons/IconButton'
import Typography from '@mui/material/Typography'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxHeight: 500,
      minWidth: 200,
    },
    header: {
      background: theme.palette.background.paper,
    },
    back: {
      marginRight: 10,
    },
    filterButton: {
      flex: 1,
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.text.primary,
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    searchIcon: {
      width: theme.spacing(9),
      display: 'flex',
      height: 50,
    },
  })

interface IFilter {
  name: String
  label: string
}

enum EFilterList {
  equipmentIds = 'equipmentIds',
  createDate = 'createdDate',
  resolvedDate = 'resolvedDate',
}

const filterArr = [
  {
    name: EFilterList.equipmentIds,
    label: 'Assets',
  },
  {
    name: EFilterList.createDate,
    label: 'Created Date',
  },
  {
    name: EFilterList.resolvedDate,
    label: 'Resolved Date',
  },
]

interface IProps extends WithStyles<typeof styles> {
  assetsLoaded: IAssignAsset[]
  selectedAsssets: string[]
  updateSelectedAssets: (newArr: string[]) => void
  updateCreatedSelectedDates: ([date1, date2]: Array<Date | null>) => void
  updateResolvedSelectedDates: ([date1, date2]: Array<Date | null>) => void
  createdSelectedDates: Array<Date | null>
  resolvedSelectedDates: Array<Date | null>
  isMobile?: boolean
}

interface IState {
  anchorEl: null | (EventTarget & HTMLButtonElement)
  selectedPopup?: EFilterList
}

class SearchMenu extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: null,
    selectedPopup: undefined,
  }

  returnPopover() {
    this.setState({ selectedPopup: undefined })
  }

  onClosePopover() {
    this.setState({ anchorEl: null })
  }

  onOpenPopover(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ anchorEl: event.currentTarget, selectedPopup: undefined })
  }

  CardContainerCreatedDate() {
    return (
      <div style={{ padding: '8px 16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            icon="back"
            variant="toolbar"
            onClick={() => this.returnPopover()}
          />
          <Typography variant="subtitle2" style={{ opacity: 0.54 }}>
            Created Date
          </Typography>
        </div>
        <LargeStaticDateRangePicker
          disableFuture
          onApply={(date1: Date | null, date2: Date | null) => {
            this.props.updateCreatedSelectedDates([date1, date2])
            this.onClosePopover()
          }}
          currentDate={this.props.createdSelectedDates}
          onClose={() => this.returnPopover()}
          isMobile={this.props.isMobile}
        />
      </div>
    )
  }

  CardContainerResolvedDate() {
    return (
      <div style={{ padding: '8px 16px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            icon="back"
            variant="toolbar"
            onClick={() => this.returnPopover()}
          />
          <Typography variant="subtitle2" style={{ opacity: 0.54 }}>
            Resolved Date
          </Typography>
        </div>
        <LargeStaticDateRangePicker
          disableFuture
          onApply={(date1: Date | null, date2: Date | null) => {
            this.props.updateResolvedSelectedDates([date1, date2])
            this.onClosePopover()
          }}
          currentDate={this.props.resolvedSelectedDates}
          onClose={() => this.returnPopover()}
          isMobile={this.props.isMobile}
        />
      </div>
    )
  }

  popupCardChange(FList?: EFilterList) {
    switch (FList) {
      case EFilterList.createDate:
        return this.CardContainerCreatedDate()
      case EFilterList.resolvedDate:
        return this.CardContainerResolvedDate()
      case EFilterList.equipmentIds:
        return (
          <AssetsList
            return={() => this.returnPopover()}
            header
            equips={this.props.assetsLoaded}
            filterEquips={this.props.selectedAsssets}
            onChange={(newVal: string[]) =>
              this.props.updateSelectedAssets(newVal)
            }
            label="Assets"
          />
        )
      default:
        return (
          <List
            subheader={
              <ListSubheader className={this.props.classes.header}>
                Add Filter
              </ListSubheader>
            }
          >
            {filterArr.map((filter, key) => (
              <ListItem
                button
                key={key}
                onClick={() => this.setState({ selectedPopup: filter.name })}
              >
                <ListItemText primary={filter.label} />
              </ListItem>
            ))}
          </List>
        )
    }
  }

  render = () => {
    return (
      <>
        <div className={this.props.classes.searchIcon}>
          <ButtonBase
            className={this.props.classes.filterButton}
            onClick={(event) => this.onOpenPopover(event)}
          >
            <Icon icon="filter" />
            <Icon icon="arrowDown" />
          </ButtonBase>
        </div>
        <Popover
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          onClose={() => this.onClosePopover()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{
            paper: this.props.classes.container,
          }}
        >
          {this.popupCardChange(this.state.selectedPopup)}
        </Popover>
      </>
    )
  }
}

export default withStyles(styles)(SearchMenu)
