import { IStandardIcons } from '../ux/standardIcons'
import {
  EAssetReports,
  TAssetReports,
} from '../../constants/equipmentHashControllers'
import { FluidUsageAssetLineComp } from './assetReports/FluidUsageAssetLineReport'
import { FluidUsageAssetBarComp } from './assetReports/FluidUsageAssetBarReport'

export interface IReportLink {
  style?: React.CSSProperties
  href?: string
  title: string
  value?: TAssetReports // used for asset report
  description?: string
  reportPage?: (
    width: number,
    height: number,
    isMobile: boolean,
    esn: string,
    tenant: string,
    selectedDates: Array<Date | null>
  ) => JSX.Element
}

export interface IReportCategorys {
  title: string
  icon?: IStandardIcons
  reports: IReportLink[]
}

export const otherReports: IReportLink[] = [
  {
    title: 'Fluid Level Line',
    value: EAssetReports.fluidUsageLine,
    reportPage: FluidUsageAssetLineComp,
  },
  {
    title: 'Fluid Level Bar',
    value: EAssetReports.fluidUsageBar,
    reportPage: FluidUsageAssetBarComp,
  },
  {
    title: 'Daily Usage',
    value: EAssetReports.dailyUsage,
  },
  {
    title: 'Asset Metrics',
    value: EAssetReports.equipmentUsage,
  },
  {
    title: 'Daily Timeline',
    value: EAssetReports.dailyTimeline,
  },
  {
    title: 'Nightly Timeline',
    value: EAssetReports.nightlyTimeline,
  },
  {
    title: 'Year / Month',
    value: EAssetReports.yearMonth,
  },
  {
    title: 'Daily Stops',
    value: EAssetReports.dailyStops,
  },
  {
    title: 'Daily Idle Time',
    value: EAssetReports.dailyIdleTime,
  },
]

export const reports: IReportCategorys[] = [
  {
    title: 'Other Reports',
    reports: otherReports,
  },
]
