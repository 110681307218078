import React from 'react'
import '../../styles/pages/service/TasksPage.scss'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionActions from '@mui/material/AccordionActions'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Icon from '../Icon'
import IconButton from '../buttons/IconButton'
import Typography from '@mui/material/Typography'
import EllipsisText from '../ux/EllipsisText'
import blue from '@mui/material/colors/blue'
import orange from '@mui/material/colors/orange'
import red from '@mui/material/colors/red'
import grey from '@mui/material/colors/grey'
import {
  IAssetTasks,
  TTaskMetric,
  TTaskType,
  IAssignAsset,
  ITask,
} from '../../types/service/tasks'
import moment from 'moment'
import TasksAccordianRow from './taskComponents/TaskAccordianRow'
import TaskDialog from './taskComponents/TaskDialog'
import AssignTaskToAssetDialog from './taskComponents/AssignTaskToAssetDialog'
import DeleteTaskDialog from './taskComponents/DeleteTaskDialog'
import { IUser } from '../../types/account/user'

interface IProps {
  schedule: TTaskType
  interval: TTaskMetric
  value: any
  canBeDeleted: boolean
  timezone: string
  useMetric: boolean
  isMobile?: boolean
  equips: IAssetTasks[]
  globalThreshold: number
  countOverdue: (equips: IAssetTasks[]) => number
  countUpcoming: (equips: IAssetTasks[]) => number
  countScheduled: (equips: IAssetTasks[]) => number
  countDiscrepency: (equips: IAssetTasks[]) => number
  allEquipment: IAssignAsset[]
  allUsers: IUser[]
  task: ITask
}

interface IState {
  totalOverdue: number
  totalUpcoming: number
  totalScheduled: number
  totalDiscrepency: number
  taskDialogOpen: boolean
  assignAssetOpen: boolean
  deleteTaskDialogOpen: boolean
}

class TasksAccordian extends React.Component<IProps, IState> {
  state: IState = {
    totalOverdue: 0,
    totalUpcoming: 0,
    totalScheduled: 0,
    totalDiscrepency: 0,
    taskDialogOpen: false,
    assignAssetOpen: false,
    deleteTaskDialogOpen: false,
  }

  taskInterval(interval: TTaskMetric, value: any) {
    let intervalS = parseInt(value) === 1 ? undefined : 's'
    switch (interval) {
      case 'date':
        return moment(value).format('MMM Do YYYY')
      case 'engineHours':
        return value + ' hour' + intervalS
      case 'time':
        return value + ' month' + intervalS
      case 'odometer':
        return value + ' mile' + intervalS
    }
  }

  taskType(schedule: TTaskType, interval: TTaskMetric, value: any) {
    switch (schedule) {
      case 'recurring':
        return 'Every ' + this.taskInterval(interval, value)
      case 'dynamic':
        return this.taskInterval(interval, value)
      case 'once':
        return this.taskInterval(interval, value)
    }
  }

  componentDidMount() {
    this.setState({
      totalOverdue: this.props.countOverdue(this.props.equips),
      totalUpcoming: this.props.countUpcoming(this.props.equips),
      totalScheduled: this.props.countScheduled(this.props.equips),
      totalDiscrepency: this.props.countDiscrepency(this.props.equips),
    })
  }

  render() {
    return (
      <>
        <Accordion>
          <AccordionSummary expandIcon={<Icon icon="expandMore" />}>
            <Typography
              className={this.props.isMobile ? 'headingMobile' : 'heading'}
              component="div"
            >
              <EllipsisText
                text={`${this.props.task.taskName} (${this.props.equips.length})`}
                width={this.props.isMobile ? 100 : 200}
              />
            </Typography>
            <Typography className="secondaryHeading">
              {this.taskType(
                this.props.schedule,
                this.props.interval,
                this.props.value
              )}
            </Typography>
            <Typography className="progressCircleContainer" component="div">
              {this.state.totalDiscrepency > 0 ? (
                <Icon
                  icon="warning"
                  style={{ height: 16, width: 16, color: red[500] }}
                />
              ) : null}
              <div className="progressCircleOuter">
                {this.state.totalScheduled >= 1 ? (
                  <span
                    className="progressCircle"
                    style={{ backgroundColor: blue[500] }}
                  >
                    {this.state.totalScheduled}
                  </span>
                ) : (
                  <span
                    className="progressCircle"
                    style={{ backgroundColor: grey[50] }}
                  />
                )}
              </div>
              <div className="progressCircleOuter">
                {this.state.totalUpcoming >= 1 ? (
                  <span
                    className="progressCircle"
                    style={{ backgroundColor: orange[500] }}
                  >
                    {this.state.totalUpcoming}
                  </span>
                ) : (
                  <span
                    className="progressCircle"
                    style={{ backgroundColor: grey[50] }}
                  />
                )}
              </div>
              <div className="progressCircleOuter">
                {this.state.totalOverdue >= 1 ? (
                  <span
                    className="progressCircle"
                    style={{ backgroundColor: red[500] }}
                  >
                    {this.state.totalOverdue}
                  </span>
                ) : (
                  <span
                    className="progressCircle"
                    style={{ backgroundColor: grey[50] }}
                  />
                )}
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense className="list">
              {this.props.equips.map((equip: IAssetTasks) => (
                <React.Fragment key={equip.id}>
                  <TasksAccordianRow
                    name={this.props.task.taskName}
                    interval={this.props.interval}
                    equips={this.props.equips}
                    globalThreshold={this.props.globalThreshold}
                    equip={equip}
                    isMobile={this.props.isMobile}
                  />
                </React.Fragment>
              ))}
            </List>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <IconButton
              icon="add"
              variant="row"
              onClick={() =>
                this.setState({
                  assignAssetOpen: true,
                })
              }
              tooltip="Assign to Asset"
            />
            <IconButton
              icon="edit"
              variant="row"
              onClick={() => this.setState({ taskDialogOpen: true })}
              tooltip="Edit"
            />
            <IconButton
              icon="delete"
              variant="row"
              onClick={() => this.setState({ deleteTaskDialogOpen: true })}
              tooltip="Delete"
              disabled={!this.props.canBeDeleted}
            />
          </AccordionActions>
        </Accordion>
        <AssignTaskToAssetDialog
          onClose={() => this.setState({ assignAssetOpen: false })}
          open={this.state.assignAssetOpen}
          isMobile={this.props.isMobile}
          name={this.props.task.taskName}
          equipment={this.props.allEquipment}
          interval={this.props.interval}
        />
        <TaskDialog
          type="edit"
          open={this.state.taskDialogOpen}
          onClose={() => this.setState({ taskDialogOpen: false })}
          onSubmit={() => this.setState({ taskDialogOpen: false })}
          users={this.props.allUsers}
          useMetric
          isMobile={this.props.isMobile}
          task={this.props.task}
          maintenanceType="single"
        />
        <DeleteTaskDialog
          open={this.state.deleteTaskDialogOpen}
          onClose={() => this.setState({ deleteTaskDialogOpen: false })}
          onDelete={() => this.setState({ deleteTaskDialogOpen: false })}
          name={this.props.task.taskName}
        />
      </>
    )
  }
}

export default TasksAccordian
