import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'

const styles = () =>
  createStyles({
    TextArea: {
      padding: 2,
      width: '90%',
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  rentalInformation?: string
}

interface IState {
  rentalTerms: string
}

class EditRentalsDialog extends React.Component<IProps, IState> {
  state: IState = {
    rentalTerms: this.props.rentalInformation
      ? this.props.rentalInformation
      : '',
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  componentDidMount() {}

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>Edit Account</DialogTitle>
        <DialogContent style={{ display: 'grid', placeItems: 'center' }}>
          <TextField
            variant="outlined"
            fullWidth
            autoFocus
            placeholder="Enter rental terms here"
            rows={10}
            multiline
            formClassName={this.props.classes.TextArea}
            value={this.state.rentalTerms}
            onChange={(event) =>
              this.setState({ rentalTerms: event.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditRentalsDialog)
