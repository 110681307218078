import React from 'react'
import { IAssetDevice } from '../../../types/account/devices'
import InfoButton from '../../buttons/InfoButton'

interface IPropsESN {
  esn: string
}

class DeviceESN extends React.Component<IPropsESN> {
  render() {
    return (
      <div style={{ flex: 1, color: '#616161', fontWeight: 'bold' }}>
        {this.props.esn}
      </div>
    )
  }
}

export class DeviceBoxOutside extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #e0e0e0',
          padding: 6,
          margin: 3,
          background: '#f5f5f5',
          borderRadius: 6,
          fontSize: 13,
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

interface IProps {
  device: IAssetDevice
}

class DeviceBoxInsides extends React.Component<IProps> {
  render() {
    return (
      <>
        <DeviceESN esn={this.props.device.esn} />
        <InfoButton>
          <div>{this.props.device.description}</div>
        </InfoButton>
      </>
    )
  }
}

export default DeviceBoxInsides
