import { IClientSites, ISite } from '../../../types/maptrac/Sites'

export function SitesSorter(siteAmount: IClientSites[]) {
  let siteCountVal: ISite[] = []
  siteAmount.forEach((client) => {
    client.clientSites.forEach((site) => {
      siteCountVal.push(site)
    })
  })
  return siteCountVal
}
