import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogRow from '../../dialogComponents/DialogRow'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Select, { ISelectValues } from '../../ux/Select'
import SelectArr from '../../ux/SelectArr'
import TextField from '../../textBoxs/TextField'
import Button from '../../buttons/Button'
import { countriesArray } from '../../../constants/countries'
import InfoHover from '../../buttons/InfoHover'
import NumberField from '../../textBoxs/NumberField'
import {
  timezoneList,
  currentUserTimezone,
  coreTimeZones,
} from '../../../constants/timezones'
import IconButton from '../../buttons/IconButton'

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    SelectField: {
      width: 250,
    },
  })

const yesNoArr = ['Yes', 'No']

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  type: 'add' | 'edit'
  name?: string
  address?: string
  city?: string
  stateProvince?: string
  postalCode?: string
  country?: string
  timezone?: string
  taxRentals?: string
  defaultTax?: number | null
  laborHourly?: number | null
  metricSystem?: string
}

interface IState {
  name: string
  address: string
  city: string
  stateProvince: string
  postalCode: string
  country: string
  countriesList: ISelectValues[]
  timezone: string
  allTimezones: boolean
  taxRentals: string
  defaultTax: number | null
  laborHourly: number | null
  metricSystem: string
}

class AddEditBranchDialog extends React.Component<IProps, IState> {
  state: IState = {
    name: this.props.name ? this.props.name : '',
    address: this.props.address ? this.props.address : '',
    city: this.props.city ? this.props.city : '',
    stateProvince: this.props.stateProvince ? this.props.stateProvince : '',
    postalCode: this.props.postalCode ? this.props.postalCode : '',
    country: this.props.country ? this.props.country : 'US',
    countriesList: countriesArray,
    timezone: this.props.timezone ? this.props.timezone : currentUserTimezone,
    allTimezones: false,
    taxRentals: this.props.taxRentals ? this.props.taxRentals : 'No',
    defaultTax: this.props.defaultTax ? this.props.defaultTax : 0.0,
    laborHourly: this.props.laborHourly ? this.props.laborHourly : 0,
    metricSystem: this.props.metricSystem ? this.props.metricSystem : 'No',
  }

  isAddDialog = this.props.type === 'add'

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  isSaveButtonDisabled() {
    return !(!!this.state.name && !!this.state.country && !!this.state.timezone)
  }

  componentDidMount() {
    if (!coreTimeZones.includes(this.state.timezone)) {
      this.setState({ allTimezones: true })
    }
  }

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>
          {this.isAddDialog ? 'Add' : 'Edit'} Branch
          <InfoHover>A branch is like your rental yard or store</InfoHover>
        </DialogTitle>
        <DialogContent>
          <DialogRow
            FieldLeft={
              <TextField
                label="Name"
                required
                className={this.props.classes.TextField}
                value={this.state.name}
                onChange={(event) =>
                  this.setState({ name: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="Address"
                className={this.props.classes.TextField}
                value={this.state.address}
                onChange={(event) =>
                  this.setState({ address: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                label="City"
                className={this.props.classes.TextField}
                value={this.state.city}
                onChange={(event) =>
                  this.setState({ city: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <TextField
                label="State / Province"
                className={this.props.classes.TextField}
                value={this.state.stateProvince}
                onChange={(event) =>
                  this.setState({ stateProvince: event.target.value })
                }
              />
            }
            FieldRight={
              <TextField
                label="Postal Code"
                className={this.props.classes.TextField}
                value={this.state.postalCode}
                onChange={(event) =>
                  this.setState({ postalCode: event.target.value })
                }
              />
            }
          />
          <DialogRow
            FieldLeft={
              <Select
                label="Country"
                required
                selectedValue={this.state.country}
                values={this.state.countriesList}
                onChange={(name: string) =>
                  this.setState({
                    country: name,
                  })
                }
                className={this.props.classes.SelectField}
              />
            }
            FieldRight={
              <SelectArr
                label="Time Zone"
                required
                selectedValue={this.state.timezone}
                values={this.state.allTimezones ? timezoneList : coreTimeZones}
                onChange={(event) =>
                  this.setState({
                    timezone: event.target.value as string,
                  })
                }
                className={this.props.classes.SelectField}
              />
            }
            FieldRightAddOn={
              <IconButton
                onClick={() =>
                  this.setState({ allTimezones: !this.state.allTimezones })
                }
                // disabled={!coreTimeZones.includes(this.state.timezone)} // disabled
                variant="appbar"
                icon={this.state.allTimezones ? 'timezone' : 'moreTimezone'}
                tooltip={`show ${
                  this.state.allTimezones ? 'less' : 'more'
                } timezone's`}
              />
            }
          />
          <DialogRow
            FieldLeft={
              <SelectArr
                label="Tax Rentals by Default"
                selectedValue={this.state.taxRentals}
                values={yesNoArr}
                onChange={(event) =>
                  this.setState({
                    taxRentals: event.target.value as string,
                  })
                }
                className={this.props.classes.SelectField}
              />
            }
            FieldRight={
              <NumberField
                disabled={this.state.taxRentals === 'No'}
                endAdornment="%"
                label="Default Tax Rate"
                value={this.state.defaultTax}
                onChange={(event) =>
                  this.setState({
                    defaultTax: event,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
          />
          <DialogRow
            FieldLeft={
              <NumberField
                startAdornment="$"
                label="Labor Hourly Rate"
                value={this.state.laborHourly}
                onChange={(event) =>
                  this.setState({
                    laborHourly: event,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
          />
          <DialogRow
            FieldLeft={
              <SelectArr
                label="Use Metric System"
                selectedValue={this.state.metricSystem}
                values={yesNoArr}
                onChange={(event) =>
                  this.setState({
                    metricSystem: event.target.value as string,
                  })
                }
                className={this.props.classes.TextField}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AddEditBranchDialog)
