import React from 'react'
import ReactHelmet from '../components/ReactHelmet'

interface IProps {}

class ClientsPage extends React.Component<IProps> {
  state = {}
  render() {
    return (
      <div>
        <ReactHelmet title="Clients" />
        Clients
      </div>
    )
  }
}

export default ClientsPage
