import { headersAPI, api } from '../../constants/api'
import {
  IGeofence,
  IGeofenceRaw,
  IGeofenceRawCoords,
} from '../../types/maptrac/Geofences'
import { ILatLng } from '../../types/maptrac/LatLng'

export const geofenceLATLNGSwitch = (postGisLL: number[][][]) => {
  let LatLng: ILatLng[] = []
  postGisLL.forEach((ll) =>
    ll.forEach(([lng, lat]) => LatLng.push({ lat: lat, lng: lng }))
  )
  return LatLng
}

const getAllGeofenceData = () => {
  // fetch(backend_api + '/maptrac/geofences', {
  return fetch(
    api + '/geofence/get/by-account/477fb8dd-5bf7-4622-a544-1e46542ff6d9',
    {
      method: 'GET',
      headers: headersAPI,
    }
  )
    .then((response) => response.json())
    .then((response: { geofence: IGeofenceRaw[] }) => {
      const parseRawGeofence = response.geofence.map((geoRaw) => {
        const coordinfo: IGeofenceRawCoords = JSON.parse(geoRaw.coordinates)
        const latLngInfo = geofenceLATLNGSwitch(coordinfo.coordinates)
        return {
          geofenceId: geoRaw.id,
          geofenceName: geoRaw.name,
          geofenceAssetCount: 0,
          points: latLngInfo,
          type: coordinfo.type.toLowerCase(),
        } as IGeofence
      })
      return parseRawGeofence
    })
    .catch((err) => console.error(err))
}

export default getAllGeofenceData
