import React from 'react'
import '../../styles/components/inventory/AssetsTab.scss'
import EllipsisText from '../ux/EllipsisText'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import Icon from '../Icon'
import UserAvatar from '../ux/UserAvatar'
import { colors } from '../../components/ux/roviTheme'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '../buttons/IconButton'
import { ERoutes } from '../routes/CheckRoutes'
import FieldRow from './FieldRow'
import ServiceButton from '../ux/ServiceButton'
import FlagButton from '../ux/FlagButton'
import CategoryChip from '../ux/CategoryChip'
import { ICategory } from '../../types/category'
import { IUser } from '../../types/account/user'
import { ListImageEquip } from '../ux/ListImage'
import Button from '../buttons/Button'
import { ContainerViewSize } from './FieldView'
import { EquipClientSiteRow } from './inventoryClientView'
import { IAgreement } from '../../types/agreements'
import { IDevices } from '../../types/account/devices'
import AddDeviceComponent from '../equipmentComponents/deviceComponents/AddDeviceComponent'

interface IFieldProps {
  title: string
  info: any
  width?: number
}

interface IState {
  anchorEl: HTMLButtonElement | null
}

interface IProps {
  id: string
  name: string
  device: string
  checked: boolean
  onChecked: (event: React.ChangeEvent<HTMLInputElement>) => void
  serialNumber?: string
  category: ICategory
  imageUrl?: string
  vin?: string
  user?: IUser
  inForService?: boolean
  hasCamera?: boolean
  hasDevice?: boolean
  tasks?: any[]
  flags?: any[]
  metrics?: any[]
  width?: number
  fakeMetrics?: any[]
  agreement?: IAgreement
  clientFiltered?: boolean
}

class ThingRow extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: null,
  }

  countFieldTypes(metrics: any[]) {
    let newWidth = 0
    metrics.forEach((m) => (newWidth = newWidth + ContainerViewSize(m)))
    return <div style={{ width: newWidth }} />
  }

  onAddDeviceClicked(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ anchorEl: event.currentTarget })
  }

  addDevice(device: IDevices) {
    console.log('Update the asset here')
    this.setState({ anchorEl: null })
  }

  componentDidMount() {}
  render() {
    return (
      <div
        className="inventoryRow"
        style={{
          borderColor: colors.abc,
          backgroundColor: colors.bg,
          minWidth: this.props.width ? this.props.width : '100%',
        }}
      >
        <Checkbox
          checked={this.props.checked}
          onChange={this.props.onChecked}
          color="primary"
        />
        <div className="assetImage">
          <ListImageEquip
            imageUrl={this.props.imageUrl}
            categoryIcon={this.props.category.icon}
            containerStyle={{ width: 64, height: 40, alignSelf: 'center' }}
          />
          <div className="imageIcons">
            {this.props.inForService ? (
              <Tooltip title="In for service" placement="right">
                <Avatar className="inService">
                  <Icon
                    icon="service"
                    style={{ color: colors.iconColor }}
                    fontSize="inherit"
                  />
                </Avatar>
              </Tooltip>
            ) : null}
            {this.props.user ? (
              <UserAvatar
                userId={this.props.user.id}
                firstName={this.props.user.firstName}
                lastName={this.props.user.lastName}
                variant="small"
              />
            ) : null}
          </div>
        </div>
        <div className="nameBlock">
          <EllipsisText text={this.props.name} width={200} className="nametext">
            <a href={ERoutes.equipment + '/56' /** + this.props.id */}>
              {this.props.name}
            </a>
          </EllipsisText>

          {this.props.vin ? (
            <div style={{ color: colors.textGrey }}>VIN {this.props.vin}</div>
          ) : this.props.serialNumber ? (
            <div style={{ color: colors.textGrey }}>
              SN {this.props.serialNumber}
            </div>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
        <div className="iconsBlock">
          <div className="iconContainer">
            {this.props.hasCamera ? (
              <IconButton icon="camera" variant="row" tooltip="Cameras" />
            ) : null}
          </div>
          {this.props.hasDevice ? (
            <>
              <div className="iconContainer">
                <IconButton
                  icon="maptrac"
                  variant="row"
                  tooltip="View on Map"
                />
              </div>
              <div className="iconContainer">
                <IconButton
                  icon="timeline"
                  variant="row"
                  tooltip="View Timeline"
                />
              </div>
              <div className="iconContainer">
                <IconButton icon="events" variant="row" tooltip="View Events" />
              </div>
            </>
          ) : (
            <div style={{ width: 102 }}>
              <Button
                variant="outlined"
                size="small"
                onClick={this.onAddDeviceClicked.bind(this)}
              >
                <Icon icon="add" variant="button" />
                Device
              </Button>
            </div>
          )}
          <div className="iconContainer">
            <ServiceButton
              assetId={this.props.device}
              tasks={this.props.tasks}
            />
          </div>
        </div>
        <div className="iconContainer">
          <FlagButton assetId={this.props.device} flags={this.props.flags} />
        </div>
        <div style={{ width: 120, margin: '0 14px' }}>
          {this.props.category ? (
            <CategoryChip
              name={this.props.category.name ? this.props.category.name : ''}
              icon={this.props.category.icon ? this.props.category.icon : 'car'}
              color={this.props.category.color ? this.props.category.color : ''}
            />
          ) : null}
        </div>
        {this.props.clientFiltered ? (
          <div style={{ width: 120, margin: '0 14px' }}>
            {this.props.agreement ? (
              <EquipClientSiteRow
                clientName={this.props.agreement.client.name}
                siteName={this.props.agreement.site.name}
                clientUrl={`/app/clients/${this.props.agreement.client.id}`}
              />
            ) : null}
          </div>
        ) : null}
        {this.props.hasDevice ? (
          <FieldRow
            device={this.props.device}
            fieldsLoaded={this.props.metrics}
          />
        ) : this.props.fakeMetrics ? (
          this.countFieldTypes(this.props.fakeMetrics)
        ) : null}
        <AddDeviceComponent
          anchorEl={this.state.anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          addDevice={this.addDevice.bind(this)}
        />
      </div>
    )
  }
}

export default ThingRow
