import React from 'react'

interface IState {
  hovered: boolean
}

interface IProps {
  children: (o: { hovered: boolean }) => React.ReactNode
  containerStyle?: React.CSSProperties
  onHoverIn?: () => void
  onHoverOut?: () => void
  id?: string
}

export default class HoverContainer extends React.Component<IProps, IState> {
  state: IState = {
    hovered: false,
  }

  handleHoverIn = () => {
    this.props.onHoverIn && this.props.onHoverIn()
    this.setState({ hovered: true })
  }
  handleHoverOut = () => {
    this.props.onHoverOut && this.props.onHoverOut()
    this.setState({ hovered: false })
  }

  render() {
    return (
      <div
        id={this.props.id}
        style={this.props.containerStyle}
        onMouseEnter={this.handleHoverIn}
        onMouseLeave={this.handleHoverOut}
        onTouchStart={this.handleHoverIn}
        onTouchEnd={this.handleHoverOut}
      >
        {this.props.children({
          hovered: this.state.hovered,
        })}
      </div>
    )
  }
}
