import React from 'react'
import ListItemSite from './ListItemSite'
import EllipsisText from '../../../ux/EllipsisText'
import MessageOverlay from '../../../ux/MessageOverlay'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import { colors } from '../../../ux/roviTheme'
import { LoadingIcon } from '../../../LoadingOverlay'
import { IClientSites } from '../../../../types/maptrac/Sites'
import { ILatLng } from '../../../../types/maptrac/LatLng'

interface IProps {
  data: IClientSites[]
  count: number
  onClick: (id: string, gps: ILatLng) => void
  onHoverIn: (id: any) => void
  onHoverOut: () => void
  loading: boolean
}

class LiveMaptracListSite extends React.Component<IProps> {
  render() {
    const props = this.props
    return (
      <>
        <MessageOverlay belowTheSurface isVisible={props.count === 0}>
          {props.loading ? <LoadingIcon loading /> : 'No Sites'}
        </MessageOverlay>
        {props.count === 0
          ? null
          : props.data.map((clientSites: IClientSites, key) =>
              clientSites.clientSites.length === 0 ? null : (
                <List
                  key={key}
                  subheader={
                    <ListSubheader style={{ background: colors.bg }}>
                      <EllipsisText
                        text={`${clientSites.clientName} (${clientSites.clientSites.length})`}
                      />
                    </ListSubheader>
                  }
                >
                  {clientSites.clientSites.map((site, key2) => (
                    <ListItemSite
                      key={key2}
                      siteName={site.siteName}
                      siteAssetCount={site.siteAssetCount}
                      onClick={() => props.onClick(site.siteId, site.siteGps)}
                      onHoverIn={() => props.onHoverIn(site.siteId)}
                      onHoverOut={props.onHoverOut}
                    />
                  ))}
                </List>
              )
            )}
      </>
    )
  }
}

export default LiveMaptracListSite
