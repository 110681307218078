import { Step, StepLabel, Stepper } from '@mui/material'
import React, { useState } from 'react'
import OnboardingFirstStep from './onboarding/OnboardingFirstStep'
import OnboardingSecondStep from './onboarding/OnboardingSecondStep'
import OnboardingThirdStep from './onboarding/OnboardingThirdStep'
import './onboarding/Onboarding.css'

export interface IOnboardingStepProps {
  next?: VoidFunction
  skip?: VoidFunction
  back?: VoidFunction
}

interface IOnboardingProps {
  userName: string | null
}

const Onboarding = ({ userName }: IOnboardingProps) => {
  const { steps, activeStep, isStepSkipped } = useOnboardingHook(userName)

  return (
    <div id="Onboarding">
      <img
        src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-320x204.png"
        alt="Rovitracker Logo"
      />
      <Stepper activeStep={activeStep} className="stepper-steps">
        {steps.map(({ stepName }, idx) => {
          const completed = !isStepSkipped(idx) && activeStep > idx
          return (
            <Step key={stepName} completed={completed}>
              <StepLabel>
                <span className="step-label">{stepName}</span>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {activeStep < steps.length && steps[activeStep].component}
    </div>
  )
}

const useOnboardingHook = (userName: string | null) => {
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())

  const [logo, setLogo] = useState<File | undefined | null>(null)
  const [branchName, setBranchName] = useState('')
  const [timezone, setTimezone] = useState('America/Denver')

  const isStepSkippable = (step: number) => step === 0
  const isStepSkipped = (step: number) => skipped.has(step)

  const onFinishOnboarding = () => {
    // TODO: finish onboarding operations (maybe reroute to login)
    console.log('done with onboarding')
  }

  const onClickNext = () => {
    let updatedSkipped = skipped
    if (isStepSkipped(activeStep)) {
      updatedSkipped = new Set(skipped.values())
      updatedSkipped.delete(activeStep)
    }

    if (activeStep === steps.length - 1) {
      onFinishOnboarding()
      return
    }

    setActiveStep((prevStep) => prevStep + 1)
    setSkipped(updatedSkipped)
  }

  const onClickBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const onClickSkip = () => {
    if (!isStepSkippable(activeStep)) return

    onClickNext()
    setSkipped((prevSkipped) => {
      const updatedSkipped = new Set(prevSkipped.values())
      updatedSkipped.add(activeStep)
      return updatedSkipped
    })
  }

  const steps = [
    {
      stepName: 'Logo',
      component: (
        <OnboardingFirstStep
          userName={userName}
          onLogoAdded={setLogo}
          logo={logo}
          next={onClickNext}
          skip={onClickSkip}
        />
      ),
    },
    {
      stepName: 'Branch',
      component: (
        <OnboardingSecondStep
          branchName={branchName}
          onChangeBranchName={setBranchName}
          next={onClickNext}
          back={onClickBack}
        />
      ),
    },
    {
      stepName: 'Time',
      component: (
        <OnboardingThirdStep
          branchName={branchName}
          selectedTimezone={timezone}
          onChangeTimezone={setTimezone}
          next={onClickNext}
          back={onClickBack}
        />
      ),
    },
  ]
  return {
    steps,
    activeStep,
    isStepSkipped,
  }
}

export default Onboarding
