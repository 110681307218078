import React from 'react'
import '../styles/pages/OtherUser.scss'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import Button from '../components/buttons/Button'
import Icon from '../components/Icon'
import LoadingOverlay from '../components/LoadingOverlay'
import { backend_api, headersAPI } from '../constants/api'
import { IUserInfo } from '../types/account/user'
import DeleteUserDialog from '../components/accountComponents/dialogs/DeleteUserDialog'
import EditOtherUserDialog from '../components/userProfile/dialogs/EditOtherUserDialog'
import EditFeaturesDialog from '../components/userProfile/dialogs/EditFeaturesDialog'
import AssignedAssets from '../components/userProfile/AssignedAssets'
import OtherUserInformation from '../components/userProfile/OtherUserInformation'
import AccessLevel from '../components/userProfile/AccessLevel'
import { userProfileURL } from '../constants/urlBuilder'
import { Auth } from 'aws-amplify'
import { ICurrentUser } from '../types/amplifyTypes/currentUser'

interface IProps {
  id: string
}

interface IState {
  userData?: IUserInfo
  loading: boolean
  deleteDialogOpen: boolean
  editOtherUserDialogOpen: boolean
  editFeaturesDialogOpen: boolean
}

class OtherUser extends React.Component<IProps> {
  state: IState = {
    loading: true,
    deleteDialogOpen: false,
    editOtherUserDialogOpen: false,
    editFeaturesDialogOpen: false,
  }

  grabUserInformation() {
    fetch(backend_api + '/user/' + this.props.id, {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          userData: response,
          loading: false,
        })
      })
      .catch((err) => console.error(err))
  }

  grabCurrentUserUUID(currentUserId: string) {
    return currentUserId.split(':')[1]
  }

  componentDidMount() {
    this.grabUserInformation()
    Auth.currentUserInfo().then((user: ICurrentUser) =>
      this.props.id === this.grabCurrentUserUUID(user.id)
        ? window.location.replace(
            userProfileURL(this.props.id, this.grabCurrentUserUUID(user.id))
          )
        : undefined
    )
  }

  render() {
    return (
      <div className="otherUserPage">
        <ReactHelmet title="User" />
        <LoadingOverlay loading={this.state.loading} />
        <Toolbar title="User" scrollBackgroundColor>
          <Button
            startIcon={<Icon icon="edit" />}
            onClick={() => this.setState({ editOtherUserDialogOpen: true })}
            disabled={this.state.loading}
            tooltip={this.state.loading ? undefined : 'Edit this user'}
          >
            Edit User
          </Button>
          <Button
            startIcon={<Icon icon="delete" />}
            onClick={() => this.setState({ deleteDialogOpen: true })}
            disabled={this.state.loading}
            tooltip={this.state.loading ? undefined : 'Delete this user'}
          >
            Delete User
          </Button>
        </Toolbar>
        {this.state.userData ? (
          <div className="otherUserPageBody">
            <OtherUserInformation
              userData={this.state.userData}
              loading={this.state.loading}
            />
            <AccessLevel
              userData={this.state.userData}
              loading={this.state.loading}
            />
            <div className="otherUserTableOuter">
              <AssignedAssets
                userData={this.state.userData}
                loading={this.state.loading}
              />
            </div>
          </div>
        ) : null}
        <DeleteUserDialog
          dialogOpen={this.state.deleteDialogOpen}
          onClose={() => this.setState({ deleteDialogOpen: false })}
          userName={
            this.state.userData
              ? this.state.userData.firstName +
                ' ' +
                this.state.userData.lastName
              : ''
          }
          userId={this.props.id}
        />
        {this.state.userData ? (
          <EditOtherUserDialog
            editedUserId={this.props.id}
            dialogOpen={this.state.editOtherUserDialogOpen}
            onClose={() => this.setState({ editOtherUserDialogOpen: false })}
            role={this.state.userData?.role}
            fname={this.state.userData?.firstName}
            lname={this.state.userData?.lastName}
            email={this.state.userData?.email}
          />
        ) : null}
        <EditFeaturesDialog
          dialogOpen={this.state.editFeaturesDialogOpen}
          onClose={() => this.setState({ editFeaturesDialogOpen: false })}
        />
      </div>
    )
  }
}

export default OtherUser
