import {
  EAccountTabs,
  HashFromString,
  HashToString,
} from '../../constants/hashControllers'
import { EServiceTabs } from '../../constants/serviceHashController'
import { EInventoryTabs } from '../../constants/inventoryHashController'
import HashStateManager from '../../rx-js/HashStateManager'

/**
 * Used to house all the Routes for the website.
 */
export enum ERoutes {
  login = '/',
  dashboard = '/',
  yard = '/yard',
  client = '/client',
  inventory = '/inventory',
  maptrac = '/maptrac',
  service = '/service',
  ticket = '/asset/ticket',
  ticketLog = '/asset/service/history',
  fleetReport = '/reports',
  fleetReportLegacy = '/api/reports', // legacy link
  profile = '/profile',
  user = '/user',
  notifications = '/notifications',
  account = '/account',
  branch = '/account/branches',
  signup = '/signup',
  verify = '/verify',
  forgot = '/forgot',
  error = '/error',
  flag = '/flag',
  archive = '/archive',
  attribute = '/attribute',
  categories = '/categories',
  subscriptions = '/subscriptions',
  history = '/history',
  equipment = '/asset',
  marketPlace = '/marketPlace',
  onboarding = '/onboard',
  tutorials = 'https://rovitracker.com/how-to-videos',
  mainLandingPage = 'https://www.rovitracker.com',
  // Reports
  EquipmentDailyRuntime = '/reports/runtimeByDay',
  EquipmentRuntimeBySite = '/reports/runtimeBySite',
  FlaggedSpeeding = '/reports/flaggedSpeeding',
  DeviceDisconnect = '/reports/eventDisconnected',
  RepairsByCategory = '/reports/repairsByCategory',
  AssetsByGeofence = '/reports/equipmentByRegion',
  ActiveClients = '/reports/rentalsByClient',
  EquipmentMileageByState = '/reports/mileageByState',
  VehicleFuel = '/reports/vehicleFuel',
  FluidUsageOverTime = '/reports/fluidUsage',
  FluidUsageOverTimeTable = '/reports/tankLevel',
}

/**
 * This Function is used to place the Custom Title at the top of the bar on each page.
 * it will check link, if one of those links changes pages on hash, it will check that.
 * @param tab pass the tab through this, inorder to have on demand update tab.
 */
export const PageTitle = (routerPage?: string, tab?: string) => {
  const route = window.location.pathname
  let hash = new HashStateManager(HashFromString, HashToString)
  switch (routerPage ? routerPage : route) {
    case ERoutes.dashboard:
      return 'Dashboard'
    case ERoutes.yard:
      return 'Yard'
    case ERoutes.client:
      return 'Client'
    case ERoutes.inventory:
      switch (tab ? tab : hash.value.tab) {
        case EInventoryTabs.thing:
          return 'Assets'
        case EInventoryTabs.people:
          return 'People'
        case EInventoryTabs.place:
          return 'Places'
        default:
          return 'Assets'
      }
    case ERoutes.onboarding:
      return 'Onboard'
    case ERoutes.maptrac:
      return 'Maptrac'
    case ERoutes.service:
      switch (tab ? tab : hash.value.tab) {
        case EServiceTabs.tickets:
          return 'Tickets'
        case EServiceTabs.tasks:
          return 'Tasks'
        case EServiceTabs.parts:
          return 'Parts'
        default:
          return 'Service'
      }
    // Reports
    case ERoutes.fleetReport:
      return 'Reports'
    case ERoutes.EquipmentDailyRuntime:
      return 'Engine Runtime By Day'
    case ERoutes.EquipmentRuntimeBySite:
      return 'Equipment Runtime By Site'
    case ERoutes.FlaggedSpeeding:
      return 'Flagged Speeding'
    case ERoutes.DeviceDisconnect:
      return 'Device Disconnect'
    case ERoutes.RepairsByCategory:
      return 'Repairs by Category'
    case ERoutes.AssetsByGeofence:
      return 'Assets by Geofence'
    case ERoutes.ActiveClients:
      return 'Active Clients'
    case ERoutes.EquipmentMileageByState:
      return 'Equipment Mileage By State'
    case ERoutes.VehicleFuel:
      return 'Vehicle Fuel Usage'
    case ERoutes.FluidUsageOverTimeTable:
      return 'Tank Level Report'
    case ERoutes.FluidUsageOverTime:
      return 'Fluid Usage Over Time'

    case ERoutes.user:
      return 'User'
    case ERoutes.profile:
      return 'User Profile'
    case ERoutes.notifications:
      return 'Notifications'
    case ERoutes.account:
      switch (tab ? tab : hash.value.tab) {
        case EAccountTabs.account:
          return 'Account: Rovitracker Inc.'
        case EAccountTabs.devices:
          return 'Devices'
        case EAccountTabs.fieldLabels:
          return 'Labels'
        case EAccountTabs.integrations:
          return 'Integrations'
        default:
          return 'Account'
      }
    case ERoutes.branch:
      return 'Branch'
    case ERoutes.archive:
      return 'Archive'
    case ERoutes.attribute:
      return 'Attributes'
    case ERoutes.categories:
      return 'Categories'
    case ERoutes.subscriptions:
      return 'Subscriptions'
    case ERoutes.flag:
      return 'Flags'
    case ERoutes.history:
      return 'History'
    case ERoutes.marketPlace:
      return 'Market Place'
    default:
      return ''
  }
}

/**
 * Adding Pages to this array/list will remove the default appbar on mobile
 */
export const disabledMobileBarPages = [
  PageTitle(ERoutes.flag),
  PageTitle(ERoutes.profile),
  PageTitle(ERoutes.client),
  PageTitle(ERoutes.branch),
  PageTitle(ERoutes.archive),
  PageTitle(ERoutes.attribute),
  PageTitle(ERoutes.categories),
  PageTitle(ERoutes.subscriptions),
  PageTitle(ERoutes.marketPlace),
  // Reports
  PageTitle(ERoutes.EquipmentDailyRuntime),
  PageTitle(ERoutes.EquipmentRuntimeBySite),
  PageTitle(ERoutes.FlaggedSpeeding),
  PageTitle(ERoutes.DeviceDisconnect),
  PageTitle(ERoutes.RepairsByCategory),
  PageTitle(ERoutes.EquipmentMileageByState),
  PageTitle(ERoutes.VehicleFuel),
  PageTitle(ERoutes.FluidUsageOverTime),
  PageTitle(ERoutes.FluidUsageOverTimeTable),
]
