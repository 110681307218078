import React from 'react'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { TCategoryIcons } from '../../types/category'
import { getCategorySvg } from '../../svg-icons/CategoryIcons'

export interface IIconProps extends SvgIconProps {
  icon: TCategoryIcons
}

const CategoryIcon = ({ icon, ...props }: IIconProps) => {
  const Icon = getCategorySvg(icon)
  return <Icon {...props} />
}

export default CategoryIcon
