import React from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import Typography from '@mui/material/Typography'
import { colors } from '../ux/roviTheme'

interface IProps {
  title?: string
}

/**
 * Universal panel component.
 * @param props.title pass the title for the subheader of the component.
 * @param props.children pass what you want inside the panel component here.
 */
const Panel: React.FunctionComponent<IProps> = (props) => (
  <div>
    {props.title && (
      <ListSubheader
        style={{
          background: colors.mbg,
          borderTop: `1px solid ${colors.assetBorderColor}`,
          borderBottom: `1px solid ${colors.assetBorderColor}`,
          height: 50,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography color="textPrimary">{props.title}</Typography>
      </ListSubheader>
    )}
    <div style={{ padding: '10px 32px' }}>{props.children}</div>
  </div>
)

export default Panel
