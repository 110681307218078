import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import Button from '../../buttons/Button'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { TFeatureAccess, allFeatures } from '../../../types/account/user'

const styles = (theme: Theme) =>
  createStyles({
    paper: {},
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    formControl: {
      margin: theme.spacing(1),
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
}

interface IState {
  featureAccess: TFeatureAccess[]
}

class EditFeaturesDialog extends React.Component<IProps, IState> {
  state: IState = {
    featureAccess: [...allFeatures],
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  componentDidMount() {}

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl className={this.props.classes.formControl}>
              <FormLabel>Feature Access</FormLabel>
              <FormGroup row>
                {allFeatures.map((feature) => (
                  <FormControlLabel
                    key={feature}
                    control={
                      <Checkbox
                        checked={this.state.featureAccess.includes(feature)}
                        onChange={(event) => {
                          const featureIndex =
                            this.state.featureAccess.indexOf(feature)
                          let newArr = this.state.featureAccess

                          if (event.target.checked) {
                            this.state.featureAccess.push(feature)
                          } else {
                            this.state.featureAccess.splice(featureIndex, 1)
                          }
                          this.setState({ featureAccess: newArr })
                        }}
                        color="primary"
                      />
                    }
                    label={`${feature[0].toUpperCase()}${feature.substr(1)}`}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditFeaturesDialog)
