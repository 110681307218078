import React from 'react'
import { standardIcons, IStandardIcons } from './ux/standardIcons'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

interface IIconProps extends SvgIconProps {
  icon: IStandardIcons
  variant?: 'button' | 'tab'
  viewSize?: string
  fontSize?: 'small' | 'inherit' | 'large' | 'medium'
  className?: string
}

/** @Error Ever have an error pointed at this file, check to make sure the icon exists */

/** Icon limited to the rovitracker standard set */
const Icon = ({
  icon,
  color,
  variant,
  viewSize,
  fontSize,
  className,
  ...props
}: IIconProps) => {
  const C = standardIcons[icon]
  return (
    <SvgIcon
      fontSize={fontSize}
      viewBox={viewSize ? viewSize : undefined}
      style={{
        width: viewSize && '24px',
        height: viewSize && '24px',
        marginRight: variant === 'button' ? 8 : undefined,
      }}
    >
      <C
        color={
          color
            ? color
            : variant === 'button'
            ? 'inherit'
            : variant === 'tab'
            ? 'inherit'
            : 'action'
        }
        {...props}
        className={className}
      />
    </SvgIcon>
  )
}

export default Icon
