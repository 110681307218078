import React from 'react'
import {
  ImageWithDefaultEquip,
  ImageWithDefaultPlace,
} from './ImageWithDefault'
import { TCategoryIcons } from '../../types/category'

const styles: Record<string, React.CSSProperties> = {
  container: {
    borderRadius: 4,
    overflow: 'hidden',
  },
}

interface IEquipProps {
  imageUrl?: string
  categoryIcon?: TCategoryIcons
  containerStyle?: React.CSSProperties
}

export const ListImageEquip: React.FunctionComponent<IEquipProps> = (props) => (
  <ImageWithDefaultEquip
    imageUrl={props.imageUrl}
    categoryIcon={props.categoryIcon}
    height={40}
    width={64}
    containerStyle={{
      ...styles.container,
      ...props.containerStyle,
    }}
  />
)

interface IPlaceProps {
  imageUrl?: string
  containerStyle?: React.CSSProperties
}

export const ListImagePlace: React.FunctionComponent<IPlaceProps> = (props) => (
  <ImageWithDefaultPlace
    imageUrl={props.imageUrl}
    height={40}
    width={64}
    containerStyle={{
      ...styles.container,
      ...props.containerStyle,
    }}
  />
)
