import React from 'react'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import IconButton from '../buttons/IconButton'
import Button from '../buttons/Button'
import {
  IEquipmentProfile,
  IBoundary,
} from '../../types/equipment/equipmentProfile'
import NumberField from '../textBoxs/NumberField'
import Icon from '../Icon'
import { colors } from '../ux/roviTheme'

const styles = createStyles({
  numberField: {
    width: 75,
    height: 36,
  },
  creatingBoundary: {
    height: 36,
  },
})

interface IProps extends WithStyles<typeof styles> {
  equipmentProfile?: IEquipmentProfile
}

interface IState {
  isCreatingNewGeofence: boolean
  newRadius: number | null
}

class BoundarySegment extends React.Component<IProps, IState> {
  state = {
    isCreatingNewGeofence: false,
    newRadius: 50,
  }

  createGeofenceFlip = () => {
    this.setState({ isCreatingNewGeofence: !this.state.isCreatingNewGeofence })
  }

  onSave = () => {
    this.createGeofenceFlip()
  }

  saveEditGeofence() {
    return (
      <div className={this.props.classes.creatingBoundary}>
        <NumberField
          value={this.state.newRadius}
          onChange={(event) => this.setState({ newRadius: event })}
          endAdornment="ft"
          className={this.props.classes.numberField}
          min={50}
        />
        <Button size="small" onClick={() => this.createGeofenceFlip()}>
          CANCEL
        </Button>
        <Button size="small" onClick={() => this.onSave()}>
          SAVE
        </Button>
      </div>
    )
  }

  insideOutside(inside: boolean) {
    return (
      <Button
        size="small"
        onClick={() => this.onSave()}
        startIcon={
          <Icon
            icon="checkAll"
            style={{ color: inside ? colors.onGreen : colors.red }}
          />
        }
      >
        {inside ? 'INSIDE' : 'OUTSIDE'}
      </Button>
    )
  }

  displayGeofence(boundary: IBoundary) {
    return (
      <div className={this.props.classes.creatingBoundary}>
        {boundary.radius}ft
        <IconButton
          icon="recenterIcon"
          variant="row"
          tooltip="Re-center Geofence to Current Equipment Location"
        />
        <IconButton icon="edit" variant="row" tooltip="Edit Geofence" />
        <IconButton icon="delete" variant="row" tooltip="Delete Geofence" />
        {this.insideOutside(boundary.inside)}
      </div>
    )
  }

  render() {
    const equipmentProfile = this.props.equipmentProfile
    return equipmentProfile ? (
      equipmentProfile.gps ? (
        equipmentProfile.boundary ? (
          this.displayGeofence(equipmentProfile.boundary)
        ) : this.state.isCreatingNewGeofence ? (
          this.saveEditGeofence()
        ) : (
          <IconButton
            icon="geofence"
            variant="row"
            tooltip="Create a new Geofence"
            onClick={this.createGeofenceFlip}
          />
        )
      ) : (
        <Typography variant="body1">
          Radius settings available when GPS location is fixed
        </Typography>
      )
    ) : null
  }
}

export default withStyles(styles)(BoundarySegment)
