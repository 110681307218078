import React from 'react'
import {
  EMarkerShape,
  EMarkerCenterPosition,
  OverlayMarker,
} from '../../Marker'
import { onlyUpdateForKeys } from 'recompose'
import MaptracOverlay from '../../MaptracOverlay'
import { iconPaths } from '../../../../svg-icons/CategoryIcons'
import { TCategoryIcons } from '../../../../types/category'

interface IProps {
  lat: number
  lng: number
  color: string
  icon: TCategoryIcons
  name: string
  offset: number
  onClick?: () => void
  isTransparent: boolean
  isHighlighted: boolean
}

const TagMarker: React.FunctionComponent<IProps> = (props) => (
  <MaptracOverlay
    lat={props.lat}
    lng={props.lng}
    zIndex={props.isHighlighted ? 7 : 6}
  >
    <OverlayMarker
      onClick={props.onClick}
      title={props.name}
      centerPosition={EMarkerCenterPosition.Center}
      shape={EMarkerShape.Circle}
      iconPath={iconPaths[props.icon]}
      markerColor={props.color}
      offset={props.offset}
      scale={props.isHighlighted ? 1 : 0.8}
      opacity={props.isTransparent ? 0.2 : 1}
    />
  </MaptracOverlay>
)

export default onlyUpdateForKeys([
  'lat',
  'lng',
  'isHighlighted',
  'isTransparent',
  'offset',
])(TagMarker)
