import React from 'react'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { Select, MenuItem } from '@mui/material'
import Typography from '@mui/material/Typography'
import Margin from '../ux/Margin'

const hours = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

/// Splits '12AM' into { hour: '12', meridian: 'AM' }
const splitTime = (time: string): [string, string] => {
  const index = time.length - 2
  const hour = time.substring(0, index)
  const meridian = time.substring(index)
  return [hour, meridian]
}

interface IHoursOfDayFilter extends WithStyles<typeof styles> {
  hours: [string, string]
  onChange: (newTimes: [string, string]) => void
}

const HoursOfDayFilter = (props: IHoursOfDayFilter) => {
  const [from, to] = props.hours
  const [toHour, toMeridian] = splitTime(to)
  const [fromHour, fromMeridian] = splitTime(from)

  return (
    <div className={props.classes.container}>
      <div className={props.classes.section}>
        <Typography variant="body1">From:</Typography>
        <Margin width={6} />
        <Select
          variant="standard"
          value={fromHour}
          onChange={(ev) =>
            props.onChange([ev.target.value + fromMeridian, to])
          }
        >
          {hours.map((hour) => (
            <MenuItem key={hour} value={hour}>
              {hour}
            </MenuItem>
          ))}
        </Select>
        <Margin width={2} />
        <Select
          variant="standard"
          value={fromMeridian}
          onChange={(ev) => props.onChange([fromHour + ev.target.value, to])}
        >
          <MenuItem value="AM">AM</MenuItem>
          <MenuItem value="PM">PM</MenuItem>
        </Select>
      </div>
      <Margin width={10} />
      <div className={props.classes.section}>
        <Typography variant="body1">To:</Typography>
        <Margin width={6} />
        <Select
          variant="standard"
          value={toHour}
          onChange={(ev) =>
            props.onChange([from, ev.target.value + toMeridian])
          }
        >
          {hours.map((hour) => (
            <MenuItem key={hour} value={hour}>
              {hour}
            </MenuItem>
          ))}
        </Select>
        <Margin width={2} />
        <Select
          variant="standard"
          value={toMeridian}
          onChange={(ev) => props.onChange([from, toHour + ev.target.value])}
        >
          <MenuItem value="AM">AM</MenuItem>
          <MenuItem value="PM">PM</MenuItem>
        </Select>
      </div>
    </div>
  )
}

const styles = createStyles({
  container: {
    padding: 10,
    display: 'flex',
    flexWrap: 'wrap',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
})

export default withStyles(styles)(HoursOfDayFilter)
