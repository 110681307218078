import React from 'react'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import { ERoutes, PageTitle } from '../components/routes/CheckRoutes'
import List from '@mui/material/List'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import SubscriptionRow, {
  ISubscriptionUser,
} from '../components/subscriptionComponents/SubscriptionRow'
import { backend_api, headersAPI } from '../constants/api'
import { RoviLog } from '../utility/roviLog'
import { IUser } from '../types/account/user'
import {
  ITreeAssetsCate,
  ITreePlaces,
  ITreeLayout,
  ITreeItem
} from '../components/ux/TreeSelector'

interface ISubscription {
  flagId?: string
  name: string
  users: ISubscriptionUser[]
}

interface IAllSubscription {
  standard: ISubscription[]
  custom: ISubscription[]
  event: ISubscription[]
}

interface IProps {}

interface IState {
  subscriptions: IAllSubscription
  subscriptionLoading: boolean
  allUsers: IUser[]
  usersLoading: boolean
  treeAssetsCategory: ITreeLayout[]
  treePlaces: ITreeLayout[]
}

class SubscriptionsPage extends React.Component<IProps, IState> {
  state: IState = {
    subscriptionLoading: true,
    subscriptions: {
      standard: [
        {
          name: 'Outside Asset Boundary',
          users: [],
        },
        {
          name: 'Places Alert',
          users: [],
        },
      ],
      custom: [],
      event: [],
    },
    allUsers: [],
    usersLoading: true,
    treeAssetsCategory: [],
    treePlaces: [],
  }

  grabAllSubscriptions() {
    fetch(backend_api + '/subscription/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          subscriptions: response,
          subscriptionLoading: false,
        })
      })
      .catch((err) => RoviLog.info(err, 'error'))
  }

  loadUsers() {
    fetch(backend_api + '/users/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          allUsers: response,
        })
      })
      .catch((err) => console.log(err))
  }

  loadAssetsbyCategory() {
    fetch(backend_api + '/assets/tree/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response: ITreeAssetsCate[]) => {
        let newResponse: ITreeLayout[] = []
        response.forEach((tree) => {
          let newAssets: ITreeItem[] = []
          tree.assets.forEach((assets) => {
            newAssets.push({
              label: assets.name,
              value: assets.id,
            })
          })
          newResponse.push({
            label: tree.category.name,
            value: tree.category.id,
            children: newAssets
          })
        })
        this.setState({
          treeAssetsCategory: newResponse,
        })
      })
      .catch((err) => console.log(err))
  }

  loadPlacesTree() {
    fetch(backend_api + '/places/tree/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response: ITreePlaces[]) => {
        let newResponse: ITreeLayout[] = []
        response.forEach((tree) => {
          let newAssets: ITreeItem[] = []
          tree.area.forEach((area) => {
            newAssets.push({
              label: area.name,
              value: area.id,
            })
          })
          newResponse.push({
            label: tree.place.name,
            value: tree.place.id,
            children: newAssets
          })
        })
        this.setState({
          treePlaces: newResponse,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabAllSubscriptions()
    this.loadUsers()
    this.loadAssetsbyCategory()
    this.loadPlacesTree()
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <ReactHelmet title={PageTitle(ERoutes.subscriptions)} />
        <Toolbar title={PageTitle(ERoutes.subscriptions)} noXtraBar />
        <div style={{ width: 820, marginTop: 50 }}>
          <Typography variant="h6" style={{ paddingTop: 10, paddingLeft: 20 }}>
            Standard Flag Subscriptions
          </Typography>

          <Paper style={{ margin: 10, overflow: 'hidden' }}>
            {this.state.subscriptions.standard.map((val, key) => (
              <SubscriptionRow
                isFlag
                name={val.name}
                id={val.flagId}
                key={`standard ${key}`}
                allUsers={this.state.allUsers}
                subscribedUsers={val.users}
                treeAssetsCategory={
                  val.name === 'Places Alert'
                    ? this.state.treePlaces
                    : this.state.treeAssetsCategory
                }
              />
            ))}
          </Paper>

          <Typography variant="h6" style={{ paddingTop: 10, paddingLeft: 20 }}>
            Custom Flag Subscriptions
          </Typography>

          <Paper style={{ margin: 10, overflow: 'hidden' }}>
            <List disablePadding>
              {this.state.subscriptions.custom.map((val, key) => (
                <SubscriptionRow
                  isFlag
                  name={val.name}
                  id={val.flagId}
                  key={`custom ${key}`}
                  allUsers={this.state.allUsers}
                  subscribedUsers={val.users}
                  treeAssetsCategory={this.state.treeAssetsCategory}
                />
              ))}
            </List>
          </Paper>

          <Typography variant="h6" style={{ paddingTop: 10, paddingLeft: 20 }}>
            Event Subscriptions
          </Typography>

          <Paper style={{ margin: 10, overflow: 'hidden' }}>
            {this.state.subscriptions.event.map((val, key) => (
              <SubscriptionRow
                name={val.name}
                id={val.flagId}
                key={`standard ${key}`}
                allUsers={this.state.allUsers}
                subscribedUsers={val.users}
                treeAssetsCategory={this.state.treeAssetsCategory}
              />
            ))}
          </Paper>
        </div>
      </div>
    )
  }
}

export default SubscriptionsPage
