import React from 'react'
import '../../styles/components/accountComponents/AccountBranch.scss'
import Icon from '../../components/Icon'
import Button from '../../components/buttons/Button'
import Typography from '@mui/material/Typography'
import AccountBranchCard from './AccountBranchCard'
import Paper from '@mui/material/Paper'
import AddEditBranchDialog from './dialogs/AddEditBranchDialog'
import { IBranch } from '../../types/account/account'

interface IProps {
  branches?: any
  loadingData: boolean
  isMobile: boolean
}

interface IState {
  addBranchDialogOpen: boolean
}

class AccountBranches extends React.Component<IProps, IState> {
  state: IState = {
    addBranchDialogOpen: false,
  }

  render() {
    return (
      <>
        <div>
          <Paper className="accountBranchCardLongContainer">
            <div className="accountBranchCardHeaderLong">
              <Typography variant="h5">Branches</Typography>
              <Button
                onClick={() => this.setState({ addBranchDialogOpen: true })}
                disabled={this.props.loadingData}
              >
                <Icon icon="add" variant="button" />
                Branch
              </Button>
            </div>

            {this.props.branches
              ? this.props.branches.map((branch: IBranch) => (
                  <AccountBranchCard
                    key={branch.BranchId}
                    branch={branch}
                    isMobile={this.props.isMobile}
                  />
                ))
              : null}
          </Paper>
        </div>
        <AddEditBranchDialog
          type="add"
          dialogOpen={this.state.addBranchDialogOpen}
          onClose={() => this.setState({ addBranchDialogOpen: false })}
        />
      </>
    )
  }
}

export default AccountBranches
