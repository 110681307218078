import React from 'react'

interface IViewport {
  width: number
  height: number
  isMobile: boolean
}

interface IProps {
  children: (viewport: IViewport) => React.ReactNode
}

interface IState {
  viewport: IViewport
}

export default class WidthViewport extends React.Component<IProps, IState> {
  mobileThreshold = 840

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => this.setState({ viewport: this.getDimensions() })

  getDimensions = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < this.mobileThreshold,
  })

  state: IState = {
    viewport: this.getDimensions(),
  }

  render() {
    return this.props.children(this.state.viewport)
  }
}
