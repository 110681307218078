import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import DialogContentText from '@mui/material/DialogContentText'

interface IProps {
  dialogOpen: boolean
  onClose: () => void
  feeDescription: string
  feeId: string
}

interface IState {}

class DeleteDefaultFeeDialog extends React.Component<IProps, IState> {
  state: IState = {}

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = () => {
    this.props.onClose()
  }

  render() {
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose}>
        <DialogTitle>Delete {this.props.feeDescription}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this agreement fee?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              this.onSubmit()
              this.handleClose()
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default DeleteDefaultFeeDialog
