import React from 'react'
import Button from './Button'
import Badge from '@mui/material/Badge'
import { IStandardIcons } from '../ux/standardIcons'
import Icon from '../Icon'
import { WithStyles } from '@mui/styles'
import { withStyles, createStyles } from '@mui/styles'
import DrawerContainer, { Anchor } from '../ux/DrawerContainer'

const styles = createStyles({
  badge: {
    width: 23,
  },
})

interface IState {
  anchorEl: Anchor
  anchor: boolean
}

interface IProps extends WithStyles<typeof styles> {
  title?: string
  icon: IStandardIcons
  swipeable?: boolean
  anchor?: Anchor
  width?: number
  dot?: boolean
  children?: React.ReactNode // Everything was breaking without this so added it when upgrading to Material-ui@v5.0.0-alpha.25 Feb 15th
}

/**
 * Flat Button Design that opens a drawer
 */
class ButtonDrawer extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: this.props.anchor ? this.props.anchor : 'left',
    anchor: false,
  }

  render() {
    const props = this.props
    const classes = this.props.classes
    return (
      <div>
        <Button
          id="assetsToolBarFilterButton"
          onClick={() => this.setState({ anchor: true })}
        >
          <Badge
            color="secondary"
            variant="dot"
            invisible={props.dot ? false : true}
            className={classes.badge}
          >
            <Icon icon={props.icon} variant="button" />
          </Badge>
          <span style={{ marginLeft: 8 }}>{props.title}</span>
        </Button>
        <DrawerContainer
          swipeable={this.props.swipeable}
          anchorEl={this.state.anchorEl}
          anchor={this.state.anchor}
          setAnchor={(bool: boolean) => this.setState({ anchor: bool })}
          width={this.props.width}
        >
          {props.children}
        </DrawerContainer>
      </div>
    )
  }
}

export default withStyles(styles)(ButtonDrawer)
