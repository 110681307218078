import React from 'react'
import { Section } from '../equipmentComponents/EquipmentSection'
import DeviceBoxInsides, {
  DeviceBoxOutside,
} from './deviceComponents/DeviceBoxInsides'
import { IEquipmentProfile } from '../../types/equipment/equipmentProfile'
import { EModel, IAssetDevice } from '../../types/account/devices'
import DFU from './deviceComponents/deviceBoxComponents/DFU'
import IgnitionState from './deviceComponents/deviceBoxComponents/IgnitionState'
import DeviceDialog from './deviceComponents/DeviceDialog'

interface IProps {
  equipmentProfile: IEquipmentProfile
  isMobile: boolean
}

interface IState {
  deviceDialogIsOpen: boolean
  assignedDevices: IAssetDevice[]
}

class DeviceSection extends React.Component<IProps, IState> {
  state: IState = {
    deviceDialogIsOpen: false,
    assignedDevices: [],
  }

  deviceBoxSwitch(device: IAssetDevice) {
    switch (device.deviceType) {
      case EModel.rlgl300ma:
      case EModel.rlgl500ma:
        return (
          <DFU id={device.esn} deviceType={device.deviceType}>
            <DeviceBoxInsides device={device} />
          </DFU>
        )
      case EModel.rl26:
      case EModel.rl28:
      case EModel.rl400:
        return (
          <IgnitionState
            id={device.esn}
            deviceType={device.deviceType}
            ignitionDisabled
          >
            <DeviceBoxInsides device={device} />
          </IgnitionState>
        )
      default:
        return <DeviceBoxInsides device={device} />
    }
  }

  componentDidMount() {
    this.setState({ assignedDevices: this.props.equipmentProfile.devices })
  }

  render() {
    const { equipmentProfile } = this.props
    const devices = equipmentProfile.devices
    return (
      <div style={{ width: '100%', margin: '10px 0' }}>
        <Section
          header="Devices"
          equipId={equipmentProfile.id}
          emptyMessage={devices.length === 0 ? 'no devices' : undefined}
          editButton
          onEdit={() => this.setState({ deviceDialogIsOpen: true })}
        >
          {/* This div here is to shrink the amount of devices listed at once if they have over like 6 devices */}
          <div style={{ maxHeight: 300, overflow: 'auto' }}>
            {this.state.assignedDevices.map((device, key) => (
              <DeviceBoxOutside key={'device' + key}>
                {this.deviceBoxSwitch(device)}
              </DeviceBoxOutside>
            ))}
          </div>
        </Section>
        <DeviceDialog
          open={this.state.deviceDialogIsOpen}
          onClose={() => this.setState({ deviceDialogIsOpen: false })}
          isMobile={this.props.isMobile}
          equipmentProfile={this.props.equipmentProfile}
        />
      </div>
    )
  }
}

export default DeviceSection
