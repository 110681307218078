import React from 'react'
import '../../styles/components/menus/TabSelection.scss'
import { SECTION_HEIGHT, SECTION_WIDTH } from './NavBar'

interface IProps {
  idx: number
}

class SideTabSelection extends React.Component<IProps> {
  render() {
    const props = this.props
    return (
      <div className="SideTabSectionCompContainer">
        {props.children}

        <div
          className="SideTabSectionCompIndicatorContainer"
          style={{
            top: SECTION_HEIGHT * props.idx,
            height: SECTION_HEIGHT,
            width: SECTION_WIDTH,
          }}
        >
          <div className="SideTabSectionCompContainer">
            <div
              className="SideTabSectionCompArrow"
              style={{
                borderTop: `${SECTION_HEIGHT / 2}px solid transparent`,
                borderBottom: `${SECTION_HEIGHT / 2}px solid transparent`,
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default SideTabSelection
