import React from 'react'
import GoogleRoviMap, { IBounds } from '../GoogleRoviMap'
import LiveMaptracDrawerComponents from './LiveMaptracDrawerComponents'
import { TFocusType } from './LiveMaptracDrawerComponents'
import LiveMaptracMarkers from './LiveMaptracMarkers'
import { getHash, clearHash } from '../../../constants/hashGrabber'
import {
  MaptracHashFromString,
  MaptracHashToString,
  IMaptracHash,
  IMaptracFocus,
  EMaptracFocus,
} from '../../../constants/maptracHashController'
import HashStateManager from '../../../rx-js/HashStateManager'
import CurrentLocationButton from '../CurrentLocationButton'
import { backend_api, headersAPI } from '../../../constants/api'
import GeofenceButton from '../GeofenceButton'
import { ERadiusType, TRadiusType } from '../GeofenceDrawingManager'
import { ISite, IClientSites } from '../../../types/maptrac/Sites'
import { ITag } from '../../../types/maptrac/Tags'
import FilterMaptrac, {
  IMaintenance,
  maintenanceHashEncrypter,
  maintenanceHashDecrypter,
  splitToArr,
} from '../filters/FilterMaptrac'
import { ILatLng } from '../../../types/maptrac/LatLng'
import { IGeofenceInfo, IGeofence } from '../../../types/maptrac/Geofences'
import { ICategory } from '../../../types/category'
import {
  IAssetClicked,
  IAssetList,
  IAsset,
} from '../../../types/maptrac/Assets'
import { findZoomLvl } from '../findZoomLvl'
import {
  filterAssetsDrawer,
  filterTagMarkers,
  filterAssetsMarker,
  filterAssetsMaintenanceMarker,
} from './MaptracAssetFilter'
import {
  AssetsSorter,
  TagsSorter,
  MaintenanceSorter,
  AssetCategorySorter,
} from './MaptracAssetSorter'
import { SitesSorter } from './MaptracSitesSorter'
import {
  filterSiteMarkers,
  filterClientSitesDrawer,
} from './MaptracSitesFilter'
import {
  filterGeofenceDrawer,
  filterGeofenceMarkers,
  filterGeofenceMarkersUUID,
} from './MaptracGeofenceFilter'
import getAllGeofenceData from '../../../apiCalls/getCalls/getAllGeofenceData'
import EditGeofence from '../EditGeofence'

interface IProps {
  isMobile: boolean
  height: number
  width: number
}

interface IState {
  /**
   * SortingPoint
   */
  // searchString is for the maptrac Drawer
  searchString: string
  BLETagsShown: boolean
  maintenance: IMaintenance
  categoriesFiltered: string[]
  categoriesList: ICategory[]

  focused: boolean
  center?: ILatLng
  zoom?: number
  focusedAssetLatLng?: ILatLng
  assetBounds?: IBounds
  focusedType: TFocusType
  tagMarkers: Array<ITag[]>
  // Geofence
  geofence: IGeofence[]
  geofenceLoading: boolean
  geofenceBeingEdited?: IGeofenceInfo // should be a UUID of the geofence
  // Sites
  siteMarkers: ISite[]
  sitesLoading: boolean
  clientsVal: IClientSites[]
  // Assets
  assetsList: IAssetList[]
  assetMarkers: IAsset[]
  assetLatLng: Array<ILatLng>
  assetTagsLoading: boolean
}
class LiveMaptracContainer extends React.Component<IProps, IState> {
  state: IState = {
    searchString: '',
    BLETagsShown: false,
    maintenance: {
      red: {
        value: 0,
        filter: true,
      },
      orange: {
        value: 0,
        filter: true,
      },
      blue: {
        value: 0,
        filter: true,
      },
    },
    categoriesList: [],
    categoriesFiltered: [],
    focused: false,
    focusedType: 'not_focused',
    assetBounds: undefined,
    focusedAssetLatLng: undefined,
    // Assets
    assetsList: [],
    assetMarkers: [],
    assetLatLng: [],
    assetTagsLoading: true,
    // BLE Tags
    tagMarkers: [],
    // Geofences
    geofence: [],
    geofenceLoading: true,
    // Sites
    /** clients that contain the sites */
    clientsVal: [],
    /** site markers loaded onto maptrac */
    siteMarkers: [],
    /** are the sites still pending to load */
    sitesLoading: true,
  }

  hash: HashStateManager<IMaptracHash> = new HashStateManager(
    MaptracHashFromString,
    MaptracHashToString
  )

  setTab = (focus: IMaptracFocus, id: string) => {
    this.setState({ focused: true, focusedType: focus })
    this.hash.set({
      ...this.hash.value,
      focus: focus,
      id: id,
    })
  }

  clearSelection = () => {
    this.setState({ focused: false })
  }

  clickAsset(assetId: string) {
    this.clearSelection()
    this.setTab(EMaptracFocus.asset, assetId)
  }

  clickGeofence(geofenceId: string) {
    this.clearSelection()
    this.setTab(EMaptracFocus.geofence, geofenceId)
  }

  clickSite(siteId: string) {
    this.clearSelection()
    this.setTab(EMaptracFocus.site, siteId)
  }

  updateCenterPosition(center: ILatLng, zoom: number) {
    this.setState({ center: center, zoom: zoom })
  }

  assetMFilters() {
    return filterAssetsMaintenanceMarker(
      filterAssetsMarker(this.state.assetMarkers, this.state.searchString),
      this.state.maintenance.red.filter,
      this.state.maintenance.orange.filter,
      this.state.maintenance.blue.filter
    )
  }

  setCategoriesFiltered(assetsList: IAssetList[]) {
    let newArr: string[] = []
    AssetCategorySorter(assetsList).forEach((c) => newArr.push(c.id))
    return newArr
  }

  /**
   * Sites Call, should load the list view, and the markers
   */
  grabMaptracSiteCall = () => {
    fetch(backend_api + '/maptrac/sites', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          clientsVal: response,
          siteMarkers: SitesSorter(response),
          sitesLoading: false,
        })
      })
      .catch((err) => console.error(err))
  }

  /**
   * Geofences Call, loads the list view and the markers
   * You can also use this API Call for Reloading Geofences
   */
  grabMaptracGeofenceCall = () => {
    // fetch(backend_api + '/maptrac/geofences', {
    getAllGeofenceData()
      .then((data) => {
        this.setState({
          geofence: data ? data : [],
          geofenceLoading: false,
        })
      })
      .catch(() => console.log('error'))
  }

  /**
   * Assets Call, loads the list view and markers
   */
  grabMaptracAssetCall = () => {
    fetch(backend_api + '/maptrac/assets', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          assetMarkers: AssetsSorter(response),
          assetsList: response,
          assetTagsLoading: false,
          tagMarkers: TagsSorter(response),
          maintenance: MaintenanceSorter(response, this.state.maintenance),
          categoriesList: AssetCategorySorter(response),
          categoriesFiltered: this.setCategoriesFiltered(response),
        })

        this.grabMarkerILatLng([])
      })
      .catch((err) => console.error(err))
  }

  grabMarkerILatLng(assetLatLngVal: Array<ILatLng>) {
    let assetLatLngClone = assetLatLngVal
    this.state.assetMarkers.forEach((marker) =>
      assetLatLngClone.push(marker.gps)
    )
    this.setState({ assetLatLng: assetLatLngClone })
    return assetLatLngClone
  }

  componentDidMount() {
    const hashgrabbed = getHash() ? (getHash() as IMaptracHash) : undefined
    if (!!hashgrabbed) {
      if (hashgrabbed.focus && hashgrabbed.id) {
        this.setTab(
          hashgrabbed.focus ? hashgrabbed.focus : EMaptracFocus.asset,
          hashgrabbed.id ? hashgrabbed.id : ''
        )
      }
      this.setState({
        BLETagsShown: hashgrabbed.displayTags === 'true' ? true : false,
        maintenance: maintenanceHashDecrypter(
          this.state.maintenance,
          hashgrabbed.tasks ? hashgrabbed.tasks : ''
        ),
        categoriesFiltered: splitToArr(
          hashgrabbed.categories ? hashgrabbed.categories : ''
        ),
      })
    }
    /** I call the markers here so they can communicate with both the map, and drawer */
    this.grabMaptracAssetCall()
    this.grabMaptracGeofenceCall()
    this.grabMaptracSiteCall()
  }

  render() {
    return (
      <GoogleRoviMap
        isMobile={this.props.isMobile}
        height={this.props.height}
        width={this.props.width}
        onChangeCenter={(val) => console.log(val)}
        gpsCoords={this.state.assetLatLng}
        onClick={() => console.log('maplive c')}
      >
        {(map: google.maps.Map<Element>) => (
          <>
            <LiveMaptracDrawerComponents
              searchString={this.state.searchString}
              updateSearch={(val: string) =>
                this.setState({ searchString: val })
              }
              isMobile={this.props.isMobile}
              focused={this.state.focused}
              unFocus={() => {
                clearHash()
                this.setState({ focused: false })
              }}
              focusedType={this.state.focusedType}
              // Sites
              clientsVal={filterClientSitesDrawer(
                this.state.clientsVal,
                this.state.searchString
              )}
              sitesValTotal={
                filterSiteMarkers(
                  this.state.siteMarkers,
                  this.state.searchString
                ).length
              }
              sitesLoading={this.state.sitesLoading}
              siteClicked={(siteId: string, gps: ILatLng) => {
                this.setTab(EMaptracFocus.site, siteId)
                setTimeout(() => {
                  // I left it this way because it behaves the exact same as original maptrac version
                  // map.panTo(gps)
                  // map.setZoom(11)
                  let bounds = new google.maps.LatLngBounds()
                  bounds.extend(gps)
                  map.fitBounds(bounds)
                }, 1)
              }}
              // Geofences
              geofencesVal={filterGeofenceDrawer(
                this.state.geofence,
                this.state.searchString
              )}
              geofencesLoading={this.state.geofenceLoading}
              setEditedGeofence={(editedGeofence) =>
                this.setState({ geofenceBeingEdited: editedGeofence })
              }
              onReloadGeofences={this.grabMaptracGeofenceCall}
              geofenceClicked={(val) => {
                this.setTab(EMaptracFocus.geofence, val.geofenceId)
                setTimeout(() => {
                  this.clickGeofence(val.geofenceId)
                  let bounds = new google.maps.LatLngBounds()
                  val.latlng.forEach((path: google.maps.LatLng | ILatLng) =>
                    // adding each path to the boundary once this is full it should allow for some spacing along with it zooming on to the target.
                    bounds.extend(path)
                  )
                  if (val.type === ERadiusType.Circle) {
                    map.setCenter(val.latlng[0])
                    map.setZoom(findZoomLvl(val.radius))
                  } else {
                    map.fitBounds(bounds)
                  }
                }, 1)
              }}
              // Assets
              assetsVal={filterAssetsDrawer(
                this.state.assetsList,
                this.state.searchString,
                this.state.maintenance
              )}
              assetsValTotal={this.assetMFilters().length}
              assetsLoading={this.state.assetTagsLoading}
              assetClicked={(val: IAssetClicked) => {
                /** We need to panTo the asset, the panning should happen here */
                this.setTab(EMaptracFocus.asset, val.assetId)
                setTimeout(() => {
                  // I left it this way because it behaves the exact same as original maptrac version
                  let bounds = new google.maps.LatLngBounds()
                  bounds.extend(val.gps)
                  map.fitBounds(bounds)
                  // map.panTo(val.gps)
                  // map.setZoom(11)
                }, 1)
              }}
              width={this.props.width}
              height={this.props.height}
            />
            {/* 
                According to Docs your suppose to wrap the google map around everything that modifys it 
                https://react-google-maps-api-docs.netlify.app/#section-getting-started 
              */}
            <LiveMaptracMarkers
              isMobile={this.props.isMobile}
              assetClicked={(val: IAssetClicked) => {
                this.clickAsset(val.assetId)
                setTimeout(() => {
                  // I left it this way because it behaves the exact same as original maptrac version
                  // map.panTo(val.gps)
                  // map.setZoom(11)
                  let bounds = new google.maps.LatLngBounds()
                  bounds.extend(val.gps)
                  map.fitBounds(bounds)
                }, 1)
              }}
              BLETagsShown={this.state.BLETagsShown}
              assetMarkers={this.assetMFilters()}
              geofenceClicked={(
                geofenceId: string,
                paths: google.maps.LatLng[] | ILatLng[],
                geofenceType: TRadiusType,
                radius: number
              ) => {
                setTimeout(() => {
                  this.clickGeofence(geofenceId)
                  let bounds = new google.maps.LatLngBounds()
                  paths.forEach((path: google.maps.LatLng | ILatLng) =>
                    // adding each path to the boundary once this is full it should allow for some spacing along with it zooming on to the target.
                    bounds.extend(path)
                  )
                  if (geofenceType === ERadiusType.Circle) {
                    map.setCenter(paths[0])
                    map.setZoom(findZoomLvl(radius))
                  } else {
                    map.fitBounds(bounds)
                  }
                }, 1)
              }}
              geofenceMarkers={filterGeofenceMarkersUUID(
                filterGeofenceMarkers(
                  this.state.geofence,
                  this.state.searchString
                ),
                this.state.geofenceBeingEdited
                  ? this.state.geofenceBeingEdited.geofenceId
                  : ''
              )}
              // Sites
              siteMarkers={filterSiteMarkers(
                this.state.siteMarkers,
                this.state.searchString
              )}
              siteClicked={(siteId: string, gps: ILatLng) => {
                this.clickSite(siteId)
                setTimeout(() => {
                  // I left it this way because it behaves the exact same as original maptrac version
                  // map.panTo(gps)
                  // map.setZoom(11)
                  let bounds = new google.maps.LatLngBounds()
                  bounds.extend(gps)
                  map.fitBounds(bounds)
                }, 1)
              }}
              tagMarkers={
                this.state.BLETagsShown
                  ? this.state.tagMarkers
                  : filterTagMarkers(
                      this.state.tagMarkers,
                      this.state.searchString
                    )
              }
            />

            <GeofenceButton
              isMobile={this.props.isMobile}
              onReload={this.grabMaptracGeofenceCall}
            />

            {this.state.geofenceBeingEdited ? (
              <EditGeofence
                isMobile={this.props.isMobile}
                onReload={this.grabMaptracGeofenceCall}
                isOpen
                onClose={() =>
                  this.setState({ geofenceBeingEdited: undefined })
                }
                shape={this.state.geofenceBeingEdited.type}
                points={this.state.geofenceBeingEdited.points}
                name={this.state.geofenceBeingEdited.geofenceName}
                geofenceUUID={this.state.geofenceBeingEdited.geofenceId}
              />
            ) : null}

            <CurrentLocationButton
              isMobile={this.props.isMobile}
              centerUser={(location: ILatLng, zoom: number) => {
                map.panTo(location)
                map.setZoom(zoom)
              }}
              isLiveMap
            />

            <FilterMaptrac
              isMobile={this.props.isMobile}
              BLETagsShown={this.state.BLETagsShown}
              BLETagsToggle={(bool: boolean) => {
                this.setState({ BLETagsShown: bool })
                this.hash.set({
                  ...this.hash.value,
                  displayTags: bool,
                })
              }}
              maintenance={this.state.maintenance}
              changeMaintenance={(newMaintenance: IMaintenance) => {
                this.setState({ maintenance: newMaintenance })
                this.hash.set({
                  ...this.hash.value,
                  tasks: maintenanceHashEncrypter(newMaintenance),
                })
              }}
              clearHash={() => this.hash.set({})}
              categoriesList={this.state.categoriesList}
              assets={this.state.assetsList}
              updateCategoriesFiltered={(val) => {
                this.hash.set({
                  ...this.hash.value,
                  categories: val.toString(),
                })
                this.setState({ categoriesFiltered: val })
              }}
              categoriesFiltered={this.state.categoriesFiltered}
            />
          </>
        )}
      </GoogleRoviMap>
    )
  }
}

export default LiveMaptracContainer
