import React from 'react'
import '../../styles/components/toolbar/Toolbar.scss'
import BackButton from '../../components/buttons/BackButton'
import Typography from '@mui/material/Typography'
import { colors } from '../ux/roviTheme'

interface IProps {
  title?: string
  noXtraBar?: boolean
  noBackButton?: boolean
  scrollBackgroundColor?: boolean
  defaultBackgroundColor?: boolean
  noBlueBar?: boolean
  noPositionFixed?: boolean
  isMobile?: boolean
  addPaddingXtraBar?: string
}

class Toolbar extends React.Component<IProps> {
  render() {
    return (
      <>
        <div
          className="toolbarContainer"
          style={{
            boxShadow: this.props.scrollBackgroundColor
              ? '0px 3px 1px -2px rgba(0, 0, 0, 0.2)'
              : undefined,
            position: this.props.noPositionFixed ? undefined : 'fixed',
          }}
        >
          <div
            className="toolbarUpperContainer"
            style={{
              backgroundColor: this.props.noBlueBar ? colors.mbg : '#0054b2',
            }}
          >
            <div className="toolbarUpperInner">
              {this.props.noBackButton ? (
                <div className="noBack" />
              ) : (
                <BackButton isWhiteBG={this.props.noBlueBar} />
              )}
              <Typography
                variant="h6"
                style={{
                  color: this.props.noBlueBar
                    ? colors.defaultText
                    : colors.white,
                }}
              >
                {this.props.title}
              </Typography>
            </div>
          </div>
          {this.props.noXtraBar ? null : (
            <div
              className="toolbarLowerContainer"
              style={{
                height: this.props.isMobile
                  ? '100%'
                  : this.props.addPaddingXtraBar
                  ? undefined
                  : 48,
                backgroundColor: this.props.scrollBackgroundColor
                  ? this.props.defaultBackgroundColor
                    ? colors.mbg
                    : colors.bg
                  : undefined,
                padding: this.props.addPaddingXtraBar
                  ? this.props.addPaddingXtraBar
                  : undefined,
              }}
            >
              {this.props.children}
            </div>
          )}
        </div>
        {/* <div style={{ height: this.props.noXtraBar ? 50 : 100 }} /> */}
      </>
    )
  }
}

export default Toolbar

interface IPropsCompBar {
  scrollBackgroundColor?: boolean
  defaultBackgroundColor?: boolean
}

export class CompBar extends React.Component<IPropsCompBar> {
  render() {
    return (
      <>
        <div
          className="compBarContainer"
          style={{
            backgroundColor: this.props.scrollBackgroundColor
              ? this.props.defaultBackgroundColor
                ? colors.mbg
                : colors.bg
              : undefined,
            boxShadow: this.props.scrollBackgroundColor
              ? '0px 3px 1px -2px rgba(0, 0, 0, 0.2)'
              : undefined,
          }}
        >
          {this.props.children}
        </div>
      </>
    )
  }
}
