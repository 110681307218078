import { IUser } from '../account/user'

export enum ETaskType {
  recurring = 'recurring',
  dynamic = 'dynamic',
  once = 'once',
}

export type TTaskType = ETaskType.recurring | ETaskType.dynamic | ETaskType.once

export enum ETaskMetric {
  date = 'date',
  engineHours = 'engineHours',
  time = 'time',
  odometer = 'odometer',
}

export type TTaskMetric =
  | ETaskMetric.date
  | ETaskMetric.engineHours
  | ETaskMetric.time
  | ETaskMetric.odometer

export interface ITask {
  type: TTaskType
  metric: TTaskMetric
  taskName: string
  taskId: string
  value: any
  users: IUser[]
  assets: IAssetTasks[]
}

export interface IAssetTasks {
  id: string
  name: string
  scheduleRatio?: number
  due?: any
}

export interface IAssignAsset {
  id: string
  name: string
  imageUrl: string
  current: any
  archived: boolean
}
