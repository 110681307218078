import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import Button from '../../buttons/Button'
import DatePicker from '../../datePickers/DatePicker'
import TextField from '../../textBoxs/TextField'
import Select, { ISelectValues } from '../../ux/Select'
import { backend_api, headersAPI } from '../../../constants/api'
import { IClient } from '../../../types/clients'
import Skeleton from '@mui/material/Skeleton'
import NumberField from '../../textBoxs/NumberField'
import CheckContainer from '../../ux/CheckContainer'
// import LinearProgressBar from '../../metricUX/LinearProgressBar'
import FormLabel from '@mui/material/FormLabel'
import { IEquipTask } from '../../../types/equipment/equipmentProfile'
import { globalThreshold } from '../../../pages/service/TasksPage'
import ScheduleRatio from '../../serviceComponents/ScheduleRatio'

const MaintenanceCheckBox = (
  scheduleRatio: number,
  title: string,
  id: string
) => (
  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
    <CheckContainer label={title} color="primary" ellipsisWidth={200} />
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 25,
        width: 200,
      }}
    >
      <ScheduleRatio
        progress={scheduleRatio}
        threshold={globalThreshold}
        equipId={id}
      />
    </div>
  </div>
)

const styles = () =>
  createStyles({
    TextField: {
      width: 250,
      marginTop: 14,
      marginBottom: 0,
    },
    LargeTextArea: {
      width: '100%',
      marginTop: 14,
      marginBottom: 0,
    },
    DialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    FormLabel: {
      margin: '14px 0 0 0',
    },
  })

interface IProps extends WithStyles<typeof styles> {
  dialogOpen: boolean
  onClose: () => void
  isMobile: boolean
  assetName: string
  assetId: string
  tasks: IEquipTask[]
}

interface IState {
  CheckInDate: Date | null
  notes: string
  clients: IClient[]
  clientSelectValue: ISelectValues[]
  loadingClients: boolean
  selectedClient: string
  engineHour: number | null
}

class CreateServiceTicket extends React.Component<IProps, IState> {
  state: IState = {
    CheckInDate: new Date(),
    notes: '',
    clients: [],
    clientSelectValue: [],
    loadingClients: true,
    selectedClient: '',
    engineHour: 0,
  }

  handleClose = () => this.props.onClose()

  handleSubmit = () => {
    this.handleClose()
  }

  loadClients() {
    fetch(backend_api + '/clients/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response: IClient[]) => {
        this.setState({
          clients: response,
          clientSelectValue: response.map(
            (client) =>
              ({ value: client.id, label: client.name } as ISelectValues)
          ),
          loadingClients: false,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.loadClients()
  }

  render() {
    const classes = this.props.classes
    return (
      <Dialog open={this.props.dialogOpen} onClose={this.handleClose} fullWidth>
        <DialogTitle>
          New Service Ticket for: {this.props.assetName}
        </DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <DatePicker
            selectedDate={this.state.CheckInDate}
            onChange={(newDate) => this.setState({ CheckInDate: newDate })}
            showToolbar
            className={classes.TextField}
            label="Check In Date"
            required
          />
          <TextField
            label="Issue"
            helperText="i.e. Broken belt, battery not charging, etc."
            required
            multiline
            minRows={4}
            className={this.props.classes.LargeTextArea}
            value={this.state.notes}
            onChange={(event) => this.setState({ notes: event.target.value })}
          />
          {this.state.loadingClients ? (
            <Skeleton animation="wave" className={classes.TextField} />
          ) : (
            <Select
              label="Client"
              selectedValue={this.state.selectedClient}
              values={this.state.clientSelectValue}
              onChange={(name: string) =>
                this.setState({
                  selectedClient: name,
                })
              }
              className={classes.TextField}
            />
          )}
          <FormLabel className={classes.FormLabel}>
            Planned Maintenance
          </FormLabel>
          {/* percentage needs to be better calculated here */}
          {this.props.tasks.map((task) =>
            MaintenanceCheckBox(
              parseFloat(task.scheduleRatio),
              task.task,
              this.props.assetId
            )
          )}
          <NumberField
            value={this.state.engineHour}
            onChange={(numVal) => this.setState({ engineHour: numVal })}
            label="Engine Hours"
            endAdornment="hours"
            className={classes.TextField}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button color="primary" onClick={this.handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(CreateServiceTicket)
