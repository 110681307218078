import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from "@sentry/tracing"
import Amplify from 'aws-amplify'
import awsExports from './aws-exports'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { muiTheme } from './components/ux/roviTheme'
import CssBaseline from '@mui/material/CssBaseline'
import { findEnv, isProduction } from './utility/hostCheck'

Amplify.configure(awsExports)

// const { REACT_APP_SENTRY_DSN } = process.env;

window.location.hostname !== 'localhost' &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || 'https://b31771d7bd1c403bb7e27037ec0c5090@sentry.rovitracker.cloud/30',
    environment: process.env.REACT_APP_ENV_NAME,
    tracesSampleRate: process.env.REACT_APP_ENV_NAME === 'production' ? 0.25 : 1.0,
    release: 'rovitracker-web@' + process.env.REACT_APP_VERSION,
    integrations: [new BrowserTracing()],
  })

ReactDOM.render(
  <ThemeProvider theme={createTheme(muiTheme)}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://github.com/facebook/create-react-app/blob/master/packages/cra-template/template/README.md
serviceWorker.unregister()
