import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const MaintenanceIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="m 17.589546,3.7501663 c -2.214442,0 -4.009766,1.7953244 -4.009766,4.0097656 0,2.2144414 1.790869,4.0117181 4.009766,4.0117181 2.214441,0 4.009765,-1.7972767 4.009765,-4.0117181 0,-2.2144412 -1.795324,-4.0097656 -4.009765,-4.0097656 z m 0,1.0363964 c 1.644792,0 2.975277,1.3286216 2.975277,2.9734142 0,1.6447924 -1.330485,2.9752771 -2.975277,2.9752771 -1.644793,0 -2.975278,-1.3304847 -2.975278,-2.9752771 0,-1.6447926 1.330485,-2.9734142 2.975278,-2.9734142 z m -0.406005,0.7639229 4.58e-4,2.4941175 1.970589,1.233681 0.413908,-0.6762112 -1.561492,-0.9689721 -4.58e-4,-2.0826152 z M 7.2917286,3.6578763 c -0.752658,-0.0112 -1.505215,0.1435848 -2.1933594,0.4589843 L 8.3854786,7.40397 6.0925098,9.6988913 2.7272755,6.4098294 c -0.9175259,1.8350518 -0.5350691,4.1289926 0.9941406,5.6582026 1.4527493,1.45275 3.5187525,1.834628 5.2773437,1.146484 l 6.9570312,6.958985 c 0.305841,0.305842 0.764471,0.305842 1.070313,0 l 1.759766,-1.759766 c 0.382301,-0.305842 0.382013,-0.84093 0.07617,-1.070312 L 11.903057,10.386391 C 12.591201,8.6278005 12.209323,6.5637506 10.756573,5.1110013 9.800816,4.1552451 8.5461585,3.6765434 7.2917286,3.6578763 Z" />,
  'Maintenance'
)

export default MaintenanceIcon
