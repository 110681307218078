import { headersAPI, api } from '../../constants/api'
import { ILatLng } from '../../types/maptrac/LatLng'
import {
  defaultMessageParameters,
  checkExceptionError,
  createResponseMessage,
} from '../../types/apiCalls'

/**
 * Confluence Documentation
 * https://rovitracker.atlassian.net/l/c/9U1KbXuW
 */

interface ICoords {
  latitude: {
    value: number
  }
  longitude: {
    value: number
  }
}

interface IProps {
  name: string
  geofenceId: string
  latLng: ILatLng[]
  shape: string
}

// tenant_id: UUID,
//   legacy_account_id: Option[Int],
//   legacy_branch_id: Option[Int],
//   name: String,
//   branch_id: Option[UUID],
//   is_hidden: Boolean,
//   shape: String,
//   coordinates: GeofenceCoordinates

// tenant_id: Option[UUID],
//   geofence_id: UUID,
//   name: Option[String],
//   coordinates: Option[GeofenceCoordinates]

const postUpdateGeofence = (props: IProps) => {
  const APICallType = 'Geofence Updated Post' // Let Sentry Know what this API Call is
  const DefaultMessages = createResponseMessage(
    'Geofence Successfully Updated',
    'Geofence Failed to Update, Please Try Again Later'
  )
  let latLngPlus = props.latLng
  latLngPlus.push(props.latLng[0]) // Post Gis requires the first point to be on the last point
  const coords: ICoords[] = latLngPlus.map((ll) => ({
      latitude: {
        value: ll.lat,
      },
      longitude: {
        value: ll.lng,
      },
    })),
    body = JSON.stringify({
      tenant_id: '477fb8dd-5bf7-4622-a544-1e46542ff6d9',
      geofence_id: props.geofenceId,
      name: props.name,
      coordinates: {
        coords: coords,
      },
    })
  return fetch(api + '/geofence/update', {
    method: 'POST',
    headers: headersAPI,
    body: body,
  })
    .then((response) => response.json())
    .then((response) =>
      checkExceptionError(response, APICallType, DefaultMessages)
    )
    .catch((err) =>
      defaultMessageParameters('error', APICallType, err, DefaultMessages)
    )
}

export default postUpdateGeofence
