enum PressureType {}

type Pressure = number & PressureType

namespace Pressure {
  const PSI_PER_PASCAL = 0.000145038

  export const ofPascal = (v: number): Pressure => v as Pressure
  export const ofPsi = (v: number) => Pressure.ofPascal(v * PSI_PER_PASCAL)

  export const toPascal = (v: Pressure) => v as number
  export const toPsi = (v: Pressure) => Pressure.toPascal(v) / PSI_PER_PASCAL

  export enum Unit {
    Pascal = 'Pa',
    PSI = 'psi'
  }

  const conversionLookup: { [key in Unit]: (val: Pressure) => number } = {
    [Unit.Pascal]: toPascal,
    [Unit.PSI]: toPsi
  }

  export const format = (val: Pressure, unit: Unit = Unit.PSI) =>
    `${Math.round(conversionLookup[unit](val))}${unit}`
}

export default Pressure
