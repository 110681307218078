import React from 'react'
import ReactHelmet from '../../components/ReactHelmet'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Button from '../../components/buttons/Button'
import Carousel from '../../components/ux/Carousel'
import DeviceTypeCard from '../../components/accountComponents/DeviceTypeCard'
import { backend_api, headersAPI } from '../../constants/api'
import { IDeviceType, IDevices } from '../../types/account/devices'
import { colors } from '../../components/ux/roviTheme'
import LoadingOverlay from '../../components/LoadingOverlay'
import PurchaseDeviceDialog from '../../components/accountComponents/dialogs/PurchaseDeviceDialog'

// id = dt.id,
// label = dt.label,
// dimensions = dt.dimensions,
// description = dt.usage,
// shortDescription = dt.shortDescription,
// price = dt.price,
// showOnMarket = dt.showOnMarket,
// imageUrl = s"https://public.rovitracker.com/app/images/devices/${dt.id.text}.png",
// smallImageUrl = s"https://public.rovitracker.com/app/images/devices/${dt.id.text}-small.png",
// isGateway = dt.isGateway,
// isTag = dt.isTag

interface IProps {
  isMobile: boolean
}

interface IState {
  deviceTypesLoading: boolean
  devicesLoading: boolean
  deviceTypes: IDeviceType[]
  allDevices: IDevices[]
  purchaseDeviceId: string
}

class DevicesPage extends React.Component<IProps, IState> {
  state: IState = {
    deviceTypesLoading: true,
    devicesLoading: true,
    deviceTypes: [],
    allDevices: [],
    purchaseDeviceId: '',
  }

  grabDeviceTypes() {
    fetch(backend_api + '/deviceTypes/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          deviceTypes: response,
          deviceTypesLoading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  grabAllDevicesOnAccount() {
    fetch(backend_api + '/devices/all/123', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          allDevices: response,
          devicesLoading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabDeviceTypes()
    this.grabAllDevicesOnAccount()
  }

  componentWillUnmount() {
    /** This is here to essentially unmount the state, fixing the async issue */
    this.setState = () => {
      return
    }
  }

  render() {
    return (
      <>
        <ReactHelmet title="Devices" />
        <LoadingOverlay
          loading={this.state.deviceTypesLoading && this.state.devicesLoading}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: this.props.isMobile ? '48px 0' : undefined,
          }}
        >
          <div>
            <Typography
              variant="h6"
              style={{ paddingLeft: 20, paddingTop: 10 }}
            >
              Available Devices
            </Typography>

            {this.state.deviceTypesLoading ? null : (
              <Carousel scrollBy={290}>
                {this.state.deviceTypes.map((device, key) => (
                  <div key={key}>
                    {device.showOnMarket ? (
                      <DeviceTypeCard
                        deviceType={device}
                        onClickPurchase={() =>
                          this.setState({ purchaseDeviceId: device.id })
                        }
                      />
                    ) : null}
                    {this.state.purchaseDeviceId === device.id ? (
                      <PurchaseDeviceDialog
                        dialogOpen={this.state.purchaseDeviceId === device.id}
                        onClose={() => this.setState({ purchaseDeviceId: '' })}
                        deviceInfo={device}
                      />
                    ) : null}
                  </div>
                ))}
                <div style={{ width: 1, flexShrink: 0 }} />
              </Carousel>
            )}
          </div>

          <div style={{ padding: this.props.isMobile ? undefined : 10 }}>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ padding: 10 }}
            >
              Purchased Devices
            </Typography>
            {this.state.devicesLoading ? null : (
              <Paper style={{ width: '100%', overflowX: 'auto' }}>
                <Table style={{ minWidth: 900 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>
                        Device ID ({this.state.allDevices.length})
                      </TableCell>
                      <TableCell>Device Type</TableCell>
                      <TableCell>Branch</TableCell>
                      <TableCell>Asset</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.allDevices.map((device, key) => (
                      <TableRow key={key}>
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                width: 45,
                                height: 45,
                                overflow: 'hidden',
                                borderRadius: '50%',
                                margin: '5px 0',
                              }}
                            >
                              <a href="/">
                                <img
                                  alt={'device Img' + key}
                                  src={device.imageUrl}
                                  style={{ height: 45 }}
                                />
                                {/* <img
                                alt={'device Img' + key}
                                src="https://via.placeholder.com/150"
                                style={{ height: 45 }}
                              /> */}
                              </a>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>{device.esn}</TableCell>
                        <TableCell>{device.deviceType}</TableCell>
                        <TableCell>{device.branch}</TableCell>
                        <TableCell>
                          <div>
                            <a href="/">{device.assetName}</a>
                            <br />
                            <span
                              style={{
                                color: colors.textGrey,
                              }}
                            >
                              {device.assetMake} / {device.assetModel}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            onClick={() =>
                              this.setState({ purchaseDeviceId: device.id })
                            }
                          >
                            Purchase More
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default DevicesPage
