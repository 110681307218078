import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { darkMode, colors } from '../ux/roviTheme'

interface IProps {
  style?: React.CSSProperties
  href?: string
  title: string
  description?: string
  small?: boolean
  noHighlight?: boolean
  minWidth?: number
  onClick?: () => void
}

class ReportCard extends React.Component<IProps> {
  state = {
    hovered: false,
  }

  render() {
    const { style, href, title, description, onClick } = this.props

    return (
      <Paper
        style={{
          display: 'flex',
          margin: '8px 16px',
          minWidth: this.props.minWidth ? this.props.minWidth : undefined,
          maxWidth: 600,
          cursor: 'pointer',
          backgroundColor: this.state.hovered
            ? darkMode
              ? '#7f929a'
              : '#ddf5ff'
            : colors.cbg,
          ...style,
          overflow: 'hidden',
          position: 'relative',
          userSelect: this.props.noHighlight ? 'none' : undefined,
        }}
        onClick={() => {
          if (href) {
            window.location.href = href
          }
          if (onClick) {
            onClick()
          }
        }}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        elevation={this.state.hovered ? 2 : 1}
      >
        {/* Orange Bar */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            backgroundColor: colors.primaryOrange2,
            height: '100%',
            width: 6,
          }}
        />
        <div style={{ padding: this.props.small ? '0 10px' : 16 }}>
          <Typography
            style={{ margin: '8px 0', fontSize: this.props.small ? 14 : 20 }}
          >
            {title}
          </Typography>
          {this.props.small ? null : (
            <Typography style={{ margin: '14px 0', fontSize: 14 }}>
              {description}
            </Typography>
          )}
        </div>
      </Paper>
    )
  }
}

export default ReportCard
