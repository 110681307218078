import React from 'react'
import IconButton from './IconButton'
import Popover from '@mui/material/Popover'
import { colors } from '../ux/roviTheme'

interface IProps {
  info?: Array<{
    title: string
    text: string
  }>
  style?: React.CSSProperties
}

class InfoButton extends React.Component<IProps> {
  state = {
    anchorEl: null,
  }

  update = {
    Open: (ev: React.MouseEvent<Element, MouseEvent>) =>
      this.setState({ anchorEl: ev.currentTarget }),
    Close: () => this.setState({ anchorEl: null }),
  }

  render() {
    return (
      <>
        <IconButton
          icon="info"
          variant="row"
          onClick={this.update.Open}
          style={{ color: colors.primaryBlue }}
        />

        <Popover
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          onClose={this.update.Close}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: 10, maxWidth: 300 }}>
            {this.props.info &&
              this.props.info.map((val, i) => (
                <div key={i} style={{ margin: '8px 0' }}>
                  <span style={{ fontWeight: 'bold' }}>{val.title}:</span>{' '}
                  {val.text}
                </div>
              ))}
            {this.props.children}
          </div>
        </Popover>
      </>
    )
  }
}

export default InfoButton
