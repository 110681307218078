import { EquipmentDailyRuntimeComp } from '../../pages/reports/EquipmentDailyRuntime'
import { EquipmentRuntimeBySiteComp } from '../../pages/reports/EquipmentRuntimeBySite'
import { FlaggedSpeedingComp } from '../../pages/reports/FlaggedSpeeding'
import { DeviceDisconnectComp } from '../../pages/reports/DeviceDisconnect'
import { RepairsByCategoryComp } from '../../pages/reports/RepairsByCategory'
import { AssetsByGeofenceComp } from '../../pages/reports/AssetsByGeofence'
import { ActiveClientsComp } from '../../pages/reports/ActiveClients'
import { EquipmentMileageByStateComp } from '../../pages/reports/EquipmentMileageByState'
import { VehicleFuelComp } from '../../pages/reports/VehicleFuel'
import { FluidUsageOvertimeComp } from '../../pages/reports/FluidUsageOverTimeReport'
import { FluidUsageOverTimeTableComp } from '../../pages/reports/TankLevelReport'
import { ERoutes } from '../routes/CheckRoutes'
import { IStandardIcons } from '../ux/standardIcons'

const styles: Record<string, React.CSSProperties> = {
  mileageByStateStyle: {
    backgroundImage:
      'url(https://s3.amazonaws.com/assets.rovitracker.cloud/images/reportsPage/usa_states2.svg)',
    backgroundPosition: 'right -114px top -107px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 733,
  },
  rentalsStyle: {
    backgroundImage:
      'url(https://s3.amazonaws.com/assets.rovitracker.cloud/images/reportsPage/categories2.svg)',
    backgroundPosition: 'right -36px top -19px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 366,
  },
}

export const otherReports: IReportLink[] = [
  {
    style: styles.rentalsStyle,
    href: ERoutes.ActiveClients,
    title: 'Active Clients',
    description: 'Breakdown of equipment rentals by client.',
    reportPage: ActiveClientsComp,
  },
  {
    style: styles.mileageByStateStyle,
    href: ERoutes.EquipmentMileageByState,
    title: 'Equipment Mileage by US State',
    description: 'Breakdown of equipment mileage per US State.',
    reportPage: EquipmentMileageByStateComp,
  },
  {
    href: ERoutes.VehicleFuel,
    title: 'Fuel Report',
    description: 'Breakdown of vehicle fuel usage.',
    reportPage: VehicleFuelComp,
  },
  {
    href: '/reports/advanced',
    title: 'Advanced Reporting',
    description: 'Customizable advanced reports and dashboards.',
  },
  {
    href: ERoutes.FluidUsageOverTime,
    title: 'Fluid Usage Over Time Report',
    description: 'Shows the fluid level over time in percentage',
    reportPage: FluidUsageOvertimeComp,
  },
  {
    href: ERoutes.FluidUsageOverTimeTable,
    title: 'Tank Level Report',
    description: 'Shows the fluid level over time in percentage on a table.',
    reportPage: FluidUsageOverTimeTableComp,
  },
]

const runtimeReports: IReportLink[] = [
  {
    href: ERoutes.EquipmentDailyRuntime,
    title: 'Equipment Daily Runtime',
    description: 'Breakdown of equipment engine runtime per day.',
    reportPage: EquipmentDailyRuntimeComp,
  },
  {
    href: ERoutes.EquipmentRuntimeBySite,
    title: 'Equipment Runtime By Site',
    description:
      'Breakdown of equipment engine runtime per day, Sorted by Site.',
    reportPage: EquipmentRuntimeBySiteComp,
  },
]

const eventReports: IReportLink[] = [
  {
    href: ERoutes.FlaggedSpeeding,
    title: 'Flagged Speeding Report',
    description: 'Breakdown of all equipment flagged for speeding.',
    reportPage: FlaggedSpeedingComp,
  },
  {
    href: ERoutes.DeviceDisconnect,
    title: 'Device Disconnect Report',
    description: 'Breakdown of all equipment flagged for being disconnected.',
    reportPage: DeviceDisconnectComp,
  },
]

const maintenanceReports: IReportLink[] = [
  {
    href: ERoutes.RepairsByCategory,
    title: 'Repairs by Category',
    description: 'Breakdown of Repairs by category per day.',
    reportPage: RepairsByCategoryComp,
  },
]

const geofenceReports: IReportLink[] = [
  {
    href: ERoutes.AssetsByGeofence,
    title: 'Assets by Geofence',
    description: 'Breakdown of equipment in a geofence.',
    reportPage: AssetsByGeofenceComp,
  },
]

export interface IReportLink {
  style?: React.CSSProperties
  href?: string
  title: string
  value?: string // used for asset report
  description?: string
  reportPage?: (width: number, height: number, isMobile: boolean) => JSX.Element
}

export interface IReportCategorys {
  title: string
  icon?: IStandardIcons
  reports: IReportLink[]
}

export const reports: IReportCategorys[] = [
  {
    title: 'Runtime Reports',
    icon: 'rpm',
    reports: runtimeReports,
  },
  {
    title: 'Event Reports',
    icon: 'events',
    reports: eventReports,
  },
  {
    title: 'Service Reports',
    icon: 'service',
    reports: maintenanceReports,
  },
  {
    title: 'Geofence Reports',
    icon: 'geofence',
    reports: geofenceReports,
  },
  {
    title: 'Other Reports',
    reports: otherReports,
  },
]
