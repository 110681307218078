import React from 'react'
import FabButton from '../buttons/FabButton'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { TextField, Paper } from '@mui/material'
import { WithStyles, createStyles } from '@mui/styles'
import Button from '../buttons/Button'
import { withStyles } from '@mui/styles'
import { colors } from '../ux/roviTheme'
import GeofenceDrawingManager, {
  TRadiusType,
  ERadiusType,
} from './GeofenceDrawingManager'
import InfoHover from '../buttons/InfoHover'
import postCreateGeofence from '../../apiCalls/postCalls/postCreateGeofence'
import { LoadingIcon } from '../../components/LoadingOverlay'
import SnackBar from '../../components/ux/SnackBar'
import { TErrorTypes } from '../../types/apiCalls'
import { GEOFENCE_RULES } from './EditGeofence'

/**
 * Confluence Documentation
 * https://rovitracker.atlassian.net/l/c/9U1KbXuW
 */

interface ILatLng {
  lat: number
  lng: number
}

const styles = () =>
  createStyles({
    positionContainer: {
      overflow: 'visible',
      position: 'absolute',
      top: 40,
      right: 5,
      margin: 10,
      zIndex: 10,
    },
    iconStyle: {
      fill: colors.defaultText,
    },
    positionDialogContainer: {
      position: 'absolute',
      bottom: 24,
      right: '50%',
      zIndex: 20,
      transform: 'translate(50%)',
      display: 'flex',
      flexDirection: 'column',
    },
    textInput: {
      width: 200,
      marginTop: 10,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  isMobile: boolean
  onReload: () => void
}

interface IState {
  geofenceDialogIsOpen: boolean
  geofenceName: string
  geofenceError: string
  geofenceRadius?: number
  geofencePoly: { region: { shape: TRadiusType; points: ILatLng[] } }
  isNotCreated: boolean
  saving: boolean
  snackBarMessage: string
  snackBarError: TErrorTypes
}

class GeofenceButton extends React.Component<IProps, IState> {
  state: IState = {
    geofenceDialogIsOpen: false,
    geofenceName: '',
    geofenceError: '',
    geofenceRadius: undefined,
    geofencePoly: { region: { shape: ERadiusType.Polygon, points: [] } },
    isNotCreated: true,
    saving: false,
    snackBarMessage: '',
    snackBarError: 'error',
  }

  onSave = () => {
    // Here is where the submit button happens
    this.setState({ saving: true })
    postCreateGeofence({
      name: this.state.geofenceName,
      latLng: this.state.geofencePoly.region.points,
      shape: this.state.geofencePoly.region.shape,
    }).then((response) => {
      if (response.type === 'success') {
        setTimeout(() => this.props.onReload(), 2000)
      }
      this.setState({
        saving: false,
        snackBarError: response.type,
        snackBarMessage: response.snackBarMessage,
        geofenceDialogIsOpen: response.type === 'error' ? true : false,
      })
    })
  }

  /**
   * On cancel this should reset the state to the original state, prevent bugs such as users cant create multiple geofences in a row
   */
  onCancel() {
    this.setState({
      geofenceDialogIsOpen: false,
      geofenceName: '',
      geofenceError: '',
      geofenceRadius: undefined,
      geofencePoly: { region: { shape: ERadiusType.Polygon, points: [] } },
      isNotCreated: true,
    })
  }

  render() {
    return this.props.isMobile ? null : (
      <>
        <div className={this.props.classes.positionContainer}>
          <FabButton
            icon="addGeofence"
            whiteBlackVersion
            noDefaultPostion
            size="small"
            onClick={() => this.setState({ geofenceDialogIsOpen: true })}
          />
        </div>

        {this.state.geofenceDialogIsOpen && (
          <Paper className={this.props.classes.positionDialogContainer}>
            <DialogContent>
              <DialogContentText>
                Click on the map to draw a geofence
                <InfoHover text="A geofence sets a virtual border" />
              </DialogContentText>

              <TextField
                label="Geofence Name"
                value={this.state.geofenceName}
                onChange={(ev) =>
                  this.setState({
                    geofenceName: ev.target.value,
                    geofenceError: ev.target.value
                      ? ''
                      : GEOFENCE_RULES.NO_NAME,
                  })
                }
                error={!!this.state.geofenceError}
                helperText={this.state.geofenceError}
                className={this.props.classes.textInput}
                required
                autoFocus
                variant="standard"
                style={{ width: 256 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.onCancel()}>Cancel</Button>
              <Button
                color="primary"
                onClick={() => this.onSave()}
                disabled={this.state.isNotCreated || !this.state.geofenceName}
              >
                {this.state.saving ? <LoadingIcon loading /> : 'Save'}
              </Button>
            </DialogActions>

            {this.state.geofenceDialogIsOpen && (
              <GeofenceDrawingManager
                onDrawEnd={(points, type, radius) =>
                  this.setState({
                    isNotCreated: false,
                    geofenceRadius: radius ? radius : undefined,
                    geofencePoly: {
                      region: {
                        shape: type ? type : ERadiusType.Polygon,
                        points: points,
                      },
                    },
                  })
                }
                editable={this.state.isNotCreated}
              />
            )}
          </Paper>
        )}
        <SnackBar
          message={this.state.snackBarMessage}
          onClose={() => this.setState({ snackBarMessage: '' })}
          variant={this.state.snackBarError}
        />
      </>
    )
  }
}

export default withStyles(styles)(GeofenceButton)
