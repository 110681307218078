import React from 'react'
import '../../styles/ux/UserAvatar.scss'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { colors } from '../ux/roviTheme'
import { Theme } from '@mui/material/styles/createTheme'
import { userProfileURL } from '../../constants/urlBuilder'
import { Auth } from 'aws-amplify'
import { ICurrentUser } from '../../types/amplifyTypes/currentUser'

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      color: 'white',
      background: theme.palette.primary.light,
      height: 24,
      width: 24,
      fontSize: 12,
    },
    disabled: {
      background: theme.palette.grey[400],
    },
    small: {
      height: 18,
      width: 18,
      fontSize: 10,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  userId: string
  firstName: string
  lastName: string
  disabled?: boolean
  className?: string
  variant?: 'small' | 'medium'
}

interface IState {
  currentUser?: ICurrentUser
}

class UserAvatar extends React.Component<IProps, IState> {
  state: IState = {
    currentUser: undefined,
  }

  comp = (
    <Tooltip
      title={this.props.firstName + ' ' + this.props.lastName}
      placement={this.props.variant === 'small' ? 'right' : undefined}
    >
      <Avatar
        className={
          this.props.variant === 'small' ? 'smallAvatar' : 'mediumAvatar'
        }
        style={{
          backgroundColor: this.props.disabled
            ? colors.disabled
            : colors.primaryBlue,
          color: '#fff',
        }}
      >
        {this.props.firstName[0] + this.props.lastName[0]}
      </Avatar>
    </Tooltip>
  )

  grabCurrentUserUUID(currentUserId: string | undefined) {
    return currentUserId?.split(':')?.[1]
  }

  componentDidMount() {
    Auth.currentUserInfo().then((user) => this.setState({ currentUser: user }))
  }

  render() {
    return this.props.disabled ? (
      this.comp
    ) : (
      <a
        href={userProfileURL(
          this.props.userId,
          this.state.currentUser
            ? this.grabCurrentUserUUID(this.state.currentUser?.id)
            : ''
        )}
        onClick={(ev) => ev.stopPropagation()}
      >
        {this.comp}
      </a>
    )
  }
}

export default withStyles(styles)(UserAvatar)
