import { IClientSites, ISite } from '../../../types/maptrac/Sites'

/**
 * Filter out Sites
 */
export function filterSiteMarkers(sites: ISite[], searchString: string) {
  return sites.filter((s) =>
    s.siteName.toLowerCase().includes(searchString.toLowerCase())
  )
}

export function filterClientSitesDrawer(
  clientSites: IClientSites[],
  searchString: string
) {
  // let fakeSites = [] as IClientSites[]

  return clientSites.map((c) => {
    return {
      clientName: c.clientName,
      clientId: c.clientId,
      clientSites: c.clientSites.filter((s) =>
        s.siteName.toLowerCase().includes(searchString.toLowerCase())
      ),
    }
  })
  // clientSites.forEach((c) =>
  //   fakeSites.push(
  // c.clientSites.filter((s) =>
  //   s.siteName.toLowerCase().includes(searchString.toLowerCase())
  // )
  //   )
  // )

  // return clientSites.filter((cl) =>
  //   cl.clientSites.find((a) =>
  //     a.siteName.toLowerCase().includes(searchString.toLowerCase())
  //   )
  // )
}
