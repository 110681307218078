abstract class DurationType {
  public type = 'Duration'
}
type Duration = DurationType

namespace Duration {
  const MILLIS_PER_SEC = 1000
  const SEC_PER_MIN = 60
  const MIN_PER_H = 60
  const H_PER_DAY = 24

  export const ofMilliseconds = (v: number): Duration => v as any as Duration
  export const ofSeconds = (v: number) =>
    Duration.ofMilliseconds(v * MILLIS_PER_SEC)
  export const ofMinutes = (v: number) => Duration.ofSeconds(v * SEC_PER_MIN)
  export const ofHours = (v: number) => Duration.ofMinutes(v * MIN_PER_H)
  export const ofDays = (v: number) => Duration.ofHours(v * H_PER_DAY)

  export const toMilliseconds = (v: Duration) => v as any as number
  export const toSeconds = (v: Duration) =>
    Duration.toMilliseconds(v) / MILLIS_PER_SEC
  export const toMinutes = (v: Duration) => Duration.toSeconds(v) / SEC_PER_MIN
  export const toHours = (v: Duration) => Duration.toMinutes(v) / MIN_PER_H
  export const toDays = (v: Duration) => Duration.toHours(v) / H_PER_DAY

  export enum Unit {
    Milliseconds = 'ms',
    Seconds = 'sec',
    Minutes = 'min',
    Hours = 'h',
    Days = 'day',
  }

  const conversionLookupTo: { [key in Unit]: (val: Duration) => number } = {
    [Unit.Milliseconds]: toMilliseconds,
    [Unit.Seconds]: toSeconds,
    [Unit.Minutes]: toMinutes,
    [Unit.Hours]: toHours,
    [Unit.Days]: toDays,
  }

  const conversionLookupFrom: { [key in Unit]: (val: number) => Duration } = {
    [Unit.Milliseconds]: ofMilliseconds,
    [Unit.Seconds]: ofSeconds,
    [Unit.Minutes]: ofMinutes,
    [Unit.Hours]: ofHours,
    [Unit.Days]: ofDays,
  }

  export const format = (val: Duration, unit: Unit = Unit.Hours) =>
    `${Number(conversionLookupTo[unit](val)).toFixed(1)}${unit}`

  export const convertToUnit = (val: Duration, unit: Unit) =>
    conversionLookupTo[unit](val)
  export const convertFromUnit = (val: number, unit: Unit) =>
    conversionLookupFrom[unit](val)
}

export default Duration
