import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '../../buttons/Button'

interface IProps {
  open: boolean
  onClose: () => void
  onDelete: () => void
  ticketId: string
}

const DeleteTicketDialog = (props: IProps) => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DialogTitle>Delete Ticket</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete this Ticket?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose}>Cancel</Button>
      <Button onClick={props.onDelete} color="primary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

export default DeleteTicketDialog
