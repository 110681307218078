import React from 'react'
import '../styles/pages/Inventory.scss'
import ReactHelmet from '../components/ReactHelmet'
import Toolbar from '../components/toolbar/Toolbar'
import Button from '../components/buttons/Button'
import Icon from '../components/Icon'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '../components/buttons/IconButton'

const notificationsList = [
  {
    flag: 'Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
  {
    flag: 'Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
  {
    flag: 'Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
  {
    flag: 'Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
  {
    flag: 'Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
]
const notificationsListRead = [
  {
    flag: 'Read Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
  {
    flag: 'Read Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
  {
    flag: 'Read Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
  {
    flag: 'Read Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
  {
    flag: 'Read Flag "Enter Rovitracker" has been removed from asset "E350(Nathaniel Young)"',
    howLongAgo: '3 months ago',
  },
]

interface IState {
  isRead: boolean
}

interface IProps {}

class Notifications extends React.Component<IProps, IState> {
  state: IState = {
    isRead: false,
  }

  render() {
    return (
      <div>
        <ReactHelmet title="Service" />
        <Toolbar title="Notifications" scrollBackgroundColor>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <div>
                <Button id="notificationsMarkAsReadButton">
                  <Icon icon="check" variant="button" />
                  Mark all as read
                </Button>
              </div>
            </div>
            <div style={{ flexShrink: 0, display: 'flex' }}>
              {/* <ButtonGroup
                disableElevation
                variant="contained"
                color="primary"
                size="small"
              >
                <Tooltip title="Show Unread">
                  <Button
                    onClick={() => this.setState({ isRead: false })}
                    disabled={!this.state.isRead}
                  >
                    Unread
                  </Button>
                </Tooltip>
                <Tooltip title="Show Read">
                  <Button
                    onClick={() => this.setState({ isRead: true })}
                    disabled={this.state.isRead}
                  >
                    Read
                  </Button>
                </Tooltip>
              </ButtonGroup> */}
            </div>
          </div>
        </Toolbar>
        <Paper style={{ width: '100%', overflowX: 'auto', paddingTop: 96 }}>
          <Table style={{ minWidth: 900 }} size="small">
            {!this.state.isRead ? (
              <TableBody>
                {notificationsList.map((notification, key) => (
                  <TableRow key={key}>
                    <TableCell>{notification.flag}</TableCell>
                    <TableCell align="right">
                      {notification.howLongAgo}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        icon="check"
                        variant="appbar"
                        tooltip="Mark as Read"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {notificationsListRead.map((notification, key) => (
                  <TableRow key={key}>
                    <TableCell>{notification.flag}</TableCell>
                    <TableCell align="right">
                      {notification.howLongAgo}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Paper>
      </div>
    )
  }
}

export default Notifications
