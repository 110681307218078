import React from 'react'
import '../../styles/ux/Attribute.scss'
import Typography from '@mui/material/Typography'
import EllipsisText from './EllipsisText'
import { colors } from './roviTheme'

/**
 * Used for where we want red require stars, just to work best dont use normal @mui required.
 * @param label will append the red star after the label.
 */
export const requiredStar = (label?: any) => (
  <span>
    {label} <span className="requiredStarSpan">*</span>
  </span>
)

interface ICardItem {
  value?: any
  title?: string
  ellipsis?: boolean
}

/**
 * The Newest value layout for the asset profile page, this is to be used to show
 * basic metrics and values of everything that the user can see of this asset.s
 */
export const CardItem: React.FunctionComponent<ICardItem> = (props) => (
  <div className="cardItemBox">
    <EllipsisText width={props.ellipsis ? 100 : undefined}>
      <Typography>{props.value}</Typography>
    </EllipsisText>
    <EllipsisText width={props.ellipsis ? 100 : undefined}>
      <Typography variant="caption" color="textSecondary">
        {props.title}
      </Typography>
    </EllipsisText>
  </div>
)

interface IValBox {
  title: string
  value?: any
  width?: number
}

/**
 * Straight forward layout component.
 * @param title The title of the component, that will be rendered like the rest.
 * @param value pass any value to this to be generated the same as all the rest.
 * @param width This one correlates with the ElipsisText in pixels.
 */
export const ValueBox = (props: IValBox) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      alignItems: 'flex-start',
    }}
  >
    <div style={{ display: 'flex' }}>{props.value}</div>
    <Typography variant="caption" color="textSecondary">
      <EllipsisText
        text={props.title}
        width={props.width ? props.width : 100}
      />
    </Typography>
  </div>
)

interface IInputBox {
  indicatorOn: boolean
  label: string
}

/**
 * Needs information written on this component @TODO
 * @param props
 */
export const InputBox = (props: IInputBox) => (
  <div
    title={props.indicatorOn ? 'On' : 'Off'}
    style={{
      width: 30,
      padding: 2,
      background: props.indicatorOn ? colors.onGreen : colors.offGrey,
      borderRadius: 4,
      textAlign: 'center',
      fontSize: 10,
      fontWeight: 'bold',
      marginRight: 1,
    }}
  >
    {props.label}
  </div>
)
