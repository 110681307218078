import React from 'react'
import ReactHelmet from '../components/ReactHelmet'
import Typography from '@mui/material/Typography'
import Toolbar from '../components/toolbar/Toolbar'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '../components/buttons/Button'
import Icon from '../components/Icon'
import Fab from '@mui/material/Fab'
import WidthViewport from '../components/ux/WidthViewport'
import { ERoutes, PageTitle } from '../components/routes/CheckRoutes'
import { backend_api, headersAPI } from '../constants/api'
import { ICategory } from '../types/category'
import CategoryAvatar from '../components/ux/CategoryAvatar'
import AttributesRow from '../components/attributeComponents/AttributeRow'
import AddEditAttributeDialog from '../components/attributeComponents/dialogs/AddEditAttributeDialog'

const DefaultAttributeData = [
  { name: 'Name' },
  { name: 'Serial' },
  { name: 'Monitoring Device ID' },
  { name: 'Hour Meter' },
]

interface IAttribute {
  id: string
  name: string
}

interface IAttributeCategory {
  category: ICategory
  attributes: IAttribute[]
}

interface IAttributeAll extends IAttribute {}

interface IProps {}

interface IState {
  AttributesAllData: IAttributeAll[]
  AttributesCategoryData: IAttributeCategory[]
  loadingAllData: boolean
  loadingCategoryData: boolean
  categoriesLoading: boolean
  categories: ICategory[]
  addAttributeDialogOpen: boolean
}

class AttributesPage extends React.Component<IProps, IState> {
  state: IState = {
    AttributesAllData: [],
    AttributesCategoryData: [],
    loadingAllData: true,
    loadingCategoryData: true,
    categoriesLoading: true,
    categories: [],
    addAttributeDialogOpen: false,
  }

  grabAttributeAllData() {
    fetch(backend_api + '/attributes/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          AttributesAllData: response,
          loadingAllData: false,
        })
      })
      .catch((err) => console.error(err))
  }

  grabAttributeCategoryData() {
    fetch(backend_api + '/attributes/category', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          AttributesCategoryData: response,
          loadingCategoryData: false,
        })
      })
      .catch((err) => console.error(err))
  }

  grabCategories() {
    fetch(backend_api + '/categories/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          categories: response,
          categoriesLoading: false,
        })
      })
      .catch((err) => console.error(err))
  }

  componentDidMount() {
    this.grabAttributeAllData()
    this.grabAttributeCategoryData()
    this.grabCategories()
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <WidthViewport>
          {({ isMobile }) => (
            <>
              <ReactHelmet title={PageTitle(ERoutes.attribute)} />
              <Toolbar
                title={PageTitle(ERoutes.attribute)}
                scrollBackgroundColor
                noXtraBar={isMobile}
                defaultBackgroundColor
              >
                {isMobile ? null : (
                  <Button
                    onClick={() =>
                      this.setState({ addAttributeDialogOpen: true })
                    }
                    startIcon={<Icon icon="add" variant="button" />}
                    disabled={this.state.categoriesLoading}
                  >
                    Attribute
                  </Button>
                )}
              </Toolbar>

              {isMobile ? (
                <Fab
                  color="primary"
                  style={{
                    position: 'fixed',
                    right: 10,
                    bottom: 10,
                    zIndex: 20,
                  }}
                  onClick={() =>
                    this.setState({ addAttributeDialogOpen: true })
                  }
                  disabled={this.state.categoriesLoading}
                >
                  <Icon icon="add" style={{ color: '#fff' }} />
                </Fab>
              ) : null}
              <div
                style={{
                  width: '100%',
                  marginTop: isMobile ? 50 : 100,
                  padding: '0 20px 50px',
                }}
              >
                <div style={{ marginBottom: 20 }}>
                  <Typography variant="h6" style={{ paddingTop: 10 }}>
                    All Categories
                  </Typography>
                  <Table size="small">
                    <TableBody>
                      {DefaultAttributeData.map((val, key) => (
                        <TableRow style={{ height: 48 }} key={key}>
                          <TableCell>{val.name}</TableCell>
                          <TableCell align="right">STANDARD</TableCell>
                        </TableRow>
                      ))}
                      {this.state.AttributesAllData.map((attr) => (
                        <AttributesRow
                          key={attr.id}
                          attribute={attr}
                          categoryId="all"
                          categories={this.state.categories}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </div>

                {this.state.AttributesCategoryData.map(
                  (categoryAttribute, key) => (
                    <div
                      style={{ marginBottom: 20 }}
                      key={key + categoryAttribute.category.id}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          paddingTop: 10,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ marginRight: 12 }}>
                          <CategoryAvatar
                            color={categoryAttribute.category.color}
                            icon={categoryAttribute.category.icon}
                            size={25}
                          />
                        </div>
                        {categoryAttribute.category.name}
                      </Typography>
                      <Table size="small">
                        <TableBody>
                          {categoryAttribute.attributes.map(
                            (attribute, key) => (
                              <AttributesRow
                                key={key}
                                attribute={attribute}
                                categoryId={categoryAttribute.category.id}
                                categories={this.state.categories}
                              />
                            )
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  )
                )}
              </div>
              {this.state.addAttributeDialogOpen ? (
                <AddEditAttributeDialog
                  dialogOpen={this.state.addAttributeDialogOpen}
                  onClose={() =>
                    this.setState({ addAttributeDialogOpen: false })
                  }
                  type="add"
                  categories={this.state.categories}
                />
              ) : null}
            </>
          )}
        </WidthViewport>
      </div>
    )
  }
}

export default AttributesPage
