import React from 'react'
import { ContactRoviDialog } from './IntegrationDialog'

interface IOnStarIntegrationDialogProps {
  open: boolean
  logo?: string
  onClose: () => void
}

const OnStarIntegrationDialog = ({
  open,
  logo,
  onClose,
}: IOnStarIntegrationDialogProps) => {
  return (
    <ContactRoviDialog
      open={open}
      logo={logo}
      onClose={onClose}
      title="OnStar Integration"
    />
  )
}

export default OnStarIntegrationDialog
