import React from 'react'
import '../styles/pages/Forgot.scss'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material/styles/createTheme'
import { createTheme } from '@mui/material/styles'
import { darkMode } from '../components/ux/roviTheme'
import ReactHelmet from '../components/ReactHelmet'
import LoadingOverlay from '../components/LoadingOverlay'
import { ERoutes } from '../components/routes/CheckRoutes'
import ForgotPage from './forgot/ForgotPage'
import VerificationCodePage from './forgot/VerificationCodePage'
import ResetPasswordPage from './forgot/ResetPasswordPage'
import WidthViewport from '../components/ux/WidthViewport'

const DarkTheme = (theme: Theme) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#0078FF',
      },
      background: {
        paper: darkMode ? theme.palette.grey[700] : theme.palette.grey[100],
      },
    },
  })

interface IProps {}

interface IState {
  loading: boolean
  location: 'forgot' | 'verify' | 'newPass'
  verifyCode: string
  email: string
}

class Forgot extends React.Component<IProps, IState> {
  state: IState = {
    loading: true,
    location: 'forgot',
    verifyCode: '',
    email: '',
  }

  submitVerificationCode(verifyCode: string) {
    this.setState({
      location: verifyCode.length > 5 ? 'newPass' : 'verify',
    })
  }

  changeEmail(email: string) {
    this.setState({ email: email })
  }

  ForgotComponent(isMobile: boolean) {
    return (
      <ForgotPage
        submitForgotRequest={() => this.submitForgotRequest()}
        DarkTheme={DarkTheme}
        email={this.state.email}
        changeEmail={(email: string) => this.changeEmail(email)}
        isMobile={isMobile}
      />
    )
  }

  submitForgotRequest() {
    this.setState({ location: 'verify' })
  }

  ComponentLoaded(isMobile: boolean) {
    switch (this.state.location) {
      case 'verify':
        return (
          <VerificationCodePage
            verifyCode={this.state.verifyCode}
            submitVerificationCode={() =>
              this.submitVerificationCode(this.state.verifyCode)
            }
            setVerifyCode={(code) => this.setState({ verifyCode: code })}
            isMobile={isMobile}
          />
        )
      case 'newPass':
        return (
          <ResetPasswordPage
            email={this.state.email}
            verifyCode={this.state.verifyCode}
            isMobile={isMobile}
          />
        )
      case 'forgot':
        return this.ForgotComponent(isMobile)
      default:
        return this.ForgotComponent(isMobile)
    }
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <Paper className="forgotOuterContainer" square>
            <ReactHelmet title="Login" />
            <LoadingOverlay loading={this.state.loading} delayedTime={5000} />
            <h1 className="forgotLogoh1">
              <a title="Rovi Tracker" href={ERoutes.mainLandingPage}>
                <img
                  src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-320x204.png"
                  className="forgotLogo"
                  alt="Rovitracker"
                />
              </a>
            </h1>
            {this.ComponentLoaded(isMobile)}
          </Paper>
        )}
      </WidthViewport>
    )
  }
}

export default Forgot
