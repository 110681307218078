import React from 'react'
import Paper from '@mui/material/Paper'
import Toolbar from '../../components/toolbar/Toolbar'
import ReactHelmet from '../../components/ReactHelmet'
import { ERoutes, PageTitle } from '../../components/routes/CheckRoutes'

interface IProps {
  width: number
  height: number
  isMobile?: boolean
}

interface IState {}

export default class EquipmentMileageByState extends React.Component<
  IProps,
  IState
> {
  state: IState = {}

  render() {
    return (
      <div>
        <ReactHelmet title={PageTitle(ERoutes.EquipmentMileageByState)} />
        <Toolbar
          title={PageTitle(ERoutes.EquipmentMileageByState)}
          scrollBackgroundColor
          defaultBackgroundColor
          noPositionFixed
        >
          <div></div>
        </Toolbar>
        <Paper square>Report</Paper>
      </div>
    )
  }
}

/** This is the component that needs to be exported as it is used on the array that renders all the reports */
export const EquipmentMileageByStateComp = (
  width: number,
  height: number,
  isMobile?: boolean
) => (
  <EquipmentMileageByState width={width} height={height} isMobile={isMobile} />
)
