import React from 'react'
import { WithStyles, createStyles } from '@mui/styles'
import {
  TextField,
  Paper,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import Button from '../buttons/Button'
import { withStyles } from '@mui/styles'
import { colors } from '../ux/roviTheme'
import { TRadiusType } from './GeofenceDrawingManager'
import InfoHover from '../buttons/InfoHover'
import postUpdateGeofence from '../../apiCalls/postCalls/postUpdateGeofence'
import { LoadingIcon } from '../LoadingOverlay'
import SnackBar from '../ux/SnackBar'
import { TErrorTypes } from '../../types/apiCalls'
import EditablePolygon from './EditablePolygon'
import { ILatLng } from '../../types/maptrac/LatLng'

/**
 * Confluence Documentation
 * https://rovitracker.atlassian.net/l/c/EhVMPibQ
 */

export const GEOFENCE_RULES = {
  NO_NAME: 'Geofence Requires a Name',
}

const styles = () =>
  createStyles({
    positionContainer: {
      overflow: 'visible',
      position: 'absolute',
      top: 40,
      right: 5,
      margin: 10,
      zIndex: 10,
    },
    iconStyle: {
      fill: colors.defaultText,
    },
    positionDialogContainer: {
      position: 'absolute',
      bottom: 24,
      right: '50%',
      zIndex: 20,
      transform: 'translate(50%)',
      display: 'flex',
      flexDirection: 'column',
    },
    textInput: {
      width: 200,
      marginTop: 10,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  isMobile: boolean
  onReload: () => void
  isOpen: boolean
  onClose: () => void
  shape: TRadiusType
  points: ILatLng[]
  name: string
  geofenceUUID: string
}

interface IState {
  geofenceName: string
  geofenceError: string
  saving: boolean
  snackBarMessage: string
  snackBarError: TErrorTypes
  points: ILatLng[]
}

class EditGeofence extends React.Component<IProps, IState> {
  state: IState = {
    geofenceName: this.props.name,
    geofenceError: '',
    saving: false,
    snackBarMessage: '',
    snackBarError: 'error',
    points: this.props.points,
  }

  onSave = () => {
    // Here is where the submit button happens
    this.setState({ saving: true })
    postUpdateGeofence({
      name: this.state.geofenceName,
      geofenceId: this.props.geofenceUUID,
      latLng: this.state.points,
      shape: this.props.shape,
    }).then((response) => {
      this.setState(
        {
          saving: false,
          snackBarError: response.type,
          snackBarMessage: response.snackBarMessage,
        },
        // This will trigger after the state change
        () => {
          if (response.type === 'success') {
            this.props.onClose()
            this.props.onReload()
          }
        }
      )
    })
  }

  /**
   * On cancel this should reset the state to the original state, prevent bugs such as users cant create multiple geofences in a row
   */
  onCancel() {
    this.setState({
      geofenceName: '',
      geofenceError: '',
    })
    this.props.onClose()
  }

  render() {
    return this.props.isMobile ? null : (
      <>
        {this.props.isOpen && (
          <Paper className={this.props.classes.positionDialogContainer}>
            <DialogContent>
              <DialogContentText>
                Click on the map to edit your geofence
                <InfoHover text="A geofence sets a virtual border" />
              </DialogContentText>

              <TextField
                label="Geofence Name"
                value={this.state.geofenceName}
                onChange={(ev) =>
                  this.setState({
                    geofenceName: ev.target.value,
                    geofenceError: ev.target.value
                      ? ''
                      : GEOFENCE_RULES.NO_NAME,
                  })
                }
                error={!!this.state.geofenceError}
                helperText={this.state.geofenceError}
                className={this.props.classes.textInput}
                required
                autoFocus
                variant="standard"
                style={{ width: 256 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.onCancel()}>Cancel</Button>
              <Button
                color="primary"
                onClick={() => this.onSave()}
                disabled={!this.state.geofenceName}
              >
                {this.state.saving ? <LoadingIcon loading /> : 'Save'}
              </Button>
            </DialogActions>

            {this.props.isOpen && (
              <EditablePolygon
                points={this.props.points}
                onEditPoints={(pointsRef) =>
                  this.setState({ points: pointsRef })
                }
              />
            )}
          </Paper>
        )}
        <SnackBar
          message={this.state.snackBarMessage}
          onClose={() => this.setState({ snackBarMessage: '' })}
          variant={this.state.snackBarError}
        />
      </>
    )
  }
}

export default withStyles(styles)(EditGeofence)
