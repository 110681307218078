import React from 'react'
import FlagBox from '../flagComponents/FlagBox'
import { ERoutes } from '../routes/CheckRoutes'
import { IFlag } from '../../types/flag'

interface IProps {
  flags: IFlag[]
}

class FlagsSection extends React.Component<IProps> {
  render() {
    return (
      <div
        style={{
          width: 325,
          margin: '5px 0',
          display: 'flex',
          flexWrap: 'wrap',
          paddingBottom: 15,
        }}
      >
        {this.props.flags.map((flag, key) => (
          <FlagBox
            name={flag.flagName}
            href={ERoutes.flag}
            key={flag.flagName + key}
          />
        ))}
      </div>
    )
  }
}

export default FlagsSection
