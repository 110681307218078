import React from 'react'
import SnackbarMui from '@mui/material/Snackbar'
import SnackbarContentMui from '@mui/material/SnackbarContent'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import green from '@mui/material/colors/green'
import { TErrorTypes } from '../../types/apiCalls'

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
}))

interface IProps {
  onClose: () => void
  message?: string
  variant: TErrorTypes
}

const SnackBar = (props: IProps) => {
  const classes = useStyles()

  return (
    <SnackbarMui
      open={!!props.message}
      autoHideDuration={4000}
      onClose={props.onClose}
    >
      <SnackbarContentMui
        className={classes[props.variant]}
        message={props.message}
      />
    </SnackbarMui>
  )
}

export default SnackBar
