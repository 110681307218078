import React from 'react'
import { colors } from '../ux/roviTheme'
import EllipsisText from '../ux/EllipsisText'

const equipClientSiteStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}

interface IProps {
  clientName: string
  clientUrl?: string
  siteName: string
}

export class EquipClientSiteRow extends React.Component<IProps> {
  render() {
    return (
      <div style={equipClientSiteStyles}>
        {!this.props.clientUrl ? (
          <EllipsisText
            text={this.props.clientName}
            style={{ color: colors.textGrey }}
          />
        ) : (
          <EllipsisText text={this.props.clientName} width={100}>
            <a href={this.props.clientUrl}>{this.props.clientName}</a>
          </EllipsisText>
        )}
        <EllipsisText
          text={this.props.siteName}
          width={100}
          style={{ color: colors.textGrey }}
        />
      </div>
    )
  }
}
