import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '../buttons/IconButton'
import TextField from '../textBoxs/TextField'

export interface IInput {
  name: string
  label: string
}

interface IProps {
  input: IInput
}

interface IState {
  editable: boolean
  value?: string
}

class FieldLabelRow extends React.Component<IProps, IState> {
  state: IState = {
    editable: false,
  }

  onEdit() {
    this.setState({ editable: true, value: undefined })
  }

  onSave() {
    this.setState({ editable: false })
  }

  render() {
    const input = this.props.input
    return (
      <TableRow>
        <TableCell>{input.name}</TableCell>
        <TableCell>
          {this.state.editable ? (
            <TextField
              defaultValue={input.label}
              value={this.state.value}
              onChange={(event) => this.setState({ value: event.target.value })}
            />
          ) : (
            input.label
          )}
        </TableCell>
        <TableCell>
          {this.state.editable ? (
            <IconButton
              icon="save"
              variant="row"
              onClick={() => this.onSave()}
            />
          ) : (
            <IconButton
              icon="edit"
              variant="row"
              onClick={() => this.onEdit()}
            />
          )}
        </TableCell>
      </TableRow>
    )
  }
}

export default FieldLabelRow
