import React, { useState } from 'react'
import CardActions from '@mui/material/CardActions'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Button from '../buttons/Button'
import { makeStyles } from '@mui/styles'
import { IDeviceType } from '../../types/account/devices'
import LearnMoreDialog from './dialogs/LearnMoreDialog'

const useStyles = makeStyles({
  card: {
    width: 280,
    marginRight: 10,
    flexShrink: 0,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: 200,
    height: 160,
  },
})

interface Props {
  deviceType: IDeviceType
  onClickPurchase: () => void
}

const DeviceTypeCard: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [dialogOpen, setDialogState] = useState(false)

  return (
    <>
      <Card className={classes.card}>
        <div className={classes.imageContainer}>
          <CardMedia
            className={classes.image}
            image={props.deviceType.imageUrl}
          />
        </div>

        <CardContent>
          <Typography gutterBottom variant="h5">
            {props.deviceType.label}
          </Typography>
          <Typography color="textSecondary">
            Dimensions: {props.deviceType.dimensions}
          </Typography>
        </CardContent>

        <CardActions>
          <Button size="small" color="primary" onClick={props.onClickPurchase}>
            Purchase
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => setDialogState(true)}
          >
            Learn More
          </Button>
        </CardActions>
      </Card>
      <LearnMoreDialog
        dialogOpen={dialogOpen}
        onClose={() => setDialogState(false)}
        deviceInfo={props.deviceType}
      />
    </>
  )
}

export default DeviceTypeCard
