import React from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import EllipsisText from '../ux/EllipsisText'
import Divider from '@mui/material/Divider'
import Margin from '../ux/Margin'
import Icon from '../Icon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { colors } from '../ux/roviTheme'
import BranchList from './BranchList'
import ManageList from './ManageList'
import { ILinks } from '../../types/appbar'

interface IProps {
  sections: Array<ILinks>
}

export default class RoviMainMenu extends React.Component<IProps> {
  state = {
    sessionMenuOpen: false,
  }

  menuItemIcona = {
    dash: 'dash',
    yard: 'yard',
    clients: 'clients',
    inventory: 'equipment',
    maptrac: 'maptrac',
    service: 'service',
    reports: 'reports',
  }

  render() {
    return (
      <>
        <div style={{ padding: 10 }}>
          <div
            style={{
              backgroundImage:
                'url(https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-120x78.png)',
              height: 60,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />

          <Margin height={10} />

          <div style={{ fontSize: 14 }}>Rovitracker Inc.</div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() =>
              this.setState({ sessionMenuOpen: !this.state.sessionMenuOpen })
            }
            id="MobileSessionMenuOpen"
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', width: 200 }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  fontSize: 16,
                  height: 36,
                }}
              >
                <EllipsisText text="Nathaniel Young" />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  color: colors.textDarkGrey,
                  height: 20,
                }}
              >
                <EllipsisText text="nathan@rovitracker.com" />
              </div>
            </div>
            {this.state.sessionMenuOpen ? (
              <KeyboardArrowUpIcon style={{ color: colors.iconColor }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: colors.iconColor }} />
            )}
          </div>
        </div>

        <Margin height={10} />
        <Divider />

        {this.state.sessionMenuOpen && (
          <>
            <BranchList />
            <ManageList />
          </>
        )}

        {!this.state.sessionMenuOpen && (
          <List>
            {this.props.sections.map((sect: ILinks) => (
              <ListItem
                button
                component="a"
                key={sect.name}
                // selected={this.props.section === s.name}
                href={sect.link}
                id={`MobileMenuBar${sect.name}`}
              >
                <ListItemIcon>
                  {sect.icon ? (
                    <Icon
                      icon={sect.icon}
                      style={{ color: colors.iconColor }}
                    />
                  ) : null}
                </ListItemIcon>
                <ListItemText primary={sect.name} />
              </ListItem>
            ))}
          </List>
        )}
      </>
    )
  }
}
