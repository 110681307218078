import React from 'react'
import '../../styles/components/Appbar.scss'
import AppBar from '@mui/material/AppBar'
import IconButton from '../buttons/IconButton'
import BranchMenu from './BranchMenu'
import UserMenu from '../UserMenu'
import AppBarLinks from './AppBarLinks'
import { ILinks } from '../../types/appbar'
import { colors } from '../ux/roviTheme'

interface IProps {
  links: Array<ILinks>
  userLoggedIn?: boolean
  title?: string
}

class DesktopRoviAppbar extends React.Component<IProps> {
  render() {
    return (
      <div className="mainAppBar">
        <AppBar
          position="absolute"
          className="innerMainAppBar"
          style={{ backgroundColor: colors.bg }}
        >
          <div className="appBarFloatLeft">
            <a href="https://rovitracker.com/">
              <img
                className="rovitrackerLogo"
                src="https://s3.amazonaws.com/assets.rovitracker.cloud/images/rovitrackerLogos/rovi-logo-120x78.png"
                alt="Rovitracker Logo"
              />
            </a>

            {this.props.userLoggedIn && (
              <AppBarLinks links={this.props.links} />
            )}
          </div>

          {this.props.userLoggedIn && (
            <div className="appBarFloatRight">
              <BranchMenu title="Salt Lake City" />

              <IconButton
                variant="appbar"
                icon="notifications"
                href="/notifications"
                id="appbarNotificationsButton"
                tooltip="Notifications"
              />

              <UserMenu
                title="Session"
                user={{
                  name: 'Nathaniel Young',
                  email: 'nathan@rovitracker.com',
                }}
                accountName="Rovitracker Inc."
              />
            </div>
          )}
        </AppBar>
      </div>
    )
  }
}

export default DesktopRoviAppbar
