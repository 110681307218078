import React from 'react'
import '../../styles/components/buttons/FloatingButtonDrawer.scss'
import Paper from '@mui/material/Paper'
import { WithStyles, createStyles } from '@mui/styles'
import { withStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import Icon from '../Icon'
import { colors } from '../ux/roviTheme'

const mobileArrowHeight = 36

const styles = (theme: Theme) =>
  createStyles({
    mobileContainer: {
      position: 'absolute',
      width: '100%',
      left: 0,
      zIndex: 9,
      transition: '0.3s',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    desktopContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      zIndex: 4,
      transition: '0.3s',
      display: 'flex',
      flexDirection: 'column',
    },
    mobileToggle: {
      display: 'flex',
      justifyContent: 'center',
      height: mobileArrowHeight,
      flexShrink: 0,
      zIndex: 1,
      alignItems: 'center',
      background: theme.palette.primary.dark,
    },
    childContainer: {
      flex: 1,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
    },
  })

interface IProps extends WithStyles<typeof styles> {
  isOpen?: boolean
  closeOpen?: (bool: boolean) => void
  isMobile: boolean
  defaultOpen?: boolean
  width?: number
  isLeft?: boolean
  addPadding?: boolean // meant for the recentactivity Tab
  onClick?: (bool: boolean) => void
  topMargin?: number // this will add topMargin based on the number in px - mostly used on dashboard
  leftMargin?: number // this will add leftMargin based on the number in px - mostly used on equipment reports
  mobileBottom?: number // this will change the drawer point when it is open or closed
  children?: React.ReactNode // Everything was breaking without this so added it when upgrading to Material-ui@v5.0.0-alpha.25 Feb 15th
}

class FloatingButtonDrawer extends React.Component<IProps> {
  state = {
    open: this.props.defaultOpen
      ? this.props.isLeft
        ? true
        : false
      : this.props.isLeft
      ? false
      : true,
  }

  handleToggleDrawer = () => {
    this.props.onClick && this.props.onClick(this.state.open)
    if (this.props.closeOpen) {
      this.props.closeOpen(!this.props.isOpen)
    }
    this.setState({ open: !this.state.open })
  }

  render() {
    const { classes, isLeft, addPadding } = this.props
    const open =
      this.props.isOpen !== undefined ? this.props.isOpen : this.state.open
    const defaultWidth = isLeft
      ? open
        ? this.props.width
          ? this.props.width
          : 350
        : 0
      : open
      ? 0
      : this.props.width
      ? this.props.width
      : 350
    const sideStyles = this.props.isLeft
      ? {
          left: open ? 0 : defaultWidth * -1,
          width: defaultWidth,
          marginTop: this.props.topMargin ? this.props.topMargin : undefined,
          marginLeft: this.props.leftMargin ? this.props.leftMargin : undefined,
        }
      : {
          right: open ? defaultWidth * -1 : 0,
          width: defaultWidth,
          marginTop: this.props.topMargin ? this.props.topMargin : undefined,
          marginLeft: this.props.leftMargin ? this.props.leftMargin : undefined,
        } // turns the positive number as a negative}
    const sideButton = this.props.isLeft
      ? { right: -23, background: colors.assetBorderColor, zIndex: 2 }
      : { left: -23, background: colors.assetBorderColor, zIndex: 2 }
    const iconLook = open ? 'arrowLeft' : 'arrowRight'

    const stylePadding = addPadding
      ? {
          padding: isLeft ? (open ? '5px 10px' : 0) : open ? 0 : '5px 10px',
        }
      : undefined
    return (
      <>
        {this.props.isMobile ? (
          <Paper
            className={classes.mobileContainer}
            style={{
              height: open ? 'calc(100vh - 180px)' : mobileArrowHeight,
              bottom: this.props.mobileBottom ? this.props.mobileBottom : 0,
            }}
          >
            <div
              className={classes.mobileToggle}
              onClick={this.handleToggleDrawer}
            >
              <Icon
                style={{ color: 'white' }}
                icon={open ? 'arrowDown' : 'arrowUp'}
              />
            </div>
            <div className={classes.childContainer}>{this.props.children}</div>
          </Paper>
        ) : (
          <Paper className={classes.desktopContainer} style={sideStyles}>
            <div
              className="desktopToggle"
              style={sideButton}
              onClick={this.handleToggleDrawer}
            >
              <Icon style={{ color: colors.iconColor }} icon={iconLook} />
            </div>
            <div className={classes.childContainer} style={stylePadding}>
              {this.props.children}
            </div>
          </Paper>
        )}
      </>
    )
  }
}

export default withStyles(styles)(FloatingButtonDrawer)
