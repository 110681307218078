import React from 'react'
import ReactHelmet from '../../components/ReactHelmet'
import Typography from '@mui/material/Typography'
import TicketSearchBar from '../../components/serviceComponents/ticketsComponents/TicketsSearchBar'
import WidthViewport from '../../components/ux/WidthViewport'
import TicketsTable from '../../components/serviceComponents/ticketsComponents/TicketsTable'
import { backend_api, headersAPI } from '../../constants/api'
import { ITickets } from '../../types/service/tickets'
import { IAssignAsset } from '../../types/service/tasks'
import { IServiceHash } from '../../constants/serviceHashController'
import HashStateManager from '../../rx-js/HashStateManager'
import moment from 'moment'

interface IProps {
  hash: HashStateManager<IServiceHash>
}

interface IState {
  TicketsLoaded?: ITickets
  TicketsLoading: boolean
  statusOpen: boolean
  searchBarVal: string
  selectedAsssets?: string[]
  assetsLoaded: IAssignAsset[]
  assetsLoading: boolean
  createdSelectedDates: Array<Date | null>
  resolvedSelectedDates: Array<Date | null>
}

class TicketsPage extends React.Component<IProps, IState> {
  state: IState = {
    TicketsLoaded: undefined,
    TicketsLoading: true,
    statusOpen: true,
    searchBarVal: '',
    selectedAsssets: [],
    assetsLoaded: [],
    assetsLoading: true,
    createdSelectedDates: [null, null],
    resolvedSelectedDates: [null, null],
  }

  splitDates(hashDate: string) {
    let splstr = hashDate.split('~')
    return [
      moment(splstr[0]).toDate(),
      moment(splstr[1]).toDate(),
    ] as Array<Date | null>
  }

  grabAllTickets() {
    fetch(backend_api + '/service/tickets/all', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ TicketsLoaded: response, TicketsLoading: false })
      })
      .catch((err) => console.log(err))
  }

  grabAllAssets() {
    fetch(backend_api + '/service/assets', {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ assetsLoaded: response, assetsLoading: false })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    const hashgrabbed: IServiceHash = this.props.hash.value
    this.grabAllTickets()
    this.grabAllAssets()
    this.setState({
      selectedAsssets: hashgrabbed.assets,
      statusOpen: hashgrabbed.status === 'closed' ? false : true,
      createdSelectedDates: hashgrabbed.createdDate
        ? this.splitDates(hashgrabbed.createdDate)
        : [null, null],
      resolvedSelectedDates: hashgrabbed.resolvedDate
        ? this.splitDates(hashgrabbed.resolvedDate)
        : [null, null],
    })
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <div
            style={
              isMobile
                ? { paddingTop: 48 }
                : { margin: 24, width: 'calc(100% - 48px)' }
            }
          >
            <ReactHelmet title="Tickets" />
            <TicketSearchBar
              statusOpen={this.state.statusOpen}
              changeStatus={(status) => {
                this.setState({ statusOpen: status })
                this.props.hash.set({
                  ...this.props.hash.value,
                  status: status ? 'open' : 'closed',
                })
              }}
              searchBarVal={this.state.searchBarVal}
              updateSearchBarVal={(newVal) =>
                this.setState({ searchBarVal: newVal })
              }
              assetsLoaded={this.state.assetsLoaded}
              selectedAsssets={
                this.state.selectedAsssets ? this.state.selectedAsssets : []
              }
              updateSelectedAssets={(newVal: string[]) => {
                this.setState({ selectedAsssets: newVal })
                this.props.hash.set({
                  ...this.props.hash.value,
                  assets: newVal,
                })
              }}
              createdSelectedDates={this.state.createdSelectedDates}
              resolvedSelectedDates={this.state.resolvedSelectedDates}
              updateResolvedSelectedDates={(date: Array<Date | null>) => {
                this.setState({ resolvedSelectedDates: date })
                this.props.hash.set({
                  ...this.props.hash.value,
                  resolvedDate:
                    date[0] && date[1]
                      ? `${moment(date[0]).format('YYYY-MM-DD')}~${moment(
                          date[1]
                        ).format('YYYY-MM-DD')}`
                      : undefined,
                })
              }}
              updateCreatedSelectedDates={(date: Array<Date | null>) => {
                this.setState({ createdSelectedDates: date })
                this.props.hash.set({
                  ...this.props.hash.value,
                  createdDate:
                    date[0] && date[1]
                      ? `${moment(date[0]).format('YYYY-MM-DD')}~${moment(
                          date[1]
                        ).format('YYYY-MM-DD')}`
                      : undefined,
                })
              }}
              isMobile={isMobile}
            />
            <Typography
              variant="h6"
              style={{ marginLeft: isMobile ? 24 : undefined }}
            >
              Service Tickets
            </Typography>
            <TicketsTable
              isMobile={isMobile}
              TicketsLoaded={this.state.TicketsLoaded}
              TicketsLoading={this.state.TicketsLoading}
              searchBarVal={this.state.searchBarVal}
              statusOpen={this.state.statusOpen}
              createdSelectedDates={this.state.createdSelectedDates}
              resolvedSelectedDates={this.state.resolvedSelectedDates}
            />
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default TicketsPage
