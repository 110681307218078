import { ISelectValues } from '../components/ux/Select'

/** This function takes just a year format like 2021 and will count down depending on the variable howManyYears */
export function countBackwardsInYears(
  currentYear: string,
  howManyYears: number
) {
  let yearArr: ISelectValues[] = []
  let currentYearToNum = parseInt(currentYear)
  for (let i = 0; i < howManyYears; i++) {
    let year = (currentYearToNum - i).toString()
    yearArr.push({
      value: year,
      label: year,
    })
  }
  return yearArr
}
