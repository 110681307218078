import React from 'react'
import '../styles/pages/Dashboard.scss'
import { Helmet } from 'react-helmet'

interface IProps {
  title: string
}

class reactHelmet extends React.Component<IProps> {
  render() {
    return (
      <Helmet>
        <title>{this.props.title}</title>
        {/* The Icons are set here as well for when we overwrite the helmet of the page */}
        <link rel="shortcut icon" href="../../logoIcon.png" type="image/png" />
        <link rel="icon" href="../../logoIcon.png" type="image/png" />
      </Helmet>
    )
  }
}

export default reactHelmet
