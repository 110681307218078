import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const FlagIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M 4.486,13.57 H 6.32 v 9.18 H 4.486 Z m 0,-8.394 H 6.32 V 11.36 H 4.486 Z M 4.094,3.399 v 1.392 l 2.92,-0 0.02,6.923 -2.941,0 0,1.34 H 21.02 L 18.24,8.233 21.02,3.399 Z M 4.486,1.984 H 6.32 V 3.03 H 4.486 Z" />,
  'Flag'
)

export default FlagIcon
