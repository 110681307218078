import React from 'react'
import WidthViewport from '../../components/ux/WidthViewport'
import { WithStyles } from '@mui/styles'
import { createStyles, withStyles } from '@mui/styles'
import ReactHelmet from '../../components/ReactHelmet'
import Toolbar from '../../components/toolbar/Toolbar'
import Button from '../../components/buttons/Button'
import Icon from '../../components/Icon'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardItem } from '../../components/ux/Attribute'
import PartsTable from '../../components/serviceComponents/ticketInfoComponents/PartsTable'
import NotesTable from '../../components/serviceComponents/ticketInfoComponents/NotesTable'
import { backend_api, headersAPI } from '../../constants/api'
import { IRepair } from '../../types/service/tickets'
import moment from 'moment'
import EllipsisText from '../../components/ux/EllipsisText'
import LoadingOverlay from '../../components/LoadingOverlay'
import TextField from '../../components/textBoxs/TextField'
import DeleteTicketDialog from '../../components/serviceComponents/ticketInfoComponents/DeleteTicketDialog'
import EditTicketDialog from '../../components/serviceComponents/ticketInfoComponents/EditTicketDialog'
import ResolveTicketDialog from '../../components/serviceComponents/ticketInfoComponents/ResolveTicketDialog'

const checkIfCompleted = (value?: any) => (value ? value : '-')

const styles = () =>
  createStyles({
    serviceTicketBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: '100%',
      padding: '100px 24px 24px',
    },
    card: {
      width: '100%',
      marginTop: 5,
      marginBottom: 5,
      maxWidth: 700,
    },
    cardComponent: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    addImage: {
      padding: 24,
    },
    textField: { width: 256 },
  })

interface IProps extends WithStyles<typeof styles> {
  id: string
}

interface IState {
  ticketInfo?: IRepair
  newNote: string
  deleteDialogOpen: boolean
  editDialogOpen: boolean
  resolveDialogOpen: boolean
}

class ServiceTicketInfo extends React.Component<IProps, IState> {
  state: IState = {
    newNote: '',
    deleteDialogOpen: false,
    editDialogOpen: false,
    resolveDialogOpen: false,
  }

  grabServiceTicketInfo() {
    fetch(backend_api + '/service/ticket/info/' + this.props.id, {
      method: 'GET',
      headers: headersAPI,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        this.setState({ ticketInfo: response })
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.grabServiceTicketInfo()
  }

  render() {
    const classes = this.props.classes
    const { ticketInfo } = this.state
    return (
      <WidthViewport>
        {({ isMobile }: { isMobile: boolean }) => (
          <div>
            <ReactHelmet title="Service Ticket" />
            <Toolbar title="Service Ticket" scrollBackgroundColor>
              <Button
                startIcon={<Icon icon="edit" />}
                onClick={() => this.setState({ editDialogOpen: true })}
              >
                Edit
              </Button>
              <Button
                startIcon={<Icon icon="check" />}
                onClick={() => this.setState({ resolveDialogOpen: true })}
              >
                Resolve
              </Button>
              <Button
                startIcon={<Icon icon="delete" />}
                onClick={() => this.setState({ deleteDialogOpen: true })}
              >
                Delete
              </Button>
            </Toolbar>
            {ticketInfo ? (
              <div className={classes.serviceTicketBody}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardComponent}>
                    <CardItem
                      title="Start Date"
                      value={checkIfCompleted(
                        moment(ticketInfo.checkInDate).format('ll')
                      )}
                    />
                    <CardItem
                      title="Completed Date"
                      value={checkIfCompleted(
                        moment(ticketInfo.dateCompleted).format('ll')
                      )}
                    />
                  </CardContent>
                  <CardContent>
                    <CardItem
                      title="Hour Meter"
                      value={checkIfCompleted(ticketInfo.engineCount)}
                    />
                  </CardContent>
                  <CardContent className={classes.cardComponent}>
                    <CardItem title="Ticket #" value={ticketInfo.id} />
                    <CardItem
                      title="Issue"
                      value={
                        <EllipsisText
                          text={checkIfCompleted(ticketInfo.issue)}
                          width={100}
                        />
                      }
                    />
                    <CardItem
                      title="Resolution"
                      value={
                        <EllipsisText
                          text={checkIfCompleted(ticketInfo.resolution)}
                          width={100}
                        />
                      }
                    />
                  </CardContent>
                </Card>
                <div className={classes.addImage}>
                  <Button startIcon={<Icon icon="add" />} component="label">
                    Image
                    <input
                      id="imageFile"
                      type="file"
                      style={{ display: 'none' }}
                      accept="image/*"
                      onChange={({ target }) => {
                        target.files && console.log(target.files[0])
                      }}
                    />
                  </Button>
                </div>
                {ticketInfo.parts ? (
                  <PartsTable parts={ticketInfo.parts} />
                ) : null}
                <br />
                {ticketInfo.notes ? (
                  <NotesTable notes={ticketInfo.notes} />
                ) : null}
                <br />
                <TextField
                  name="note"
                  value={this.state.newNote}
                  onChange={(event) =>
                    this.setState({ newNote: event.target.value })
                  }
                  multiline
                  rows={3}
                  className={classes.textField}
                  maxLength={14850}
                  helperText="Please enter your note"
                />
                <br />
                <Button variant="contained">Add Note</Button>
                {this.state.deleteDialogOpen ? (
                  <DeleteTicketDialog
                    open={this.state.deleteDialogOpen}
                    onClose={() => this.setState({ deleteDialogOpen: false })}
                    onDelete={() => this.setState({ deleteDialogOpen: false })}
                    ticketId={ticketInfo.id}
                  />
                ) : null}
                {this.state.editDialogOpen ? (
                  <EditTicketDialog
                    open={this.state.editDialogOpen}
                    onClose={() => this.setState({ editDialogOpen: false })}
                    onSave={() => this.setState({ editDialogOpen: false })}
                    ticketId={ticketInfo.id}
                    parts={ticketInfo.parts}
                  />
                ) : null}
                {this.state.resolveDialogOpen ? (
                  <ResolveTicketDialog
                    open={this.state.resolveDialogOpen}
                    onClose={() => this.setState({ resolveDialogOpen: false })}
                    onSave={() => this.setState({ resolveDialogOpen: false })}
                    ticketId={ticketInfo.id}
                    parts={ticketInfo.parts}
                  />
                ) : null}
              </div>
            ) : (
              <LoadingOverlay loading />
            )}
          </div>
        )}
      </WidthViewport>
    )
  }
}

export default withStyles(styles)(ServiceTicketInfo)
