import React from 'react'
// import { ILatLng } from 'dto/geo'
import { Popover } from '@mui/material'
import IconButton from '../buttons/IconButton'
import Margin from '../ux/Margin'
import { colors } from '../ux/roviTheme'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const DarkTheme = createTheme({
  palette: {
    action: {
      active: '#fff',
    },
  },
  typography: {
    fontSize: 11,
  },
})

interface ILatLng {
  lat: number
  lng: number
}

const styles: Record<string, React.CSSProperties> = {
  iconButton: {
    padding: 0,
    marginRight: 8,
  },
  popoverContainer: {
    padding: 14,
    paddingRight: 24,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    top: -2,
    right: 12,
    height: 16,
    width: 16,
    fill: colors.textGrey,
  },
}

interface IProps {
  gps: ILatLng
  lastUpdateAt?: Date
  timezone: string
}

export default class LocationDetailsPopover extends React.Component<IProps> {
  state = {
    open: false,
    anchorEl: undefined,
    address: null,
  }

  // formatter = ZonedInstantFormatter.of(this.props.timezone)

  handleClick = (el: EventTarget & Element) => {
    this.setState({ open: true, anchorEl: el })
    if (this.props.gps) {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ location: this.props.gps }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          this.setState({ address: results[0].formatted_address })
        } else {
          this.setState({ address: null })
        }
      })
    }
  }

  render() {
    return (
      <>
        <ThemeProvider theme={DarkTheme}>
          <IconButton
            icon="coord"
            onClick={(ev) => this.handleClick(ev.currentTarget)}
            variant="row"
            style={styles.iconButton}
          />
        </ThemeProvider>
        <Popover
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          anchorEl={this.state.anchorEl}
        >
          <div style={styles.popoverContainer}>
            <div style={styles.closeIcon}>
              <IconButton
                icon="close"
                onClick={() => this.setState({ open: false })}
                variant="row"
                style={styles.closeIcon}
              />
            </div>
            {this.props.lastUpdateAt && (
              <div>
                <span style={{ fontWeight: 'bold' }}>
                  Last GPS Update Received:
                </span>{' '}
                {/* {this.formatter.format(
                  this.props.lastUpdateAt,
                  Formats.longDateTime
                )} */}
              </div>
            )}
            <Margin height={12} />
            <div>
              {this.props.gps.lat.toFixed(6)}, {this.props.gps.lng.toFixed(6)}
            </div>
            <Margin height={4} />
            <div>{this.state.address ? this.state.address : 'Loading...'}</div>
            <Margin height={12} />
            {/* Using target="_blank" without rel="noopener noreferrer" is a security risk: see https://mathiasbynens.github.io/rel-noopener */}
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${this.props.gps.lat},${this.props.gps.lng}`}
              target="_BLANK"
              rel="noopener noreferrer"
            >
              Get Directions
            </a>
          </div>
        </Popover>
      </>
    )
  }
}
