import React from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Icon from '../Icon'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import EllipsisText from '../ux/EllipsisText'
import { colors } from '../ux/roviTheme'

interface IProps {}

class BranchList extends React.Component<IProps> {
  state = {
    branches: [
      { id: '1', name: 'Salt Lake City' },
      { id: '2', name: 'South Salt Lake' },
      { id: '3', name: 'West Jordan' },
      { id: '4', name: 'Midland Texas' },
    ],
    selectedBranch: '1',
  }

  render() {
    return (
      <>
        <ListSubheader>Branches</ListSubheader>
        <MenuList>
          {this.state.branches.map((branch) => (
            <MenuItem
              key={branch.id}
              id={`BranchName${branch.name}`}
              onClick={() => this.setState({ selectedBranch: branch.id })}
            >
              {this.state.selectedBranch === branch.id ? (
                <ListItemIcon>
                  <Icon icon="check" style={{ color: colors.iconColor }} />
                </ListItemIcon>
              ) : (
                <div style={{ paddingLeft: 56 }} />
              )}
              <ListItemText
                primary={<EllipsisText text={branch.name} width={136} />}
              />
            </MenuItem>
          ))}
        </MenuList>
      </>
    )
  }
}

export default BranchList
