import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '../../buttons/Button'
import TextField from '../../textBoxs/TextField'
import { withStyles } from '@mui/styles'
import { WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'
import NumberField from '../../textBoxs/NumberField'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { IPart } from '../../../types/service/parts'
import { ICategory } from '../../../types/category'
import { backend_api, headersAPI } from '../../../constants/api'
import FormControl from '@mui/material/FormControl'

const styles = (theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    selectContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    select: {
      marginTop: 2,
      width: 250,
    },
    selectMenu: {
      maxHeight: 300,
    },
    TextField: {
      width: 250,
      marginRight: theme.spacing(1),
    },
  })

interface IProps extends WithStyles<typeof styles> {
  open: boolean
  onClose: () => void
  isMobile?: boolean
  type: 'add' | 'edit'
  part?: IPart
}

interface IState {
  number?: string
  vendor?: string
  manufacturer?: string
  description?: string
  category?: string
  cost?: number | null
  retail?: number | null
  rentalRate?: number | null
  allCategories: ICategory[]
}

class PartDialog extends React.Component<IProps, IState> {
  state: IState = {
    allCategories: [],
  }

  grabCategories() {
    fetch(backend_api + '/assets/all', { method: 'GET', headers: headersAPI })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ allCategories: response })
      })
      .catch((err) => console.log(err))
  }

  onClose() {
    /**
     * Clears State
     */
    this.setState({
      number: undefined,
      vendor: undefined,
      manufacturer: undefined,
      description: undefined,
      category: undefined,
      cost: undefined,
      retail: undefined,
      rentalRate: undefined,
    })
    this.props.onClose()
  }

  componentDidMount() {
    this.grabCategories()
  }

  render() {
    const classes = this.props.classes
    const props = this.props
    return (
      <Dialog
        open={props.open}
        onClose={() => this.onClose()}
        fullScreen={props.isMobile}
        fullWidth
      >
        <DialogTitle>
          {props.type === 'add' ? 'Add' : 'Edit'} Service Part
        </DialogTitle>
        <DialogContent>
          <div className={classes.row}>
            <TextField
              className={classes.TextField}
              defaultValue={this.props.part ? this.props.part.number : ''}
              value={this.state.number}
              required
              label="Number"
              autoFocus
              onChange={(event) =>
                this.setState({ number: event.target.value })
              }
            />
          </div>

          <div className={classes.row}>
            <TextField
              defaultValue={this.props.part ? this.props.part.vendor : ''}
              value={this.state.vendor}
              label="Vendor"
              onChange={(event) =>
                this.setState({ vendor: event.target.value })
              }
              className={classes.TextField}
            />
            <TextField
              className={classes.TextField}
              defaultValue={props.part ? props.part.manufacturer : ''}
              value={this.state.manufacturer}
              onChange={(event) =>
                this.setState({ manufacturer: event.target.value })
              }
              label="Manufacturer"
            />
          </div>

          <div className={classes.row}>
            <TextField
              className={classes.TextField}
              defaultValue={props.part ? props.part.description : ''}
              value={this.state.description}
              onChange={(event) =>
                this.setState({ description: event.target.value })
              }
              label="Description"
            />
          </div>

          <div className={classes.row}>
            <div className={classes.selectContainer}>
              <FormControl variant="standard" sx={{ m: 1 }}>
                <InputLabel shrink>Category</InputLabel>
                <Select
                  defaultValue={props.part ? props.part.category.id : ''}
                  value={this.state.category}
                  className={classes.select}
                  MenuProps={{
                    classes: {
                      paper: classes.selectMenu,
                    },
                  }}
                  onChange={(event) =>
                    this.setState({ category: event.target.value as string })
                  }
                  displayEmpty
                >
                  <MenuItem value="">- All -</MenuItem>
                  {this.state.allCategories.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className={classes.row}>
            <NumberField
              defaultValue={props.part ? props.part.cost : 0}
              value={this.state.cost}
              startAdornment="$"
              label="Cost"
              className={classes.TextField}
              onChange={(event) => this.setState({ cost: event })}
            />
            <NumberField
              defaultValue={props.part ? props.part.retail : 0}
              value={this.state.retail}
              startAdornment="$"
              label="Retail"
              className={classes.TextField}
              onChange={(event) => this.setState({ retail: event })}
            />
            <NumberField
              className={classes.TextField}
              defaultValue={props.part ? props.part.rentalRate : 0}
              value={this.state.rentalRate}
              startAdornment="$"
              label="Rental Rate"
              onChange={(event) => this.setState({ rentalRate: event })}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => this.onClose()}>Cancel</Button>
          <Button color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(PartDialog)
