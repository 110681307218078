import React from 'react'
import Drawer from '@mui/material/Drawer'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

export type Anchor = 'top' | 'left' | 'bottom' | 'right'

interface IProps {
  swipeable?: boolean
  anchorEl: Anchor
  anchor: boolean
  setAnchor: (val: boolean) => void
  width?: number
}

class DrawerContainer extends React.Component<IProps> {
  render() {
    const props = this.props
    return props.swipeable ? (
      <SwipeableDrawer
        anchor={props.anchorEl}
        open={props.anchor}
        onOpen={() => props.setAnchor(true)}
        onClose={() => props.setAnchor(false)}
      >
        <div style={{ width: props.width ? props.width : 250 }}>
          {props.children}
        </div>
      </SwipeableDrawer>
    ) : (
      <Drawer
        anchor={props.anchorEl}
        open={props.anchor}
        onClose={() => props.setAnchor(false)}
      >
        <div style={{ width: props.width ? props.width : 250 }}>
          {props.children}
        </div>
      </Drawer>
    )
  }
}

export default DrawerContainer
