import React from 'react'
import ImageUploader from '../ux/ImageUploader'
import { ImageWithDefaultEquip } from '../ux/ImageWithDefault'
import { IImage } from '../ux/ImageUploader'
import { ICategory } from '../../types/category'

interface IProps {
  images: IImage[]
  category: ICategory
}

class ProfileImageUploader extends React.Component<IProps> {
  render() {
    return (
      <div style={{ width: '100%', margin: '5px 0' }}>
        <ImageWithDefaultEquip
          imageUrl={
            this.props.images.length > 0 ? this.props.images[0].url : undefined
          }
          categoryIcon={this.props.category.icon}
          height={180}
          width={325}
        />
        <ImageUploader
          images={this.props.images}
          handleDeleteImageClick={() => console.log('delete image')}
          imageUploading={false}
          handleChangeImages={(val) => console.log(val)}
          imageLimit={5}
        />
      </div>
    )
  }
}

export default ProfileImageUploader
