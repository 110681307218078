import React, { Suspense } from 'react'
import AssetsTab from '../components/inventory/AssetsTab'
import PlacesTab from '../components/inventory/PlacesTab'
import PeopleTab from '../components/inventory/PeopleTab'
import '../styles/pages/Inventory.scss'
import ReactHelmet from '../components/ReactHelmet'
import { getHash } from '../constants/hashGrabber'
import SideAppBar from '../components/sideAppBar/SideAppBar'
import HashStateManager from '../rx-js/HashStateManager'
import LoadingOverlay from '../components/LoadingOverlay'
import {
  InventoryHashFromString,
  InventoryHashToString,
  IInventoryHash,
  EInventoryTabs,
  IInventoryTabs,
} from '../constants/inventoryHashController'
import NoAccessPage from './NoAccessPage'

/** Removed temporarily until ready to re introduce in the future */
const tabs = [
  // { label: 'People', value: EInventoryTabs.people, icon: 'people' },
  // { label: 'Places', value: EInventoryTabs.place, icon: 'place' },
  { label: 'Things', value: EInventoryTabs.thing, icon: 'equipment' },
]

interface IState {
  selectedTab: IInventoryTabs
  addThingDialog: boolean
}

interface IProps {}

class Inventory extends React.Component<IProps, IState> {
  state: IState = {
    selectedTab: EInventoryTabs.thing,
    addThingDialog: false,
  }

  hash: HashStateManager<IInventoryHash> = new HashStateManager(
    InventoryHashFromString,
    InventoryHashToString
  )

  setTab = (tab: IInventoryTabs) => {
    this.setState({ selectedTab: tab })
    this.hash.set({
      ...this.hash.value,
      tab,
    })
  }

  lazyLoadInventory(selectedTab: string) {
    switch (selectedTab) {
      case EInventoryTabs.thing:
        return (
          <AssetsTab
            hash={this.hash}
            addThingDialog={this.state.addThingDialog}
            closeThingDialog={() => this.setState({ addThingDialog: false })}
          />
        )
      case EInventoryTabs.place:
        return <PlacesTab />
      case EInventoryTabs.people:
        return <PeopleTab />
      default:
        return <NoAccessPage Error="404 Not Found" />
    }
  }

  getTooltip(select: string): string {
    switch (select) {
      case EInventoryTabs.people:
        return 'Add Person'
      case EInventoryTabs.place:
        return 'Add Place'
      default:
        return 'Add Thing'
    }
  }

  whichOnClickFunction(selectedTab: string) {
    switch (selectedTab) {
      case EInventoryTabs.thing:
        return this.setState({ addThingDialog: true })
    }
  }

  componentDidMount() {
    const hashgrabbed = getHash()
      ? (getHash() as { tab?: IInventoryTabs })
      : { tab: EInventoryTabs.thing as IInventoryTabs }
    this.setTab(hashgrabbed.tab ? hashgrabbed.tab : EInventoryTabs.thing)
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <ReactHelmet title="Inventory" />
        <div className="viewport">
          <SideAppBar
            tabs={tabs}
            selectedTab={this.state.selectedTab}
            onChangeTab={(val: string) => this.setTab(val as IInventoryTabs)}
            tooltip={this.getTooltip(this.state.selectedTab)}
            addFab
            FabDisabled={this.state.selectedTab === EInventoryTabs.people}
            bottomIconButton
            onClick={() => this.whichOnClickFunction(this.state.selectedTab)}
          >
            <Suspense fallback={<LoadingOverlay loading delayedTime={5000} />}>
              {this.lazyLoadInventory(this.state.selectedTab)}
            </Suspense>
          </SideAppBar>
        </div>
      </div>
    )
  }
}

export default Inventory
