import React from 'react'
import IconButton from './IconButton'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'

const DarkTheme = createTheme({
  palette: {
    action: {
      active: '#fff',
    },
  },
})

interface IProps {
  isWhiteBG?: boolean
}

const BackButton = (props: IProps) => {
  const history = useHistory()
  return props.isWhiteBG ? (
    <IconButton
      id="notificationsBackButton"
      icon="back"
      variant="appbar"
      tooltip="Back"
      onClick={() => history.goBack()}
    />
  ) : (
    <ThemeProvider theme={DarkTheme}>
      <IconButton
        id="notificationsBackButtonDark"
        icon="back"
        variant="appbar"
        tooltip="Back"
        onClick={() => history.goBack()}
      />
    </ThemeProvider>
  )
}

export default BackButton
