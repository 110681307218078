import React from 'react'
import { iconPaths } from '../../svg-icons/CategoryIcons'
import { TCategoryIcons } from '../../types/category'
import Marker, {
  EMarkerShape,
  EMarkerCenterPosition,
  OverlayMarker,
} from './Marker'
import OverlayView from './MaptracOverlay'
// import { Category } from 'dto/category'
// import { IReadingLight } from 'dto/reading'

interface IProps {
  useMetric?: boolean
  onClick?: () => void
  lat: number
  lng: number
  category?: {
    color: string
    icon: TCategoryIcons
  }
  name: string
  speed?: number
  ign?: boolean
  heading?: number
  isTransparent?: boolean
  isHighlighted?: boolean
  tasks?: {
    equipId: string
    color: string
    onMouseEnter: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseLeave: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  }
}

export default class AssetMarkerEquip extends React.Component<IProps> {
  shouldComponentUpdate(nextProps: IProps) {
    return (
      this.props.lat !== nextProps.lat ||
      this.props.lng !== nextProps.lng ||
      this.props.isHighlighted !== nextProps.isHighlighted ||
      this.props.isTransparent !== nextProps.isTransparent ||
      this.props.speed !== nextProps.speed ||
      this.props.heading !== nextProps.heading ||
      this.props.ign !== nextProps.ign
    )
  }

  render() {
    return (
      <Marker
        title={this.props.name}
        speed={this.props.speed}
        ign={this.props.ign}
        position={{ lat: this.props.lat, lng: this.props.lng }}
        centerPosition={EMarkerCenterPosition.BottomCenter}
        shape={EMarkerShape.Teardrop}
        iconPath={
          this.props.category ? iconPaths[this.props.category.icon] : undefined
        }
        markerColor={
          this.props.category ? this.props.category.color : undefined
        }
        scale={this.props.isHighlighted ? 1 : 0.8}
        opacity={this.props.isTransparent ? 0.2 : 1}
        onClick={this.props.onClick}
        tasks={this.props.tasks}
      />
    )
  }
}

export class AssetMarkerEquipOverlay extends React.Component<IProps> {
  shouldComponentUpdate(nextProps: IProps) {
    return (
      this.props.lat !== nextProps.lat ||
      this.props.lng !== nextProps.lng ||
      this.props.isHighlighted !== nextProps.isHighlighted ||
      this.props.isTransparent !== nextProps.isTransparent ||
      this.props.speed !== nextProps.speed ||
      this.props.heading !== nextProps.heading
    )
  }

  render() {
    return (
      <OverlayView
        lat={this.props.lat}
        lng={this.props.lng}
        zIndex={this.props.isHighlighted ? 6 : 3}
        // transition="left 0.4s linear, top 0.4s linear"
      >
        <OverlayMarker
          title={this.props.name}
          speed={this.props.speed}
          ign={this.props.ign}
          heading={this.props.heading}
          centerPosition={EMarkerCenterPosition.Center}
          shape={EMarkerShape.Arrow}
          iconPath={
            this.props.category
              ? iconPaths[this.props.category.icon]
              : undefined
          }
          markerColor={
            this.props.category ? this.props.category.color : undefined
          }
          scale={this.props.isHighlighted ? 1 : 0.8}
          opacity={this.props.isTransparent ? 0.2 : 1}
          onClick={this.props.onClick}
          tasks={this.props.tasks}
        />
      </OverlayView>
    )
  }
}
