import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import EllipsisText from '../ux/EllipsisText'
import Divider from '@mui/material/Divider'
import IconButton from '../buttons/IconButton'
import { TCategoryIcons } from '../../types/category'
import CategoryAvatar from '../ux/CategoryAvatar'
import AddEditCategoryDialog from './dialogs/AddEditCategoryDialog'
import DeleteCategoryDialog from './dialogs/DeleteCategoryDialog'

interface ICategory {
  name: string
  id: string
  color: string
  icon: TCategoryIcons
  things: number
}

interface IProps {
  category: ICategory
  isMobile: boolean
}

interface IState {
  categoryDialogOpen: boolean
  categoryDeleteDialogOpen: boolean
}

class CategoryRow extends React.Component<IProps, IState> {
  state = {
    categoryDialogOpen: false,
    categoryDeleteDialogOpen: false,
  }

  render() {
    return (
      <List disablePadding key={this.props.category.id}>
        <ListItem>
          <CategoryAvatar
            color={this.props.category.color}
            icon={this.props.category.icon}
            size={40}
          />
          <ListItemText
            primary={
              <EllipsisText text={this.props.category.name}>
                {this.props.category.name}
              </EllipsisText>
            }
            secondary={'things: ' + this.props.category.things}
            style={{ marginLeft: 16 }}
          />
          <IconButton
            icon="edit"
            variant="toolbar"
            tooltip="Edit Category"
            onClick={() => this.setState({ categoryDialogOpen: true })}
          />
          <IconButton
            icon="delete"
            variant="toolbar"
            disabled={this.props.category.things > 0}
            tooltip="Delete Category"
            onClick={() => this.setState({ categoryDeleteDialogOpen: true })}
          />
        </ListItem>
        <Divider />
        <AddEditCategoryDialog
          dialogOpen={this.state.categoryDialogOpen}
          onClose={() => this.setState({ categoryDialogOpen: false })}
          type="edit"
          isMobile={this.props.isMobile}
          categoryName={this.props.category.name}
          categoryColor={this.props.category.color}
          categoryIcon={this.props.category.icon}
        />
        <DeleteCategoryDialog
          dialogOpen={this.state.categoryDeleteDialogOpen}
          onClose={() => this.setState({ categoryDeleteDialogOpen: false })}
          categoryName={this.props.category.name}
          categoryId={this.props.category.id}
        />
      </List>
    )
  }
}

export default CategoryRow
