import React from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Toolbar from '../../components/toolbar/Toolbar'
import ReactHelmet from '../../components/ReactHelmet'
import { ERoutes, PageTitle } from '../../components/routes/CheckRoutes'

interface IProps {
  width: number
  height: number
  isMobile?: boolean
}

interface IState {}

export default class RepairsByCategory extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <div>
        <ReactHelmet title={PageTitle(ERoutes.RepairsByCategory)} />
        <Toolbar
          title={PageTitle(ERoutes.RepairsByCategory)}
          scrollBackgroundColor
          defaultBackgroundColor
          noPositionFixed
        >
          <div>
            <Typography>Repairs for the month of:</Typography>
          </div>
        </Toolbar>
        <Paper square>Report</Paper>
      </div>
    )
  }
}

/** This is the component that needs to be exported as it is used on the array that renders all the reports */
export const RepairsByCategoryComp = (
  width: number,
  height: number,
  isMobile?: boolean
) => <RepairsByCategory width={width} height={height} isMobile={isMobile} />
