import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const HeartbeatIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="m 12.47,3.147 a 0.5514,0.5514 0 0 0 -0.52,0.415 l -2.647,10.608 -5.866,0 a 0.555,0.555 0 1 0 0,1.11 l 6.296,0 a 0.5514,0.5514 0 0 0 0.537,-0.42 l 1.67,-6.686 0,12.076 a 0.5514,0.5514 0 0 0 1.04,0.23 l 2.61,-5.2 2.29,0 a 1.922,1.922 0 0 0 1.85,1.38 1.922,1.922 0 0 0 1.92,-1.92 1.922,1.922 0 0 0 -1.92,-1.92 1.922,1.922 0 0 0 -1.85,1.35 l -2.64,0 a 0.5514,0.5514 0 0 0 -0.48,0.31 l -1.72,3.43 0,-14.206 A 0.5514,0.5514 0 0 0 12.47,3.147 Z" />,
  'Heartbeat'
)

export default HeartbeatIcon
