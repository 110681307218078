import React from 'react'
import { colors } from './roviTheme'

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 14,
    borderRadius: 4,
  },
  textContainer: {
    fontSize: 8,
  },
}

interface IProps {
  isOn: boolean
  label: string
  style?: React.CSSProperties
}

const IgnitionBox = (props: IProps) => (
  <div
    style={{
      ...styles.container,
      background: props.isOn ? colors.onGreen : colors.offGrey,
      ...props.style,
    }}
    title={props.isOn ? 'On' : 'Off'}
  >
    <div style={styles.textContainer}>{props.label}</div>
  </div>
)

export default IgnitionBox
