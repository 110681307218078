import React from 'react'
import { Section } from '../equipmentComponents/EquipmentSection'
import {
  IRentalRates,
  IRentalRatesBody,
  rateList,
  showKey,
  labelPer,
} from '../../types/equipment/equipmentProfile'
import { Table, TableRow, TableCell } from '@mui/material'
import RentalRatesDialog from './RentalRatesDialog'

export const dollarFormat = (val: number) =>
  `$${val.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`

interface IProps {
  rentalRates: IRentalRates
  equipId: string
  isMobile: boolean
}

interface IState {
  openEditDialog: boolean
}

class RentalRatesSection extends React.Component<IProps, IState> {
  state: IState = {
    openEditDialog: false,
  }

  handleClose = () => {
    this.setState({ openEditDialog: false })
  }

  handleOpen = () => {
    this.setState({ openEditDialog: true })
  }

  handleSave = (rentalTerms: IRentalRatesBody) => console.log(rentalTerms)

  render() {
    const { rentalRates } = this.props
    const showTable =
      rentalRates.showHour ||
      rentalRates.showDay ||
      rentalRates.showWeek ||
      rentalRates.showMonth ||
      rentalRates.showFlat
    return (
      <div style={{ width: '100%', margin: '10px 0' }}>
        <Section
          header="Rental Rates"
          equipId={this.props.equipId}
          emptyMessage={!showTable ? 'no rates' : undefined}
          editButton
          onEdit={this.handleOpen}
        >
          {showTable && (
            <Table
              size="small"
              style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
            >
              {rateList.map((rate) => {
                let currentRate: number | undefined = rentalRates[rate]
                return rentalRates[showKey[rate]] ? (
                  <TableRow key={rate} component="div">
                    <TableCell
                      align="right"
                      style={{
                        width: 100,
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <b>{labelPer[rate]}:</b>
                    </TableCell>
                    <TableCell align="left">
                      {currentRate ? dollarFormat(currentRate) : '-'}
                    </TableCell>
                  </TableRow>
                ) : null
              })}
            </Table>
          )}
        </Section>
        <RentalRatesDialog
          open={this.state.openEditDialog}
          onClose={this.handleClose}
          isMobile={this.props.isMobile}
        />
      </div>
    )
  }
}

export default RentalRatesSection
