import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { SvgIconProps } from '@mui/material/SvgIcon'

const AddGeofenceIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M12.9,17v-0.8v-1.4v-0.8h0.8H16v-2.3V11h0.8h1.4h0.1c0.1-0.2,0.3-0.5,0.4-0.7c0.7-1.5,0.9-2.9,0.5-4.5c-0.9-3.6-4.5-6.1-8.3-5.5C7.4,0.8,4.6,4,4.6,7.5c0,1.1,0.4,2.2,0.9,3.1c0.9,1.8,2,3.5,3.2,5.1c1,1.4,2.1,2.8,3.3,4.2c0.8-1,1.6-1.9,2.4-2.9h-0.7H12.9z M7.1,7.7c0-2.7,2.3-4.9,5-4.9c2.7,0.1,4.9,2.3,4.9,5s-2.2,4.9-4.9,4.9C9.3,12.7,7.1,10.4,7.1,7.7z M18.2,11.8v3.1h3.1v1.4h-3.1v3.1h-1.4v-3.1h-3.1v-1.4h3.1v-3.1H18.2 M15.2,18.1c0.3,0,0.6,0.1,0.8,0.1V17L15.2,18.1z M20.1,20.1c0,0.8-3.1,2.2-8.1,2.2s-8.1-1.4-8.1-2.2c0-0.6,1.8-1.6,5-2l-1.2-1.4c-3,0.5-5.4,1.7-5.4,3.3c0,2.4,4.9,3.7,9.7,3.7s9.7-1.3,9.7-3.7c0-1.1-1.1-1.9-2.8-2.6v1.7C19.7,19.4,20.1,19.8,20.1,20.1z" />,
  'addGeofenceIcon'
)

export default AddGeofenceIcon
