import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '../../buttons/Button'
import { IPart } from '../../../types/service/parts'

interface IProps {
  open: boolean
  onClose: () => void
  onDelete: (partId: string) => void
  part?: IPart
}

const DeletePartDialog = (props: IProps) => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DialogTitle>{`Delete ${
      props.part ? props.part.number : 'no part number'
    }`}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete this part?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose}>Cancel</Button>
      <Button
        onClick={() =>
          props.onDelete(props.part ? props.part.id : 'no part id')
        }
        color="primary"
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

export default DeletePartDialog
