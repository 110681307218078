import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material'
import React from 'react'
import { coreTimeZones } from 'src/constants/timezones'
import { IOnboardingStepProps } from '../Onboarding'

interface IOnboardingThirdStepProps {
  branchName: string
  selectedTimezone: string
  onChangeTimezone: (timezone: string) => void
}

const OnboardingThirdStep = ({
  back,
  next,
  branchName,
  selectedTimezone,
  onChangeTimezone,
}: IOnboardingStepProps & IOnboardingThirdStepProps) => {
  const buttonClassName = 'onboarding-action-button'
  const timezones = coreTimeZones.map((tz) => tz.replaceAll('_', ' '))

  return (
    <div className="onboarding-step">
      <Typography variant="h4" fontWeight={700} textAlign="center" marginY={2}>
        Select a time zone for {branchName}.
      </Typography>
      <FormControl className="timezone-select">
        <InputLabel variant="standard">Time Zone</InputLabel>
        <Select
          onChange={(e) => onChangeTimezone(e.target.value)}
          variant="standard"
          value={selectedTimezone}
          MenuProps={{
            style: {
              maxHeight: 300,
            },
          }}
        >
          {timezones.map((timezone) => (
            <MenuItem value={timezone} key={timezone}>
              {timezone}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="action-button-group">
        <Button onClick={back} className={buttonClassName}>
          Back
        </Button>
        <Button onClick={next} className={buttonClassName} variant="contained">
          Finish
        </Button>
      </div>
    </div>
  )
}

export default OnboardingThirdStep
