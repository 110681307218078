import React from 'react'
import '../../styles/components/menus/NavBar.scss'
import { createStyles } from '@mui/material/styles'
import { withStyles, WithStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles/createTheme'

export const SECTION_HEIGHT = 70
export const SECTION_WIDTH = 80

const styles = (theme: Theme) =>
  createStyles({
    sideNavContainer: {
      type: 'dark',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      width: 80,
      background: theme.palette.primary.dark,
      zIndex: 9,
      boxShadow: theme.shadows[3],
    },
    bottomNavContainer: {
      type: 'dark',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      height: 56,
      zIndex: 9,
      display: 'flex',
      justifyContent: 'center',
      background: theme.palette.primary.dark,
      boxShadow: theme.shadows[3],
    },
  })

interface ISideNavSectionProps extends WithStyles<typeof styles> {
  onClick?: () => void
  style?: React.CSSProperties
  className?: string
}

const SideNavSectionComp: React.SFC<ISideNavSectionProps> = (props) => (
  <div
    className={'sideNavSectionCompSectionContainer ' + props.className}
    onClick={props.onClick}
    style={props.style}
  >
    {props.children}
  </div>
)

export const SideNavSection = withStyles(styles)(SideNavSectionComp)

interface ISideNavProps extends WithStyles<typeof styles> {
  offsetTop?: number
  offsetBottom?: number
}

const SideNavComp: React.SFC<ISideNavProps> = (props) => (
  <>
    <div
      className={props.classes.sideNavContainer}
      style={{
        paddingTop: props.offsetTop,
        paddingBottom: props.offsetBottom,
      }}
    >
      {props.children}
    </div>

    <div style={{ width: 80, height: '100%', flexShrink: 0 }} />
  </>
)

export const SideNav = withStyles(styles)(SideNavComp)

interface IBottomNavProps extends WithStyles<typeof styles> {}

const BottomNavComp: React.SFC<IBottomNavProps> = (props) => (
  <div className={props.classes.bottomNavContainer}>{props.children}</div>
)

export const BottomNav = withStyles(styles)(BottomNavComp)
