import React from 'react'
import { QueryRenderer } from '@cubejs-client/react'
import 'antd/dist/antd.min.css'
import { Bar } from 'react-chartjs-2'
import cubejsApi from '../../reportComponents/cubejsConfig'
import moment from 'moment'
import { COLORS_SERIES } from '../../ux/roviTheme'
import { LoadingIconCenter } from '../../LoadingOverlay'

// http://localhost:4000/#/build?query={%22measures%22:[%22TankMetrics.tanklevelPercentage%22],%22timeDimensions%22:[{%22dimension%22:%22TankMetrics.receivedTime%22,%22granularity%22:%22day%22,%22dateRange%22:%22This%20week%22}],%22order%22:{%22TankMetrics.tanklevelPercentage%22:%22desc%22},%22dimensions%22:[%22TankMetrics.assetId%22],%22filters%22:[],%22limit%22:1000,%22segments%22:[]}

const commonOptions = {
  maintainAspectRatio: false,
}

interface IProps {
  width: number
  height: number
  isMobile: boolean
  esn: string
  tenant: string
  selectedDates: Array<Date | null>
}

interface IState {}

export default class FluidUsageAssetBarReport extends React.Component<
  IProps,
  IState
> {
  state: IState = {}

  /**
   * this is to allow for felxibility for the future, but essentially its to map through the dates,
   * insuring that the dates dont get messed up when we map into the past.
   */
  maxTotal =
    this.props.selectedDates[0] && this.props.selectedDates[1]
      ? parseInt(
          moment
            .duration(
              moment(this.props.selectedDates[1]).diff(
                moment(this.props.selectedDates[0]).startOf('day')
              )
            )
            .asDays()
            .toString()
        )
      : 0

  renderChart = ({
    resultSet,
    error,
    height,
    width,
    pivotConfig,
  }: {
    resultSet: any
    error: any
    height: number
    width: number
    pivotConfig: any
  }) => {
    if (error) {
      return <div>{error.toString()}</div>
    }

    if (!resultSet) {
      return <LoadingIconCenter loading height={height} width={width} />
    }

    const data = {
      labels: resultSet.categories().map((c: { category: any }) => c.category),
      datasets: resultSet
        .series()
        .map((s: { title: any; series: any[] }, index: number) => ({
          label: s.title,
          data: s.series.map((r: { value: any }) => r.value),
          borderColor: COLORS_SERIES[index],
          backgroundColor: COLORS_SERIES[index],
          fill: false,
        })),
    }
    const options = {
      ...commonOptions,
      scales: {
        xAxes: [
          {
            stacked: true,
            type: 'time',
            time: {
              unit: 'day',
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              callback: function (value: any) {
                return value + '%'
              },
            },
          },
        ],
      },
    }
    console.log(data.datasets)
    return (
      <Bar
        data={data}
        options={options}
        height={height}
        width={width}
        legend={{ reverse: true, align: 'center' }}
      />
    )
  }

  render() {
    return (
      <QueryRenderer
        query={{
          measures: ['TankMetrics.tanklevelPercentage'],
          timeDimensions: [
            {
              dimension: 'TankMetrics.timestamp',
              granularity: 'day',
              dateRange: [
                moment(this.props.selectedDates[0]).format('YYYY-MM-DD'),
                this.props.selectedDates[1]
                  ? moment(this.props.selectedDates[1]).format('YYYY-MM-DD')
                  : moment(this.props.selectedDates[0]).format('YYYY-MM-DD'),
              ],
            },
          ],
          order: [
            ['TankMetrics.tanklevelPercentage', 'desc'],
            ['TankMetrics.timestamp', 'asc'],
          ],
          filters: this.props.esn
            ? [
                {
                  member: 'TankMetrics.deviceEsn',
                  operator: 'equals',
                  values: [this.props.esn],
                },
                {
                  member: 'TankMetrics.tenantId',
                  operator: 'equals',
                  values: [this.props.tenant],
                },
              ]
            : [],
          dimensions: ['TankMetrics.deviceEsn'],
        }}
        cubejsApi={cubejsApi(this.props.tenant)}
        resetResultSetOnChange={false}
        render={(props) => (
          <div id="reportFluidUsageOverTime">
            {this.renderChart({
              ...props,
              height: this.props.height,
              width: this.props.width,
              pivotConfig: {
                x: ['TankMetrics.deviceEsn', 'TankMetrics.timestamp.day'],
                y: ['measures'],
                fillMissingDates: true,
                joinDateRange: false,
              },
            })}
          </div>
        )}
      />
    )
  }
}

/** This is the component that needs to be exported as it is used on the array that renders all the reports */
export const FluidUsageAssetBarComp = (
  width: number,
  height: number,
  isMobile: boolean,
  esn: string,
  tenant: string,
  selectedDates: Array<Date | null>
) => (
  <FluidUsageAssetBarReport
    width={width}
    height={height}
    isMobile={isMobile}
    esn={esn}
    tenant={tenant}
    selectedDates={selectedDates}
  />
)
