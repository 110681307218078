enum VelocityType {}

type Velocity = number & VelocityType

namespace Velocity {
  const CM_PER_M = 100
  const M_PER_KM = 1000
  const SEC_PER_HR = 3600
  const CM_PER_IN = 2.54
  const IN_PER_FT = 12
  const FT_PER_MI = 5280

  export const ofCentimetersPerSecond = (v: number): Velocity => v as Velocity
  export const ofMetersPerSecond = (v: number) =>
    Velocity.ofCentimetersPerSecond(v * CM_PER_M)
  export const ofKilometersPerSecond = (v: number) =>
    Velocity.ofMetersPerSecond(v * M_PER_KM)
  export const ofInchesPerSec = (v: number) =>
    Velocity.ofCentimetersPerSecond(v * CM_PER_IN)
  export const ofFeetPerSec = (v: number) =>
    Velocity.ofInchesPerSec(v * IN_PER_FT)
  export const ofMilesPerSec = (v: number) =>
    Velocity.ofFeetPerSec(v * FT_PER_MI)

  export const ofCentimetersPerHour = (v: number) =>
    Velocity.ofCentimetersPerSecond(v / SEC_PER_HR)
  export const ofMetersPerHour = (v: number) =>
    Velocity.ofCentimetersPerHour(v * CM_PER_M)
  export const ofKilometersPerHour = (v: number) =>
    Velocity.ofMetersPerHour(v * M_PER_KM)
  export const ofInchesPerHour = (v: number) =>
    Velocity.ofCentimetersPerHour(v * CM_PER_IN)
  export const ofFeetPerHour = (v: number) =>
    Velocity.ofInchesPerHour(v * IN_PER_FT)
  export const ofMilesPerHour = (v: number) =>
    Velocity.ofFeetPerHour(v * FT_PER_MI)

  export const toCentimetersPerSecond = (v: Velocity) => v as number
  export const toMetersPerSecond = (v: Velocity) =>
    Velocity.toCentimetersPerSecond(v) / CM_PER_M
  export const toKilometersPerSecond = (v: Velocity) =>
    Velocity.toMetersPerSecond(v) / M_PER_KM
  export const toInchesPerSec = (v: Velocity) =>
    Velocity.toCentimetersPerSecond(v) / CM_PER_IN
  export const toFeetPerSec = (v: Velocity) =>
    Velocity.toInchesPerSec(v) / IN_PER_FT
  export const toMilesPerSec = (v: Velocity) =>
    Velocity.toFeetPerSec(v) / FT_PER_MI

  export const toCentimetersPerHour = (v: Velocity) =>
    Velocity.toCentimetersPerSecond(v) * SEC_PER_HR
  export const toMetersPerHour = (v: Velocity) =>
    Velocity.toCentimetersPerHour(v) / CM_PER_M
  export const toKilometersPerHour = (v: Velocity) =>
    Velocity.toMetersPerHour(v) / M_PER_KM
  export const toInchesPerHour = (v: Velocity) =>
    Velocity.toCentimetersPerHour(v) / CM_PER_IN
  export const toFeetPerHour = (v: Velocity) =>
    Velocity.toInchesPerHour(v) / IN_PER_FT
  export const toMilesPerHour = (v: Velocity) =>
    Velocity.toFeetPerHour(v) / FT_PER_MI

  export enum Unit {
    CentimetersPerSecond = 'cm/s',
    MetersPerSecond = 'm/s',
    KilometersPerSecond = 'km/s',
    InchesPerSecond = 'in/s',
    FeetPerSecond = 'ft/s',
    MilesPerSecond = 'mi/s',

    CentimetersPerHour = 'cm/h',
    MetersPerHour = 'm/h',
    KilometersPerHour = 'km/h',
    InchesPerHour = 'in/h',
    FeetPerHour = 'ft/h',
    MilesPerHour = 'mph'
  }

  const conversionLookup: { [key in Unit]: (val: Velocity) => number } = {
    [Unit.CentimetersPerSecond]: toCentimetersPerSecond,
    [Unit.MetersPerSecond]: toMetersPerSecond,
    [Unit.KilometersPerSecond]: toKilometersPerSecond,
    [Unit.InchesPerSecond]: toInchesPerSec,
    [Unit.FeetPerSecond]: toFeetPerSec,
    [Unit.MilesPerSecond]: toMilesPerSec,
    [Unit.CentimetersPerHour]: toCentimetersPerHour,
    [Unit.MetersPerHour]: toMetersPerHour,
    [Unit.KilometersPerHour]: toKilometersPerHour,
    [Unit.InchesPerHour]: toInchesPerHour,
    [Unit.FeetPerHour]: toFeetPerHour,
    [Unit.MilesPerHour]: toMilesPerHour
  }

  export const format = (val: Velocity, unit: Unit = Unit.MetersPerSecond) =>
    `${Math.round(conversionLookup[unit](val))}${unit}`
}

export default Velocity
